import {
  GlobalAppMethodsContextInterface,
  GlobalAppStateContextInterface,
} from "../exports/Interfaces";

class GlobalAppUtility {
  public globalAppMethodsContext: GlobalAppMethodsContextInterface | null =
    null;
  public globalAppStateContext: GlobalAppStateContextInterface | null = null;

  public setGlobalAppMethodsContext(
    context: GlobalAppMethodsContextInterface | null
  ): void {
    if (context) {
      this.globalAppMethodsContext = context;
    }
  }

  public setGlobalAppStateContext(
    state: GlobalAppStateContextInterface | null
  ): void {
    if (state) {
      this.globalAppStateContext = state;
    }
  }
}

export default new GlobalAppUtility();
