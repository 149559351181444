import {useEffect, useRef, useState} from "react";
import CustomNavBar from "../components-library/navigation/CustomNavBar";
import CustomButton from "../components-library/buttons/CustomButton";
import {PaymentMethod} from "../services/exports/Constants";
import {PaymentOptionPropertiesInterface,} from "../services/exports/Interfaces";
import LoadingIndicator from "../components-library/loading/LoadingIndicator";
import {useSelector} from "react-redux";
import PaymentManager from "../services/api/PaymentManager";
import ConfirmCancelModal from "./ConfirmCancelModal";
import collect from "collect.js";
import {useSearchParams} from "react-router-dom";
import * as stripeJs from "@stripe/stripe-js";
import useAlert from "../hooks/utility/useAlert";
import {useTranslation} from "react-i18next";
import ButtonFooter from "../components-library/footers/ButtonFooter";
import {StoreInterface} from "../store/types";
import GlobalAppUtility from "../services/utilities/GlobalAppUtility";
import useStripeHelpers from "../hooks/payment/useStripeHelpers";
import PaymentMethodItem from "../components-library/payment/PaymentMethodItem";
import usePaymentMethods from "../hooks/payment/usePaymentMethods";

interface Props {
  openModal: boolean;
  defaultPaymentMethodId?: PaymentMethod;
  onCloseModal: () => void;
}

export default function PaymentMethodModal(props: Props) {
  const { t } = useTranslation(null, { keyPrefix: 'Modals:PaymentMethodModal' });

  const { openModal, defaultPaymentMethodId, onCloseModal } = props;

  const { company } = useSelector((state: StoreInterface) => state.initialData);
  const { profile } = useSelector((state: StoreInterface) => state.profile);

  const {
    stripe,
    elements,
    options: elementOptions,
    getClientSecret,
  } = useStripeHelpers();

  const { paymentOptions, getDefaultPaymentOption, isPaymentMethodSaved } = usePaymentMethods();

  const alert = useAlert();
  const [searchParams] = useSearchParams();
  const urlParams = Object.fromEntries([...searchParams]);

  const [loading, setLoading] = useState(false);
  const [deletingPaymentMethod, setDeletingPaymentMethod] = useState(false);
  const [showRemovePaymentMethodModal, setShowRemovePaymentMethodModal] = useState(false);
  const [selectedRemovePaymentMethod, setSelectedRemovePaymentMethod] = useState<PaymentOptionPropertiesInterface | null>(null);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState<PaymentOptionPropertiesInterface>(null);
  const [saving, setSaving] = useState(false);

  const stripeRef = useRef(stripe);
  stripeRef.current = stripe;

  const elementsRef = useRef(elements);
  elementsRef.current = elements;

  useEffect(() => {
    if (openModal) {
      onDidFocus();
    }
  }, [openModal]);

  function onDidFocus() {
    setLoading(true);
    setTimeout(() => setLoading(false), 1000);
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    if (saving) {
      return;
    }

    setSelectedPaymentOption(
      getDefaultPaymentOption(selectedPaymentOption?.payment_method_id ?? defaultPaymentMethodId)
    );
  }, [paymentOptions, openModal, defaultPaymentMethodId, saving]);

  useEffect(() => {
    if (selectedPaymentOption) {
      getClientSecret(selectedPaymentOption);
    }
  }, [selectedPaymentOption?.payment_method_id]);

  useEffect(() => {
    if (!urlParams?.setup || urlParams?.setup === 'false') {
      return;
    }

    if (urlParams?.redirect_status !== "succeeded") {
      return alert.error({
        description: t("error_messages.saving_payment_method_unsuccessful"),
      });
    }

    const paymentMethodToBeSaved = collect(paymentOptions)?.first(
      (paymentOption: PaymentOptionPropertiesInterface) =>
        paymentOption.payment_method_id === urlParams?.payment_method_id
    );

    if (paymentMethodToBeSaved) {
      savePaymentMethod(paymentMethodToBeSaved);
    }
  }, []);

  function confirmSetup() {
    if (! isPaymentMethodSaved(selectedPaymentOption?.payment_method_id)) {
      switch (selectedPaymentOption?.payment_method_id) {
        case PaymentMethod.Sofort:
          return confirmSofortPaymentSetup();
        case PaymentMethod.Paypal:
        case PaymentMethod.ApplePay:
        case PaymentMethod.Cash:
        case PaymentMethod.GooglePay:
        case PaymentMethod.Giropay:
          return savePaymentMethod(selectedPaymentOption);
        default:
          return confirmPaymentSetup();
      }
    }

    if (selectedPaymentOption?.payment_method_id === getDefaultPaymentOption()?.payment_method_id) {
      return onCloseModal();
    }

    return savePaymentMethod(selectedPaymentOption);
  }

  async function confirmSofortPaymentSetup() {
    setSaving(true);
    const { error } = await stripe.confirmSofortSetup(elementOptions?.clientSecret, {
      payment_method: {
        sofort: {
          country: "DE",
        },
        billing_details: {
          name: `${profile?.first_name} ${profile?.last_name}`,
          email: profile?.email,
        },
      },
      return_url: `${window.location.href.replace(window.location.search, '')}?payment_method_id=${selectedPaymentOption?.payment_method_id}&setup=true`,
    })
    toggleSaving();

    if (error) {
      return alert.error({
        description: t("error_messages.saving_payment_method_unsuccessful"),
      });
    }
  }

  async function confirmPaymentSetup() {
    setSaving(true);
    const result = await stripeRef.current.confirmSetup({
      elements: elementsRef.current,
      redirect: "if_required",
      confirmParams: {
        return_url: `${window.location.href.replace(window.location.search, '')}?payment_method_id=${selectedPaymentOption?.payment_method_id}&setup=true`,
        payment_method_data: {
          billing_details: {
            name: `${profile?.first_name} ${profile?.last_name}`,
            email: profile?.email,
            phone: profile?.phone_number,
          },
        },
      },
    });

    if (result.error) {
      setSaving(false);
      const { message } = result.error;

      return GlobalAppUtility.globalAppMethodsContext.toggleInfoModal({
        openModal: true,
        title: message,
        primaryButtonTitle: 'Ok',
        onConfirm: toggleSaving,
      });
    }

    const { setupIntent } = result;

    return savePaymentMethod(selectedPaymentOption, setupIntent);
  }

  async function savePaymentMethod(
    paymentOption: PaymentOptionPropertiesInterface,
    setupIntent?: stripeJs.SetupIntent
  ) {
    setSaving(true);


    if (isPaymentMethodSaved(paymentOption.payment_method_id)) {
      const { success } = await PaymentManager.putDefaultPaymentOption({
        payment_option_id: collect(paymentOptions).firstWhere('payment_method_id', paymentOption.payment_method_id).id,
      });

      return onSavePaymentMethod(success);
    }

    const { success } = await PaymentManager.postPaymentOption({
      company_id: company.id,
      payment_method_id: paymentOption.payment_method_id,
      external_payment_method_id: setupIntent?.payment_method,
    });

    return onSavePaymentMethod(success);
  }

  function onSavePaymentMethod(success: boolean) {
    toggleSaving();

    if (! success) {
      return alert.error({
        description: t("error_messages.saving_payment_method_unsuccessful"),
      });
    }

    onCloseModal();
  }

  function toggleSaving(): void {
    setTimeout(() => setSaving(false), 1000);
  }

  function togglePaymentMethod(paymentOption: PaymentOptionPropertiesInterface) {
    if (selectedPaymentOption?.payment_method_id === paymentOption?.payment_method_id) {
      return setSelectedPaymentOption(null);
    }

    setSelectedPaymentOption(paymentOption);
  }

  function toggleRemovePaymentMethodModal(item?: PaymentOptionPropertiesInterface) {
    if (item) {
      setSelectedRemovePaymentMethod(item);
    }

    setShowRemovePaymentMethodModal(!showRemovePaymentMethodModal);
  }

  async function removePaymentMethod() {
    setDeletingPaymentMethod(true);
    const { success } = await PaymentManager.removePaymentOption(
      selectedRemovePaymentMethod?.id
    );
    setDeletingPaymentMethod(false);

    if (! success) {
      return alert.error({
        description: t("error_messages.removing_payment_method_unsuccessful"),
      });
    }

    if (selectedRemovePaymentMethod?.payment_method_id === PaymentMethod.Card) {
      return window.location.reload();
    }

    return setShowRemovePaymentMethodModal(false);
  }

  function renderLoading() {
    return (
      <div
        className={loadingContainer}
        data-test={"loading-payment-method-modal"}
      >
        <div className={loadingWrapper}>
          <div className="-mb-tiny">
            <LoadingIndicator lg={60} md={50} sm={40} />
          </div>
        </div>
      </div>
    );
  }

  function renderConfirmCancelModal() {
    return (
      <ConfirmCancelModal
        title={t("confirm_cancel_modal.title", {
          name: selectedRemovePaymentMethod?.display_name,
        })}
        description={t("confirm_cancel_modal.description", {
          name: selectedRemovePaymentMethod?.display_name,
        })}
        primaryButtonTitle={t("confirm_cancel_modal.buttons.yes")}
        secondaryButtonTitle={t("confirm_cancel_modal.buttons.no")}
        openModal={showRemovePaymentMethodModal}
        loading={deletingPaymentMethod}
        onConfirm={removePaymentMethod}
        onCancel={toggleRemovePaymentMethodModal}
      />
    );
  }

  function renderHeaderBar() {
    return (
      <div className={headerBarContainer}>
        <CustomNavBar
          title={t("header.payment_method")}
          rightClick={onCloseModal}
          hideBackIcon
        />
      </div>
    );
  }

  function renderAddButton() {
    return openModal ? (
      <ButtonFooter className={buttonContainer}>
        <div className={buttonWrapper}>
          <CustomButton
            title={t("custom_button.save")}
            onClick={confirmSetup}
            loading={saving}
            type="submit"
            testId="save-payment-method-button"
            disabled={!selectedPaymentOption}
          />
        </div>
      </ButtonFooter>
    ) : undefined;
  }

  function renderPaymentOptions() {
    return (
      <div className={paymentOptionContainer}>
        {paymentOptions?.map(
          (paymentOption: PaymentOptionPropertiesInterface) => (
            <div key={`${paymentOption?.id?.toString()}-${elementOptions?.clientSecret}`}>
              <PaymentMethodItem
                item={paymentOption}
                openModal={openModal}
                onClick={togglePaymentMethod}
                onRemove={toggleRemovePaymentMethodModal}
                selectedPaymentMethod={selectedPaymentOption}
              />
            </div>
          )
        )}
      </div>
    );
  }

  return (
    <div className={container(openModal)}>
      {renderHeaderBar()}
      <div className={wrapper}>
        {loading ? (
          renderLoading()
        ) : (
          <div>
            <div className={paymentsWrapper}>{renderPaymentOptions()}</div>
            {renderAddButton()}
          </div>
        )}
      </div>
      {renderConfirmCancelModal()}
    </div>
  );
}

const container = (open: boolean) =>
  `${
    open ? "visible" : "invisible"
  } w-screen h-screen bg-background-inkWhite-white_1 fixed inset-0 z-50 overflow-auto overscroll-contain`;

const wrapper = "bg-background-inkWhite-white_1 h-full relative";

const paymentsWrapper = "pt-small bg-background-inkWhite-white_1";

const headerBarContainer = "w-full sticky top-0 z-50";

const buttonContainer = "fixed bottom-0 flex justify-center items-center";

const buttonWrapper = "lg:w-1/2 w-full";

const paymentOptionContainer =
  "flex flex-col lg:px-none lg:px-medium px-small mx-auto pb-[50px] lg:w-1/2 w-full z-30";

const loadingContainer =
  "flex flex-col h-screen justify-center items-center w-full";

const loadingWrapper =
  "bg-background-inkWhite-white_1 lg:h-24 lg:w-24 md:h-20 md:w-20 h-16 w-16 mb-[140px] rounded-xl flex justify-center items-center shadow border";
