import React from "react";

interface Props {
  className?: string;
  style?: Object;
  color?: string;
  standard?: boolean;
}

export default function PromoCodeIcon(props: Props) {
  const { className, standard, style, color } = props;

  return (
    <svg
      className={className ?? "h-4 w-4"}
      viewBox="0 0 17 17"
      fill="none"
      style={style}
    >
      <path d="M9.64637 15.9681C9.38031 16.2342 9.05907 16.3672 8.68267 16.3672C8.30626 16.3672 7.98503 16.2342 7.71897 15.9681L0.379518 8.62865C0.233434 8.48258 0.133656 8.32842 0.0801859 8.16617C0.0267286 8.00391 0 7.83358 0 7.65516V1.70532C0 1.32239 0.126512 1.00363 0.379538 0.749054C0.632537 0.494476 0.950501 0.367188 1.33343 0.367188H7.28328C7.4617 0.367188 7.63855 0.394706 7.81385 0.449742C7.98915 0.504765 8.14984 0.605318 8.29591 0.751402L15.5962 8.04703C15.8753 8.32614 16.0149 8.65467 16.0149 9.03263C16.0149 9.4106 15.8753 9.73914 15.5962 10.0183L9.64637 15.9681ZM8.73627 15.0533L14.6861 9.10348L7.28328 1.70532H1.33343V7.65516L8.73627 15.0533ZM3.38848 4.75853C3.66248 4.75853 3.90061 4.65741 4.10285 4.45516C4.30509 4.25292 4.40621 4.0148 4.40621 3.74079C4.40621 3.46678 4.30509 3.22866 4.10285 3.02642C3.90061 2.82417 3.66248 2.72305 3.38848 2.72305C3.11447 2.72305 2.87635 2.82417 2.6741 3.02642C2.47186 3.22866 2.37074 3.46678 2.37074 3.74079C2.37074 4.0148 2.47186 4.25292 2.6741 4.45516C2.87635 4.65741 3.11447 4.75853 3.38848 4.75853Z" fill={color ?? 'black'} />
    </svg>
  );
}
