import CustomButton, { CustomButtonProps } from "./CustomButton";
import classnames from "classnames";

interface ExtendedCustomButtonProps extends CustomButtonProps {
  hideButton?: boolean;
}

interface Props {
  leftButtonProps: ExtendedCustomButtonProps;
  rightButtonProps: ExtendedCustomButtonProps;
}

const HorizontalButtons = (props: Props): JSX.Element => {
  const { leftButtonProps, rightButtonProps } = props;
  return (
    <div
      className={classnames("grid grid-cols-1 w-full", {
        "grid-cols-2 gap-3":
          !leftButtonProps?.hideButton && !rightButtonProps?.hideButton,
      })}
    >
      {!leftButtonProps?.hideButton && (
        <CustomButton className="py-xsmall" {...leftButtonProps} />
      )}
      {!rightButtonProps?.hideButton && (
        <CustomButton className="py-xsmall" {...rightButtonProps} />
      )}
    </div>
  );
};

export default HorizontalButtons;
