export default {
  header: "Thank you",
  titles: {
    rate_experience: "How was your experience?",
    leave_google_review: "Submit review screenshot",
    receipt_sent: "Receipt sent",
  },
  text: {
    not_all_paid:
      "Thank you {{name}}! Please ensure the whole table is paid before you leave.",
    all_paid: "You’re good to go 😊 Hope to see you again soon {{name}}!",
    waiter_knows_the_bill_is_paid:
      "Our staff knows that the bill is paid. Feel free to go or stay more 😊",
    receipt_sent: "We already sent you the receipt via email.",
  },
  labels: {
    you_paid: "You paid {{amount}}",
    left_to_pay: "Left to pay",
  },
  buttons: {
    continue: "Continue",
  },
  feedback: {
    general_error: "Something went wrong, please reload the page",
  },
  toasts: {
    rate_experience:
      "How did you like your experience? Please select a rating below 😊",
    table_reset:
      "Thank you for your visit. We automatically clear your table when all orders are paid. If you want to order more feel free to do so 😋",
  },
};
