import {
  ModifierInterface,
  ModifierGroupInterface,
} from "../../../services/exports/Interfaces";
import ModifierMultiSelect from "../modifier/ModifierMultiSelect";
import { useTranslation } from "react-i18next";
import collect from "collect.js";
import {BasketLineItemModifierInterface} from "../../../store/types";
import BasketHelpers from "../../../services/helpers/BasketHelpers";
import React, {forwardRef, useCallback} from "react";

interface Props {
  modifierGroup: ModifierGroupInterface;
  selectedModifiers: BasketLineItemModifierInterface[];
  toggle: (modifier: ModifierInterface) => void;
}

const ModifierGroupMultiSelect = forwardRef((props: Props, ref: React.Ref<HTMLDivElement>) => {
  const { t } = useTranslation(null, { keyPrefix: 'Components:Menu:ModifierGroup:ModifierGroupMultiSelect' });

  const { modifierGroup, selectedModifiers, toggle } = props;

  const isSelected = useCallback(
    (modifierId) => !!collect(selectedModifiers).first(
      (item) => item?.modifier_group_id === modifierGroup?.id && item?.modifier_id === modifierId,
    ),
    [modifierGroup?.id, selectedModifiers]
  );

  function renderTitle() {
    const title = modifierGroup?.is_variant ? t('select_size') : modifierGroup?.name;

    return (
      <div className={container}>
        <div className={titleContainer}>
          <h6 className={titleStyle}>{title}</h6>
          {BasketHelpers.isModifierGroupRequired(modifierGroup) && <p className={descriptionStyle}>{t("required")}</p>}
        </div>
        {modifierGroup?.max_count && (
          <p className={maxCountStyle}>
            {t('select_up_to', { maxCount: modifierGroup?.max_count })}
          </p>
        )}
      </div>
    );
  }

  return (
    <>
      {renderTitle()}
      <div className="lg:px-medium px-small">
        {modifierGroup?.modifiers?.map((item: ModifierInterface) => (
          <div key={item?.id?.toString()}>
            <ModifierMultiSelect
              data={item}
              isSelected={isSelected(item.id)}
              toggle={() => toggle(item)}
            />
          </div>
        ))}
      </div>
    </>
  );
});

export default ModifierGroupMultiSelect;

const container = "mb-small bg-brand-inkGrey-grey_6 lg:px-medium px-small py-mini";

const titleContainer = "flex flex-row items-center";

const titleStyle = "mr-xsmall";

const descriptionStyle = "text-brand-text-grey mini";

const maxCountStyle = "text-brand-text-grey mini mt-tiny";
