export default {
  header: {
    main: "Confirm order",
    order_summary: "Order Summary",
  },
  labels: {
    total: "Total",
  },
  buttons: {
    add_products: "+ Add products",
    place_order: "Place order now",
  },
  empty_order_basket: {
    title: "Let's go",
    description: "Add items to get started",
  },
  loading_overlay: "Hold on… we’re sending your order to the kitchen",
};
