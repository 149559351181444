import { ProductLikesContextInterface } from "../context/types";
import ProductLikesContext from "../context/ProductLikesContext";
import { useSelector } from "react-redux";
import { StoreInterface } from "../store/types";
import ReduxActions from "../store/ReduxActions";
import { actionCreators } from "../store/actions";

interface Props {
  children: any;
}

export default function ProductLikesProvider({ children }: Props) {

  const { likedProductIds } = useSelector((store: StoreInterface) => store.productLikes);

  const likeProduct = (id: number) => {
    ReduxActions.dispatch(actionCreators.productLikes.likeProduct(id));
  };

  const unlikeProduct = (id: number) => {
    ReduxActions.dispatch(actionCreators.productLikes.unlikeProduct(id));
  }

  const context: ProductLikesContextInterface = {
    likedProductIds,
    likeProduct,
    unlikeProduct,
  };

  return (
    <ProductLikesContext.Provider value={context}>{children}</ProductLikesContext.Provider>
  );
}
