import {generatePath as reactGeneratePath, useNavigate as useReactNavigate} from "react-router-dom";
import {To} from "@remix-run/router";
import {NavigateOptions} from "react-router/dist/lib/context";
import useCompanySlug from "../global/useCompanySlug";
import {useCallback} from "react";

export interface UseNavigateInterface {
  generatePath: (to: To|number) => string;
  navigate: NavigateFunction;
}

interface CustomNavigateOptions extends NavigateOptions {
  absolute?: boolean;
}

interface NavigateFunction {
  (to: To, options?: CustomNavigateOptions): void;
  (delta: number): void;
}

export default function useNavigate(): UseNavigateInterface {
  const reactNavigate = useReactNavigate();

  const { slug } = useCompanySlug();

  const generatePath = useCallback(
    (to: To, options?: CustomNavigateOptions): string => {
      if (!slug || options?.absolute) {
        return (to as string).startsWith('/')
          ? (to as string)
          : `/${to}`;
      }

      if (to === '/') {
        return reactGeneratePath('/:slug', {slug})
      }

      return reactGeneratePath(
        typeof to === 'string' && to.startsWith('/')
          ? `/:slug${to}`
          : `/:slug/${to}`,
        {slug}
      )
    },
    [slug]
  );

  const navigate = useCallback<NavigateFunction>(
    (to: To|number, options?: CustomNavigateOptions) => {
      if (typeof to === 'number') {
        return reactNavigate(to);
      }

      return reactNavigate(
        generatePath(to, options),
        options,
      );
    },
    [slug]
  );

  return {
    generatePath,
    navigate,
  };
}