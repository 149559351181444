import GooglePayIcon from "../../../assets/logo/payments/GooglePayIcon";
import ApplePayIcon from "../../../assets/logo/payments/ApplePayIcon";
import PayPalIcon from "../../../assets/logo/payments/PayPalIcon";
import VisaIcon from "../../../assets/logo/payments/VisaIcon";
import MasterCardIcon from "../../../assets/logo/payments/MasterCardIcon";
import CustomButton from "../../buttons/CustomButton";
import {ButtonTypes} from "../../../services/exports/Interfaces";
import ReduxActions from "../../../store/ReduxActions";
import {actionCreators} from "../../../store/actions";
import {useTranslation} from "react-i18next";

export default function PaymentNoteStep({ onConfirm }) {
  const { t } = useTranslation(null, { keyPrefix: 'Components:DineIn:Stepper:PaymentNoteStep' });

  const iconClassName = "w-8 h-8";

  const payments = [
    <GooglePayIcon className={iconClassName} />,
    <ApplePayIcon className={iconClassName} />,
    <PayPalIcon className="w-7 h-7" />,
    <VisaIcon className={iconClassName} />,
    <MasterCardIcon className={iconClassName} />,
  ];

  return (
    <div>
      <div className="mb-small">
        <p className="mini">{t('text')}</p>
      </div>
      <div className="mb-small flex items-center">
        {payments.map((payment, index) => (
          <div
            className="h-[32px] w-[42px] border rounded-[4px] flex justify-center items-center mr-tiny"
            key={`payment-icon-${index}`}
          >
            {payment}
          </div>
        ))}
      </div>
      <CustomButton
        title={t('buttons.got_it')}
        buttonType={ButtonTypes.secondary}
        onClick={onConfirm}
      />
    </div>
  );
}