import React from "react";
import useTheme from "../../hooks/ui/useTheme";
import { ThemeInterface } from "../../services/exports/Interfaces";

interface Props {
  className?: string;
  style?: Object;
  color?: string;
}

export default function PlusIcon(props: Props) {
  const { className, color, style } = props;
  const theme: ThemeInterface = useTheme();
  return (
    <svg
      className={className ?? "h-4 w-4"}
      viewBox="0 0 12 12"
      fill="none"
      style={style}
    >
      <path
        d="M10.9286 4.92857H7.28571C7.16738 4.92857 7.07143 4.83262 7.07143 4.71429V1.07143C7.07143 0.479736 6.59169 0 6 0C5.40831 0 4.92857 0.479736 4.92857 1.07143V4.71429C4.92857 4.83262 4.83262 4.92857 4.71429 4.92857H1.07143C0.479736 4.92857 0 5.40831 0 6C0 6.59169 0.479736 7.07143 1.07143 7.07143H4.71429C4.83262 7.07143 4.92857 7.16738 4.92857 7.28571V10.9286C4.92857 11.5203 5.40831 12 6 12C6.59169 12 7.07143 11.5203 7.07143 10.9286V7.28571C7.07143 7.16738 7.16738 7.07143 7.28571 7.07143H10.9286C11.5203 7.07143 12 6.59169 12 6C12 5.40831 11.5203 4.92857 10.9286 4.92857Z"
        fill={color ?? theme.color.brand.inkGrey.grey_3}
      />
    </svg>
  );
}
