export default {
  sections: {
    use_cashback: {
      min_spending_limit_not_reached: 'You need minimum {{value}} points to redeem',
      balance: 'Use {{value}}p ({{moneyValue}})',
      max_spending_limit: 'Maximum spent {{value}} points per order',
    },
    expected_cashback: 'You earn <highlight>{{value}} points</highlight> with this order',
  },
  states: {
    on: 'Yes',
    off: 'No',
  },
  buttons: {
    log_in: 'Login to pay with points',
  },
};