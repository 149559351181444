import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import collect from "collect.js";
import {ORDER_METHODS, REDUX_STORE_SLICES} from "../../services/exports/Constants";
import {OrderInterface, OrderStoreInterface, ProfileInterface,} from "../../services/exports/Interfaces";

const initialOrder: Partial<OrderInterface> = {
  method: ORDER_METHODS.PICKUP,
  customer_note: "",
  delivery_street_name: null,
  delivery_street_number: null,
  delivery_city: null,
  delivery_state: null,
  delivery_country: null,
  delivery_company_name: null,
  delivery_doorbell_name: null,
  delivery_zip_code: null,
  delivery_lat: null,
  delivery_lng: null,
  order_instructions: "",
  promo: "",
  coupon_details: null,
  tip: 0,
  scheduled_for: null,
  line_items: [],
};

export default createSlice({
  name: REDUX_STORE_SLICES.ORDER,
  initialState: {
    cached_order: initialOrder,
  },
  reducers: {
    setOrder: (state: OrderStoreInterface, action) => {
      try {
        state.cached_order = action.payload;
      } catch (error) {}
    },
    updateOrder: (state: OrderStoreInterface, action) => {
      try {
        state.cached_order = { ...state.cached_order, ...action.payload };
      } catch (error) {}
    },
    resetOrder: (
      state: OrderStoreInterface,
      action: PayloadAction<ProfileInterface>,
    ) => {
      try {
        const deliveryInfo = collect({
          ...action.payload,
          method: state.cached_order?.method ?? ORDER_METHODS.PICKUP,
        })?.intersectByKeys(initialOrder)?.items;

        state.cached_order = {
          ...initialOrder,
          ...deliveryInfo,
        };
      } catch (error) {}
    },
    resetScheduledTime: (state: OrderStoreInterface, action?: PayloadAction<Partial<OrderInterface>>) => {
      state.cached_order = {
        ...state.cached_order,
        scheduled_for: action?.payload?.scheduled_for ?? null,
      }
    },
  },
});

export const props = (state: OrderStoreInterface) => state;
