import React from "react";
import useTheme from "../../hooks/ui/useTheme";
import {ButtonSizes, ButtonTypes, ThemeInterface,} from "../../services/exports/Interfaces";
import LoadingIndicator from "../loading/LoadingIndicator";
import classnames from "classnames";

export interface CustomButtonProps {
  testId?: string;
  title?: string | React.ReactNode;
  type?: "button" | "submit" | "reset";
  className?: string;
  titleClass?: string;
  buttonType?: ButtonTypes;
  buttonSize?: ButtonSizes;
  containerStyle?: string;
  fontStyle?: string;
  customGradient?: string;
  disabled?: boolean;
  loading?: boolean;
  customIcon?: any;
  onClick?: () => void;
}

export default function CustomButton(props: CustomButtonProps) {
  const {
    testId,
    title,
    type,
    className,
    titleClass,
    buttonType,
    buttonSize,
    customGradient,
    disabled,
    loading,
    customIcon,
    containerStyle,
    fontStyle,
    onClick,
  } = props;
  const theme: ThemeInterface = useTheme();

  function getLoadingColor() {
    switch (buttonType) {
      case ButtonTypes.third:
        return theme.color.text.default;
      default:
        return theme.color.text.light;
    }
  }

  function renderLoadingIndicator() {
    return loading ? (
      <div
        className={loadingIndicatorContainer}
        data-test={`${testId}-loading-indicator`}
      >
        <LoadingIndicator customColor={getLoadingColor()} lg={20} md={20} sm={20} />
      </div>
    ) : undefined;
  }

  function getBackgroundColor() {
    if (disabled) {
      return "bg-background-inkDisabled-disabled_3";
    }

    switch (buttonType) {
      case ButtonTypes.secondary:
        return "bg-brand-secondary";
      case ButtonTypes.third:
        return "bg-background-inkWhite-white_1 border border-brand-text-default";
      case ButtonTypes.forth:
        return "bg-background-inkWhite-white_1 border border-companyBrand-primary";
      case ButtonTypes.fifth:
        return "border rounded-lg px-small py-mini";
      case ButtonTypes.sixth:
        return "bg-brand-inkGrey-grey_5 p-mini rounded-lg";
      case ButtonTypes.disabled:
        return "border border-brand-inkGrey-grey_3";
      case ButtonTypes.danger:
        return "bg-brand-danger";
      case ButtonTypes.warning:
        return "bg-brand-inkWarning-warning_1";
      default:
        if (customGradient) {
          return customGradient;
        }

        return "bg-gradient-to-r from-companyBrand-gradients-primary-color_1 via-companyBrand-gradients-primary-color_1 to-companyBrand-gradients-primary-color_2";
    }
  }

  function getTitleColor() {
    switch (buttonType) {
      case ButtonTypes.third:
        return "text-brand-text-default";
      case ButtonTypes.forth:
        return "text-companyBrand-primary";
      case ButtonTypes.fifth:
        return "text-companyBrand-primary text-mini font-semibold";
      case ButtonTypes.sixth:
        return "text-brand-text-default text-mini";
      case ButtonTypes.disabled:
        return "text-brand-inkGrey-grey_3";
      case ButtonTypes.warning:
        return "text-brand-text-warning";
      default:
        return "text-white";
    }
  }

  function getContainer() {
    switch (buttonSize) {
      case ButtonSizes.small:
        return tinyContainer;
      default:
        return container;
    }
  }

  return (
    <button
      data-test={testId}
      type={type ?? 'button'}
      className={classnames(
        getContainer(),
        getBackgroundColor(),
        containerStyle ?? '',
        className
      )}
      onClick={() => (disabled ? null : onClick && onClick())}
    >
      {customIcon && !loading ? customIcon : null}
      <p
        className={classnames(
          titleStyle,
          getTitleColor(),
          titleClass,
          fontStyle ?? '',
          {
            invisible: loading,
          }
        )}
      >
        {title}
      </p>
      {renderLoadingIndicator()}
    </button>
  );
}

const container =
  "p-small rounded-md shadow-md w-full flex flex-row items-center justify-center relative shadow";

const tinyContainer = "p-xsmall rounded w-full relative";

const titleStyle = "text-center visible w-full contents capitalize semibold";

const loadingIndicatorContainer =
  "absolute inset-0 flex justify-center items-center";
