import React from "react";
import useTheme from "../../hooks/ui/useTheme";
import { ThemeInterface } from "../../services/exports/Interfaces";

interface Props {
  className?: string;
  style?: Object;
  color?: string;
}

export default function CheckmarkIcon(props: Props) {
  const { className, color, style } = props;
  const theme: ThemeInterface = useTheme();
  return (
    <svg
      className={className ?? "h-4 w-4"}
      viewBox="0 0 26 24"
      fill="none"
      style={style}
    >
      <rect
        x="22.3872"
        y="1.52588e-05"
        width="4.41049"
        height="25.5809"
        rx="2.20525"
        transform="rotate(35 22.3872 1.52588e-05)"
        fill={color ?? theme.color.brand.inkGrey.grey_3}
      />
      <rect
        x="13.0986"
        y="19.9175"
        width="4.41049"
        height="14.1136"
        rx="2.20525"
        transform="rotate(135 13.0986 19.9175)"
        fill={color ?? theme.color.brand.inkGrey.grey_3}
      />
    </svg>
  );
}
