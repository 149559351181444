import React, { useState } from "react";
import MinusIcon from "../../assets/logo/MinusIcon";
import PlusIcon from "../../assets/logo/PlusIcon";
import useTheme from "../../hooks/ui/useTheme";
import { ThemeInterface } from "../../services/exports/Interfaces";

interface Props {
  count: number;
  increase: (count: number) => void;
  decrease: (count: number) => void;
}

export default function CounterButton(props: Props) {
  const { count, increase, decrease } = props;
  const [minusHighlighted, setMinusHighlighted] = useState(false);
  const [plusHighlighted, setPlusHighlighted] = useState(false);
  const theme: ThemeInterface = useTheme();
  function onDecrease() {
    if (count <= 1) {
      return;
    }
    decrease(count - 1);
  }

  function getIconColor(active: boolean) {
    if (active) {
      return theme.color.companyBrand.primary;
    }
    return theme.color.text.default;
  }

  return (
    <div className={container}>
      <button
        onClick={onDecrease}
        onMouseOver={() => setMinusHighlighted(true)}
        onMouseLeave={() => setMinusHighlighted(false)}
        className={buttonStyle}
      >
        <MinusIcon className={iconStyle} color={getIconColor(minusHighlighted)} />
      </button>

      <h6 key={count}>{count}</h6>
      <button
        onClick={() => increase(count + 1)}
        onMouseOver={() => setPlusHighlighted(true)}
        onMouseLeave={() => setPlusHighlighted(false)}
        className={buttonStyle}
      >
        <PlusIcon className={iconStyle} color={getIconColor(plusHighlighted)} />
      </button>
    </div>
  );
}

const container =
  "w-full bg-background-inkWhite-white_1 rounded-full border border-brand-inkGrey-grey_2 flex justify-between items-center px-medium py-xsmall shadow";

const iconStyle = "h-5 w-5";

const buttonStyle = "before:relative after:content-[''] after:absolute after:-top-4 after:-bottom-4 after:-left-5 after:-right-5";

