import {useTranslation} from "react-i18next";
import {
  LineItemInterface,
  ModifierInterface,
  OrderItemInterface, ProductComboInterface,
  ProductInterface,
} from "../../services/exports/Interfaces";
import CustomCarousel, {Item} from "./CustomCarousel";
import useScreenType from "../../hooks/utility/useScreenType";
import MultiOrderProduct from "../menu/product/MultiOrderProduct";
import classnames from "classnames";
import {ORDER_PRODUCT_HEIGHT, ProductType} from "../../services/exports/Constants";
import useAlert from "../../hooks/utility/useAlert";
import useMenu from "../../hooks/menu/useMenu";
import collect from "collect.js";
import useAppMethods from "../../hooks/utility/useAppMethods";
import {useMemo} from "react";

interface Props {
  order: OrderItemInterface | null;
  className?: string;
  onProductClick?: (product: ProductInterface|ProductComboInterface|null) => void;
}

const OrderCarousel = (props: Props): JSX.Element => {
  const { t } = useTranslation(null, { keyPrefix: "Components:Carousel:OrderCarousel" });

  const {
    order,
    className,
    onProductClick,
  } = props;

  const { toggleMenuItemModal, toggleComboModal } = useAppMethods();
  const { compileProduct } = useMenu();
  const { isDesktop } = useScreenType();
  const alert = useAlert();

  const onClick = (item: LineItemInterface) => {
    const product = compileProduct(item.product_id);

    if (! product) {
      return alert.error({
        description: t("error_messages.product_does_not_exist"),
      });
    }

    if (onProductClick) {
      return onProductClick(product);
    }

    if (product.type === ProductType.Combo) {
      return toggleComboModal({
        openModal: true,
        combo: product,
        lineItem: {
          product_id: item.product_id,
          quantity: 1,
          note: item.note,
          children: item.children.map((child) => ({
            product_id: child.product_id,
            combo_item_id: child.combo_item_id,
            quantity: child.quantity,
            modifiers: collect(child.modifiers)
              .where('is_available', true)
              .map((modifier: ModifierInterface) => ({
                modifier_id: modifier.id,
                modifier_group_id: modifier.modifier_group_id,
                quantity: 1
              }))
              .toArray(),
          }))
        },
      });
    }

    toggleMenuItemModal({
      openModal: true,
      product: { id: item.product_id },
      lineItem: {
        product_id: item.product_id,
        quantity: 1,
        note: item.note,
        modifiers: collect(item.modifiers)
          .where('is_available', true)
          .map((modifier) => ({
            modifier_id: modifier.id,
            modifier_group_id: modifier.modifier_group_id,
            quantity: 1
          }))
          .toArray(),
      }
    })
  }

  const items = useMemo<Item[]>(
    () => order?.line_items?.map((item) => ({
      data: item,
      render: () => (
        <MultiOrderProduct
          product={item}
          toggleProduct={() => onClick(item)}
        />
      )
    })),
    [order?.line_items, onClick]
  )


  return (
    <div className={classnames("relative w-full", className)}>
      <CustomCarousel
        items={items}
        itemsPerSlide={isDesktop ? 3.5 : 1.25}
        slideJump={isDesktop ? 3 : 2}
        itemHeight={ORDER_PRODUCT_HEIGHT - 6}
        arrowsPosition="top"
        arrowsClassName="lg:mr-none mr-small"
        itemsClassName="lg:pl-none pl-small"
        title={<h5 className="lg:ml-none ml-small">{t("order_again")}</h5>}
        headerClassName="mb-mini"
      />
    </div>
  );
};

export default OrderCarousel;
