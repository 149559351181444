import TextInput from "../inputs/TextInput";
import { Dispatch, SetStateAction } from "react";
import Section from "./Section";
import { useTranslation } from "react-i18next";

interface Props {
  firstName: string;
  setFirstName: Dispatch<SetStateAction<string>>;
  className?: string;
}

export default function JoinPartySection(props: Props) {
  const { t } = useTranslation(undefined, {
    keyPrefix: "Components:DineIn:JoinPartySection",
  });

  const { firstName, setFirstName, className } = props;

  return (
    <Section
      className={className}
      title={t("title")}
      description={t("description")}
    >
      <TextInput
        value={firstName}
        onChange={setFirstName}
        placeholder={t("form.first_name.placeholder")}
        autoFocus
      />
    </Section>
  );
}
