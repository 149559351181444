import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import LoadingPage from '../../loading/LoadingPage';
import AppFocusObserver from './AppFocusObserver';
import useCompanySlug from '../../../hooks/global/useCompanySlug';
import useInitialData from '../../../hooks/global/useInitialData';

interface Props {
    children: any;
    loader?: JSX.Element;
}

export default function CompanySlugObserver(props: Props): JSX.Element {
    const { children, loader } = props;
    const { loading: appLoading } = useInitialData();
    const { slug } = useParams();
    const { loading, updateSlug } = useCompanySlug();

    useEffect(() => {
        updateSlug(slug);
    }, [slug]);

    const render = () => {
        if (loading && !appLoading) {
            return loader ?? <LoadingPage />;
        }

        return <AppFocusObserver>{children}</AppFocusObserver>;
    };

    return render();
}
