import React from "react";
import Theme from "../../components-library/Theme";
import classnames from "classnames";

interface Props {
  className?: string;
  color?: string;
}

export default function BoxIcon(props: Props) {
  const { className, color } = props;

  return (
    <svg
      className={classnames('h-6 w-6', className)}
      viewBox="0 0 22 25"
      fill="none"
    >
      <path
        d="M9.6 21.41V13.19L2.4 9.02V17.24L9.6 21.41ZM12 21.41L19.2 17.24V9.02L12 13.19V21.41ZM9.6 24.17L1.2 19.34C0.82 19.12 0.525 18.83 0.315 18.47C0.105 18.11 0 17.71 0 17.27V7.73C0 7.29 0.105 6.89 0.315 6.53C0.525 6.17 0.82 5.88 1.2 5.66L9.6 0.83C9.98 0.61 10.38 0.5 10.8 0.5C11.22 0.5 11.62 0.61 12 0.83L20.4 5.66C20.78 5.88 21.075 6.17 21.285 6.53C21.495 6.89 21.6 7.29 21.6 7.73V17.27C21.6 17.71 21.495 18.11 21.285 18.47C21.075 18.83 20.78 19.12 20.4 19.34L12 24.17C11.62 24.39 11.22 24.5 10.8 24.5C10.38 24.5 9.98 24.39 9.6 24.17ZM15.6 8.33L17.91 7.01L10.8 2.9L8.46 4.25L15.6 8.33ZM10.8 11.12L13.14 9.77L6.03 5.66L3.69 7.01L10.8 11.12Z"
        fill={color ?? '#5F6368'}
      />
    </svg>
  );
}
