import {ORDER_METHODS, RoomServiceLocationType} from "../../../../../services/exports/Constants";

export default {
  labels: {
    today: 'Heute',
  },
  [RoomServiceLocationType.RoomService]: {
    title: "Zimmerservice",
    description: {
      asap: "Fertig in {{time}} min bei {{location}}",
      scheduled: "{{date}} um {{time}} bei {{location}}",
    },
    details_label: 'Zimmernummer',
  },
  [RoomServiceLocationType.TableService]: {
    title: "Vor Ort",
    description: {
      asap: "Fertig in {{time}} min bei {{location}}",
      scheduled: "{{date}} um {{time}} bei {{location}}",
    },
    details_label: 'Tischnummer',
  },
  [ORDER_METHODS.PICKUP]: {
    title: "Abholung",
    description: {
      asap: "Fertig in {{time}} min bei {{location}}",
      scheduled: "{{date}} um {{time}} bei {{location}}",
    },
  },
  [ORDER_METHODS.DELIVERY]: {
    title: "Lieferung",
    description: {
      asap: "Fertig in {{time}} min",
      scheduled: "{{date}} um {{time}}",
    },
  },
};
