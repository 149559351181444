import { useRef } from "react";
import Carousel from "react-multi-carousel";
import classnames from "classnames";
import { useIntersection } from "../../hooks/utility/useIntersection";
import { StoreInterface } from "../../store/types";
import { useSelector } from "react-redux";
import SeoResource from "../../services/resources/SeoResource";

interface Props {
    pictures: string[];
    className?: string;
}

export default function PicturesSlider(props: Props) {
    const { pictures, className } = props;

    const containerRef = useRef<HTMLDivElement>(null);

    const { company } = useSelector((state: StoreInterface) => state.initialData);

    const isVisible = useIntersection(containerRef, {
        rootMargin: "0px",
        threshold: 0,
    });

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
            slidesToSlide: 1,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            slidesToSlide: 1,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1,
        },
    };

    const pictureLength: number = pictures?.length ?? 0;

    return (
        pictureLength > 0 && (
            <div ref={containerRef} className={classnames(sliderHeight, className)}>
                <Carousel
                    responsive={responsive}
                    autoPlay={pictureLength > 1 && isVisible}
                    infinite={pictureLength > 1}
                    autoPlaySpeed={5000}
                    showDots={false}
                    arrows={false}
                    sliderClass={sliderHeight}
                    itemClass={sliderHeight}
                    containerClass={"w-full h-full"}
                >
                    {pictures?.map((picture: string) => (
                        <div className={carouselItemContainer} key={picture}>
                            <img
                                src={picture}
                                alt={SeoResource.toAltText(`${company?.name} image`)}
                                className={carouselImageStyle}
                            />
                        </div>
                    ))}
                </Carousel>
            </div>
        )
    );
}

const carouselItemContainer = "w-full h-full relative";

const carouselImageStyle = "w-full h-full object-cover";

const sliderHeight = "h-full";
