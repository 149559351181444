import { ProductInterface } from "../../services/exports/Interfaces";
import CustomCarousel, {Item} from "./CustomCarousel";
import useScreenType from "../../hooks/utility/useScreenType";
import { useTranslation } from "react-i18next";
import SeoResource from "../../services/resources/SeoResource";
import {useMemo} from "react";

interface Props {
  products: ProductInterface[] | null;
  hideTitle?: boolean;
}

const ProductsCarousel = (props: Props): JSX.Element | undefined => {
  const { products, hideTitle } = props;

  const { t } = useTranslation(null, { keyPrefix: 'Components:Carousel:ProductsCarousel' });

  const { isDesktop } = useScreenType();

  const ProductWithImage = ({ item: product, itemHeight }): JSX.Element => {
    const nameHeight = 40;

    return (
      <div className="rounded-[8px] border overflow-hidden bg-background-inkWhite-white_1">
        <div style={{ height: itemHeight - nameHeight }}>
          <img
            src={product?.thumbnail_url}
            alt={SeoResource.toAltText(product?.name)}
            className="h-full w-full object-cover"
          />
        </div>
        <div
          className="flex w-full justify-center items-center px-tiny"
          style={{ height: nameHeight }}
        >
          <p className="tiny text-center h-fit line-clamp-1 break-words">
            {product?.name}
          </p>
        </div>
      </div>
    );
  };

  const ProductWithoutImage = ({ item: product }): JSX.Element => {
    return (
      <div className="h-full w-full flex flex-col justify-center items-center px-mini bg-background-inkWhite-white_1 rounded-[8px] border overflow-hidden">
        <p className="tiny text-center line-clamp-1 break-words">
          {product?.name}
        </p>
        {product?.description && (
          <p className="tiny text-center text-brand-text-grey line-clamp-2 break-words mt-tiny">
            {product?.description}
          </p>
        )}
      </div>
    );
  };

  const items = useMemo<Item[]>(
    () => products?.map((item) => ({
      data: item,
      render: (index, itemHeight) => item.thumbnail_url
        ? <ProductWithImage item={item} itemHeight={itemHeight} />
        : <ProductWithoutImage item={item} />
    })),
    [products]
  )

  return (
    <>
      {!hideTitle && (
        <div className="mb-mini">
          <p className="mini">{t("applies_to_articles")}</p>
        </div>
      )}
      <CustomCarousel
        items={items}
        itemsPerSlide={isDesktop ? 3 : 2}
      />
    </>
  );
};

export default ProductsCarousel;
