import { ORDER_METHODS } from "../../../../../services/exports/Constants";

export default {
  more_info: 'Mehr Info',
  pickup: '{{time}} min Abholung: ',
  delivery: '{{time}} min Lieferung: ',
  labels: {
    available: 'Verfügbar',
    unavailable: 'Nicht verfügbar',
  },
  buttons: {
    change_store: 'Store wechseln',
  },
  promo_code_banner: {
    single_use: '🔥 {{discount}} Rabatt - Code: {{code}}',
    multi_use: '🔥 {{discount}} Rabatt auf {{count}} Bestellungen - Code: {{code}}',
  },
  order_method_info: {
    fee: {
      [ORDER_METHODS.PICKUP]: 'Abholung: {{fee}}',
      [ORDER_METHODS.DELIVERY]: 'Lieferung: {{fee}}',
      [ORDER_METHODS.ROOM_SERVICE]: 'Vor Ort: {{fee}}',
      [ORDER_METHODS.DINE_IN]: 'Vor Ort: {{fee}}',
    },
    min_order: 'MBW: {{minOrder}}',
  },
};
