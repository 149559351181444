import {ErrorHandlerInterface, PurchaseDataInterface} from "../exports/Interfaces";
import BackendApiClient from "./BackendApiClient";
import { Response } from "../exports/Types";

class PurchaseManager {
  public purchase = async (
    orderId: number,
    data: PurchaseDataInterface,
    errorHandlers?: ErrorHandlerInterface
  ): Promise<Response<any>> =>
    BackendApiClient.request({
      method: "POST",
      url: `/orders/${orderId}/checkout`,
      data,
    }, false, errorHandlers);
}

export default new PurchaseManager();
