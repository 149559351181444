import React from "react";
import useTheme from "../../hooks/ui/useTheme";
import { ThemeInterface } from "../../services/exports/Interfaces";

interface Props {
  className?: string;
  style?: Object;
  color?: string;
}

export default function CreditCardIcon(props: Props) {
  const { className, color, style } = props;
  const theme: ThemeInterface = useTheme();
  return (
    <svg
      className={className ?? "h-4 w-4"}
      viewBox="0 0 36 28"
      fill="none"
      style={style}
    >
      <path
        d="M36 2.75V25.25C36 26.0167 35.7333 26.6667 35.2 27.2C34.6667 27.7333 34.0167 28 33.25 28H2.75C1.98333 28 1.33333 27.7333 0.8 27.2C0.266667 26.6667 0 26.0167 0 25.25V2.75C0 1.98333 0.266667 1.33333 0.8 0.8C1.33333 0.266667 1.98333 0 2.75 0H33.25C34.0167 0 34.6667 0.266667 35.2 0.8C35.7333 1.33333 36 1.98333 36 2.75ZM1.55 7.85H34.45V2.75C34.45 2.45 34.325 2.175 34.075 1.925C33.825 1.675 33.55 1.55 33.25 1.55H2.75C2.45 1.55 2.175 1.675 1.925 1.925C1.675 2.175 1.55 2.45 1.55 2.75V7.85ZM1.55 11.85V25.25C1.55 25.55 1.675 25.825 1.925 26.075C2.175 26.325 2.45 26.45 2.75 26.45H33.25C33.55 26.45 33.825 26.325 34.075 26.075C34.325 25.825 34.45 25.55 34.45 25.25V11.85H1.55ZM1.55 26.45V1.55V26.45Z"
        fill={color ?? theme.color.text.default}
      />
    </svg>
  );
}
