import {useEffect, useState} from "react";

export default function useFocus(
    onFocus: () => void,
    onBlur?: () => void,
    ignoreInitial?: boolean,
): boolean {
    const [focused, setFocus] = useState(true);

    const handleFocus = () => {
        setFocus(true);
        onFocus();
    }
    const handleBlur = () => {
        setFocus(false);
        onBlur && onBlur();
    }

    useEffect(() => {
        if (! ignoreInitial) {
            if (focused) {
                onFocus();
            }

            return () => onBlur && onBlur();
        }
    }, []);

    useEffect(() => {
        window.addEventListener("focus", handleFocus);
        window.addEventListener("blur", handleBlur);

        return () => {
            window.removeEventListener("focus", handleFocus);
            window.removeEventListener("blur", handleBlur);
        };
    }, []);

    return focused;
}