import {LineItemInterface} from "../../services/exports/Interfaces";
import classnames from "classnames";
import {collect} from "collect.js";
import useAppMethods from "../../hooks/utility/useAppMethods";

interface Props {
  data: LineItemInterface;
  variant?: 'primary' | 'secondary';
  className?: string;
}

export default function LineItem(props: Props) {
  const {data, variant, className} = props;

  const modifiers = collect(data?.modifiers).implode('name', ' · ');

  const { formatCurrency } = useAppMethods();

  return (
    <div className={classnames(className, {
      'text-brand-inkGrey-grey_4': variant === 'secondary'
    })}>
      <div className="flex justify-between">
        <p className="text-mini truncate">{data?.quantity}x {data?.name}</p>
        <div className="flex justify-end">
          {data?.discount > 0 && (
            <p className="text-right text-tiny line-through mr-mini">{formatCurrency(data.original_price)}</p>
          )}
          <p className="text-right text-tiny">{formatCurrency(data.actual_price)}</p>
        </div>
      </div>
      {modifiers?.length > 0 && (
        <p className="text-tiny mt-tiny text-brand-inkGrey-grey_4">{modifiers}</p>
      )}
    </div>
  );
}