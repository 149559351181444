import { useMemo } from "react";
import {
  CategoryInterface,
  ProductComboInterface,
  ProductInterface,
} from "../../../services/exports/Interfaces";
import MultiSelectProduct from "./MultiSelectProduct";
import SingleSelectProduct from "./SingleSelectProduct";

interface Props {
  product: ProductInterface|ProductComboInterface;
  category: CategoryInterface;
  className?: string;
  toggleProduct?: () => void;
}

export default function ProductItem(props: Props) {
  const { product, category, className, toggleProduct } = props;

  const renderMultiSelectProduct = useMemo(
    () => (
      <MultiSelectProduct
        product={product}
        className={className}
        toggleProduct={toggleProduct}
      />
    ),
    [product, className, toggleProduct]
  );

  const renderSingleSelectProduct = useMemo(
    () => (
      <SingleSelectProduct
        product={product}
        className={className}
        toggleProduct={toggleProduct}
      />
    ),
    [product, className, toggleProduct]
  );

  if (category?.id > 0) {
    return renderMultiSelectProduct;
  }

  return renderSingleSelectProduct;
}
