import React from 'react';
import BaseCurrencyInput from 'react-currency-input-field';
import {IntlConfig} from "react-currency-input-field/src/components/CurrencyInputProps";
import classnames from "classnames";
import useStore from "../../hooks/global/useStore";
import HelperMethods from "../../services/exports/HelperMethods";

interface Props {
  id?: string;
  value: string | number | undefined;
  onChange: (value: string | number | undefined) => void;
  disabled?: boolean;
  intlConfig?: IntlConfig;
  className?: string;
}

export default function CurrencyInput(props: Props) {
  const {
    value,
    onChange,
    disabled,
    intlConfig,
    id,
    className,
  } = props;

  const { company } = useStore()?.initialData;

  const currency = company?.currency ?? 'EUR';

  return (
    <BaseCurrencyInput
      id={id}
      value={value}
      disabled={disabled}
      defaultValue="0.00"
      onValueChange={onChange}
      decimalSeparator="."
      disableGroupSeparators
      intlConfig={intlConfig ?? {
        locale: 'en',
        currency,
      }}
      transformRawValue={(value) => value.replace(',', '.')}
      className={classnames('w-full p-xsmall rounded bg-brand-text-disabled font-sm', className)}
    />
  );
}
