import React from 'react';
import PageStyle from '../../../styles/pages/cookie-policy/DE';

export default function DE() {
    return (
        <div className="p-small">
            <PageStyle />
            <h1 className="c28 break-words" id="h.xsled2qv7pd"><span className="c16 c27">Verwendung von Cookies auf der Website (das Gesch&auml;ft)</span>
            </h1>
            <p className="c23"><span className="c3">Cookies sind kleine Textdateien, die Informationen &uuml;ber das von einem Besucher verwendete Ger&auml;t liefern. Wenn Cookies verwendet werden, werden sie auf Ihr Ger&auml;t heruntergeladen und auf Ihrem Ger&auml;t gespeichert. Die folgende Tabelle erkl&auml;rt, welche Cookies diese Website verwendet und warum</span>
            </p><a id="t.bbe244e66efb21dba430229a4ab040631eb0f9b6"></a><a id="t.0"></a>
            <table className="c2">
                <tr className="c21">
                    <td className="c4" colSpan={1} rowSpan={1}><p className="c0"><span className="c17">Cookie</span></p>
                    </td>
                    <td className="c8" colSpan={1} rowSpan={1}><p className="c0"><span className="c17">Name</span></p>
                    </td>
                    <td className="c11" colSpan={1} rowSpan={1}><p className="c0"><span className="c17">Zweck</span></p>
                    </td>
                    <td className="c15" colSpan={1} rowSpan={1}><p className="c0"><span className="c17">Dauer</span></p>
                    </td>
                    <td className="c7" colSpan={1} rowSpan={1}><p className="c0"><span className="c17">Kategorie</span>
                    </p></td>
                </tr>
                <tr className="c20">
                    <td className="c4" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">Stripe</span></p>
                    </td>
                    <td className="c8" colSpan={1} rowSpan={1}><p className="c0"><span
                        className="c1 c16">__stripe_mid</span></p>
                        <p className="c0"><span className="c1 c16">__stripe_orig_props</span></p>
                        <p className="c0"><span className="c1">__stripe_sid</span></p></td>
                    <td className="c11" colSpan={1} rowSpan={1}><p className="c0"><span
                        className="c1">Stripe wird f&uuml;r Kartenzahlungen verwendet. Stripe verwendet ein Cookie, um sich zu merken, wer Sie sind, und um es der Website zu erm&ouml;glichen, Zahlungen zu verarbeiten, ohne Bezahlkarteninformationen auf ihren eigenen Servern zu speichern.</span>
                    </p></td>
                    <td className="c15" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">1 Jahr</span></p>
                    </td>
                    <td className="c7" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">Unbedingt notwendig</span>
                    </p></td>
                </tr>
                <tr className="c10">
                    <td className="c4" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">FoodAmgios</span>
                    </p></td>
                    <td className="c8" colSpan={1} rowSpan={1}><p className="c0"><span
                        className="c1">foodamigosauth</span></p></td>
                    <td className="c11" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">Dieses Cookie wird verwendet, um die Information zu speichern, dass der Benutzer sich angemeldet hat.</span>
                    </p></td>
                    <td className="c15" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">6 Monate</span>
                    </p></td>
                    <td className="c7" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">Funktional</span>
                    </p></td>
                </tr>
                <tr className="c10">
                    <td className="c4" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">FoodAmigos</span>
                    </p></td>
                    <td className="c8" colSpan={1} rowSpan={1}><p className="c0"><span
                        className="c1">cookieconsent</span></p></td>
                    <td className="c11" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">Dieses Cookie wird verwendet, um Ihre Einstellungen in Bezug auf Cookies zu speichern.</span>
                    </p></td>
                    <td className="c15" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">6 Monate</span>
                    </p></td>
                    <td className="c7" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">Unbedingt notwendig</span>
                    </p></td>
                </tr>
                <tr className="c10">
                    <td className="c4" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">FoodAmigos</span>
                    </p></td>
                    <td className="c8" colSpan={1} rowSpan={1}><p className="c0"><span
                        className="c1">foodamigosauth</span></p></td>
                    <td className="c11" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">Dieses Cookie wird verwendet, um sich zu erinnern, dass der Benutzer sich angemeldet hat.</span>
                    </p></td>
                    <td className="c15" colSpan={1} rowSpan={1}><p className="c0"><span
                        className="c1">Sessi&oacute;</span></p></td>
                    <td className="c7" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">Unbedingt notwendig</span>
                    </p></td>
                </tr>
                <tr className="c10">
                    <td className="c4" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">FoodAmigos</span>
                    </p></td>
                    <td className="c8" colSpan={1} rowSpan={1}><p className="c0"><span
                        className="c1">foodamigos-token</span></p></td>
                    <td className="c11" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">Dieses Cookie wird zur Hilfe bei der Authentifizierung auf bestimmten Browsern verwendet</span>
                    </p></td>
                    <td className="c15" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">6 Monate</span>
                    </p></td>
                    <td className="c7" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">Funktional</span>
                    </p></td>
                </tr>
                <tr className="c10">
                    <td className="c4" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">FoodAmigos</span>
                    </p></td>
                    <td className="c8" colSpan={1} rowSpan={1}><p className="c0"><span
                        className="c1">foodamigos-token</span></p></td>
                    <td className="c11" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">Dieses Cookie wird zur Hilfe bei der Authentifizierung auf bestimmten Browsern verwendet</span>
                    </p></td>
                    <td className="c15" colSpan={1} rowSpan={1}><p className="c0"><span
                        className="c1">Sessi&oacute;</span></p></td>
                    <td className="c7" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">Unbedingt notwendig</span>
                    </p></td>
                </tr>
                <tr className="c12">
                    <td className="c4" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">FoodAmigos</span>
                    </p></td>
                    <td className="c8" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">$fa.wo.b[*]</span>
                    </p></td>
                    <td className="c11" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">Dieses Cookie wird verwendet um sich an die Artikel in Ihrem Warenkorb zu erinnern.</span>
                    </p></td>
                    <td className="c15" colSpan={1} rowSpan={1}><p className="c0"><span
                        className="c1">Browser-gesteuert</span></p></td>
                    <td className="c7" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">Unbedingt notwendig</span>
                    </p></td>
                </tr>
                <tr className="c12">
                    <td className="c4" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">FoodAmigos</span>
                    </p></td>
                    <td className="c8" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">logLevel:*</span>
                    </p></td>
                    <td className="c11" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">Dieses Cookie wird f&uuml;r Fehlerbeseitigungszwecke verwendet.</span>
                    </p></td>
                    <td className="c15" colSpan={1} rowSpan={1}><p className="c0"><span
                        className="c1">Browser-gesteuert</span></p></td>
                    <td className="c7" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">Unbedingt notwendig</span>
                    </p></td>
                </tr>
                <tr className="c5">
                    <td className="c4" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">FoodAmgios</span>
                    </p></td>
                    <td className="c8" colSpan={1} rowSpan={1}><p className="c0"><span
                        className="c1">foodamigos-delivery-locations</span></p>
                    </td>
                    <td className="c11" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">Diese wesentlichen Informationen beziehen sich auf Ihre Lieferorte und werden im Browser gespeichert, um eine unn&ouml;tige Datennutzung zu vermeiden.</span>
                    </p></td>
                    <td className="c15" colSpan={1} rowSpan={1}><p className="c0"><span
                        className="c1">Browser-gesteuert</span></p></td>
                    <td className="c7" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">Unbedingt notwendig</span>
                    </p></td>
                </tr>
                <tr className="c5">
                    <td className="c4" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">FoodAmigos</span>
                    </p></td>
                    <td className="c8" colSpan={1} rowSpan={1}><p className="c0"><span
                        className="c1">foodamigos-customer</span></p></td>
                    <td className="c11" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">Diese wesentlichen Informationen beziehen sich auf Ihre Kundendaten und werden im Browser gespeichert, um eine unn&ouml;tige Datennutzung zu vermeiden.</span>
                    </p></td>
                    <td className="c15" colSpan={1} rowSpan={1}><p className="c0"><span
                        className="c1">Browser-gesteuert</span></p></td>
                    <td className="c7" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">Unbedingt notwendig</span>
                    </p></td>
                </tr>
                <tr className="c5">
                    <td className="c4" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">FoodAmigos</span>
                    </p></td>
                    <td className="c8" colSpan={1} rowSpan={1}><p className="c0"><span
                        className="c1">foodamigos-pickup-restaurant-*</span></p>
                    </td>
                    <td className="c11" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">Diese wesentlichen Informationen beziehen sich auf die Details des Gesch&auml;fts und werden im Browser gespeichert, um unn&ouml;tige Datennutzung zu vermeiden.</span>
                    </p></td>
                    <td className="c15" colSpan={1} rowSpan={1}><p className="c0"><span
                        className="c1">Browser-gesteuert</span></p></td>
                    <td className="c7" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">Unbedingt notwendig</span>
                    </p></td>
                </tr>
                <tr className="c25">
                    <td className="c4" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">FoodAmigos</span>
                    </p></td>
                    <td className="c8" colSpan={1} rowSpan={1}><p className="c0"><span
                        className="c1">foodamigos-location</span></p></td>
                    <td className="c11" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">Diese wesentlichen Informationen beziehen sich darauf wo in der Welt Ihr Standort ist, so dass relevante Standorte zuerst angezeigt werden, und werden im Browser gespeichert, um unn&ouml;tige Datennutzung zu vermeiden.</span>
                    </p></td>
                    <td className="c15" colSpan={1} rowSpan={1}><p className="c0"><span
                        className="c1">Browser-gesteuert</span></p></td>
                    <td className="c7" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">Unbedingt notwendig</span>
                    </p></td>
                </tr>
                <tr className="c10">
                    <td className="c4" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">FoodAmigos</span>
                    </p></td>
                    <td className="c8" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">$fd.wo.b.om</span>
                    </p></td>
                    <td className="c11" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">Dieses Cookie wird verwendet, wenn Sie bei der Zahlung zu 3D-Sicherheitszwecken umgeleitet werden.</span>
                    </p></td>
                    <td className="c15" colSpan={1} rowSpan={1}><p className="c0"><span
                        className="c1">Sessi&oacute;</span></p></td>
                    <td className="c7" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">Unbedingt notwendig</span>
                    </p></td>
                </tr>
                <tr className="c5">
                    <td className="c4" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">FoodAmigos</span>
                    </p></td>
                    <td className="c8" colSpan={1} rowSpan={1}><p className="c0"><span
                        className="c1">foodamigos-current-language</span></p>
                    </td>
                    <td className="c11" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">Diese wesentlichen Informationen beziehen sich auf Ihre Spracheinstellungen und werden im Browser gespeichert, um unn&ouml;tige Datenverwendung zu vermeiden.</span>
                    </p></td>
                    <td className="c15" colSpan={1} rowSpan={1}><p className="c0"><span
                        className="c1">Browser-gesteuert</span></p></td>
                    <td className="c7" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">Unbedingt notwendig</span>
                    </p></td>
                </tr>
                <tr className="c12">
                    <td className="c4" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">FoodAmigos</span>
                    </p></td>
                    <td className="c8" colSpan={1} rowSpan={1}><p className="c0"><span
                        className="c1">foodamigos-test-key</span></p></td>
                    <td className="c11" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">Dieses Cookie wird f&uuml;r Fehlerbeseitigungs- und Testzwecke verwendet</span>
                    </p></td>
                    <td className="c15" colSpan={1} rowSpan={1}><p className="c0"><span
                        className="c1">Browser-gesteuert</span></p></td>
                    <td className="c7" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">Unbedingt notwendig</span>
                    </p></td>
                </tr>
                <tr className="c5">
                    <td className="c4" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">FoodAmigos</span>
                    </p></td>
                    <td className="c8" colSpan={1} rowSpan={1}><p className="c0"><span
                        className="c1">foodamigos-app-config</span></p></td>
                    <td className="c11" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">Diese wesentlichen Informationen beziehen sich auf die Details des Gesch&auml;fts und werden im Browser gespeichert, um eine unn&ouml;tige Datennutzung zu vermeiden.</span>
                    </p></td>
                    <td className="c15" colSpan={1} rowSpan={1}><p className="c0"><span
                        className="c1">Browser-gesteuert</span></p></td>
                    <td className="c7" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">Unbedingt notwendig</span>
                    </p></td>
                </tr>
                <tr className="c20">
                    <td className="c4" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">FoodAmigos</span>
                    </p></td>
                    <td className="c8" colSpan={1} rowSpan={1}><p className="c0"><span
                        className="c1">foodamigos-last-url</span></p></td>
                    <td className="c11" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">Dieses Cookie wird verwendet um sich daran zu erinnern, in welchem Schritt des Bestellvorgangs Sie sich befanden, als Sie die Seite verlassen haben, so dass Sie bei Ihrer R&uuml;ckkehr dort weitermachen k&ouml;nnen, wo Sie aufgeh&ouml;rt haben.</span>
                    </p></td>
                    <td className="c15" colSpan={1} rowSpan={1}><p className="c0"><span
                        className="c1">Browser-gesteuert</span></p></td>
                    <td className="c7" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">Unbedingt notwendig</span>
                    </p></td>
                </tr>
                <tr className="c12">
                    <td className="c4" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">FoodAmigos</span>
                    </p></td>
                    <td className="c8" colSpan={1} rowSpan={1}><p className="c0"><span
                        className="c1">foodamigos-last-url-expiry</span></p></td>
                    <td className="c11" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">Dieses Cookie wird verwendet, um die letzte url ablaufen zu lassen</span>
                    </p></td>
                    <td className="c15" colSpan={1} rowSpan={1}><p className="c0"><span
                        className="c1">Browser-gesteuert</span></p></td>
                    <td className="c7" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">Unbedingt notwendig</span>
                    </p></td>
                </tr>
                <tr className="c5">
                    <td className="c4" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">FoodAmigos</span>
                    </p></td>
                    <td className="c8" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">$fd.wo.pa</span>
                    </p></td>
                    <td className="c11" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">Dieses Cookie wird verwendet, um Benutzerzahlungen zwischenzuspeichern. Diese Informationen werden bei der Abmeldung gel&ouml;scht.</span>
                    </p></td>
                    <td className="c15" colSpan={1} rowSpan={1}><p className="c0"><span
                        className="c1">Sessi&oacute;</span></p></td>
                    <td className="c7" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">Unbedingt notwendig</span>
                    </p></td>
                </tr>
                <tr className="c10">
                    <td className="c4" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">FoodAmigos</span>
                    </p></td>
                    <td className="c8" colSpan={1} rowSpan={1}><p className="c0"><span
                        className="c1">foodamigos-device-id</span></p></td>
                    <td className="c11" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">Dieses Cookie wird zu Protokollierungs-, Leistungs- und Fehlerbeseitigungszwecken verwendet.</span>
                    </p></td>
                    <td className="c15" colSpan={1} rowSpan={1}><p className="c0"><span
                        className="c1">Browser-gesteuert</span></p></td>
                    <td className="c7" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">Unbedingt notwendig</span>
                    </p></td>
                </tr>
                <tr className="c10">
                    <td className="c4" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">FoodAmigos</span>
                    </p></td>
                    <td className="c8" colSpan={1} rowSpan={1}><p className="c0"><span
                        className="c1">foodamigos-redirect</span></p></td>
                    <td className="c11" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">Dieses Cookie wird verwendet, um Ihre Auswahl zu verarbeiten, ob Sie auf die mobile App weitergeleitet werden wollen oder nicht.</span>
                    </p></td>
                    <td className="c15" colSpan={1} rowSpan={1}><p className="c0"><span
                        className="c1">Browser-gesteuert</span></p></td>
                    <td className="c7" colSpan={1} rowSpan={1}><p className="c0"><span className="c1">Unbedingt notwendig</span>
                    </p></td>
                </tr>
            </table>
            <p className="c23"><span
                className="c3">Wenn Sie alle nicht unbedingt erforderlichen Cookies ablehnen, k&ouml;nnen Sie die Vorz&uuml;ge der foodamigos-Website m&ouml;glicherweise nicht in vollem Umfang nutzen.</span>
            </p>
            <h2 className="c18" id="h.1je9qhgd13ib"><span
                className="c24 c16">Wie kann ich meine Cookie-Einstellungen &auml;ndern?</span>
            </h2>
            <p className="c23"><span className="c3">Sie k&ouml;nnen Ihre Cookie-Einstellungen jederzeit &uuml;ber die Cookie-Einstellungen &auml;ndern. Sie k&ouml;nnen dann die Schieberegler auf &quot;Ein&quot; oder &quot;Aus&quot; stellen und dann auf &quot;Fertig&quot; klicken. M&ouml;glicherweise m&uuml;ssen Sie Ihre Seite aktualisieren, damit die Einstellungen wirksam werden.</span>
            </p>
            <p className="c23"><span className="c3">Sie k&ouml;nnen Cookies jederzeit l&ouml;schen oder konfigurieren, indem Sie Ihren Internet-Browser konfigurieren. Bitte besuchen Sie die speziellen Hilfeabschnitte Ihres Browsers, um Informationen &uuml;ber die Cookie-Einstellungen zu erhalten:</span>
            </p>
            <ul className="c29 lst-kix_2skaq8gcne7c-0 start">
                <li className="c23 c26 li-bullet-0"><span className="c6"><a className="c9"
                                                                            href="https://www.google.com/url?q=https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies&amp;sa=D&amp;source=editors&amp;ust=1656336351257170&amp;usg=AOvVaw1bMqOgpA_0ydea1S3Vi3v-">Internet Explorer</a></span>
                </li>
                <li className="c23 c26 li-bullet-0"><span className="c6"><a className="c9"
                                                                            href="https://www.google.com/url?q=https://support.google.com/chrome/answer/95647?hl%3Den%26ref_topic%3D14666&amp;sa=D&amp;source=editors&amp;ust=1656336351257676&amp;usg=AOvVaw3tE3e6fMmc_PDYYLUiPHuT">Chrome</a></span>
                </li>
                <li className="c23 c26 li-bullet-0"><span className="c6"><a className="c9"
                                                                            href="https://www.google.com/url?q=https://support.mozilla.com/en-US/kb/cookies-information-websites-store-on-your-computer&amp;sa=D&amp;source=editors&amp;ust=1656336351258065&amp;usg=AOvVaw1CU3ojVG4AM25K72swN-_E">Firefox</a></span>
                </li>
                <li className="c23 c26 li-bullet-0"><span className="c6"><a className="c9"
                                                                            href="https://www.google.com/url?q=https://support.apple.com/en-ie/guide/safari/manage-cookies-and-website-data-sfri11471/mac&amp;sa=D&amp;source=editors&amp;ust=1656336351258428&amp;usg=AOvVaw0FNrJFGkBPqq8bYvHFdqjc">Safari web</a></span><span
                    className="c22">&nbsp;und </span><span className="c6"><a className="c9"
                                                                             href="https://www.google.com/url?q=https://support.apple.com/en-ie/HT201265&amp;sa=D&amp;source=editors&amp;ust=1656336351258673&amp;usg=AOvVaw3yVnLNyDC8ZpTce-BERC1u">Safari f&uuml;r iOS</a></span>
                </li>
            </ul>
            <p className="c23"><span className="c3">Um Informationen zu anderen Browsern zu finden, besuchen Sie die Website des Browser-Entwicklers.</span>
            </p>
            <h2 className="c18" id="h.3fvl40ejn6uh"><span className="c16 c24">Angaben zum Verantwortlichen</span></h2>
            <p className="c23"><span className="c22">Der Verantwortliche ist </span><span
                className="c14">das Gesch&auml;ft </span><span
                className="c22">(&quot;</span><span className="c14">wir</span><span
                className="c22">&quot;, &quot;</span><span className="c14">uns</span><span
                className="c22">&quot; oder &quot;</span><span className="c14">unser</span><span className="c3">&quot;). Wir k&ouml;nnen &uuml;ber die auf unserer Website angegebenen Kontaktdaten kontaktiert werden.</span>
            </p>
            <h2 className="c18" id="h.3e0f8qju5yps"><span className="c24 c16">Kontaktieren Sie uns</span></h2>
            <p className="c23"><span className="c3">F&uuml;r weitere Informationen zu dieser Datenschutzerkl&auml;rung oder zur Verarbeitung Ihrer personenbezogenen Daten durch uns oder in unserem Namen kontaktieren Sie uns bitte &uuml;ber die auf unserer Website angegebenen Kontaktdaten.</span>
            </p>
            <p className="c23"><span
                className="c22">Sie k&ouml;nnen bei der Datenschutzkommission eine Beschwerde &uuml;ber die Einhaltung der Datenschutzgesetze durch uns einreichen, wir bitten Sie jedoch, sich zun&auml;chst mit uns in Verbindung zu setzen, um uns Gelegenheit zu geben, auf Ihr Anliegen einzugehen.</span>
            </p>
        </div>
    );
}