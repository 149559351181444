import {createGlobalStyle} from "styled-components";

const pageStyle = createGlobalStyle`
  .lst-kix_obyyghjgpuql-6 > li:before {
    content: "\\0025cf  "
  }

  .lst-kix_obyyghjgpuql-7 > li:before {
    content: "\\0025cb  "
  }

  .lst-kix_obyyghjgpuql-8 > li:before {
    content: "\\0025a0  "
  }

  ul.lst-kix_stipjsb61sk8-6 {
    list-style-type: none
  }

  ul.lst-kix_stipjsb61sk8-5 {
    list-style-type: none
  }

  .lst-kix_izljxf9wdcgj-0 > li:before {
    content: "\\0025cf  "
  }

  .lst-kix_izljxf9wdcgj-1 > li:before {
    content: "\\0025cb  "
  }

  ul.lst-kix_stipjsb61sk8-8 {
    list-style-type: none
  }

  .lst-kix_stipjsb61sk8-1 > li:before {
    content: "\\0025cb  "
  }

  ul.lst-kix_stipjsb61sk8-7 {
    list-style-type: none
  }

  .lst-kix_db0zr17d8gnt-7 > li:before {
    content: "\\0025cb  "
  }

  .lst-kix_izljxf9wdcgj-3 > li:before {
    content: "\\0025cf  "
  }

  .lst-kix_stipjsb61sk8-0 > li:before {
    content: "\\0025cf  "
  }

  .lst-kix_stipjsb61sk8-4 > li:before {
    content: "\\0025cb  "
  }

  .lst-kix_db0zr17d8gnt-8 > li:before {
    content: "\\0025a0  "
  }

  .lst-kix_izljxf9wdcgj-4 > li:before {
    content: "\\0025cb  "
  }

  .lst-kix_izljxf9wdcgj-5 > li:before {
    content: "\\0025a0  "
  }

  ul.lst-kix_stipjsb61sk8-0 {
    list-style-type: none
  }

  ul.lst-kix_izljxf9wdcgj-8 {
    list-style-type: none
  }

  .lst-kix_stipjsb61sk8-3 > li:before {
    content: "\\0025cf  "
  }

  ul.lst-kix_stipjsb61sk8-2 {
    list-style-type: none
  }

  ul.lst-kix_izljxf9wdcgj-6 {
    list-style-type: none
  }

  ul.lst-kix_stipjsb61sk8-1 {
    list-style-type: none
  }

  .lst-kix_izljxf9wdcgj-6 > li:before {
    content: "\\0025cf  "
  }

  ul.lst-kix_izljxf9wdcgj-7 {
    list-style-type: none
  }

  ul.lst-kix_stipjsb61sk8-4 {
    list-style-type: none
  }

  .lst-kix_obyyghjgpuql-0 > li:before {
    content: "\\0025cf  "
  }

  ul.lst-kix_izljxf9wdcgj-4 {
    list-style-type: none
  }

  .lst-kix_stipjsb61sk8-2 > li:before {
    content: "\\0025a0  "
  }

  ul.lst-kix_stipjsb61sk8-3 {
    list-style-type: none
  }

  ul.lst-kix_izljxf9wdcgj-5 {
    list-style-type: none
  }

  .lst-kix_obyyghjgpuql-1 > li:before {
    content: "\\0025cb  "
  }

  .lst-kix_stipjsb61sk8-7 > li:before {
    content: "\\0025cb  "
  }

  .lst-kix_obyyghjgpuql-2 > li:before {
    content: "\\0025a0  "
  }

  .lst-kix_stipjsb61sk8-8 > li:before {
    content: "\\0025a0  "
  }

  .lst-kix_obyyghjgpuql-5 > li:before {
    content: "\\0025a0  "
  }

  .lst-kix_stipjsb61sk8-5 > li:before {
    content: "\\0025a0  "
  }

  .lst-kix_obyyghjgpuql-3 > li:before {
    content: "\\0025cf  "
  }

  .lst-kix_izljxf9wdcgj-2 > li:before {
    content: "\\0025a0  "
  }

  .lst-kix_obyyghjgpuql-4 > li:before {
    content: "\\0025cb  "
  }

  .lst-kix_stipjsb61sk8-6 > li:before {
    content: "\\0025cf  "
  }

  ul.lst-kix_obyyghjgpuql-8 {
    list-style-type: none
  }

  ul.lst-kix_db0zr17d8gnt-3 {
    list-style-type: none
  }

  ul.lst-kix_obyyghjgpuql-7 {
    list-style-type: none
  }

  ul.lst-kix_db0zr17d8gnt-2 {
    list-style-type: none
  }

  ul.lst-kix_obyyghjgpuql-6 {
    list-style-type: none
  }

  ul.lst-kix_db0zr17d8gnt-1 {
    list-style-type: none
  }

  ul.lst-kix_obyyghjgpuql-5 {
    list-style-type: none
  }

  ul.lst-kix_db0zr17d8gnt-0 {
    list-style-type: none
  }

  ul.lst-kix_obyyghjgpuql-4 {
    list-style-type: none
  }

  ul.lst-kix_db0zr17d8gnt-7 {
    list-style-type: none
  }

  ul.lst-kix_obyyghjgpuql-3 {
    list-style-type: none
  }

  ul.lst-kix_db0zr17d8gnt-6 {
    list-style-type: none
  }

  ul.lst-kix_obyyghjgpuql-2 {
    list-style-type: none
  }

  ul.lst-kix_db0zr17d8gnt-5 {
    list-style-type: none
  }

  ul.lst-kix_obyyghjgpuql-1 {
    list-style-type: none
  }

  ul.lst-kix_db0zr17d8gnt-4 {
    list-style-type: none
  }

  ul.lst-kix_obyyghjgpuql-0 {
    list-style-type: none
  }

  ul.lst-kix_izljxf9wdcgj-2 {
    list-style-type: none
  }

  ul.lst-kix_izljxf9wdcgj-3 {
    list-style-type: none
  }

  .lst-kix_izljxf9wdcgj-8 > li:before {
    content: "\\0025a0  "
  }

  ul.lst-kix_izljxf9wdcgj-0 {
    list-style-type: none
  }

  ul.lst-kix_db0zr17d8gnt-8 {
    list-style-type: none
  }

  ul.lst-kix_izljxf9wdcgj-1 {
    list-style-type: none
  }

  .lst-kix_izljxf9wdcgj-7 > li:before {
    content: "\\0025cb  "
  }

  .lst-kix_db0zr17d8gnt-6 > li:before {
    content: "\\0025cf  "
  }

  .lst-kix_db0zr17d8gnt-4 > li:before {
    content: "\\0025cb  "
  }

  .lst-kix_db0zr17d8gnt-5 > li:before {
    content: "\\0025a0  "
  }

  .lst-kix_db0zr17d8gnt-3 > li:before {
    content: "\\0025cf  "
  }

  .lst-kix_db0zr17d8gnt-1 > li:before {
    content: "\\0025cb  "
  }

  .lst-kix_db0zr17d8gnt-2 > li:before {
    content: "\\0025a0  "
  }

  li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt
  }

  .lst-kix_db0zr17d8gnt-0 > li:before {
    content: "\\0025cf  "
  }

  ol {
    margin: 0;
    padding: 0
  }

  table td, table th {
    padding: 0
  }

  .c15 {
    border-right-style: solid;
    padding: 8pt 12pt 8pt 0pt;
    border-bottom-color: #000000;
    border-top-width: 0pt;
    border-right-width: 0pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 0pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 0pt;
    width: 177.8pt;
    border-top-color: #000000;
    border-bottom-style: solid
  }

  .c0 {
    border-right-style: solid;
    padding: 8pt 12pt 8pt 0pt;
    border-bottom-color: #000000;
    border-top-width: 0pt;
    border-right-width: 0pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 0pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 0pt;
    width: 278.2pt;
    border-top-color: #000000;
    border-bottom-style: solid
  }

  .c17 {
    background-color: #ffffff;
    color: #333333;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 16pt;
    font-family: "Helvetica Neue";
    font-style: italic
  }

  .c1 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 11pt
  }

  .c2 {
    padding-top: 15pt;
    padding-bottom: 8pt;
    line-height: 1.1;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .c24 {
    padding-top: 0pt;
    padding-bottom: 36pt;
    line-height: 1.1;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .c5 {
    padding-top: 0pt;
    padding-bottom: 8pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .c7 {
    padding-top: 12pt;
    padding-bottom: 15pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .c3 {
    background-color: #ffffff;
    font-size: 10.5pt;
    font-family: "Helvetica Neue";
    color: #333333;
    font-weight: 400
  }

  .c12 {
    background-color: #ffffff;
    color: #333333;
    font-weight: 700;
    font-size: 18pt;
    font-family: "Helvetica Neue"
  }

  .c14 {
    background-color: #ffffff;
    font-size: 9pt;
    font-family: "Helvetica Neue";
    color: #333333;
    font-weight: 700
  }

  .c6 {
    background-color: #ffffff;
    font-size: 10.5pt;
    font-family: "Helvetica Neue";
    color: #333333;
    font-weight: 700
  }

  .c10 {
    background-color: #ffffff;
    font-size: 9pt;
    font-family: "Helvetica Neue";
    color: #333333;
    font-weight: 400
  }

  .c16 {
    background-color: #ffffff;
    color: #333333;
    font-weight: 700;
    font-size: 24pt;
    font-family: "Helvetica Neue"
  }

  .c18 {
    color: #000000;
    font-weight: 400;
    font-size: 11pt;
    font-family: "Arial"
  }

  .c21 {
    border-spacing: 0;
    border-collapse: collapse;
    margin-right: auto
  }

  .c20 {
    background-color: #ffffff;
    max-width: 468pt;
    padding: 72pt 72pt 72pt 72pt
  }

  .c4 {
    text-decoration: none;
    vertical-align: baseline;
    font-style: normal
  }

  .c11 {
    padding: 0;
    margin: 0
  }

  .c8 {
    margin-left: 36pt;
    padding-left: 0pt
  }

  .c19 {
    height: 48pt
  }

  .c23 {
    height: 105.8pt
  }

  .c13 {
    height: 69.8pt
  }

  .c9 {
    height: 26.2pt
  }

  .c22 {
    height: 75.8pt
  }

  .title {
    padding-top: 0pt;
    color: #000000;
    font-size: 26pt;
    padding-bottom: 3pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .subtitle {
    padding-top: 0pt;
    color: #666666;
    font-size: 15pt;
    padding-bottom: 16pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  li {
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
  }

  p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
  }

  h1 {
    padding-top: 20pt;
    color: #000000;
    font-size: 20pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h2 {
    padding-top: 18pt;
    color: #000000;
    font-size: 16pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h3 {
    padding-top: 16pt;
    color: #434343;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h4 {
    padding-top: 14pt;
    color: #666666;
    font-size: 12pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h5 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h6 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
  }
`;

export default pageStyle;