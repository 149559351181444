import React from "react";
import useTheme from "../../hooks/ui/useTheme";
import { ThemeInterface } from "../../services/exports/Interfaces";

interface Props {
  className?: string;
  style?: Object;
  color?: string;
}

export default function WarningIcon(props: Props) {
  const { className, color, style } = props;
  const theme: ThemeInterface = useTheme();
  return (
    <svg
      className={className ?? "h-4 w-4"}
      viewBox="0 0 19 17"
      fill="none"
      style={style}
    >
      <path
        d="M2.86816 16.0586H15.8838C17.1953 16.0586 18.0503 15.0708 18.0503 13.8838C18.0503 13.5186 17.959 13.1533 17.7681 12.813L11.252 1.15869C10.8369 0.411621 10.1147 0.0463867 9.37598 0.0463867C8.63721 0.0463867 7.90674 0.419922 7.5 1.15869L0.983887 12.8213C0.792969 13.1616 0.693359 13.5186 0.693359 13.8838C0.693359 15.0708 1.55664 16.0586 2.86816 16.0586ZM9.38428 10.2646C8.85303 10.2646 8.5542 9.96582 8.5293 9.43457L8.40479 5.5083C8.37988 4.95215 8.78662 4.56201 9.37598 4.56201C9.95703 4.56201 10.3721 4.95215 10.3472 5.5083L10.2227 9.42627C10.1978 9.97412 9.89893 10.2646 9.38428 10.2646ZM9.38428 13.1782C8.78662 13.1782 8.29688 12.7715 8.29688 12.1904C8.29688 11.6094 8.77832 11.1943 9.38428 11.1943C9.99023 11.1943 10.4634 11.6094 10.4634 12.1904C10.4634 12.7798 9.98193 13.1782 9.38428 13.1782Z"
        fill={color ?? theme.color.brand.inkGrey.grey_3}
      />
    </svg>
  );
}
