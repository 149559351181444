import Availability from "./availability";
import Global from "./global";
import GoogleReview from "./google-review";
import Menu from "./menu";
import Order from './order';
import SEO from './seo';

export default {
  Availability,
  Global,
  GoogleReview,
  Menu,
  Order,
  SEO,
};
