import { useMemo } from "react";
import MenuItemsModal from "./MenuItem/MenuItemsModal";
import OrderMethodModal from "./OrderMethodModal";
import LoginModal from "./LoginModal";
import ChangeTablePartyModal from "./dine-in/ChangeTablePartyModal";
import OrdersDisabledModal from "./dine-in/OrdersDisabledModal";
import InfoModal from "./InfoModal";
import AllOrdersPaidModal from "./dine-in/AllOrdersPaidModal";
import FreeProductsModal from "./FreeProductsModal";
import useAppState from "../hooks/global/useAppState";
import ComboUpsellModal from "./combo/ComboUpsellModal";
import ComboModal from "./combo/ComboModal";

export default function GlobalModalsStack() {
  const {
    orderMethodModalProps,
    freeProductsModalProps,
    changeTablePartyModalProps,
    comboUpsellModalProps,
    menuItemModalProps,
    comboModalProps,
  } = useAppState();

  const { openModal: orderMethodModalOpen } = orderMethodModalProps;
  const { openModal: comboUpsellModalOpen } = comboUpsellModalProps;
  const { openModal: menuItemModalOpen } = menuItemModalProps;
  const { openModal: comboModalOpen } = comboModalProps;
  const { openModal: freeProductsModalOpen } = freeProductsModalProps;
  const { openModal: changeTablePartyModalOpen } = changeTablePartyModalProps;

  const renderOrderMethodModal = useMemo(
    () => orderMethodModalOpen && <OrderMethodModal />,
    [orderMethodModalOpen]
  );

  const renderComboUpsellModal = useMemo(() => {
    return comboUpsellModalOpen && <ComboUpsellModal />;
  }, [comboUpsellModalOpen]);

  const renderMenuItemsModal = useMemo(
    () => menuItemModalOpen && <MenuItemsModal />,
    [menuItemModalOpen]
  );

  const renderComboModal = useMemo(
    () => comboModalOpen && <ComboModal />,
    [comboModalOpen]
  );

  const renderChangeTablePartyModal = useMemo(
    () => changeTablePartyModalOpen && <ChangeTablePartyModal />,
    [changeTablePartyModalOpen]
  );

  const renderFreeProductsModal = useMemo(
    () => freeProductsModalOpen && <FreeProductsModal />,
    [freeProductsModalOpen]
  );

  const renderLoginModal = useMemo(() => <LoginModal />, []);
  const renderAllOrdersPaidModal = useMemo(() => <AllOrdersPaidModal />, []);
  const renderInfoModal = useMemo(() => <InfoModal />, []);
  const renderOrdersDisabledModal = useMemo(() => <OrdersDisabledModal />, []);

  return (
    <>
      {renderOrderMethodModal}
      {renderComboUpsellModal}
      {renderMenuItemsModal}
      {renderComboModal}
      {renderChangeTablePartyModal}
      {renderFreeProductsModal}
      {renderOrdersDisabledModal}
      {renderLoginModal}
      {renderAllOrdersPaidModal}
      {renderInfoModal}
    </>
  );
}
