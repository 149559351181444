export default {
  header: {
    table: "Table {{table}}",
  },
  titles: {
    join_party: "Find the people you are here with",
  },
  text: {
    join_party:
      "When you join a group you can pay together and split the bill between each other.",
  },
  toasts: {
    joined_table: "You joined {{members}}",
    created_party:
      "Great! Your friends can join your group and you can order & pay together",
  },
  feedback: {
    general_error: "Something went wrong, please try again",
  },
  buttons: {
    continue_to_order: "Continue To Place Order",
    here_with_someone_else: "I Am Here With Someone Else 🤝",
    here_alone: "I Am Here By Myself 👋",
  },
};
