import React from 'react';
import PageStyle from '../../../styles/pages/privacy-policy/GB';
import {useSelector} from "react-redux";
import {StoreInterface} from "../../../store/types";

export default function GB(): JSX.Element {
  const { company } = useSelector((store: StoreInterface) => store.initialData);
  
  return (
    <div className="p-small">
      <PageStyle />
      <p className="c2"><span className="c5 c3">PRIVACY POLICY {company?.name}</span></p>
      <p className="c2 c4"><span className="c5 c3"></span></p>
      <p className="c2"><span className="c0">{company?.name} is committed to respecting the privacy rights of all visitors to our Website.</span>
      </p>
      <p className="c2 c4"><span className="c0"></span></p>
      <p className="c2"><span className="c0">This Privacy Policy covers the use of the Website and the services provided by {company?.name} trading as {company?.name} (&quot;{company?.name}&quot;, &quot;we&quot;, &quot;us&quot; or &quot;our&quot;). {company?.name} is incorporated with company registration number and having its registered address at {company?.legal_address}. This Privacy Policy provides details of the way in which we process personal data in line with our obligations under the General Data Protection Regulation (No. 2016/679) (&quot;GDPR&quot;), the Data Protection Act 2018 and any other laws which apply to us in relation to the processing of personal data (collectively referred to as &quot;Data Protection Laws&quot;). In this Privacy Policy, &quot;controller&quot;, &quot;personal data&quot; and &quot;process&quot; (and its derivatives) have the meanings given to those terms in Data Protection Laws.</span>
      </p>
      <p className="c2 c4"><span className="c0"></span></p>
      <p className="c2"><span className="c0">Personal data generally means information that can be used to individually identify a person, and processing generally covers actions that can be performed in connection with data such as collection, use, storage and disclosure. We are the controller of your personal data processed in connection with the Website.</span>
      </p>
      <p className="c2 c4"><span className="c0"></span></p>
      <p className="c2"><span className="c0">The purpose of this Privacy Policy is to explain our data processing activities, including how and why we process personal data. In addition, this Privacy Policy outlines our duties and responsibilities regarding the protection of such personal data. The manner in which we process personal data will evolve over time and we will update this Policy from time to time to reflect changing practices.</span>
      </p>
      <p className="c2 c4"><span className="c5 c3"></span></p>
      <p className="c2"><span className="c5 c3">Personal Data We Collect</span></p>
      <p className="c2"><span className="c0">We collect personal data about you when you provide it directly to us, when third parties provide us with personal data about you, or when personal data about you is automatically collected in connection with your use of our Website. We do not disclose your personal data to third parties, except as outlined in this Privacy Policy.</span>
      </p>
      <p className="c2 c4"><span className="c0"></span></p>
      <p className="c2"><span className="c0">In running and maintaining our Website, we may collect and process the following information:</span>
      </p>
      <p className="c2 c4"><span className="c0"></span></p>
      <p className="c2"><span className="c5 c3">Personal Data Collected Directly From You</span></p>
      <p className="c2"><span className="c0">We receive personal data directly from you when you provide it to us through placing a food order, including, but not limited to:</span>
      </p>
      <p className="c2 c4"><span className="c0"></span></p>
      <p className="c2"><span className="c0">- name;</span></p>
      <p className="c2"><span className="c0">- email address;</span></p>
      <p className="c2"><span className="c0">- mailing address;</span></p>
      <p className="c2"><span className="c0">- telephone number;</span></p>
      <p className="c2"><span className="c0">- username; and</span></p>
      <p className="c2"><span className="c0">- payment details.</span></p>
      <p className="c2 c4"><span className="c0"></span></p>
      <p className="c2"><span className="c0">You may also include details in your food order that indicate your health information or religious beliefs.</span>
      </p>
      <p className="c2 c4"><span className="c0"></span></p>
      <p className="c2"><span className="c5 c3">Personal Data Collected We Automatically Collect</span></p>
      <p className="c2"><span className="c0">Some personal data is automatically collected when you use the Website, such as:</span>
      </p>
      <p className="c2 c4"><span className="c0"></span></p>
      <p className="c2"><span className="c0">- IP_address;</span></p>
      <p className="c2"><span className="c0">- device_identifiers;</span></p>
      <p className="c2"><span className="c0">- browser_information;_and</span></p>
      <p className="c2"><span className="c0">- cookie data (as explained in the Cookie Policy).</span></p>
      <p className="c2 c4"><span className="c0"></span></p>
      <p className="c2"><span className="c3 c11">How We Use Personal Data</span></p>
      <p className="c2"><span className="c0">We process personal data to operate, improve, understand and personalise our services e.g. we use personal data to:</span>
      </p>
      <p className="c2 c4"><span className="c0"></span></p>
      <p className="c2"><span className="c0">- complete orders including delivery or collection of food;</span></p>
      <p className="c2"><span className="c0">- personalise content based on your preferences;</span></p>
      <p className="c2"><span
        className="c0">- contact you about our offers, subject to your marketing preferences;</span></p>
      <p className="c2"><span className="c0">- communicate_with_you;</span></p>
      <p className="c2"><span className="c0">- protect against or deter fraudulent, illegal or harmful actions;</span>
      </p>
      <p className="c2"><span className="c0">- respond_to_user_inquiries;</span></p>
      <p className="c2"><span className="c0">- provide_support_and_assistance;</span></p>
      <p className="c2"><span className="c0">- comply with our legal or contractual obligations;</span></p>
      <p className="c2"><span className="c0">- enforce our terms and conditions; and</span></p>
      <p className="c2"><span className="c0">- resolve disputes.</span></p>
      <p className="c2 c4"><span className="c3 c5"></span></p>
      <p className="c2"><span className="c5 c3">Lawful Basis for Processing</span></p>
      <p className="c2 c4"><span className="c0"></span></p><a id="t.fb915fff3c8466b7b7d48776521ed4bbdd8c2b48"></a><a
      id="t.0"></a>
      <table className="c14">
        <tr className="c7">
          <td className="c6" colSpan={1} rowSpan={1}><p className="c15"><span
            className="c1 c3">Purpose of Processing</span></p></td>
          <td className="c8" colSpan={1} rowSpan={1}><p className="c15"><span
            className="c1 c3">Lawful Basis under GDPR</span></p>
          </td>
        </tr>
        <tr className="c18">
          <td className="c6" colSpan={1} rowSpan={1}><p className="c13"><span
            className="c1 c3">Administration purposes</span></p>
          </td>
          <td className="c8" colSpan={1} rowSpan={1}><p className="c13"><span className="c1">Such processing is necessary for the performance of a contract between us and you, where necessary for the purpose of complying with our legal obligations and where you have consented to providing certain information in relation to your food order preferences at the time of purchase.</span>
          </p></td>
        </tr>
        <tr className="c12">
          <td className="c6" colSpan={1} rowSpan={1}><p className="c13"><span className="c1 c3">Training, quality monitoring or evaluating the services we provide</span>
          </p></td>
          <td className="c8" colSpan={1} rowSpan={1}><p className="c13"><span className="c1">Such processing is necessary for the legitimate interests pursued by us in monitoring and improving our Website&#39;s services and their usage and ensuring that our users use the Website in accordance with our terms and conditions and policies.</span>
          </p></td>
        </tr>
        <tr className="c12">
          <td className="c6" colSpan={1} rowSpan={1}><p className="c13"><span className="c1 c3">Website services, including for troubleshooting, data analysis, and survey purposes</span>
          </p></td>
          <td className="c8" colSpan={1} rowSpan={1}><p className="c13"><span className="c1">We have a legitimate interest in operating and maintaining the Website and for related purposes including improving our services.</span>
          </p></td>
        </tr>
        <tr className="c18">
          <td className="c6" colSpan={1} rowSpan={1}><p className="c13"><span className="c1 c3">Statistical information that cannot be related back to individuals to help us improve the services we offer</span>
          </p></td>
          <td className="c8" colSpan={1} rowSpan={1}><p className="c13"><span className="c1">We have a legitimate interest in having access to certain analytics to ensure the products and services we provide are adequate.</span>
          </p></td>
        </tr>
        <tr className="c17">
          <td className="c6" colSpan={1} rowSpan={1}><p className="c13"><span
            className="c1 c3">Enforcing and Defending Our Rights</span></p></td>
          <td className="c8" colSpan={1} rowSpan={1}><p className="c13"><span className="c1">We have a legitimate interest in ensuring that our services and the Website are used in accordance with our terms and conditions of use and policies. Where necessary for the purpose of complying with our legal obligations.Where necessary for the purpose of establishing, exercising or defending a legal claim, a prospective legal claim, legal proceedings or prospective legal proceedings.</span>
          </p></td>
        </tr>
      </table>
      <p className="c2 c4"><span className="c0"></span></p>
      <p className="c2 c4"><span className="c0"></span></p>
      <p className="c2"><span className="c0">We will not use your personal data for marketing purposes without your consent. If you wish to stop receiving marketing communications from us, you can opt out at any time by clicking the &quot;opt-out&quot; link at the bottom of any marketing communication from us or by contacting us at the e-mail provided on our website.</span>
      </p>
      <p className="c2 c4"><span className="c0"></span></p>
      <p className="c2"><span className="c11 c3">Individual Data Subject Rights</span></p>
      <p className="c9">
        <span>Data Protection Laws provide certain rights in favour of data subjects (the &quot;</span><span
        className="c3">Data Subject Rights</span><span className="c0">&quot;).</span></p>
      <p className="c9"><span className="c0">Data Subject Rights include the right of a data subject to:</span></p>
      <ul className="c10 lst-kix_dqljyk4kmem1-0 start">
        <li className="c16 li-bullet-0"><span className="c0">receive detailed information on the processing (as per transparency obligations on controllers) which we have provided through this Privacy Policy;</span>
        </li>
        <li className="c16 li-bullet-0"><span className="c0">access personal data;</span></li>
        <li className="c16 li-bullet-0"><span className="c0">rectify or erase personal data (i.e. right to be forgotten);</span>
        </li>
        <li className="c16 li-bullet-0"><span className="c0">restrict processing;</span></li>
        <li className="c16 li-bullet-0"><span className="c0">data portability;</span></li>
        <li className="c16 li-bullet-0"><span className="c0">object to processing; and</span></li>
        <li className="c16 li-bullet-0"><span
          className="c0">object to automated decision making (including profiling).</span></li>
      </ul>
      <p className="c9"><span className="c0">These Data Subject Rights will be exercisable by you subject to limitations as provided for under Data Protection Laws.</span>
      </p>
      <p className="c9"><span className="c0">You may make a request to us to exercise any Data Subject Right by contacting the e-mail address provided on our website. Your request will be dealt with in accordance with Data Protection Laws.</span>
      </p>
      <p className="c2 c4"><span className="c0"></span></p>
      <p className="c2"><span className="c5 c3">How we Share Data with our Partners</span></p>
      <p className="c2"><span className="c0">We do not share any personally identifying data with third parties and will not disclose your personal data to any other party except as set out in this policy.</span>
      </p>
      <p className="c2 c4"><span className="c0"></span></p>
      <p className="c2"><span className="c0">We and Tuck, our online ordering partner, are joint controllers of your personal data processed in connection with food orders placed through the Tuck Platform on the Website. If you have queries regarding how your personal data is processed by Tuck or wish to address your rights, please contact Tuck Technology Ltd.</span>
      </p>
      <p className="c2 c4"><span className="c0"></span></p>
      <p className="c2"><span
        className="c0">Tuck Technology Ltd acts as a processor in assisting us with managing users&#39; consent to our marketing communications. Tuck Technology Ltd also acts as a processor when it assists us to manage our analytics.</span>
      </p>
      <p className="c2 c4"><span className="c0"></span></p>
      <p className="c2"><span
        className="c0">[Content about data sharing, disclosure, and third-party interaction]</span></p>
      <p className="c2 c4"><span className="c0"></span></p>
      <p className="c2"><span className="c5 c3">Data Retention</span></p>
      <p className="c9"><span className="c0">We will keep personal data only for as long as the retention of such personal data is deemed necessary for the purposes for which that personal data are processed (as described in this Privacy Policy) unless we are required to retain your personal data for a longer period (e.g. in the event of legal proceedings or investigations).</span>
      </p>
      <p className="c2 c4"><span className="c0"></span></p>
      <p className="c2 c4"><span className="c0"></span></p>
      <p className="c2"><span className="c5 c3">Third Party Links</span></p>
      <p className="c2"><span className="c0">On occasion we include links to third parties on the Website. Where we provide a link it does not mean that we have any control over the linked website or its privacy policy or that we endorse, or approve that website&#39;s policy towards visitor privacy. You should review the linked website&#39;s privacy policy before sending them any personal data.</span>
      </p>
      <p className="c2 c4"><span className="c0"></span></p>
      <p className="c2 c4"><span className="c0"></span></p>
      <p className="c2"><span className="c5 c3">Service-related Announcements and Changes</span></p>
      <p className="c9"><span className="c0">Any changes to this Privacy Policy in the future will be posted to the Website and, where appropriate, through email notification.</span>
      </p>
      <p className="c2 c4"><span className="c0"></span></p>
      <p className="c2 c4"><span className="c0"></span></p>
      <p className="c2"><span className="c5 c3">Contacting Us</span></p>
      <p className="c2"><span className="c0">For further information about this Privacy Policy or the processing of your personal data by or on our behalf, please contact us by using the contact details provided on our website.</span>
      </p>
      <p className="c2 c4"><span className="c0"></span></p>
      <p className="c2"><span className="c0">While you may make a complaint in respect of our compliance with Data Protection Laws to the Data Protection Commission, we ask that you contact us in the first instance to give us the opportunity to address your concerns.</span>
      </p>
      <p className="c2 c4"><span className="c0"></span></p>
      <p className="c2"><span className="c0">This Policy was last updated on September 2023</span></p>
      <p className="c2 c4"><span className="c0"></span></p>
    </div>
  )
}