import FoodAmigosLogo from "../../assets/images/FoodAmigosLogo.png";
import {useSelector} from "react-redux";
import {StoreInterface} from "../../store/types";
import {Stringable} from "../../services/helpers/Stringable";
import classnames from "classnames";

export default function PoweredByFooter({ className = null }): JSX.Element {
  const { company } = useSelector((state: StoreInterface) => state.initialData);

  const website = (new Stringable(company?.service_provider?.website)).start('https://').toString();

  return (
    <div className={classnames('w-full py-mini flex justify-center border-t border-brand-inkGrey-grey_2', className)}>
      <a
        className="flex items-center"
        href={website}
        target={"_blank"}
        rel="noreferrer"
      >
        <p className="mr-tiny text-tiny text-brand-inkGrey-grey_4">
          Powered by
        </p>
        <img
          src={company?.service_provider?.logo_url ?? FoodAmigosLogo}
          alt="FoodAmigosLogo"
          className="h-[12px]"
        />
      </a>
    </div>
  );
}