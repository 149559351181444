import Section from "./Section";
import useDineInFlow from "../../hooks/global/useDineInFlow";
import { useTranslation } from "react-i18next";
import useCompanyHours from "../../hooks/availability/useCompanyHours";
import moment from "moment";
import PickUpTimesResource from "../../services/resources/PickUpTimesResource";
import {useSelector} from "react-redux";
import {StoreInterface} from "../../store/types";
import useOrderMethodsStatus from "../../hooks/availability/useOrderMethodsStatus";
import useAppMethods from "../../hooks/utility/useAppMethods";

interface Props {
  className?: string;
}

export default function OrdersDisabledSection(props: Props) {
  const { t } = useTranslation(undefined, { keyPrefix: "Components:DineIn:OrdersDisabledSection" });

  const { className } = props;

  const { company } = useSelector((store: StoreInterface) => store.initialData);
  const { unpaidAmount } = useDineInFlow();
  const { dineInCurrentlyOpen } = useOrderMethodsStatus();
  const { formatTime, formatDateTime } = useAppMethods();
  const {
    getCurrentDineInSchedule,
    ordersArePaused,
    ordersPausedUntil,
    orderArePausedTillEndOfDay,
    dineInClosed,
    dineInOpensToday
  } = useCompanyHours();

  const getTitle = () => {
    if (ordersArePaused) {
      if (orderArePausedTillEndOfDay) {
        return t('titles.opens_other_day', {
          dateTime: formatDateTime(
            PickUpTimesResource.createDate(company?.next_work_schedule?.open),
            { dateFormat: 'dddd' },
          ),
        });
      }

      return moment(ordersPausedUntil).day() === moment().day()
        ? t('titles.opens_today', {
          time: formatTime(ordersPausedUntil)
        })
        : t('titles.opens_other_day', {
          dateTime: formatDateTime(ordersPausedUntil, { dateFormat: 'Do MMMM' }),
        });
    }

    if (! dineInClosed) {
      return t('titles.main');
    }

    if (dineInOpensToday) {
      return t('titles.opens_today', {
        time: formatTime(PickUpTimesResource.createDate(getCurrentDineInSchedule()?.open)),
      })
    }

    return t('titles.opens_other_day', {
      dateTime: formatDateTime(
        PickUpTimesResource.createDate(company?.current_work_schedule?.open ?? company?.next_work_schedule?.open),
        { dateFormat: 'dddd' },
      ),
    });
  }

  return (
    !dineInCurrentlyOpen && (
      <Section
        className={className}
        title={
          <h6 className="text-brand-text-warning">
            {getTitle()}
            {unpaidAmount > 0 && (
              <>
                {' '}
                {t('pay_remaining')}
              </>
            )}
          </h6>
        }
        description={
          <p className="text-brand-text-default text-tiny">{t('description')}</p>
        }
      />
    )
  );
}
