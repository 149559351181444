import { useMemo, useState } from "react";
import CustomButton from "../components-library/buttons/CustomButton";
import DiscountIllustration from "../assets/illustrations/DiscountIllustration";
import { useCallback, useEffect } from "react";
import {
  ThemeInterface,
  PromoComponentInterface,
} from "../services/exports/Interfaces";
import { useSelector } from "react-redux";
import { collect } from "collect.js";
import {
  MODAL_SIZES,
  PROMO_CODE_DISCOUNT_TYPES,
} from "../services/exports/Constants";
import useTheme from "../hooks/ui/useTheme";
import CheckmarkIcon from "../assets/logo/CheckmarkIcon";
import useScreenType from "../hooks/utility/useScreenType";
import CustomModal from "../components-library/CustomModal";
import LoadingIndicator from "../components-library/loading/LoadingIndicator";
import { useTranslation } from "react-i18next";
import {StoreInterface} from "../store/types";
import useAppMethods from "../hooks/utility/useAppMethods";

interface Props {
  openModal: boolean;
  toggleModal: () => void;
}

export default function MultiPromoCodeModal(props: Props) {
  const { t } = useTranslation(null, {
    keyPrefix: "Modals:MultiPromoCodeModel",
  });

  const { openModal, toggleModal } = props;

  const { cached_order } = useSelector((state: StoreInterface) => state.order);

  const { formatCurrency } = useAppMethods();
  const theme: ThemeInterface = useTheme();
  const { isDesktop } = useScreenType();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (openModal) {
      onDidFocus();
    }
  }, [openModal]);

  function onDidFocus() {
    if (!isDesktop) {
      setLoading(true);
      setTimeout(() => setLoading(false), 1000);
    }
  }

  const totalSaved = useCallback(() => {
    return collect(cached_order?.coupon_details?.components)?.reduce(
      (carry: number, component: PromoComponentInterface) =>
        carry + component?.discount
    );
  }, [cached_order?.coupon_details]);

  function isActive(
    component: PromoComponentInterface,
    index: number
  ): boolean {
    if (index === 0) {
      return !component?.is_used;
    }
    return (
      !component?.is_used &&
      collect(cached_order?.coupon_details?.components)?.get(index - 1)?.is_used
    );
  }

  function renderList() {
    function renderDiscount(component: PromoComponentInterface) {
      switch (cached_order?.coupon_details?.discount_type) {
        case PROMO_CODE_DISCOUNT_TYPES.PERCENTAGE:
          return t("discounts.percentage", {
            discount: component?.discount,
          });
        case PROMO_CODE_DISCOUNT_TYPES.FIXED_AMOUNT:
          return t("discounts.fixed", {
            discount: formatCurrency(component?.discount),
          });
      }
    }

    function getBackgroundColor(
      component: PromoComponentInterface,
      active: boolean
    ) {
      if (component?.is_used) {
        return theme.color.background.inkDisabled.disabled_3;
      }
      if (active) {
        return theme.color.companyBrand.primary;
      }
      return theme.color.background.inkWhite.white_1;
    }

    function getSubTextColor(
      component: PromoComponentInterface,
      active: boolean
    ) {
      if (component?.is_used) {
        return theme.color.text.light;
      }
      if (active) {
        return theme.color.text.light;
      }
      return theme.color.text.grey;
    }

    function getDiscountColor(
      component: PromoComponentInterface,
      active: boolean
    ) {
      if (component?.is_used) {
        return theme.color.text.light;
      }
      if (active) {
        return theme.color.text.light;
      }
      return theme.color.companyBrand.primary;
    }

    return (
      <div
        className={listContainer(
          cached_order?.coupon_details?.components?.length
        )}
      >
        {cached_order?.coupon_details?.components?.map(
          (component: PromoComponentInterface, index: number) => {
            const active = isActive(component, index);
            return (
              <div
                className={itemContainer}
                style={{
                  backgroundColor: getBackgroundColor(component, active),
                }}
                key={component?.id?.toString()}
              >
                <p
                  style={{ color: getSubTextColor(component, active) }}
                  className="mb-tiny"
                >
                  {t("list.item.order", {
                    orderNumber: index + 1,
                  })}
                </p>
                <h3 style={{ color: getDiscountColor(component, active) }}>
                  {renderDiscount(component)}
                </h3>
                {active && (
                  <div className={checkmarkIconContainer}>
                    <CheckmarkIcon
                      className={checkmarkIconStyle}
                      color={theme.color.text.light}
                    />
                  </div>
                )}
              </div>
            );
          }
        )}
      </div>
    );
  }

  function renderButton() {
    return (
      <div className={buttonContainer}>
        <CustomButton title={t("custom_button.done")} onClick={toggleModal} />
      </div>
    );
  }

  function renderTitle() {
    switch (cached_order?.coupon_details?.discount_type) {
      case PROMO_CODE_DISCOUNT_TYPES.PERCENTAGE:
        return t("savings.percentage", {
          totalSaved: totalSaved(),
        });
      case PROMO_CODE_DISCOUNT_TYPES.FIXED_AMOUNT:
        return t("savings.fixed", {
          totalSaved: formatCurrency(totalSaved()),
        });
    }
  }

  const renderLoadingPage = useMemo(() => {
    return loading ? (
      <div className={loadingContainer}>
        <div className="-mb-1.5">
          <LoadingIndicator lg={60} md={50} sm={40} />
        </div>
      </div>
    ) : null;
  }, [loading]);

  return (
    <CustomModal
      open={openModal}
      size={MODAL_SIZES.LG}
      fullScreen={!isDesktop}
      onClose={toggleModal}
    >
      <div className={container}>
        <div className={wrapper}>
          <div className={illustrationContainer}>
            <DiscountIllustration color={theme.color.companyBrand.primary} />
          </div>
          <h3 className="text-center mb-small">{renderTitle()}</h3>
          <p className="text-center mb-medium">
            {t("description", {
              code: cached_order?.coupon_details?.code,
              count: cached_order?.coupon_details?.components?.length,
            })}
          </p>
          {renderList()}
        </div>
        {renderButton()}
        {renderLoadingPage}
      </div>
    </CustomModal>
  );
}

const container =
  "w-full h-full flex flex-col justify-center bg-background-inkWhite-white_1 relative";

const wrapper = "w-full lg:px-medium px-small py-medium lg:h-fit h-full";

const listContainer = (length: number) =>
  `grid lg:grid-cols-${length} grid-cols-1 gap-4`;

const itemContainer =
  "flex flex-col rounded p-mini justify-center items-center bg-background-inkWhite-white_1 border border-brand-inkGrey-grey_2 relative";

const illustrationContainer = "flex justify-center";

const checkmarkIconContainer = "absolute right-6";

const checkmarkIconStyle = "lg:h-3 lg:w-3 md:w-3 md:h-3 w-2 h-2";

const buttonContainer =
  "w-full flex justify-center items-center lg:px-medium px-small py-xsmall border-t border-brand-inkGrey-grey_2 lg:relative fixed bottom-0 bg-background-inkWhite-white_1 ";

const loadingContainer =
  "absolute inset-0 flex flex-col bg-background-inkWhite-white_1 justify-center items-center";
