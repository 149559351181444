export default {
  pickup: "Abholung",
  delivery: "Lieferung",
  ongoing_order: "Laufende Bestellung",
  view_more: "Antippen, um Details anzuzeigen",
  status: {
    rejected: {
      title: "Bestellung storniert",
      description: "Antippen, um Details anzuzeigen",
    },
    created: {
      description: "Bestellung erhalten",
    },
    reviewed: {
      description: "Deine Bestellung wurde gesehen",
    },
  },
  order_type: {
    pickup: 'Lieferzeit',
    delivery: 'Abholzeit',
  },
  order_status_toast: {
    order_reviewed: "#{{number}} Deine Bestellung wurde gesehen",
    order_confirmed: "#{{number}} Bestellung bestätigt {{time}}",
    order_preparation_up_time_changed: "#{{number}} {{type}} geändert auf {{time}}",
    order_preparing: "Deine Bestellung #{{number}} wird zubereitet",
    order_rejected: "#{{number}} Bestellung storniert",
  },
  error_messages: {
    failed_to_load: 'Etwas ist schief gelaufen, bitte versuche es noch einmal.',
  },
};
