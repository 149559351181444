import {ProductInterface} from "../../../services/exports/Interfaces";
import BackIcon from "../../../assets/logo/BackIcon";
import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import useTheme from "../../../hooks/ui/useTheme";
import classnames from "classnames";
import RadioButton from "../../buttons/RadioButton";
import collect from "collect.js";
import {BasketLineItemInterface} from "../../../store/types";
import useBasket from "../../../hooks/basket/useBasket";
import useAppMethods from "../../../hooks/utility/useAppMethods";

interface Props {
  data: ProductInterface;
  lineItemData: null|BasketLineItemInterface;
  extraCost: number;
  className?: string;
  onCLick: () => void;
}

export default function ComboItemProduct(props: Props): JSX.Element {
  const { t } = useTranslation(null, { keyPrefix: 'Components:Menu:Combo:ComboItemProduct' });

  const theme = useTheme();
  const { compileItem } = useBasket();
  const { formatCurrency } = useAppMethods();

  const {
    data,
    lineItemData,
    extraCost,
    className,
    onCLick,
  } = props;

  const isSelected = lineItemData?.product_id === data.id;
  const modifiers = useMemo(
    () => isSelected && lineItemData
      ? collect(compileItem(lineItemData).modifiers).implode('modifier.name', ', ')
      : null,
    [isSelected, lineItemData]
  );

  return (
    <button
      className={classnames(container, className, {
        'touchable-highlight': data.is_available,
      })}
      onClick={onCLick}
    >
      <RadioButton active={isSelected} className="mr-mini" />
      <div className="flex-1 mr-mini">
        <p
          className={classnames("text-left", {
            "opacity-25": !data?.is_available,
          })}
        >
          {data?.name}
        </p>
        {isSelected && modifiers?.length > 0 && (
          <p className="text-tiny text-left italic text-brand-inkGrey-grey_4">{modifiers}</p>
        )}
      </div>
      {!data?.is_available ? (
        <div className="flex-none">
          <p className="ml-mini text-companyBrand-primary">
            {t('labels.sold_out')}
          </p>
        </div>
      ) : (
        <>
          {extraCost > 0 && (
            <p className="text-tiny text-brand-inkGrey-grey_4 mr-mini">
              +
              {' '}
              {formatCurrency(extraCost)}
            </p>
          )}
          {data.modifier_groups?.length > 0 && (
            <BackIcon className="rotate-180 h-3 w-3" color={theme.color.brand.inkGrey.grey_4} />
          )}
        </>
      )}
    </button>
  )
}

const container =
  "flex flex-row w-full items-center before:relative after:content-[''] after:absolute after:-top-0 after:-bottom-0";
