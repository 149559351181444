import AlertContext from "../context/AlertContext";
import {useMemo, useState} from "react";
import {
  AlertContextInterface,
  AlertInfoInterface,
} from "../services/exports/Interfaces";
import AlertModal from "../modals/AlertModal";

interface Props {
  children: any;
}

export default function AlertModalProvider(props: Props): JSX.Element {
  const { children } = props;

  const [alertInfo, setAlertInfo] = useState<AlertInfoInterface>({
    title: '',
    description: '',
  });

  const [showAlertModal, setShowAlertModal] = useState(false);

  function toggleAlertModal(info?: AlertInfoInterface) {
    if (info) {
      setShowAlertModal(true);
      setAlertInfo({
        title: info?.title,
        description: info?.description,
      });
      return;
    }
    setShowAlertModal(false);
    setAlertInfo({
      title: "",
      description: "",
    });
  }

  const renderAlertModal = useMemo(() => {
    return (
      <AlertModal
        openModal={showAlertModal}
        title={alertInfo?.title}
        description={alertInfo?.description}
        toggleModal={() => toggleAlertModal(null)}
      />
    );
  }, [showAlertModal, alertInfo]);

  const context: AlertContextInterface = useMemo(
    () => ({
      error: (info: AlertInfoInterface) => {
        toggleAlertModal({ ...info, type: "Error" });
      },
    }),
    []
  );

  return (
    <AlertContext.Provider value={context}>
      {children}
      {renderAlertModal}
    </AlertContext.Provider>
  )
}