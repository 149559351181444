import {DEAL_TYPES} from "../../../../../components-library/carousel/DealsCarousel";

export default {
  title: 'Rabatte',
  deals: {
    [DEAL_TYPES.GOOGLE_REVIEW_PROGRAM]: {
      title: '+{{reward}} Extra Punkte',
      description: 'Bewerte uns auf Google',
      buttons: {
        add: 'Add',
      },
    },
    [DEAL_TYPES.SMART_PRICING_RULE]: {
      description: 'Bereits automatisch angewandt',
    },
  },
};
