import classnames from "classnames";

interface Props {
  className?: string;
  children?: any;
  title?: string | JSX.Element;
  description?: string | JSX.Element;
  titleClassName?: string;
}

export default function Section(props: Props) {
  const { className, children, title, description, titleClassName } = props;

  return (
    <div
      className={classnames(
        "flex flex-col rounded-xl shadow-section p-small bg-background-inkWhite-white_0",
        className
      )}
    >
      {(title || description) && (
        <div
          className={classnames("mb-small", titleClassName, {
            "mb-none": !children,
          })}
        >
          {title && <h6>{title}</h6>}

          {description && (
            <p
              className={classnames("text-brand-text-grey tiny mt-mini", {
                "mt-none": !title,
              })}
            >
              {description}
            </p>
          )}
        </div>
      )}

      {children}
    </div>
  );
}
