import { createSlice } from "@reduxjs/toolkit";
import { REDUX_STORE_SLICES } from "../../services/exports/Constants";
import { ProfileStoreInterface } from "../../services/exports/Interfaces";

export default createSlice({
  name: REDUX_STORE_SLICES.PROFILE,
  initialState: {
    profile: null,
  },
  reducers: {
    setProfile: (state: ProfileStoreInterface, action) => {
      try {
        state.profile = action.payload;
      } catch (error) {}
    },
  },
});

export const props = (state: ProfileStoreInterface) => state;
