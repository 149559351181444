import { useCallback, useEffect, useMemo, useState } from "react";
import MenuContext from "../context/MenuContext";
import {
  CategoryInterface,
  MenuContextInterface,
  ProductComboInterface,
  ProductComboItemInterface,
  ProductInterface,
  ModifierGroupInterface,
  RewardInterface,
  ModifierInterface,
} from "../services/exports/Interfaces";
import collect, { Collection } from "collect.js";
import useInitialData from "../hooks/global/useInitialData";
import MenuResource from "../services/resources/MenuResource";
import useImagePreloader from "../hooks/utility/useImagePreloader";
import {
  CategoryType,
  ORDER_METHODS,
  ProductType,
} from "../services/exports/Constants";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { StoreInterface } from "../store/types";
import useLoyaltyProgram from "../hooks/loyalty/useLoyaltyProgram";

interface Props {
  children: any;
}

export default function MenuProvider(props: Props) {
  const { t, i18n } = useTranslation(undefined, { keyPrefix: 'Providers:MenuProvider' });

  const { cached_order } = useSelector((state: StoreInterface) => state.order);

  const { children } = props;
  const { data } = useInitialData();
  const { company } = data;

  const { preloadImages } = useImagePreloader();
  const { hasLoyaltyPromotion } = useLoyaltyProgram()

  const hasFreeItem = hasLoyaltyPromotion && collect(company?.rewards)?.last(
      (reward: RewardInterface) => reward?.is_available
    )?.discount === 100;

  const [selectedMenu, setSelectedMenu] = useState(null);

  const applyOrderMethodPrices = useCallback<
    (
      item: ProductInterface | ProductComboInterface | ModifierInterface
    ) => ProductInterface | ProductComboInterface | ModifierInterface
  >(
    // @ts-ignore
    (item) => ({
      ...item,
      base_price: item.original_price, // a price that isn't affected by any discounts
      original_price:
        item?.original_order_method_prices?.[
          cached_order?.method ?? ORDER_METHODS.PICKUP
        ] ?? item?.original_price,
      actual_price:
        item?.actual_order_method_prices?.[
          cached_order?.method ?? ORDER_METHODS.PICKUP
        ] ?? item?.actual_price,
    }),
    [cached_order?.method]
  );

  const modifierGroups = useMemo<Collection<ModifierGroupInterface>>(
    () =>
      collect(company?.modifier_groups).map((item) => {
        const modifiers = company?.modifiers ?? {};

        return {
          ...item,
          modifiers:
            item.modifier_ids?.map(
              (id: number) =>
                applyOrderMethodPrices(modifiers[id]) as ModifierInterface
            ) ?? [],
        };
      }),
    [company?.modifier_groups, company?.modifiers, cached_order?.method]
  );

  const compilePrice = (
    product: ProductInterface,
    isPromotable: boolean
  ): ProductInterface => {
    if (isPromotable) {
      return product;
    }

    return {
      ...product,
      actual_price: product.original_price,
      modifier_groups: product.modifier_groups?.map((item) => ({
        ...item,
        modifiers: item.modifiers?.map((modifier) => ({
          ...modifier,
          actual_price: modifier.original_price,
        })),
      })),
    };
  };

  const compileProduct = useCallback<
    (
      id: number,
      isPromotable: boolean
    ) => ProductInterface | ProductComboInterface
  >(
    (id, isPromotable = true) => {
      const products = company?.products ?? {};
      const data = products[id] ?? null;

      if (!data) {
        return null;
      }

      if (data.type === ProductType.Product) {
        return compilePrice(
          applyOrderMethodPrices({
            ...data,
            modifier_groups: collect(data?.modifier_group_ids)
              .map((id: number) => modifierGroups.get(id))
              .whereNotNull()
              .toArray(),
          }) as ProductInterface,
          isPromotable
        ) as ProductInterface;
      }

      const mainComboItem = collect(data.items).firstWhere("is_main", true);
      const mainProductId = collect(mainComboItem.product_ids).first();

      return applyOrderMethodPrices({
        ...data,
        main_product: compileProduct(mainProductId, false),
        items: data.items?.map((item: ProductComboItemInterface) => ({
          ...item,
          products: collect(item.product_ids)
            .map((id: number) => compileProduct(id, false))
            .whereNotNull()
            .toArray(),
        })),
      }) as ProductComboInterface;
    },
    [company?.products, modifierGroups, cached_order?.method]
  );

  const mappedCategories = useMemo<Collection<Collection<CategoryInterface>>>(
    () =>
      collect(company?.menus).mapWithKeys((menu) => [
        menu.id,
        collect(menu.category_ids)
          .map((item: number) => {
            const category = company.categories[item];

            return category
              ? {
                  ...category,
                  menu_id: menu.id,
                  products:
                    category.product_ids?.map((id: number) =>
                      applyOrderMethodPrices(company.products[id])
                    ) ?? [],
                }
              : null;
          })
          .whereNotNull()
          .filter((item) => item.products.length > 0),
      ]),
    [
      company?.menus,
      company?.categories,
      company?.products,
      cached_order?.method,
    ]
  );

  const menus = useMemo(() => {
    const menus = collect(company?.menus).filter(
      (item) =>
        (!company?.has_pre_order
          ? new MenuResource(item, company).isActiveToday()
          : true) && mappedCategories.get(item?.id)?.isNotEmpty()
    );

    return menus.isEmpty()
      ? menus.push(collect(company?.menus).first())
      : menus;
  }, [
    company?.menus,
    company?.has_pre_order,
    company?.work_schedule,
    mappedCategories,
  ]);

  const freeItems = useMemo<Collection<ProductInterface>>(
    () =>
      collect(company?.products)
        .where("free_eligible", true)
        .map((item: ProductInterface) => {
          const data = compileProduct(item.id) as ProductInterface;

          return {
            ...data,
            actual_price: 0,
            discount: 100,
            modifier_groups: collect(data.modifier_groups)
              .whereNotNull()
              .map((modifierGroup: ModifierGroupInterface) => ({
                ...modifierGroup,
                modifiers: collect(modifierGroup.modifiers)
                  .whereNotNull()
                  .map((modifier: ModifierInterface) => ({
                    ...modifier,
                    actual_price: modifier.free_eligible
                      ? 0
                      : modifier.actual_price,
                  }))
                  .toArray(),
              })),
          };
        })
        .keyBy("id"),
    [company?.products, modifierGroups]
  );

  const categories = useMemo<CategoryInterface[]>(() => {
    const freeItemsCategory: CategoryInterface = {
      id: 0,
      menu_id: null,
      menu_ids: [],
      type: CategoryType.FreeItems,
      name: t("labels.free_items"),
      image_url: null,
      product_ids: [],
      products: freeItems.toArray() as ProductInterface[],
    };

    return [
      ...(hasFreeItem ? [freeItemsCategory] : []),
      ...((mappedCategories
        .get(selectedMenu?.id)
        ?.toArray() as CategoryInterface[]) ?? []),
    ];
  }, [hasFreeItem, freeItems, selectedMenu, mappedCategories, i18n.language]);

  const combos = useMemo<Collection<ProductComboInterface>>(
    () =>
      collect(company?.products)
        .where("type", ProductType.Combo)
        .keyBy("combo_product_id"),
    [company?.products]
  );

  const findFreeProduct = useCallback<(id: number) => ProductInterface>(
    (id: number): ProductInterface => {
      return freeItems.get(id) ?? compileProduct(id);
    },
    [freeItems]
  );

  const findProductCombo = useCallback<
    (
      product: ProductInterface | ProductComboInterface
    ) => ProductComboInterface | null
  >(
    (
      product: ProductInterface | ProductComboInterface
    ): ProductComboInterface | null => {
      if (product.type === ProductType.Combo) {
        return null;
      }

      return combos.get(product.id);
    },
    [combos]
  );

  useEffect(() => {
    if (!selectedMenu) {
      setSelectedMenu(menus.firstWhere("is_active") ?? menus.first());
    }
  }, [menus]);

  useEffect(() => {
    if (!menus.firstWhere("id", selectedMenu?.id)) {
      setSelectedMenu(menus.firstWhere("is_active") ?? menus.first());
    }
  }, [menus]);

  useEffect(() => {
    if (selectedMenu) {
      preloadImages(
        collect(categories)
          .flatMap((category) => category?.products)
          .pluck("thumbnail_url")
          .filter((url: null | string) => !!url)
          .toArray()
      );
    }
  }, [selectedMenu]);

  const context: MenuContextInterface = {
    menus,
    selectedMenu,
    setSelectedMenu,
    categories,
    combos,
    compileProduct,
    findProductCombo,
    findFreeProduct,
    applyOrderMethodPrices,
  };

  return (
    <MenuContext.Provider value={context}>{children}</MenuContext.Provider>
  );
}
