import React from 'react';
import PageStyle from '../../../styles/pages/cookie-policy/GB';
import {useSelector} from "react-redux";
import {StoreInterface} from "../../../store/types";

export default function GB() {
    const { company } = useSelector((store: StoreInterface) => store.initialData);

    return (
        <div className="p-small">
            <PageStyle />
            <h1 className="c14" id="h.5ue3a6k7yehl"><span
              className="c10 c28">Use of Cookies on the {company?.name}</span>
            </h1>
            <p className="c16"><span className="c1">Cookies are small text files that provide information regarding the device used by a visitor. Where used, cookies are downloaded to your device and stored on the device. The table below explains the cookies this website uses and why.</span>
            </p><a id="t.cac883d666660a050358837c6ed8bc7118608773"></a><a id="t.0"></a>
            <table className="c29">
                <tr className="c26">
                    <td className="c3" colSpan={1} rowSpan={1}><p className="c13"><span className="c2 c10">Cookie</span>
                    </p></td>
                    <td className="c11" colSpan={1} rowSpan={1}><p className="c13"><span className="c10 c2">name</span>
                    </p></td>
                    <td className="c5" colSpan={1} rowSpan={1}><p className="c13"><span
                      className="c10 c2">Purpose</span></p></td>
                    <td className="c6" colSpan={1} rowSpan={1}><p className="c13"><span
                      className="c10 c2">Duration</span></p></td>
                    <td className="c4" colSpan={1} rowSpan={1}><p className="c13"><span
                      className="c10 c2">Category</span></p></td>
                </tr>
                <tr className="c24">
                    <td className="c3" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">Universal Analytics (Google)</span>
                    </p>
                    </td>
                    <td className="c11" colSpan={1} rowSpan={1}><p className="c0"><span className="c17 c2">_ga</span>
                    </p>
                        <p className="c0"><span className="c2">_gid</span></p></td>
                    <td className="c5" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">These cookies are used to collect information about how visitors use our website. We use the information to compile reports and to help us improve the website. The cookies collect information in a way that does not directly identify anyone</span>
                    </p></td>
                    <td className="c6" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c17 c2">_ga - 2 years</span></p>
                        <p className="c0"><span className="c2">_gid - 1 day</span></p></td>
                    <td className="c4" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">Performance</span>
                    </p></td>
                </tr>
                <tr className="c24">
                    <td className="c3" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">Amplitude</span>
                    </p></td>
                    <td className="c11" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">AMP_*</span></p>
                    </td>
                    <td className="c5" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">These cookies are used to collect information about how visitors use our website. We use the information to compile reports and to help us improve the website. The cookies collect information in a way that does not directly identify anyone</span>
                    </p></td>
                    <td className="c6" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">1 year</span></p>
                    </td>
                    <td className="c4" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">Performance</span>
                    </p></td>
                </tr>
                <tr className="c25">
                    <td className="c3" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">Cloudflare</span>
                    </p></td>
                    <td className="c11" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">__cfduid</span>
                    </p></td>
                    <td className="c5" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">This cookie is set by the CloudFlare service to identify trusted web traffic. It does not correspond to any user id in the web application, nor does the cookie store any personally identifiable information</span>
                    </p></td>
                    <td className="c6" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">2 months</span>
                    </p></td>
                    <td className="c4" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">Strictly necessary</span></p></td>
                </tr>
                <tr className="c27">
                    <td className="c3" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">Stripe</span></p>
                    </td>
                    <td className="c11" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2 c17">__stripe_mid</span></p>
                        <p className="c0"><span className="c17 c2">__stripe_orig_props</span></p>
                        <p className="c0"><span className="c2">__stripe_sid</span></p></td>
                    <td className="c5" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">Stripe is used to make card payments. Stripe uses a cookie to remember who you are and to enable the website to process payments without storing any card information on its own servers</span>
                    </p></td>
                    <td className="c6" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">1 year</span></p>
                    </td>
                    <td className="c4" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">Strictly necessary</span></p></td>
                </tr>
                <tr className="c9">
                    <td className="c3" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">Facebook</span>
                    </p></td>
                    <td className="c11" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">_fbp</span></p>
                    </td>
                    <td className="c5" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">This cookie is used to deliver personalised advertisements to users who have already visited our Mobile App using Facebook Advertising</span>
                    </p></td>
                    <td className="c6" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">3 months</span>
                    </p></td>
                    <td className="c4" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">Advertising</span>
                    </p></td>
                </tr>
                <tr className="c21">
                    <td className="c3" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">Tuck</span></p>
                    </td>
                    <td className="c11" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">tuckauth</span>
                    </p></td>
                    <td className="c5" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">This cookie is used to remember the fact that the user has logged in</span>
                    </p></td>
                    <td className="c6" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">6 months</span>
                    </p></td>
                    <td className="c4" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">Functional</span>
                    </p></td>
                </tr>
                <tr className="c21">
                    <td className="c3" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">Tuck</span></p>
                    </td>
                    <td className="c11" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">tuck_auth_transition</span></p></td>
                    <td className="c5" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">This cookie is used to remember the fact that the user has logged in</span>
                    </p></td>
                    <td className="c6" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">6 months</span>
                    </p></td>
                    <td className="c4" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">Functional</span>
                    </p></td>
                </tr>
                <tr className="c21">
                    <td className="c3" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">Tuck</span></p>
                    </td>
                    <td className="c11" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">cookieconsent</span></p></td>
                    <td className="c5" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">This cookie is used to remember your preferences related to cookies</span>
                    </p></td>
                    <td className="c6" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">6 months</span>
                    </p></td>
                    <td className="c4" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">Strictly necessary</span></p></td>
                </tr>
                <tr className="c21">
                    <td className="c3" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">Tuck</span></p>
                    </td>
                    <td className="c11" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">tuckauth</span>
                    </p></td>
                    <td className="c5" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">This cookie is used to remember the fact that the user has logged in</span>
                    </p></td>
                    <td className="c6" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">Session</span></p>
                    </td>
                    <td className="c4" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">Strictly necessary</span></p></td>
                </tr>
                <tr className="c21">
                    <td className="c3" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">Tuck</span></p>
                    </td>
                    <td className="c11" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">tuck-token</span>
                    </p></td>
                    <td className="c5" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">Used to help with authentication on certain browsers</span>
                    </p></td>
                    <td className="c6" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">6 months</span>
                    </p></td>
                    <td className="c4" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">Functional</span>
                    </p></td>
                </tr>
                <tr className="c21">
                    <td className="c3" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">Tuck</span></p>
                    </td>
                    <td className="c11" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">tuck-token</span>
                    </p></td>
                    <td className="c5" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">Used to help with authentication on certain browsers</span>
                    </p></td>
                    <td className="c6" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">Session</span></p>
                    </td>
                    <td className="c4" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">Strictly necessary</span></p></td>
                </tr>
                <tr className="c21">
                    <td className="c3" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">Tuck</span></p>
                    </td>
                    <td className="c11" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">$fd.wo.b[*]</span></p></td>
                    <td className="c5" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">This is used to remember items in your basket</span>
                    </p></td>
                    <td className="c6" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">Browser Controlled</span></p></td>
                    <td className="c4" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">Strictly necessary</span></p></td>
                </tr>
                <tr className="c21">
                    <td className="c3" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">Tuck</span></p>
                    </td>
                    <td className="c11" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">logLevel:*</span>
                    </p></td>
                    <td className="c5" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">Used for debugging purposes</span>
                    </p>
                    </td>
                    <td className="c6" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">Browser Controlled</span></p></td>
                    <td className="c4" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">Strictly necessary</span></p></td>
                </tr>
                <tr className="c9">
                    <td className="c3" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">Tuck</span></p>
                    </td>
                    <td className="c11" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">tuck-delivery-locations</span></p></td>
                    <td className="c5" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">This important information relates to your delivery locations and is saved to the browser to prevent unnecessary data usage</span>
                    </p></td>
                    <td className="c6" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">Browser Controlled</span></p></td>
                    <td className="c4" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">Strictly necessary</span></p></td>
                </tr>
                <tr className="c9">
                    <td className="c3" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">Tuck</span></p>
                    </td>
                    <td className="c11" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">tuck-customer</span></p></td>
                    <td className="c5" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">This important information relates to your customer details and is saved to the browser to prevent unnecessary data usage</span>
                    </p></td>
                    <td className="c6" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">Browser Controlled</span></p></td>
                    <td className="c4" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">Strictly necessary</span></p></td>
                </tr>
                <tr className="c9">
                    <td className="c3" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">Tuck</span></p>
                    </td>
                    <td className="c11" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">tuck-pickup-restaurant-*</span></p></td>
                    <td className="c5" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">This important information relates to the details of the store and is saved to the browser to prevent unnecessary data usage</span>
                    </p></td>
                    <td className="c6" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">Browser Controlled</span></p></td>
                    <td className="c4" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">Strictly necessary</span></p></td>
                </tr>
                <tr className="c27">
                    <td className="c3" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">Tuck</span></p>
                    </td>
                    <td className="c11" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">tuck-location</span></p></td>
                    <td className="c5" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">This important information relates to your location in the world, so that relevant locations are shown first, and is saved to the browser to prevent unnecessary data usage</span>
                    </p></td>
                    <td className="c6" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">Browser Controlled</span></p></td>
                    <td className="c4" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">Strictly necessary</span></p></td>
                </tr>
                <tr className="c21">
                    <td className="c3" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">Tuck</span></p>
                    </td>
                    <td className="c11" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">$fd.wo.b.om</span></p></td>
                    <td className="c5" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">Used for when you may be redirected for 3D secure purposes when making payment</span>
                    </p></td>
                    <td className="c6" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">Session</span></p>
                    </td>
                    <td className="c4" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">Strictly necessary</span></p></td>
                </tr>
                <tr className="c9">
                    <td className="c3" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">Tuck</span></p>
                    </td>
                    <td className="c11" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">flipdsh-current-language</span></p></td>
                    <td className="c5" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">This important information relates to your language settings and is saved to the browser to prevent unnecessary data usage</span>
                    </p></td>
                    <td className="c6" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">Browser Controlled</span></p></td>
                    <td className="c4" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">Strictly necessary</span></p></td>
                </tr>
                <tr className="c9">
                    <td className="c3" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">Tuck</span></p>
                    </td>
                    <td className="c11" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">flipdsh-language</span></p></td>
                    <td className="c5" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">This important information relates to your language settings and is saved to the browser to prevent unnecessary data usage</span>
                    </p></td>
                    <td className="c6" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">Browser Controlled</span></p></td>
                    <td className="c4" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">Strictly necessary</span></p></td>
                </tr>
                <tr className="c21">
                    <td className="c3" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">Tuck</span></p>
                    </td>
                    <td className="c11" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">tuck-test-key</span></p></td>
                    <td className="c5" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">Used for debugging and testing purposes</span></p></td>
                    <td className="c6" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">Browser Controlled</span></p></td>
                    <td className="c4" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">Strictly necessary</span></p></td>
                </tr>
                <tr className="c9">
                    <td className="c3" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">Tuck</span></p>
                    </td>
                    <td className="c11" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">tuck-app-config</span></p></td>
                    <td className="c5" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">This important information relates to the details of the business, and is saved to the browser to prevent unnecessary data usage</span>
                    </p></td>
                    <td className="c6" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">Browser Controlled</span></p></td>
                    <td className="c4" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">Strictly necessary</span></p></td>
                </tr>
                <tr className="c9">
                    <td className="c3" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">Tuck</span></p>
                    </td>
                    <td className="c11" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">tuck-last-url</span></p></td>
                    <td className="c5" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">Used to remember at what stage of the ordering process you were in when you left last time, so that you can pick up where you left off on your return</span>
                    </p></td>
                    <td className="c6" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">Browser Controlled</span></p></td>
                    <td className="c4" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">Strictly necessary</span></p></td>
                </tr>
                <tr className="c21">
                    <td className="c3" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">Tuck</span></p>
                    </td>
                    <td className="c11" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">tuck-last-url-expiry</span></p></td>
                    <td className="c5" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">Used to expire the last url</span>
                    </p>
                    </td>
                    <td className="c6" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">Browser Controlled</span></p></td>
                    <td className="c4" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">Strictly necessary</span></p></td>
                </tr>
                <tr className="c21">
                    <td className="c3" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">Tuck</span></p>
                    </td>
                    <td className="c11" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">$fd.wo.pa</span>
                    </p></td>
                    <td className="c5" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">Used to cache user payments. This information is cleared on logout</span>
                    </p></td>
                    <td className="c6" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">Session</span></p>
                    </td>
                    <td className="c4" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">Strictly necessary</span></p></td>
                </tr>
                <tr className="c21">
                    <td className="c3" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">Tuck</span></p>
                    </td>
                    <td className="c11" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">tuck-device-id</span></p></td>
                    <td className="c5" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">This is used for logging, performance and debugging purposes</span>
                    </p></td>
                    <td className="c6" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">Browser Controlled</span></p></td>
                    <td className="c4" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">Strictly necessary</span></p></td>
                </tr>
                <tr className="c21">
                    <td className="c3" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">Tuck</span></p>
                    </td>
                    <td className="c11" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">tuck-redirect</span></p></td>
                    <td className="c5" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">Used for handling your choice around whether to redirect you to the mobile app or not</span>
                    </p></td>
                    <td className="c6" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">Browser Controlled</span></p></td>
                    <td className="c4" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">Strictly necessary</span></p></td>
                </tr>
                <tr className="c21">
                    <td className="c3" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">Tuck</span></p>
                    </td>
                    <td className="c11" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">_dd_s</span></p>
                    </td>
                    <td className="c5" colSpan={1} rowSpan={1}><p className="c0"><span className="c2">Used for error logging via Datadog</span>
                    </p></td>
                    <td className="c6" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">Browser Controlled</span></p></td>
                    <td className="c4" colSpan={1} rowSpan={1}><p className="c0"><span
                      className="c2">Strictly necessary</span></p></td>
                </tr>
            </table>
            <p className="c16"><span className="c1">If you reject all non-strictly necessary cookies, you may not be able to take full advantage of our website.</span>
            </p>
            <h2 className="c12" id="h.34ov7izfsvp9"><span className="c10 c20">Third-party providers</span></h2>
            <p className="c16"><span className="c1">We work with third-party providers like Google, Stripe and Paypal, which also set their own cookies for purposes including analytics and marketing purposes. This list may not be exhaustive, but we commit to keeping it updated on a regular basis based on information from these providers. Please see the following resources for more detailed information about the cookies set by Google, Stripe and Paypal:</span>
            </p>
            <ul className="c8 lst-kix_7zadmqcdx2q7-0 start">
                <li className="c15 li-bullet-0"><span className="c19"><a className="c7"
                                                                         href="https://www.google.com/url?q=https://policies.google.com/technologies/cookies%23types-of-cookies&amp;sa=D&amp;source=editors&amp;ust=1697472248078693&amp;usg=AOvVaw3vnVBxiZKkZ6VqxgaNU0IV">Google documentation</a></span>
                </li>
                <li className="c15 li-bullet-0"><span className="c19"><a className="c7"
                                                                         href="https://www.google.com/url?q=https://stripe.com/legal/cookies-policy&amp;sa=D&amp;source=editors&amp;ust=1697472248079247&amp;usg=AOvVaw1GGYLRFSA16RzHXxushDMn">Stripe documentation</a></span>
                </li>
                <li className="c15 li-bullet-0"><span className="c19"><a className="c7"
                                                                         href="https://www.google.com/url?q=https://www.paypal.com/webapps/mpp/ua/cookie-full&amp;sa=D&amp;source=editors&amp;ust=1697472248079601&amp;usg=AOvVaw2tOZ8yjx3W2kqjYPx2Yt0q">Paypal documentation</a></span>
                </li>
            </ul>
            <h2 className="c12" id="h.wa0tzif8u61c"><span className="c20 c10">How do I change my cookie settings?</span>
            </h2>
            <p className="c16"><span className="c1">You can change your cookie preferences at any time through the Cookie Settings. You can then adjust the sliders to &quot;On&quot; or &quot;Off&quot;, and then click &quot;Done&quot;. You may need to refresh your page for the settings to take effect.</span>
            </p>
            <p className="c16"><span className="c1">You may, at any time, delete or configure cookies by configuring your internet browser. Please visit the dedicated help sections of your browser for information on cookie settings:</span>
            </p>
            <ul className="c8 lst-kix_14qqezpp1pyb-0 start">
                <li className="c15 li-bullet-0"><span className="c19"><a className="c7"
                                                                         href="https://www.google.com/url?q=https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies&amp;sa=D&amp;source=editors&amp;ust=1697472248080283&amp;usg=AOvVaw3Zn2k-UD255iaPrjZ-Sruy">Internet Explorer</a></span>
                </li>
                <li className="c15 li-bullet-0"><span className="c19"><a className="c7"
                                                                         href="https://www.google.com/url?q=https://support.google.com/chrome/answer/95647?hl%3Den%26ref_topic%3D14666&amp;sa=D&amp;source=editors&amp;ust=1697472248080657&amp;usg=AOvVaw2DNsGpjJoCjCqRq9Y5aWxZ">Chrome</a></span>
                </li>
                <li className="c15 li-bullet-0"><span className="c19"><a className="c7"
                                                                         href="https://www.google.com/url?q=https://support.mozilla.com/en-US/kb/cookies-information-websites-store-on-your-computer&amp;sa=D&amp;source=editors&amp;ust=1697472248081031&amp;usg=AOvVaw3k_OSMY-B7NoeXVyFt-D-n">Firefox</a></span>
                </li>
                <li className="c15 li-bullet-0"><span className="c19"><a className="c7"
                                                                         href="https://www.google.com/url?q=https://support.apple.com/en-ie/guide/safari/manage-cookies-and-website-data-sfri11471/mac&amp;sa=D&amp;source=editors&amp;ust=1697472248081382&amp;usg=AOvVaw1dKhsf0t7MGZTNnYHfQkkE">Safari web </a></span><span>and </span><span
                  className="c19"><a className="c7"
                                     href="https://www.google.com/url?q=https://support.apple.com/en-ie/HT201265&amp;sa=D&amp;source=editors&amp;ust=1697472248081621&amp;usg=AOvVaw2WM9lI4u4UTRoDT1Ss_ldU">Safari for iOS</a></span>
                </li>
            </ul>
            <p className="c16"><span className="c1">To find information relating to other browsers, visit the browser developer&#39;s website.</span>
            </p>
            <h2 className="c12" id="h.skbuzt597oqm"><span className="c20 c10">Controller details</span></h2>
            <p className="c16"><span>The controller is {company?.name} </span><span
              className="c10">{company?.name} </span><span>(&quot;</span><span
              className="c10">we</span><span>&quot;, &quot;</span><span
              className="c10">us</span><span>&quot; or &quot;</span><span
              className="c10">our</span><span className="c1">&quot;). We may be contacted at the contact details provided on our website.</span>
            </p>
            <p className="c23"><span className="c1"></span></p>
        </div>
    );
}