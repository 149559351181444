import useTheme from "../../hooks/ui/useTheme";
import { ThemeInterface } from "../../services/exports/Interfaces";

interface Props {
  className?: string;
  style?: Object;
  color?: string;
  type?: "outline";
}

export default function UserIcon(props: Props) {
  const { className, color, style, type } = props;
  const theme: ThemeInterface = useTheme();
  if (type === "outline") {
    return (
      <svg
        className={className ?? "h-4 w-4"}
        viewBox="0 0 18 16"
        fill="none"
        style={style}
      >
        <path
          d="M8.99965 7.72947C7.86599 7.72947 6.93846 7.36876 6.21704 6.64734C5.49562 5.92593 5.13491 4.99839 5.13491 3.86473C5.13491 2.73108 5.49562 1.80354 6.21704 1.08213C6.93846 0.360709 7.86599 0 8.99965 0C10.1333 0 11.0608 0.360709 11.7823 1.08213C12.5037 1.80354 12.8644 2.73108 12.8644 3.86473C12.8644 4.99839 12.5037 5.92593 11.7823 6.64734C11.0608 7.36876 10.1333 7.72947 8.99965 7.72947ZM0.754883 16V13.5781C0.754883 12.9254 0.91806 12.3671 1.24442 11.9034C1.57077 11.4396 1.9916 11.0875 2.5069 10.847C3.65773 10.3317 4.76132 9.94525 5.81769 9.6876C6.87405 9.42995 7.9347 9.30113 8.99965 9.30113C10.0646 9.30113 11.121 9.43425 12.1687 9.70048C13.2165 9.96672 14.3158 10.3489 15.4666 10.847C15.9991 11.0875 16.4285 11.4396 16.7549 11.9034C17.0812 12.3671 17.2444 12.9254 17.2444 13.5781V16H0.754883ZM2.30078 14.4541H15.6985V13.5781C15.6985 13.3033 15.6169 13.0413 15.4538 12.7923C15.2906 12.5432 15.0888 12.3586 14.8483 12.2383C13.749 11.7059 12.7441 11.3408 11.8338 11.1433C10.9234 10.9458 9.97871 10.847 8.99965 10.847C8.02058 10.847 7.06728 10.9458 6.13975 11.1433C5.21221 11.3408 4.20738 11.7059 3.12525 12.2383C2.88478 12.3586 2.68725 12.5432 2.53266 12.7923C2.37807 13.0413 2.30078 13.3033 2.30078 13.5781V14.4541ZM8.99965 6.18358C9.66954 6.18358 10.2235 5.96457 10.6615 5.52657C11.0995 5.08857 11.3185 4.53462 11.3185 3.86473C11.3185 3.19485 11.0995 2.6409 10.6615 2.2029C10.2235 1.7649 9.66954 1.54589 8.99965 1.54589C8.32976 1.54589 7.77582 1.7649 7.33781 2.2029C6.89981 2.6409 6.68081 3.19485 6.68081 3.86473C6.68081 4.53462 6.89981 5.08857 7.33781 5.52657C7.77582 5.96457 8.32976 6.18358 8.99965 6.18358Z"
          fill={color ?? theme.color.brand.inkGrey.grey_3}
        />
      </svg>
    );
  }
  return (
    <svg
      className={className ?? "h-4 w-4"}
      viewBox="0 0 20 19"
      fill="none"
      style={style}
    >
      <path
        d="M9.99414 18.1846C14.9775 18.1846 19.0908 14.0713 19.0908 9.08789C19.0908 4.11328 14.9688 0 9.98535 0C5.01074 0 0.90625 4.11328 0.90625 9.08789C0.90625 14.0713 5.01953 18.1846 9.99414 18.1846ZM9.99414 8.78027C8.71094 8.78027 7.67383 7.69922 7.67383 6.24902C7.67383 4.9043 8.71094 3.7793 9.99414 3.7793C11.2686 3.7793 12.3057 4.9043 12.3057 6.24902C12.3057 7.69922 11.2686 8.78906 9.99414 8.78027ZM5.96875 13.667C5.55566 13.667 5.3623 13.3945 5.3623 13.0254C5.3623 12.041 6.82129 9.50098 9.99414 9.50098C13.1582 9.50098 14.6172 12.041 14.6172 13.0254C14.6172 13.3945 14.4238 13.667 14.0195 13.667H5.96875Z"
        fill={color ?? theme.color.brand.inkGrey.grey_3}
      />
    </svg>
  );
}
