export default {
  header: {
    rate_us: 'Bewerte uns & erhalte',
    cashback: '{{value}} Punkte = {{moneyValue}}',
    earn_points_get_free_rewards: "{{count}} Punkte = Prämien",
    upload_google_review_get_free_rewards: "Bewertung = Prämien",
  },
  cashback_reward: '{{value}} Punkte ({{moneyValue}})',
  cashback_benefits: {
    text: 'Willkommen in unserem Treueprogramm. Sammle Punkte bei jeder Bestellung und nutze sie, um Rabatte zu bekommen. 🤩',
    title: 'Benefits',
    earn: '✅ Verdiene {{value}} Punkte für jeden ausgegebenen {{amount}}.',
    redeem: '✅ Löse deine Punkte ein, um bis zu 100% Rabatt auf deine nächste Bestellung zu erhalten.',
    max_spending_limit: '✅ Maximaler Rabatt von {{value}} pro Bestellung.',
    point_lifetime: '✅ Jeder Treuepunkt ist {{value}} Tage gültig',
  },
  enter_phone_number: {
    title: 'Handynummer',
    description: 'Wir brauchen es, um sicherzustellen, dass wir die richtige Person belohnen',
    place_holder: 'Handynummer',
  },
  button: {
    continue: "Weiter",
  },
  product_suggestions: {
    loyalty: {
      title: "Punkte für Rabatte & gratis Essen",
      description: "Erhalte Punkte für Rabatte & gratis Essen",
    },
    googleReview: {
      title: "Bewerte uns & erhalte",
    },
  },
};
