import { useState, useEffect, useMemo } from "react";
import Theme from "../components-library/Theme";
import ScreenTypeContext from "../context/ScreenTypeContext";
import { useWindowSize } from "../hooks/utility/useWindowSize";
import { SCREEN_TYPES } from "../services/exports/Constants";
import { ScreenTypeContextInterface } from "../services/exports/Interfaces";

interface Props {
  children: any;
}

const ScreenTypeProvider = (props: Props): JSX.Element => {
  const { children } = props;

  const windowSize = useWindowSize();

  const [screenType, setScreenType] = useState<SCREEN_TYPES>(
    SCREEN_TYPES.DESKTOP
  );
  const width = windowSize.width ?? 0;

  function handleResize() {
    if (width > Theme.dimension.breakPoints.xl) {
      setScreenType(SCREEN_TYPES.DESKTOP);
    } else if (
      width < Theme.dimension.breakPoints.xl &&
      width > Theme.dimension.breakPoints.lg
    ) {
      setScreenType(SCREEN_TYPES.DESKTOP);
    } else if (
      width < Theme.dimension.breakPoints.lg &&
      width > Theme.dimension.breakPoints.md
    ) {
      setScreenType(SCREEN_TYPES.TABLET);
    } else if (width < Theme.dimension.breakPoints.md) {
      setScreenType(SCREEN_TYPES.MOBILE);
    }
  }

  useEffect(() => {
    handleResize();
  }, [width]);

  const context = useMemo<ScreenTypeContextInterface>(
    () => ({
      isDesktop: screenType === SCREEN_TYPES.DESKTOP,
      isTablet: screenType === SCREEN_TYPES.TABLET,
      isMobile: screenType === SCREEN_TYPES.MOBILE,
    }),
    [screenType, windowSize]
  );

  return (
    <ScreenTypeContext.Provider value={context}>
      {children}
    </ScreenTypeContext.Provider>
  );
};

export default ScreenTypeProvider;
