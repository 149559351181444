import { useEffect } from "react";
import GlobalStyle from "./styles/GlobalStyles";
import Router from "./navigation/Router";
import { positions, Provider as AlertProvider, transitions } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import { useSelector } from "react-redux";
import { ENVIRONMENTS, EventHelperTypes } from "./services/exports/Constants";
import HelperMethods from "./services/exports/HelperMethods";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import ThemeContext from "./context/ThemeContext";
import useCreateTheme from "./hooks/ui/useCreateTheme";
import MetaInfo from "./components-library/MetaInfo";
import ErrorScreen from "./pages/ErrorScreen";
import { useLoadScript } from "@react-google-maps/api";
import useSideEffects from "./hooks/global/useSideEffects";
import useManifest from "./hooks/seo/useManifest";
import ChannelStack from "./pusher-channels/ChannelStack";
import ReduxActions from "./store/ReduxActions";
import { actionCreators } from "./store/actions";
import CustomToaster from "./components-library/CustomToaster";
import StripeProvider from "./providers/StripeProvider";
import useStore from "./hooks/global/useStore";
import MenuProvider from "./providers/MenuProvider";
import InitialDataProvider from "./providers/InitialDataProvider";
import AppMethodsProvider from "./providers/AppMethodsProvider";
import AlertModalProvider from "./providers/AlertModalProvider";
import PusherProvider from "./providers/PusherProvider";
import AppStateProvider from "./providers/AppStateProvider";
import CompanySlugProvider from "./providers/CompanySlugProvider";
import ScreenTypeProvider from "./providers/ScreenTypeProvider";
import * as Sentry from "@sentry/react";
import BasketProvider from "./providers/BasketProvider";
import { StoreInterface } from "./store/types";
import LoadingProvider from "./providers/LoadingProvider";
import PaymentWalletsValidator from "./components-library/payment/wallet-validators/PaymentWalletsValidator";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ProductLikesProvider from "./providers/ProductLikesProvider";

const options = {
  position: positions.MIDDLE,
  timeout: 3000,
  offset: "-30px",
  transition: transitions.SCALE,
  containerStyle: { zIndex: 5000 },
};

const libraries = ["places", "geometry"];

function App() {
  const { event } = useStore()?.eventHelper;
  const { environment } = useSelector((state: StoreInterface) => state.appStatus);
  const { company } = useSelector((store: StoreInterface) => store.initialData);
  const { cached_order } = useSelector((state: StoreInterface) => state.order);
  const { profile } = useSelector((state: StoreInterface) => state.profile);

  const theme = useCreateTheme();
  const paypalConfig = {
    "client-id": import.meta.env.VITE_PAYPAL_CLIENT_ID,
    currency: "EUR", // todo change to dynamic currency of company
    components: "buttons",
  };

  const muiTheme = createTheme({
    palette: {
      primary: {
        main: theme.color.companyBrand.primary,
      },
    },
  });

  useLoadScript({
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
    // @ts-ignore
    libraries,
  });

  useSideEffects();
  useManifest();

  useEffect(() => {
    if (isReduxOrderValid()) {
      return;
    }

    return ReduxActions.dispatch(actionCreators.order.resetOrder({}));
  }, []);

  useEffect(() => {
    handleReduxSideEffects();
  }, [event]);

  useEffect(() => {
    window.gtag('config', company?.service_provider?.google_analytics_id, {
      user_id: profile?.id,
      debug_mode: import.meta.env.VITE_MODE !== ENVIRONMENTS.PROD,
    });
  }, [profile?.id, company?.service_provider?.google_analytics_id]);

  useEffect(() => {
    if (!import.meta.env.DEV) {
      Sentry.getCurrentScope().setUser({
        id: profile?.id,
      });
    }
  }, [profile?.id]);

  function isReduxOrderValid(): boolean {
    return !!cached_order?.method;
  }

  async function handleReduxSideEffects() {
    try {
      const { type } = event;
      if (!type) {
        return;
      }

      return new Promise((resolve, reject) => {
        switch (type) {
          case EventHelperTypes.LOG_OUT:
            clearAllData(true);
            break;
        }
        resolve(true);
        reject(false);
      }).then(HelperMethods.resetEventSideEffects);
    } catch (error) {}
  }

  useEffect(() => {
    if (import.meta.env.VITE_MODE !== ENVIRONMENTS.PROD) {
      clearAllData();
    }
  }, []);

  function clearAllData(force: boolean = false): void {
    new Promise((resolve) => {
      if (force || (environment && environment !== import.meta.env.VITE_MODE)) {
        localStorage.clear();
        ReduxActions.clearReduxStore();
        window.location.reload();
      }

      resolve(true);
    }).finally(() => {
      ReduxActions.dispatch(
        actionCreators.appStatus.setEnvironment(import.meta.env.VITE_MODE)
      );
    });
  }

  return (
    <div data-test="body">
      <GlobalStyle />

      <Sentry.ErrorBoundary fallback={<ErrorScreen />}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <ThemeProvider theme={muiTheme}>
            <AppStateProvider>
              <AppMethodsProvider>
                <InitialDataProvider>
                  <PusherProvider>
                    <ScreenTypeProvider>
                      <ThemeContext.Provider value={theme}>
                        <AlertModalProvider>
                          <AlertProvider template={AlertTemplate} {...options}>
                            <PayPalScriptProvider options={paypalConfig}>
                              <MenuProvider>
                                <BasketProvider>
                                  <CompanySlugProvider>
                                    <LoadingProvider>
                                      <StripeProvider>
                                        <ProductLikesProvider>
                                          <PaymentWalletsValidator />
                                          <Router />
                                        </ProductLikesProvider>
                                      </StripeProvider>
                                    </LoadingProvider>
                                    <MetaInfo />
                                    <ChannelStack />
                                  </CompanySlugProvider>
                                </BasketProvider>
                              </MenuProvider>
                            </PayPalScriptProvider>
                            <CustomToaster />
                            <MetaInfo />
                          </AlertProvider>
                        </AlertModalProvider>
                      </ThemeContext.Provider>
                    </ScreenTypeProvider>
                  </PusherProvider>
                </InitialDataProvider>
              </AppMethodsProvider>
            </AppStateProvider>
          </ThemeProvider>
        </LocalizationProvider>
      </Sentry.ErrorBoundary>
    </div>
  );
}

export default App;
