import {ReactElement, useCallback, useEffect, useMemo, useRef, useState,} from "react";
import collect from "collect.js";
import {useSelector} from "react-redux";
import {useLocation, useSearchParams} from "react-router-dom";
import EmptyCartIcon from "../assets/logo/EmptyCartIcon";
import CustomButton from "../components-library/buttons/CustomButton";
import HeaderBar from "../components-library/headers/HeaderBar";
import IllustrationExplainer from "../components-library/IllustrationExplainer";
import FeedbackLabel from "../components-library/labels/tinyLabels/FeedbackLabel";
import OrderBasketItem from "../components-library/basket/OrderBasketItem";
import LoadingPage from "../components-library/loading/LoadingPage";
import CheckoutNavBar from "../components-library/navigation/CheckoutNavBar";
import Tips from "../components-library/checkout/Tips";
import OrderManager from "../services/api/OrderManager";
import ProfileManager from "../services/api/ProfileManager";
import PromotionManager from "../services/api/PromotionManager";
import PurchaseManager from "../services/api/PurchaseManager";
import {
  COUPON_TYPES,
  DeliveryProvider,
  ERROR_CODES,
  ORDER_METHODS,
  ORDER_PAYMENT_STATUSES,
  PAYMENT_ERROR_RESPONSE_STATUS,
  PaymentMethod,
  PUSHER_EVENTS,
  SCREENS,
  TaxBehaviour,
} from "../services/exports/Constants";
import HelperMethods from "../services/exports/HelperMethods";
import {
  ButtonTypes,
  CreateOrderPaymentInterface,
  FeedbackLabelInterface,
  OrderDataInterface,
  OrderInterface,
  OrderItemInterface,
  OrderPaymentInterface,
  PaymentOptionPropertiesInterface,
  PromoComponentInterface,
  PromotionCodeDataInterface,
  RewardInterface,
} from "../services/exports/Interfaces";
import * as stripeJs from "@stripe/stripe-js";
import usePaymentMethods from "../hooks/payment/usePaymentMethods";
import {orderChannel} from "../services/pusher/Channels";
import PaymentMethodModal from "../modals/PaymentMethodModal";
import {PayPalButtons} from "@paypal/react-paypal-js";
import PayPalManager from "../services/api/PayPalManager";
import {DataWrappedResponse, DataWrapper} from "../services/exports/Types";
import {CopyToClipboard} from "react-copy-to-clipboard";
import CheckmarkIcon from "../assets/logo/CheckmarkIcon";
import useAlert from "../hooks/utility/useAlert";
import useTheme from "../hooks/ui/useTheme";
import DiscountIcon from "../assets/logo/DiscountIcon";
import ReduxActions from "../store/ReduxActions";
import {actionCreators} from "../store/actions";
import DeliveryThreshold from "../components-library/delivery/DeliveryThreshold";
import useDeliveryThreshold from "../hooks/order/useDeliveryThreshold";
import AuthorizationTokenStorage from "../services/auth/AuthorizationTokenStorage";
import LoadingWithText from "../components-library/loading/LoadingWithText";
import {debounce} from "lodash";
import OrderPaymentDraftsManager from "../services/api/OrderPaymentDraftsManager";
import CustomLoadingIndicator from "../components-library/loading/CustomLoadingIndicator";
import PartiesManager from "../services/api/PartiesManager";
import OrderPaymentsManager from "../services/api/OrderPaymentsManager";
import useDineInFlow from "../hooks/global/useDineInFlow";
import CheckoutThreshold from "../components-library/checkout/CheckoutThreshold";
import useCheckoutThreshold from "../hooks/order/useCheckoutThreshold";
import StripePaymentRequestButton from "../components-library/payment/StripePaymentRequestButton";
import PaymentManager from "../services/api/PaymentManager";
import DineInNavBar from "../components-library/navigation/DineInNavBar";
import usePusher from "../hooks/global/usePusher";
import LoyaltyPointsLabel from "../components-library/rewards/LoyaltyPointsLabel";
import {useTranslation} from "react-i18next";
import ButtonFooter from "../components-library/footers/ButtonFooter";
import useNavigate from "../hooks/navigation/useNavigate";
import useInitialData from "../hooks/global/useInitialData";
import useTips from "../hooks/order/useTips";
import InfoIcon from "../assets/logo/InfoIcon";
import PageLayout from "../components-library/layouts/PageLayout";
import useBasket from "../hooks/basket/useBasket";
import {CompleteBasketItemInterface} from "../context/types";
import {StoreInterface} from "../store/types";
import moment from "moment";
import DuplicateOrderModal from "../modals/checkout/DuplicateOrderModal";
import useAppMethods from "../hooks/utility/useAppMethods";
import useStripeHelpers from "../hooks/payment/useStripeHelpers";
import Tooltip from "../components-library/Tooltip";
import useGoogleAnalytics from "../hooks/analytics/useGoogleAnalytics";
import CheckoutStepper from "../components-library/checkout/stepper/CheckoutStepper";
import PromoCodeInput from "../components-library/checkout/PromoCodeInput";
import OrderBasketCashback from "../components-library/cashback/OrderBasketCashback";

interface LocationStateInterface {
  order?: OrderInterface;
  defaultActiveTipIndex?: number;
}

const CheckoutScreen = (): JSX.Element => {
  const { t } = useTranslation(null, { keyPrefix: 'Pages:CheckoutScreen' });

  const { company } = useSelector((state: StoreInterface) => state.initialData);
  const { profile } = useSelector((state: StoreInterface) => state.profile);
  const { cached_order } = useSelector((state: StoreInterface) => state.order);

  const { refresh } = useInitialData();
  const { formatCurrency, saveMenu, toggleOrderMethodModal } = useAppMethods();
  const { trackBeginCheckout } = useGoogleAnalytics();

  const { isDineIn, party, paymentInfo, masterOrder, isBillSplittable } = useDineInFlow();
  const { isWithinMinThreshold, isWithinMaxThreshold, isWithinThreshold } = useDeliveryThreshold();
  const { hasReachedCheckoutThreshold } = useCheckoutThreshold();
  const { defaultOption: defaultTipOption, calculate: calculateTip } = useTips();

  const {
    data: basketData,
    items,
    subtotal,
    total,
    formatItemForBackend,
  } = useBasket();

  const theme = useTheme();
  const alert = useAlert();
  const { connect, disconnect, unsubscribe } = usePusher();

  const { navigate } = useNavigate();
  const location = useLocation() as any;
  const locationState: LocationStateInterface = location?.state;

  const [searchParams] = useSearchParams();
  const urlParams = Object.fromEntries([...searchParams]);

  const defaultPaymentMethodId: PaymentMethod | undefined = urlParams?.defaultPaymentMethodId as PaymentMethod ?? undefined;

  const initializedOrder: OrderInterface | undefined = locationState?.order;
  const [order, setOrder] = useState<OrderInterface | null>(initializedOrder);
  const [checkoutOrder, setCheckoutOrder] = useState<OrderInterface | null>(
    initializedOrder
  );

  const [defaultPaymentOption, setDefaultPaymentOption] =
    useState<PaymentOptionPropertiesInterface | null>(null);

  const { enabledWallets, guessPaymentDestination, setPaymentDestination } = useStripeHelpers();
  const {
    paymentOptions,
    getDefaultPaymentOption,
    handleCardConfirmation,
    handleAppleGooglePayConfirmation,
    handleSofortConfirmation,
    handleGiropayConfirmation,
    handlePaypalConfirmation,
    handlePaypalStandaloneConfirmation,
  } = usePaymentMethods();

  const promoCode = cached_order?.coupon_details?.code ?? "";
  const promoApplied = !!cached_order?.coupon_details?.code;
  const [promoCodeThresholdReached, setPromoCodeThresholdReached] = useState(!!cached_order?.coupon_details?.code);
  const [promoCodeCopiedToClipboard, setPromoCodeCopiedToClipboard] = useState<string | null>(null);
  const [availablePromoCodes, setAvailablePromoCodes] = useState<PromotionCodeDataInterface[]>([]);

  const [tip, setTip] = useState(getDefaultTip());
  const [activeTipIndex, setActiveTipIndex] = useState(getDefaultTipIndex());

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingDraft, setLoadingDraft] = useState<boolean>(false);
  const [purchasing, setPurchasing] = useState<boolean>(false);
  const [removingPaymentMethod, setRemovingPaymentMethod] =
    useState<boolean>(false);
  const [screenInitialized, setScreenInitialized] = useState<boolean>(false);
  const [purchaseSuccessful, setPurchaseSuccessful] = useState<boolean>(false);
  const [readyForPurchase, setReadyForPurchase] = useState<boolean>(false);
  const [duplicateOrderAware, setDuplicateOrderAware] = useState<boolean>(false);

  const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false);
  const [duplicateOrderModalProps, setDuplicateOrderModalProps] = useState({
    open: false,
    order: null,
  });
  const [feedback, setFeedback] = useState<FeedbackLabelInterface>({
    message: "",
  });
  const [errorType, setErrorType] = useState<null | string>(null);

  const orderRef = useRef(order);
  orderRef.current = order;

  const removingPaymentMethodRef = useRef(removingPaymentMethod);
  removingPaymentMethodRef.current = removingPaymentMethod;

  const errorTypeRef = useRef(errorType);
  errorTypeRef.current = errorType;

  const defaultPaymentOptionRef = useRef(defaultPaymentOption);
  defaultPaymentOptionRef.current = defaultPaymentOption;

  const checkoutOrderRef = useRef<OrderInterface | null>(checkoutOrder);
  checkoutOrderRef.current = checkoutOrder;

  const cachedOrderRef = useRef<Partial<OrderInterface> | null>(cached_order);
  cachedOrderRef.current = cached_order;

  const tipRef = useRef(tip);
  tipRef.current = tip;

  const orderItemsRef = useRef(items);
  orderItemsRef.current = items;

  const promoCodeRef = useRef(promoCode);
  promoCodeRef.current = promoCode;

  const promoAppliedRef = useRef(promoApplied);
  promoAppliedRef.current = promoApplied;

  const promoCodeThresholdReachedRef = useRef(promoCodeThresholdReached);
  promoCodeThresholdReachedRef.current = promoCodeThresholdReached;

  const masterOrderRef = useRef(masterOrder);
  masterOrderRef.current = masterOrder;

  const purchaseSuccessfulRef = useRef(purchaseSuccessful);
  purchaseSuccessfulRef.current = purchaseSuccessful;

  const duplicateOrderAwareRef = useRef(duplicateOrderAware);
  duplicateOrderAwareRef.current = duplicateOrderAware;

  const earnedPoints = useMemo(
    () =>
      +collect(orderRef.current?.reward_account_operations)
        .where("value", ">", 0)
        .sum("value"),
    [orderRef.current?.reward_account_operations]
  );

  useEffect(() => {
    defaultPaymentOptionRef.current && setPaymentDestination(
      guessPaymentDestination(defaultPaymentOptionRef.current.payment_method_id)
    );
  }, [defaultPaymentOptionRef.current?.payment_method_id]);

  useEffect(() => {
    if (isDineIn && typeof masterOrderRef.current?.id === "number") {
      connectPusher(masterOrderRef.current?.id);
    } else if (typeof checkoutOrderRef.current?.id === "number") {
      connectPusher(checkoutOrderRef.current?.id ?? cachedOrderRef?.current?.id);
    }
  }, [checkoutOrderRef.current?.id]);

  useEffect(() => {
    return () => {
      if (isDineIn && typeof masterOrderRef.current?.id === "number") {
        return unsubscribe(orderChannel(masterOrderRef.current?.id), [
          PUSHER_EVENTS.TRANSACTION_PAID,
          PUSHER_EVENTS.ORDER_PAID,
        ]);
      }

      return disconnect();
    };
  }, []);

  useEffect(() => {
    if (!urlParams?.setup || urlParams?.setup === 'true') {
      return;
    }

    if (urlParams?.redirect_status !== "succeeded") {
      return alert.error({
        title: t("error_messages.payment_unsuccessful.title"),
        description: t("error_messages.payment_unsuccessful.description"),
      });
    }

    const orderId = urlParams?.order_id
      ? Number(urlParams.order_id)
      : null;

    setPurchasing(true);
    const timeOut1 = setTimeout(() => verifyPaymentStatus({ id: orderId }, false), 1000);
    const timeOut2 = setTimeout(() => verifyPaymentStatus({ id: orderId }, false), 2000);
    const timeOut3 = setTimeout(() => verifyPaymentStatus({ id: orderId }, false), 3000);
    const timeOut4 = setTimeout(() => verifyPaymentStatus({ id: orderId }), 5000);

    return () => {
      clearTimeout(timeOut1);
      clearTimeout(timeOut2);
      clearTimeout(timeOut3);
      clearTimeout(timeOut4);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    saveMenu({ menuLoaded: true });
  }, []);

  useEffect(() => {
    if (AuthorizationTokenStorage.getToken()) {
      initAuthorized();
    } else {
      init();
    }
  }, []);

  useEffect(() => {
    if (!isDineIn) {
      debouncedOrderDraft();
    }
  }, [
    basketData,
    defaultPaymentOptionRef?.current?.id,
    cachedOrderRef?.current?.method,
    cachedOrderRef?.current?.delivery_lat,
    cachedOrderRef?.current?.delivery_lng,
    cachedOrderRef?.current?.delivery_quote?.id,
    cachedOrderRef?.current?.coupon_type,
  ]);

  useEffect(() => {
    setDefaultPaymentOption(
      getDefaultPaymentOption(
        null,
        collect()
          // @ts-ignore
          .when(
            cachedOrderRef.current?.method === ORDER_METHODS.DELIVERY && company?.delivery_provider !== DeliveryProvider.Standalone,
            (collection) => collection.push(PaymentMethod.Cash)
          )
          // @ts-ignore
          .toArray()
      )
    );
  }, [
    paymentOptions,
    cachedOrderRef.current?.method,
  ]);

  useEffect(() => {
    observeMinimumOrderThresholdPromoCode();
  }, [isDineIn, orderRef.current, total]);

  async function connectPusher(orderId: number) {
    const pusher = await connect();

    pusher
      .subscribe(orderChannel(orderId))
      .bind(
        PUSHER_EVENTS.TRANSACTION_PAID,
        (data: OrderPaymentInterface) =>
          isDineIn && data?.user_id === profile?.id && purchaseSuccessHandler()
      )
      .bind(
        PUSHER_EVENTS.ORDER_PAID,
        () => !isDineIn && purchaseSuccessHandler()
      )
      .bind(PUSHER_EVENTS.ORDER_FAILED, () => {
        setPurchasing(false);
        switch (errorTypeRef.current) {
          case "Canceled":
            return null;
          default:
            alert.error({
              title: t("error_messages.payment_unsuccessful.title"),
              description: t("error_messages.payment_unsuccessful.description"),
            });
        }
      });
  }

  async function initAuthorized() {
    await requestProfile();
    await requestPromoCodes();
    await draft();
    setScreenInitialized(true);
  }

  async function init() {
    setTimeout(() => {
      setScreenInitialized(true);
    }, 500);
  }

  async function verifyPaymentStatus(data: Partial<OrderInterface|OrderPaymentInterface> = null, toggleLoading: boolean = true) {
    if (isDineIn) {
      return await getTransactionStatus(data as OrderPaymentInterface, toggleLoading);
    }

    return await getOrderStatus(data as OrderInterface, toggleLoading);
  }

  async function getTransactionStatus(data: Partial<OrderPaymentInterface> = null, toggleLoading: boolean = true) {
    const id = data?.id ?? paymentInfo?.id;

    if (! id) {
      return;
    }

    toggleLoading && setPurchasing(true);
    const { response, success } = await OrderPaymentsManager.getStatus(id);

    if (!success) {
      toggleLoading && setPurchasing(false);
    }

    if (response?.data?.data?.status === ORDER_PAYMENT_STATUSES.PAID) {
      return purchaseSuccessHandler();
    }

    toggleLoading && setPurchasing(false);
  }

  async function getOrderStatus(data: Partial<OrderInterface> = null, toggleLoading: boolean = true) {
    const id = data?.id ?? checkoutOrderRef.current?.id ?? cachedOrderRef?.current?.id;

    if (! id) {
      return;
    }

    toggleLoading && setPurchasing(true);
    const { response, success } = await OrderManager.getOrderStatus(id);

    if (!success) {
      toggleLoading && setPurchasing(false);
    }

    if (response?.data?.data?.payment_status === ORDER_PAYMENT_STATUSES.PAID) {
      return purchaseSuccessHandler();
    }

    toggleLoading && setPurchasing(false);
  }

  async function requestPromoCodes() {
    const request = await new PromotionManager().getPromoCodes(
      company?.id,
      orderRef.current?.coupon_details?.code
    );
    const { response, success } = request;
    if (success) {
      setAvailablePromoCodes(response?.data?.data);
    }

    return request;
  }

  async function requestProfile() {
    return await ProfileManager.putProfile({
      delivery_street_name: cachedOrderRef.current?.delivery_street_name,
      delivery_street_number: cachedOrderRef.current?.delivery_street_number,
      delivery_city: cachedOrderRef.current?.delivery_city,
      delivery_state: cachedOrderRef.current?.delivery_state,
      delivery_country: cachedOrderRef.current?.delivery_country,
      delivery_zip_code: cachedOrderRef.current?.delivery_zip_code,
      delivery_lat: cachedOrderRef.current?.delivery_lat,
      delivery_lng: cachedOrderRef.current?.delivery_lng,
      delivery_dropoff_action: cachedOrderRef.current?.delivery_dropoff_action,
      delivery_doorbell_name: cachedOrderRef.current?.delivery_doorbell_name,
      delivery_company_name: cachedOrderRef.current?.delivery_company_name,
      customer_note: cachedOrderRef.current?.customer_note,
    });
  }

  useEffect(() => {
      draft({promo: cached_order?.coupon_details?.code} as OrderDataInterface);
  }, [cachedOrderRef?.current?.coupon_details]);

  function getDefaultTip(): number {
    if (initializedOrder?.tip) {
      return initializedOrder?.tip;
    }

    const price = isDineIn ? paymentInfo?.amount : total;

    return calculateTip(price, defaultTipOption);
  }

  function getDefaultTipIndex(): number {
    if (typeof locationState?.defaultActiveTipIndex === "number") {
      return locationState?.defaultActiveTipIndex;
    }

    return defaultTipOption?.index ?? 0;
  }

  function getOrderData(
    data?: OrderDataInterface
  ): OrderDataInterface | undefined {
    if (!company) {
      return;
    }

    return {
      company_id: company?.id,
      payment_option_id: defaultPaymentOptionRef?.current?.id ?? null,
      delivery_quote_id: cachedOrderRef?.current?.delivery_quote?.id,
      room_service_location_id: cachedOrderRef.current.room_service_location_id,
      method: cachedOrderRef.current?.method,
      room_service_details: cachedOrderRef.current.room_service_details,
      delivery_street_name: cachedOrderRef.current?.delivery_street_name ?? "",
      delivery_street_number: cachedOrderRef.current?.delivery_street_number ?? "",
      delivery_city: cachedOrderRef.current?.delivery_city ?? "",
      delivery_state: cachedOrderRef.current?.delivery_state ?? null,
      delivery_country: cachedOrderRef.current?.delivery_country ?? null,
      delivery_zip_code: cachedOrderRef.current?.delivery_zip_code ?? "",
      delivery_dropoff_action: cachedOrderRef.current?.delivery_dropoff_action,
      delivery_doorbell_name: cachedOrderRef.current?.delivery_doorbell_name ?? "",
      delivery_company_name: cachedOrderRef.current?.delivery_company_name ?? "",
      delivery_lat: cachedOrderRef.current?.delivery_lat ?? orderRef.current?.delivery_lat,
      delivery_lng: cachedOrderRef.current?.delivery_lng ?? orderRef.current?.delivery_lng,
      customer_note: cachedOrderRef.current?.customer_note,
      scheduled_for: cachedOrderRef.current?.scheduled_for ?? null,
      order_instructions: cachedOrderRef.current?.order_instructions ?? null,
      promo: cachedOrderRef.current?.coupon_details?.code ?? null,
      use_cashback: cachedOrderRef.current?.coupon_type === COUPON_TYPES.CASHBACK,
      tip: tipRef.current,
      line_items: orderItemsRef.current.map((item) => formatItemForBackend(item)),
      invoice_data: cachedOrderRef.current.invoice_data,
      ...data,
    };
  }

  function getPaymentData(
    data?: CreateOrderPaymentInterface
  ): CreateOrderPaymentInterface {
    return {
      ...paymentInfo,
      promo:
        promoAppliedRef.current && promoCodeThresholdReachedRef.current
          ? promoCodeRef.current
          : null,
      tip: tipRef.current
        ? HelperMethods.roundNumberWithPrecision(tipRef.current, 2)
        : 0,
      payment_option_id: defaultPaymentOptionRef?.current?.id,
      ...data,
    };
  }

  const debouncedDraft = useRef(
    debounce(
      async (data?: OrderDataInterface | CreateOrderPaymentInterface) => {
        draft(data);
      },
      500
    )
  ).current;

  const debouncedOrderDraft = useRef(
    debounce(async (data?: OrderDataInterface) => {
      draftOrder(data);
    }, 500)
  ).current;

  async function draft(
    data?: OrderDataInterface | CreateOrderPaymentInterface
  ) {
    if (isDineIn) {
      // @ts-ignore
      return draftPayment(data);
    }

    return draftOrder(data as OrderDataInterface);
  }

  async function draftOrder(
    data?: OrderDataInterface
  ): Promise<DataWrappedResponse<DataWrapper<OrderInterface>>> {
    let order = getOrderData(data);

    if (!order) {
      return {
        response: null,
        success: false,
        status: 400,
      };
    }

    setLoadingDraft(true);
    const request = await OrderManager.draftOrder(order);
    toggleLoadingDraft();

    const { response, success } = request;

    if (success && response?.data?.data) {
      setOrder(response?.data?.data);
    } else {
      draftOrderErrorHandler(response);
    }

    return request;
  }

  async function draftPayment(
    data?: CreateOrderPaymentInterface
  ): Promise<DataWrappedResponse<DataWrapper<OrderPaymentInterface>>> {
    setLoadingDraft(true);
    const request = await OrderPaymentDraftsManager.create(
      masterOrder?.id,
      getPaymentData(data)
    );
    toggleLoadingDraft();
    const { success, response } = request;

    if (!success) {
      draftOrderErrorHandler(response);
    }

    return request;
  }

  function draftOrderErrorHandler(error: any): void {
    if (error?.response?.status === 417) {
      alert.error({
        title: error?.response?.data?.title,
        description: error?.response?.data?.description,
      });
    }
  }

  async function checkOrderNotDuplicate(): Promise<boolean> {
    if (duplicateOrderAwareRef.current) {
      return true;
    }

    const { success, response } = await OrderManager.getOrderHistory({ limit: 1 });

    if (! success) {
      return true;
    }

    const order = collect(response.data.data).first();

    if (order && moment().diff(moment(order.paid_at), 'minutes') < 15) {
      toggleDuplicateOrderModal(order);

      return false;
    }

    return true;
  }

  async function prepareForPurchase() {
    setPurchasing(true);

    if (! await checkOrderNotDuplicate()) {
      return setPurchasing(false);
    }

    const { success, response } = await initCheckoutOrder();

    if (success) {
      trackBeginCheckout(response.data.data);

      return purchase(response?.data?.data);
    }

    setPurchasing(false);
  }

  async function initCheckoutOrder(): Promise<
    DataWrappedResponse<DataWrapper<OrderInterface>>
  > {
    const order = getOrderData();
    if (!order) {
      return {
        response: null,
        success: false,
        status: 400,
      };
    }

    let request = await OrderManager.postOrder(order, {
      [ERROR_CODES.ITEMS_OUT_OF_STOCK]: handleItemsOutOfStock,
    });
    const { success, response } = request;
    if (success) {
      response?.data?.data && setCheckoutOrder(response?.data?.data);
    } else {
      draftOrderErrorHandler(response);
    }

    return request;
  }

  function handleItemsOutOfStock() {
    refresh().then(() =>
      navigate(isDineIn ? SCREENS.DINE_IN : SCREENS.HOME_SCREEN)
    );
  }

  async function orderPayPalApproved() {
    setPurchasing(true);
    const payment = collect(checkoutOrderRef.current?.payments)
      .whereNull("paid_at")
      .where("user_id", profile?.id)
      .sortByDesc("created_at")
      .first();

    await PayPalManager.capturePayPalPayments(payment?.id);
  }

  async function purchase(orderResponse: OrderInterface) {
    setPurchasing(true);
    const { response, success } = await PurchaseManager.purchase(
      orderResponse?.id,
      {
        payment_option_id: defaultPaymentOptionRef.current?.id,
      },
      {
        [ERROR_CODES.CARD_EXCEPTION]: () => null,
        [ERROR_CODES.ORDER_FULFILLMENT_FAILED]: () => null,
      }
    );

    if (success) {
      return purchaseSuccessHandler();
    }

    setTimeout(() => verifyPaymentStatus(), 15000);
    switch (response?.response?.status) {
      case PAYMENT_ERROR_RESPONSE_STATUS.PAYMENT_METHOD_AUTHORIZATION_REQUIRED_CODE:
        setCheckoutOrder(response?.response?.data?.order);
        const paymentData = collect(response?.response?.data?.order?.payments)
          .whereNull("paid_at")
          .where("user_id", profile?.id)
          .sortByDesc("created_at")
          .first();

        return resolveExtraPaymentAuthorization(
          response?.response?.data?.payment_intent,
          paymentData
        );
    }
    setPurchasing(false);
  }

  async function pay() {
    setPurchasing(true);
    const { response, success } = await OrderPaymentsManager.create(
      masterOrder?.id,
      getPaymentData()
    );

    if (
      success &&
      response?.data?.data?.status === ORDER_PAYMENT_STATUSES.PAID
    ) {
      return purchaseSuccessHandler();
    }

    setTimeout(() => verifyPaymentStatus(), 15000);
    switch (response?.response?.status) {
      case PAYMENT_ERROR_RESPONSE_STATUS.PAYMENT_METHOD_AUTHORIZATION_REQUIRED_CODE:
        setCheckoutOrder(response?.response?.data?.order);
        const paymentData = collect(response?.response?.data?.order?.payments)
          .whereNull("paid_at")
          .where("user_id", profile?.id)
          .sortByDesc("created_at")
          .first();
        paymentData &&
          ReduxActions.dispatch(
            actionCreators.dineInFlow.updatePaymentInfo(paymentData)
          );

        return resolveExtraPaymentAuthorization(
          response?.response?.data?.payment_intent,
          paymentData
        );
    }
    setPurchasing(false);
  }

  async function purchaseSuccessHandler() {
    if (purchaseSuccessfulRef.current) {
      return;
    }

    setPurchaseSuccessful(true);

    if (isDineIn) {
      await PartiesManager.show(party?.id);
      navigate(`${SCREENS.PAYMENT_SUCCESSFUL}/${party?.id}`, {
        state: {
          prevPage: location.pathname,
        },
      });

      setTimeout(() => {
        ReduxActions.dispatch(actionCreators.dineInFlow.resetPaymentInfo());
      }, 500);

      return setPurchasing(false);
    }

    const uuid = checkoutOrderRef.current?.uuid ?? cachedOrderRef?.current?.uuid;
    navigate(`${SCREENS.ORDER_DETAILS}/${uuid}`, {
      state: {
        afterPurchase: true,
      },
    });
    setPurchasing(false);
  }

  function resolveExtraPaymentAuthorization(
    response: stripeJs.PaymentIntent,
    paymentData?: OrderPaymentInterface
  ) {
    switch (defaultPaymentOptionRef.current?.payment_method_id) {
      case PaymentMethod.Card:
        return handleCard3DAuthorization(response);
      case PaymentMethod.GooglePay:
      case PaymentMethod.ApplePay:
        return handleAppleGooglePayAuthorization(response);
      case PaymentMethod.Sofort:
        return handleSofortPayAuthorization(response);
      case PaymentMethod.Giropay:
        return handleGiroPayAuthorization(response, paymentData);
      case PaymentMethod.PaypalStandalone:
        return handlePayPalStandaloneAuthorization(response, paymentData);
      case PaymentMethod.Paypal:
        return handlePaypalAuthorization(response, paymentData);
    }
  }

  function handleCard3DAuthorization(
    response: stripeJs.PaymentIntent,
    paymentData?: OrderPaymentInterface
  ) {
    return handleCardConfirmation(
      response,
      () => setPurchasing(true),
      (error) => setErrorType(error.code)
    );
  }

  function handleAppleGooglePayAuthorization(response: stripeJs.PaymentIntent) {
    return handleAppleGooglePayConfirmation(
      response,
      () => setPurchasing(true),
      (error) => setErrorType(error.code)
    );
  }

  function handleSofortPayAuthorization(response: stripeJs.PaymentIntent) {
    return handleSofortConfirmation(
      response,
      defaultPaymentOptionRef.current,
      () => setPurchasing(true),
      (error) => {
        switch (error.code) {
          case "Canceled":
            return;
          default:
            return;
        }
      }
    );
  }

  function handleGiroPayAuthorization(
    response: stripeJs.PaymentIntent,
    paymentData?: OrderPaymentInterface
  ) {
    return handleGiropayConfirmation(
      response,
      defaultPaymentOptionRef.current,
      () => setPurchasing(true),
      (error) => {
        switch (error.code) {
          case "Canceled":
            return;
          default:
            return;
        }
      }
    );
  }

  function handlePaypalAuthorization(
    response: stripeJs.PaymentIntent,
    paymentData?: OrderPaymentInterface
  ) {
    return handlePaypalConfirmation(
      response,
      defaultPaymentOptionRef.current,
      () => setPurchasing(true),
      (error) => {
        switch (error.code) {
          case "Canceled":
            return;
          default:
            return;
        }
      }
    );
  }

  function handlePayPalStandaloneAuthorization(
    response: any,
    paymentData?: OrderPaymentInterface
  ) {
    return handlePaypalStandaloneConfirmation(
      checkoutOrderRef.current,
      paymentData,
      () => {
        setPurchasing(false);
        setFeedback({
          type: "Warning",
          message: t("error_messages.payment_unsuccessful.title"),
        });
      }
    );
  }

  async function removePaymentMethod(
    paymentMethod: PaymentOptionPropertiesInterface
  ): Promise<void> {
    if (removingPaymentMethodRef.current) {
      return;
    }
    setRemovingPaymentMethod(true);
    await PaymentManager.removePaymentOption(paymentMethod?.id);
    setRemovingPaymentMethod(false);
  }

  function toggleLoadingDraft(timeout: number = 700): void {
    setTimeout(() => setLoadingDraft(false), timeout);
  }

  function toggleDuplicateOrderModal(order: OrderItemInterface = null): void {
    setDuplicateOrderModalProps({
      open: !!order,
      order,
    });
  }

  const getSubTotal = useCallback<() => number>(() => {
    let price: number = 0;

    if (isDineIn) {
      price = (paymentInfo?.products_amount ?? 0) + (paymentInfo?.tip ?? 0);
    } else {
      price =
        (orderRef.current?.total ?? 0) +
        (orderRef.current?.total_discount ?? 0);
    }

    return HelperMethods.roundNumberWithPrecision(price, 2);
  }, [isDineIn, orderRef.current, paymentInfo]);

  const getTotal = useCallback<() => number>(() => {
    return isDineIn ? paymentInfo?.total_amount : orderRef?.current?.total;
  }, [isDineIn, paymentInfo, orderRef.current]);

  function observeMinimumOrderThresholdPromoCode() {
    const data = isDineIn ? paymentInfo : orderRef.current;
    const price = isDineIn ? paymentInfo?.amount : subtotal;

    if (!data?.coupon_details) {
      return;
    }

    const threshold: number = collect(data?.coupon_details?.components)?.first(
      (component: PromoComponentInterface) => !component?.is_used
    )?.usage_threshold;

    setPromoCodeThresholdReached(price >= (threshold ?? 0));
  }

  function copyPromoCodeToClipboard(promotion: PromotionCodeDataInterface) {
    setPromoCodeCopiedToClipboard(promotion?.code);
  }


  const renderHeaderBar = (): JSX.Element => {
    if (isDineIn) {
      return (
        <DineInNavBar
          title={t("header.checkout")}
          leftClick={() =>
            navigate(isBillSplittable ? SCREENS.SPLIT_BILL : SCREENS.DINE_IN)
          }
          hideCloseIcon
        />
      );
    }

    return (
      <CheckoutNavBar
        title={t("header.checkout")}
        goBack={() => navigate(SCREENS.HOME_SCREEN)}
      />
    );
  };

  const renderSteps = (): JSX.Element => {
    return (
      <div className={mainContainer}>
        <CheckoutStepper
          total={getTotal()}
          paymentOption={defaultPaymentOption}
          onProgress={(complete: boolean) => setReadyForPurchase(complete)}
        />
      </div>
    );
  };

  const renderOrdersList = (): JSX.Element => {
    return (
      <div>
        {items.map((item: CompleteBasketItemInterface, index) => (
          <div className="mb-small" key={`basket-item-${index}`}>
            <OrderBasketItem
              data={item}
              index={index}
            />
          </div>
        ))}
      </div>
    );
  };

  const renderMediumSpace = (): JSX.Element => {
    return <div className="mb-medium" />;
  };

  const renderDivider = (): JSX.Element => {
    return <div className="border-b border-brand-inkGrey-grey_2" />;
  };

  function renderTitle(title: string, children?: React.ReactElement) {
    return (
      <div className="mb-small">
        <h6>{title}</h6>
        {children}
      </div>
    );
  }

  function renderPriceLabel(
    title: string,
    price?: string | number | null,
    color?: string,
    titleIcon?: JSX.Element
  ) {
    const defaultColor = color ?? theme.color.text.default;

    return (
      <div className="flex flex-row justify-between mb-small">
        <div className="flex">
          <p style={{color: defaultColor}}>
            {title}
          </p>
          {titleIcon ? <span className="my-auto ml-xmini">{titleIcon}</span> : null}
        </div>
        <p style={{color: defaultColor}}>{price}</p>
      </div>
    );
  }

  const renderPointsAppliedPriceLabel = (): JSX.Element | null => {
    const condition2: boolean = orderRef.current?.total_discount > 0;
    const condition3: boolean = orderRef.current?.coupon_type === COUPON_TYPES.REWARD;

    const reward = collect(company?.rewards)?.last(
      (reward: RewardInterface) => reward?.is_available
    );

    return collect([condition2, condition3])?.doesntContain(false) ? (
      <div className="flex flex-row justify-between mb-small">
        <div>
          <div className={pointsAppliedTitleContainer}>
            <h6>
              {t("payment_breakdown:points_applied.title", {
                count: reward?.cost,
              })}
            </h6>
            <div className={pointsAppliedCheckmarkIconContainer}>
              <CheckmarkIcon
                color={theme.color.text.default}
                className={pointsAppliedCheckmarkIcon}
              />
            </div>
          </div>

          <p className={pointsAppliedDescription}>
            {t("payment_breakdown:points_applied.description")}
          </p>
        </div>
        <p className={pointsAppliedCostStyle}>
          -{formatCurrency(orderRef.current?.total_discount)}
        </p>
      </div>
    ) : null;
  };

  const renderDineInPaymentBreakdown = (): JSX.Element => {
    return (
      <div>
        {renderPriceLabel(
          t("payment_breakdown.subtotal"),
          formatCurrency(paymentInfo?.amount)
        )}
        {paymentInfo?.total_discount > 0 &&
          renderPriceLabel(
            t("payment_breakdown.promo_code"),
            `-${formatCurrency(paymentInfo?.total_discount)}`,
            theme.color.brand.primary
          )}
      </div>
    );
  };

  const renderPaymentBreakdown = (): JSX.Element => {
    return (
      <div>
        {renderPriceLabel(
          t("payment_breakdown.subtotal"),
          formatCurrency(orderRef.current?.sub_total ?? 0)
        )}
        {renderPointsAppliedPriceLabel()}
        {orderRef.current?.method === ORDER_METHODS.DELIVERY &&
          orderRef.current?.method_fee > 0 &&
          renderPriceLabel(
            t("payment_breakdown.delivery_fee"),
            formatCurrency(orderRef.current?.method_fee ?? 0)
          )}
        {orderRef.current?.service_fee > 0 && renderPriceLabel(
          t("payment_breakdown.service_fee.title"),
          formatCurrency(orderRef.current?.service_fee),
          "",
          <Tooltip title={t(`payment_breakdown.service_fee.description`)}>
            <InfoIcon
              color={theme.color.text.grey}
              className="w-[18px] h-[18px]"
            />
          </Tooltip>
        )}
        {orderRef.current?.payment_method_upcharge_fee > 0 && renderPriceLabel(
          t(`payment_breakdown.payment_method_upcharge_fee.${defaultPaymentOption?.payment_method_id}.title`),
          formatCurrency(orderRef.current?.payment_method_upcharge_fee),
          "",
          <Tooltip title={t(`payment_breakdown.payment_method_upcharge_fee.${defaultPaymentOption?.payment_method_id}.description`)}>
            <InfoIcon
              color={theme.color.text.grey}
              className="w-[18px] h-[18px]"
            />
          </Tooltip>
        )}
        {orderRef.current?.total_discount > 0 && promoAppliedRef.current
          ? renderPriceLabel(
              t("payment_breakdown.promo_code"),
              `-${formatCurrency(orderRef.current?.total_discount)}`,
              theme.color.brand.primary
            )
          : null}
        {orderRef.current?.tax_behaviour === TaxBehaviour.Exclusive && orderRef.current?.tax > 0 && renderPriceLabel(
          t('payment_breakdown.tax'),
          formatCurrency(orderRef.current?.tax)
        )}
      </div>
    );
  };

  const renderAvailablePromoCodes = (): JSX.Element => {
    return !promoAppliedRef.current && availablePromoCodes?.length > 0 ? (
      <div className="flex flex-col mt-small">
        <div className="flex flex-row items-center mb-mini">
          <DiscountIcon
            style={{ marginRight: theme.dimension.padding.tiny }}
            color={theme.color.brand.primary}
          />
          <h5 className="text-brand-text-default mini semibold">
            {t("available_codes.title")}
          </h5>
        </div>

        {availablePromoCodes?.map((promotion: PromotionCodeDataInterface) => (
          <CopyToClipboard
            key={promotion?.id}
            text={promotion?.code}
            onCopy={() => copyPromoCodeToClipboard(promotion)}
            options={{
              format: "text/plain",
            }}
          >
            <div
              className="flex flex-row justify-between items-center cursor-pointer mb-mini"
              key={promotion?.id}
            >
              <h5 className="tiny">{promotion?.code}</h5>

              {promoCodeCopiedToClipboard !== promotion?.code ? (
                <h4 className="text-brand-primary tiny semibold">
                  {t("available_codes.code.copy")}
                </h4>
              ) : (
                <CheckmarkIcon
                  color={theme.color.brand.primary}
                  className="h-3 w-3"
                />
              )}
            </div>
          </CopyToClipboard>
        ))}
      </div>
    ) : null;
  };

  const renderFeedbackLabel = (): JSX.Element => {
    if (feedback?.type) {
      return (
        <div className="mt-small">
          <FeedbackLabel type={feedback?.type} message={feedback.message} />
        </div>
      );
    }

    return null;
  };

  const renderEnterPromoCode = (): JSX.Element => {
    const price = isDineIn ? paymentInfo?.amount : orderRef.current?.total;

    return (
      price > 0 && (
        <div className="mb-4">
          <PromoCodeInput />
          {renderFeedbackLabel()}
          {renderAvailablePromoCodes()}
        </div>
      )
    );
  };

  const renderTips = (): JSX.Element => {
    const price = isDineIn ? paymentInfo?.amount : orderRef.current?.sub_total;

    function onChangeTip(value: number): void {
      new Promise((resolve) => {
        setTip(value);
        resolve(true);
      }).then(() => debouncedDraft());
    }

    return (
      getTotal() > 0 && (
        <div>
          {renderTitle(
            t("tips.title"),
            <p className="tiny mt-tiny">{t("tips.description")}</p>
          )}
          <div>
            <Tips
              price={price}
              defaultTip={getDefaultTip()?.toString()}
              activeIndex={activeTipIndex}
              setActiveIndex={setActiveTipIndex}
              onChangeTip={onChangeTip}
            />
          </div>
        </div>
      )
    );
  };

  const renderPayPalButton = (): JSX.Element => {
    return (
      <div
        className="flex justify-center items-center"
        data-test="paypal-button"
      >
        <PayPalButtons
          style={{
            layout: "horizontal",
            color: "blue",
            label: "pay",
          }}
          // @ts-ignore
          createOrder={isDineIn ? pay : prepareForPurchase}
          onApprove={orderPayPalApproved}
          onCancel={() => setPurchasing(false)}
          onError={() => setPurchasing(false)}
          fundingSource="paypal"
          className="w-screen"
        />
      </div>
    );
  };

  const renderPayButton = (): ReactElement => {
    const total = getTotal();
    const isDiscount: boolean = getSubTotal() !== total;

    function getButtonTitle(): string {
      if (!hasReachedCheckoutThreshold || !isWithinMinThreshold) {
        return t("pay_button.add_items");
      }

      if (!isWithinMaxThreshold) {
        return t("pay_button.remove_items");
      }

      if (isDineIn) {
        if (total === 0) {
          return t("pay_button.close_table");
        }

        return t("pay_button.complete_payment");
      }

      if (orderRef.current?.total === 0) {
        return t("pay_button.place_order");
      }

      return t("pay_button.pay_and_place_order");
    }

    function onClick() {
      if (!isWithinThreshold || !hasReachedCheckoutThreshold) {
        return navigate(SCREENS.HOME_SCREEN);
      }

      if (isDineIn) {
        return pay();
      }

      return prepareForPurchase();
    }

    const renderCustomButton = (): JSX.Element => {
      return (
        <CustomButton
          buttonType={!isWithinThreshold || !hasReachedCheckoutThreshold ? ButtonTypes.forth : ButtonTypes.primary}
          title={getButtonTitle()}
          onClick={onClick}
          loading={purchasing}
          disabled={!readyForPurchase}
          testId="custom-purchase-button"
        />
      );
    };

    const renderPaymentRequestButton = (): JSX.Element => {
      return (
        <StripePaymentRequestButton
          amount={getTotal()}
          confirmPayment={onClick}
          validate={checkOrderNotDuplicate}
          onStartPayment={() => setPurchasing(true)}
          onCancelPayment={() => setPurchasing(false)}
        />
      );
    };

    const renderPayButton = (): JSX.Element => {
      if (readyForPurchase && getTotal() > 0) {
        switch (defaultPaymentOptionRef.current?.payment_method_id) {
          case PaymentMethod.PaypalStandalone:
            return renderPayPalButton();
          case PaymentMethod.ApplePay:
            if (enabledWallets.includes(PaymentMethod.ApplePay)) {
              return renderPaymentRequestButton();
            }

            removePaymentMethod(defaultPaymentOptionRef.current);
            return null;
          case PaymentMethod.GooglePay:
            if (enabledWallets.includes(PaymentMethod.GooglePay)) {
              return renderPaymentRequestButton();
            }

            removePaymentMethod(defaultPaymentOptionRef.current);
            return null;
          default:
            return renderCustomButton();
        }
      }
      return renderCustomButton();
    };

    return (
      <ButtonFooter className={payButtonContainer}>
        {!isDineIn && (
          <>
            <OrderBasketCashback
              subtotal={subtotal}
              total={getTotal()}
              className="mb-mini"
            />
            <LoyaltyPointsLabel quantity={earnedPoints} className="mb-mini" />
          </>
        )}
        <div className="flex flex-row justify-between pb-small">
          <h4>{t("pay_button.total")}</h4>
          <div className="flex flex-row">
            {loadingDraft && (
              <div className="w-32 h-6 relative overflow-clip">
                <div className="absolute -right-10">
                  <CustomLoadingIndicator className="h-6 w-32" />
                </div>
              </div>
            )}
            {!loadingDraft && (
              <>
                {isDiscount && (
                  <div className="relative flex justify-center items-center">
                    <h4>{formatCurrency(getSubTotal())}</h4>
                    <div className="absolute w-full h-0.5 bg-brand-text-default" />
                  </div>
                )}
                <h4
                  style={{
                    color: isDiscount
                      ? theme.color.brand.primary
                      : theme.color.text.default,
                    marginLeft: theme.dimension.padding.mini,
                  }}
                  data-test="total-price"
                >
                  {formatCurrency(isDiscount ? getTotal() : getSubTotal())}
                </h4>
              </>
            )}
          </div>
        </div>
        {renderPayButton()}
      </ButtonFooter>
    );
  };

  const renderHeaderSummary = (): JSX.Element => {
    return (
      <div className={headerSummaryContainer}>
        <HeaderBar title={t("header.order_summary")} />
      </div>
    );
  };

  const renderDineInOrderSummary = (): JSX.Element => {
    return (
      <div className={orderSummaryContainer}>
        <div className={orderSummaryWrapper}>
          {renderTips()}
          {renderMediumSpace()}
          {renderTitle(t("header.price_details"))}
          {renderDineInPaymentBreakdown()}
        </div>
        {renderPayButton()}
      </div>
    );
  };

  const renderOrderSummary = (): JSX.Element => {
    return (
      <div className={orderSummaryContainer}>
        {renderHeaderSummary()}
        <div className={orderSummaryWrapper}>
          {renderOrdersList()}
          {renderDivider()}
          {renderMediumSpace()}
          <DeliveryThreshold className="mb-medium" />
          <CheckoutThreshold className="mb-medium" />
          {renderTips()}
          {renderMediumSpace()}
          {renderEnterPromoCode()}
          {renderMediumSpace()}
          {renderTitle(t("header.price_details"))}
          {renderMediumSpace()}
          {renderPaymentBreakdown()}
        </div>
        {renderPayButton()}
      </div>
    );
  };

  const renderPaymentMethodModal = useMemo(() => {
    function onCloseModal(): void {
      return setShowPaymentMethodModal(false);
    }

    return (
      <PaymentMethodModal
        openModal={showPaymentMethodModal}
        defaultPaymentMethodId={defaultPaymentMethodId}
        onCloseModal={onCloseModal}
      />
    );
  }, [showPaymentMethodModal]);

  const renderDuplicateOrderModal = useMemo(() => {
    return (
      <DuplicateOrderModal
        open={duplicateOrderModalProps.open}
        order={duplicateOrderModalProps.order}
        onConfirm={() => setDuplicateOrderAware(true)}
        toggleModal={toggleDuplicateOrderModal}
      />
    );
  }, [duplicateOrderModalProps]);

  const renderLoadingPage = useMemo(() => {
    return !screenInitialized ||
      loading ? (
      <LoadingPage />
    ) : null;
  }, [screenInitialized, loading]);

  const renderLoadingPayment = useMemo(() => {
    return purchasing ? (
      <LoadingWithText description={t("loading.processing")} />
    ) : null;
  }, [purchasing]);

  const renderEmptyBasket = (): JSX.Element => {
    return (
      <div className={emptyBasketContainer}>
        {renderHeaderSummary()}
        <div className="mt-medium">
          <IllustrationExplainer
            illustration={<EmptyCartIcon className="h-20 w-20" />}
            title={t("empty_order_basket.title")}
            description={t("empty_order_basket.description")}
          />
        </div>
        <div className={addProductsButtonContainer}>
          <CustomButton
            title={t("empty_order_basket.custom_button.add_products")}
            buttonType={ButtonTypes.third}
            onClick={() =>
              navigate(isDineIn ? SCREENS.MENU : SCREENS.HOME_SCREEN)
            }
          />
        </div>
      </div>
    );
  };

  const renderSummary = (): JSX.Element => {
    if (isDineIn) {
      return renderDineInOrderSummary();
    }

    if (orderItemsRef.current?.length > 0) {
      return renderOrderSummary();
    }

    return renderEmptyBasket();
  };

  return (
    <PageLayout header={renderHeaderBar()}>
      <>
        <div className={container}>
          <main className={mainWrapper}>
            <div className={mainGrid}>{renderSteps()}</div>
            <div className={secondGrid}>{renderSummary()}</div>
          </main>
        </div>
        {renderPaymentMethodModal}
        {renderDuplicateOrderModal}
        {renderLoadingPayment}
        {renderLoadingPage}
      </>
    </PageLayout>
  );
};

export default CheckoutScreen;

const container = "relative h-full";

const mainWrapper = "h-full flex lg:flex-row flex-col";

const mainGrid = "lg:w-3/4 w-full relative lg:h-full overscroll-contain";

const secondGrid =
  "lg:w-1/4 w-full lg:border-l border-brand-inkGrey-grey_2 relative lg:h-screen relative overscroll-contain";

const mainContainer = "lg:w-4/5 w-full h-full lg:px-medium lg:py-small";

const payButtonContainer = "fixed bottom-0 lg:w-1/4 w-full";

const emptyBasketContainer = "w-full";

const headerSummaryContainer =
  "lg:sticky lg:top-0 mb-2 z-20 border-b lg:border-l border-brand-inkGrey-grey_2";

const addProductsButtonContainer = "px-small mt-medium";

const orderSummaryContainer =
  "lg:overflow-y-auto lg:fixed h-full overscroll-contain lg:w-1/4 right-0";

const orderSummaryWrapper = "px-small pt-small lg:pb-[300px] pb-[200px]";

const pointsAppliedTitleContainer = "flex flex-row items-center";

const pointsAppliedCheckmarkIconContainer = "ml-mini";

const pointsAppliedCheckmarkIcon = "w-3 h-3";

const pointsAppliedDescription = "text-brand-text-grey tiny";

const pointsAppliedCostStyle = "text-brand-primary";
