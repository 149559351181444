import React, { useState, useMemo, useRef } from "react";
import {
  ModifierInterface,
  ProductComboInterface,
  ProductComboItemInterface,
  ProductInterface,
  ModifierGroupInterface,
} from "../../services/exports/Interfaces";
import ModifierGroupSingleSelect from "../../components-library/menu/modifier-group/ModifierGroupSingleSelect";
import ModifierGroupMultiSelect from "../../components-library/menu/modifier-group/ModifierGroupMultiSelect";
import CustomButton from "../../components-library/buttons/CustomButton";
import CounterButton from "../../components-library/buttons/CounterButton";
import CustomNavBar from "../../components-library/navigation/CustomNavBar";
import useAlert from "../../hooks/utility/useAlert";
import useScreenType from "../../hooks/utility/useScreenType";
import classnames from "classnames";
import useOrderingDisabledStatus from "../../hooks/availability/useOrderingDisabledStatus";
import PriceComparison from "../../components-library/payment/PriceComparison";
import Theme from "../../components-library/Theme";
import CloseIcon from "../../assets/logo/CloseIcon";
import ProductIdentifiers from "../../components-library/menu/product/ProductIdentifiers";
import useDineInFlow from "../../hooks/global/useDineInFlow";
import useTheme from "../../hooks/ui/useTheme";
import { useTranslation } from "react-i18next";
import ButtonFooter from "../../components-library/footers/ButtonFooter";
import useAppState from "../../hooks/global/useAppState";
import collect, { Collection } from "collect.js";
import ComboItem from "../../components-library/menu/combo/ComboItem";
import BasketHelpers from "../../services/helpers/BasketHelpers";
import useBasket from "../../hooks/basket/useBasket";
import { BasketLineItemInterface } from "../../store/types";
import TextTransformationResource from "../../services/resources/TextTransformationResource";
import useAppMethods from "../../hooks/utility/useAppMethods";
import useOrderMethodsStatus from "../../hooks/availability/useOrderMethodsStatus";
import SeoResource from "../../services/resources/SeoResource";
import TextArea from "../../components-library/inputs/TextArea";
import EarnPointsTag from "../../components-library/loyalty-program/EarnPointsTag";
import EarnCashbackTag from "../../components-library/cashback/EarnCashbackTag";

interface Props {
  data: ProductComboInterface;
  lineItem: BasketLineItemInterface;
  collapsedItems: number[];
  comboItemRefs: Collection<React.RefObject<any>>;
  toggleItem: (id: number) => void;
  onSelectProduct: (
    comboItem: ProductComboItemInterface,
    product: ProductInterface
  ) => void;
  onSelectNullOption: (comboItem: ProductComboItemInterface) => void;
  updateLineItem: (field: string, value: any) => void;
  updateComboItem: (id: number, data: Partial<BasketLineItemInterface>) => void;
}

export default function ComboContent(props: Props): JSX.Element {
  const { t } = useTranslation(null, { keyPrefix: 'Modals:Combo:ComboContent' });

  const {
    data,
    lineItem,
    collapsedItems,
    comboItemRefs,
    toggleItem,
    onSelectProduct,
    onSelectNullOption,
    updateLineItem,
    updateComboItem,
  } = props;

  const product = data.main_product;
  const items = collect(data.items);
  const mainLineItem: BasketLineItemInterface = collect(
    lineItem.children
  ).firstWhere("combo_item_id", items.first().id);

  const { menu, comboModalProps } = useAppState();
  const { screenPosition } = menu;

  const alert = useAlert();
  const { isDesktop, isMobile } = useScreenType();
  const theme = useTheme();

  const { addItem, compileItem } = useBasket();
  const { formatCurrency, toggleComboModal, toggleOrdersDisabledModal, toggleFreeProductsModal } = useAppMethods();

  const [modifierGroupRefs] = useState<Collection<React.RefObject<HTMLDivElement>>>(
    collect(product?.modifier_groups ?? [])
      .keyBy('id')
      .map(() => React.createRef())
  );

  const prefillDefaultComboItems = (): BasketLineItemInterface => {
    const missingItems: BasketLineItemInterface[] = collect(data.items)
      .filter(
        (item) =>
          item.is_required &&
          item.products?.length > 0 &&
          !collect(lineItem.children).firstWhere('combo_item_id', item.id)
      )
      .map((item): BasketLineItemInterface => {
        const product = collect(item.products).first();

        return {
          product_id: product.id,
          combo_item_id: item.id,
          modifiers: [],
          quantity: 1,
        };
      })
      .toArray();

    return {
      ...lineItem,
      children: [...lineItem.children, ...missingItems],
    };
  };

  const price = useMemo<number>(
    () => compileItem(prefillDefaultComboItems()).actual_price,
    [lineItem, data.items]
  );

  const { isDineIn } = useDineInFlow();
  const { dineInCurrentlyOpen } = useOrderMethodsStatus();
  const { render: renderOrderingDisabled } = useOrderingDisabledStatus();

  const [showNote, setShowNote] = useState<boolean>(false);
  const toggleNote = () => setShowNote((current) => !current);

  const headerRef = useRef<HTMLDivElement>(null);
  const noteRef = useRef<HTMLDivElement>(null);

  const scrollYRef = useRef<number>(scrollY);
  scrollYRef.current = scrollY;

  const toggleModifier = (
    modifierGroup: ModifierGroupInterface,
    modifier: ModifierInterface
  ) =>
    updateComboItem(
      mainLineItem.combo_item_id,
      BasketHelpers.toggleModifier(mainLineItem, modifierGroup, modifier)
    );

  const scrollToModifierGroup = (id: number) => {
    const ref = modifierGroupRefs.get(id);

    ref.current &&
      ref.current.scrollIntoView({ block: "center", behavior: "smooth" });
  };

  const scrollToComboItem = (id: number) => {
    const ref = comboItemRefs.get(id);
    const collapsed = collapsedItems.includes(id);

    ref.current && collapsed && toggleItem(id);
    ref.current &&
      setTimeout(
        () =>
          ref.current.scrollIntoView({ block: "center", behavior: "smooth" }),
        5
      );
  };

  function validateInput() {
    if (isDineIn && !dineInCurrentlyOpen) {
      return toggleOrdersDisabledModal({ openModal: true });
    }

    const completeLineItem = compileItem(lineItem);
    const valid = BasketHelpers.validateLineItem(completeLineItem);

    if (!valid) {
      const invalidModifierGroup = BasketHelpers.findInvalidModifierGroup(
        product,
        mainLineItem.modifiers
      );
      const invalidComboItem =
        BasketHelpers.findInvalidComboItem(completeLineItem);

      invalidModifierGroup && scrollToModifierGroup(invalidModifierGroup.id);
      !invalidModifierGroup &&
        invalidComboItem &&
        scrollToComboItem(invalidComboItem.id);

      return alert.error({
        description: t('error_messages.missing_fields'),
      });
    }

    return addProduct();
  }

  function addProduct() {
    new Promise((resolve) => {
      if (!product) {
        resolve(true);
      }

      addItem(lineItem);

      resolve(true);
    }).then(() => toggleComboModal());
  }

  const renderNote = useMemo<JSX.Element>(() => {
    function onFocus(): void {
      if (isDesktop) {
        setTimeout(() =>
          noteRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
          })
        );
      }
    }

    return (
      <div className="mb-small px-small">
        <h6>{t("note.title")}</h6>
        <div className="my-mini" ref={noteRef}>
          <TextArea
            value={lineItem.note}
            placeholder={t("note.placeholder")}
            onChange={(value) =>
              updateLineItem(
                "note",
                new TextTransformationResource(value).reduceToText()
              )
            }
            autoFocus={false}
            onFocus={onFocus}
          />
        </div>
      </div>
    );
  }, [
    showNote,
    lineItem.note,
    mainLineItem.combo_item_id,
    theme,
    screenPosition,
    isMobile,
    noteRef.current,
  ]);

  const renderProductInfo = (): JSX.Element => {
    return (
      <div className="mb-small px-small">
        <div className="flex justify-between">
          <h4 className="text-brand-text-default break-words mb-xsmall mr-small shrink">
            {data?.name}
          </h4>
          <div className="flex-none">
            <PriceComparison
              actualPrice={data?.actual_price}
              originalPrice={data?.original_price}
              originalPriceStyle={{
                fontSize: Theme.fonts.fontSizes.p,
              }}
              actualPriceStyle={{
                fontSize: Theme.fonts.fontSizes.p,
              }}
            />
          </div>
        </div>
        <div>
          <ProductIdentifiers product={product} className="mb-xsmall" />
          <p className={classnames("text-brand-text-grey break-words", "mini")}>
            {data?.description}
          </p>
        </div>
        <EarnPointsTag price={price} quantity={lineItem.quantity} className="mt-small" />
        <EarnCashbackTag amount={price} className="mt-small" />
      </div>
    );
  };

  const renderModifierGroup = (item: ModifierGroupInterface): JSX.Element => {
    if (item?.max_count === 1) {
      return (
        <ModifierGroupSingleSelect
          ref={modifierGroupRefs.get(item?.id)}
          modifierGroup={item}
          selectedModifiers={mainLineItem.modifiers}
          toggle={(modifier) => toggleModifier(item, modifier)}
        />
      );
    }

    return (
      <ModifierGroupMultiSelect
        ref={modifierGroupRefs.get(item?.id)}
        modifierGroup={item}
        selectedModifiers={mainLineItem.modifiers}
        toggle={(modifier) => toggleModifier(item, modifier)}
      />
    );
  };

  const renderCounterButton = (): JSX.Element => {
    return (
      <CounterButton
        count={lineItem.quantity}
        increase={() => updateLineItem("quantity", lineItem.quantity + 1)}
        decrease={() => updateLineItem("quantity", lineItem.quantity - 1)}
      />
    );
  };

  const renderAddButton = (): JSX.Element => {
    if (renderOrderingDisabled) {
      return renderOrderingDisabled;
    }

    if (!data?.is_available) {
      return (
        <div className={productUnavailableContainer}>
          <h4 className={productUnavailableTextStyle}>
            {t("error_messages.product_unavailable")}
          </h4>
        </div>
      );
    }

    return (
      <ButtonFooter className={addButtonContainer}>
        <div className={countButtonContainer}>{renderCounterButton()}</div>
        <div className={addButtonWrapper}>
          <CustomButton
            title={formatCurrency(price)}
            onClick={validateInput}
          />
        </div>
      </ButtonFooter>
    );
  };

  const renderHeaderBar = useMemo(() => {
    const shouldFadeInHeader =
      scrollYRef.current > (headerRef.current?.clientHeight ?? 0);

    if (!isDesktop && data?.image_url) {
      return (
        <button
          className={closeIconContainer}
          onClick={() => toggleComboModal()}
        >
          <CloseIcon color={Theme.color.text.default} />
        </button>
      );
    }

    return (
      <div className={headerContainer} ref={headerRef}>
        {!!data?.image_url && (
          <div className={headerBackground(shouldFadeInHeader)} />
        )}

        <CustomNavBar
          rightClick={() => toggleComboModal()}
          hideBackIcon
          transparent={!!data?.image_url}
        />
      </div>
    );
  }, [isDesktop, data?.image_url, scrollYRef.current, headerRef.current]);

  const renderProductPicture = useMemo((): JSX.Element | null => {
    return data?.image_url ? (
      <div
        className={productImageContainer}
        style={{
          marginTop: (headerRef.current?.clientHeight ?? 0) * -1.5,
        }}
      >
        <img
          src={data?.thumbnail_url}
          alt={SeoResource.toAltText(`${product?.name} image`)}
          className={productImage}
        />
        <img
          src={data?.image_url}
          alt={SeoResource.toAltText(`${product?.name} image`)}
          className={productImage}
        />
      </div>
    ) : null;
  }, [data?.image_url, headerRef.current]);

  const renderComboItems = () =>
    items.skip(1).map((item) => {
      const lineItemData = collect(lineItem.children).firstWhere(
        "combo_item_id",
        item.id
      );
      const collapsed = collapsedItems.includes(item.id);

      return (
        <ComboItem
          ref={comboItemRefs.get(item.id)}
          data={item}
          lineItemData={lineItemData}
          isCollapsed={collapsed}
          onCollapse={() => toggleItem(item.id)}
          onSelect={(product: ProductInterface) => onSelectProduct(item, product)}
          onSelectNullOption={() => onSelectNullOption(item)}
          className={classnames({ "!mb-small": collapsed })}
          key={`combo-item-${item.id}`}
        />
      );
    });

  return (
    <>
      {renderHeaderBar}
      {renderProductPicture}
      <div className={wrapper(!!data?.image_url)}>
        {renderProductInfo()}
        <div className="mb-small">
          {product?.modifier_groups?.map((item: ModifierGroupInterface) => (
            <div key={item?.id?.toString()}>{renderModifierGroup(item)}</div>
          ))}
        </div>
        {renderComboItems()}
        {renderNote}
      </div>
      {renderAddButton()}
    </>
  );
}

const headerContainer = "sticky top-0 z-10 relative";

const headerBackground = (fadeIn: boolean) =>
  `bg-background-inkWhite-white_1 transition-opacity duration-500 ease-out border-b border-brand-inkGrey-grey_2 absolute inset-0 ${
    fadeIn ? "opacity-100" : "opacity-0"
  }`;

const closeIconContainer =
  "w-9 h-9 bg-background-inkWhite-white_1 shadow-icon rounded-full flex justify-center items-center fixed top-0 z-10 right-0 mr-small mt-small";

const productImageContainer = "relative w-full aspect-square";

const productImage = "object-cover absolute w-full h-full";

const wrapper = (hasOffset: boolean) =>
  `pt-small lg:pb-small pb-[100px] ${
    hasOffset
      ? "lg:-mt-[150px] -mt-[40px] border-t border-brand-inkGrey-grey_2"
      : ""
  } h-fit bg-background-inkWhite-white_1 relative`;

const addButtonContainer = `lg:sticky fixed bottom-0 grid grid-cols-2 gap-4 items-center`;

const countButtonContainer = "col-span-1";

const addButtonWrapper = "col-span-1";

const productUnavailableContainer =
  "lg:sticky fixed bottom-0 w-full flex flex-row items-center justify-center px-medium py-small bg-brand-inkGrey-grey_1 border-t border-brand-inkGrey-grey_2";

const productUnavailableTextStyle = "text-brand-text-grey text-center";
