import {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import OrderMethodInfo from "../../../components-library/checkout/OrderMethodInfo";
import TextArea from "../../../components-library/inputs/TextArea";
import TextInput from "../../../components-library/inputs/TextInput";
import ProfileManager from "../../../services/api/ProfileManager";
import {actionCreators} from "../../../store/actions";
import ReduxActions from "../../../store/ReduxActions";
import useAlert from "../../../hooks/utility/useAlert";
import {useTranslation} from "react-i18next";
import {StoreInterface} from "../../../store/types";
import {DeliveryProvider, DropoffAction, ORDER_METHODS} from "../../../services/exports/Constants";
import RadioButton from "../../../components-library/buttons/RadioButton";
import useTheme from "../../../hooks/ui/useTheme";
import BoxIcon from "../../../assets/logo/BoxIcon";
import {debounce} from "lodash";
import useAuth from "../../../hooks/global/useAuth";

interface Props {
  onClickInfo: () => void;
  onContinue: () => void;
}

export default function OrderInfoStep(props: Props) {
  const { t } = useTranslation(null, { keyPrefix: 'Components:Checkout:Stepper:OrderInfoStep' });

  const { onClickInfo, onContinue } = props;

  const { profile } = useSelector((state: StoreInterface) => state.profile);
  const { cached_order } = useSelector((state: StoreInterface) => state.order);
  const { company } = useSelector((state: StoreInterface) => state.initialData);

  const { isLoggedIn } = useAuth();
  const alert = useAlert();
  const theme = useTheme();

  const [data, setData] = useState({
    delivery_dropoff_action: profile?.delivery_dropoff_action ?? DropoffAction.RingDoorBell,
    delivery_doorbell_name: profile?.delivery_doorbell_name ?? '',
    delivery_company_name: profile?.delivery_company_name ?? '',
    customer_note: profile?.customer_note ?? '',
    order_instructions: cached_order?.order_instructions ?? '',
  });
  const [loading, setLoading] = useState(false);

  const dataRef = useRef(data);
  dataRef.current = data;

  const updateData = (field: any, value?: any) => {
    const change = typeof field === 'string' ? { [field]: value } : value;

    setData((current) => ({
      ...current,
      ...change,
    }));
  };

  useEffect(
    () => updateData({
      delivery_dropoff_action: profile?.delivery_dropoff_action ?? DropoffAction.RingDoorBell,
      delivery_doorbell_name: profile?.delivery_doorbell_name ?? '',
      delivery_company_name: profile?.delivery_company_name ?? '',
      customer_note: profile?.customer_note ?? '',
    }),
    [
      profile?.delivery_dropoff_action,
      profile?.delivery_doorbell_name,
      profile?.delivery_company_name,
      profile?.customer_note,
    ]
  );

  useEffect(
    () => updateData({
      order_instructions: cached_order?.order_instructions ?? '',
    }),
    [cached_order?.order_instructions]
  );

  const saveChanges = useRef(
    debounce( () => _saveChanges(), 500)
  ).current;

  useEffect(
    () => {
      isLoggedIn && saveChanges();
    },
    [
      isLoggedIn,
      data.delivery_dropoff_action,
      data.delivery_doorbell_name,
      data.delivery_company_name,
      data.customer_note,
    ]
  )

  async function _saveChanges() {
    setLoading(true);
    const { success } = await ProfileManager.putProfile(dataRef.current);
    setLoading(false);

    if (success) {
      ReduxActions.dispatch(actionCreators.order.updateOrder(dataRef.current));

      return onContinue();
    }

    alert.error({
      description: t("error_messages.update_profile_unsuccessful"),
    });
  }

  function renderPickupFields() {
    return null;
  }

  function renderDeliveryFields() {
    if (cached_order?.method !== ORDER_METHODS.DELIVERY) {
      return null;
    }

    return (
      <div className="mt-small">
        {company?.delivery_provider !== DeliveryProvider.Standalone && (
          <div className="flex w-full mb-small px-small py-xsmall border rounded border-brand-inkGrey-grey_2">
            <BoxIcon className="my-auto mr-xsmall" />
            <div>
              <h6 className="font-medium">{t('fields.dropoff_action.label')}</h6>
              {Object.values(DropoffAction).map((item) => (
                <button
                  className="flex flex-row w-full items-center mt-mini"
                  onClick={() => updateData('delivery_dropoff_action', item)}
                  key={`dropoff-action-${item}`}
                >
                  <RadioButton
                    active={data.delivery_dropoff_action === item}
                    color={theme.color.brand.secondary}
                    key={`delivery-dropoff-action-${item}`}
                  />
                  <p className="ml-mini my-auto mini">{t(`fields.dropoff_action.options.${item}`)}</p>
                </button>
              ))}
            </div>
          </div>
        )}
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-2 mb-small">
          <TextInput
            type="text"
            placeholder={t('fields.door_name.placeholder')}
            value={data.delivery_doorbell_name}
            onChange={(value) => updateData('delivery_doorbell_name', value)}
          />
          <TextInput
            type="text"
            placeholder={t('fields.company_name.placeholder')}
            value={data.delivery_company_name}
            onChange={(value) => updateData('delivery_company_name', value)}
          />
        </div>
        <TextArea
          placeholder={t('fields.delivery_note.placeholder')}
          value={data.customer_note}
          onChange={(value) => updateData('customer_note', value)}
          maxLength={80}
        />
      </div>
    );
  }

  function renderRoomServiceFields() {
    if (cached_order?.method !== ORDER_METHODS.ROOM_SERVICE) {
      return null;
    }

    return (
      <div className="mt-small">
        <div className="mb-small">
          <label htmlFor="order_instruction" className="mini text-brand-text-grey">
            {company?.order_instructions_title}
          </label>
        </div>
        <TextArea
          value={data.order_instructions}
          onChange={(value) => updateData('order_instructions', value)}
          placeholder={t('fields.order_instruction.placeholder')}
          maxLength={160}
          id="order_instruction"
        />
      </div>
    );
  }

  function renderDineInFields() {
    return null;
  }

  return (
    <div>
      <OrderMethodInfo onClick={onClickInfo} />
      {renderPickupFields()}
      {renderDeliveryFields()}
      {renderRoomServiceFields()}
      {renderDineInFields()}
    </div>
  );
}
