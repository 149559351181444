import { useState, useEffect, useMemo } from "react";
import RewardIcon from "../../assets/logo/RewardIcon";
import useTheme from "../../hooks/ui/useTheme";
import {
  ThemeInterface,
  RewardInterface,
} from "../../services/exports/Interfaces";
import DiscountCircleLabel from "./DiscountCircleLabel";
import RewardStatusResource from "../../services/resources/RewardStatusResource";
import collect from "collect.js";
import { useTranslation } from "react-i18next";

interface Props {
  rewards?: RewardInterface[] | null;
  points?: number | null;
  hideTitle?: boolean;
  hideDescription?: boolean;
}

const RewardStatus = (props: Props): JSX.Element => {
  const { t } = useTranslation(null, {
    keyPrefix: "Components:Rewards:RewardStatus",
  });

  const { rewards, points, hideTitle, hideDescription } = props;

  const theme: ThemeInterface = useTheme();
  const [newRewards, setNewRewards] = useState<RewardInterface[]>([]);
  const [progress, setProgress] = useState(0);
  const [activeReward, setActiveReward] = useState<RewardInterface | null>(
    null
  );

  const RewardStatus = new RewardStatusResource({
    rewards,
    rewardBalance: points,
  });

  useEffect(() => {
    if (rewards && rewards?.length > 0) {
      updateNewRewards();
      updateProgress();
      getRedeemableDiscount();
    }
  }, [rewards, points]);

  function updateNewRewards() {
    if (rewards && rewards?.length > 0) {
      let newData = [...rewards];
      newData.unshift({
        id: 0,
        cost: 0,
        discount: 0,
        is_available: false,
      });
      setNewRewards(newData);
    }
  }

  function updateProgress() {
    setProgress(RewardStatus.getRewardProgress());
  }

  function getRedeemableDiscount() {
    const reward = RewardStatus.getLastActiveReward();

    if (reward) {
      setActiveReward(reward);
    }
  }

  const renderExplanation = useMemo(() => {
    let getDescription = () => {
      // if user has points but no reward is available at the moment
      if (points > 0 && !activeReward) {
        return null;
      }

      // if user has unlocked the last reward
      let lastReward = rewards && rewards[rewards?.length - 1];
      if (lastReward && activeReward?.id === lastReward?.id) {
        return null;
      }

      // if user has a reward that is currently available
      if (activeReward) {
        return t("status.descriptions.description_3", {
          points: points,
        });
      }
    };

    let getTitle = () => {
      if (activeReward) {
        return t("status.titles.title_2", {
          discount: activeReward?.discount,
        });
      }

      return t("status.titles.title_1");
    };

    return (
      <div>
        {!hideTitle && <h6 className="mb-xsmall">{getTitle()}</h6>}

        {getDescription() && !hideDescription && (
          <p className={"text-brand-text-grey mini mb-xsmall"}>
            {getDescription()}
          </p>
        )}
      </div>
    );
  }, [activeReward, points, hideTitle, hideDescription]);

  function renderTotalPoints() {
    function getPointsTitle() {
      if (points === 1) {
        return t("point");
      }

      return t("point_other");
    }

    return (
      <div className={totalPointsContainer}>
        <RewardIcon fill style={{ marginRight: theme.dimension.padding.mini }} />
        <span>
          <h3 className="inline">{points}</h3>{" "}
          <h6 className="inline">{getPointsTitle()}</h6>
        </span>
      </div>
    );
  }

  const renderPointsOverLay = useMemo(() => {
    const nextReward = RewardStatus.getNextReward();

    function isActive(reward: RewardInterface) {
      let condition1 =
        reward?.cost >= (points ?? 0) && reward?.cost < (nextReward?.cost ?? 0);
      let condition2 = reward?.cost >= (points ?? 0) && !nextReward;
      return condition1 || condition2;
    }

    return (
      <div className={pointsContainer}>
        {newRewards?.map((reward: RewardInterface) => {
          const active = isActive(reward);
          return (
            <div className={pointsWrapper} key={reward?.id?.toString()}>
              <div className={point(active)} />
              <div className={costContainer}>
                <p className={pointsStyle}>{reward?.cost}</p>
              </div>
            </div>
          );
        })}
      </div>
    );
  }, [newRewards]);

  const renderProgressBar = (): JSX.Element => {
    return (
      <div className={progressBarParentContainer}>
        {renderTotalPoints()}
        <div className="relative">
          <div className={progressBarContainer}>
            <div
              style={{
                width: progress + "%",
                height: "100%",
                borderRadius: theme.dimension.borderRadius.tiny,
                backgroundColor: theme.color.companyBrand.primary,
              }}
            />
          </div>
          {renderPointsOverLay}
        </div>
      </div>
    );
  };

  const renderProgressCircles = (): JSX.Element => {
    const reverseRewards = () => {
      try {
        return collect(rewards)?.reverse()?.toArray();
      } catch (error) {
        return [];
      }
    };

    return (
      <div className={progressCircleRow}>
        {rewards?.map((reward: RewardInterface) => {
          const searchReverseIndex = reverseRewards()?.findIndex(
            (item: RewardInterface) => item?.id === reward?.id
          );
          return (
            <div
              className={progressCircleContainer(rewards?.length ?? 0)}
              key={reward?.id?.toString()}
              style={{
                marginRight:
                  (searchReverseIndex !== -1 ? searchReverseIndex : 0) * -4,
              }}
            >
              <DiscountCircleLabel
                points={points}
                reward={reward}
                activeReward={activeReward}
              />
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      {renderExplanation}
      {renderProgressBar()}
      {renderProgressCircles()}
    </div>
  );
};

export default RewardStatus;

const progressBarParentContainer = "mb-[40px]";

const progressBarContainer =
  "h-[12px] w-full rounded bg-brand-inkGrey-grey_2 relative overflow-hidden";

const pointsContainer =
  "h-[12px] w-full absolute top-0 flex flex-row justify-between items-center px-mini rounded";

const pointsWrapper =
  "h-full flex flex-col mx-tiny justify-center items-center relative";

const point = (active: boolean) => {
  return `${
    active ? "h-[16px] w-[12px]" : "h-[5px] w-[5px]"
  } rounded-full bg-white`;
};

const costContainer = "absolute top-6";

const totalPointsContainer = "flex flex-row items-center mb-mini";

const progressCircleRow = "flex flex-row items-end justify-end mt-medium";

const progressCircleContainer = (length: number) =>
  `flex justify-end w-1/${length}`;

const pointsStyle = "tiny text-brand-text-grey";
