export default {
  pickup: "Pickup",
  delivery: "Delivery",
  ongoing_order: "Ongoing order",
  view_more: "Tap to show details",
  status: {
    rejected: {
      title: "Order rejected",
      description: "Tap to show details",
    },
    created: {
      description: "Order received",
    },
    reviewed: {
      description: "Your order has been seen",
    },
  },
  order_type: {
    pickup: 'pickup',
    delivery: 'delivery',
  },
  order_status_toast: {
    order_reviewed: "#{{number}} Your order has been seen",
    order_confirmed: "#{{number}} Confirmed time {{time}}",
    order_preparation_up_time_changed: "#{{number}} {{type}} time changed to {{time}}",
    order_preparing: "Your order #{{number}} is being prepared",
    order_rejected: "#{{number}} order rejected",
  },
  error_messages: {
    failed_to_load: 'Something went wrong, please try again.',
  },
};
