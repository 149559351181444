import Components from './components/index';
import Hooks from './hooks/index';
import Modals from './modals/index';
import Pages from './pages/index';
import Providers from "./providers";
import PusherChannels from './pusher-channels';
import Services from './services/index';

export default {
  Pages,
  Modals,
  Hooks,
  Components,
  Providers,
  PusherChannels,
  Services,
};
