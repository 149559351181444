import React, {ReactElement} from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import BackIcon from "../../assets/logo/BackIcon";
import CheckmarkIcon from "../../assets/logo/CheckmarkIcon";
import {
  ThemeInterface,
} from "../../services/exports/Interfaces";
import useTheme from "../../hooks/ui/useTheme";
import Section from "../../components-library/Section";
import useScreenType from "../../hooks/utility/useScreenType";
import classnames from "classnames";
import Highlighter, { HighlighterProps } from "react-highlight-words";

export interface StepperItemProps {
  number: number;
  title: string;
  isActive: boolean;
  isComplete: boolean;
  isExpandable: boolean;
  onToggle: () => void;

  description?: string|ReactElement;
  isCollapsable?: boolean;

  children?: any;
  className?: string;
  alignTop?: boolean;

  highlighterProps?: Partial<HighlighterProps>;
}

export default function StepperItem(props: StepperItemProps) {
  const {
    number,
    title,
    isActive,
    isComplete,
    isExpandable,
    description,
    isCollapsable,
    onToggle,
    children,
    className,
    alignTop,
    highlighterProps,
  } = props;

  const theme: ThemeInterface = useTheme();

  const isExpanded = isActive || !isCollapsable;

  const displayActive: boolean = isExpanded || isComplete;

  const { isDesktop } = useScreenType();

  function renderIcon() {
    return (
      <div className="rounded-full w-7 h-7 flex relative justify-center items-center mr-mini bg-brand-secondary flex-none">
        {isComplete && !isExpanded ? (
          <CheckmarkIcon
            color={theme.color.text.light}
            className={"h-3 w-3 relative"}
          />
        ) : (
          <h6 className="text-mini text-brand-text-light relative">
            {number}
          </h6>
        )}
      </div>
    );
  }

  const _isExpandable = isActive || (isCollapsable && isExpandable);

  return (
    <Section
      hideTopDivider
      hideBottomDivider={isDesktop}
      className={classnames('px-small py-0', className)}
    >
      <Accordion
        style={{
          boxShadow: "none",
          backgroundColor: "transparent",
          opacity: displayActive ? 1 : 0.15,
        }}
        expanded={isExpanded}
        onChange={() => (_isExpandable ? onToggle() : null)}
      >
        <AccordionSummary
          expandIcon={
            _isExpandable && isCollapsable ? (
              <BackIcon
                className={"h-4 w-4 -rotate-90"}
                color={theme.color.text.default}
              />
            ) : undefined
          }
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          style={{
            margin: 0,
            padding: 0,
          }}
        >
          <div className={headerContainer(alignTop)}>
            {renderIcon()}
            <div>
              <h4
                className={headerStyle(alignTop)}
                data-test="stepper-item-title"
              >
                <Highlighter
                  activeClassName=""
                  autoEscape={true}
                  searchWords={[]}
                  textToHighlight={title}
                  {...highlighterProps}
                />
              </h4>
              {description && description}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails style={{ margin: 0, padding: 0 }}>
          <div className="pb-small">{children}</div>
        </AccordionDetails>
      </Accordion>
    </Section>
  );
}


const headerContainer = (alignTop: boolean) => classnames('flex flex-row', {
  'items-start': alignTop,
  'items-center': !alignTop,
});

const headerStyle = (alignTop: boolean) => classnames('text-left flex flex-1 pr-mini', { '-mt-1': alignTop });
