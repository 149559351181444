export default {
  buttons: {
    no_thanks: 'No, Thanks',
    send_receipt: 'Send Receipt to Email',
  },
  text_inputs: {
    first_name: {
      placeholder: 'First name',
    },
    last_name: {
      placeholder: 'Last name',
    },
    email: {
      placeholder: 'Email',
    },
  },
  error_messages: {
    failed_update_profile: 'Failed to update your personal information',
  },
};
