import { CompanyDetailsInterface } from "../../services/exports/Interfaces";
import useDineInFlow from "../global/useDineInFlow";
import {useTranslation} from "react-i18next";
import useStore from "../global/useStore";
import useOrderMethodsStatus from "./useOrderMethodsStatus";
import useOpeningStatus from "./useOpeningStatus";

interface Props {
  data?: CompanyDetailsInterface;
}

export default function useOrderingDisabledStatus(props: Props = {}) {
  const { t } = useTranslation(null, { keyPrefix: 'Hooks:Availability:UseOrderingDisabledStatus' });

  const { data } = props;

  const { company: companyData} = useStore()?.initialData;
  const company = data ?? companyData;
  const { cached_order } = useStore()?.order;

  const { isDineIn } = useDineInFlow();
  const openingStatus = useOpeningStatus({ data: company });
  const { takeoutEnabled, takeoutOpenForFutureOrders, isOrderMethodCurrentlyOpen } = useOrderMethodsStatus();

  function render(): JSX.Element {
    if (isDineIn) {
      return null;
    }

    if (! takeoutEnabled) {
      return (
        <div className={closedCompanyContainer}>
          <h4 className={closedTextStyle}>{t('error_messages.orders_disabled')}</h4>
        </div>
      );
    }

    if (takeoutOpenForFutureOrders) {
      return null;
    }

    if (! isOrderMethodCurrentlyOpen(cached_order?.method)) {
      return (
        <div className={closedCompanyContainer}>
          <h4 className={closedTextStyle}>{openingStatus.details_text}</h4>
        </div>
      );
    }
  }

  return {
    render: render(),
  };
}

const closedCompanyContainer =
  "lg:sticky fixed bottom-0 w-full flex flex-row items-center justify-center px-medium py-small bg-brand-inkGrey-grey_1 border-t border-brand-inkGrey-grey_2";

const closedTextStyle = "text-brand-text-grey text-center";
