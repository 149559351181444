import { useMemo, useState } from "react";
import CompanySlugContext from "../context/CompanySlugContext";
import ReduxActions from "../store/ReduxActions";
import {actionCreators} from "../store/actions";
import useInitialData from "../hooks/global/useInitialData";
import {CompanySlugContextInterface} from "../services/exports/Interfaces";

interface Props {
  children: any;
}

export default function CompanySlugProvider(props: Props): JSX.Element {
  const { children } = props;

  const { refresh } = useInitialData();

  const [slug, setSlug] = useState<string>(null);
  const [loading, setLoading] = useState(true);

  const updateSlug = async (value) => {
    if (slug === value) {
      return;
    }

    setSlug(value);
    setLoading(true);
    const { success, status} = await refresh(value);
    setLoading(false);

    if (! success && status === 404) {
      ReduxActions.dispatch(
        actionCreators.initialData.updateCompany({
          slug: null,
        })
      );

      return window.location.pathname !== '/404' && window.location.replace('/404');
    }
  }

  const context: CompanySlugContextInterface = useMemo(
    () => ({
      slug,
      loading,
      updateSlug,
    }),
    [slug, loading]
  );

  return (
    <CompanySlugContext.Provider value={context}>
      {children}
    </CompanySlugContext.Provider>
  )
}