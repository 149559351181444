import {useRef, useState, useEffect, ReactElement} from "react";
import classnames from "classnames";
import BackIcon from "../../assets/logo/BackIcon";
import useTheme from "../../hooks/ui/useTheme";

export interface Item {
  data: any;
  render: (index: number, itemHeight: number) => ReactElement;
}

interface Props {
  items: Item[];
  itemsPerSlide?: number;
  itemHeight?: number;
  ratio?: number;
  gap?: number;
  hideArrows?: boolean;
  arrowsPosition?: "top" | "bottom";
  arrowsClassName?: string;
  itemsClassName?: string;
  headerClassName?: string;
  slideJump?: number;
  title?: ReactElement;
}

const CustomCarousel = (props: Props): ReactElement => {
  const {
    items,
    itemsPerSlide: _itemsPerSlide,
    itemHeight: _itemHeight,
    gap: _gap,
    hideArrows,
    ratio,
    arrowsPosition,
    slideJump: _slideJump,
    title,
    arrowsClassName,
    itemsClassName,
    headerClassName,
  } = props;

  const theme = useTheme();

  const containerRef = useRef<HTMLDivElement>(null);

  const sliderWidth = containerRef?.current?.getBoundingClientRect()?.width;

  const gap = _gap ?? theme.dimension.padding.small;

  const itemsPerSlide = _itemsPerSlide ?? 3;

  const itemWidthIncludingGap = sliderWidth / itemsPerSlide - gap;

  const itemWidth = itemWidthIncludingGap - gap;

  const itemHeight = _itemHeight ?? itemWidth * (ratio ?? 1.06);

  const slideJump = (_slideJump ?? itemsPerSlide) - 1;

  const showArrows = items?.length > itemsPerSlide && !hideArrows;

  const [mounted, setMounted] = useState<boolean>(false);

  function onNext(): void {
    containerRef.current.scrollLeft += itemWidthIncludingGap * slideJump;
  }

  function onPrevious(): void {
    containerRef.current.scrollLeft -= itemWidthIncludingGap * slideJump;
  }

  useEffect(() => {
    if (containerRef.current) {
      setMounted(true);
    }
  }, [containerRef.current]);

  return (
    <>
      {arrowsPosition === "top" && (
        <div className={classnames("grid grid-cols-3 ", headerClassName)}>
          <div className="col-span-2 flex">{title}</div>
          {arrowsPosition === "top" && showArrows && (
            <CustomButtonGroup
              next={onNext}
              previous={onPrevious}
              className={arrowsClassName}
            />
          )}
        </div>
      )}
      <div
        ref={containerRef}
        className={classnames(
          "w-full overflow-x-auto scrollbar-hide scroll-smooth snap-x",
          itemsClassName
        )}
      >
        {mounted && (
          <div
            className={"flex py-mini overflow-hidden"}
            style={{
              width: itemWidthIncludingGap * items?.length,
            }}
          >
            {items?.map((item, index) => (
              <div
                key={index?.toString()}
                className="snap-star"
                style={{
                  width: itemWidthIncludingGap,
                  height: itemHeight,
                  paddingRight: gap,
                }}
              >
                {item.render(index, itemHeight)}
              </div>
            ))}
          </div>
        )}
      </div>
      {arrowsPosition !== "top" && showArrows && (
        <CustomButtonGroup
          next={onNext}
          previous={onPrevious}
          className={classnames("mt-xsmall", arrowsClassName)}
        />
      )}
    </>
  );
};

export default CustomCarousel;

const CustomButtonGroup = ({
  next,
  previous,
  className,
}: {
  next?: any;
  previous?: any;
  className?: string;
}) => {
  const theme = useTheme();

  const Arrows = ({
    onClick,
    isRight,
  }: {
    onClick: () => void;
    isRight?: boolean;
  }) => {
    return (
      <button
        className={classnames(
          "w-[28px] h-[28px] rounded-full flex justify-center items-center bg-background-inkWhite-white_1 shadow-icon",
          { "ml-medium": isRight }
        )}
        onClick={onClick}
      >
        <div className={isRight ? "rotate-180" : ""}>
          <BackIcon
            className={"w-[8px] h-[8px]"}
            color={theme.color.text.grey}
          />
        </div>
      </button>
    );
  };

  return (
    <div className={classnames("flex justify-end", className)}>
      <Arrows onClick={previous} />
      <Arrows onClick={next} isRight />
    </div>
  );
};
