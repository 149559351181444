import { PaymentMethod } from "../../../../services/exports/Constants";

export default {
  header: {
    checkout: "Checkout",
    order_summary: "Order Summary",
    price_details: "Price details",
  },
  labels: {
    today: 'today',
  },
  payment_breakdown: {
    subtotal: "Subtotal",
    promo_code: "Promo code",
    delivery_fee: "Delivery fee",
    service_fee: {
      title: "Online processing",
      description: 'To facilitate the operation of our business and minimise expenses related to third-party fees, a nominal service fee is applied. This fee helps us maintain our operations efficiently and continue providing value to our customers.'
    },
    payment_method_upcharge_fee: {
      [PaymentMethod.Paypal]: {
        title: 'PayPal fee',
        description: 'Every time a customer pays with PayPal, PayPal charges 6-7% fees. So on 100€, we lose 6-7€. Therefore, we unfortunately have to add a surcharge of 3.5% for using PayPal. 🤯 Please consider using one of the other secure and encrypted payment methods at checkout. We are very grateful for your understanding and support! 🙏',
      },
      [PaymentMethod.PaypalStandalone]: {
        title: 'PayPal fee',
        description: 'Every time a customer pays with PayPal, PayPal charges 6-7% fees. So on 100€, we lose 6-7€. Therefore, we unfortunately have to add a surcharge of 3.5% for using PayPal. 🤯 Please consider using one of the other secure and encrypted payment methods at checkout. We are very grateful for your understanding and support! 🙏',
      },
    },
    tax: "Taxes",
    points_applied: {
      title: "{{count}} point applied",
      title_other: "{{count}} points applied",
      description: "*Points are automatically applied",
    },
  },
  available_codes: {
    title: "Available Promo Codes",
    code: {
      copy: "COPY",
    },
  },
  minimum_value_error_label: {
    description:
      "Minimum basket amount for this promo is (excl. delivery costs)",
  },
  enter_promo_code: {
    label: 'Promo code',
    placeholder: "Enter Promo Code",
    button: {
      apply: "Apply",
      remove: "Remove",
    },
  },
  tips: {
    title: "Choose a tip",
    description: "Our staff will be grateful 🙏",
  },
  pay_button: {
    total: "Total",
    close_table: "Close Bill & Leave Table",
    complete_payment: "Complete Payment",
    pay_and_place_order: "Pay & Place Order",
    place_order: "Place Order",
    add_items: "Add items",
    remove_items: "Remove items",
  },
  empty_order_basket: {
    title: "Let's go",
    description: "Add items to get started",
    custom_button: {
      add_products: "+ Add products",
    },
  },
  loading: {
    processing:
      "Hold on and please don’t leave while we are processing your payment.",
  },
  error_messages: {
    payment_unsuccessful: {
      title: "Payment unsuccessful",
      description: "Please check your payment information and try again.",
    },
  },
};
