import { PartyInterface } from "../../services/exports/Interfaces";
import { collect } from "collect.js";
import Card from "./Card";
import BackIcon from "../../assets/logo/BackIcon";
import { useTranslation } from "react-i18next";
import useAppMethods from "../../hooks/utility/useAppMethods";

interface Props {
  className?: string;
  party: PartyInterface;
  key?: string;
  onJoin: () => void;
}

export default function PartyCard(props: Props) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:DineIn:PartyCard' });

  const { className, party, key, onJoin } = props;

  const { formatTime } = useAppMethods();

  return (
    <Card className={className} key={key}>
      <div className="flex items-center" onClick={onJoin}>
        <div className="flex flex-col flex-1">
          <p className="mini">
            {collect(party?.users)
              ?.filter((user) => !!user.first_name)
              ?.implode('first_name', ', ')}
          </p>
          <p className="mt-xsmall tiny text-brand-inkGrey-grey_4">
            {t('description', { time: formatTime(party?.created_at) } )}
          </p>
        </div>
        <div className="rotate-180">
          <BackIcon className="h-3.5 w-3.5" />
        </div>
      </div>
    </Card>
  );
}
