import Link from "../../components-library/navigation/Link";

export default function Impressum(): JSX.Element {
  return (
    <div className="lg:p-medium p-small">
      <h1>Impressum</h1>
      <p className="mt-small">
        Betreiber der Website von foodamigos.io ist die
      </p>
      <div className="mt-small">
        <p>Foodamigos GmbH</p>
        <p>Am Hauptbahnhof 6</p>
        <p>53111 Bonn</p>
      </div>
      <p className="mt-small">Tel.: 015203602293</p>
      <p className="mt-small">info@foodamigos.io</p>
      <p className="mt-small">Registergericht: Amtsgericht Bonn</p>
      <p className="mt-small">Handelsregisternummer HRB 26506</p>
      <p className="mt-small">USt.-IdNr. DE347702854</p>
      <p className="mt-small">Geschäftsführer: Artur Zvinchuk</p>
      <p className="mt-small">Rechtliche Hinweise</p>
      <p className="mt-small">
        Verbraucherinformationen gemäß Verordnung (EU) Nr. 524/2013:
      </p>
      <p className="mt-small">
        Im Rahmen der Verordnung über Online-Streitbeilegung in
        Verbraucherangelegenheiten steht unter{" "}
        <Link to="http://ec.europa.eu/consumers/odr" /> eine
        Online-Streitbeilegungsplattform der EU-Kommission zur Verfügung.
      </p>
    </div>
  );
}
