export default {
  banner: {
    description:
      "Erhalte {{range}}, wenn du mit der kostenlosen FoodAmigos App bestellst. Klicke hier, um die App zu erhalten →",
  },
  labels: {
    deals: 'Angebote',
    collecting_points: 'Sammelst du bereits Punkte?',
    earn_points: 'Punkte sammeln & Prämien erhalten',
  },
  checkout_button: {
    title: "Zum Warenkorb",
  },
};
