import {Stringable} from "./Stringable";
import collect from "collect.js";

export default class AddressHelpers {
  public static format(
    streetName: null|string = null,
    streetNumber: null|string = null,
    city: null|string = null,
    state: null|string = null,
    country: null|string = null,
    zipCode: null|string = null,
    note: null|string = null,
  ): string {
    const address = {
      'US': this.implode([
        this.implode([streetNumber, streetName], ' '),
        note,
        city,
        state,
        zipCode,
      ]),
    }[country] ?? this.implode([
      this.implode([streetName, streetNumber], ' '),
      this.implode([city, zipCode], ' '),
    ])

    return (new Stringable(address)).squish().toString();
  }

  private static implode(fields: string[], glue: string = ', '): string {
    return collect(fields)
      .filter((item) => item && item.length > 0)
      .implode(glue).trim();
  }
}
