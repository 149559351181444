import {ORDER_FULFILLMENT_STATUSES} from '../../../../../services/exports/Constants';

export default {
  status: {
    [ORDER_FULFILLMENT_STATUSES.REJECTED]: {
      title: 'Bestellung storniert',
    },
    [ORDER_FULFILLMENT_STATUSES.CREATED]: {
      title: 'Bestellung erhalten',
    },
    [ORDER_FULFILLMENT_STATUSES.REVIEWED]: {
      title: 'Deine Bestellung wurde gesehen',
    },
    [ORDER_FULFILLMENT_STATUSES.ACCEPTED]: {
      title: 'Bestellung bestätigt',
    },
  },
  waiting_confirmation: {
    title: 'Warten auf Bestellbestätigung',
    description: 'Einen Moment...',
  },
  pickup_time: {
    title: 'Abholzeit',
    description: 'Bestätigt von {{name}}',
  },
  delivery_time: {
    title: 'Lieferzeit',
    description: 'Bestätigt von {{name}}',
  },
};
