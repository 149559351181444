import useTheme from "../../hooks/ui/useTheme";

interface Props {
  className?: string;
  style?: Object;
  filled?: boolean;
}

export default function HeartIcon(props: Props) {
  const { className, style, filled } = props;
  const theme = useTheme();

  return (
    <svg
      className={className ?? "h-3.5 w-4"}
      viewBox="0 0 16 14"
      fill="none"
      style={style}
    >
      {filled ? (
        <path 
          d="M8.12943 14L7.02316 13.0082C5.73887 11.851 4.67711 10.8529 3.83787 10.0136C2.99864 9.17439 2.33106 8.42098 1.83515 7.75341C1.33924 7.08583 0.992734 6.4723 0.79564 5.91281C0.598547 5.35332 0.5 4.78111 0.5 4.19619C0.5 3.00091 0.900545 2.00272 1.70163 1.20163C2.50272 0.400545 3.50091 0 4.69619 0C5.3574 0 5.98683 0.139873 6.58447 0.419619C7.18211 0.699364 7.69709 1.09355 8.12943 1.60218C8.56176 1.09355 9.07675 0.699364 9.67439 0.419619C10.272 0.139873 10.9015 0 11.5627 0C12.7579 0 13.7561 0.400545 14.5572 1.20163C15.3583 2.00272 15.7589 3.00091 15.7589 4.19619C15.7589 4.78111 15.6603 5.35332 15.4632 5.91281C15.2661 6.4723 14.9196 7.08583 14.4237 7.75341C13.9278 8.42098 13.2602 9.17439 12.421 10.0136C11.5817 10.8529 10.52 11.851 9.23569 13.0082L8.12943 14Z" 
          fill={theme.color.brand.danger}
        />
      ) : (
        <path
          d="M7.62943 14L6.52316 13.0082C5.23887 11.851 4.17711 10.8529 3.33787 10.0136C2.49864 9.17439 1.83106 8.42098 1.33515 7.75341C0.839237 7.08583 0.492734 6.4723 0.29564 5.91281C0.0985468 5.35332 0 4.78111 0 4.19619C0 3.00091 0.400545 2.00272 1.20163 1.20163C2.00272 0.400545 3.00091 0 4.19619 0C4.8574 0 5.48683 0.139873 6.08447 0.419619C6.68211 0.699364 7.19709 1.09355 7.62943 1.60218C8.06176 1.09355 8.57675 0.699364 9.17439 0.419619C9.77203 0.139873 10.4015 0 11.0627 0C12.2579 0 13.2561 0.400545 14.0572 1.20163C14.8583 2.00272 15.2589 3.00091 15.2589 4.19619C15.2589 4.78111 15.1603 5.35332 14.9632 5.91281C14.7661 6.4723 14.4196 7.08583 13.9237 7.75341C13.4278 8.42098 12.7602 9.17439 11.921 10.0136C11.0817 10.8529 10.02 11.851 8.73569 13.0082L7.62943 14ZM7.62943 11.9401C8.85014 10.8465 9.85468 9.90872 10.6431 9.1267C11.4314 8.34469 12.0545 7.6644 12.5123 7.08583C12.97 6.50727 13.2879 5.99228 13.4659 5.54087C13.644 5.08946 13.733 4.64124 13.733 4.19619C13.733 3.43324 13.4787 2.79746 12.97 2.28883C12.4614 1.7802 11.8256 1.52589 11.0627 1.52589C10.465 1.52589 9.9119 1.69437 9.40327 2.03134C8.89464 2.3683 8.54496 2.79746 8.35422 3.3188H6.90463C6.7139 2.79746 6.36421 2.3683 5.85559 2.03134C5.34696 1.69437 4.79382 1.52589 4.19619 1.52589C3.43324 1.52589 2.79746 1.7802 2.28883 2.28883C1.7802 2.79746 1.52589 3.43324 1.52589 4.19619C1.52589 4.64124 1.6149 5.08946 1.79292 5.54087C1.97094 5.99228 2.28883 6.50727 2.74659 7.08583C3.20436 7.6644 3.82743 8.34469 4.6158 9.1267C5.40418 9.90872 6.40872 10.8465 7.62943 11.9401Z" 
          fill={theme.color.brand.inkGrey.grey_4}
        />
      )}
    </svg>
  );
}
