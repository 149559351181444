export default {
  title: 'Track your order',
  text: 'Thank you for ordering here! Our Uber partnership allows us to handle deliveries directly on our site, saving us {{savings}} on average in delivery commission fees. Your order will be delivered by Uber, ensuring great service 😊',
  labels: {
    delivered_by: 'Delivered by',
  },
  buttons: {
    track_order: 'Get Live Order Updates →',
    track_order_once_confirmed: 'Get Live Order Updates Once Confirmed',
  },
};
