import {BasketContextInterface, CompleteBasketItemModifierInterface, CompleteBasketItemInterface} from "../context/types";
import BasketContext from "../context/BasketContext";
import {useCallback, useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import collect from "collect.js";
import {BasketLineItemModifierInterface, BasketLineItemInterface, StoreInterface} from "../store/types";
import ReduxActions from "../store/ReduxActions";
import {actionCreators} from "../store/actions";
import {COUPON_TYPES, PROMO_CODE_DISCOUNT_TYPES, ProductType} from "../services/exports/Constants";
import MenuResource from "../services/resources/MenuResource";
import useMenu from "../hooks/menu/useMenu";
import {
  ModifierInterface,
  ProductComboInterface,
  ProductInterface,
  RewardInterface
} from "../services/exports/Interfaces";
import useCompanyHours from "../hooks/availability/useCompanyHours";
import useGoogleAnalytics from "../hooks/analytics/useGoogleAnalytics";
import useDiscounts from "../hooks/order/useDiscounts";

interface Props {
  children: any;
}

export default function BasketProvider(props: Props) {
  const { children } = props;

  const { company } = useSelector((store: StoreInterface) => store.initialData);
  const { cached_order } = useSelector((state: StoreInterface) => state.order);
  const { lineItems } = useSelector((store: StoreInterface) => store.basket);
  const data: BasketLineItemInterface[] = lineItems[company?.id] ?? [];

  const { findFreeProduct, compileProduct, applyOrderMethodPrices } = useMenu();
  const { trackAddToCart, trackRemoveFromCart } = useGoogleAnalytics();
  const { orderTimeAvailable } = useCompanyHours();
  const { calculateDiscount } = useDiscounts();
  const hasFreeItem = collect(company?.rewards)?.last(
    (reward: RewardInterface) => reward?.is_available
  )?.discount === 100;

  const refreshBasketInterval = 30000;
  const [refreshBasketTrigger, setRefreshBasketTrigger] = useState(false);

  useEffect(() => {
    const id = setInterval(
      () => refreshBasket(),
      refreshBasketInterval
    );

    return () => clearInterval(id);
  }, []);

  const formatItemForBackend = (data: BasketLineItemInterface): BasketLineItemInterface => {
    return collect({
      product_id: data.product_id,
      quantity: data.quantity,
      is_free: data.is_free,
      note: data.note,
      modifiers: data.modifiers?.map((item) => ({
        modifier_group_id: item.modifier_group_id,
        modifier_id: item.modifier_id,
        quantity: item.quantity,
      }))
    })
    //@ts-ignore
    .when(
      data.children,
      (collection) => collection.put(
        'children',
        data.children?.map((item) => formatItemForBackend(item))
      )
    )
    //@ts-ignore
    .when(
      data.combo_item_id,
      (collection) => collection.put(
        'combo_item_id',
        data.combo_item_id
      )
    ).all() as BasketLineItemInterface;
  }

  const formatForBackend = (): BasketLineItemInterface[] => {
    return items?.map((item) => formatItemForBackend(item));
  }

  const applyPrices = (item: Omit<CompleteBasketItemInterface, 'original_price' | 'actual_price' | 'is_sold_out'>): CompleteBasketItemInterface => {
    if (! item.product) {
      return {
        ...item,
        is_sold_out: true,
        original_price: 0,
        actual_price: 0,
      };
    }

    if (item.product.type === ProductType.Combo) {
      const price = (+collect(item.children).whereNotNull().sum('original_price') - item.product.combo_discount) * item.quantity;
      const isSoldOut = !item.product?.is_available || !!collect(item.children).first(
        (child) => ! child.product?.is_available || collect(child.modifiers).firstWhere('modifier.is_available', false)
      )

      return {
        ...item,
        is_sold_out: isSoldOut,
        original_price: price,
        actual_price: price,
      };
    }

    // When promo code is applied - the item prices are not discounted, but the total is
    if (cached_order?.coupon_type === COUPON_TYPES.PROMO_CODE) {
      return {
        ...item,
        is_sold_out: ! item.product.is_available || collect(item.modifiers).whereNotNull().firstWhere('modifier.is_available', false),
        original_price: (item.product.original_price + +collect(item.modifiers).whereNotNull().sum('original_price')) * item.quantity,
        actual_price: (item.product.original_price + +collect(item.modifiers).whereNotNull().sum('original_price')) * item.quantity,
      };
    }

    return {
      ...item,
      is_sold_out: ! item.product.is_available || collect(item.modifiers).whereNotNull().firstWhere('modifier.is_available', false),
      original_price: (item.product.original_price + +collect(item.modifiers).whereNotNull().sum('original_price')) * item.quantity,
      actual_price: (item.product.actual_price + +collect(item.modifiers).whereNotNull().sum('actual_price')) * item.quantity,
    };
  };

  const compileItem = (item: BasketLineItemInterface): CompleteBasketItemInterface => {
    const isFree = item.is_free && hasFreeItem;
    const product = isFree ? findFreeProduct(item.product_id) : compileProduct(item.product_id);
    const categories = collect(product?.category_ids).map((id) => company?.categories[id]);
    const menus = collect(company?.menus).whereIn(
      'id',
      categories.pluck('menu_ids').flatten().toArray()
    );
    const menu = menus.first((menu) => new MenuResource(menu, company).isActive(cached_order?.scheduled_for))
    const isAvailable = !!menu;

    const getSortingPosition = (modifier: CompleteBasketItemModifierInterface) => {
      const groupIndex = (product as ProductInterface)?.modifier_group_ids?.indexOf(modifier.modifier_group_id);

      return (groupIndex * 1000) + collect((product as ProductInterface)?.modifier_groups[groupIndex]?.modifier_ids ?? [])
        .search(modifier.modifier_id);
    }
    const modifiers = collect(item.modifiers)
      .whereIn('modifier_group_id', (product as ProductInterface)?.modifier_group_ids ?? [])
      .map((modifier) => compileModifier(item, modifier))
      .whereNotNull('modifier')
      .sort((a, b) => getSortingPosition(a) - getSortingPosition(b));

    return applyPrices({
      ...item,
      product,
      menu: menu ?? menus.first(),
      is_available: orderTimeAvailable ? isAvailable : true,
      modifiers: modifiers.toArray(),
      children: collect(item.children).map(
        (child) => compileItem(child)
      ).toArray(),
    });
  };

  const compileModifier = (item: BasketLineItemInterface, modifier: BasketLineItemModifierInterface): CompleteBasketItemModifierInterface => {
    const modifiers = company?.modifiers ?? {};
    const data = applyOrderMethodPrices(modifiers[modifier.modifier_id] ?? null) as ModifierInterface;
    const isFree = item.is_free && hasFreeItem && data?.free_eligible;

    return {
      ...modifier,
      modifier: data,
      original_price: data?.original_price,
      actual_price: isFree ? 0 : data?.actual_price,
    };
  };

  const items = useMemo<CompleteBasketItemInterface[]>(
    () => collect(data)
      .map((item) => compileItem(item))
      .filter((item) => {
        if (! item.product) {
          return false;
        }

        if (item.product.type === ProductType.Combo) {
          return collect(item.children).every(
            (child) => child.product
              && collect((item.product as ProductComboInterface).items).firstWhere('id', child.combo_item_id)
          )
        }

        return true;
      })
      .whereNotNull('product')
      .toArray(),
    [
      data,
      company?.products,
      company?.categories,
      company?.menus,
      cached_order?.scheduled_for,
      cached_order?.coupon_type,
      cached_order?.method,
      refreshBasketTrigger,
    ]
  );

  const searchItem = useCallback(
    (item: BasketLineItemInterface) => {
      if (! company?.id || items.length === 0 || item.is_free || item.children?.length > 0) {
        return null;
      }


      return collect(items)
        .search(
          (lineItem) => ! lineItem.is_free
            && item.product_id === lineItem.product_id
            && item.note === lineItem.note
            && collect(item.modifiers)
              .sortBy((modifier) => `${modifier.modifier_group_id}-${modifier.modifier_id}-${modifier.quantity}`)
              .reduce((carry, modifier) => carry + `${modifier.modifier_group_id}-${modifier.modifier_id}-${modifier.quantity}`, '')
            === collect(lineItem.modifiers)
              .sortBy((modifier) => `${modifier.modifier_group_id}-${modifier.modifier_id}-${modifier.quantity}`)
              .reduce((carry, modifier) => carry + `${modifier.modifier_group_id}-${modifier.modifier_id}-${modifier.quantity}`, '')
        )
    },
    [items, company?.id]
  );

  const addItem = (item: BasketLineItemInterface) => {
    if (! company?.id) {
      return;
    }

    const index = searchItem(item);

    if (typeof index === 'number' && index >= 0) {
      return incrementItem(index);
    }

    ReduxActions.dispatch(actionCreators.basket.addItem({ company_id: company.id, item }));
    trackAddToCart(compileItem(item));
  }

  const incrementItem = (index: number): void => {
    try {
      if (! company?.id) {
        return;
      }

      const item = items[index];

      ReduxActions.dispatch(
        actionCreators.basket.updateItem({
          company_id: company.id,
          index,
          item: { quantity: item.quantity + 1 },
        })
      );
      trackAddToCart({
        ...compileItem(item),
        quantity: item.quantity + 1
      });
    } catch (e) {
      return;
    }
  }

  const decrementItem = (index: number): void => {
    try {
      if (! company?.id) {
        return;
      }

      const item = items[index];

      item.quantity === 1
        ? ReduxActions.dispatch(actionCreators.basket.removeItem({ company_id: company.id, index }))
        : ReduxActions.dispatch(actionCreators.basket.updateItem({
            company_id: company.id,
            index,
            item: { quantity: item.quantity - 1 },
          })
        );
      trackRemoveFromCart({
        ...compileItem(item),
        quantity: item.quantity - 1
      });
    } catch (e) {
      return;
    }
  }

  const clearBasket = () => company?.id && ReduxActions.dispatch(actionCreators.basket.clear({ company_id: company.id }));

  const refreshBasket = () => setRefreshBasketTrigger((prev) => !prev);

  const getProductIds = (): number[] => collect(items).pluck('product_id').merge(collect(items).pluck('children.product_id')).toArray();

  const isValid = useMemo(
    () => !collect(items).first(
      (item) => item.is_sold_out
    ),
    [items]
  );

  const freeItem = useMemo<CompleteBasketItemInterface|null>(
    () => collect(items).firstWhere('is_free', true),
    [items]
  );

  const quantity = useMemo<number>(
    () => +collect(items).sum('quantity'),
    [items]
  );

  const subtotal = useMemo<number>(
    () => +collect(items).sum('actual_price'),
    [items]
  );

  const discount = useMemo<number>(
    () => calculateDiscount(subtotal),
    [
      cached_order?.coupon_type,
      cached_order?.coupon_details,
      subtotal,
    ],
  );

  const total = useMemo<number>(
    () => subtotal - discount,
    [subtotal, discount]
  );

  const context : BasketContextInterface = {
    data,
    items,
    isValid,
    freeItem,
    quantity,
    subtotal,
    total,
    compileItem,
    addItem,
    incrementItem,
    decrementItem,
    clearBasket,
    refreshBasket,
    formatForBackend,
    formatItemForBackend,
    getProductIds,
  };

  return (
    <BasketContext.Provider value={context}>{children}</BasketContext.Provider>
  );
}
