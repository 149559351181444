import FoodAmigosLogo from "../../assets/images/FoodAmigosLogo.png";
import { useTranslation } from "react-i18next";
import LinearProgressBar from "../progress/LinearProgressBar";
import {useSelector} from "react-redux";
import {StoreInterface} from "../../store/types";

interface Props {
  tableNumber?: number;
  progress?: number;
}

export default function LoadingTablePage(props: Props) {
  const { t } = useTranslation(null, { keyPrefix: 'Components:Loading:LoadingTablePage' });

  const { tableNumber, progress } = props;

  const { company } = useSelector((state: StoreInterface) => state.initialData);

  return (
    <div className={container} data-test={"loading-table-page"}>
      {typeof tableNumber === "number" && (
        <div className="flex justify-center items-end">
          <h3 className="text-companyBrand-primary">Nr.</h3>
          <h1 className="text-companyBrand-primary">{tableNumber}</h1>
        </div>
      )}

      <div className="w-1/2 mt-small">
        <LinearProgressBar progress={progress} />
        <div className="bg-companyBrand-primary/20 w-[8px] h-[30px] mx-auto" />
        <div className="bg-companyBrand-primary/20 w-[40px] h-[5px] mx-auto" />
      </div>
      <p className={textStyle}>{t("text")}</p>
      <div className={logoContainer}>
        <h6 className="mr-mini font-semibold">Powered by</h6>
        <img
          src={company?.service_provider?.logo_url ?? FoodAmigosLogo}
          alt="Logo"
          className={foodAmigosLogoStyle}
        />
      </div>
    </div>
  );
}

const container =
  "top-0 left-0 w-full h-full z-[60] fixed inset-0 bg-background-inkWhite-white_1 flex flex-col justify-center items-center";

const textStyle = "mt-small";

const logoContainer =
  "flex flex-row mt-xsmall items-center justify-center w-full fixed bottom-10";

const foodAmigosLogoStyle = "h-10 h-8";
