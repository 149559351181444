import React from "react";

interface Props {
  className?: string;
  style?: Object;
  color?: string;
}

export default function ChatIcon(props: Props) {
  const { className, style, color } = props;

  return (
    <svg
      className={className ?? "h-4 w-4"}
      viewBox="0 0 21 20"
      fill="none"
      style={style}
    >
      <path
        d="M6.5 9C6.78333 9 7.02083 8.90417 7.2125 8.7125C7.40417 8.52083 7.5 8.28333 7.5 8C7.5 7.71667 7.40417 7.47917 7.2125 7.2875C7.02083 7.09583 6.78333 7 6.5 7C6.21667 7 5.97917 7.09583 5.7875 7.2875C5.59583 7.47917 5.5 7.71667 5.5 8C5.5 8.28333 5.59583 8.52083 5.7875 8.7125C5.97917 8.90417 6.21667 9 6.5 9ZM10.5 9C10.7833 9 11.0208 8.90417 11.2125 8.7125C11.4042 8.52083 11.5 8.28333 11.5 8C11.5 7.71667 11.4042 7.47917 11.2125 7.2875C11.0208 7.09583 10.7833 7 10.5 7C10.2167 7 9.97917 7.09583 9.7875 7.2875C9.59583 7.47917 9.5 7.71667 9.5 8C9.5 8.28333 9.59583 8.52083 9.7875 8.7125C9.97917 8.90417 10.2167 9 10.5 9ZM14.5 9C14.7833 9 15.0208 8.90417 15.2125 8.7125C15.4042 8.52083 15.5 8.28333 15.5 8C15.5 7.71667 15.4042 7.47917 15.2125 7.2875C15.0208 7.09583 14.7833 7 14.5 7C14.2167 7 13.9792 7.09583 13.7875 7.2875C13.5958 7.47917 13.5 7.71667 13.5 8C13.5 8.28333 13.5958 8.52083 13.7875 8.7125C13.9792 8.90417 14.2167 9 14.5 9ZM0.5 20V2C0.5 1.45 0.695833 0.979167 1.0875 0.5875C1.47917 0.195833 1.95 0 2.5 0H18.5C19.05 0 19.5208 0.195833 19.9125 0.5875C20.3042 0.979167 20.5 1.45 20.5 2V14C20.5 14.55 20.3042 15.0208 19.9125 15.4125C19.5208 15.8042 19.05 16 18.5 16H4.5L0.5 20ZM3.65 14H18.5V2H2.5V15.125L3.65 14Z"
        fill={color ?? 'black'}
      />
    </svg>
  );
}
