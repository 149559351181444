export default {
  labels: {
    request_invoice: 'Request invoice',
  },
  modal: {
    title: 'Invoice information',
    form: {
      company_name: 'Company name (optional)',
      first_name: 'First name',
      last_name: 'Last name',
      address: 'Street + number',
      zip_code: 'Zip code',
      city: 'City',
      tax_id: 'St-Nr./USt-IdNr. *',
      email: 'Email',
    },
    save_button: 'Save',
  },
};
