export default {
  animation_description: {
    order_here_earn_rewards: "Hier bestellen und belohnt werden",
    order_here_earn_points: "Du sammelst Punkte, wenn du hier bestellst",
    menu_item_reward_threshold:
      "1 Menü Artikel (min. {{threshold}}) = {{count}} Punkt",
    menu_item_reward_threshold_other:
      "1 Menü Artikel (min. {{threshold}}) = {{count}} Punkte",
    order_reward_threshold: "1 Bestellung (min. {{threshold}}) = {{count}} Punkt",
    order_reward_threshold_other:
      "1 Bestellung (min. {{threshold}}) = {{count}} Punkte",
  },
  points_earned: {
    titles: {
      no_free_menu_item: "Erhalte {{discount}}% Rabatt 🥳",
      free_menu_item: "Erhalte 1 gratis {{product}} 🥳",
      multiple_free_menu_item: "Erhalte 1 gratis {{product}} & mehr 🥳",
    },
  },
  button: {
    okay: "Okay",
  },
};
