import useTheme from "../../hooks/ui/useTheme";

interface Props {
  className?: string;
  style?: Object;
  color?: string;
}

export default function ReceiptIcon(props: Props) {
  const { className, color, style } = props;
  const theme = useTheme();

  return (
    <svg
      className={className ?? "h-7 w-8"}
      viewBox="0 0 34 32"
      fill="none"
      style={style}
    >
      <path
        d="M8 26V6L9.5 7.5L11 6L12.5 7.5L14 6L15.5 7.5L17 6L18.5 7.5L20 6L21.5 7.5L23 6L24.5 7.5L26 6V26L24.5 24.5L23 26L21.5 24.5L20 26L18.5 24.5L17 26L15.5 24.5L14 26L12.5 24.5L11 26L9.5 24.5L8 26ZM11 21H23V19H11V21ZM11 17H23V15H11V17ZM11 13H23V11H11V13ZM10 23.1H24V8.9H10V23.1Z"
        fill={color ?? theme.color.brand.secondary}
      />
    </svg>
  );
}
