import { useEffect, useState } from "react";
import Theme from "../../components-library/Theme";
import { ThemeInterface } from "../../services/exports/Interfaces";
import ColorResource from "../../services/resources/ColorResource";
import useConfig from "./useConfig";

export default function useCreateTheme(): ThemeInterface {
  const [theme, setTheme] = useState(Theme);

  const { primary_color } = useConfig();

  function createTheme() {
    let newGlobals: ThemeInterface = { ...Theme };
    let newColor = newGlobals.color;

    newColor = {
      ...newColor,
      companyBrand: {
        primary: primary_color ?? newColor?.brand?.primary,
        gradients: {
          primary: [
            primary_color ?? newColor?.brand?.primary,
            primary_color ?? newColor?.brand?.primary,
          ],
        },
      },
    };

    newGlobals.color = newColor;
    updateTheme(newGlobals);
  }

  function updateTheme(newTheme: ThemeInterface) {
    document.documentElement.style.setProperty(
      "--company-color-primary",
      ColorResource.getRgbValue(newTheme.color.companyBrand.primary)
    );
    document.documentElement.style.setProperty(
      "--company-color-gradient-primary",
      ColorResource.getRgbValue(
        newTheme.color.companyBrand.gradients?.primary[0]
      )
    );
    document.documentElement.style.setProperty(
      "--company-color-gradient-secondary",
      ColorResource.getRgbValue(
        newTheme.color.companyBrand.gradients?.primary[1]
      )
    );
    setTheme(newTheme);
  }

  useEffect(() => {
    createTheme();
  }, [primary_color]);

  return theme;
}
