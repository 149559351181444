export default {
  title: 'Willkommen bei {{company}} 🤗',
  text: 'Versuche es doch mal mit der Bestellung vom Handy aus. Die schnellste Art zu bestellen und zu bezahlen 📱',
  buttons: {
    skip: 'Überspringen',
    got_it: 'Alles Klar 👍',
  },
  features: {
    1: {
      title: '1. Klick dich durch das Menü & bestelle vom Handy',
    },
    2: {
      title: '2. Wir bringen deine Bestellung zum Tisch',
    },
    3: {
      title: '3. Bezahlen vom Handy, wenn du bereit bist',
      description: '*Du kannst die Rechnung sogar mit Freunden teilen, die am selben Tisch sitzen 🖖',
    },
  },
};
