import { useMemo } from 'react';
import useTheme from '../../hooks/ui/useTheme';
import { useSelector } from 'react-redux';
import InfoIcon from '../../assets/logo/InfoIcon';
import PicturesSlider from './PicturesSlider';
import { useTranslation } from 'react-i18next';
import useAppMethods from "../../hooks/utility/useAppMethods";
import BackIcon from '../../assets/logo/BackIcon';
import { StoreInterface } from '../../store/types';
import useScreenType from '../../hooks/utility/useScreenType';
import { useNavigate } from 'react-router-dom';
import useOpeningStatus from '../../hooks/availability/useOpeningStatus';
import classNames from 'classnames';
import useConfig from '../../hooks/ui/useConfig';
import { DeliveryProvider, ORDER_METHODS, PROMO_CODE_DISCOUNT_TYPES, PROMO_CODE_TYPES } from '../../services/exports/Constants';

interface Props {
    openInfoModal: () => void;
}

export default function CompanyInfo(props: Props) {
    const { t } = useTranslation(null, { keyPrefix: 'Components:Company:CompanyInfo' });

    const { openInfoModal } = props;

    const { formatCurrency } = useAppMethods();

    const { franchise, company } = useSelector((state: StoreInterface) => state.initialData);
    const { cached_order } = useSelector((state: StoreInterface) => state.order);

    const promoCode = company?.promo_codes?.length ? company.promo_codes[0] : null;
    const promoCodeVisible = company?.show_master_promo_code && promoCode;

    const navigate = useNavigate();
    const { logo_url } = useConfig();
    const theme = useTheme();
    const { isDesktop } = useScreenType();
    const openingStatus = useOpeningStatus();

    function renderAddress() {
        const address = company?.address + (company?.city ? `, ${company?.city}` : '');

        return <p className="text-mini text-brand-inkGrey-grey_4 mt-xmini">{address}</p>;
    }

    function renderOpeningHours() {
        return (
            <div className="flex mt-xmini items-center justify-center lg:justify-start">
                <p className="text-mini text-brand-inkGrey-grey_4">{openingStatus.details_text}</p>
                <div className="flex ml-xxsmall items-center cursor-pointer" onClick={openInfoModal}>
                    <p className="text-mini text-brand-inkGrey-grey_4 mr-tiny font-semibold">{t('more_info')}</p>
                    <InfoIcon color={theme.color.brand.inkGrey.grey_4} />
                </div>
            </div>
        );
    }

    const renderOrderMethodInfo = useMemo<JSX.Element>(() => {
        const renderBandge = (text: string) => (
            <div className="bg-brand-inkGrey-grey_6 px-mini rounded-xl">
                <span className="text-tiny text-brand-inkGrey-grey_4 align-middle">
                    {text}
                </span>
            </div>
        );

        const isDelivery = cached_order?.method === ORDER_METHODS.DELIVERY;
        const usesExternalDelivery = company?.delivery_provider !== DeliveryProvider.Standalone;

        const fee = isDelivery
            ? (usesExternalDelivery ? cached_order?.delivery_quote?.customer_price : cached_order?.delivery_zone?.fee)
            : 0;

        const minOrder = isDelivery ? cached_order?.delivery_zone?.min_threshold : 0;

        return (
            <div className="flex mt-xmini items-center justify-center lg:justify-start space-x-2">
                {fee ? renderBandge(t(`order_method_info.fee.${cached_order?.method}`, { fee: formatCurrency(fee) })) : null}
                {minOrder ? renderBandge(t(`order_method_info.min_order`, { minOrder: formatCurrency(minOrder) })) : null}
            </div>
        );
    }, [
        cached_order?.method, 
        cached_order?.delivery_zone?.fee, 
        cached_order?.delivery_zone?.min_threshold,
        cached_order?.delivery_quote?.customer_price
    ]);

    function renderPromoCodeBanner() {
        const formattedDiscount = promoCode.discount_type === PROMO_CODE_DISCOUNT_TYPES.PERCENTAGE ?
            promoCode.components.find(component => component.is_used === false).discount + '%':
            formatCurrency(promoCode.discount, { trimInteger: true });

        return (
            <div className="lg:hidden absolute bottom-0 w-full p-mini bg-companyBrand-primary rounded-t-lg text-center">
                <span className="text-white text-tiny font-semibold">
                    {
                        promoCode.type === PROMO_CODE_TYPES.ONE_TIME_PROMO_CODE ||  
                        promoCode.discount_type === PROMO_CODE_DISCOUNT_TYPES.PERCENTAGE &&
                        t('promo_code_banner.single_use', {
                            discount: formattedDiscount,
                            code: promoCode.code,
                        }
                    )}

                    {
                        promoCode.type === PROMO_CODE_TYPES.MULTI_PROMO_CODE &&
                        promoCode.discount_type === PROMO_CODE_DISCOUNT_TYPES.FIXED_AMOUNT &&
                        t('promo_code_banner.multi_use', {
                            discount: formattedDiscount,
                            count: promoCode.components.length,
                            code: promoCode.code,
                        }
                    )}
                </span>
            </div>
        );
    };

    const renderImageCarousel = useMemo<JSX.Element>(() => {
        const pictureLength: number = company?.pictures?.length ?? 0;

        return (
            <div className="relative w-full lg:h-fit">
                <div className="relative lg:h-fit h-[150px]">
                    {pictureLength > 0 && (
                        <PicturesSlider
                            pictures={company?.pictures}
                            className={classNames('lg:rounded-xl rounded-none overflow-hidden lg:h-[270px]', {
                                'lg:h-[300px]': franchise?.has_multiple_companies,
                            })}
                        />
                    )}
                </div>
                {logo_url && (
                    <div className={classNames(logoContainer, {'-translate-y-3/4': promoCodeVisible})}>
                        <img className="h-full w-full object-contain" src={logo_url} alt="logo" />
                    </div>
                )}

                {promoCodeVisible && renderPromoCodeBanner()}
            </div>
        );
    }, [company?.pictures, franchise?.has_multiple_companies, logo_url]);

    return (
        <div className="h-fit w-full">
            <div className="w-full relative flex flex-col justify-center">
                {renderImageCarousel}
                <div className={infoContainer}>
                    <h1 className="lg:mr-small font-semibold leading-6" style={{ fontSize: theme.fonts.fontSizes.h3 }}>
                        {company?.name}
                    </h1>
                    {franchise?.has_multiple_companies && (
                        <div
                            className="flex mt-xmini cursor-pointer justify-center lg:justify-start" 
                            onClick={() => navigate('/', { replace: true })}
                        >
                            <h6 className="text-mini my-auto mr-tiny text-brand-inkGrey-grey_4">
                                {t('buttons.change_store')}
                            </h6>
                            <BackIcon color={theme.color.brand.inkGrey.grey_4} className="rotate-180 h-3 w-3 my-auto" />
                        </div>
                    )}
                    {renderAddress()}
                    {renderOpeningHours()}
                    {renderOrderMethodInfo}
                </div>
            </div>
            {isDesktop && <div className="h-16 w-full" />}
        </div>
    );
}

const infoContainer =
    'lg:ml-small pt-small lg:pt-xsmall pb-mini px-xsmall lg:pr-xlarge lg:absolute relative justify-center flex flex-col z-10 lg:border-none lg:shadow-section lg:mt-none lg:max-w-1/2 lg:w-fit lg:bg-background-inkWhite-white_1 lg:rounded-xl text-center lg:text-left';

const logoContainer =
    'h-16 w-16 p-tiny bg-background-inkWhite-white_0 shadow-icon rounded-xl absolute left-1/2 top-1/2 lg:top-auto lg:left-[16px] lg:bottom-0 -translate-x-1/2 lg:translate-x-0 -translate-y-1/2 lg:translate-y-1/2 lg:h-20 lg:w-20';
