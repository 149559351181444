import {DropoffAction} from '../../../../../../services/exports/Constants';

export default {
  custom_button: {
    continue: 'Continue',
  },
  fields: {
    dropoff_action: {
      label: 'Drop-off Options',
      options: {
        [DropoffAction.RingDoorBell]: 'Ring door bell',
        [DropoffAction.Sms]: 'Contact me via SMS upon arrival',
      },
    },
    door_name: {
      placeholder: 'Door name (optional)',
    },
    company_name: {
      placeholder: 'Company name (optional)',
    },
    delivery_note: {
      placeholder: 'Add delivery instructions e.g. call at arrival (optional)',
    },
    order_instruction: {
      placeholder: 'Enter order instructions...',
    },
  },
  error_messages: {
    update_profile_unsuccessful: 'Failed to update your profile',
  },
};
