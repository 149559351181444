import DiscountCircleLabel from "./DiscountCircleLabel";
import LoyaltyPointsLabel from "./LoyaltyPointsLabel";
import RewardStatus from "./RewardStatus";
import RewardStatusLabel from "./RewardStatusLabel";

export default {
  DiscountCircleLabel,
  LoyaltyPointsLabel,
  RewardStatus,
  RewardStatusLabel,
};
