import {CompanyDetailsInterface, MenuInterface, OpeningHoursInterface} from "../exports/Interfaces";
import moment, {Moment} from "moment";
import WorkScheduleHelpers from "../helpers/WorkScheduleHelpers";

export default class MenuResource {
  private readonly schedule: OpeningHoursInterface[];

  constructor(menu: MenuInterface, company: CompanyDetailsInterface) {
    this.schedule = menu?.schedule ?? company?.work_schedule;
  }

  public isActive(orderTime: Moment|string|null): boolean {
    if (! this?.schedule) {
      return true;
    }

    return WorkScheduleHelpers.isOpen(this.schedule, moment(orderTime ?? undefined));
  }

  public isActiveToday(): boolean {
    if (! this?.schedule) {
      return true;
    }

    return WorkScheduleHelpers
      .getCurrentDaySchedule(this?.schedule)
      .isNotEmpty();
  }
}
