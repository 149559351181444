import CheckoutStepper from './CheckoutStepper';
import OrderInfoStep from './OrderInfoStep';
import PaymentInfoStep from './PaymentInfoStep';
import PersonalInfoStep from './PersonalInfoStep';
import PhoneNumberStep from './PhoneNumberStep';
import ReceiptInfoStep from './ReceiptInfoStep';

export default {
  CheckoutStepper,
  OrderInfoStep,
  PaymentInfoStep,
  PersonalInfoStep,
  PhoneNumberStep,
  ReceiptInfoStep,
}