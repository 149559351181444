import { actionCreators } from "../../store/actions";
import ReduxActions from "../../store/ReduxActions";
import {
  ErrorHandlerInterface,
  OrderDataInterface,
  OrderInterface,
  PostPartyOrderComponentInterface,
} from "../exports/Interfaces";
import { DataWrappedResponse, DataWrapper } from "../exports/Types";
import BackendApiClient from "./BackendApiClient";

class SubOrdersManager {
  public create = async (
    orderId: number,
    data: OrderDataInterface,
    errorHandlers?: ErrorHandlerInterface
  ): Promise<DataWrappedResponse<DataWrapper<OrderInterface>>> => {
    const request = await BackendApiClient.request({
      method: 'POST',
      url: `/orders/${orderId}/sub-orders`,
      data,
    }, false, errorHandlers);

    const { response, success } = request;
    if (success) {
      this.saveOrderRedux(response?.data?.data);
    }

    return request;
  };

  private saveOrderRedux(response: OrderInterface) {
    ReduxActions.dispatch(actionCreators.dineInFlow.setOrder(response));
  }
}

export default new SubOrdersManager();
