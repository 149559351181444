import { createSlice } from "@reduxjs/toolkit";
import { REDUX_STORE_SLICES } from "../../services/exports/Constants";
import { AppStatusInterface } from "../../services/exports/Interfaces";

export default createSlice({
  name: REDUX_STORE_SLICES.APP_STATUS,
  initialState: {
    pickup_delivery_popup_seen: false,
    environment: import.meta.env.VITE_MODE,
    cookiesConcent: undefined,
  },
  reducers: {
    setPickUpDeliveryPopupSeen: (state: AppStatusInterface, action) => {
      try {
        state.pickup_delivery_popup_seen = action.payload;
      } catch (error) {}
    },
    setEnvironment: (state: AppStatusInterface, action) => {
      try {
        state.environment = action.payload;
      } catch (error) {}
    },
    setCookieConsent: (state: AppStatusInterface, action) => {
      try {
        state.cookiesConcent = action.payload;
      } catch (error) {}
    },
  },
});

export const props = (state: AppStatusInterface) => state;
