import { useState, useEffect, useMemo, useRef } from "react";
import CustomButton from "../../components-library/buttons/CustomButton";
import CustomNavBar from "../../components-library/navigation/CustomNavBar";
import { AnimatePresence, motion, useAnimation } from "framer-motion";
import { useLocation } from "react-router-dom";
import { SCREENS } from "../../services/exports/Constants";
import useStore from "../../hooks/global/useStore";
import SplitPaymentIllustration from "../../assets/illustrations/SplitPaymentIllustration";
import useTheme from "../../hooks/ui/useTheme";
import ServeTableIllustration from "../../assets/illustrations/ServeTableIllustration";
import OrderFromPhone from "../../assets/illustrations/OrderFromPhone";
import ReduxActions from "../../store/ReduxActions";
import { actionCreators } from "../../store/actions";
import { useTranslation } from "react-i18next";
import ButtonFooter from "../../components-library/footers/ButtonFooter";
import useInitialData from "../../hooks/global/useInitialData";
import classnames from "classnames";
import LoadingTablePage from "../../components-library/loading/LoadingTablePage";
import useDineInFlow from "../../hooks/global/useDineInFlow";
import CookieConsent from "../../components-library/CookieConsent";
import useUrlParams from "../../hooks/utility/useUrlParams";
import TablesManager from "../../services/api/TablesManager";
import useNavigate from "../../hooks/navigation/useNavigate";
import useOrderMethodsStatus from "../../hooks/availability/useOrderMethodsStatus";

interface SentenceProps {
  sentence: string;
  startAnimation: boolean;
  onTextFadeInCompleted?: () => void;
}

const FadeInSentence = (props: SentenceProps): JSX.Element => {
  const { sentence, startAnimation, onTextFadeInCompleted } = props;
  const animation = useAnimation();
  const getVariants = (index: number) => {
    return {
      visible: {
        opacity: 1,
        transition: {
          delay: index * 0.15,
          duration: 0.4,
          ease: "easeIn",
        },
      },
      hidden: { opacity: 0 },
    };
  };

  useEffect(() => {
    if (startAnimation) {
      animation.start("visible");
    }
  }, [startAnimation, animation]);

  let data: string[] = sentence?.split(" ");
  return (
    <div className={wordsContainer}>
      {data?.map((word: string, index: number) => {
        return (
          <motion.div
            className={wordContainer}
            initial="hidden"
            animate={animation}
            variants={getVariants(index)}
            onAnimationComplete={() =>
              index === data?.length - 1
                ? onTextFadeInCompleted && onTextFadeInCompleted()
                : () => undefined
            }
          >
            {word}
          </motion.div>
        );
      })}
    </div>
  );
};

const Intro = (): JSX.Element => {
  const { t } = useTranslation(undefined, { keyPrefix: "Pages:DineIn:WelcomeScreen" });

  const { data } = useInitialData();

  const { company } = data;

  const theme = useTheme();
  const { navigate } = useNavigate();
  const { search } = useLocation();

  const headerRef = useRef<HTMLDivElement>(null);

  const navBarRef = useRef<HTMLDivElement>(null);

  const [animation1Completed, setAnimation1Completed] =
    useState<boolean>(false);

  const [animation2Completed, setAnimation2Completed] =
    useState<boolean>(false);

  const [animation3Completed, setAnimation3Completed] =
    useState<boolean>(false);

  const [animation4Completed, setAnimation4Completed] =
    useState<boolean>(false);

  useEffect(() => {
    init();
  }, []);

  async function init(): Promise<void> {
    setTimeout(() => {
      setAnimation1Completed(true);
    }, 500);
  }

  function navigateToMenu(): void {
    navigate(`${SCREENS.MENU}${search}`);
  }

  const renderSkipButton = (): JSX.Element => {
    return <p className={skipStyle}>{t("buttons.skip")}</p>;
  };

  const renderButton = useMemo<JSX.Element | null>(() => {
    return animation4Completed ? (
      <motion.div
        className={buttonContainer}
        initial={{ y: 60 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.7, ease: "easeOut" }}
      >
        <ButtonFooter>
          <CustomButton
            title={t("buttons.got_it")}
            onClick={navigateToMenu}
            titleClass="opacity-90"
          />
        </ButtonFooter>
      </motion.div>
    ) : null;
  }, [animation4Completed]);

  interface FeatureInterface {
    id: number;
    title: string;
    description?: string;
    icon: JSX.Element;
  }

  const renderFeatureList = useMemo<JSX.Element | null>(() => {
    let features: FeatureInterface[] = [
      {
        id: 1,
        title: t("features.1.title"),
        icon: (
          <OrderFromPhone
            color={theme.color.companyBrand.primary}
            className={featureImage}
          />
        ),
      },
      {
        id: 2,
        title: t("features.2.title"),
        icon: (
          <ServeTableIllustration
            color={theme.color.companyBrand.primary}
            className={featureImage}
          />
        ),
      },
      {
        id: 3,
        title: t("features.3.title"),
        description: t("features.3.description"),
        icon: (
          <SplitPaymentIllustration
            color={theme.color.companyBrand.primary}
            className={featureImage}
          />
        ),
      },
    ];

    return animation3Completed ? (
      <div className={featureListContainer}>
        {features?.map((feature: FeatureInterface, index: number) => {
          return (
            <AnimatePresence>
              <motion.div
                key={feature?.id?.toString()}
                className={classnames(featureContainer, {
                  "border-b": features?.length - 1 !== index,
                })}
                initial={{ y: 30, opacity: 0, scale: 0.5 }}
                animate={{ y: 0, opacity: 1, scale: 1 }}
                transition={{
                  delay: 0.5 * index,
                  duration: 0.7,
                  ease: "easeOut",
                }}
                onAnimationComplete={() =>
                  index === features?.length - 1
                    ? setAnimation4Completed(true)
                    : null
                }
              >
                <div className={featureWrapper}>
                  <p className={featureTitleStyle}>{feature?.title}</p>
                  <p className={featureDescriptionStyle}>
                    {feature?.description}
                  </p>
                </div>
                {feature?.icon}
              </motion.div>
            </AnimatePresence>
          );
        })}
      </div>
    ) : null;
  }, [animation3Completed]);

  const renderBody = (): JSX.Element | null => {
    return animation2Completed ? (
      <motion.div
        className={wrapper}
        initial={{ y: 50, opacity: 0 }}
        animate={{
          y: 0,
          opacity: 1,
        }}
        transition={{ duration: 1, ease: "easeInOut" }}
        style={{
          marginTop: headerRef.current?.getBoundingClientRect()?.height,
        }}
        onAnimationComplete={() =>
          setTimeout(() => setAnimation3Completed(true), 300)
        }
      >
        {renderFeatureList}
      </motion.div>
    ) : null;
  };

  return (
    <>
      <div className={container}>
        <div className={mainContainer}>
          <div className={headerContainer} ref={headerRef}>
            <div className={navBarContainer} ref={navBarRef}>
              <CustomNavBar
                hideBackIcon
                transparent
                customRightAction={renderSkipButton()}
                rightClick={navigateToMenu}
              />
            </div>
            <div className={titleContainer}>
              <h4 className={fadeTitleStyle}>
                {t("title", { company: company?.name })}
              </h4>
              <h4 className={titleStyle}>
                <FadeInSentence
                  sentence={t("text")}
                  startAnimation={animation1Completed}
                  onTextFadeInCompleted={() =>
                    setTimeout(() => setAnimation2Completed(true), 100)
                  }
                />
              </h4>
            </div>
          </div>
          {renderBody()}
          {renderButton}
        </div>
      </div>
    </>
  );
};

const WelcomeScreen = (): JSX.Element => {
  const { cookiesConcent } = useStore()?.appStatus;

  const { table, firstTimeDineInVisitor } = useDineInFlow();
  const { refresh } = useInitialData();
  const { dineInCurrentlyOpen } = useOrderMethodsStatus();
  const { navigate } = useNavigate();
  const { search } = useLocation();
  const params = useUrlParams();

  const { table_id } = params;

  const [refreshing, setRefreshing] = useState<boolean>(true);

  const [initialLoading, setInitialLoading] = useState<boolean>(
    !table?.id || !cookiesConcent
  );

  const [progress, setProgress] = useState<number>(0);

  const [tableNumber, setTableNumber] = useState<number | null>(null);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (cookiesConcent) {
      setTimeout(() => setProgress(100), 100);
      setTimeout(() => setInitialLoading(false), 2000);
    }
  }, [cookiesConcent]);

  async function init(): Promise<void> {
    await refresh(null, true);
    const { success, response } = await TablesManager.show(+table_id);

    if (success) {
      setTableNumber(+response?.data?.data?.number);
    }

    setRefreshing(false);
    if (! dineInCurrentlyOpen) {
      return navigate(`${SCREENS.DINE_IN}${search}`);
    }

    handleFirstTimeVisit();
  }

  function handleFirstTimeVisit(): void {
    if (firstTimeDineInVisitor) {
      return ReduxActions.dispatch(
        actionCreators.dineInFlow.setFirstTimeDineInVisitor(false)
      );
    }
    navigateToMenu();
  }

  function navigateToMenu(): void {
    navigate(`${SCREENS.MENU}${search}`);
  }

  if (refreshing) {
    return null;
  }

  return initialLoading ? (
    <>
      <LoadingTablePage tableNumber={tableNumber} progress={progress} />
      <CookieConsent />
    </>
  ) : (
    <Intro />
  );
};

export default WelcomeScreen;

const container =
  "h-screen w-screen absolute inset-0 bg-gradient-to-r from-companyBrand-gradients-primary-color_1 via-companyBrand-gradients-primary-color_1 to-companyBrand-gradients-primary-color_2 overflow-y-auto";

const mainContainer = "h-full w-full relative";

const headerContainer = "w-full fixed top-0 flex flex-col justify-end";

const navBarContainer = "top-0 w-full";

const titleContainer = "p-small";

const wrapper =
  "w-full h-full rounded-t-2xl bg-background-inkWhite-white_1 absolute overflow-y-auto  px-small pt-small pb-[100px]";

const buttonContainer = "fixed bottom-0 w-full";

const wordsContainer = "flex flex-row flex-wrap";

const wordContainer = "break-words mr-mini";

const featureContainer = "overflow-hidden flex flex-row items-center py-small";

const featureWrapper = "flex flex-1 flex-col pr-small";

const featureImage = "h-[80px] pt-mini";

const featureListContainer = "pb-[70px] relative";

const skipStyle = "mini semibold text-brand-text-light opacity-90";

const fadeTitleStyle = "text-brand-text-light mb-mini opacity-90";

const titleStyle = "text-brand-text-light";

const featureTitleStyle = "mini semibold";

const featureDescriptionStyle = "text-brand-text-grey mini";
