import {OTP_DELIVERY_CHANNELS} from '../../../../../../services/exports/Constants';

export default {
  otp: {
    resend: {
      options: {
        [OTP_DELIVERY_CHANNELS.EMAIL]: {
          title: 'Nicht den Code per Email erhalten?',
          button: 'SMS Code senden →',
        },
        [OTP_DELIVERY_CHANNELS.SMS]: {
          title: 'Nicht den Code erhalten?',
          button: 'Code per SMS erneut senden →',
        },
      }
    },
    text: {
      [OTP_DELIVERY_CHANNELS.SMS]: 'Es wurde eine Nachricht mit einem Verifizierungscode an dein Telefon gesendet. Gib den Code ein, um fortzufahren.',
      [OTP_DELIVERY_CHANNELS.EMAIL]: 'Bitte gib den 4-stelligen Code ein, der an diese Adresse gesendet wurde: <br/> <strong>{{address}}</strong>',
    },
    tip: {
      [OTP_DELIVERY_CHANNELS.SMS]: '',
      [OTP_DELIVERY_CHANNELS.EMAIL]: 'Tipp: Überprüfe deinen Posteingang und deinen Spam-Ordner.',
    },
  },
  terms_and_conditions: {
    terms: 'Nutzungsbedingungen',
    conditions: 'Datenschutzerklärung',
    accept_terms:
      'Mit der Anmeldung erklärst du dich mit unseren <TermsLink>{{terms}}</TermsLink> und unser <ConditionsLink>{{conditions}}</ConditionsLink> einverstanden.',
  },
  phone_number_input: {
    placeholder: 'Handynummer eingeben',
  },
  custom_button: {
    continue: 'Weiter',
  },
  error_messages: {
    frequent_requests:
      'Bitte prüfen deine SMS. Wir haben den Code bereits an die angegeben Nummer per SMS gesendet. Wenn nicht prüfe bitte die Telefonnummer oder versuche es bitte in 10 Minuten erneut.',
    invalid_number: 'Ungültige Telefonnummer. Bitte versuche es erneut.',
    change_phone_number_unsuccessful:
      'Änderung der Telefonnummer fehlgeschlagen. Bitte versuche es erneut.',
    invalid_code: 'Ungültiger Code. Bitte versuche es erneut.',
  },
  toasts: {
    code_sent: 'Code gesendet',
  },
};
