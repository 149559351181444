import CollapsibleSection from "./CollapsibleSection";
import FeedbackLabel from "../labels/tinyLabels/FeedbackLabel";
import React, { useEffect } from "react";
import useStore from "../../hooks/global/useStore";
import ReduxActions from "../../store/ReduxActions";
import { actionCreators } from "../../store/actions";
import {useTranslation} from "react-i18next";

interface Props {
  className?: string;
}

export default function PaymentInfoSection(props: Props) {
  const { t } = useTranslation(undefined, {keyPrefix: 'Components:DineIn:PaymentInfoSection'});

  const { className } = props;
  const { paymentInfoSeen } = useStore()?.dineInFlow;

  useEffect(() => {
    ReduxActions.dispatch(actionCreators.dineInFlow.setPaymentInfoSeen(true));
  }, []);

  return (
    <CollapsibleSection
      defaultExpanded={!paymentInfoSeen}
      className={className}
      title={t('title')}
    >
      <FeedbackLabel
        hideIcon
        type="Notice"
        message={t('description')}
        className="px-mini mt-small"
      />
    </CollapsibleSection>
  );
}
