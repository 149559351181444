import useAppMethods from "../../hooks/utility/useAppMethods";

interface Props {
  actualPrice: number;
  originalPrice: number;
  hideDiscount?: boolean;
  actualPriceStyle?: Object;
  originalPriceStyle?: Object;
}

export default function PriceComparison(props: Props): JSX.Element {
  const {
    actualPrice,
    originalPrice,
    hideDiscount,
    actualPriceStyle,
    originalPriceStyle,
  } = props;

  const { formatCurrency } = useAppMethods();

  const isDiscount: boolean = !hideDiscount && actualPrice < originalPrice;

  return (
    <div className={priceContainer}>
      {isDiscount && (
        <h4 className={strikethroughPriceStyle} style={originalPriceStyle}>
          {formatCurrency(originalPrice)}
        </h4>
      )}
      <h4 className={priceStyle(isDiscount)} style={actualPriceStyle}>
        {formatCurrency(actualPrice)}
      </h4>
    </div>
  );
}

const priceContainer = "flex flex-row items-center justify-end inline-flex";

const strikethroughPriceStyle = "text-brand-text-grey line-clamp-1 text-right mini line-through mr-mini";

const priceStyle = (isDiscount?: boolean) =>
  `text-right mini line-clamp-1 ${
    isDiscount ? "text-companyBrand-primary semibold" : "text-brand-text-grey"
  }`;
