import React from "react";
import useTheme from "../../hooks/ui/useTheme";
import { ThemeInterface } from "../../services/exports/Interfaces";

interface Props {
  className?: string;
  style?: Object;
  color?: string;
}

export default function CheckCircleIcon(props: Props) {
  const { className, color, style } = props;
  const theme = useTheme();

  return (
    <svg
      className={className ?? "h-4 w-4"}
      viewBox="0 0 41 40"
      fill="none"
      style={style}
    >
      <path
          d="M20 40C17.1667 40 14.5333 39.4917 12.1 38.475C9.66667 37.4583 7.55 36.05 5.75 34.25C3.95 32.45 2.54167 30.3333 1.525 27.9C0.508333 25.4667 0 22.8333 0 20C0 17.2 0.508333 14.5833 1.525 12.15C2.54167 9.71667 3.95 7.6 5.75 5.8C7.55 4 9.66667 2.58333 12.1 1.55C14.5333 0.516667 17.1667 0 20 0C22.5 0 24.8333 0.4 27 1.2C29.1667 2 31.1167 3.1 32.85 4.5L30.7 6.65C29.2333 5.48333 27.6 4.58333 25.8 3.95C24 3.31667 22.0667 3 20 3C15.1667 3 11.125 4.625 7.875 7.875C4.625 11.125 3 15.1667 3 20C3 24.8333 4.625 28.875 7.875 32.125C11.125 35.375 15.1667 37 20 37C24.8333 37 28.875 35.375 32.125 32.125C35.375 28.875 37 24.8333 37 20C37 19 36.925 18.025 36.775 17.075C36.625 16.125 36.4 15.2 36.1 14.3L38.4 12C38.9333 13.2333 39.3333 14.5167 39.6 15.85C39.8667 17.1833 40 18.5667 40 20C40 22.8333 39.4833 25.4667 38.45 27.9C37.4167 30.3333 36 32.45 34.2 34.25C32.4 36.05 30.2833 37.4583 27.85 38.475C25.4167 39.4917 22.8 40 20 40ZM17.05 29.1L8.8 20.8L11.05 18.55L17.05 24.55L37.75 3.85L40.05 6.1L17.05 29.1Z"
          fill={color ?? theme.color.background.inkGreen.green_2}
      />
    </svg>
  );
}
