import classnames from 'classnames';
import BackIcon from './BackIcon';

interface Props {
  className?: string;
  style?: Object;
  color?: string;
}

export default function ForwardIcon({ className, color, style }: Props) {
  return <BackIcon className={classnames(className, 'rotate-180')} style={style} color={color} />;
}
