import React from "react";
import useTheme from "../../hooks/ui/useTheme";
import { ThemeInterface } from "../../services/exports/Interfaces";

interface Props {
  className?: string;
  style?: Object;
  color?: string;
}

export default function BulbIcon(props: Props) {
  const { className, color, style } = props;
  const theme = useTheme();

  return (
    <svg
      className={className ?? "h-4 w-4"}
      viewBox="0 0 45 44"
      fill="none"
      style={style}
    >
      <path
          d="M41.35 19.65L40.2 17.15L37.7 16L40.2 14.85L41.35 12.35L42.5 14.85L45 16L42.5 17.15L41.35 19.65ZM35 10.9L33.25 7.2L29.55 5.45L33.25 3.7L35 0L36.75 3.7L40.45 5.45L36.75 7.2L35 10.9ZM15 44C13.8667 44 12.9083 43.6083 12.125 42.825C11.3417 42.0417 10.95 41.0833 10.95 39.95H19.05C19.05 41.0833 18.6583 42.0417 17.875 42.825C17.0917 43.6083 16.1333 44 15 44ZM6.9 36.85V33.85H23.1V36.85H6.9ZM7.15 30.8C4.95 29.3667 3.20833 27.575 1.925 25.425C0.641667 23.275 0 20.85 0 18.15C0 14.0833 1.48333 10.5667 4.45 7.6C7.41667 4.63333 10.9333 3.15 15 3.15C19.0667 3.15 22.5833 4.63333 25.55 7.6C28.5167 10.5667 30 14.0833 30 18.15C30 20.85 29.3667 23.275 28.1 25.425C26.8333 27.575 25.0833 29.3667 22.85 30.8H7.15ZM8.25 27.8H21.8C23.4 26.7333 24.6667 25.35 25.6 23.65C26.5333 21.95 27 20.1167 27 18.15C27 14.85 25.825 12.025 23.475 9.675C21.125 7.325 18.3 6.15 15 6.15C11.7 6.15 8.875 7.325 6.525 9.675C4.175 12.025 3 14.85 3 18.15C3 20.1167 3.46667 21.95 4.4 23.65C5.33333 25.35 6.61667 26.7333 8.25 27.8Z"
          fill={color ?? theme.color.text.warning}
      />
    </svg>
  );
}
