import React from "react";
import useTheme from "../../hooks/ui/useTheme";

interface Props {
  description: string;
  value?: string|number;
  type?: "Error" | "Warning" | 'Notice';
}

export default function MinimumValueErrorLabel(props: Props) {
  const { description, value, type } = props;

  const theme = useTheme();

  const getBackgroundColor = () => {
    switch (type) {
      case "Error":
        return theme.color.brand.inkDanger.danger_4;
      case "Warning":
        return theme.color.brand.warning;
      case "Notice":
        return theme.color.brand.notice;
      default:
        return theme.color.brand.inkDanger.danger_4;
    }
  };

  const getTextColor = () => {
    switch (type) {
      case "Error":
        return theme.color.brand.danger;
      case "Warning":
      case 'Notice':
        return theme.color.text.default;
      default:
        return theme.color.brand.danger;
    }
  };

  return (
    <div
      className={container}
      style={{ backgroundColor: getBackgroundColor() }}
    >
      <div className={descriptionContainer}>
        <h6 className={descriptionStyle} style={{ color: getTextColor() }}>
          {description}
        </h6>
      </div>
      {value && (
        <h6 className={valueStyle} style={{color: getTextColor()}}>
          {value}
        </h6>
      )}
    </div>
  );
}

const container =
  "flex flex-row p-mini rounded-lg justify-between bg-brand-inkDanger-danger_4";

const descriptionContainer = "flex flex-1 pr-mini";

const descriptionStyle = "text-brand-danger tiny";

const valueStyle = "text-brand-danger tiny";
