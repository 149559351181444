export default {
  header: 'Bezahlen / Rechnung aufteilen',
  payment_options: {
    pay_full: 'Gesamten Rechnungsbetrag zahlen',
    pay_rest: 'Rest zahlen',
    pay_yours: 'Für meine Artikel zahlen',
    pay_equally: 'Gleichmäßige Aufteilung der Rechnung',
    pay_custom: 'Selber Betrag angeben',
  },
  titles: {
    overcharged: 'Vielen Dank für deine Großzügigkeit!',
    total_bill: 'Gesamtrechnung',
    left_to_pay: 'Restbetrag',
    payment_options: 'Wie möchtest du bezahlen?',
  },
  text: {
    overcharged: 'Du bist dabei, mehr zu zahlen als das, was übrig ist',
    entered_amount: 'Eingegebener Betrag',
  },
  feedback: {
    min_payment_amount: {
      title: 'Der Mindestzahlungsbetrag muss {{amount}} betragen.',
      text: 'Leider beträgt der Mindestbetrag bei Online-Zahlungen {{amount}}. Bitte stelle den Betrag auf mindestens {{amount}} ein.',
    },
    min_amount_after_payment: {
      title: 'Der Restbetrag nach der Zahlung kann nicht weniger als {{amount}} betragen.',
      text: 'Wir können keine Online-Zahlungen unter {{amount}} akzeptieren. Daher kann der verbleibende Restbetrag nach deiner Zahlung nicht unter {{amount}} liegen.',
    },
  },
  buttons: {
    add_as_tip: 'Extra als Trinkgeld hinzufügen',
    pay_only: 'Zahle nur {{amount}}',
    confirm: 'Bestätigen',
  },
};
