import {useCallback, useEffect, useRef} from "react";
import {useSelector} from "react-redux";
import OrderValidationManager from "../../services/api/OrderValidationManager";
import {StoreInterface} from "../../store/types";
import {DeliveryProvider, ORDER_METHODS} from "../../services/exports/Constants";
import ReduxActions from "../../store/ReduxActions";
import {actionCreators} from "../../store/actions";
import moment from "moment";
import useOrderMethodsStatus from "../availability/useOrderMethodsStatus";
import ScheduledOrderSlotsManager from "../../services/api/ScheduledOrderSlotsManager";
import DeliveryQuotesManager from "../../services/api/DeliveryQuotesManager";

export default function useSideEffects() {
  const { company } = useSelector((state: StoreInterface) => state?.initialData);
  const { cached_order } = useSelector((state: StoreInterface) => state.order);
  const { profile } = useSelector((state: StoreInterface) => state.profile);

  const { enabledOrderMethods } = useOrderMethodsStatus();

  /**
   * If cached order method is disabled - reset to the first enabled one
   */
  useEffect(() => {
    ! enabledOrderMethods.includes(cached_order?.method)
    && ReduxActions.dispatch(
      actionCreators.order.updateOrder({
        method: enabledOrderMethods[0] ?? ORDER_METHODS.PICKUP,
      })
    )
  }, [enabledOrderMethods]);

  const resetScheduledTime = useCallback(
    async () => {
      if (company?.has_asap_orders) {
        return ReduxActions.dispatch(
          actionCreators.order.resetScheduledTime()
        );
      }

      const { response } = await ScheduledOrderSlotsManager.getSlots(company?.id, {
        order_method: cached_order?.method,
        date: moment().format('DD-MM-YYYY'),
        limit: 1,
      });

      ReduxActions.dispatch(
        actionCreators.order.resetScheduledTime({
          scheduled_for: response?.data[0]?.start_time ?? null,
        })
      );
    },
    [company?.has_asap_orders, company?.id, cached_order?.method]
  )

  /**
   * Reset order schedule date if
   * - pre order is disabled and cached schedule date is set for other day than now
   * - cached order date is set to the past
   */
  useEffect(() => {
    if (! cached_order?.scheduled_for) {
      return;
    }

    if (
      (!company?.has_pre_order && ! moment(cached_order?.scheduled_for).isSame(moment(), 'day'))
      || moment(cached_order?.scheduled_for).isBefore(moment(), 'day')
    ) {
      resetScheduledTime();
    }
  }, [
    company?.has_pre_order,
  ]);

  /**
   * Clear delivery address when company does not offer delivery
   */
  useEffect(() => {
    if (
      !company?.has_delivery
      || !company?.delivery_enabled
      || (cached_order?.delivery_street_name === null && cached_order?.delivery_zone?.id !== null)
    ) {
      new OrderValidationManager().clearDeliveryAddressRedux({
        order_method: enabledOrderMethods[0] ?? ORDER_METHODS.PICKUP
      });
    }
  }, [
    company?.has_delivery,
    company?.delivery_enabled,
    enabledOrderMethods,
  ]);

  /**
   * Cache order delivery coordinates to local state from profile
   */
  useEffect(() => {
    cached_order?.method === ORDER_METHODS.DELIVERY
    && profile?.delivery_lat
    && profile?.delivery_lng
    && !cached_order?.delivery_lat
    && !cached_order?.delivery_lng
    && ReduxActions.dispatch(
      actionCreators.order.updateOrder({
        delivery_lat: profile?.delivery_lat,
        delivery_lng: profile?.delivery_lng,
      })
    )
  }, []);

  /**
   * Refresh expired delivery quote
   */
  useEffect(() => {
    const refreshDeliveryQuote = async () => {
      return company?.delivery_provider !== DeliveryProvider.Standalone
        && !!cached_order?.delivery_quote?.id
        && !!cached_order?.delivery_quote?.expires_at
        && moment(cached_order?.delivery_quote?.expires_at).isBefore(moment())
        && DeliveryQuotesManager.refresh(
          cached_order.delivery_quote.id,
          { dropoff_time: cached_order?.scheduled_for ?? null },
        );
    }

    refreshDeliveryQuote();
    const interval = setInterval(
      () => refreshDeliveryQuote(),
      30000,
    );

    return () => clearInterval(interval);
  }, [
    cached_order?.delivery_quote?.id,
    cached_order?.delivery_quote?.expires_at,
  ]);
}
