import useDineInFlow from "../../hooks/global/useDineInFlow";
import classnames from "classnames";
import { useMemo } from "react";
import { collect, Collection } from "collect.js";
import SubOrderItem from "./SubOrderItem";
import PaymentsBreakdown from "./PaymentsBreakdown";
import { ORDER_FULFILLMENT_STATUSES } from "../../services/exports/Constants";
import { OrderItemInterface } from "../../services/exports/Interfaces";
import Section from "./Section";
import TableOrdersIllustration from "../../assets/illustrations/TableOrdersIllustration";
import { useTranslation } from "react-i18next";
import UserInitials from "../profile/UserInitials";
import useAppMethods from "../../hooks/utility/useAppMethods";

interface Props {
  className?: string;
}

export default function MasterOrderSection(props: Props) {
  const { t } = useTranslation(undefined, {
    keyPrefix: "Components:DineIn:MasterOrderSection",
  });

  const { className } = props;

  const { formatCurrency } = useAppMethods();
  const { masterOrder, payments, paidAmount, unpaidAmount } = useDineInFlow();

  const groupedSubOrders = useMemo(
    () =>
      collect(masterOrder?.sub_orders)
        .groupBy((item: OrderItemInterface) => item?.user?.id)
        .values()
        .sort(
          (
            a: Collection<OrderItemInterface>,
            b: Collection<OrderItemInterface>
          ) => a.first()?.number - b.first()?.number
        ),
    [masterOrder?.sub_orders]
  );

  function isOrderCanceled(order: OrderItemInterface): boolean {
    return order?.status === ORDER_FULFILLMENT_STATUSES.REJECTED;
  }

  const renderHeader = useMemo(() => {
    if (paidAmount === 0) {
      return (
        <>
          <div className="flex justify-between">
            <h6>{t("titles.all_orders")}</h6>
            <h6>
              {typeof masterOrder?.total === "number" &&
                masterOrder?.sub_orders?.length > 0 && (
                  <h6>{formatCurrency(masterOrder?.total)}</h6>
                )}
            </h6>
          </div>
        </>
      );
    }

    return (
      <>
        <div className="flex justify-between">
          <h6 className="text-mini font-normal">{t("titles.all_orders")}</h6>
          <h6 className="text-mini font-normal">
            {formatCurrency(masterOrder?.total)}
          </h6>
        </div>
        <div className="flex justify-between mt-small">
          <h6>{t("titles.left_to_pay")}</h6>
          <h6 className="underline underline-offset-2">
            {formatCurrency(unpaidAmount)}
          </h6>
        </div>
        <PaymentsBreakdown className="mt-small" />
      </>
    );
  }, [masterOrder, payments, paidAmount, unpaidAmount]);

  const renderItems = useMemo(() => {
    return masterOrder?.sub_orders?.length > 0 ? (
      <div className="border-t border-brand-inkGrey-grey_1 pt-small">
        {groupedSubOrders.map(
          (subOrders: Collection<OrderItemInterface>, index: number) => {
            const user = subOrders?.first()?.user;

            return subOrders?.count() > 0 ? (
              <div className={classnames({ "mt-small": index > 0 })}>
                <div className="flex items-center">
                  <UserInitials
                    name={user?.first_name}
                    className="mr-mini h-6 aspect-square rounded-full bg-brand-inkGrey-grey_2 flex justify-center items-center"
                    textClassName="tiny semibold"
                  />
                  <p className="mini text-brand-text-grey truncate">
                    {user?.first_name}
                  </p>
                </div>
                {subOrders?.toArray().map((item: OrderItemInterface) => {
                  return (
                    <SubOrderItem
                      data={item}
                      key={`line-item-${item?.id}`}
                      className="mt-tiny"
                      isCanceled={isOrderCanceled(item)}
                    />
                  );
                })}
              </div>
            ) : null;
          }
        )}
      </div>
    ) : (
      <div>
        <div className="py-small w-full flex flex-col items-center">
          <TableOrdersIllustration className="w-4/12" />
        </div>

        <p className="text-center text-brand-text-grey mini">
          {t("titles.no_orders")}
        </p>
      </div>
    );
  }, [groupedSubOrders]);

  return (
    <Section className={className} title={renderHeader}>
      {renderItems}
    </Section>
  );
}
