import { Server } from "miragejs";
import { ApiResponseInterface } from "../exports/Interfaces";
import { AxiosRequestConfig } from "axios";
import AuthorizationTokenStorage from "../auth/AuthorizationTokenStorage";
import { Response } from "../exports/Types";

export default class ApiMockUtility {
  private api_base: string = "api";
  private config: AxiosRequestConfig;

  constructor(config: AxiosRequestConfig) {
    this.config = config;
    new Server({
      routes() {
        this.namespace = "api";
        this.get(`${config.url}`, () => {
          return config.params.response;
        });
      },
    });
  }

  public request = async (): Promise<Response<ApiResponseInterface>> => {
    return fetch(`/${this.api_base}${this.config.url}`)
      .then((response) => {
        if (!response.ok || this.config.params.status >= 400) throw Error();
        return response.json();
      })
      .then((json) => {
        return {
          response: json,
          success: true,
          status: 200,
        };
      })
      .catch((error) => {
        return {
          response: error,
          success: false,
          status: 400,
        };
      });
  };

  public requestAuthorized = async (): Promise<
    Response<ApiResponseInterface>
  > => {
    return fetch(`/${this.api_base}${this.config.url}`)
      .then((response) => {
        if (
          !response.ok ||
          this.config.params.status >= 400 ||
          !AuthorizationTokenStorage.getToken()
        )
          throw Error();
        return response.json();
      })
      .then((json) => {
        return {
          response: json,
          success: true,
          status: 200,
        };
      })
      .catch((error) => {
        return {
          response: error,
          success: false,
          status: 400,
        };
      });
  };
}
