import { useState } from "react";

export default function useImagePreloader() {
  const [imagesPreloaded, setImagesPreloaded] = useState<boolean>(false);

  function preloadImage(src: string) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = function () {
        resolve(img);
      };
      img.onerror = img.onabort = function () {
        reject(src);
      };
      img.src = src;
    });
  }

  async function preloadImages(imageList: string[]): Promise<boolean> {
    const imagesPromiseList: Promise<any>[] = [];

    setImagesPreloaded(false);

    for (const i of imageList) {
      imagesPromiseList.push(preloadImage(i));
    }

    await Promise.all(imagesPromiseList);

    setImagesPreloaded(true);

    return true;
  }

  return {
    imagesPreloaded,
    preloadImage,
    preloadImages,
  };
}
