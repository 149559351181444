import useCashbackProgram from "../../hooks/cashback/useCashbackProgram";
import {useTranslation} from "react-i18next";
import React, {useMemo} from "react";
import classnames from "classnames";

interface Props {
  amount: number;
  className?: string;
}

export default function EarnCashbackTag(props: Props) {
  const { amount, className } = props;

  const { t } = useTranslation(null, { keyPrefix: 'Components:Cashback:EarnCashbackTag' });

  const { hasCashbackProgram, calculateExpectedCashback } = useCashbackProgram();

  const value = useMemo(
    () => calculateExpectedCashback(amount),
    [amount]
  );

  return hasCashbackProgram && value > 0 && (
    <div className={classnames('bg-companyBrand-primary/20 w-full py-mini px-small rounded-[8px] flex justify-center items-center relative', className)}>
      <p className="mini semibold text-center text-companyBrand-primary mx-mini uppercase">
        {t('text', { count: value })}
      </p>
    </div>
  );
}