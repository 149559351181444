export default {
  header: {
    main: "Bestellung bestätigen",
    order_summary: "Bestellübersicht",
  },
  labels: {
    total: "Gesamt",
  },
  buttons: {
    add_products: "+ Artikel Hinzufügen",
    place_order: "Jetzt Bestellen",
  },
  empty_order_basket: {
    title: "Los geht's",
    description: "Artikel hinzufügen, um loszulegen",
  },
  loading_overlay:
    "Bitte warte einen Moment... wir senden deine Bestellung an die Küche.",
};
