import {
  LocationInterface,
} from "../exports/Interfaces";
import BackendApiClient from "./BackendApiClient";
import {
  DataWrappedResponse,
} from "../exports/Types";

class CurrentLocationManager {
  public get = async (): Promise<DataWrappedResponse<LocationInterface>> => BackendApiClient.request({
    method: 'GET',
    url: `/current-location`,
  });
}

export default new CurrentLocationManager();
