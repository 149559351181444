import useTheme from "../../hooks/ui/useTheme";
import CustomButton from "./CustomButton";
import useOrderingDisabledStatus from "../../hooks/availability/useOrderingDisabledStatus";
import useDineInFlow from "../../hooks/global/useDineInFlow";
import { useTranslation } from "react-i18next";
import useBasket from "../../hooks/basket/useBasket";
import useAppMethods from "../../hooks/utility/useAppMethods";
import { SCREENS } from "../../services/exports/Constants";
import useScreenType from "../../hooks/utility/useScreenType";
import useNavigate from "../../hooks/navigation/useNavigate";
import useOrderMethodsStatus from "../../hooks/availability/useOrderMethodsStatus";
import classnames from "classnames";
import { ButtonTypes } from "../../services/exports/Interfaces";
import LoadingIndicator from "../loading/LoadingIndicator";
import OrderBasketCashback from "../cashback/OrderBasketCashback";

interface Props {
  title?: string;
  loading?: boolean;
  disabled?: boolean;
  hideAddProductsButton?: boolean;
  onClick?: () => void;
}

export default function CheckoutButton(props: Props) {
  const { t } = useTranslation(null, { keyPrefix: 'Components:Buttons:CheckoutButton' });

  const { title, loading, disabled, hideAddProductsButton, onClick } = props;

  const theme = useTheme();
  const { isMobile } = useScreenType();
  const { navigate } = useNavigate();
  const { dineInCurrentlyOpen, takeoutCurrentlyOpen, takeoutOpenForFutureOrders } = useOrderMethodsStatus();
  const { formatCurrency, toggleOrdersDisabledModal, toggleOrderMethodModal } = useAppMethods();
  const { render: renderOrderingDisabled } = useOrderingDisabledStatus();

  const { isDineIn } = useDineInFlow();
  const { quantity, subtotal, total } = useBasket();

  function _onClick() {
    if (loading || disabled) {
      return;
    }

    if (isDineIn && !dineInCurrentlyOpen) {
      return toggleOrdersDisabledModal({ openModal: true });
    }

    return onClick();
  }

  if (renderOrderingDisabled) {
    return renderOrderingDisabled;
  }

  const renderEmptyBasketButton = (): JSX.Element => {
    if (quantity !== 0) {
      return null;
    }

    if (isMobile && !hideAddProductsButton) {
      return (
        <CustomButton
          title={t('add_products')}
          buttonType={ButtonTypes.third}
          onClick={() =>
            navigate(isDineIn ? SCREENS.MENU : SCREENS.HOME_SCREEN)
          }
        />
      );
    }

    if (!takeoutCurrentlyOpen && takeoutOpenForFutureOrders) {
      return (
        <CustomButton
          title={t('order_ahead')}
          onClick={() => toggleOrderMethodModal({ openModal: true })}
        />
      )
    }
  }

  const renderMainButton = () => quantity > 0 && (
    <>
      <OrderBasketCashback
        subtotal={subtotal}
        total={total}
        className="mb-xsmall"
      />
      <div className={classnames(buttonStyle, {
        'justify-between': !loading,
        'justify-center': loading,
        'bg-companyBrand-primary': !disabled,
        'bg-background-inkDisabled-disabled_3': disabled,
      })} onClick={_onClick}>
        {loading && <LoadingIndicator customColor={theme.color.white} lg={20} md={20} sm={20} />}

        {!loading && (
          <>
            <span>{title}</span>
            <div>
              {subtotal !== total && (
                <span className="line-through mr-mini">{formatCurrency(subtotal)}</span>
              )}
              <span>{formatCurrency(total)}</span>
            </div>
          </>
        )}
      </div>
    </>
  );

  const content = renderEmptyBasketButton() ?? renderMainButton();

  return (
    <div className={container(isDineIn, !!content)}>
      {content}
    </div>
  );
}

const container = (isDineIn: boolean, withContent: boolean) => classnames(
  'z-50', {
    'bg-background-inkWhite-white_0': isDineIn,
    'bg-background-inkWhite-white_1': !isDineIn,
    'border-t border-brand-inkGray-grey_2 pl-small pr-small py-mini': withContent
  }
);

const buttonStyle = 'flex flex-row w-full rounded py-xsmall px-xxsmall font-semibold text-mini text-white cursor-pointer';
