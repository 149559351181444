import {
  COUPON_TYPES,
  PaymentMethod,
  RoomServiceLocationType
} from "../../../../services/exports/Constants";

export default {
  title: 'Bestellung aufgegeben - Nr. {{number}}!',
  subtitle: 'Halte dein Handy bei dir, wenn der Fahrer ankommt (Tracking-Link unten)',
  header: {
    order_canceled: "Bestellung storniert",
    track_order: "Deine Bestellung",
    order_info: "Bestellinformationen & Updates werden an deine E-Mail gesendet {{email}}",
  },
  order_type: {
    pickup: "Abholzeit",
    delivery: "Lieferzeit",
  },
  labels: {
    today: 'Heute',
  },
  order_status_toast: {
    order_reviewed: "#{{number}} Deine Bestellung wurde gesehen",
    order_confirmed: "#{{number}} Bestellung bestätigt {{time}}",
    order_preparation_up_time_changed:
      "#{{number}} {{type}} geändert auf {{time}}",
    order_preparing: "Deine Bestellung #{{number}} wird zubereitet",
    order_rejected: "#{{number}} Bestellung storniert",
  },
  room_service_instructions: {
    title: 'Anweisungen',
    location_types: {
      [RoomServiceLocationType.RoomService]: 'Zimmerservice, Nummer {{details}}',
      [RoomServiceLocationType.TableService]: 'Vor Ort, Nummer {{details}}',
    },
    location_types_without_number: {
      [RoomServiceLocationType.RoomService]: 'Zimmerservice',
      [RoomServiceLocationType.TableService]: 'Vor Ort',
    },
  },
  pickup_instructions: {
    title: "Abholinfo",
    description:
      "🗣 Nenne uns deine Bestellnummer, wenn du deine Bestellung abholst.",
  },
  delivery_instructions: {
    title: "Lieferinformation",
    address: "Deine Addresse",
    note: "Bemerkung: ",
  },
  rewards: {
    title: "Deine Prämien",
  },
  order_details: {
    title: "Bestell Details",
  },
  payment_breakdown: {
    free: "Gratis",
  },
  price_details: {
    title: "Preis Details",
    original: "Zwischensumme",
    tip: "Trinkgeld",
    total: "Gesamt",
    delivery_fee: "Liefergebühr",
    payment_method_upcharge_fee: {
      [PaymentMethod.Paypal]: 'PayPal Gebühr',
    },
    service_fee: "Onlinezuschlag",
    tax: 'Taxes',
    discount: {
      [COUPON_TYPES.REWARD]: 'Punkte angewandt',
      [COUPON_TYPES.CASHBACK]: 'Stempelkarte',
      [COUPON_TYPES.PROMO_CODE]: 'Promo angewandt',
    }
  },
  review_form: {
    title: "Wie gefällt dir {{name}}?",
    description: "Dein Feedback ist anonym und hilft uns besser zu werden.",
  },
  upload_google_review: {
    title: "Wir belohnen dich",
  },
  toast_messages: {
    share_experience:
      "Wie findest du unser Lokal? Bitte teile es mit uns unten 😊",
  },
  error_messages: {
    failed_to_load:
      "Bestellung konnte nicht geladen werden. Bitte versuche es erneut.",
  },
};
