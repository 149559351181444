import React from 'react';
import {OpeningHoursInterface} from "../../services/exports/Interfaces";
import HelperMethods from "../../services/exports/HelperMethods";
import classnames from "classnames";
import useAppMethods from "../../hooks/utility/useAppMethods";

interface Props {
    day: number;
    hours: OpeningHoursInterface[];
    classNames?: string
}

export default function WorkScheduleUnit(props: Props) {
    const { day, hours, classNames } = props;

    const { formatScheduleUnit } = useAppMethods();

    return (
        <div className={classnames('w-full py-tiny flex justify-between', classNames)}>
            <h6 className="font-normal text-mini">{HelperMethods.getDay(day)}</h6>
            <div className="flex flex-col">
                {hours.map((item, index) => (
                    <h6
                        className="font-normal text-mini"
                        key={`schedule-unit-${day}-${index}`}
                    >
                      {formatScheduleUnit(item?.open)}
                      {' - '}
                      {formatScheduleUnit(item?.close)}
                    </h6>
                ))}
            </div>
        </div>
    );
}