import React from "react";
import classnames from "classnames";

interface Props {
  className?: string;
}

export default function LocationCircleIcon(props: Props) {
  const { className } = props;

  return (
    <svg
      className={classnames('h-4 w-5', className)}
      viewBox="0 0 36 42"
      fill="none"
    >
      <circle cx="18" cy="18" r="18" fill="#161616"/>
      <rect x="16" y="36" width="4" height="6" fill="#161616" />
      <path fillRule="evenodd"
            clipRule="evenodd"
            d="M16.3609 12.3201C13.4796 13.367 12.1382 16.9268 13.5903 19.6715C13.795 20.0582 14.9146 21.3109 16.0782 22.4552L18.1939 24.5356L20.4882 22.1706C23.1331 19.4441 23.7032 18.1154 23.1387 15.9931C22.3196 12.9135 19.2721 11.2621 16.3609 12.3201ZM18.985 16.4779C19.7221 17.3659 18.6397 18.7298 17.6785 18.1245C17.4117 17.9564 17.1515 17.6077 17.1007 17.3498C16.8941 16.299 18.2977 15.6496 18.985 16.4779Z"
            fill="#F2F2F2"
      />
    </svg>
  );
}
