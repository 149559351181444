import { actionCreators } from "../../store/actions";
import ReduxActions from "../../store/ReduxActions";
import BackendApiClient from "../api/BackendApiClient";
import {
  PostOptDataInterface,
  PostLoginDataInterface,
  ProfileInterface,
  LoginResponseInterface, OneTimePasswordInterface,
} from "../exports/Interfaces";
import AuthorizationTokenStorage from "./AuthorizationTokenStorage";
import {DataWrappedResponse, DataWrapper, Response} from "../exports/Types";
import { AxiosRequestConfig } from "axios";

class AuthorizationManager {
  public postOptCode = async (
    data: PostOptDataInterface
  ): Promise<DataWrappedResponse<DataWrapper<OneTimePasswordInterface>>> =>
    BackendApiClient.request({
      method: 'POST',
      url: '/one-time-passwords',
      data,
    });

  public postRegister = async (
      data: PostLoginDataInterface
  ): Promise<Response<LoginResponseInterface>> => {
    const request = await BackendApiClient.request({
      method: 'POST',
      url: '/register',
      data,
    });

    const { response, success } = request;

    if (success) {
      AuthorizationTokenStorage.saveToken(response?.data?.auth_token);

      this.saveProfileRedux({
        ...response?.data?.user,
        phone_number: data?.phone_number,
      });
    }

    return request;
  };

  public postLogin = async (
    data: PostLoginDataInterface,
    config?: AxiosRequestConfig
  ): Promise<Response<LoginResponseInterface>> => {
    const request = await BackendApiClient.request({
      method: "POST",
      url: "/login",
      data,
      ...config,
    });
    const { response, success } = request;

    if (success) {
      AuthorizationTokenStorage.saveToken(response?.data?.auth_token);

      this.saveProfileRedux({
        ...response?.data?.user,
        phone_number: data?.phone_number,
      });
    }

    return request;
  };

  public logout = async (): Promise<boolean> => {
    if (!AuthorizationTokenStorage.getToken()) {
      return true;
    }

    return new Promise((resolve) => {
      AuthorizationTokenStorage.deleteToken();
      ReduxActions.batchActions([
        actionCreators.order.resetOrder(this.getProfile()),
        actionCreators.profile.setProfile(null),
      ]);
      resolve(true);
    })
    .then(() => true)
    .catch(() => false);
  };

  private saveProfileRedux(response?: ProfileInterface) {
    ReduxActions.dispatch(actionCreators.profile.setProfile(response));
  }

  private getProfile(): ProfileInterface {
    return ReduxActions.getStore()?.profile?.profile;
  }
}

export default new AuthorizationManager();
