import LocationIcon from "../../assets/logo/LocationIcon";
import Divider from "../Divider";
import classnames from "classnames";
import useTheme from "../../hooks/ui/useTheme";
import AddressResource from "../../services/resources/AddressResource";
import CustomLabel from "../labels/tinyLabels/CustomLabel";
import { useTranslation } from "react-i18next";
import CustomButton from "../buttons/CustomButton";
import { useMemo, useState } from "react";
import CompanyDetailsModal from "../../modals/CompanyDetailsModal";
import InfoIcon from "../../assets/logo/InfoIcon";
import { CompanyDistanceInterface } from "../../pages/HomeScreen";
import useOpeningStatus from "../../hooks/availability/useOpeningStatus";
import HelperMethods from "../../services/exports/HelperMethods";
import useAppMethods from "../../hooks/utility/useAppMethods";
import useNavigate from "../../hooks/navigation/useNavigate";
import {ORDER_METHODS} from "../../services/exports/Constants";
import useOrderMethodsStatus from "../../hooks/availability/useOrderMethodsStatus";

interface Props {
  data: CompanyDistanceInterface;
  className?: string;
}

export default function CompanyCard(props: Props) {
  const { t } = useTranslation(null, { keyPrefix: 'Components:Franchise:CompanyCard' });

  const { data, className } = props;
  const { company, distance } = data;

  const { navigate } = useNavigate();
  const theme = useTheme();
  const { saveMenu } = useAppMethods();
  const { isOrderMethodCurrentlyOpen, isOrderMethodOpenForFutureOrders } = useOrderMethodsStatus(company);
  const { is_open, details_text } = useOpeningStatus({ data: company });

  const [showInfoModal, setShowInfoModal] = useState(false);

  const toggleInfoModal = () => setShowInfoModal((prev) => !prev);

  const renderStatus = () => {
    return (
      <h6
        className={classnames("text-mini", {
          "text-brand-primary": is_open,
          "text-brand-danger": !is_open,
        })}
      >
        {details_text}
      </h6>
    );
  };

  function renderAvailability() {
    const getTextColor = (isAvailable: boolean) => isAvailable ? theme.color.brand.success : theme.color.brand.inkGrey.grey_3;
    const getBackgroundColor = (isAvailable: boolean) => isAvailable ? theme.color.brand.inkPrimary.primary_3 : theme.color.background.inkDisabled.disabled_3;
    const getLabel = (isAvailable: boolean) => isAvailable ? t('labels.available') : t('labels.unavailable');

    const pickupAvailable = isOrderMethodCurrentlyOpen(ORDER_METHODS.PICKUP) || isOrderMethodOpenForFutureOrders(ORDER_METHODS.PICKUP);
    const deliveryAvailable = isOrderMethodCurrentlyOpen(ORDER_METHODS.DELIVERY) || isOrderMethodOpenForFutureOrders(ORDER_METHODS.DELIVERY);

    return (
      <div className="mt-small flex lg:flex-row flex-col">
        <div className="flex flex-row items-center mr-small">
          <p className="mr-tiny text-mini text-brand-inkGrey-grey_4">
            {t('pickup', {
              time: HelperMethods.formatTimeInterval(company?.average_order_preparation_time),
            })}
          </p>
          <CustomLabel
            label={getLabel(pickupAvailable)}
            textColor={getTextColor(pickupAvailable)}
            backgroundColor={getBackgroundColor(pickupAvailable)}
          />
        </div>
        {company?.has_delivery && company?.delivery_enabled && (
          <div className="flex flex-row items-center mr-small lg:mt-none mt-xsmall">
            <p className="mr-tiny text-mini text-brand-inkGrey-grey_4">
              {t('delivery', {
                time: HelperMethods.formatTimeInterval(company?.average_order_delivery_time),
              })}
            </p>
            <CustomLabel
              label={getLabel(deliveryAvailable)}
              textColor={getTextColor(deliveryAvailable)}
              backgroundColor={getBackgroundColor(deliveryAvailable)}
            />
          </div>
        )}
      </div>
    );
  }

  const renderCompanyInfoModal = useMemo(
    () =>
      showInfoModal && (
        <CompanyDetailsModal
          data={company}
          openModal={showInfoModal}
          toggleModal={toggleInfoModal}
        />
      ),
    [company, showInfoModal]
  );

  return (
    <div className={classnames("w-full shadow rounded-lg p-small", className)}>
      <div className="flex justify-between">
        <h6 className="my-auto">{company.name}</h6>
        <div onClick={toggleInfoModal} className="h-8 w-8 cursor-pointer">
          <InfoIcon
            className="h-5 w-5 my-auto"
            color={theme.color.companyBrand.primary}
            fill
          />
        </div>
      </div>
      {distance ? (
        <p className="my-mini text-tiny text-brand-inkGrey-grey_4">
          {t("distance", { distance: HelperMethods.formatDistance(distance) })}
        </p>
      ) : null}
      {company.location_name ? (
        <h6 className="text-mini text-brand-inkGrey-grey_4">{company.location_name}</h6>
      ) : null}
      <div className="flex mt-mini">
        <LocationIcon
          className="h-4 w-4 my-auto mr-tiny"
          color={theme.color.brand.inkGrey.grey_4}
        />
        <p className="text-mini text-brand-inkGrey-grey_4">
          {AddressResource.compileAddress(company)}
        </p>
      </div>
      <Divider size="small" className="my-xsmall" />
      <div className="flex mt-mini">{renderStatus()}</div>
      {renderAvailability()}
      <CustomButton
        className="mt-small"
        titleClass="text-mini"
        title={t("buttons.order_here")}
        onClick={() => {
          new Promise((resolve) => {
            saveMenu({ menuLoaded: false });
            resolve(true);
          }).then(() => navigate(company.slug));
        }}
      />
      {renderCompanyInfoModal}
    </div>
  );
}
