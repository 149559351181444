import CheckmarkIcon from "../../assets/logo/CheckmarkIcon";
import CloseIcon from "../../assets/logo/CloseIcon";
import useTheme from "../../hooks/ui/useTheme";
import { ButtonSizes, ButtonTypes, ThemeInterface } from "../../services/exports/Interfaces";
import CustomButton from "../buttons/CustomButton";
import classnames from "classnames";

interface Props {
  placeholder: string;
  value: string;
  id?: string;
  type?: "text" | "number" | "email" | "uppercase";
  isComplete?: boolean;
  containerStyle?: Object;
  loading?: boolean;
  buttonTitle?: string;
  buttonType?: ButtonTypes;
  disabled?: boolean;
  inputContainerStyle?: string;
  inputStyle?: string;
  buttonContainerStyle?: string;
  buttonFontStyle?: string;
  className?: string;
  testId?: string;
  onChange: (value: string) => void;
  onClick?: () => void;
}

export default function CheckmarkTextInput(props: Props) {
  const {
    id,
    type,
    value,
    placeholder,
    isComplete,
    containerStyle,
    loading,
    buttonType,
    disabled,
    buttonTitle,
    inputContainerStyle,
    inputStyle,
    buttonContainerStyle,
    buttonFontStyle,
    className,
    testId,
    onChange,
    onClick,
  } = props;
  const theme: ThemeInterface = useTheme();

  function renderButton() {
    return onClick ? (
      <div className={buttonContainer}>
        <CustomButton
          title={buttonTitle}
          buttonSize={ButtonSizes.small}
          onClick={onClick}
          loading={loading}
          buttonType={buttonType}
          disabled={
            isComplete === undefined
              ? value?.length === 0
              : !isComplete || disabled
          }
          containerStyle={buttonContainerStyle}
          fontStyle={buttonFontStyle}
        />
      </div>
    ) : null;
  }

  function renderRemoveIcon() {
    return (
      <div className={removeIconContainer} onClick={() => onChange("")}>
        <CloseIcon className={iconStyle} color={theme.color.text.disabled} />
      </div>
    );
  }

  function renderCheckmarkIcon() {
    return (
      <div className={checkmarkIconContainer}>
        <CheckmarkIcon className={iconStyle} color={theme.color.text.light} />
      </div>
    );
  }

  function renderInput() {
    function renderActionIcons() {
      if (value?.length === 0 || disabled) {
        return null;
      }

      if (isComplete) {
        return renderCheckmarkIcon();
      }

      return renderRemoveIcon();
    }

    function changeValue(e: any) {
      switch (type) {
        case "number":
          const isNum: Boolean =
            /^\d+$/.test(e.target.value) || e.target.value === "";
          return isNum ? onChange && onChange(e.target.value) : undefined;
        case "uppercase":
          return onChange && onChange(e.target.value.toUpperCase());
        default:
          return onChange && onChange(e.target.value);
      }
    }

    function getType(): string {
      switch (type) {
        case 'email': return 'email';
        default: return 'text';
      }
    }

    return (
      <div
        className={classnames(inputContainer, inputContainerStyle ?? "")}
        style={containerStyle}
      >
        <input
          data-test={testId}
          id={id ?? "checkmarkInput"}
          type={getType()}
          value={value}
          placeholder={placeholder}
          onChange={changeValue}
          className={classnames(textInputStyle(disabled), inputStyle)}
          disabled={disabled}
          autoComplete={"off"}
          autoCorrect={"off"}
        />
        {!disabled && <div className="mx-2">{renderActionIcons()}</div>}
      </div>
    );
  }

  return (
    <div className={classnames(container, className)}>
      {renderInput()}
      {renderButton()}
    </div>
  );
}

const container = "flex flex-row justify-between items-center";

const inputContainer =
  "placeholder:text-grey text-brand-text-default rounded-lg flex flex-row p-xsmall items-center w-full border rounded border-brand-inkGrey-grey_2";

const textInputStyle = (disabled?: boolean) =>
  `w-full ${
    disabled
      ? "text-brand-inkGrey-grey_3 pointer-events-none"
      : "text-brand-text-default"
  }  `;

const buttonContainer = "ml-xsmall";

const removeIconContainer =
  "bg-brand-text-grey rounded-full flex justify-center items-center w-5 h-5 cursor-pointer";

const checkmarkIconContainer =
  "bg-brand-primary rounded-full flex justify-center items-center w-5 h-5";

const iconStyle = "h-2 w-2";
