import classnames from "classnames";
import collect from "collect.js";

interface Item<T> {
  label: string;
  value: T;
}

interface Props {
  items: Item<any>[];
  value: any;
  onClick: (value: any) => void;
}

const TabBar = (props: Props): JSX.Element => {
  const { items, value, onClick } = props;

  const _items = collect(items).whereNotNull('value').toArray();

  return (
    <div
      className="grid grid-cols-2 rounded-md bg-brand-inkGrey-grey_1 p-tiny border"
      style={{
        gridTemplateColumns: `repeat(${_items?.length}, minmax(0, 1fr))`,
      }}
    >
      {_items.map((item: Item<any>, index: number) => (
        <div
          className={classnames(
            "px-small py-xsmall justify-center items-center flex flex-col rounded-md cursor-pointer z-10",
            {
              "bg-background-inkWhite-white_0 shadow": value === item.value,
            }
          )}
          onClick={() => onClick(item.value)}
          key={`tab-${item.label}-${index}`}
        >
          <h6
            className={classnames('text-brand-text-default', {
              'text-brand-text-grey': value !== item.value,
            })}
          >
            {item.label}
          </h6>
        </div>
      ))}
    </div>
  );
};

export default TabBar;
