import {
  CreateDeliveryQuotesParams,
  DeliveryQuote,
  ErrorHandlerInterface,
} from "../exports/Interfaces";
import BackendApiClient from "./BackendApiClient";
import {
  DataWrapper,
  DataWrappedResponse,
} from "../exports/Types";
import { actionCreators } from "../../store/actions";
import ReduxActions from "../../store/ReduxActions";

class DeliveryQuotesManager {
  public create = async (
    companyId: number,
    data: CreateDeliveryQuotesParams,
    errorHandlers?: ErrorHandlerInterface,
  ): Promise<DataWrappedResponse<DataWrapper<DeliveryQuote>>> => {
    const response = await BackendApiClient.request({
      method: 'POST',
      url: `/companies/${companyId}/delivery-quotes`,
      data,
    }, false, errorHandlers);

    if (! response?.success) {
      return response;
    }

    this.saveDataRedux(response.response.data.data);

    return response;
  }

  public refresh = async (
    id: number,
    data: Partial<CreateDeliveryQuotesParams>,
    errorHandlers?: ErrorHandlerInterface,
  ): Promise<DataWrappedResponse<DataWrapper<DeliveryQuote>>> => {
    const response = await BackendApiClient.request({
      method: 'PUT',
      url: `/delivery-quotes/${id}/refresh`,
      data,
    }, false, errorHandlers);

    if (! response?.success) {
      this.saveDataRedux(null);

      return response;
    }

    this.saveDataRedux(response.response.data.data);

    return response;
  }

  private saveDataRedux = (data: null|DeliveryQuote) => {
    ReduxActions.dispatch(
      actionCreators.order.updateOrder({
        delivery_quote: data
      })
    )
  }
}

export default new DeliveryQuotesManager();
