interface Props {
  className?: string;
  style?: Object;
  color?: string;
  standard?: boolean;
}

export default function PayPalIcon(props: Props) {
  const { className, style } = props;

  return (
    <svg
      className={className ?? "h-4 w-4"}
      viewBox="0 0 20 18"
      fill="none"
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.40001 12.2383H0.844392C0.737939 12.2383 0.647402 12.3156 0.630802 12.4207L0.00163188 16.4098C-0.0108734 16.4885 0.0500852 16.5595 0.129927 16.5595H0.872593C0.979045 16.5595 1.06958 16.4821 1.08618 16.3768L1.25588 15.3009C1.27225 15.1956 1.363 15.1183 1.46924 15.1183H1.96169C2.98643 15.1183 3.5778 14.6224 3.73232 13.6398C3.8019 13.2099 3.73527 12.8721 3.5339 12.6354C3.31285 12.3758 2.92064 12.2383 2.40001 12.2383ZM2.57948 13.6952C2.49443 14.2534 2.06792 14.2534 1.65553 14.2534H1.42078L1.58547 13.2109C1.59525 13.148 1.64984 13.1015 1.71353 13.1015H1.82112C2.10204 13.1015 2.36706 13.1015 2.50401 13.2617C2.58559 13.3572 2.61064 13.4991 2.57948 13.6952ZM7.05012 13.6773H6.30517C6.2417 13.6773 6.18686 13.7238 6.17707 13.7868L6.14412 13.9951L6.09201 13.9197C5.93075 13.6855 5.57117 13.6073 5.21222 13.6073C4.38896 13.6073 3.6859 14.2308 3.54896 15.1054C3.4778 15.5417 3.57896 15.9588 3.82643 16.2498C4.05348 16.5172 4.37833 16.6287 4.76475 16.6287C5.42801 16.6287 5.79591 16.2022 5.79591 16.2022L5.76265 16.4092C5.75012 16.4884 5.81107 16.5593 5.89043 16.5593H6.56149C6.66823 16.5593 6.75823 16.482 6.77507 16.3767L7.1777 13.827C7.19044 13.7486 7.1297 13.6773 7.05012 13.6773ZM6.0117 15.1272C5.9398 15.5528 5.60201 15.8385 5.17128 15.8385C4.95496 15.8385 4.78201 15.7691 4.67106 15.6377C4.56096 15.5071 4.51906 15.3212 4.55412 15.1143C4.62127 14.6923 4.96475 14.3973 5.38896 14.3973C5.60043 14.3973 5.77244 14.4675 5.8857 14.6002C5.99917 14.7342 6.04422 14.9211 6.0117 15.1272ZM10.269 13.6772H11.0176C11.1224 13.6772 11.1836 13.7948 11.124 13.8808L8.63423 17.4747C8.59391 17.5329 8.5275 17.5676 8.45655 17.5676H7.70886C7.6036 17.5676 7.54212 17.449 7.60307 17.3628L8.37834 16.2685L7.55381 13.8487C7.52528 13.7646 7.58739 13.6772 7.67686 13.6772H8.41244C8.50802 13.6772 8.59234 13.74 8.61992 13.8314L9.0575 15.2929L10.09 13.7721C10.1304 13.7127 10.1976 13.6772 10.269 13.6772Z"
        fill="#253B80"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3851 16.41L19.0236 12.348C19.0334 12.2849 19.088 12.2385 19.1515 12.2383H19.8702C19.9496 12.2383 20.0105 12.3094 19.998 12.3882L19.3684 16.377C19.352 16.4823 19.2615 16.5597 19.1548 16.5597H18.5129C18.4336 16.5597 18.3726 16.4887 18.3851 16.41ZM13.4958 12.2385H11.9399C11.8337 12.2385 11.7431 12.3159 11.7265 12.4209L11.0973 16.41C11.0848 16.4887 11.1458 16.5597 11.2252 16.5597H12.0236C12.0978 16.5597 12.1612 16.5056 12.1728 16.4319L12.3513 15.3011C12.3678 15.1958 12.4585 15.1185 12.5647 15.1185H13.0569C14.0819 15.1185 14.6731 14.6226 14.8278 13.64C14.8976 13.2101 14.8305 12.8723 14.6292 12.6357C14.4083 12.376 14.0164 12.2385 13.4958 12.2385ZM13.6752 13.6954C13.5904 14.2537 13.1639 14.2537 12.7512 14.2537H12.5167L12.6817 13.2111C12.6914 13.1482 12.7456 13.1018 12.8094 13.1018H12.917C13.1978 13.1018 13.463 13.1018 13.5999 13.2619C13.6816 13.3574 13.7064 13.4993 13.6752 13.6954ZM18.1455 13.6775H17.401C17.337 13.6775 17.2827 13.724 17.2731 13.787L17.2402 13.9953L17.1879 13.9199C17.0266 13.6858 16.6673 13.6075 16.3083 13.6075C15.485 13.6075 14.7822 14.231 14.6452 15.1057C14.5743 15.5419 14.675 15.959 14.9225 16.25C15.15 16.5174 15.4744 16.6289 15.8608 16.6289C16.5241 16.6289 16.8919 16.2024 16.8919 16.2024L16.8587 16.4095C16.8462 16.4886 16.9071 16.5596 16.987 16.5596H17.6578C17.764 16.5596 17.8545 16.4822 17.8711 16.3769L18.274 13.8272C18.2863 13.7488 18.2254 13.6775 18.1455 13.6775ZM17.1071 15.1274C17.0357 15.553 16.6975 15.8387 16.2666 15.8387C16.0507 15.8387 15.8775 15.7693 15.7664 15.6379C15.6563 15.5073 15.6149 15.3214 15.6496 15.1145C15.717 14.6925 16.0601 14.3975 16.4843 14.3975C16.6959 14.3975 16.8678 14.4678 16.9811 14.6004C17.095 14.7344 17.1401 14.9213 17.1071 15.1274Z"
        fill="#179BD7"
      />
      <path
        d="M8.15665 10.8138L8.34791 9.59907L7.92191 9.58918H5.8877L7.30138 0.625344C7.3057 0.598281 7.32001 0.573049 7.34086 0.555133C7.3617 0.537218 7.38844 0.527344 7.41622 0.527344H10.8462C11.985 0.527344 12.7708 0.764302 13.1811 1.232C13.3734 1.45141 13.4959 1.68068 13.5552 1.933C13.6173 2.19776 13.6184 2.51406 13.5577 2.89985L13.5533 2.92801V3.1752L13.7457 3.28418C13.9077 3.37011 14.0363 3.46848 14.1351 3.58111C14.2997 3.7687 14.4061 4.00712 14.4511 4.28978C14.4975 4.5805 14.4821 4.92643 14.4061 5.31807C14.3183 5.76859 14.1765 6.16096 13.9849 6.48202C13.8086 6.77785 13.584 7.02317 13.3175 7.21338C13.063 7.39401 12.7606 7.53117 12.4187 7.61896C12.0873 7.70517 11.7096 7.74875 11.2953 7.74875H11.0283C10.8375 7.74875 10.652 7.81749 10.5065 7.94075C10.3607 8.06654 10.264 8.23833 10.2344 8.42633L10.2143 8.5357L9.87644 10.6767L9.86107 10.7553C9.85707 10.7802 9.85013 10.7927 9.83992 10.8011C9.83076 10.8087 9.8176 10.8138 9.80476 10.8138H8.15665Z"
        fill="#253B80"
      />
      <path
        d="M13.9279 2.95312C13.9177 3.01858 13.906 3.08549 13.8928 3.15424C13.4404 5.47666 11.8929 6.27895 9.9164 6.27895H8.91008C8.6684 6.27895 8.46471 6.45447 8.42703 6.69289L7.91176 9.96061L7.76587 10.8868C7.74134 11.0434 7.86208 11.1845 8.01998 11.1845H9.80493C10.0163 11.1845 10.1958 11.0309 10.229 10.8225L10.2466 10.7318L10.5827 8.59914L10.6043 8.48219C10.6371 8.27303 10.817 8.11945 11.0284 8.11945H11.2954C13.0246 8.11945 14.3784 7.41734 14.7741 5.3856C14.9394 4.53686 14.8538 3.82819 14.4164 3.32977C14.2841 3.17947 14.1199 3.05478 13.9279 2.95312Z"
        fill="#179BD7"
      />
      <path
        d="M13.4545 2.76783C13.3854 2.74771 13.314 2.72943 13.2409 2.71297C13.1674 2.69689 13.092 2.68263 13.0146 2.6702C12.7432 2.62631 12.4459 2.60547 12.1274 2.60547H9.43897C9.37276 2.60547 9.30992 2.62046 9.2536 2.64752C9.1296 2.70712 9.0375 2.82451 9.01518 2.96822L8.44318 6.59061L8.42676 6.69629C8.46444 6.45787 8.66813 6.28234 8.90981 6.28234H9.91613C11.8927 6.28234 13.4402 5.47969 13.8926 3.15764C13.906 3.08889 13.9174 3.02197 13.9276 2.95652C13.8132 2.89582 13.6892 2.84389 13.5557 2.79964C13.5229 2.78867 13.4889 2.77807 13.4545 2.76783Z"
        fill="#222D65"
      />
      <path
        d="M9.01557 2.96769C9.03788 2.82397 9.13009 2.70659 9.25399 2.64735C9.31073 2.62029 9.37325 2.6053 9.43936 2.6053H12.1279C12.4464 2.6053 12.7437 2.62614 13.0149 2.67002C13.0925 2.68246 13.1679 2.69672 13.2414 2.7128C13.3145 2.72927 13.3858 2.74755 13.4549 2.76766C13.4893 2.7779 13.5233 2.7885 13.5565 2.79911C13.69 2.84335 13.814 2.89565 13.9284 2.95598C14.0631 2.09774 13.9274 1.51339 13.4634 0.984251C12.9518 0.401734 12.0284 0.152344 10.8469 0.152344H7.41683C7.17546 0.152344 6.96967 0.32786 6.9323 0.56665L5.50367 9.62263C5.47546 9.80179 5.61367 9.96347 5.7943 9.96347H7.91199L8.44367 6.59008L9.01557 2.96769Z"
        fill="#253B80"
      />
    </svg>
  );
}
