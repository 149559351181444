import useTheme from "../../hooks/ui/useTheme";
import { ThemeInterface } from "../../services/exports/Interfaces";

interface Props {
  className?: string;
  style?: Object;
  color?: string;
  fill?: boolean;
}

export default function InfoIcon(props: Props) {
  const { className, color, style, fill } = props;
  const theme: ThemeInterface = useTheme();

  if (!fill) {
    return (
      <svg
        className={className ?? "h-6 w-6"}
        viewBox="0 0 48 48"
        fill="none"
        style={style}
      >
        <path
          d="M22.3391 34.3113H25.9609V22.0004H22.3391V34.3113ZM24.0016 18.7244C24.5897 18.7244 25.0821 18.5304 25.4788 18.1425C25.8756 17.7545 26.074 17.2739 26.074 16.7004C26.074 16.0775 25.8753 15.5677 25.478 15.1712C25.0806 14.7747 24.5883 14.5765 24.0009 14.5765C23.3923 14.5765 22.8944 14.7747 22.5071 15.1712C22.1197 15.5677 21.9261 16.0727 21.9261 16.6863C21.9261 17.2715 22.125 17.7575 22.5228 18.1442C22.9206 18.531 23.4135 18.7244 24.0016 18.7244ZM24.0172 44.707C21.1438 44.707 18.452 44.1671 15.9419 43.0872C13.4318 42.0074 11.2362 40.5268 9.35486 38.6456C7.47359 36.7643 5.99304 34.5686 4.91321 32.0587C3.83337 29.5487 3.29346 26.8556 3.29346 23.9793C3.29346 21.1031 3.83337 18.4086 4.91321 15.8961C5.99304 13.3835 7.47212 11.1944 9.35046 9.32882C11.2288 7.46322 13.4236 5.98629 15.935 4.89802C18.4463 3.80976 21.1409 3.26562 24.0188 3.26562C26.8966 3.26562 29.5929 3.80903 32.1077 4.89583C34.6225 5.98263 36.812 7.45757 38.6762 9.32067C40.5404 11.1838 42.0162 13.3746 43.1037 15.8933C44.1911 18.4119 44.7348 21.1099 44.7348 23.9871C44.7348 26.8632 44.1907 29.5561 43.1024 32.0659C42.0141 34.5756 40.5372 36.7669 38.6716 38.6398C36.806 40.5126 34.6156 41.9921 32.1003 43.078C29.585 44.164 26.8906 44.707 24.0172 44.707ZM24.025 40.7461C28.6663 40.7461 32.6134 39.1172 35.8663 35.8595C39.1192 32.6017 40.7457 28.6404 40.7457 23.9754C40.7457 19.3341 39.1223 15.387 35.8757 12.1341C32.629 8.88123 28.6658 7.25478 23.9859 7.25478C19.3518 7.25478 15.4047 8.87811 12.1446 12.1248C8.88442 15.3714 7.25436 19.3347 7.25436 24.0146C7.25436 28.6486 8.88322 32.5957 12.141 35.8559C15.3987 39.116 19.36 40.7461 24.025 40.7461Z"
          fill={color ?? theme.color.brand.inkGrey.grey_3}
        />
      </svg>
    );
  }

  return (
    <svg
      className={className ?? "h-6 w-6"}
      viewBox="0 0 26 26"
      fill="none"
      style={style}
    >
      <circle
        r="11.6667"
        transform="matrix(-1 0 0 1 13.9974 14.0007)"
        fill={color ?? theme.color.brand.inkGrey.grey_3}
      />
      <path
        d="M14 14L14 21"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M14 9.16602L14 7.99935"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  );
}
