import {
  DeliveryZone, ErrorHandlerInterface, SearchDeliveryZoneParams,
} from "../exports/Interfaces";
import BackendApiClient from "./BackendApiClient";
import {
  DataWrapper,
  DataWrappedResponse,
} from "../exports/Types";
import { actionCreators } from "../../store/actions";
import ReduxActions from "../../store/ReduxActions";

class DeliveryZonesManager {
  public search = async (
    params: SearchDeliveryZoneParams,
    errorHandlers?: ErrorHandlerInterface
  ): Promise<DataWrappedResponse<DataWrapper<DeliveryZone>>> => {
    const response = await BackendApiClient.request({
      method: 'GET',
      url: '/delivery-zones/search',
      params,
    }, false, errorHandlers);

    if (! response?.success) {
      return response;
    }

    this.saveDataRedux(response.response.data.data);

    return response;
  };

  private saveDataRedux = (data) => {
    ReduxActions.dispatch(
      actionCreators.order.updateOrder({
        delivery_zone: data ?? null
      })
    )
  }
}

export default new DeliveryZonesManager();
