import {useCallback, useMemo} from "react";
import {useSelector} from "react-redux";
import {StoreInterface} from "../../store/types";
import useCompanyHours from "./useCompanyHours";
import {DeliveryProvider, ORDER_METHODS, RoomServiceLocationType} from "../../services/exports/Constants";
import {CompanyDetailsInterface} from "../../services/exports/Interfaces";
import collect, {Collection} from "collect.js";
import {TFunction} from "i18next";

export default function useOrderMethodsStatus(data?: CompanyDetailsInterface) {
  const { company: reduxCompany } = useSelector((state: StoreInterface) => state.initialData);
  const company = data ?? reduxCompany;

  const companyHours = useCompanyHours({ data: company });

  const pickupEnabled = useMemo(
    () => company?.has_live_orders
      && company?.has_pickup,
    [company?.has_live_orders, company?.has_pickup]
  );

  const deliveryEnabled = useMemo(
    () => company?.has_live_orders
      && company?.has_delivery
      && company?.delivery_enabled
      && (
        company?.delivery_provider === DeliveryProvider.Standalone
        || (company?.delivery_provider === DeliveryProvider.FirstDelivery && company?.first_delivery_merchant_id !== null)
      ),
    [
      company?.has_live_orders,
      company?.has_delivery,
      company?.delivery_enabled,
      company?.delivery_provider,
      company?.first_delivery_merchant_id,
    ]
  );

  const dineInEnabled = useMemo(
    () => company?.has_live_orders
      && company?.dine_in_enabled,
    [company?.has_live_orders, company?.dine_in_enabled]
  );

  const roomServiceEnabled = useMemo(
    () => company?.has_live_orders
      && company?.has_room_service
      && !!collect(company?.room_service_locations).firstWhere('type', RoomServiceLocationType.RoomService),
    [company?.has_live_orders, company?.has_room_service, company?.room_service_locations]
  );

  const tableServiceEnabled = useMemo(
    () => company?.has_live_orders
      && company?.has_room_service
    && !!collect(company?.room_service_locations).firstWhere('type', RoomServiceLocationType.TableService),
    [company?.has_live_orders, company?.has_room_service, company?.room_service_locations]
  );

  const takeoutEnabled = pickupEnabled || deliveryEnabled || roomServiceEnabled || tableServiceEnabled;

  const pickupCurrentlyOpen = useMemo(
    () => pickupEnabled
      && companyHours?.open
      && !companyHours?.pickupClosed
      && !companyHours?.ordersArePaused,
    [pickupEnabled, companyHours]
  );

  const deliveryCurrentlyOpen = useMemo(
    () => deliveryEnabled
      && companyHours?.open
      && !companyHours?.deliveryClosed
      && !companyHours?.ordersArePaused,
    [deliveryEnabled, companyHours]
  );

  const dineInCurrentlyOpen = useMemo(
    () => dineInEnabled
      && companyHours?.open
      && !companyHours?.dineInClosed
      && !companyHours?.ordersArePaused,
    [dineInEnabled, companyHours]
  );

  const roomServiceCurrentlyOpen = useMemo(
    () => roomServiceEnabled
      && companyHours?.open
      && !companyHours?.pickupClosed
      && !companyHours?.ordersArePaused,
    [roomServiceEnabled, companyHours]
  );

  const tableServiceCurrentlyOpen = useMemo(
    () => tableServiceEnabled
      && companyHours?.open
      && !companyHours?.pickupClosed
      && !companyHours?.ordersArePaused,
    [tableServiceEnabled, companyHours]
  );

  const takeoutCurrentlyOpen = pickupCurrentlyOpen || deliveryCurrentlyOpen || roomServiceCurrentlyOpen || tableServiceCurrentlyOpen;

  const takeoutOpenForFutureOrders = takeoutEnabled && company?.has_scheduled_orders && company?.has_pre_order;

  const enabledOrderMethods = useMemo<ORDER_METHODS[]>(
    () => collect({
      [ORDER_METHODS.ROOM_SERVICE]: roomServiceEnabled || tableServiceEnabled,
      [ORDER_METHODS.PICKUP]: pickupEnabled,
      [ORDER_METHODS.DELIVERY]: deliveryEnabled,
      [ORDER_METHODS.DINE_IN]: dineInEnabled,
    }).reduce(
      // @ts-ignore
      (carry: Collection<ORDER_METHODS>, enabled: boolean, method: ORDER_METHODS) => carry.when(enabled, (result) => result.push(method)),
      collect()
    ).toArray(),
    [roomServiceEnabled, tableServiceEnabled, pickupEnabled, deliveryEnabled, dineInEnabled]
  );

  const visibleOrderMethods = useMemo<ORDER_METHODS[]>(
    () => collect([
        ORDER_METHODS.PICKUP,
        ORDER_METHODS.DELIVERY,
        ORDER_METHODS.ROOM_SERVICE,
      ])
      .reject((item) => (company?.hidden_order_methods ?? []).includes(item))
      .toArray(),
    [company?.hidden_order_methods]
  );

  const openOrderMethods = useMemo<ORDER_METHODS[]>(
    () => collect({
      [ORDER_METHODS.ROOM_SERVICE]: roomServiceCurrentlyOpen || tableServiceCurrentlyOpen,
      [ORDER_METHODS.PICKUP]: pickupCurrentlyOpen,
      [ORDER_METHODS.DELIVERY]: deliveryCurrentlyOpen,
      [ORDER_METHODS.DINE_IN]: dineInCurrentlyOpen,
    }).reduce(
      // @ts-ignore
      (carry: Collection<ORDER_METHODS>, enabled: boolean, method: ORDER_METHODS) => carry.when(enabled, (result) => result.push(method)),
      collect()
    ).toArray(),
    [roomServiceCurrentlyOpen, tableServiceCurrentlyOpen, pickupCurrentlyOpen, deliveryCurrentlyOpen, dineInCurrentlyOpen]
  );

  const isOrderMethodEnabled = useCallback(
    (method: ORDER_METHODS) => (enabledOrderMethods ?? []).includes(method),
    [enabledOrderMethods]
  );

  const isOrderMethodCurrentlyOpen = useCallback(
    (method: ORDER_METHODS) => (openOrderMethods ?? []).includes(method),
    [openOrderMethods]
  );

  const isOrderMethodOpenForFutureOrders = useCallback(
    (method: ORDER_METHODS) => {
      if (method === ORDER_METHODS.DELIVERY && company?.delivery_provider === DeliveryProvider.FirstDelivery) {
        return false;
      }

      return isOrderMethodEnabled(method)
        && company?.has_scheduled_orders
        && company?.has_pre_order
    },
    [
      openOrderMethods,
      isOrderMethodEnabled,
      company?.delivery_provider,
      company?.has_scheduled_orders,
      company?.has_pre_order,
    ]
  );

  const renderOrderMethodBasedCopy = useCallback(
    (method: ORDER_METHODS, t: TFunction, prefix: string, params: object = undefined): string => {
      if (method !== ORDER_METHODS.ROOM_SERVICE) {
        return t(`${prefix}.${method}`, params);
      }

      if (roomServiceEnabled && tableServiceEnabled) {
        return t(`${prefix}.${method}.both`, params);
      }

      if (roomServiceEnabled) {
        return t(`${prefix}.${method}.${RoomServiceLocationType.RoomService}`, params);
      }

      return t(`${prefix}.${method}.${RoomServiceLocationType.TableService}`, params);
    },
    [roomServiceEnabled, tableServiceEnabled]
  );

  return {
    takeoutEnabled,
    pickupEnabled,
    deliveryEnabled,
    roomServiceEnabled,
    tableServiceEnabled,
    dineInEnabled,
    takeoutCurrentlyOpen,
    pickupCurrentlyOpen,
    deliveryCurrentlyOpen,
    dineInCurrentlyOpen,
    roomServiceCurrentlyOpen,
    tableServiceCurrentlyOpen,
    takeoutOpenForFutureOrders,
    enabledOrderMethods,
    visibleOrderMethods,
    openOrderMethods,
    isOrderMethodEnabled,
    isOrderMethodCurrentlyOpen,
    isOrderMethodOpenForFutureOrders,
    renderOrderMethodBasedCopy,
  };
}