import {createGlobalStyle} from "styled-components";

const pageStyle = createGlobalStyle`
ol {
    margin: 0;
    padding: 0
}

table td, table th {
    padding: 0
}

.c1 {
    background-color: #ffffff;
    color: #333333;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 18pt;
    font-family: "Helvetica Neue";
    font-style: normal
}

.c2 {
    background-color: #ffffff;
    color: #333333;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 10.5pt;
    font-family: "Helvetica Neue";
    font-style: normal
}

.c13 {
    background-color: #ffffff;
    -webkit-text-decoration-skip: none;
    color: #1155cc;
    font-weight: 400;
    text-decoration: underline;
    text-decoration-skip-ink: none;
    font-size: 10.5pt;
    font-family: "Helvetica Neue"
}

.c9 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 11pt
}

.c5 {
    padding-top: 15pt;
    padding-bottom: 8pt;
    line-height: 1.1;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c12 {
    padding-top: 0pt;
    padding-bottom: 36pt;
    line-height: 1.1;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c14 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c3 {
    padding-top: 0pt;
    padding-bottom: 8pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c11 {
    background-color: #ffffff;
    font-size: 28pt;
    font-family: "Helvetica Neue";
    color: #333333;
    font-weight: 700
}

.c4 {
    background-color: #ffffff;
    font-size: 10.5pt;
    font-family: "Helvetica Neue";
    color: #333333;
    font-weight: 400
}

.c0 {
    background-color: #ffffff;
    font-size: 10.5pt;
    font-family: "Helvetica Neue";
    color: #333333;
    font-weight: 700
}

.c10 {
    color: #000000;
    font-weight: 400;
    font-size: 11pt;
    font-family: "Arial"
}

.c8 {
    background-color: #ffffff;
    max-width: 468pt;
    padding: 72pt 72pt 72pt 72pt
}

.c7 {
    text-decoration: none;
    vertical-align: baseline;
    font-style: normal
}

.c6 {
    color: inherit;
    text-decoration: inherit
}

.title {
    padding-top: 0pt;
    color: #000000;
    font-size: 26pt;
    padding-bottom: 3pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.subtitle {
    padding-top: 0pt;
    color: #666666;
    font-size: 15pt;
    padding-bottom: 16pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

li {
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
}

p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
}

h1 {
    padding-top: 20pt;
    color: #000000;
    font-size: 20pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h2 {
    padding-top: 18pt;
    color: #000000;
    font-size: 16pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h3 {
    padding-top: 16pt;
    color: #434343;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h4 {
    padding-top: 14pt;
    color: #666666;
    font-size: 12pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h5 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h6 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
}`;

export default pageStyle;