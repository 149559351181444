import classnames from 'classnames';
import { useMemo } from 'react';
import LocationIcon from '../../assets/logo/LocationIcon';
import useStore from '../../hooks/global/useStore';
import { useTranslation } from 'react-i18next';
import useTheme from '../../hooks/ui/useTheme';
import useAppMethods from '../../hooks/utility/useAppMethods';
import { DeliveryProvider, ORDER_METHODS } from '../../services/exports/Constants';
import moment from 'moment';
import useOrderMethodsStatus from '../../hooks/availability/useOrderMethodsStatus';
import useCompanyLocation from '../../hooks/location/useCompanyLocation';
import collect from 'collect.js';
import HelperMethods from '../../services/exports/HelperMethods';
import useOrderPreparationTime from '../../hooks/order/useOrderPreparationTime';
import ForwardIcon from '../../assets/logo/ForwardIcon';

interface Props {
  className?: string;
}

export default function OrderType({ className }: Props) {
  const { t } = useTranslation(null, { keyPrefix: 'Components:OrderType:OrderType' });

  const theme = useTheme();

  const { company } = useStore()?.initialData;
  const { cached_order } = useStore()?.order;

  const companyLocation = useCompanyLocation();

  const {
    visibleOrderMethods, 
    isOrderMethodCurrentlyOpen, 
    isOrderMethodOpenForFutureOrders,
  } = useOrderMethodsStatus();

  const getPreparationTime = useOrderPreparationTime();

  const { formatDateTime, createScheduledOrderSlot, toggleOrderMethodModal } = useAppMethods();

  const usesExternalDelivery = company?.delivery_provider !== DeliveryProvider.Standalone;

  const orderMethods = useMemo<ORDER_METHODS[]>(
    () => collect(visibleOrderMethods).take(2).toArray(),
    [visibleOrderMethods],
  );

  const getPickupAddress = (): string => {
    if (cached_order?.method === ORDER_METHODS.DELIVERY) {
      return cached_order?.delivery_street_name + ' ' + cached_order?.delivery_street_number;
    }

    return companyLocation.address;
  }

  const getScheduledTime = (): string => {
    if (cached_order?.scheduled_for === null) {
      return t('asap');
    }

    return moment(cached_order.scheduled_for).isSame(moment(), 'day') ?
      createScheduledOrderSlot(cached_order?.scheduled_for) :
      formatDateTime(cached_order.scheduled_for, { format: 'dd, DD MMM HH:mm' });
  }

  return (
    <>
      <div className={classnames('relative flex justify-between items-center py-xTiny px-tiny rounded-xl bg-brand-inkGrey-grey_6 cursor-pointer z-10 shadow-section', className)}>
        {orderMethods.map((item, index) => {
          const isMethodAvailable = isOrderMethodCurrentlyOpen(item) || isOrderMethodOpenForFutureOrders(item);
          const preparationTime = HelperMethods.formatTimeInterval(getPreparationTime(item));

          const isActiveMethod = cached_order?.method === item;

          return (
            <div
              key={index}
              className={classnames('flex flex-col w-3/6 text-center rounded-xl py-tiny', {
                  'bg-white shadow-section': isActiveMethod,
              })}
              onClick={() => toggleOrderMethodModal({ openModal: true, orderMethod: item})}
            >
              <span
                className={classnames('font-semibold', {
                    'text-companyBrand-primary': isActiveMethod,
                    'text-brand-inkGrey-grey_4': !isActiveMethod,
                })}
              >
                  {t(`order_methods.${item}`)}
                  <span className="ml-tiny text-sm">
                    {item === ORDER_METHODS.DELIVERY && usesExternalDelivery ? '🆕' : ''}
                  </span>
              </span>
              <span className="text-xtiny text-brand-inkGrey-grey_4">
                {isMethodAvailable ? t('time', { time: preparationTime }) : t('unavailable')}
              </span>
            </div>
          );
        })}
      </div>

      <div 
        className="flex justify-center lg:justify-between items-center lg:border rounded-xl w-full h-8 mt-mini p-mini cursor-pointer"
        onClick={() => toggleOrderMethodModal({ openModal: true })}
      >
        <div className="flex items-center">
          <LocationIcon
            color={theme.color.companyBrand.primary}
            className="h-5 w-3"
          />

          <span className="text-tiny ml-tiny lg:ml-mini">
            {`${getPickupAddress()} (${getScheduledTime()})`}
          </span>
        </div>

        <div className="ml-tiny lg:ml-none">
          <ForwardIcon className="w-1.5" color={theme.color.text.default} />
        </div>
      </div>
    </>
  );
}