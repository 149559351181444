import React from 'react';
import {useSelector} from "react-redux";
import {StoreInterface} from "../../store/types";
import DE from "./privacy-policy/DE";
import GB from "./privacy-policy/GB";

export default function PrivacyPolicy() {
  const { company } = useSelector((store: StoreInterface) => store.initialData);

  switch (company?.country) {
    case 'DE': return <DE />;
    default: return <GB />;
  }
}