import { useMemo, useState } from "react";
import Card from "./Card";
import { CategoryInterface } from "../../../services/exports/Interfaces";
import SideBarIcon from "../../../assets/logo/SideBarIcon";
import useTheme from "../../../hooks/ui/useTheme";
import MenuSideBar from "./MenuSideBar";
import useMenu from "../../../hooks/menu/useMenu";
import ScrollMenu from "./ScrollMenu";

interface Props {
  focusedCategoryId?: number;
  menuNavBarStickyPosition?: number;
}

const MenuBar = (props: Props): JSX.Element => {
  const { focusedCategoryId, menuNavBarStickyPosition } = props;

  const theme = useTheme();

  const [showSideBar, setShowSideBar] = useState<boolean>(false);

  const { categories } = useMenu();

  function toggleSideBar(): void {
    setShowSideBar(!showSideBar);
  }

  function jumpToCategory(id?: number): void {
    const category = document.getElementById(`category-id-${id}`);

    window.scrollTo({
      left: 0,
      top: category.offsetTop - 70,
      behavior: "smooth",
    });
  }

  const renderSideBar = useMemo<JSX.Element>(() => {
    return (
      <MenuSideBar
        open={showSideBar}
        toggle={setShowSideBar}
        focusedCategoryId={focusedCategoryId}
        menuSideBarStickyPosition={menuNavBarStickyPosition}
        onSelectCategory={(category) => jumpToCategory(category?.id)}
      />
    );
  }, [showSideBar, focusedCategoryId, menuNavBarStickyPosition]);

  const renderMenuButton = (): JSX.Element => (
    <button
      className={menuButtonContainer}
      onClick={toggleSideBar}
      id={"menu-button"}
    >
      <SideBarIcon color={theme.color.text.grey} className="h-4 w-4" />
    </button>
  );

  return (
    <div className={container} id="menu-bar">
      <div className={categoriesContainer}>
        {renderMenuButton()}
        {categories?.length > 0 && (
          <ScrollMenu
            focusedCategoryId={focusedCategoryId}
            jumpToCategory={jumpToCategory}
          >
            {categories?.map((category: CategoryInterface) => (
              <Card
                key={category?.id?.toString()}
                itemId={category?.id?.toString()}
                category={category}
                focusedCategoryId={focusedCategoryId}
                onClick={jumpToCategory}
              />
            ))}
          </ScrollMenu>
        )}
      </div>
      {renderSideBar}
    </div>
  );
};

export default MenuBar;

const container =
  "px-small lg:px-none bg-background-inkWhite-white_1 border-b h-[58px]";

const categoriesContainer =
  "h-full w-full flex flex-row items-center relative";

const menuButtonContainer =
  "flex flex-row p-tiny bg-background-inkWhite-white_0 shadow-icon rounded-[4px] items-center mr-small";
