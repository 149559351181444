import React, { useCallback } from "react";
import CustomButton from "../components-library/buttons/CustomButton";
import collect from "collect.js";
import CustomModal from "../components-library/CustomModal";
import {ButtonTypes} from "../services/exports/Interfaces";

export interface ConfirmCancelModalProps {
  title?: string | React.ReactNode;
  description?: string;
  icon?: React.ReactNode;
  openModal: boolean;
  primaryButtonTitle?: string;
  secondaryButtonTitle?: string;
  customGradient?: string;
  loading?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
}

export default function ConfirmCancelModal(props: ConfirmCancelModalProps) {
  const {
    title,
    description,
    icon,
    primaryButtonTitle,
    secondaryButtonTitle,
    openModal,
    loading,
    customGradient,
    onConfirm,
    onCancel,
  } = props;

  const buttonCount = useCallback(
    () =>
      collect([primaryButtonTitle, secondaryButtonTitle])
        ?.filter((button: string) => collect(button)?.isNotEmpty())
        ?.count(),
    [primaryButtonTitle, secondaryButtonTitle]
  );

  return (
    <CustomModal open={openModal}>
      <div className={container}>
        {icon && <div className={iconContainer}>{icon}</div>}
        {title && <h4 className={titleStyle}>{title}</h4>}
        {descriptionStyle && <p className={descriptionStyle}>{description}</p>}
        <div className={buttonContainer(buttonCount())}>
          {primaryButtonTitle && (
            <CustomButton
              title={primaryButtonTitle}
              customGradient={
                customGradient ??
                "bg-gradient-to-r from-brand-gradients-primary-color_1 via-brand-gradients-primary-color_1 to-brand-gradients-primary-color_2"
              }
              onClick={onConfirm}
              loading={loading}
            />
          )}
          {secondaryButtonTitle && (
            <CustomButton
              title={secondaryButtonTitle}
              buttonType={ButtonTypes.danger}
              onClick={onCancel}
            />
          )}
        </div>
      </div>
    </CustomModal>
  );
}

const container = "w-full p-medium flex flex-col items-center";

const iconContainer = "mb-small";

const titleStyle = "mb-small text-center";

const descriptionStyle = "mb-medium text-center";

const buttonContainer = (count: number) =>
  `w-full grid grid-cols-${count} gap-4 mt-2`;
