import React, { useEffect, useMemo, useState } from "react";
import CustomModal from "../../components-library/CustomModal";
import {MODAL_SIZES, SCREENS} from "../../services/exports/Constants";
import BackIcon from "../../assets/logo/BackIcon";
import LoadingIndicator from "../../components-library/loading/LoadingIndicator";
import Divider from "../../components-library/Divider";
import DineInNavBar from "../../components-library/navigation/DineInNavBar";
import {useTranslation} from "react-i18next";
import useAppMethods from "../../hooks/utility/useAppMethods";
import useNavigate from "../../hooks/navigation/useNavigate";

interface Props {
  openModal: boolean;
  toggleModal: () => void;
}

export default function ExitDineInModal(props: Props) {
  const {t} = useTranslation(null, {keyPrefix: 'Modals:DineIn:ExitDineInModal'});

  const {openModal, toggleModal} = props;

  const { toggleOrderMethodModal } = useAppMethods();
  const { navigate } = useNavigate();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    toggleLoading()
  }, []);

  function toggleLoading() {
    setTimeout(() => setLoading(false), 500);
  }

  const renderContent = useMemo(() => {
    if (loading) {
      return (
        <div className="mx-auto py-small w-fit">
          <LoadingIndicator lg={60} md={50} sm={40}/>
        </div>
      );
    }

    return (
      <>
        <div
          className={action}
          onClick={toggleModal}
        >
          <h6>{t('options.dine_in')}</h6>
          <BackIcon className={actionIcon}/>
        </div>
        <Divider/>
        <div
          className={action}
          onClick={() => {
            navigate(SCREENS.HOME_SCREEN);
            toggleOrderMethodModal({openModal: true})
          }}
        >
          <h6>{t('options.takeaway')}</h6>
          <BackIcon className={actionIcon}/>
        </div>
      </>
    )
  }, [loading]);

  return (
    <CustomModal
      open={openModal}
      size={MODAL_SIZES.SM}
      onClose={toggleModal}
      position="bottom"
      className="w-full"
    >
      <div className={container}>
        <div className={headerContainer}>
          <div className="border-b rounded-t-lg">
            <DineInNavBar
              title={t('title')}
              hideBackIcon
              transparent
              rightClick={toggleModal}
              className="rounded-t-lg"
            />
          </div>
          <div>
            {renderContent}
          </div>
        </div>
      </div>
    </CustomModal>
  );
}

const container = "w-full bg-background-inkWhite-white_1 rounded-t-lg";

const headerContainer = "sticky top-0 bg-background-inkWhite-white_1 z-10 rounded-t-lg";

const action = 'flex justify-between p-small';

const actionIcon = 'h-4 w-4 rotate-180';