import BackIcon from "../../assets/logo/BackIcon";
import CloseIcon from "../../assets/logo/CloseIcon";
import useTheme from "../../hooks/ui/useTheme";
import { SCREENS } from "../../services/exports/Constants";
import DomainResource from "../../services/resources/DomainResource";
import classnames from "classnames";
import useConfig from "../../hooks/ui/useConfig";

interface Props {
  type?: "page";
  title?: string | JSX.Element;
  backIconTitle?: string;
  closeIconTitle?: string;
  hideBackIcon?: boolean;
  hideCloseIcon?: boolean;
  customRightAction?: any;
  transparent?: boolean;
  containerClassName?: string;
  showCompanyLogo?: boolean;
  customCenterElement?: React.ReactNode;
  rightClick?: () => void;
  leftClick?: () => void;
}

export default function CustomNavBar(props: Props) {
  const {
    type,
    title,
    backIconTitle,
    closeIconTitle,
    hideBackIcon,
    hideCloseIcon,
    transparent,
    showCompanyLogo,
    containerClassName,
    customRightAction,
    rightClick,
    leftClick,
  } = props;

  const { logo_url } = useConfig();
  const theme = useTheme();

  const domain = DomainResource.getDomain();

  const extraSpacing: boolean = !!backIconTitle || !!closeIconTitle;

  function goBack(): void {
    window.location.replace(`${domain}${SCREENS.HOME_SCREEN}`);
  }

  return (
    <div
      className={classnames(
        container(transparent, extraSpacing),
        containerClassName
      )}
    >
      <button
        className={leftContainer(extraSpacing)}
        onClick={leftClick && leftClick}
        disabled={hideBackIcon}
      >
        {!hideBackIcon && (
          <div className={leftWrapper}>
            <div
              className={classnames(iconContainer, {
                "shadow-transparent bg-transparent": type === "page",
              })}
            >
              <BackIcon
                className="h-4 w-4"
                color={theme.color.brand.secondary}
              />
            </div>
            {backIconTitle && (
              <p
                className={classnames(backIconDescription, {
                  "text-base": type === "page",
                })}
              >
                {backIconTitle}
              </p>
            )}
          </div>
        )}
      </button>
      <div className={centerContainer(extraSpacing)}>
        <h4 className={titleStyle(showCompanyLogo)}>{title}</h4>
        {logo_url && showCompanyLogo && (
          <button onClick={goBack}>
            <img
              src={logo_url}
              alt="Logo"
              className={companyLogoStyle}
            />
          </button>
        )}
      </div>
      <button
        className={rightContainer(extraSpacing)}
        onClick={rightClick && rightClick}
        data-test="custom-nav-bar-close-icon"
        disabled={hideCloseIcon}
      >
        {customRightAction ??
          (!hideCloseIcon && (
            <div className={rightWrapper}>
              {closeIconTitle && (
                <p className={closeIconDescription}>{closeIconTitle}</p>
              )}
              <div className={iconContainer}>
                <CloseIcon color={theme.color.brand.secondary} />
              </div>
            </div>
          ))}
      </button>
    </div>
  );
}

const container = (transparent?: boolean, extraSpacing?: boolean) =>
  `w-full grid ${extraSpacing ? "grid-cols-9" : "grid-cols-5"} p-small ${
    transparent
      ? "border-none"
      : "border-b bg-background-inkWhite-white_1 border-brand-inkGrey-grey_1"
  }`;

const leftContainer = (extraSpacing?: boolean) =>
  `${extraSpacing ? "lg:col-span-2 col-span-3" : ""}  flex items-center`;

const leftWrapper = "flex flex-1 items-center";

const centerContainer = (extraSpacing?: boolean) =>
  `${
    extraSpacing ? "lg:col-span-5 col-span-3" : "col-span-3"
  } flex justify-center items-center`;

const rightContainer = (extraSpacing?: boolean) =>
  `${
    extraSpacing ? "lg:col-span-2 col-span-3" : ""
  } flex justify-end items-center`;

const rightWrapper = "flex flex-1 items-center justify-end";

const iconContainer =
  "w-9 h-9 bg-background-inkWhite-white_1 shadow-icon rounded-full flex justify-center items-center";

const companyLogoStyle = "h-[36px] lg:hidden flex";

const titleStyle = (hidden: boolean) =>
  `text-center ${hidden ? "lg:flex hidden" : ""}`;

const backIconDescription = "ml-mini text-tiny";

const closeIconDescription = "mr-mini tiny";
