import { useState } from "react";
import CheckmarkIcon from "../assets/logo/CheckmarkIcon";
import CloseIcon from "../assets/logo/CloseIcon";
import { ThemeInterface } from "../services/exports/Interfaces";
import PhoneInput, { Country } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import useTheme from "../hooks/ui/useTheme";
import { parsePhoneNumber, isValidPhoneNumber } from "react-phone-number-input";
import classnames from "classnames";

export interface PhoneNumberInputProps {
  placeholder: string;
  value: string;
  id?: string;
  disabled?: boolean;
  country?: Country;
  inputContainerStyle?: string;
  onChange: (value: string) => void;
  onChangeCountry: (value: Country) => void;
}

export default function PhoneNumberInput(props: PhoneNumberInputProps) {
  const {
    value,
    placeholder,
    country,
    disabled,
    inputContainerStyle,
    onChange,
    onChangeCountry,
  } = props;

  const theme: ThemeInterface = useTheme();
  const [elementId, setElementId] = useState(0);

  function renderRemoveIcon() {
    return (
      <div className={removeIconContainer} onClick={() => onChange("")}>
        <CloseIcon className={iconStyle} color={theme.color.text.disabled} />
      </div>
    );
  }

  function renderCheckmarkIcon() {
    return (
      <div className={checkmarkIconContainer}>
        <CheckmarkIcon className={iconStyle} color={theme.color.text.light} />
      </div>
    );
  }

  const onPaste = () => {
    setTimeout(() => setElementId(elementId + 1));
  };

  function changeValue(value: string) {
    return onChange && onChange(value ?? "");
  }

  function isValid() {
    return isValidPhoneNumber(value);
  }

  function formatValue() {
    return parsePhoneNumber(value)?.formatInternational();
  }

  function renderInput() {
    function renderActionIcons() {
      if (value?.length === 0) {
        return null;
      }
      if (isValid()) {
        return renderCheckmarkIcon();
      }
      return renderRemoveIcon();
    }

    function renderPlaceholder(): JSX.Element | null {
      return value?.length === 0 ? (
        <div className={placeholderContainer}>
          <p className={placeholderStyle}>123 4567890</p>
        </div>
      ) : null;
    }

    return (
      <div className={classnames(inputContainer, inputContainerStyle ?? "")}>
        <form onPaste={onPaste} onSubmit={(e) => e.preventDefault()} className={"w-full relative"}>
          {renderPlaceholder()}
          <PhoneInput
            data-test="phone-number-input"
            key={elementId}
            country={country ?? "DE"}
            defaultCountry={country ?? "DE"}
            placeholder={placeholder}
            value={formatValue()}
            onChange={changeValue}
            onCountryChange={onChangeCountry}
            international={true}
            className="w-full font-sm relative"
            countryCallingCodeEditable={false}
            onBlur={onPaste}
          />
        </form>
        {!disabled && <div className="mx-2">{renderActionIcons()}</div>}
      </div>
    );
  }

  return <div className={container}>{renderInput()}</div>;
}

const container = "flex flex-row justify-between items-center";

const inputContainer =
  "placeholder:text-brand-text-grey text-brand-text-default rounded flex flex-row px-small py-xsmall items-center w-full mr-4 border rounded border-brand-inkGrey-grey_2";

const placeholderContainer = "absolute inset-0 pl-[85px] z-0";

const removeIconContainer =
  "bg-brand-text-grey rounded-full flex justify-center items-center w-5 h-5";

const checkmarkIconContainer =
  "bg-brand-primary rounded-full flex justify-center items-center w-5 h-5";

const iconStyle = "h-2 w-2";

const placeholderStyle = "text-brand-text-grey";
