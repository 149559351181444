import RewardIcon from "../../assets/logo/RewardIcon";
import useDineInFlow from "../../hooks/global/useDineInFlow";
import {useTranslation} from "react-i18next";
import InfoIcon from "../../assets/logo/InfoIcon";
import useTheme from "../../hooks/ui/useTheme";
import useLoyaltyProgram from "../../hooks/loyalty/useLoyaltyProgram";

interface Props {
  quantity?: number;
  className?: string;
}

export default function LoyaltyPointsLabel(props: Props) {
  const { t } = useTranslation(null, { keyPrefix: 'Components:Rewards:LoyaltyPointsLabel' });

  const { quantity, className } = props;

  const theme = useTheme();
  const { isDineIn } = useDineInFlow();
  const { hasLoyaltyPromotion } = useLoyaltyProgram();

  return quantity > 0 && hasLoyaltyPromotion && (
    <div className={className}>
      <div className="flex justify-between">
        <h6 className="text-mini text-companyBrand-primary">{t('title')}</h6>
        <h6 className="flex text-mini text-companyBrand-primary">
          {t('points', {count: quantity})}
          <RewardIcon className="h-5 w-5 ml-tiny my-auto" />
        </h6>
      </div>
      {isDineIn && (
        <div className="flex mt-tiny items-center">
          <InfoIcon
            className="mr-tiny h-3.5 w-3.5"
            color={theme.color.text.grey}
            fill
          />
          <p className="text-tiny text-brand-text-grey">
            {t('subtitle')}
          </p>
        </div>
      )}
    </div>
  )
}