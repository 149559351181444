import {useSelector} from "react-redux";
import useBasket from "../basket/useBasket";
import {StoreInterface} from "../../store/types";
import {ORDER_METHODS} from "../../services/exports/Constants";
import {useMemo} from "react";

export default function useDeliveryThreshold() {
  const { company } = useSelector((state: StoreInterface) => state.initialData);
  const { cached_order } = useSelector((state: StoreInterface) => state.order);

  const { total } = useBasket();
  const minThreshold = cached_order?.method === ORDER_METHODS.DELIVERY
    ? cached_order?.delivery_zone?.min_threshold
    : null;
  const maxThreshold = cached_order?.method === ORDER_METHODS.DELIVERY
    ? cached_order?.delivery_zone?.max_threshold
    : null;

  const hasDeliveryThreshold = useMemo(
    () => cached_order?.method === ORDER_METHODS.DELIVERY
      && company?.has_delivery
      && company?.delivery_enabled
      && (
        cached_order?.delivery_zone?.min_threshold > 0
        || cached_order?.delivery_zone?.max_threshold > 0
      ),
    [
      cached_order?.method,
      company?.has_delivery,
      company?.delivery_enabled,
      cached_order?.delivery_zone?.min_threshold,
      cached_order?.delivery_zone?.max_threshold,
    ]
  );

  const isWithinMinThreshold = useMemo(
    () => minThreshold ? total >= minThreshold : true,
    [minThreshold, total],
  );

  const isWithinMaxThreshold = useMemo(
    () => maxThreshold ? total <= maxThreshold : true,
    [maxThreshold, total],
  );

  const isWithinThreshold = useMemo(
    () => isWithinMinThreshold && isWithinMaxThreshold,
    [isWithinMinThreshold, isWithinMaxThreshold],
  );

  const amountTillMinThreshold = useMemo(
    () => minThreshold - total,
    [minThreshold, total],
  );

  return {
    hasDeliveryThreshold,
    isWithinMinThreshold,
    isWithinMaxThreshold,
    isWithinThreshold,
    minThreshold,
    maxThreshold,
    amountTillMinThreshold,
  };
}
