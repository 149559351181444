import React from 'react';
import {useSelector} from "react-redux";
import {StoreInterface} from "../../store/types";
import DE from "./terms-and-conditions/DE";
import GB from "./terms-and-conditions/GB";

export default function TermsAndConditions() {
  const {company} = useSelector((store: StoreInterface) => store.initialData);

  switch (company?.country) {
    case 'DE':
      return <DE/>;
    default:
      return <GB/>;
  }
}