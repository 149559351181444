import React from 'react';
import PageStyle from '../../../styles/pages/terms-and-conditions/GB';
import {useSelector} from "react-redux";
import {StoreInterface} from "../../../store/types";

export default function GB() {
  const {company} = useSelector((store: StoreInterface) => store.initialData);

  return (
    <div className="p-small">
      <PageStyle/>
      <p className="c4"><span
        className="c0">Terms &amp; Conditions of {company?.name} (the &quot;Store&quot;)</span>
      </p>
      <p className="c3"><span className="c0"></span></p>
      <p className="c4"><span className="c5 c7">Introduction</span></p>
      <p className="c3"><span className="c1"></span></p>
      <p className="c4"><span className="c1">PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING THE ONLINE ORDERING SYSTEM</span>
      </p>
      <p className="c3"><span className="c1"></span></p>
      <p className="c4"><span className="c1">By accessing, using or otherwise availing of the Online Ordering System, you agree to the terms and conditions set out below, and to any other terms and conditions made available to you on the Online Ordering System, (&quot;Terms of Use&quot;) in respect of your use of the Online Ordering System.</span>
      </p>
      <p className="c3"><span className="c1"></span></p>
      <p className="c4"><span
        className="c1">The Terms of Use constitute a binding legal agreement between the Store (&quot;us&quot;, &quot;we&quot; or &quot;our&quot;) and you. Please read the Terms of Use carefully and if you do not agree to any of them, you will not be entitled or permitted to access or use the Online Ordering System and you must refrain from doing so.</span>
      </p>
      <p className="c3"><span className="c1"></span></p>
      <p className="c4"><span className="c1">Your use of the Online Ordering System is also subject to our Privacy Policy and our Cookie Policy and you must also familiarise yourself with these other terms and policies before you use the Online Ordering System.</span>
      </p>
      <p className="c3"><span className="c1"></span></p>
      <p className="c4"><span className="c1">We may change the Terms of Use from time to time to the extent permitted by applicable law. We will provide you with notice of any such change through our website, app and/or by other means, as appropriate. You should review the Terms of Use periodically and you agree that your continued use of the Online Ordering System after we publish or send a notice about any changes to the Terms of Use constitutes your acceptance of the updated Terms of Use as of their effective date. If you object to any changes to the Terms of Use, you may stop using the Online Ordering System.</span>
      </p>
      <p className="c3"><span className="c0"></span></p>
      <p className="c4"><span className="c0">1. Purpose</span></p>
      <p className="c3"><span className="c1"></span></p>
      <p className="c4"><span className="c1">The purpose of the Online Ordering System is to provide a simple, convenient and cost-effective service that enables you (and other customers) to place orders for the delivery or collection of food and/or other items (the &quot;Products&quot;) with us. You are granted a right to use the Online Ordering System pursuant to the Tuck Terms of Service which form a separate and leading binding agreement between you and Tuck.</span>
      </p>
      <p className="c3"><span className="c1"></span></p>
      <h2 className="c6" id="h.8ijenwfeqnfq"><span className="c0">2. Disclaimer and Liability</span></h2>
      <p className="c2"><span className="c1">You hereby acknowledge and agree that the Online Ordering System is made available for use &quot;as is&quot; and &quot;as available&quot; and we make no representations and give no warranties of any kind whatsoever in relation to the Online Ordering System and that, without prejudice to the generality of the foregoing, we make no warranty or representation regarding, and shall have no responsibility for, the accuracy, availability, reliability, security, fitness for purpose or performance or continuity of the Online Ordering System or the contents thereof. It is your sole responsibility to ensure that the Online Ordering System is suitable for your purposes.</span>
      </p>
      <p className="c2"><span className="c1">You acknowledge and agree that to the extent permitted by applicable law, the Online Ordering System may at any time be modified or withdrawn, on a temporary or permanent basis, and wholly or in part, for any reason without notice, and we shall have no liability to you or any other party arising out of or in connection with such modification or withdrawal. We make no representation or warranty that the Online Ordering System will be uninterrupted or error free or that the Online Ordering System will be free of viruses. We do not warrant or represent that the Online Ordering System will meet your requirements or specifications, or that any errors or bugs in the Online Ordering System will be corrected.</span>
      </p>
      <p className="c2"><span className="c1">All representations, warranties, guarantees, terms and conditions whether express or implied by statute or otherwise in relation to the Online Ordering System or the information contained therein (including any implied warranty of accuracy of data, non-infringement, merchantability or fitness for a particular purpose) are hereby excluded to the fullest extent permitted by applicable law.</span>
      </p>
      <p className="c2"><span className="c1">Our total aggregate liability to you whether in contract, tort (including negligence) under statute or otherwise arising out of or in connection with the Terms of Use, the Online Ordering System and/or the Products shall be limited to &quot;100.</span>
      </p>
      <p className="c2"><span className="c1">We acknowledge that nothing in the Terms of Use shall exclude or limit our liability for death or personal injury resulting from our negligence or that of our agents or employees.</span>
      </p>
      <h2 className="c6" id="h.17qivdyk619z"><span className="c0">3. Use of data</span></h2>
      <p className="c2"><span className="c1">If you enter your payment card details on the Online Ordering System, these details will be stored securely by a third party payment service provider. We do not have access to your payment card details, with the exception of the last 4 digits of your payment card number and the card expiry date which are used to identify your card and orders.</span>
      </p>
      <p className="c2"><span className="c1">Where you provide your mobile phone number, your name and your address through the Online Ordering System we will be provided with this information so that we can complete your order.</span>
      </p>
      <p className="c2"><span className="c1">For more information on how we may process information about you please see our Privacy Policy. Please also see Flipdish&quot;s privacy policy for information about how they process your information.</span>
      </p>
      <h2 className="c6" id="h.ym9wkdpt4hg"><span className="c0">4. Third Party Sites</span></h2>
      <p className="c2"><span className="c1">The Online Ordering System may contain links to other websites. Such websites are not under our control and we are not responsible or liable for the practices, content or availability of such websites. The inclusion on the Online Ordering System of links to other websites does not imply any endorsement or sponsorship of the content or practices on such websites nor does it imply any affiliation or association between us and any operators of such websites. These links to websites are provided for your convenience only.</span>
      </p>
      <p className="c2"><span className="c1">If you decide to access a website through a link on the Online Ordering System, you do so at your own risk, and we will not be responsible or liable to you or any third party for any loss, cost, damage or other liability arising out of or in connection with accessing, using or relying on the website or any content, goods, practices or services available on such website.</span>
      </p>
      <p className="c2"><span className="c1">You are prohibited from linking to the Online Ordering System from any other business, service or other website.</span>
      </p>
      <h2 className="c6" id="h.5dsdn533i1x4"><span className="c0">5. Obligations and Limitations on Use</span>
      </h2>
      <p className="c2"><span className="c1">The Online Ordering System is made available for access by you in our country of establishment and we make no representation or warranty that any information, materials or functionality included in the Online Ordering System is appropriate or available for use in any jurisdiction other than our country of establishment.</span>
      </p>
      <p className="c2"><span className="c1">You agree that any information provided by you on the Online Ordering System (including your name and contact details) is true, accurate, current and complete in all respects at the time it is provided and that you will update this information when subsequently using the Online Ordering System to the extent that it changes.</span>
      </p>
      <p className="c2"><span className="c1">By using the Online Ordering System you also agree that:</span>
      </p>
      <p className="c2"><span className="c5">5.1</span><span
        className="c1">&nbsp;you will comply with the Terms of Use;</span>
      </p>
      <p className="c2"><span className="c5">5.2</span><span className="c1">&nbsp;you will not impersonate any other person or entity or use a false name or a name that you are not authorised to use on the Online Ordering System;</span>
      </p>
      <p className="c2"><span className="c5">5.3</span><span
        className="c1">&nbsp;you are 18 years of age or older;</span>
      </p>
      <p className="c2"><span className="c5">5.4</span><span className="c1">&nbsp;you will pay in full for all Products ordered by you through the Online Ordering System;</span>
      </p>
      <p className="c2"><span className="c5">5.5</span><span className="c1">&nbsp;you will not use the Online Ordering System to place any speculative or false orders;</span>
      </p>
      <p className="c2"><span className="c5">5.6</span><span className="c1">&nbsp;you will not use the Online Ordering System to research our Products or the pricing of our Products;</span>
      </p>
      <p className="c2"><span className="c5">5.7</span><span className="c1">&nbsp;you will not use any automated systems, robots or software to extract data from the Online Ordering System;</span>
      </p>
      <p className="c2"><span className="c1">You acknowledge and agree that you may not use the Online Ordering System for any unlawful purpose, or any purpose that is not authorised by Flipdish, including without limitation:</span>
      </p>
      <p className="c2"><span className="c5">5.8</span><span className="c1">&nbsp;transmitting material that constitutes a criminal offence, results in civil liability or otherwise breaches any laws, regulations or codes of practice;</span>
      </p>
      <p className="c2"><span className="c5">5.9</span><span className="c1">&nbsp;interfering with any other persons use or enjoyment of the Online Ordering System;</span>
      </p>
      <p className="c2"><span className="c5">5.10</span><span className="c1">&nbsp;damaging, disabling or impairing the operation, functionality or availability of the Online Ordering System (including by knowingly or recklessly introducing to the Online Ordering System viruses, trojans, worms, logic bombs or other material which is malicious or harmful) or attempting to gain unauthorised access to the Online Ordering System or to networks connected to it, through hacking, spoofing or any other means;</span>
      </p>
      <p className="c2"><span className="c5">5.11</span><span className="c1">&nbsp;distributing any unlawful, libellous, abusive, threatening, harmful, obscene or otherwise objectionable material on or through the Online Ordering System;</span>
      </p>
      <p className="c2"><span className="c1">You may not access the Online Ordering System for any commercial purposes (including for the purposes of copying or reproducing any material on the Online Ordering System, monitoring the availability, performance or functionality of the Online Ordering System, or for any other benchmarking or competitive purposes).</span>
      </p>
      <h2 className="c6" id="h.30u06u2bvcse"><span className="c0">6. Orders &amp; Payment</span></h2>
      <p className="c2"><span className="c1">It is your responsibility to ensure that each order for Products that you place through the Online Ordering System is correct prior to you submitting such order. All questions that you may have regarding Products displayed on, or any orders you place through, the Online Ordering System should be directed to us using the Store&quot;s contact details available on our homepage. You acknowledge that Flipdish is not able to address any such questions and you agree that you will not contact, or attempt to contact, Flipdish in relation to any such questions.</span>
      </p>
      <p className="c2"><span>Please note that any order that you place through the Online Ordering System is not accepted until you are sent an SMS confirming that we have received and accepted your order (the &quot;</span><span
        className="c5">SMS</span><span className="c1">&quot;). This means that when the Online Ordering System states that your order has been confirmed, we may still (at our discretion) choose to decline your order until such time as you are sent the SMS. If we are unable to accept your order after it has been placed through the Online Ordering System, we will contact you to confirm this to you.</span>
      </p>
      <p className="c2"><span className="c1">Please note that the inclusion of incorrect personal details by you when placing an order with us on the Online Ordering System may lead to delays in the completion of your order or mean that we are unable to complete your order. It is your responsibility to ensure that you have included your correct personal details on the Online Ordering System (including your delivery address and telephone number) and we are not liable for any delay or failure to complete your order due to the inclusion of incorrect personal details on the Online Ordering System.</span>
      </p>
      <p className="c2"><span className="c1">It is your responsibility to keep your mobile phone secure. You agree that you are responsible and liable for all orders and charges placed on the Online Ordering System via your mobile phone.</span>
      </p>
      <p className="c2"><span className="c1">Please note that where you have not made payment in full at the time of placing your order on the Online Ordering System, you will be solely responsible and liable for making all outstanding payments due in respect of your order at the time that you collect your order from us or we deliver it to you.</span>
      </p>
      <p className="c2"><span className="c1">We reserve the right at our discretion to change the Products, and the prices of Products, available on the Online Ordering System.</span>
      </p>
      <h2 className="c6" id="h.pqftnznz0a5n"><span className="c0">7. Communication</span></h2>
      <p className="c2"><span className="c1">You consent to us providing you with notices and other communications electronically either through the Online Ordering System or by other electronic means and you acknowledge that all contracts, notices, information and other communications that we provide to you electronically comply with any legal requirement that such communications be in writing.</span>
      </p>
      <h2 className="c6" id="h.kim9ut3q593"><span className="c0">8. Cancellation</span></h2>
      <p className="c2"><span className="c1">You acknowledge and agree that we may cancel an order placed by you on the Online Ordering System, either before or after acceptance of such order, where we believe that inaccurate information was provided as part of this order or where circumstances beyond our reasonable control occur which prevent or hinder completion of such order.</span>
      </p>
      <h2 className="c6" id="h.nz9xkaos9ns1"><span className="c0">9. Termination</span></h2>
      <p className="c2"><span className="c1">Each of you and us may terminate the Terms of Use immediately at any time and without notice to the other. Upon such termination, you will not be entitled or permitted to access and use the Online Ordering System. The following Clauses of the Terms of Use survive any such termination: Clauses 2, 3, 9, 10, 11 and 13.</span>
      </p>
      <h2 className="c6" id="h.e8l2qzrbkewv"><span className="c0">10. General Terms</span></h2>
      <p className="c2"><span className="c1">If any part of the Terms of Use are deemed unlawful, void or for any reason unenforceable, then that provision shall be deemed severable from the Terms of Use and shall not affect the validity and enforceability of any of the remaining provisions of the Terms of Use.</span>
      </p>
      <p className="c2"><span className="c1">No failure or delay by us to exercise any right or remedy provided hereunder or by law shall constitute a waiver of that or any other right or remedy, nor shall it prevent or restrict the further exercise of that or any other right or remedy. No single or partial exercise of such right or remedy shall prevent or restrict the further exercise of that or any other right or remedy.</span>
      </p>
      <p className="c2"><span className="c1">You agree that no partnership, employment, or agency relationship exists between you and us as a result of the Terms of Use or your use of the Online Ordering System.</span>
      </p>
      <p className="c2"><span className="c1">The Terms of Use constitute the entire agreement between you and us regarding the Online Ordering System and supersede and extinguish all prior agreements promises, assurances, warranties, representations and understandings between you and us regarding the Online Ordering System.</span>
      </p>
      <p className="c2"><span className="c1">You may not assign, novate or otherwise transfer the Terms of Use to any person without our prior written consent but you agree that we may assign, novate or otherwise transfer any of our rights or obligations under the Terms of Use to our affiliates or a party that acquires us.</span>
      </p>
      <h2 className="c6" id="h.bebl43eabjpc"><span className="c0">11. Governing Law</span></h2>
      <p className="c2"><span className="c1">The Terms of Use and any dispute or claim (including non-contractual disputes or claims) arising out of or in connection with the Terms of Use or their subject matter shall be governed by, and construed in accordance with, Irish law and any such dispute or claim shall be resolved exclusively in the courts of Ireland or in the courts of the country of our establishment.</span>
      </p>
      <p className="c3"><span className="c0"></span></p>
      <p className="c4"><span className="c5 c7">12. Contact</span></p>
      <p className="c3"><span className="c1"></span></p>
      <p className="c4"><span className="c1">All comments, questions and requests relating to your information are welcomed and should be addressed to us using the Store&quot;s contact details available on our homepage. Please also direct any complaints or legal notices to this address.</span>
      </p>
      <p className="c3"><span className="c1"></span></p>
      <p className="c4"><span className="c0">**13. Definitions**</span></p>
      <p className="c3"><span className="c1"></span></p>
      <p className="c4"><span className="c1">&quot;Tuck&quot; means Tuck Technology Ltd, a company registered in Ireland under company number 555703 and having its registered address at {company?.legal_address}</span>
      </p>
      <p className="c3"><span className="c1"></span></p>
      <p className="c4"><span className="c1">&quot;Online Order System&quot; means the technology solutions developed by Tuck which are made available to you through our website, app or other platform and which enable you to place orders with us electronically for Products;</span>
      </p>
      <p className="c3"><span className="c1"></span></p>
      <p className="c4"><span className="c0">Privacy Policy</span></p>
      <p className="c3"><span className="c1"></span></p>
      <p className="c4"><span
        className="c1">Please review our Privacy Policy which is available on the Store&rsquo;s website.</span>
      </p>
      <p className="c3"><span className="c0"></span></p>
      <p className="c4"><span className="c0">Cookie Policy</span></p>
      <p className="c3"><span className="c1"></span></p>
      <p className="c4"><span
        className="c1">Please review our Cookie Policy which is available on the Store&quot;s website.</span>
      </p>
    </div>
  );
}