import React, { useLayoutEffect, useRef, UIEvent } from "react";
import { RemoveScroll } from "react-remove-scroll";
import { MODAL_SIZES } from "../services/exports/Constants";
import ReactDOM from "react-dom";
import classnames from "classnames";
import useScreenType from "../hooks/utility/useScreenType";
import LoadingIndicator from "./loading/LoadingIndicator";
import { AnimatePresence, motion } from "framer-motion";

export type ModalPosition = "center" | "bottom";

interface Props {
  open: boolean;
  children: React.ReactNode;
  size?: MODAL_SIZES;
  fullScreen?: boolean;
  position?: ModalPosition;
  containerClassName?: string;
  className?: string;
  transparent?: boolean;
  fixedHeight?: boolean;
  hideBackgroundOverlay?: boolean;
  loading?: boolean;
  onClose?: () => void;
  onScroll?: (y: number) => void;
}

export default function CustomModal(props: Props) {
  const {
    children,
    open,
    fullScreen,
    position,
    size,
    containerClassName,
    className,
    transparent,
    hideBackgroundOverlay,
    loading,
    onClose,
    onScroll,
  } = props;

  const bodyRef = useRef<HTMLDivElement>(null);

  const { isDesktop } = useScreenType();

  const scrollEnabled: boolean = !!onScroll;

  useLayoutEffect(() => {
    if (open) {
      document.addEventListener("wheel", () => handleScroll());
    }
    return document.removeEventListener("wheel", () => handleScroll());
  }, [open]);

  function handleScroll(event?: UIEvent<HTMLDivElement>): void {
    try {
      if (!scrollEnabled) {
        return;
      }
      if (!isDesktop) {
        bodyRef.current &&
          onScroll((bodyRef.current?.getBoundingClientRect()?.y ?? 0) * -1);
        return;
      }
      onScroll(event.currentTarget.scrollTop);
    } catch (error) {}
  }

  function getModalWrapperStyle() {
    if (fullScreen) {
      return modalWrapperFullScreen;
    }

    return classnames(
      customSizeModalWrapper(size, position),
      containerClassName
    );
  }

  return open
    ? ReactDOM.createPortal(
        <RemoveScroll
          enabled={open}
          className={modalContainer(fullScreen)}
          style={{ zIndex: 4000 }}
        >
          <div
            className={classnames(modalRelativeWrapper, className)}
            style={{ height: window.innerHeight }}
          >
            {!hideBackgroundOverlay && (
              <div className={backgroundOverlay} onClick={onClose} />
            )}
            <div
              className={classnames(
                defaultModalWrapper(transparent),
                getModalWrapperStyle()
              )}
              ref={bodyRef}
              onScroll={handleScroll}
            >
              <div
                className={classnames("w-full h-full", {
                  invisible: loading,
                })}
              >
                {children}
              </div>
              <AnimatePresence>
                {loading && (
                  <motion.div
                    className="inset-0 absolute flex flex-col justify-center items-center bg-background-inkWhite-white_0"
                    initial={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2, ease: "linear" }}
                  >
                    <LoadingIndicator />
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        </RemoveScroll>,
        document.body
      )
    : null;
}

const modalContainer = (fullScreen: boolean) =>
  classnames("w-screen fixed inset-0", {
    "overflow-y-auto": fullScreen,
    "h-screen": !fullScreen,
  });

const modalRelativeWrapper = `w-full h-full relative flex flex-wrap justify-center items-center`;

const modalWrapperFullScreen = "w-full h-full relative";

const customSizeModalWrapper = (size?: MODAL_SIZES, position?: ModalPosition) =>
  classnames("max-h-95/100 overflow-clip", size ?? MODAL_SIZES.SM, {
    "rounded-t-lg fixed bottom-0": position === "bottom",
    "relative rounded-lg": position !== "bottom",
  });

const defaultModalWrapper = (transparent?: boolean) =>
  classnames("lg:overflow-y-auto", {
    "bg-transparent": transparent,
    "bg-background-inkWhite-white_1": !transparent,
  });

const backgroundOverlay = "bg-black/50 absolute inset-0";
