import useDineInFlow from "../../hooks/global/useDineInFlow";
import { useCallback, useEffect, useMemo, useState } from "react";
import useTheme from "../../hooks/ui/useTheme";
import PartiesManager from "../../services/api/PartiesManager";
import classnames from "classnames";
import PencilIcon from "../../assets/logo/PencilIcon";
import BackIcon from "../../assets/logo/BackIcon";
import ExitDineInModal from "../../modals/dine-in/ExitDineInModal";
import {useTranslation} from "react-i18next";
import useAppMethods from "../../hooks/utility/useAppMethods";

interface Props {
  className?: string;
  showExitDineInButton?: boolean;
  loading?: boolean;
}

export default function CurrentTableHeader(props: Props) {
  const { t } = useTranslation(undefined, {keyPrefix: 'Components:DineIn:CurrentTableHeader'});

  const { className, showExitDineInButton } = props;

  const theme = useTheme();
  const { table, party } = useDineInFlow();

  const [showExitDineInModal, setShowExitDineInModal] = useState(false);

  useEffect(() => {
    party?.id && PartiesManager.show(party?.id);
  }, []);

  const { toggleChangeTablePartyModal } = useAppMethods();
  const toggleExitDineInModal = () =>
    setShowExitDineInModal(!showExitDineInModal);

  const getChangeTableButtonTitle = useCallback(() => {
    if (!table?.id) {
      return t('buttons.select_table');
    }

    return t('buttons.table', {
      table: table?.number,
    });
  }, [table]);

  const renderExitDineInModal = useMemo(
    () =>
      showExitDineInModal && (
        <ExitDineInModal
          openModal={showExitDineInModal}
          toggleModal={() => setShowExitDineInModal(!showExitDineInModal)}
        />
      ),
    [showExitDineInModal]
  );

  const renderButton = ({
    onClick,
    title,
    icon,
  }: {
    onClick: () => void;
    title: string;
    icon: JSX.Element;
  }) => (
    <div onClick={onClick} className={buttonContainer}>
      <p className={buttonTitle}>{title}</p>
      {icon}
    </div>
  );

  return (
    <div className={classnames(container, className)}>
      <div className="flex justify-between">
        {renderButton({
          onClick: () => toggleChangeTablePartyModal({ openModal: true }),
          title: getChangeTableButtonTitle(),
          icon: <PencilIcon className="h-3 w-3 ml-mini" />,
        })}
        {showExitDineInButton &&
          renderButton({
            onClick: () => toggleExitDineInModal(),
            title: t('buttons.dine_in'),
            icon: (
              <BackIcon
                className="h-3 w-3 ml-mini -rotate-90"
                color={theme.color.text.default}
              />
            ),
          })}
      </div>
      {renderExitDineInModal}
    </div>
  );
}

const container = "px-small py-xsmall";

const buttonContainer =
  "h-fit p-tiny flex items-center border rounded-md border-brand-inkGrey-grey_2 bg-background-inkWhite-white_0";

const buttonTitle = "semibold tiny";
