import { createActions } from "redux-actions";

export const actionCreators = createActions({
  appStatus: {
    setPickUpDeliveryPopupSeen: undefined,
    setEnvironment: undefined,
    setCookieConsent: undefined,
  },
  basket: {
    addItem: undefined,
    removeItem: undefined,
    updateItem: undefined,
    clear: undefined,
  },
  dineInFlow: {
    setDineInStatus: undefined,
    reset: undefined,
    setTable: undefined,
    replaceTableParty: undefined,
    setParty: undefined,
    resetParty: undefined,
    closeParty: undefined,
    setOrder: undefined,
    updateOrder: undefined,
    resetOrder: undefined,
    setPaymentInfo: undefined,
    updatePaymentInfo: undefined,
    resetPaymentInfo: undefined,
    setFirstTimeDineInVisitor: undefined,
    setPaymentInfoSeen: undefined,
    setRewardInfoModalSeen: undefined,
    setPaymentNoteAccepted: undefined,
  },
  eventHelper: {
    setEvent: undefined,
    resetEvent: undefined,
  },
  initialData: {
    setAllData: undefined,
    setFranchise: undefined,
    setCompany: undefined,
    updateCompany: undefined,
    setOngoingOrders: undefined,
    addOngoingOrder: undefined,
    setCashbackAccount: undefined,
    toggleLoading: undefined,
  },
  order: {
    setOrder: undefined,
    updateOrder: undefined,
    resetOrder: undefined,
    resetScheduledTime: undefined,
  },
  paymentOptions: {
    setPaymentOptions: undefined,
    addPaymentOption: undefined,
    removePaymentOption: undefined,
    updatePaymentOption: undefined,
  },
  profile: {
    setProfile: undefined,
  },
  productLikes: {
    likeProduct: undefined,
    unlikeProduct: undefined,
  },
});
