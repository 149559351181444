function compilePrivateChannel(name: string): string {
  return `private-${name}`;
}

export function orderChannel(id: number): string {
  return compilePrivateChannel(`order-${id}`);
}

export function orderTrackingChannel(uuid: string): string {
  return `order-tracking-${uuid}`;
}

export function tableChannel(id: number): string {
  return `table-${id}`;
}

export function partyChannel(id: number): string {
  return compilePrivateChannel(`party-${id}`);
}

export function restaurantChannel(id: number): string {
  return `restaurant-${id}`;
}