import React from "react";
import CheckmarkIcon from "../../assets/logo/CheckmarkIcon";
import CloseIcon from "../../assets/logo/CloseIcon";
import useTheme from "../../hooks/ui/useTheme";
import {ORDER_FULFILLMENT_STATUSES} from "../../services/exports/Constants";
import {
  EventStatusCopyInterface,
  OrderFulfillmentEvent,
  OrderItemInterface,
  ThemeInterface,
} from "../../services/exports/Interfaces";
import {useTranslation} from "react-i18next";
import classnames from "classnames";

interface Props {
  order: OrderItemInterface | null;
  className?: string;
}

export default function OrderTimeline(props: Props) {
  const { t } = useTranslation(null, { keyPrefix: 'Components:Order:OrderTimeline' });

  const { order, className } = props;

  const theme: ThemeInterface = useTheme();

  function renderIcon(event: Partial<OrderFulfillmentEvent>) {
    function getIconBackground() {
      switch (event?.status) {
        case ORDER_FULFILLMENT_STATUSES.REJECTED:
          return theme.color.brand.danger;
        default:
          return theme.color.brand.primary;
      }
    }

    function getIcon() {
      switch (event?.status) {
        case ORDER_FULFILLMENT_STATUSES.REJECTED:
          return <CloseIcon color={theme.color.text.light} />;
        default:
          return <CheckmarkIcon color={theme.color.text.light} />;
      }
    }

    return (
      <div
        className={iconContainer}
        style={{ backgroundColor: getIconBackground() }}
      >
        {getIcon()}
      </div>
    );
  }

  const renderEvent = (event: OrderFulfillmentEvent, copy: EventStatusCopyInterface) => copy?.title && (
    <div className={itemContainer}>
      {renderIcon(event)}
      <div className={descriptionContainer}>
        <p>{copy?.title}</p>
        {copy?.description && (
          <h5 className={descriptionStyle}>{copy?.description}</h5>
        )}
      </div>
    </div>
  );

  const renderWaitingOrderConfirmation = () => {
    if (! [
      ORDER_FULFILLMENT_STATUSES.CREATED,
      ORDER_FULFILLMENT_STATUSES.REVIEWED
    ].includes(order?.status)) {
      return null;
    }

    return (
      <div className={itemContainer}>
        <div
          className={iconContainer}
          style={{backgroundColor: theme.color.brand.inkGrey.grey_2}}
        >
          <CheckmarkIcon color={theme.color.text.grey}/>
        </div>
        <div className={descriptionContainer}>
          <p>{t('waiting_confirmation.title')}</p>
          <h5 className={descriptionStyle}>{t('waiting_confirmation.description')}</h5>
        </div>
      </div>
    );
  };

  return (
    <div className={classnames(container, className)}>
      {order?.fulfillment_events?.map(
        (event: OrderFulfillmentEvent) => (
          <div key={`order-event-${event.status}`}>
            {renderEvent(event, {
              title: t(`status.${event.status}.title`, { defaultValue: null }),
            })}
          </div>
        )
      )}
      {renderWaitingOrderConfirmation()}
    </div>
  );
}

const container =
  "w-full bg-background-inkWhite-white_1 shadow rounded-md border border-brand-inkGrey-grey_2";

const iconContainer = "w-8 h-8 rounded-full flex justify-center items-center my-auto";

const itemContainer = "flex flex-row justify-between p-small";

const descriptionContainer = "px-xsmall flex flex-col flex-1 my-auto";

const descriptionStyle = "text-brand-text-grey mini mt-tiny";
