import {useMemo} from "react";
import useBasket from "../basket/useBasket";

export default function useCheckoutThreshold() {
  const { total } = useBasket();

  const hasReachedCheckoutThreshold = useMemo(
      () => total === 0 || total >= 1,
      [total]
  );

  return {
    hasReachedCheckoutThreshold,
  };
}
