export default {
  status: {
    open: 'Open',
    closed: 'Closed',
  },
  offline_until: "Offline bis",
  online_orders_paused: "Online Bestellungen pausiert",
  online_orders_paused_until: "Online Bestellungen pausiert bis",
  reopens: "Wieder geöffnet",
  opens_today: "Öffnet heute um",
  opens: "Öffnet",
  open: "Geöffnet",
  open_whole_day: 'Ganztags geöffnet',
  outside_ordering_times: 'Außerhalb der Bestellzeiten',
};
