import {useState, useRef, useEffect, useCallback, useMemo, ReactElement} from "react";
import styled from "@emotion/styled";
import { Box, LinearProgress, linearProgressClasses } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import GoogleReviewCard from "../assets/images/GoogleReviewCard.png";
import CloseIcon from "../assets/logo/CloseIcon";
import GoogleIcon from "../assets/logo/GoogleIcon";
import ScreenShotIcon from "../assets/logo/ScreenShotIcon";
import CustomButton from "../components-library/buttons/CustomButton";
import ProductsCarousel from "../components-library/carousel/ProductsCarousel";
import ButtonFooter from "../components-library/footers/ButtonFooter";
import LoadingWithText from "../components-library/loading/LoadingWithText";
import CustomNavBar from "../components-library/navigation/CustomNavBar";
import RatingStars from "../components-library/review/RatingStars";
import TextInput from "../components-library/inputs/TextInput";
import useDineInFlow from "../hooks/global/useDineInFlow";
import useGooglePace from "../hooks/google-review/useGooglePlace";
import useGoogleReview from "../hooks/google-review/useGoogleReview";
import useGoogleReviewSuggestedProducts from "../hooks/google-review/useGoogleReviewSuggestedProducts";
import useScreenType from "../hooks/utility/useScreenType";
import ConfirmCancelModal from "../modals/ConfirmCancelModal";
import PromotionManager from "../services/api/PromotionManager";
import { SCREENS } from "../services/exports/Constants";
import {ButtonTypes} from "../services/exports/Interfaces";
import useTheme from "../hooks/ui/useTheme";
import Theme from "../components-library/Theme";
import useAlert from "../hooks/utility/useAlert";
import classnames from "classnames";
import Divider from "../components-library/Divider";
import LoadingPage from "../components-library/loading/LoadingPage";
import useInitialData from "../hooks/global/useInitialData";
import useNavigate from "../hooks/navigation/useNavigate";
import PageLayout from "../components-library/layouts/PageLayout";
import {StoreInterface} from "../store/types";
import useLoyaltyProgram from "../hooks/loyalty/useLoyaltyProgram";

interface LocationStateInterface {
  prevPage: SCREENS;
  rating?: number;
  googleReviewHeaderTitle?: string;
}

enum PAGES {
  GOOGLE_REVIEW = 0,
  UPLOAD_SCREEN_SHOT = 1,
}

export default function UploadGoogleReviewScreen(): JSX.Element {
  const { t } = useTranslation(null, { keyPrefix: "Pages:UploadGoogleReviewScreen" });

  const { company } = useSelector((state: StoreInterface) => state.initialData);

  const theme = useTheme();
  const { isDesktop } = useScreenType();
  const { navigate } = useNavigate();
  const location = useLocation();
  const { isDineIn } = useDineInFlow();
  const { getPlace } = useGooglePace();
  const alert = useAlert();
  const { hasLoyaltyPromotion } = useLoyaltyProgram();
  const {
    getTitle,
    message: ratingMessage,
    suggestedProducts,
    loading,
  } = useGoogleReviewSuggestedProducts({ autoLoad: true });
  const {
    hasUploadedGoogleReview,
    loading: loadingGoogleReview,
    throwGoogleReviewUploadedToast,
  } = useGoogleReview();

  const locationState: LocationStateInterface = location?.state;
  const rating = locationState?.rating ?? 5;
  const googleReviewHeaderTitle = locationState?.googleReviewHeaderTitle;
  const prevPage = locationState?.prevPage;

  const { refresh } = useInitialData();

  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const [page, setPage] = useState<PAGES>(PAGES.GOOGLE_REVIEW);
  const [screenShotFile, setScreenShotFile] = useState<null | Blob>(null);
  const [name, setName] = useState<string>("");
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [uploading, setUploading] = useState<boolean>(false);
  const [processingReview, setProcessingReview] = useState<boolean>(false);
  const [showNameMissingAlert, setShowNameMissingAlert] = useState<boolean>(false);

  const inputFile = useRef<HTMLInputElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);
  const stepsRef = useRef<HTMLDivElement>(null);
  const isDineInRef = useRef(isDineIn);
  isDineInRef.current = isDineIn;

  useEffect(() => {
    setTimeout(() => setInitialLoading(false), 1000);
  }, []);

  useEffect(() => {
    if (!loading && !initialLoading && !loadingGoogleReview) {
      setTimeout(
        () =>
          stepsRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "start",
          }),
        1000
      );
    }
  }, [loading, initialLoading, loadingGoogleReview]);

  function openGoogleReviewPage(): void {
    company?.google_places_id &&
      window.open(getPlace(company.google_places_id), "_blank");
  }

  function openFiles(): void {
    if (hasUploadedGoogleReview) {
      return alert.error({
        description: t("error_messages.review_already_uploaded"),
      });
    }
    inputFile.current.click();
  }

  function validateBeforeUpload() {
    if (!name) {
      return toggleNameAlert();
    }
    uploadScreenShot();
  }

  function toggleNameAlert(): void {
    setShowNameMissingAlert(!showNameMissingAlert);
  }

  function goBack(state?: Object): void {
    if (typeof prevPage === "string") {
      return navigate(prevPage, {
        absolute: true,
        state: {
          ...state,
          prevPage: location.pathname,
        },
      });
    }

    return navigate(
      isDineInRef.current ? SCREENS.DINE_IN : SCREENS.HOME_SCREEN,
      {
        state: {
          ...state,
          prevPage: location.pathname,
        },
      }
    );
  }

  function scrollToTop(): void {
    bodyRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  }

  async function uploadScreenShot(): Promise<void> {
    let data = new FormData();
    data.append("name", name);
    data.append("screenshot", screenShotFile);

    setUploading(true);
    const { response, success } = await new PromotionManager().uploadGoogleReview(
      company?.id,
      data,
      onUploadProgress,
    );
    setUploading(false);

    if (success) {
      return uploadSuccessHandler();
    }

    uploadErrorHandler(response);
  }

  function onUploadProgress(progress: number) {
    setUploadProgress(progress);
  }

  async function uploadSuccessHandler(): Promise<void> {
    setProcessingReview(true);
    const { response, success } = await refresh(null, true);
    setTimeout(() => {
      setProcessingReview(false);
      goBack({
        review: true,
        rating,
      });
      if (success) {
        const company = response?.data?.company;
        throwGoogleReviewUploadedToast({
          rewards: company?.rewards,
          rewardBalance: company?.reward_balance,
        });
      }
    }, 3000);
  }

  function uploadErrorHandler(error: any): void {
    switch (error?.response?.status) {
      case 403:
        return alert.error({
          description: t("error_messages.review_already_uploaded"),
        });
      default:
        return alert.error({
          description: t("error_messages.upload_failed"),
        });
    }
  }

  function onChangeFile(event: any): void {
    event.stopPropagation();
    event.preventDefault();
    setScreenShotFile(event.target.files[0]);
    setInitialLoading(true);
    setPage(PAGES.UPLOAD_SCREEN_SHOT);
    setTimeout(() => {
      setInitialLoading(false);
    }, 1000);
  }

  const getScreenShotUrl = useCallback<() => string>(() => {
    try {
      return URL.createObjectURL(screenShotFile);
    } catch (error) {
      return "";
    }
  }, [screenShotFile]);

  function renderSeparator(): JSX.Element {
    return <div className={separatorContainer} />;
  }

  const renderHeaderBar = (): JSX.Element => {
    function getTitle(): string {
      if (page === PAGES.GOOGLE_REVIEW) {
        if (googleReviewHeaderTitle) {
          return googleReviewHeaderTitle;
        }

        return t("header.review_us");
      }

      return t("header.upload");
    }

    function getBackIconTitle(): string {
      if (page === PAGES.GOOGLE_REVIEW) {
        return t("header.not_now");
      }

      return "";
    }

    function onLeftClick(): void {
      if (page === PAGES.GOOGLE_REVIEW) {
        return goBack();
      }

      setPage(PAGES.GOOGLE_REVIEW);
    }

    return (
      <CustomNavBar
        title={getTitle()}
        backIconTitle={getBackIconTitle()}
        rightClick={goBack}
        leftClick={onLeftClick}
        hideCloseIcon={true}
        showCompanyLogo
      />
    );
  };

  const renderLoadingPage = useMemo<JSX.Element>(() => {
    return initialLoading || loading || loadingGoogleReview ? (
      <LoadingPage />
    ) : null;
  }, [initialLoading, loading, loadingGoogleReview]);

  const renderProcessingReview = useMemo<JSX.Element>(() => {
    return processingReview ? (
      <LoadingWithText description={t("loading.processing")} />
    ) : null;
  }, [processingReview]);

  const renderNameMissingAlert = useMemo(() => {
    function onConfirm(): void {
      scrollToTop();
      toggleNameAlert();
    }
    return (
      <ConfirmCancelModal
        openModal={showNameMissingAlert}
        description={t("error_messages.name_missing.description")}
        primaryButtonTitle={t("error_messages.name_missing.button.title")}
        customGradient={
          "bg-gradient-to-r from-companyBrand-primary via-companyBrand-primary to-companyBrand-primary"
        }
        onConfirm={onConfirm}
      />
    );
  }, [showNameMissingAlert]);

  const renderRatings = (): ReactElement =>
    rating && (
      <div>
        <div className="w-full mb-medium flex justify-center">
          <RatingStars
            rating={rating}
            starCount={rating}
            disabled
            starProps={{
              iconClassName: "w-[26px] h-[25px]",
              fillColor: theme.color.companyBrand?.primary,
            }}
          />
        </div>
        {getTitle('text-center')}
      </div>
    );

  function renderProductSuggestions(): ReactElement {
    if (!hasLoyaltyPromotion) {
      return null;
    }

    return (
      suggestedProducts?.length > 0 && (
        <div className="mt-small">
          <ProductsCarousel products={suggestedProducts}/>
        </div>
      )
    );
  }

  interface StepInterface {
    icon: JSX.Element;
    title: string;
    description: string;
    button: JSX.Element;
    children?: JSX.Element;
  }

  function renderSteps(): JSX.Element {
    let steps: StepInterface[] = [
      {
        icon: <GoogleIcon />,
        title: t("steps.step_1.title"),
        description: t("steps.step_1.description", {
          name: company?.name,
        }),
        button: renderGoogleReviewButton(),
      },
      {
        icon: (
          <ScreenShotIcon
            color={Theme.color.text.default}
            className="w-4 h-4"
          />
        ),
        title: t("steps.step_2.title"),
        description: t("steps.step_2.description"),
        button: renderUploadScreenShotButton(),
        children: (
          <img
            src={GoogleReviewCard}
            alt={"GoogleReviewCard"}
            className={googleReviewCardStyle}
          />
        ),
      },
    ];

    return (
      <div className="mt-medium">
        <h6>{t("steps.how_it_works")}</h6>
        {steps?.map((step: StepInterface, index: number) => (
          <div
            className={classnames("py-medium", {
              "border-t": steps?.length - 1 === index,
            })}
          >
            <div className={stepItemContainer} key={index?.toString()}>
              <div className={stepIconContainer}>{step?.icon}</div>
              <div>
                <h6 className={stepItemTitle}>
                  {step?.title} {index + 1}
                </h6>
                <p className={stepItemDescription}>{step?.description}</p>
              </div>
            </div>
            {step?.children && <div className="mt-small">{step?.children}</div>}
            <div className="mt-small">{step?.button}</div>
          </div>
        ))}
      </div>
    );
  }

  function renderReviewOnGooglePage(): JSX.Element {
    return (
      <div className={wrapper}>
        {renderRatings()}
        {renderProductSuggestions()}
        <div className="mt-medium">
          <Divider size="large" />
        </div>
        <div ref={stepsRef}>{renderSteps()}</div>
      </div>
    );
  }

  function renderEnterPostedName(): JSX.Element {
    return (
      <div className={enterPostedNameContainer}>
        <h4>{t("enter_name.title")}</h4>
        {renderSeparator()}
        <TextInput
          value={name}
          onChange={setName}
          placeholder={t("enter_name.place_holder")}
          inputContainerStyle="bg-brand-text-disabled"
        />
      </div>
    );
  }

  function renderScreenShot(): JSX.Element {
    return (
      <div>
        {renderInputFile()}
        <h4>{t("screen_shot_uploaded.title")}</h4>
        {renderSeparator()}
        <p className={uploadScreenShotDescriptionStyle}>
          {t("screen_shot_uploaded.description")}
        </p>
        {renderSeparator()}
        <div className={screenShotContainer}>
          <img
            src={getScreenShotUrl()}
            alt="screenShot"
            className={screenShotStyle}
          />
          <button className={closeIconContainer} onClick={openFiles}>
            <CloseIcon color={Theme.color.text.light} />
          </button>
        </div>
      </div>
    );
  }

  function uploadReviewPage(): JSX.Element {
    return (
      <>
        <div className={wrapper}>
          {renderEnterPostedName()}
          {renderScreenShot()}
        </div>
        {renderSubmitScreenShotButton()}
      </>
    );
  }

  function renderInputFile(): JSX.Element {
    return (
      <input
        type="file"
        accept="image/png, image/jpeg"
        id="file"
        ref={inputFile}
        style={{ display: "none" }}
        onChange={onChangeFile}
      />
    );
  }

  const renderGoogleReviewButton = (): JSX.Element => {
    const renderGoogleIcon = (): JSX.Element => {
      return (
        <div className={iconContainer}>
          <GoogleIcon className={googleIcon} />
        </div>
      );
    };

    return (
      <CustomButton
        title={t("buttons.leave_google_review")}
        loading={loading}
        buttonType={ButtonTypes.secondary}
        customIcon={renderGoogleIcon()}
        onClick={openGoogleReviewPage}
        containerStyle={customButtonContainerStyle}
        fontStyle={customButtonTitleStyle}
      />
    );
  };

  const renderUploadScreenShotButton = (): JSX.Element => {
    const renderScreenShotIcon = (): JSX.Element => {
      return (
        <div className={iconContainer}>
          <ScreenShotIcon
            className={screenShotIcon}
            color={Theme.color.text.light}
          />
        </div>
      );
    };
    return (
      <>
        {renderInputFile()}
        <CustomButton
          title={t("buttons.upload_screenshot")}
          loading={loading}
          customIcon={renderScreenShotIcon()}
          onClick={openFiles}
          containerStyle={customButtonContainerStyle}
          fontStyle={customButtonTitleStyle}
        />
      </>
    );
  };

  function renderSubmitScreenShotButton(): JSX.Element {
    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
      height: 8,
      borderRadius: 5,
      [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: Theme.color.text.disabled,
      },
      [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: Theme.color.companyBrand.primary,
      },
    }));

    return (
      <ButtonFooter className={buttonContainer}>
        <div className="lg:w-1/2 w-full">
          {uploading && (
            <div className={progressBarContainer}>
              <Box sx={{ width: "100%" }}>
                <BorderLinearProgress
                  variant="determinate"
                  value={uploadProgress}
                />
              </Box>
            </div>
          )}
          <CustomButton
            title={t("buttons.submit_screenshot")}
            loading={uploading}
            onClick={validateBeforeUpload}
          />
        </div>
      </ButtonFooter>
    );
  }

  function renderBody(): JSX.Element {
    switch (page) {
      case PAGES.GOOGLE_REVIEW:
        return renderReviewOnGooglePage();
      case PAGES.UPLOAD_SCREEN_SHOT:
        return uploadReviewPage();
    }
  }

  return (
    <PageLayout header={renderHeaderBar()}>
      <>
        <div className={container} ref={bodyRef}>
          {renderBody()}
          {renderProcessingReview}
          {renderLoadingPage}
          {renderNameMissingAlert}
        </div>
      </>
    </PageLayout>
  );
}

const container = "h-full w-full bg-background-inkWhite-white_1 relative";

const wrapper =
  "lg:w-1/2 w-full mx-auto lg:px-none px-small pt-small pb-[100px] h-fit bg-background-inkWhite-white_1";

const buttonContainer =
  "w-full flex flex-col justify-center items-center lg:px-none px-small lg:sticky fixed bottom-0";

const separatorContainer = "mb-small";

const progressBarContainer = "mb-mini w-full";

const iconContainer = "mr-mini";

const stepItemContainer = "flex flex-row";

const stepIconContainer =
  "rounded-full bg-background-inkWhite-white_0 shadow-icon h-[33px] aspect-square flex justify-center items-center mr-small";

const googleIcon = "w-5 h-5";

const screenShotIcon = "w-5 h-6";

const googleReviewCardStyle = "lg:w-8/12 w-full";

const enterPostedNameContainer = "mb-large";

const screenShotContainer =
  "w-full bg-brand-text-disabled rounded-2xl relative ";

const screenShotStyle = "w-full h-full object-cover";

const closeIconContainer =
  "w-[36px] h-[36px] rounded-full bg-brand-secondary flex justify-center items-center absolute -top-1 -right-2 shadow-icon";

const customButtonContainerStyle = "py-xsmall";

const suggestionTitleStyle = "font-normal mb-tiny text-center";

const suggestionDescriptionStyle = "text-companyBrand-primary text-center";

const stepItemTitle = "mb-tiny mini semibold";

const stepItemDescription = "mini text-brand-text-grey";

const uploadScreenShotDescriptionStyle = "mini text-brand-text-grey";

const customButtonTitleStyle = "mini semibold";
