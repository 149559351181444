import React from "react";

interface Props {
  className?: string;
  style?: Object;
  color?: string;
}

export default function EmptyCartIcon(props: Props) {
  const { className, color, style } = props;
  return (
    <svg
      className={className ?? "h-4 w-4"}
      viewBox="0 0 86 86"
      fill="none"
      style={style}
    >
      <path
        d="M43.1875 85.854C66.4648 85.854 85.7458 66.6142 85.7458 43.2957C85.7458 20.0184 66.4648 0.737427 43.1463 0.737427C19.869 0.737427 0.629211 20.0184 0.629211 43.2957C0.629211 66.6142 19.9102 85.854 43.1875 85.854ZM36.0601 57.4269C32.2698 57.4269 30.1275 55.6141 29.5919 52.1946L26.3784 29.8237H20.075C18.6743 29.8237 17.6443 28.7938 17.6443 27.393C17.6443 25.951 18.6743 24.9211 20.075 24.9211H27.2024C29.2623 24.9211 30.7455 26.157 30.9927 28.0522L31.3635 30.6065H64.652C66.0528 30.6065 66.8768 31.4305 66.8768 32.6664C66.8768 32.8312 66.8356 33.202 66.7944 33.4904L65.1876 44.2433C64.7344 47.4156 63.0453 49.3519 59.8318 49.3519H34.1238L34.3298 50.9175C34.4946 52.1122 35.1949 52.7714 36.3485 52.7714H58.8842C60.2438 52.7714 61.2325 53.6778 61.2325 55.0786C61.2325 56.5205 60.2438 57.4269 58.8842 57.4269H36.0601ZM36.9253 67.8914C34.7418 67.8914 33.0526 66.2022 33.0526 64.0187C33.0526 61.8351 34.7418 60.1048 36.9253 60.1048C39.1088 60.1048 40.8392 61.8351 40.8392 64.0187C40.8392 66.2022 39.1088 67.8914 36.9253 67.8914ZM55.4647 67.8914C53.3224 67.8914 51.5096 66.2022 51.5096 64.0187C51.5096 61.8351 53.3224 60.1048 55.4647 60.1048C57.6483 60.1048 59.3786 61.8351 59.3786 64.0187C59.3786 66.2022 57.6483 67.8914 55.4647 67.8914Z"
        fill="#5F6974"
      />
    </svg>
  );
}
