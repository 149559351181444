import {DropoffAction} from '../../../../../../services/exports/Constants';

export default {
  custom_button: {
    continue: 'Weiter',
  },
  fields: {
    dropoff_action: {
      label: 'Abgabemöglichkeiten',
      options: {
        [DropoffAction.RingDoorBell]: 'Tür klingeln',
        [DropoffAction.Sms]: 'Kontaktiere mich per SMS bei Ankunft',
      },
    },
    door_name: {
      placeholder: 'Türklingelname (optional)',
    },
    company_name: {
      placeholder: 'Firmenname (optional)',
    },
    delivery_note: {
      placeholder: 'Lieferanweisungen hinzufügen z.B. bitte anrufen bei der Ankunft (optional)',
    },
    order_instruction: {
      placeholder: 'Bestellanweisungen eingeben...',
    },
  },
  error_messages: {
    update_profile_unsuccessful: 'Deine persönlichen Daten konnten nicht aktualisiert werden',
  },
};
