import AccordionSummary from "@mui/material/AccordionSummary";
import ChevronDownIcon from "../../assets/logo/ChevronDownIcon";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import React, {useMemo, useState} from "react";
import useDineInFlow from "../../hooks/global/useDineInFlow";
import {OrderPaymentInterface} from "../../services/exports/Interfaces";
import classnames from "classnames";
import {useTranslation} from "react-i18next";
import useAppMethods from "../../hooks/utility/useAppMethods";

interface Props {
  className?: string;
}

export default function PaymentsBreakdown(props: Props) {
  const { t } = useTranslation(undefined, {keyPrefix: 'Components:DineIn:PaymentsBreakdown'});

  const { className} = props;

  const { payments } = useDineInFlow();
  const { formatCurrency } = useAppMethods();

  const [isCollapsed, setIsCollapsed] = useState(true);
  const toggleIsCollapsed = () => setIsCollapsed(!isCollapsed);

  const renderPayments = useMemo(() => {
    if (isCollapsed) {
      return null;
    }

    return payments.toArray().map((item: OrderPaymentInterface) => (
      <div className="mt-small">
        <div
          className="flex justify-between"
          key={`order-payment-${item?.id}`}
        >
          <p
            className={classnames('text-mini', {'text-brand-inkGreen-green_2': item?.refunds?.length > 0})}
          >{item?.user?.first_name}</p>
          <p
            className={classnames('text-mini', {'line-through text-brand-inkGreen-green_2': item?.refunds?.length > 0})}
          >{formatCurrency(item?.total_amount)}</p>
        </div>
        {item.refunds?.map((refund) => (
          <div
            className="flex justify-between mt-tiny"
            key={`order-payment-${refund?.id}`}
          >
            <p className="text-tiny text-brand-inkGreen-green_2">{t('labels.refunded')}</p>
            <p className="text-tiny text-brand-inkGreen-green_2">+{formatCurrency(refund?.total_amount)}</p>
          </div>
        ))}
      </div>
    ));
  }, [isCollapsed, payments]);

  return (
    <Accordion
      style={{
        boxShadow: 'none',
        backgroundColor: 'transparent',
      }}
      sx={{
        '&:before': {
          display: 'none',
        }
      }}
      disableGutters
      elevation={0}
      expanded={!isCollapsed}
      onChange={toggleIsCollapsed}
      className={className}
    >
      <AccordionSummary
        expandIcon={(
          <ChevronDownIcon className="h-3 w-3"/>
        )}
        style={{
          margin: 0,
          padding: 0,
          minHeight: 0,
        }}
        classes={{
          content: '!m-none'
        }}
      >
        <p className="text-mini my-auto">{isCollapsed ? t('buttons.show_payments') : t('buttons.hide_payments')}</p>
      </AccordionSummary>
      <AccordionDetails
        style={{
          margin: 0,
          padding: 0,
        }}
      >
        {renderPayments}
      </AccordionDetails>
    </Accordion>
  );
}