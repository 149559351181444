import MinimumValueErrorLabel from "../labels/MinimumValueErrorLabel";
import classnames from "classnames";
import useCheckoutThreshold from "../../hooks/order/useCheckoutThreshold";
import {useTranslation} from "react-i18next";
import useAppMethods from "../../hooks/utility/useAppMethods";

interface Props {
  className?: string;
}

export default function CheckoutThreshold(props: Props) {
  const {t} = useTranslation(null, {keyPrefix: 'Components:Checkout:CheckoutThreshold'});

  const { className } = props;

  const { formatCurrency } = useAppMethods();
  const { hasReachedCheckoutThreshold } = useCheckoutThreshold();

  return !hasReachedCheckoutThreshold && (
    <div className={classnames(container, className)}>
      <MinimumValueErrorLabel
        description={t('title', {
          rangeStart: formatCurrency(0.01),
          rangeEnd: formatCurrency(0.99),
        })}
        value={formatCurrency(1)}
        type="Notice"
      />
      <h5 className={h5Style}>
        {t('description', {
          amount: formatCurrency(0),
        })}
      </h5>
    </div>
  );
}

const container = "bg-background-inkWhite-white_1";

const h5Style = "mt-mini tiny";
