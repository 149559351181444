export default {
  header: "Vielen Dank",
  titles: {
    rate_experience: "Wie war deine Erfahrung?",
    leave_google_review: "Screenshot deiner Bewertung hochladen",
    receipt_sent: "Quittung gesendet",
  },
  text: {
    not_all_paid:
      "Vielen Dank {{name}}! Bitte stelle sicher, dass der gesamte Tisch bezahlt ist, bevor du gehst.",
    all_paid:
      "Du kannst gehen 😊 Hoffentlich sehen wir dich bald wieder {{name}}!",
    waiter_knows_the_bill_is_paid:
      "Unser Personal weiß, dass die Rechnung bezahlt ist. Du kannst gehen oder noch was hier bleiben 😊",
    receipt_sent: "Wir haben dir die Quittung bereits per E-Mail gesendet.",
  },
  labels: {
    you_paid: "Du hast bezahlt {{amount}}",
    left_to_pay: "Restbetrag",
  },
  buttons: {
    continue: "Weiter",
  },
  feedback: {
    general_error: "Es ist ein Fehler aufgetreten, bitte laden die Seite neu",
  },
  toasts: {
    rate_experience:
      "Wie hat es dir gefallen? Bitte wähle unten eine Bewertung aus 😊",
    table_reset:
      'Vielen Dank für deinen Besuch! Wir "räumen" deinen Tisch automatisch ab, wenn alle Bestellungen bezahlt sind. Wenn du mehr bestellen möchtest, kannst du das gerne tun 😋',
  },
};
