import {useEffect, useMemo, useRef, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import CompanyDescription from "../components-library/company/CompanyDescription";
import LoadingPage from "../components-library/loading/LoadingPage";
import CheckoutNavBar from "../components-library/navigation/CheckoutNavBar";
import Section from "../components-library/Section";
import OrderManager from "../services/api/OrderManager";
import {
  DeliveryProvider,
  ORDER_FULFILLMENT_STATUSES,
  ORDER_METHODS,
  PUSHER_EVENTS,
  SCREENS,
  TaxBehaviour,
} from "../services/exports/Constants";
import {actionCreators} from "../store/actions";
import {LineItemInterface, OrderItemInterface,} from "../services/exports/Interfaces";
import ReduxActions from "../store/ReduxActions";
import {orderTrackingChannel} from "../services/pusher/Channels";
import {useSelector} from "react-redux";
import useAlert from "../hooks/utility/useAlert";
import RatingStars from "../components-library/review/RatingStars";
import RewardsUnlockedModal from "../modals/RewardsUnlockedModal";
import PointsEarnedModal from "../modals/PointsEarnedModal";
import collect from "collect.js";
import useGoogleReview from "../hooks/google-review/useGoogleReview";
import RewardStatusResource from "../services/resources/RewardStatusResource";
import ProfileManager from "../services/api/ProfileManager";
import useFocus from "../hooks/utility/useFocus";
import usePusher from "../hooks/global/usePusher";
import useToast from "../hooks/utility/useToast";
import {useTranslation} from "react-i18next";
import {FeedbackLabelTypes} from "../services/exports/Types";
import OrderRatesManager from "../services/api/OrderRatesManager";
import useNavigate from "../hooks/navigation/useNavigate";
import PageLayout from "../components-library/layouts/PageLayout";
import useBasket from "../hooks/basket/useBasket";
import OrderLineItem from "../components-library/order/OrderLineItem";
import {StoreInterface} from "../store/types";
import useAppMethods from "../hooks/utility/useAppMethods";
import AddressHelpers from "../services/helpers/AddressHelpers";
import OrderTracker from "../components-library/order/OrderTracker";
import useGoogleAnalytics from "../hooks/analytics/useGoogleAnalytics";
import CashbackEarnedModal from "../modals/CashbackEarnedModal";

interface LocationStateInterface {
  prevPage?: string;
  review?: boolean;
  afterPurchase?: boolean;
}

export default function OrderDetailsScreen() {
  const { t, i18n } = useTranslation(null, { keyPrefix: 'Pages:OrderDetailsScreen' });

  const { company } = useSelector((state: StoreInterface) => state.initialData);
  const { profile } = useSelector((state: StoreInterface) => state.profile);

  const location = useLocation();
  const { navigate } = useNavigate();
  const toast = useToast();
  const alert = useAlert();
  const { uuid } = useParams();

  const locationState: LocationStateInterface = location.state;

  const { formatCurrency, formatTime, saveMenu } = useAppMethods();
  const { clearBasket } = useBasket();
  const { trackPurchase } = useGoogleAnalytics();
  const {
    loading: loadingGoogleReview,
    hasGooglePromotion,
    hasUploadedGoogleReview,
  } = useGoogleReview();

  const [order, setOrder] = useState<OrderItemInterface | null>(null);

  const [screenInitialized, setScreenInitialized] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showCashbackEarnedModal, setShowCashbackEarnedModal] = useState(false);
  const [showRewardsUnlockedModal, setShowRewardsUnlockedModal] = useState(false);
  const [showPointsEarnedModal, setShowPointsEarnedModal] = useState(false);
  const [rewardsEvaluated, setRewardsEvaluated] = useState(false);
  const [rating, setRating] = useState(0);
  const [showRating, setShowRating] = useState(false);

  const orderRef = useRef(order);
  orderRef.current = order;

  const screenInitializedRef = useRef(screenInitialized);
  screenInitializedRef.current = screenInitialized;

  const { isConnected, connect, disconnect } = usePusher();

  useEffect(() => {
    window.scrollTo(0, 0);
    loadCashbackAccount();
  }, []);

  useEffect(
    () => {
      if (!!order?.id) {
        trackPurchase(order.payments[0], order)
      }
    },
    [order?.id]
  );

  useFocus(
    () => initData(false),
    () => null,
    true
  );

  useEffect(() => {
    initData();

    return disconnect;
  }, []);

  useEffect(() => {
    setShowRating(
      hasGooglePromotion &&
        !hasUploadedGoogleReview &&
        profile?.orders_count > 1
    );
  }, [hasGooglePromotion, hasUploadedGoogleReview, profile?.orders_count]);

  useEffect(() => {
    if (
      screenInitializedRef.current &&
      showRating &&
      rewardsEvaluated &&
      !showPointsEarnedModal &&
      !showRewardsUnlockedModal
    ) {
      setTimeout(() => toast(t("toast_messages.share_experience")), 500);
    }
  }, [
    screenInitializedRef.current,
    showRating,
    rewardsEvaluated,
    showPointsEarnedModal,
    showRewardsUnlockedModal,
  ]);

  async function initData(withLoading: boolean = true) {
    requestOrder(withLoading);

    if (!isConnected()) {
      return connectPusher();
    }
  }

  async function requestOrderInfo(
    callback?: (data: OrderItemInterface) => void,
    errorCallback?: () => void
  ) {
    const { response, success } = await OrderManager.getOrder(uuid);

    if (success) {
      return callback && callback(response?.data?.data);
    }

    return errorCallback && errorCallback();
  }

  async function requestOrder(withLoading: boolean = true) {
    withLoading && setLoading(true);
    await requestOrderInfo(successHandler, errorHandler);
    await ProfileManager.getProfile();
    setScreenInitialized(true);
    withLoading && toggleLoading();

    resetHomeScreenScrollPosition();
    scrollToTop();
  }

  async function loadCashbackAccount() {
    await ProfileManager.getCashbackAccount(company?.id);
  }

  function toggleLoading() {
    setTimeout(() => setLoading(false), 250);
  }

  function successHandler(response: OrderItemInterface) {
    setOrder(response);
    !rating &&
      setRating(
        collect(response?.rates).firstWhere("user_id", profile?.id)?.rate
      );

    if (locationState?.afterPurchase) {
      ReduxActions.batchActions([
        actionCreators.initialData.addOngoingOrder(response),
        actionCreators.order.resetOrder(profile),
        actionCreators.initialData.setCompany({
          ...company,
          rewards: response?.company?.rewards,
          reward_balance: response?.company?.reward_balance,
        }),
      ]);
      initCashbackModal();
      initRewardsModal();
      clearBasket();
    }
  }

  function initCashbackModal(): void {
    if (orderRef.current?.cashback_account_operations?.length < 1) {
      return;
    }

    setShowCashbackEarnedModal(true);
  }

  function initRewardsModal(): void {
    if (!screenInitializedRef.current) {
      setTimeout(() => {
        new Promise((resolve) => {
          if (
            locationState?.prevPage !== SCREENS.UPLOAD_GOOGLE_REVIEW_SCREEN ||
            locationState?.review
          ) {
            toggleRewardsModal();
          }
          resolve(true);
        }).then(() => setRewardsEvaluated(true));
      }, 500);
    }
  }

  function errorHandler() {
    alert.error({
      description: t("error_messages.failed_to_load"),
    });
  }

  async function connectPusher() {
    const pusher = await connect();
    pusher
      .subscribe(orderTrackingChannel(uuid))
      .bind(PUSHER_EVENTS.ORDER_UPDATED, pusherHandler);
  }

  function pusherHandler(data: OrderItemInterface) {
    requestOrderInfo(orderUpdatedHandler, errorHandler);
  }

  function orderUpdatedHandler(data: OrderItemInterface) {
    function getToastConfig(): {
      message: string;
      type?: FeedbackLabelTypes;
    } {
      if (
        orderRef.current?.status === data.status &&
        data.estimated_pickup_time !== orderRef.current?.estimated_pickup_time
      ) {
        return {
          message: t("order_status_toast.order_preparation_up_time_changed", {
            number: data.number,
            type:
              data.method === ORDER_METHODS.PICKUP
                ? t("order_type.pickup")
                : t("order_type.delivery"),
            time: formatTime(data.estimated_pickup_time),
          }),
        };
      }

      switch (data?.status) {
        case ORDER_FULFILLMENT_STATUSES.REVIEWED:
          return {
            message: t("order_status_toast.order_reviewed", {
              number: data.number,
            }),
          };
        case ORDER_FULFILLMENT_STATUSES.ACCEPTED:
          return {
            message: t("order_status_toast.order_confirmed", {
              number: data.number,
              time: formatTime(data.estimated_pickup_time),
            }),
            type: "Success",
          };
        case ORDER_FULFILLMENT_STATUSES.PREPARING:
          return {
            message: t("order_status_toast.order_preparing", {
              number: data.number,
            }),
          };
        case ORDER_FULFILLMENT_STATUSES.REJECTED:
          return {
            message: t("order_status_toast.order_rejected", {
              number: data.number,
            }),
            type: "Error",
          };
        default:
          return {
            message: t("order_status_toast.order_preparation_up_time_changed", {
              number: data.number,
              type:
                data.method === ORDER_METHODS.PICKUP
                  ? t("order_type.pickup")
                  : t("order_type.delivery"),
              time: formatTime(data.estimated_pickup_time),
            }),
          };
      }
    }

    if (
      data?.status !== orderRef.current?.status ||
      data.estimated_pickup_time !== orderRef.current?.estimated_pickup_time
    ) {
      const config = getToastConfig();

      switch (config?.type) {
        case "Success":
          toast.success(config?.message);
          break;
        case "Error":
          toast.error(config?.message);
          break;
        default:
          toast(config?.message);
          break;
      }
    }

    setOrder(data);
    ReduxActions.dispatch(
      actionCreators.initialData.setCompany({
        ...company,
        ...data?.company,
      })
    );
  }

  function resetHomeScreenScrollPosition(): void {
    saveMenu({
      screenPosition: 0,
      activeCategoryId: null,
    });
  }

  function scrollToTop(): void {
    window.scrollTo(0, 0);
  }

  function toggleRewardsModal(): void {
    //When I purchase and unlock a reward
    // -> Only when after purchase, I unlock a reward
    // -> Thus, if e.g. I have not redeemed my reward, yet, I am not again presented with the reward unlocked Screen
    const reward = new RewardStatusResource({
      rewards: orderRef.current?.company?.rewards,
      rewardBalance: orderRef.current?.company?.reward_balance,
    }).getLastActiveReward();

    if (
      reward &&
      orderRef.current?.company?.reward_balance -
        //@ts-ignore
        collect(orderRef.current?.reward_account_operations)
          .where("value", ">", 0)
          .map((op) => op?.value)
          ?.sum() <
        reward?.cost
    ) {
      return setShowRewardsUnlockedModal(true);
    }

    return setShowPointsEarnedModal(!!reward);
  }

  function renderHeaderBar() {
    return (
      <header>
        <CheckoutNavBar
          title={order?.status === ORDER_FULFILLMENT_STATUSES.REJECTED ? t("header.order_canceled") : t("header.track_order")}
        />
      </header>
    );
  }

  function renderOrderNumber() {
    return (
      <Section hideTopDivider className={sectionStyle}>
        <h3 className="text-center">{t('title', { number: order?.number })}</h3>
        {order?.method === ORDER_METHODS.DELIVERY && order?.delivery_provider !== DeliveryProvider.Standalone && (
          <h3 className="text-center">{t('subtitle')}</h3>
        )}
        <p className="text-center mini mt-tiny">
          {t("header.order_info", { email: profile?.email })}
        </p>
      </Section>
    );
  }

  const renderRating = (): JSX.Element | undefined => {
    function onSelect(rate: number): void {
      if (rate >= 4) {
        setTimeout(
          () =>
            navigate(SCREENS.UPLOAD_GOOGLE_REVIEW_SCREEN, {
              state: {
                prevPage: location?.pathname,
                rating: rate,
                googleReviewHeaderTitle: t("upload_google_review.title"),
              },
            }),
          500
        );
      }

      OrderRatesManager.create(orderRef.current?.order_id, { rate });
    }

    return (
      showRating && (
        <Section hideTopDivider className={sectionStyle}>
          <div className="flex flex-col items-center">
            <h6 className="text-center">
              {t("review_form.title", { name: company?.name })}
            </h6>
            <p className="tiny text-brand-text-grey text-center mt-mini">
              {t("review_form.description")}
            </p>
            <div className="flex justify-center bg-background-inkGreen-green_0 mt-small py-medium px-[40px] rounded-[16px]">
              <RatingStars
                rating={rating}
                setRating={setRating}
                onSelect={onSelect}
              />
            </div>
          </div>
        </Section>
      )
    );
  };

  function renderInstructions() {
    if (order?.method === ORDER_METHODS.ROOM_SERVICE) {
      return (
        <Section hideTopDivider className={sectionStyle}>
          <h4 className="mb-small">{t("room_service_instructions.title")}</h4>
          <CompanyDescription
            company={company}
            datetime={order?.estimated_pickup_time}
            showPhoneNumber
            shouldOpenMap
          />
          <div className="mt-mini">
            <p className="text-mini">
              {t(
                `room_service_instructions.${company?.table_number_hidden ? 'location_types_without_number' : 'location_types'}.${order?.room_service_location?.type}`,
                {
                  details: order?.room_service_details,
                }
              )}
            </p>
          </div>
          {order?.order_instructions && (
            <div className="mt-mini">
              <p className="mini">{order?.order_instructions}</p>
            </div>
          )}
        </Section>
      );
    }

    if (order?.method === ORDER_METHODS.DELIVERY) {
      return (
        <Section hideTopDivider className={sectionStyle}>
          <h4 className="mb-small">{t("delivery_instructions.title")}</h4>
          <h6 className={deliveryAddressStyle}>
            {t("delivery_instructions.address")}
          </h6>
          <p className={deliveryDescription}>
            {AddressHelpers.format(
              order?.delivery_street_name,
              order?.delivery_street_number,
              order?.delivery_city,
              order?.delivery_state,
              order?.delivery_country,
              order?.delivery_zip_code,
              order?.delivery_doorbell_name
            )}
          </p>

          {order?.delivery_doorbell_name && (
            <p className={deliveryDescription}>
              {order?.delivery_doorbell_name}
            </p>
          )}
          {order?.delivery_company_name && (
            <p className={deliveryDescription}>
              {order?.delivery_company_name}
            </p>
          )}

          {order?.customer_note && (
            <p className={deliveryDescription}>
              {t("delivery_instructions.note")}
              <p className={deliveryNoteDescription}>
                "{order?.customer_note}"
              </p>
            </p>
          )}
          {order?.order_instructions && (
            <div className="mt-mini">
              <p className="mini">{order?.order_instructions}</p>
            </div>
          )}
        </Section>
      );
    }

    const getPickupInstructions = () => {
      return i18n.language === "de"
        ? company?.pickup_customer_message_de
        : company?.pickup_customer_message_en;
    };

    return (
      <Section hideTopDivider className={sectionStyle}>
        <h4 className="mb-small">{t("pickup_instructions.title")}</h4>
        <CompanyDescription
          company={company}
          datetime={order?.estimated_pickup_time}
          showPhoneNumber
          shouldOpenMap
        />
        <div className="mt-mini">
          <p className="mini">
            {getPickupInstructions() ?? t("pickup_instructions.description")}
          </p>
        </div>
        {order?.order_instructions && (
          <div className="mt-mini">
            <p className="mini">{order?.order_instructions}</p>
          </div>
        )}
      </Section>
    );
  }

  function renderOrderDetails() {
    return (
      <Section hideTopDivider className={sectionStyle}>
        <h4 className="mb-small">{t("order_details.title")}</h4>
        {order?.line_items?.map((item: LineItemInterface) => (
          <OrderLineItem
            data={item}
            key={`line-item-${item.id}`}
            className="mt-small"
          />
        ))}
      </Section>
    );
  }

  function renderPriceDetails() {
    function renderPriceLabel(
      label: string,
      price?: string | number | null,
      className?: string
    ) {
      return (
        <div className="flex flex-row justify-between">
          <p className={className}>{label}</p>
          <p className={className}>{price}</p>
        </div>
      );
    }

    return (
      <Section hideTopDivider hideBottomDivider className={sectionStyle}>
        <h4 className="mb-small">{t("price_details.title")}</h4>
        <div className={labelContainer}>
          {renderPriceLabel(t("price_details.original"), formatCurrency(order?.original_price))}
        </div>

        {order?.tip > 0 && (
          <div className={labelContainer}>
            {renderPriceLabel(
              t("price_details.tip"),
              formatCurrency(order?.tip)
            )}
          </div>
        )}

        {order?.method === ORDER_METHODS.DELIVERY && order?.method_fee > 0 && (
          <div className={labelContainer}>
            {renderPriceLabel(
              t("price_details.delivery_fee"),
              formatCurrency(order?.method_fee)
            )}
          </div>
        )}

        {order?.service_fee > 0 && (
          <div className={labelContainer}>
            {renderPriceLabel(
              t("price_details.service_fee"),
              formatCurrency(order?.service_fee)
            )}
          </div>
        )}

        {order?.payment_method_upcharge_fee > 0 && (
          <div className={labelContainer}>
            {renderPriceLabel(
              t(`price_details.payment_method_upcharge_fee.${order?.payments?.[0]?.payment_method_id}`),
              formatCurrency(order?.payment_method_upcharge_fee)
            )}
          </div>
        )}

        {order?.coupon_type !== null && order?.total_discount > 0 && (
          <div className={labelContainer}>
            {renderPriceLabel(
              t(`price_details.discount.${order?.coupon_type}`),
              `-${formatCurrency(order?.total_discount)}`
            )}
          </div>
        )}

        {order?.tax_behaviour === TaxBehaviour.Exclusive && order?.tax > 0 && (
          <div className={labelContainer}>
            {renderPriceLabel(
              t('price_details.tax'),
              formatCurrency(order?.tax)
            )}
          </div>
        )}

        {renderPriceLabel(
          t('price_details.total'),
          order?.total_amount === 0 ? t('payment_breakdown.free') : formatCurrency(order?.total_amount),
          'font-bold'
        )}
      </Section>
    );
  }

  const renderLoadingPage = useMemo(() => {
    return loading || loadingGoogleReview ? <LoadingPage /> : null;
  }, [loading, loadingGoogleReview]);

  const renderCashbackEarnedModal = useMemo<JSX.Element>(() => {
    return (
      <CashbackEarnedModal
        open={showCashbackEarnedModal}
        value={orderRef.current?.cashback_account_operations?.[0]?.value}
        toggleModal={() => setShowCashbackEarnedModal(false)}
      />
    );
  }, [showCashbackEarnedModal, orderRef.current?.cashback_account_operations]);

  const renderRewardsUnlockedModal = useMemo<JSX.Element>(() => {
    return (
      <RewardsUnlockedModal
        open={showRewardsUnlockedModal}
        toggleModal={() => setShowRewardsUnlockedModal(false)}
      />
    );
  }, [showRewardsUnlockedModal]);

  const renderPointsEarnedModal = useMemo<JSX.Element>(() => {
    return (
      <PointsEarnedModal
        open={showPointsEarnedModal}
        toggleModal={() => setShowPointsEarnedModal(false)}
      />
    );
  }, [showPointsEarnedModal]);

  return (
    <PageLayout header={renderHeaderBar()}>
      <>
        <main className={wrapper}>
          <div className={innerWrapper}>
            {renderOrderNumber()}
            {renderRating()}
            <OrderTracker order={order} />
            {renderInstructions()}
            {renderOrderDetails()}
            {renderPriceDetails()}
          </div>
        </main>
        {renderPointsEarnedModal}
        {renderCashbackEarnedModal}
        {renderRewardsUnlockedModal}
        {renderLoadingPage}
      </>
    </PageLayout>
  );
}

const wrapper = "flex flex-col items-center";

const innerWrapper = "lg:w-1/2 w-full";

const labelContainer = "mb-mini";

const sectionStyle = "py-medium lg:px-none px-small";

const deliveryAddressStyle = "mb-tiny";

const deliveryDescription = "mb-tiny";

const deliveryNoteDescription = "italic inline break-words";
