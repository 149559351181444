import collect from "collect.js";
import {useEffect, useMemo, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import EmptyCartIcon from "../../assets/logo/EmptyCartIcon";
import CustomButton from "../../components-library/buttons/CustomButton";
import HeaderBar from "../../components-library/headers/HeaderBar";
import IllustrationExplainer from "../../components-library/IllustrationExplainer";
import LoadingPage from "../../components-library/loading/LoadingPage";
import {ERROR_CODES, ORDER_METHODS, SCREENS} from "../../services/exports/Constants";
import {
  ButtonTypes,
  ErrorHandlerInterface,
  OrderDataInterface,
  OrderInterface,
  ThemeInterface,
} from "../../services/exports/Interfaces";
import {DataWrappedResponse, DataWrapper} from "../../services/exports/Types";
import useAlert from "../../hooks/utility/useAlert";
import useTheme from "../../hooks/ui/useTheme";
import useDineInFlow from "../../hooks/global/useDineInFlow";
import OrderBasketItem from "../../components-library/basket/OrderBasketItem";
import SubOrdersManager from "../../services/api/SubOrdersManager";
import DineInNavBar from "../../components-library/navigation/DineInNavBar";
import LoadingWithText from "../../components-library/loading/LoadingWithText";
import useToast from "../../hooks/utility/useToast";
import {debounce} from "lodash";
import OrderManager from "../../services/api/OrderManager";
import LoyaltyPointsLabel from "../../components-library/rewards/LoyaltyPointsLabel";
import {useTranslation} from "react-i18next";
import ButtonFooter from "../../components-library/footers/ButtonFooter";
import useNavigate from "../../hooks/navigation/useNavigate";
import useBasket from "../../hooks/basket/useBasket";
import {CompleteBasketItemInterface} from "../../context/types";
import {StoreInterface} from "../../store/types";
import useAppMethods from "../../hooks/utility/useAppMethods";
import ConfirmOrderStepper from "../../components-library/dine-in/stepper/ConfirmOrderStepper";

interface LocationState {
  toastMessage: string;
  prevPath?: string;
}

export default function ConfirmOrderScreen() {
  const { t } = useTranslation(null, { keyPrefix: 'Pages:DineIn:ConfirmOrderScreen' });

  const location = useLocation() as any;
  const locationState: LocationState = location.state;

  const { company } = useSelector((state: StoreInterface) => state.initialData);
  const { profile } = useSelector((state: StoreInterface) => state.profile);

  const toast = useToast();
  const theme: ThemeInterface = useTheme();
  const { navigate } = useNavigate();
  const alert = useAlert();

  const { party, masterOrder: order } = useDineInFlow();
  const { total } = useBasket();
  const {
    data: basketData,
    items,
    formatForBackend,
    clearBasket,
  } = useBasket();
  const { formatCurrency } = useAppMethods();

  const [loading, setLoading] = useState<boolean>(true);
  const [loadingDraft, setLoadingDraft] = useState<boolean>(false);
  const [screenInitialized, setScreenInitialized] = useState<boolean>(false);
  const [purchasing, setPurchasing] = useState<boolean>(false);
  const [readyForPurchase, setReadyForPurchase] = useState<boolean>(false);
  const [errorType] = useState<null | string>(null);

  const [orderDraft, setOrderDraft] = useState<OrderInterface>(null);

  const profileRef = useRef(profile);
  profileRef.current = profile;

  const errorTypeRef = useRef(errorType);
  errorTypeRef.current = errorType;

  const orderRef = useRef(order);
  orderRef.current = order;

  const earnedPoints = useMemo(
    () =>
      +collect(orderDraft?.reward_account_operations)
        .where("value", ">", 0)
        .sum("value"),
    [orderDraft?.reward_account_operations]
  );

  const debouncedOrderDraft = useRef(
    debounce(async () => {
      draftOrder();
    }, 500)
  ).current;

  useEffect(() => {
    if (!party?.id) {
      return navigate(SCREENS.DINE_IN);
    }
  }, [party]);

  useEffect(() => {
    items.length > 0 && debouncedOrderDraft();
  }, [basketData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (screenInitialized && locationState?.toastMessage) {
      throwToastMessagesFromPreviousScreen();
    }
  }, [screenInitialized]);

  async function init() {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setScreenInitialized(true);
    }, 1000);
    draftOrder();
  }

  function toggleLoadingDraft(timeout: number = 500): void {
    setTimeout(() => setLoadingDraft(false), timeout);
  }

  async function draftOrder(): Promise<
    DataWrappedResponse<DataWrapper<OrderInterface>>
  > {
    const order = getOrderData();

    if (!order) {
      return {
        response: null,
        success: false,
        status: 400,
      };
    }

    setLoadingDraft(true);
    const request = await OrderManager.draftOrder(order);
    toggleLoadingDraft();

    const { response, success } = request;

    if (success && response?.data?.data) {
      setOrderDraft(response?.data?.data);
    }

    return request;
  }

  function throwToastMessagesFromPreviousScreen(): void {
    setTimeout(() => {
      new Promise((resolve) => {
        toast.success(locationState.toastMessage);
        resolve(true);
      }).then(() => {
        navigate(SCREENS.CONFIRM_ORDER, {
          state: {
            ...locationState,
            toastMessage: null,
          },
        });
      });
    }, 500);
  }

  function getOrderData(data?: OrderDataInterface): OrderDataInterface {
    return {
      ...orderRef.current,
      method: ORDER_METHODS.DINE_IN,
      party_id: party?.id,
      company_id: company?.id,
      line_items: formatForBackend(),
      ...data,
    };
  }

  async function initOrder(
    callback: () => void,
    data?: OrderDataInterface,
    errorHandlers?: ErrorHandlerInterface
  ): Promise<DataWrappedResponse<DataWrapper<OrderInterface>>> {
    const request = await SubOrdersManager.create(
      orderRef.current?.id,
      getOrderData(data),
      errorHandlers
    );

    const { success, response } = request;

    if (!success) {
      handleErrorMessages(response, callback);
    }

    return request;
  }

  function handleErrorMessages(error: any, callback?: () => void) {
    switch (error?.response?.status) {
      case 417:
        return alert.error({
          title: error?.response?.data?.title,
          description: error?.response?.data?.description,
        });
      default:
        return;
    }
  }

  async function prepareForPurchase() {
    setPurchasing(true);
    const { response, success } = await initOrder(prepareForPurchase, null, {
      [ERROR_CODES.DINE_IN_CLOSED]: () => navigate(SCREENS.DINE_IN),
    });

    if (success) {
      return await purchaseSuccessHandler();
    }

    prepareForPurchaseErrorHandler(response);
  }

  function prepareForPurchaseErrorHandler(error: any) {
    if (error?.response?.status !== 403) {
      setPurchasing(false);
    }
  }

  async function purchaseSuccessHandler() {
    navigate(SCREENS.DINE_IN, {
      state: { showOrderSuccessfulModal: true },
    });

    setTimeout(() => clearBasket(), 500);
    setPurchasing(false);
  }

  function goBack(): void {
    if (locationState?.prevPath) {
      return navigate(locationState.prevPath, { absolute: true });
    }

    return navigate(-1);
  }

  function renderOrdersList() {
    return (
      <div>
        {items.map((item: CompleteBasketItemInterface, index) => (
          <div className="mb-small" key={`basket-item-${index}`}>
            <OrderBasketItem data={item} index={index} />
          </div>
        ))}
      </div>
    );
  }

  function renderPayButton() {
    function onPlaceOrder(): void {
      setPurchasing(true);
      setTimeout(() => {
        prepareForPurchase();
      }, 2000);
    }

    return (
      <ButtonFooter className={payButtonContainer}>
        <LoyaltyPointsLabel quantity={earnedPoints} className="mb-mini" />
        <div className="flex flex-row justify-between pb-small">
          <h4>{t("labels.total")}</h4>
          <div className="flex flex-row">
            <h4
              style={{
                marginLeft: theme.dimension.padding.mini,
              }}
            >
              {formatCurrency(orderDraft?.total ?? total)}
            </h4>
          </div>
        </div>
        <CustomButton
          title={t("buttons.place_order")}
          onClick={onPlaceOrder}
          loading={purchasing || loadingDraft}
          disabled={!readyForPurchase}
        />
      </ButtonFooter>
    );
  }

  function renderHeaderSummary() {
    return (
      <div className={headerSummaryContainer}>
        <HeaderBar title={t("header.order_summary")} className="py-none" />
      </div>
    );
  }

  function renderOrderSummary() {
    return (
      <div className={orderSummaryContainer}>
        {renderHeaderSummary()}
        <div className={orderSummaryWrapper}>{renderOrdersList()}</div>
        {renderPayButton()}
      </div>
    );
  }

  const renderLoadingPage = useMemo(() => {
    return loading ? <LoadingPage /> : null;
  }, [loading]);

  function renderEmptyBasket() {
    return (
      <div className={emptyBasketContainer}>
        {renderHeaderSummary()}
        <div className="mt-medium">
          <IllustrationExplainer
            illustration={<EmptyCartIcon className="h-20 w-20" />}
            title={t("empty_order_basket.title")}
            description={t("empty_order_basket.description")}
          />
        </div>
        <div className={addProductsButtonContainer}>
          <CustomButton
            title={t("buttons.add_products")}
            buttonType={ButtonTypes.third}
            onClick={() => navigate(SCREENS.MENU)}
          />
        </div>
      </div>
    );
  }

  function renderSummary() {
    if (items.length > 0) {
      return renderOrderSummary();
    }

    return renderEmptyBasket();
  }

  const renderLoading = useMemo(() => {
    return purchasing && <LoadingWithText description={t("loading_overlay")} />;
  }, [purchasing]);

  return (
    <>
      <header className={headerBarContainer}>
        <DineInNavBar
          title={t("header.main")}
          leftClick={goBack}
          hideCloseIcon
        />
      </header>
      <div className={container}>
        <main className={mainWrapper}>
          <div className={mainGrid}>
            <ConfirmOrderStepper
              total={orderDraft?.total}
              onProgress={(complete: boolean) => setReadyForPurchase(complete)}
            />
          </div>
          <div className={secondGrid}>{renderSummary()}</div>
        </main>
      </div>
      {renderLoadingPage}
      {renderLoading}
    </>
  );
}

const container = "relative h-full";

const mainWrapper = "h-full flex lg:flex-row flex-col";

const mainGrid = "lg:w-3/4 w-full relative lg:h-full overscroll-contain";

const secondGrid =
  "lg:w-1/4 w-full lg:border-l border-brand-inkGrey-grey_2 relative lg:h-screen relative overscroll-contain";

const payButtonContainer = "fixed bottom-0 lg:w-1/4 w-full";

const emptyBasketContainer = "w-full";

const headerBarContainer = "sticky top-0 z-10";

const headerSummaryContainer = "lg:sticky lg:top-0 z-20";

const addProductsButtonContainer = "px-small mt-medium";

const orderSummaryContainer =
  "lg:overflow-y-auto lg:fixed h-full overscroll-contain lg:w-1/4 right-0";

const orderSummaryWrapper = "px-small pt-small lg:pb-[220px] pb-[160px]";
