import { useSelector } from "react-redux";
import { StoreInterface } from "../../store/types";
import {CompanyLocation} from "../../services/exports/Interfaces";
import useCompanyLocation from "./useCompanyLocation";

export default function useMaps(location: null|CompanyLocation = null) {
  const { company } = useSelector((state: StoreInterface) => state.initialData);
  const currentLocation = useCompanyLocation();

  const locationToDisplay = location ?? currentLocation;

  function openGoogleMaps(): void {
    function generateQuery() {
      if (!company.has_multiple_locations) {
        return `${company?.lat}${company?.lon}&query_place_id=${company?.google_places_id}`;
      }

      return `${locationToDisplay?.address}, ${locationToDisplay?.zip_code} ${locationToDisplay?.city}, ${company?.country}`;
    }

    const encodedQuery = generateQuery()
      .replace("|", "%7C")
      ?.replace(",", "%2C")
      ?.replace(" ", "+");

    window.open(
      `https://www.google.com/maps/search/?api=1&query=${encodedQuery}`
    );
  }
  
  function generateEmbedMapUrl(): string {
    return `https://www.google.com/maps/embed/v1/place?q=place_id:${company?.google_places_id}&key=${import.meta.env.VITE_GOOGLE_MAPS_API_KEY}&center=${company.lat},${company.lon}&zoom=16`
  }

  function openMap() {
    try {
      return openGoogleMaps();
    } catch (error) {}
  }

  return {
    openMap,
    generateEmbedMapUrl,
  };
}
