import React, {useState, useEffect, useMemo} from "react";
import { useSelector } from "react-redux";
import CustomButton from "../../../components-library/buttons/CustomButton";
import CheckmarkTextInput from "../../../components-library/inputs/CheckmarkTextInput";
import useAlert from "../../../hooks/utility/useAlert";
import ProfileManager from "../../../services/api/ProfileManager";
import {useTranslation} from "react-i18next";
import {StoreInterface} from "../../../store/types";
import {ButtonTypes} from "../../../services/exports/Interfaces";
import classnames from "classnames";
import useScreenType from "../../../hooks/utility/useScreenType";

export enum Fields {
  FirstName = 'first_name',
  LastName = 'last_name',
  Email = 'email',
}

interface Props {
  fields?: Fields[];
  onContinue: () => void;
}

const EMAIL_REGEX: RegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export default function PersonalInfoStep(props: Props) {
  const { t } = useTranslation(null, { keyPrefix: 'Components:Checkout:Stepper:PersonalInfoStep' });

  const {
    fields = [
      Fields.FirstName,
      Fields.LastName,
      Fields.Email,
    ],
    onContinue,
  } = props;

  const { profile } = useSelector((state: StoreInterface) => state.profile);

  const alert = useAlert();
  const { isDesktop } = useScreenType();

  const [firstName, setFirstName] = useState(profile?.first_name ?? "");
  const [lastName, setLastName] = useState(profile?.last_name ?? "");
  const [email, setEmail] = useState(profile?.email ?? "");
  const [loading, setLoading] = useState(false);

  const isFirstNameValid = useMemo<boolean>(() => firstName?.length > 0, [firstName]);
  const isLastNameValid = useMemo<boolean>(() => lastName?.length > 0, [lastName]);
  const isEmailValid = useMemo<boolean>(() => EMAIL_REGEX.test(email.toLocaleLowerCase()), [email]);
  const isValid = isFirstNameValid && isLastNameValid && isEmailValid;

  useEffect(() => {
    setFirstName(profile?.first_name ?? "");
    setLastName(profile?.last_name ?? "");
    setEmail(profile?.email ?? "");
  }, [profile]);

  async function updateProfile() {
    setLoading(true);
    const { success } = await ProfileManager.putProfile({
      first_name: firstName,
      last_name: lastName,
      email: email,
    });
    setLoading(false);

    if (success) {
      return onContinue();
    }

    alert.error({
      description: t('error_messages.failed_update_profile'),
    });
  }

  function renderContinueButton() {
    return (
      <div className="mt-small">
        <CustomButton
          title={t('buttons.continue')}
          buttonType={ButtonTypes.secondary}
          loading={loading}
          onClick={updateProfile}
          disabled={!isValid}
        />
      </div>
    );
  }

  return (
    <div>
      {(fields.includes(Fields.FirstName) || fields.includes(Fields.LastName)) && (
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-2 mb-small">
          {fields.includes(Fields.FirstName) && (
            <CheckmarkTextInput
              id="first-name"
              type="text"
              placeholder={t('fields.first_name.placeholder')}
              value={firstName}
              isComplete={isFirstNameValid}
              onChange={setFirstName}
              className={classnames({ 'col-span-2': isDesktop && !fields.includes(Fields.LastName) })}
            />
          )}
          {fields.includes(Fields.LastName) && (
            <CheckmarkTextInput
              id="last-name"
              type="text"
              placeholder={t('fields.last_name.placeholder')}
              value={lastName}
              isComplete={isLastNameValid}
              onChange={setLastName}
              className={classnames({ 'col-span-2': isDesktop && !fields.includes(Fields.FirstName) })}
            />
          )}
        </div>
      )}
      {fields.includes(Fields.Email) && (
        <div>
          <label htmlFor="email" className="text-mini">{t('fields.email.label')}</label>
          <CheckmarkTextInput
            id="email"
            type="email"
            className="mt-mini"
            placeholder={t('fields.email.placeholder')}
            value={email}
            isComplete={isEmailValid}
            onChange={setEmail}
          />
        </div>
      )}
      {renderContinueButton()}
    </div>
  );
}
