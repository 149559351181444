import React from "react";
import useTheme from "../../hooks/ui/useTheme";
import { ThemeInterface } from "../../services/exports/Interfaces";

interface Props {
  className?: string;
  style?: Object;
  color?: string;
}

export default function NoInternetIllustration(props: Props) {
  const { className, color, style } = props;
  const theme = useTheme();
  return (
    <svg
      className={className ?? "lg:w-1/4 md:w-1/2 w-3/4 mx-auto"}
      viewBox="0 0 538 422"
      fill="none"
    >
      <path
        d="M27.392 247.671C14.478 303.762 105.173 438.142 249.032 419.241C315.94 410.448 316.283 376.613 372.076 341.639C405.331 320.795 450.056 308.435 468.08 272.867C483.645 242.168 483.613 206.258 470.337 180.597C440.481 122.887 368.386 74.3926 277.411 141.332C186.436 208.272 60.716 103.011 27.392 247.671V247.671Z"
        fill="url(#paint0_linear_1335_512)"
      />
      <path
        d="M207.207 110.084C203.609 95.6366 169.613 74.5366 151.445 87.2576C145.869 59.7326 140.653 42.5686 122.434 29.0096C104.215 15.4506 73.816 33.1736 68.933 70.2956C64.051 55.0356 39.228 34.4686 19.287 51.8106C0.836988 67.8586 -1.01301 94.7476 0.913988 110.059H207.207V110.084Z"
        fill="url(#paint1_linear_1335_512)"
      />
      <path
        d="M487.39 101.717C484.368 89.5865 455.809 71.8705 440.547 82.5515C435.862 59.4415 431.48 45.0295 416.175 33.6455C400.871 22.2605 375.334 37.1415 371.232 68.3105C367.131 55.4975 346.278 38.2285 329.527 52.7895C314.028 66.2635 312.473 88.8405 314.092 101.696H487.39V101.717V101.717Z"
        fill="url(#paint2_linear_1335_512)"
      />
      <path
        d="M299.64 54.5118C297.383 45.4588 276.051 32.2379 264.652 40.2089C261.153 22.9629 257.88 12.2078 246.449 3.71185C235.018 -4.78415 215.944 6.32185 212.88 29.5809C209.817 20.0199 194.242 7.13285 181.73 17.9989C170.153 28.0539 168.992 44.9029 170.202 54.4959H299.64V54.5118V54.5118Z"
        fill="url(#paint3_linear_1335_512)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M371.903 127.462C433.258 188.817 433.258 288.293 371.903 349.647C310.548 411.002 211.073 411.002 149.718 349.647C88.364 288.292 88.364 188.817 149.718 127.462C211.073 66.1066 310.549 66.1066 371.903 127.462Z"
        fill={color ?? theme.color.companyBrand.primary }
      />
      <path
        d="M345.911 210.152C296.283 171.416 225.339 171.416 175.71 210.152M211.182 259.71C240.125 237.139 281.497 237.139 310.457 259.71M260.811 303.213C263.744 303.213 266.135 305.518 266.135 308.346C266.135 311.174 263.744 313.478 260.811 313.478C257.878 313.478 255.487 311.174 255.487 308.346C255.487 305.518 257.878 303.213 260.811 303.213Z"
        stroke="white"
        strokeWidth="24"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M529.116 145.995L487.176 187.934C485.649 189.461 485.649 191.937 487.176 193.464C488.703 194.991 491.179 194.991 492.707 193.464L534.646 151.525C536.173 149.997 536.173 147.521 534.646 145.994C533.119 144.467 530.643 144.467 529.116 145.995Z"
        fill="#E1E4E5"
      />
      <path
        d="M525.011 176.022L512.916 188.117C511.389 189.645 511.389 192.121 512.916 193.648C514.443 195.175 516.919 195.175 518.447 193.648L530.542 181.553C532.069 180.025 532.069 177.549 530.542 176.022C529.015 174.495 526.539 174.495 525.011 176.022Z"
        fill="#E1E4E5"
      />
      <path
        d="M478.701 258.111L449.832 286.979C448.781 288.031 448.781 289.735 449.832 290.786C450.884 291.838 452.588 291.838 453.64 290.786L482.508 261.918C483.559 260.867 483.559 259.162 482.508 258.111C481.457 257.06 479.752 257.06 478.701 258.111Z"
        fill="#E1E4E5"
      />
      <path
        d="M475.876 278.779L467.551 287.104C466.499 288.156 466.499 289.86 467.551 290.911C468.602 291.963 470.306 291.963 471.358 290.911L479.683 282.586C480.734 281.535 480.734 279.83 479.683 278.779C478.632 277.728 476.927 277.728 475.876 278.779Z"
        fill="#E1E4E5"
      />
      <path
        d="M6.0925 283.032L36.8962 252.228C38.0178 251.106 38.0178 249.288 36.8962 248.166C35.7746 247.045 33.9561 247.045 32.8346 248.166L2.03087 278.97C0.90928 280.092 0.909281 281.91 2.03087 283.032C3.15245 284.153 4.97091 284.153 6.0925 283.032Z"
        fill="#E1E4E5"
      />
      <path
        d="M9.10805 260.977L17.9921 252.093C19.1137 250.972 19.1137 249.153 17.9922 248.032C16.8706 246.91 15.0521 246.91 13.9305 248.032L5.04643 256.916C3.92485 258.037 3.92486 259.856 5.04644 260.977C6.16803 262.099 7.98646 262.099 9.10805 260.977Z"
        fill="#E1E4E5"
      />
      <path
        d="M411.329 377.099L380.526 407.903C379.404 409.024 379.404 410.843 380.526 411.964C381.647 413.086 383.466 413.086 384.587 411.964L415.391 381.161C416.513 380.039 416.513 378.221 415.391 377.099C414.269 375.977 412.451 375.978 411.329 377.099Z"
        fill="#E1E4E5"
      />
      <path
        d="M408.315 399.152L399.431 408.036C398.309 409.157 398.309 410.976 399.431 412.097C400.552 413.219 402.371 413.219 403.492 412.097L412.376 403.213C413.498 402.092 413.498 400.273 412.376 399.152C411.255 398.03 409.436 398.03 408.315 399.152Z"
        fill="#E1E4E5"
      />
      <path
        d="M124.835 369.031L94.0308 399.834C92.9092 400.956 92.9092 402.774 94.0308 403.896C95.1524 405.018 96.9709 405.018 98.0925 403.896L128.896 373.092C130.018 371.971 130.018 370.152 128.896 369.031C127.775 367.909 125.956 367.909 124.835 369.031Z"
        fill="#E1E4E5"
      />
      <path
        d="M121.82 391.085L112.936 399.969C111.814 401.091 111.814 402.909 112.936 404.031C114.057 405.152 115.876 405.152 116.997 404.031L125.882 395.147C127.003 394.025 127.003 392.207 125.882 391.085C124.76 389.964 122.942 389.964 121.82 391.085Z"
        fill="#E1E4E5"
      />
      <rect
        x="184.044"
        y="169.327"
        width="30"
        height="192.719"
        rx="15"
        transform="rotate(-40 184.044 169.327)"
        fill="white"
        stroke={color ?? theme.color.companyBrand.primary}
        strokeWidth="10"
      />
      <circle cx="261" cy="309" r="9" fill="white" />
      <defs>
        <linearGradient
          id="paint0_linear_1335_512"
          x1="100.718"
          y1="610.087"
          x2="470.178"
          y2="-288.205"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#EEEEEE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1335_512"
          x1="108.171"
          y1="158.083"
          x2="107.079"
          y2="-68.6834"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#EEEEEE" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1335_512"
          x1="404.194"
          y1="142.018"
          x2="403.277"
          y2="-48.3805"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#EEEEEE" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1335_512"
          x1="237.5"
          y1="84.5868"
          x2="236.816"
          y2="-57.5011"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#EEEEEE" />
        </linearGradient>
      </defs>
    </svg>
  );
}
