import useDineInFlow from "../../hooks/global/useDineInFlow";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { ORDER_FULFILLMENT_STATUSES, SCREENS } from "../../services/exports/Constants";
import CurrentPartySection from "../../components-library/dine-in/CurrentPartySection";
import MasterOrderSection from "../../components-library/dine-in/MasterOrderSection";
import useTheme from "../../hooks/ui/useTheme";
import CustomButton from "../../components-library/buttons/CustomButton";
import PartiesManager from "../../services/api/PartiesManager";
import ReduxActions from "../../store/ReduxActions";
import { actionCreators } from "../../store/actions";
import useFeedbackHandler from "../../hooks/utility/useFeedbackHandler";
import LoadingPage from "../../components-library/loading/LoadingPage";
import CurrentTableHeader from "../../components-library/dine-in/CurrentTableHeader";
import ReceiptIcon from "../../assets/logo/ReceiptIcon";
import OrdersDisabledSection from "../../components-library/dine-in/OrdersDisabledSection";
import OrderSuccessfulModal from "../../modals/dine-in/OrderSuccessfulModal";
import PaymentInfoSection from "../../components-library/dine-in/PaymentInfoSection";
import useToast from "../../hooks/utility/useToast";
import { collect } from "collect.js";
import CutleryIcon from "../../assets/logo/CutleryIcon";
import PicturesSlider from "../../components-library/company/PicturesSlider";
import { useTranslation } from "react-i18next";
import HorizontalButtons from "../../components-library/buttons/HorizontalButtons";
import ButtonFooter from "../../components-library/footers/ButtonFooter";
import useInitialData from "../../hooks/global/useInitialData";
import useLoyaltyProgram from "../../hooks/loyalty/useLoyaltyProgram";
import PromotionsList from "../../components-library/promotion/PromotionsList";
import Section from "../../components-library/dine-in/Section";
import useNavigate from "../../hooks/navigation/useNavigate";
import useAppMethods from "../../hooks/utility/useAppMethods";
import useOrderMethodsStatus from "../../hooks/availability/useOrderMethodsStatus";
import {ButtonTypes} from "../../services/exports/Interfaces";

interface LocationState {
  showInitialLoading?: boolean;
  showOrderSuccessfulModal?: boolean;
}

export default function DineInScreen() {
  const { t } = useTranslation(null, { keyPrefix: 'Pages:DineIn:DineInScreen' });

  const { data } = useInitialData();
  const { loading, company } = data;
  const { table, party, userOrdered, masterOrder, unpaidAmount } =
    useDineInFlow();

  const theme = useTheme();
  const toast = useToast();
  const { navigate } = useNavigate();
  const location = useLocation();
  const { hasLoyaltyPromotion } = useLoyaltyProgram();
  const { dineInCurrentlyOpen } = useOrderMethodsStatus();
  const { loadingTable } = useDineInFlow({ autoLoadTable: true });
  const { setFeedback } = useFeedbackHandler({ renderToasts: true });

  const {
    saveMenu,
    toggleOrderMethodModal,
    toggleChangeTablePartyModal,
  } = useAppMethods();

  const locationState: LocationState = location.state;

  const [loadingParty, setLoadingParty] = useState(false);
  const [showInitialLoading, setShowInitialLoading] = useState(!table?.id);
  const [showOrderSuccessfulModal, setShowOrderSuccessfulModal] = useState(
    locationState?.showOrderSuccessfulModal
  );

  useEffect(() => {
    init();
  }, []);

  function init() {
    toggleDineInStatus();
    toggleLoading();
    toggleOrderMethodModal({ openModal: false });
    if (showOrderSuccessfulModal) {
      saveMenu({ screenPosition: 0, activeCategoryId: null });
    }
  }

  useEffect(() => {
    if (party?.deleted_at) {
      ReduxActions.dispatch(actionCreators.dineInFlow.resetParty());
    }
  }, [party?.deleted_at]);

  const toggleDineInStatus = (): void => {
    ReduxActions.dispatch(actionCreators.dineInFlow.setDineInStatus(true));
  };

  const toggleLoading = (timeout: number = 2000) => {
    setTimeout(() => setShowInitialLoading(false), timeout);
  };

  const loadParty = async () => {
    setLoadingParty(true);
    const response = await PartiesManager.show(party?.id);
    setLoadingParty(false);

    return response;
  };

  async function leaveParty(): Promise<void> {
    setLoadingParty(true);
    const { success } = await PartiesManager.leave(party?.id);
    setLoadingParty(false);

    if (!success) {
      return;
    }

    toast(t("toasts.left_table"));
  }

  const closeTable = async () => {
    setLoadingParty(true);
    if (
      unpaidAmount == 0 &&
      collect(masterOrder.sub_orders).firstWhere(
        "status",
        ORDER_FULFILLMENT_STATUSES.REJECTED
      )
    ) {
      const { success } = await PartiesManager.delete(party?.id);

      if (!success) {
        setLoadingParty(false);
        return toast(t("toasts.failed_to_close_table"));
      }

      return toast(t("toasts.table_closed"));
    }

    ReduxActions.dispatch(
      actionCreators.dineInFlow.setPaymentInfo({
        amount: unpaidAmount,
      })
    );

    navigate(SCREENS.CHECKOUT);
  };

  const onPay = async () => {
    const { success, response } = await loadParty();

    if (!success) {
      return setFeedback({
        type: "Warning",
        message: t("general_error"),
      });
    }

    if (response?.data?.data?.users?.length > 1 && unpaidAmount > 0) {
      return navigate(SCREENS.SPLIT_BILL);
    }

    await closeTable();
  };

  function onMenuClick(): void {
    if (!table) {
      return toggleChangeTablePartyModal({
        openModal: true,
        onTableSelected: () => navigate(SCREENS.MENU),
      });
    }

    return navigate(SCREENS.MENU);
  }

  const hideOrderSuccessfulModal = () => {
    setShowOrderSuccessfulModal(false);
    navigate(SCREENS.DINE_IN, { replace: true });
  };

  const renderLoadingPage = useMemo(() => {
    return loading || loadingTable ? <LoadingPage /> : null;
  }, [loading, loadingTable]);

  const renderMasterOrder = useMemo(
    () => <MasterOrderSection className="mt-small mb-[100px]" />,
    [masterOrder]
  );

  const renderFooter = useMemo(() => {
    if (!masterOrder?.sub_orders?.length) {
      return (
        <CustomButton
          title={
            <>
              <CutleryIcon
                className="h-4 w-4 mr-mini"
                color={theme.color.background.inkWhite.white_0}
              />
              {t("buttons.view_menu")}
            </>
          }
          onClick={onMenuClick}
          disabled={!dineInCurrentlyOpen}
        />
      );
    }

    return (
      <HorizontalButtons
        leftButtonProps={{
          buttonType: ButtonTypes.forth,
          title: (
            <>
              <CutleryIcon
                className="h-4 w-4 mr-mini"
                color={theme.color.companyBrand.primary}
              />
              {t("buttons.menu")}
            </>
          ),
          titleClass: "flex justify-center items-center",
          onClick: () => navigate(SCREENS.MENU),
          hideButton: !dineInCurrentlyOpen,
        }}
        rightButtonProps={{
          title: (
            <>
              <ReceiptIcon className="h-4 w-4 mr-mini" />
              {unpaidAmount <= 0
                ? t("buttons.close_table")
                : t("buttons.pay_now")}
            </>
          ),
          onClick: onPay,
          titleClass: "flex justify-center items-center",
          loading: loadingParty,
        }}
      />
    );
  }, [
    userOrdered,
    masterOrder?.sub_orders,
    party,
    loadingParty,
    dineInCurrentlyOpen,
  ]);

  const renderOrderSuccessfulModal = useMemo(
    () => (
      <OrderSuccessfulModal
        open={showOrderSuccessfulModal}
        onConfirm={hideOrderSuccessfulModal}
      />
    ),
    [showOrderSuccessfulModal]
  );

  const imageHeight = window.innerHeight * 0.25;

  return (
    <>
      <header className="sticky top-0 w-full bg-background-inkWhite-white_0 border-b">
        <CurrentTableHeader
          loading={showInitialLoading}
          showExitDineInButton={
            company?.has_pickup ||
            (company?.has_delivery && company?.delivery_enabled)
          }
        />
      </header>
      {company?.dine_in_pictures?.length > 0 && (
        <div style={{ height: `${imageHeight}px`, width: "100%" }}>
          <PicturesSlider pictures={company?.dine_in_pictures} />
        </div>
      )}

      <main className="h-full w-full relative pb-[100px]">
        {userOrdered && <PaymentInfoSection className="mt-small" />}

        {hasLoyaltyPromotion && (
          <Section
            className="mt-small pb-mini"
            title={t("promotions.loyalty")}
          >
            <PromotionsList />
          </Section>
        )}

        <OrdersDisabledSection className="mt-small" />
        <CurrentPartySection className="mt-small" onLeaveParty={leaveParty} />
        {renderMasterOrder}
      </main>

      <ButtonFooter className="fixed bottom-0">{renderFooter}</ButtonFooter>

      {renderLoadingPage}
      {renderOrderSuccessfulModal}
    </>
  );
}
