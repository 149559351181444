import { useState } from "react";
import CloseIcon from "../../assets/logo/CloseIcon";
import useTheme from "../../hooks/ui/useTheme";
import OrderManager from "../../services/api/OrderManager";
import {
  ORDER_FULFILLMENT_STATUSES,
  ORDER_METHODS,
  PUSHER_EVENTS,
  SCREENS,
} from "../../services/exports/Constants";
import {
  OrderItemInterface,
  ThemeInterface,
} from "../../services/exports/Interfaces";
import LoadingIndicator from "../loading/LoadingIndicator";
import usePusher from "../../hooks/global/usePusher";
import useFocus from "../../hooks/utility/useFocus";
import {orderTrackingChannel} from "../../services/pusher/Channels";
import ReduxActions from "../../store/ReduxActions";
import {actionCreators} from "../../store/actions";
import useFeedbackHandler from "../../hooks/utility/useFeedbackHandler";
import useToast from "../../hooks/utility/useToast";
import {useTranslation} from "react-i18next";
import {FeedbackLabelTypes} from "../../services/exports/Types";
import useNavigate from "../../hooks/navigation/useNavigate";
import classnames from "classnames";
import useAppMethods from "../../hooks/utility/useAppMethods";

interface Props {
  order: OrderItemInterface;
}

export default function OngoingOrder(props: Props) {
  const { t } = useTranslation(null, { keyPrefix: 'Components:Order:OngoingOrder' });

  const { order } = props;

  const theme: ThemeInterface = useTheme();
  const { navigate } = useNavigate();
  const toast = useToast();
  const { formatTime } = useAppMethods();

  const [deletingOrder, setDeletingOrder] = useState(false);
  const [loading, setLoading] = useState(false);

  const { connect, disconnect } = usePusher();
  const { setFeedback } = useFeedbackHandler({
    renderToasts: true,
  });

  useFocus(
    () => initData(),
    () => disconnect()
  );

  async function initData() {
    fetchOrderData();
    connectPusher();
  }

  async function requestOrderInfo(
    callback?: (data: OrderItemInterface) => void,
    errorCallback?: () => void
  ) {
    if (!order) {
      return;
    }

    const { response, success } = await OrderManager.getOrder(order.order_uuid);

    if (success) {
      return callback && callback(response?.data?.data);
    }

    return errorCallback && errorCallback();
  }

  async function fetchOrderData() {
    setLoading(true);
    requestOrderInfo(successHandler, errorHandler);
    setLoading(false);
  }

  function successHandler(data: OrderItemInterface) {
    ReduxActions.dispatch(actionCreators.initialData.addOngoingOrder(data));
  }

  function errorHandler() {
    setFeedback({
      type: "Error",
      message: t("error_messages.failed_to_load"),
    });
  }

  async function connectPusher() {
    if (!order) {
      return;
    }

    const pusher = await connect();
    pusher
      .subscribe(orderTrackingChannel(order?.order_uuid))
      .bind(PUSHER_EVENTS.ORDER_UPDATED, pusherHandler);
  }

  function pusherHandler(data: OrderItemInterface) {
    requestOrderInfo(orderUpdatedHandler, errorHandler);
  }

  function orderUpdatedHandler(data: OrderItemInterface) {
    function getToastConfig(): {
      message: string;
      type?: FeedbackLabelTypes;
    } {
      if (order?.status === data.status) {
        return {
          message: t("order_status_toast.order_preparation_up_time_changed", {
            number: data.number,
            type:
              data.method === ORDER_METHODS.PICKUP
                ? t("order_type.pickup")
                : t("order_type.delivery"),
            time: formatTime(data.estimated_pickup_time),
          }),
        };
      }

      switch (data?.status) {
        case ORDER_FULFILLMENT_STATUSES.REVIEWED:
          return {
            message: t("order_status_toast.order_reviewed", {
              number: data.number,
            }),
          };
        case ORDER_FULFILLMENT_STATUSES.ACCEPTED:
          return {
            message: t("order_status_toast.order_confirmed", {
              number: data.number,
              time: formatTime(data.estimated_pickup_time),
            }),
            type: "Success",
          };
        case ORDER_FULFILLMENT_STATUSES.PREPARING:
          return {
            message: t("order_status_toast.order_preparing", {
              number: data.number,
            }),
          };
        case ORDER_FULFILLMENT_STATUSES.REJECTED:
          return {
            message: t("order_status_toast.order_rejected", {
              number: data.number,
            }),
            type: "Error",
          };
        default:
          return {
            message: t("order_status_toast.order_preparation_up_time_changed", {
              number: data.number,
              type:
                data.method === ORDER_METHODS.PICKUP
                  ? t("order_type.pickup")
                  : t("order_type.delivery"),
              time: formatTime(data.estimated_pickup_time),
            }),
          };
      }
    }

    if (
      data?.status !== order?.status ||
      data.estimated_pickup_time !== order?.estimated_pickup_time
    ) {
      const config = getToastConfig();

      switch (config?.type) {
        case "Success":
          toast.success(config?.message);
          break;
        case "Error":
          toast.error(config?.message);
          break;
        default:
          toast(config?.message);
          break;
      }
    }

    ReduxActions.dispatch(actionCreators.initialData.addOngoingOrder(data));
  }

  async function deleteOrder() {
    setDeletingOrder(true);
    const { success } = await OrderManager.deleteOngoingOrder(order?.id);
    setDeletingOrder(true);
    if (success) {
      OrderManager.deleteOngoingOrderRedux();
      disconnect();
    }
  }

  function goToOrder() {
    navigate(`${SCREENS.ORDER_DETAILS}/${order?.order_uuid}`);
  }

  const getColor = () => {
    if (!order?.company?.has_scheduled_orders) {
      return theme.color.brand.warning;
    }

    switch (order?.status) {
      case ORDER_FULFILLMENT_STATUSES.REJECTED:
        return theme.color.brand.danger;
      case ORDER_FULFILLMENT_STATUSES.ACCEPTED:
        return theme.color.brand.primary;
      default:
        return theme.color.brand.warning;
    }
  };

  function renderLabel() {
    const getTitle = () => {
      if (!order?.company?.has_scheduled_orders) {
        return t("ongoing_order");
      }

      switch (order?.status) {
        case ORDER_FULFILLMENT_STATUSES.REJECTED:
          return t("status.rejected.title");
        default:
          return t("ongoing_order");
      }
    };

    const getDescription = () => {
      if (!order?.company?.has_scheduled_orders) {
        return t("view_more");
      }

      switch (order?.status) {
        case ORDER_FULFILLMENT_STATUSES.ACCEPTED:
        case ORDER_FULFILLMENT_STATUSES.REJECTED:
          return t("status.rejected.description");
        case ORDER_FULFILLMENT_STATUSES.CREATED:
          return t("status.created.description");
        case ORDER_FULFILLMENT_STATUSES.REVIEWED:
          return t("status.reviewed.description");
        default:
          return null;
      }
    };

    return (
      <div className={labelContainer}>
        <h6 className={textColor}>
          {getTitle()} <p className="inline font-bold">#{order?.number}</p>
        </h6>
        <p className={classnames(textColor, "mini")}>{getDescription()}</p>
      </div>
    );
  }

  function renderCloseIcon() {
    return !deletingOrder ? (
      <button className={closeIconContainer} onClick={deleteOrder}>
        <CloseIcon color={theme.color.text.light} />
      </button>
    ) : (
      <LoadingIndicator
        customColor={theme.color.text.light}
        sm={18}
        md={18}
        lg={18}
      />
    );
  }

  function renderPickUpDeliveryTime() {
    return (
      <div className={timeContainer}>
        <h6 className={textColor}>
          {order?.method === ORDER_METHODS.PICKUP ? t("pickup") : t("delivery")}{" "}
          <p className="inline font-semibold">
            {formatTime(order?.estimated_pickup_time)}
          </p>
        </h6>
      </div>
    );
  }

  return (
    <div className={container}>
      {loading && (
        <div className="w-full flex justify-center">
          <LoadingIndicator lg={30} md={30} sm={30} />
        </div>
      )}
      {!loading && (
        <>
          <div
            className={backgroundContainer}
            style={{ backgroundColor: getColor() }}
            onClick={goToOrder}
          />
          <div className="cursor-pointer" onClick={goToOrder}>
            {renderLabel()}
          </div>

          {order?.status === ORDER_FULFILLMENT_STATUSES.REJECTED
            ? renderCloseIcon()
            : renderPickUpDeliveryTime()}
        </>
      )}
    </div>
  );
}

const container =
  "relative flex flex-row w-full justify-between items-center lg:px-medium px-small cursor-pointer h-[58px] rounded-xl overflow-hidden";
const backgroundContainer = "absolute inset-0";

const labelContainer = "relative";

const timeContainer = "flex flex-row relative";

const closeIconContainer = "flex pt-xTiny pl-mini pb-mini relative";

const textColor = "text-white";
