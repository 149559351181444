import {useCallback} from "react";
import {useSelector} from "react-redux";
import {StoreInterface} from "../../store/types";
import {COUPON_TYPES, PROMO_CODE_DISCOUNT_TYPES} from "../../services/exports/Constants";
import useCashbackProgram from "../cashback/useCashbackProgram";

export default function useDiscounts() {
  const { cached_order } = useSelector((state: StoreInterface) => state.order);

  const { calculateSpendableCashback } = useCashbackProgram();

  const calculateRewardDiscount = () => 0;

  const calculateCashbackDiscount = (amount: number) => calculateSpendableCashback(amount) / 100;

  const calculatePromoCodeDiscount = (amount: number) => {
    const couponDetails = cached_order?.coupon_details;
    const activeCoupon = couponDetails?.components?.find((component) => !component.is_used);

    if (!activeCoupon || amount < activeCoupon.usage_threshold) {
      return 0;
    }

    return couponDetails.discount_type === PROMO_CODE_DISCOUNT_TYPES.FIXED_AMOUNT
      ? activeCoupon.discount
      : (amount * activeCoupon.discount / 100);
  };

  const calculateDiscount = useCallback(
    (amount: number) => {
      const callback =  ({
        [COUPON_TYPES.REWARD]: () => calculateRewardDiscount(),
        [COUPON_TYPES.CASHBACK]: () => calculateCashbackDiscount(amount),
        [COUPON_TYPES.PROMO_CODE]: () => calculatePromoCodeDiscount(amount),
      })[cached_order?.coupon_type] ?? null;

      return callback ? callback() : 0;
    },
    [
      cached_order?.coupon_type,
      cached_order?.coupon_details,
    ]
  )

  return {
    calculateDiscount,
  };
}
