import { useEffect } from "react";
import { actionCreators } from "../../store/actions";
import ReduxActions from "../../store/ReduxActions";

export default function useTakeout() {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get("flow") === "takeout") {
      ReduxActions.dispatch(actionCreators.dineInFlow.setDineInStatus(false));
    }
  }, [window.location.pathname]);
}
