import {
  OrderInterface,
  OrderItemInterface,
  OrderPaymentInterface,
  ProductInterface
} from "../../services/exports/Interfaces";
import {useSelector} from "react-redux";
import {StoreInterface} from "../../store/types";
import {CompleteBasketItemInterface} from "../../context/types";

export enum EVENTS {
  view_item = 'view_item',
  add_to_cart = 'add_to_cart',
  remove_from_cart = 'remove_from_cart',
  begin_checkout = 'begin_checkout',
  purchase = 'purchase',
};

export default function useGoogleAnalytics() {
  const { company } = useSelector((state: StoreInterface) => state.initialData);
  const { cached_order } = useSelector((state: StoreInterface) => state.order);

  const trackEvent = (event: EVENTS, data?: object) => window.gtag('event', event, data);

  const trackViewItem = async (product: ProductInterface) => new Promise(
    (resolve) => {
      trackEvent(EVENTS.view_item, {
        currency: company?.currency,
        value: product?.actual_price,
        order_method: cached_order?.method,
        delivery_provider: company?.delivery_provider,
        items: [{
          item_id: product.id,
          item_name: product.name,
          price: product?.actual_price,
          location_id: company?.google_places_id,
        }],
      });

      resolve(true);
    }
  );

  const trackAddToCart = async (item: CompleteBasketItemInterface) => new Promise(
    (resolve) => {
      trackEvent(EVENTS.add_to_cart, {
        currency: company?.currency,
        value: item?.actual_price ,
        order_method: cached_order?.method,
        delivery_provider: company?.delivery_provider,
        items: [{
          item_id: item.product_id,
          item_name: item.product?.name,
          price: item?.actual_price / item.quantity,
          location_id: company?.google_places_id,
        }],
      });

      resolve(true);
    }
  );

  const trackRemoveFromCart = async (item: CompleteBasketItemInterface) => new Promise(
    (resolve) => {
      trackEvent(EVENTS.remove_from_cart, {
        currency: company?.currency,
        value: item?.actual_price ,
        order_method: cached_order?.method,
        delivery_provider: company?.delivery_provider,
        items: [{
          item_id: item.product_id,
          item_name: item.product?.name,
          price: item?.actual_price / item.quantity,
          location_id: company?.google_places_id,
        }],
      });

      resolve(true);
    }
  );

  const trackBeginCheckout = async (order: OrderInterface) => new Promise(
    (resolve) => {
      trackEvent(EVENTS.begin_checkout, {
        value: order.total,
        currency: company?.currency,
        order_method: order.method,
        delivery_provider: company?.delivery_provider,
        items: order.line_items.map((item, index) => ({
          item_id: item.product_id,
          item_name: item.name,
          price: item.actual_price / item.quantity,
          quantity: item.quantity,
          index,
          location_id: company?.google_places_id,
        })),
      });

      resolve(true);
    }
  );

  const trackPurchase = async (payment: OrderPaymentInterface, order: OrderItemInterface) => new Promise(
    (resolve) => {
      trackEvent(EVENTS.purchase, {
        transaction_id: payment.id,
        value: payment.total_amount,
        currency: company?.currency,
        order_method: order.method,
        delivery_provider: company?.delivery_provider,
        items: order.line_items.map((item, index) => ({
          item_id: item.product_id,
          item_name: item.name,
          price: item.actual_price / item.quantity,
          quantity: item.quantity,
          index,
          location_id: company?.google_places_id,
        })),
      });

      resolve(true);
    }
  );

  return {
    trackEvent,
    trackViewItem,
    trackAddToCart,
    trackRemoveFromCart,
    trackBeginCheckout,
    trackPurchase,
  };
}