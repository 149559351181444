import React, {useEffect, useState} from "react";
import {CompanyDetailsInterface} from "../services/exports/Interfaces";
import SearchIllustration from "../assets/illustrations/SearchIllustration";
import useTheme from "../hooks/ui/useTheme";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import InitialLoadingManager from "../services/api/InitialLoadingManager";
import toast from "react-hot-toast";
import LoadingPage from "../components-library/loading/LoadingPage";
import {StoreInterface} from "../store/types";

export default function PageNotFound() {
  const {t} = useTranslation(null, {keyPrefix: 'Pages:PageNotFound'});

  const { franchise } = useSelector((state: StoreInterface) => state.initialData);

  const theme = useTheme();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadData()
  }, []);

  async function loadData() {
    if (!franchise) {
      return setLoading(false);
    }

    const { success, response } = await InitialLoadingManager.getFranchiseData();
    setLoading(false);

    if (! success) {
      return toast.error(t('toasts.failed_to_load_stores'));
    }

    setData(response?.data?.data);
  }

  function createUrl(slug: string): string {
    return `https://${franchise.slug}.${import.meta.env.VITE_BASE_DOMAIN}/${slug}`;
  }

  const renderDetails = () => {
    if (data?.companies?.length > 0) {
      return (
        <div className={wrapper}>
          <h2 className={titleStyle}>{t('title')}</h2>
          <h3 className={descriptionStyle}>{t('description')}</h3>
          {data.companies.map((company: CompanyDetailsInterface) => {
            const url = createUrl(company?.slug);

            return (
              <a href={url} key={url}>
                <h3 className={linkStyle}>{url}</h3>
              </a>
            );
          })}
        </div>
      )
    }

    return (
      <div className={wrapper}>
        <h2 className={titleStyle}>{t('not_found')}</h2>
      </div>
    );
  }

  return (
    <>
      {loading && <LoadingPage />}
      <SearchIllustration
        className={illustrationStyle}
        color={theme.color.brand.primary}
      />
      {renderDetails()}
    </>
  );
}

const wrapper =
  "flex flex-col w-full bg-background-inkWhite_white1 items-center lg:w-2/3 w-full mx-auto px-small";

const titleStyle = "mb-small text-center";

const descriptionStyle = "text-brand-primary mb-small text-center";

const linkStyle =
  "text-center text-brand-primary underline underline-offset-4 decoration-solid mb-small";

const illustrationStyle = "lg:w-1/4 md:w-1/2 w-3/4 mx-auto my-medium";
