import IllustrationExplainer from "../IllustrationExplainer";
import NoInternetIllustration from "../../assets/illustrations/NoInternetIllustration";
import CustomButton from "../buttons/CustomButton";
import RefreshIcon from "../../assets/logo/RefreshIcon";
import useTheme from "../../hooks/ui/useTheme";
import {useTranslation} from "react-i18next";
import useConfig from "../../hooks/ui/useConfig";

export default function NoInternetConnectionPage() {
  const { t } = useTranslation(null, {keyPrefix: 'Components:Internet:NoInternetConnectionPage'});

  const { logo_url } = useConfig();
  const theme = useTheme();

  function onRefresh(): void {
    window.location.reload();
  }

  function renderHeader() {
    return (
      <header className={headerContainer}>
        {logo_url && (
          <img src={logo_url} alt="Logo" className={companyLogo} />
        )}
      </header>
    );
  }
  return (
    <>
      {renderHeader()}
      <main className={container}>
        <IllustrationExplainer
          illustration={
            <NoInternetIllustration className={noInternetIllustrationStyle} />
          }
          title={t('title')}
          description={t('description')}
        />
        <div className={buttonContainer}>
          <CustomButton
            title={
              <div className={refreshContainer}>
                <RefreshIcon
                  className={refreshIconStyle}
                  color={theme.color.text.light}
                />
                {t('custom_button.reload')}
              </div>
            }
            onClick={onRefresh}
          />
        </div>
      </main>
    </>
  );
}

const container =
  "fixed inset-0 flex flex-col h-full w-full bg-background-inkWhite-white_1 justify-center items-center lg:w-1/2 w-full mx-auto px-small";

const headerContainer =
  "border-brand-inkGrey-grey_2 border-b flex flex-row justify-center py-mini bg-background-inkWhite-white_1";

const buttonContainer = "lg:mt-[36px] mt-medium";

const refreshContainer = "flex flex-row items-center";

const companyLogo = "h-12";

const noInternetIllustrationStyle = "w-1/2 mx-auto";

const refreshIconStyle = "w-4 h-4 mr-tiny";
