import { DataWrappedResponse, DataWrapper } from "../exports/Types";
import BackendApiClient from "./BackendApiClient";

class ProductManager {
  public async likeProduct(id: number): Promise<DataWrappedResponse<DataWrapper<void>>> {
    return BackendApiClient.request({
      method: 'POST',
      url: `/products/${id}/like`,
    });
  }

  public async unlikeProduct(id: number): Promise<DataWrappedResponse<DataWrapper<void>>> {
    return BackendApiClient.request({
      method: 'POST',
      url: `/products/${id}/unlike`,
    });
  }
}

export default new ProductManager();
