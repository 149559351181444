import React, {ReactElement} from "react";
import PromoCodeIcon from "../../assets/logo/PromoCodeIcon";
import classnames from "classnames";

interface Props {
  title: string;
  description: string|ReactElement;
  footer?: ReactElement;
  className?: string;
  onClick?: () => void;
}

export default function DiscountCard(props: Props): ReactElement {
  const { title, description, footer, className, onClick } = props;

  return (
    <div className={classnames('rounded-md h-full flex justify-between', className)} onClick={onClick}>
      <div className="w-full px-small py-xsmall border-t border-b border-l rounded-l-lg flex flex-col justify-between">
        <div>
          <h6 className="lowercase">{title}</h6>
          <p className="text-tiny text-brand-inkGrey-grey_4 mt-tiny">{description}</p>
        </div>
        {footer}
      </div>
      <div className="bg-brand-primary px-small rounded-r-lg flex">
        <PromoCodeIcon className="h-5 w-5 my-auto" color="white" />
      </div>
    </div>
  )
};