import { actionCreators } from "../../store/actions";
import ReduxActions from "../../store/ReduxActions";
import {Franchise, InitialDataResponseInterface} from "../exports/Interfaces";
import BackendApiClient from "./BackendApiClient";
import {DataWrappedResponse, DataWrapper, Response} from "../exports/Types";
import DomainResource from "../resources/DomainResource";
import AuthorizationTokenStorage from "../auth/AuthorizationTokenStorage";
import collect from "collect.js";

class InitialLoadingManager {
  private subdomain: string = DomainResource.extractSubdomain();

  public async getFranchiseData(hideLoading: boolean = false): Promise<DataWrappedResponse<DataWrapper<Franchise>>> {
    if (!hideLoading) {
      this.toggleLoading(true);
    }

    const request = await BackendApiClient.request({
      method: "GET",
      url: `/franchises/${this.subdomain}`,
    });
    if (!hideLoading) {
      this.toggleLoading(false);
    }

    const { response, success } = request;

    if (success) {
      ReduxActions.dispatch(
        actionCreators.initialData.setFranchise(response.data.data)
      );
    }

    return request;
  }

  public async getDefaultCompanySlug(): Promise<null | string> {
    const { success, response } = await this.getFranchiseData(true);

    if (! success) {
      return null;
    }

    return collect(response?.data.data.companies).first()?.slug;
  }

  public async getCompanyData(
    slug: string = null,
    hideLoading: boolean = false,
    shouldRetry: boolean = true,
  ): Promise<DataWrappedResponse<InitialDataResponseInterface>> {
    if (!hideLoading) {
      this.toggleLoading(true);
    }

    if (!slug) {
      slug = await this.getDefaultCompanySlug();
    }

    const request = await BackendApiClient.request({
      method: "GET",
      url: `/franchises/${this.subdomain}/companies/${slug}/data`,
    });
    if (!hideLoading) {
      this.toggleLoading(false);
    }

    const { response, success, status } = request;

    if (success) {
      this.saveDataRedux(response?.data);
    }

    if (status === 404) {
      if (slug !== null || !shouldRetry) {
        return this.handleCompanyNotFound();
      }

      return this.getCompanyData(
        await this.getDefaultCompanySlug(),
        hideLoading,
        false,
      );
    }

    return request;
  }

  public async getInitialData(
    slug: string = null,
    hideLoading: boolean = false,
    shouldRetry: boolean = true,
  ): Promise<DataWrappedResponse<InitialDataResponseInterface>> {
    if (!hideLoading) {
      this.toggleLoading(true);
    }

    if (!slug) {
      slug = await this.getDefaultCompanySlug();
    }

    const request = await BackendApiClient.request({
      method: "GET",
      url: `/franchises/${this.subdomain}/companies/${slug}/initial-data`,
    });
    if (!hideLoading) {
      this.toggleLoading(false);
    }

    const { response, success, status } = request;

    if (success) {
      this.saveDataRedux(response?.data);
    }

    if (status === 404) {
      if (slug !== null || !shouldRetry) {
        return this.handleCompanyNotFound();
      }

      return this.getInitialData(
        await this.getDefaultCompanySlug(),
        hideLoading,
        false
      );
    }

    return request;
  }

  public init(
    slug: string = null,
    hideLoading: boolean = false,
  ): Promise<DataWrappedResponse<InitialDataResponseInterface>> {
    if (AuthorizationTokenStorage.getToken()) {
      return this.getInitialData(slug, hideLoading);
    }

    return this.getCompanyData(slug, hideLoading);
  }

  private saveDataRedux(response: InitialDataResponseInterface): void {
    ReduxActions.batchActions([
      actionCreators.initialData.setAllData(response),
      actionCreators.profile.setProfile(response?.profile),
      actionCreators.paymentOptions.setPaymentOptions(response?.payment_options),
    ]);
  }

  private handleCompanyNotFound(): Response<any> {
    ReduxActions.dispatch(
      actionCreators.initialData.updateCompany({
        slug: null,
      })
    );

    window.location.pathname !== '/404' && window.location.replace('/404');

    return {
      success: false,
      status: 404,
      response: null,
    }
  }

  private toggleLoading(loading?: boolean) {
    ReduxActions.dispatch(actionCreators.initialData.toggleLoading(loading));
  }
}

export default new InitialLoadingManager();
