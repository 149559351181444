import React from "react";
import useTheme from "../../hooks/ui/useTheme";
import { ThemeInterface } from "../../services/exports/Interfaces";

interface Props {
  className?: string;
  style?: Object;
  color?: string;
}

export default function WalkingIcon(props: Props) {
  const { className, color, style } = props;
  const theme: ThemeInterface = useTheme();
  return (
    <svg
      className={className ?? "h-4 w-4"}
      viewBox="0 0 11 16"
      fill="none"
      style={style}
    >
      <path
        d="M5.38522 3.51887C6.2217 3.51887 6.90094 2.82704 6.90094 2.02201C6.90094 1.17925 6.2217 0.5 5.38522 0.5C4.54874 0.5 3.88836 1.17925 3.88836 2.02201C3.88836 2.82704 4.54874 3.51887 5.38522 3.51887ZM7.52987 15.5C7.88836 15.5 8.20912 15.2358 8.20912 14.7956C8.20912 14.6321 8.16509 14.4811 8.07704 14.305L6.73113 11.5755C6.61164 11.3302 6.47327 11.1101 6.34748 10.934L5.52358 9.76415L5.5739 9.60692C5.80031 8.88365 5.88836 8.4434 5.93868 7.75157L6.07704 5.80818C6.14623 4.8522 5.59277 4.07233 4.59277 4.07233C3.86321 4.07233 3.33491 4.46855 2.65566 5.12893L1.62421 6.15409C1.27201 6.5 1.15881 6.78302 1.12736 7.22327L0.995283 8.82076C0.963836 9.25472 1.20912 9.53774 1.59906 9.56289C1.97013 9.58805 2.24686 9.36792 2.29088 8.90252L2.43553 7.15409L3.0456 6.61321C3.15881 6.51258 3.32233 6.56918 3.30975 6.73899L3.19654 8.31132C3.13994 9.07233 3.47327 9.62579 3.85063 10.0975L5.19654 11.7893C5.32862 11.9528 5.34748 12.0157 5.41038 12.1352L6.84434 15.0472C7.01415 15.3868 7.26572 15.5 7.52987 15.5ZM7.82547 8.07233H9.34748C9.78774 8.07233 10.0645 7.81447 10.0645 7.42453C10.0645 7.03459 9.78145 6.77673 9.3412 6.77673H7.75L6.64308 5.57547L6.52358 7.30503L6.99528 7.77673C7.2217 8.00314 7.43553 8.07233 7.82547 8.07233ZM1.42925 15.5C1.64937 15.5 1.84434 15.3994 1.9827 15.2358L4.00786 12.827C4.21541 12.5755 4.24057 12.5126 4.32862 12.2673C4.37893 12.1038 4.42296 11.9465 4.46069 11.7956L3.30346 10.3491L2.96384 11.9277L1.0456 14.2044C0.869497 14.4057 0.75 14.5818 0.75 14.8333C0.75 15.2233 1.0456 15.5 1.42925 15.5Z"
        fill={color ?? theme.color.brand.inkGrey.grey_3}
      />
    </svg>
  );
}
