import { useEffect, useRef } from "react";
import useDineInFlow from "../hooks/global/useDineInFlow";
import useFocus from "../hooks/utility/useFocus";
import usePusher from "../hooks/global/usePusher";
import { partyChannel, restaurantChannel } from "../services/pusher/Channels";
import { PUSHER_EVENTS } from "../services/exports/Constants";
import PartiesManager from "../services/api/PartiesManager";
import {
  CompanyDetailsInterface,
  OrderItemInterface,
  OrderPaymentInterface,
  PartyInterface,
  UserInterface,
} from "../services/exports/Interfaces";
import { collect } from "collect.js";
import useToast from "../hooks/utility/useToast";
import useStore from "../hooks/global/useStore";
import { useSelector } from "react-redux";
import ReduxActions from "../store/ReduxActions";
import { actionCreators } from "../store/actions";
import { useTranslation } from "react-i18next";
import UserInitials from "../components-library/profile/UserInitials";
import PartyResource from "../services/resources/PartyResource";
import {StoreInterface} from "../store/types";
import useAppMethods from "../hooks/utility/useAppMethods";

export default function PartyChannel() {
  const { t } = useTranslation(undefined, {
    keyPrefix: "PusherChannels:PartyChannel",
  });

  const { company } = useSelector((state: StoreInterface) => state.initialData);
  const { profile } = useStore()?.profile;

  const { isDineIn, party, table, unpaidAmount, userPayments } =
    useDineInFlow();

  const { connect, isConnected, disconnect } = usePusher();

  const toast = useToast();

  const { formatCurrency, toggleAllOrdersPaidModal } = useAppMethods();

  const userPaymentsRef = useRef(userPayments);
  userPaymentsRef.current = userPayments;

  const unpaidAmountRef = useRef(unpaidAmount);
  unpaidAmountRef.current = unpaidAmount;

  const tableRef = useRef(table);
  tableRef.current = table;

  const partyRef = useRef(party);
  partyRef.current = party;

  useFocus(
    () => connectChannel(),
    () => null,
    true
  );

  useEffect(() => {
    if (partyRef.current?.id) {
      connectChannel();
    }

    return () => disconnect();
  }, [partyRef.current?.id]);

  useEffect(() => {
    if (
      !isDineIn ||
      partyRef.current?.deleted_at ||
      !collect(partyRef.current?.users).firstWhere("id", profile?.id)
    ) {
      disconnect();
    }
  }, [isDineIn, partyRef.current?.deleted_at, partyRef.current?.users]);

  async function connectChannel() {
    if (
      isConnected() ||
      !partyRef.current?.id ||
      partyRef.current?.deleted_at
    ) {
      return;
    }

    const pusher = await connect();
    pusher
      .subscribe(partyChannel(partyRef.current?.id))
      .bind("pusher:subscription_error", (error: any) => {
        switch (error?.status) {
          case 403:
            PartyResource.toggleAllOrdersPaidModal(partyRef.current);
            break;
        }
      })
      .bind(PUSHER_EVENTS.PARTY_UPDATED, partyUpdatedHandler)
      .bind(PUSHER_EVENTS.PARTY_TABLE_UPDATED, handleTableUpdated)
      .bind(
        PUSHER_EVENTS.PARTY_MEMBERSHIP_CREATED,
        partyMembershipCreatedHandler
      )
      .bind(
        PUSHER_EVENTS.PARTY_MEMBERSHIP_DELETED,
        partyMembershipDeletedHandler
      )
      .bind(PUSHER_EVENTS.PARTY_ORDER_CREATED, partyOrderCreatedHandler)
      .bind(PUSHER_EVENTS.TRANSACTION_PAID, transactionPaidHandler)
      .bind(PUSHER_EVENTS.REFUND_CREATED, refundCreatedHandler)
      .bind(PUSHER_EVENTS.SUB_ORDER_CANCELLED, subOrderCancelledHandler);

    pusher
      .subscribe(restaurantChannel(company?.id))
      .bind(PUSHER_EVENTS.ORDERS_PAUSED, companyUpdatedHandler)
      .bind(PUSHER_EVENTS.ORDERS_UNPAUSED, companyUpdatedHandler);
  }

  async function partyUpdatedHandler() {
    await PartiesManager.show(partyRef.current?.id);
  }

  async function handleTableUpdated(data: {
    user: UserInterface;
    party: PartyInterface;
  }) {
    if (
      data?.party?.table_id === tableRef?.current?.id ||
      data?.user?.id === profile?.id
    ) {
      return;
    }

    toast(
      t("toasts.table_changed", {
        user: data?.user?.first_name,
        table: data?.party?.table?.number,
      }),
      {
        duration: Infinity,
      }
    );
  }

  async function partyMembershipCreatedHandler(data: UserInterface) {
    if (profile?.id === data?.id) {
      return;
    }

    toast(
      t("toasts.member_joined", {
        user: data?.first_name,
      }),
      { icon: <UserInitials name={data?.first_name} /> }
    );
  }

  async function partyMembershipDeletedHandler(data: UserInterface) {
    if (profile?.id === data?.id) {
      return;
    }

    toast(
      t("toasts.member_left", {
        user: data?.first_name,
      }),
      { icon: <UserInitials name={data?.first_name} /> }
    );
  }

  async function partyOrderCreatedHandler(data: OrderItemInterface) {
    if (profile?.id === data?.user?.id) {
      return;
    }

    const lineItems = collect(data?.line_items)
      .map((item) => `${item?.quantity}x ${item?.name}`)
      .implode(", ");

    toast(
      t("toasts.member_ordered", {
        user: data?.user?.first_name,
        lineItems,
      }),
      { icon: <UserInitials name={data?.user?.first_name} /> }
    );
  }

  async function transactionPaidHandler(data: OrderPaymentInterface) {
    if (profile?.id === data?.user_id && userPaymentsRef.current.count() > 1) {
      return;
    }

    if (profile?.id === data?.user_id) {
      return;
    }

    const amount = formatCurrency(data?.total_amount);
    const leftToPay = unpaidAmountRef.current - data?.products_amount;

    if (leftToPay <= 0) {
      toggleAllOrdersPaidModal({
        openModal: true,
        order: data,
        party: partyRef.current,
      });

      if (data?.total_amount == 0) {
        return toast(
          t("toasts.table_closed", {
            user: data?.user?.first_name,
          }),
          { icon: <UserInitials name={data?.user?.first_name} /> }
        );
      }

      return toast(
        t("toasts.all_paid", {
          user: data?.user?.first_name,
          amount,
        }),
        { icon: <UserInitials name={data?.user?.first_name} /> }
      );
    }

    toast(
      t("toasts.left_to_pay", {
        user: data?.user?.first_name,
        leftToPay: formatCurrency(leftToPay),
        amount,
      }),
      { icon: <UserInitials name={data?.user?.first_name} /> }
    );
  }

  async function refundCreatedHandler(data: OrderPaymentInterface) {
    if (data?.user?.id !== profile?.id) {
      return;
    }

    toast(
      t("toasts.refund_created", {
        amount: formatCurrency(data.amount),
      }),
      {
        icon: "💶",
        duration: Infinity,
      }
    );
  }

  async function subOrderCancelledHandler(data: OrderItemInterface) {
    if (data?.user?.id !== profile?.id) {
      return;
    }

    toast.error(t("toasts.order_canceled"), { duration: Infinity });
  }

  async function companyUpdatedHandler(data: CompanyDetailsInterface) {
    ReduxActions.dispatch(
      actionCreators.initialData.setCompany({
        ...company,
        ...data,
      })
    );
  }

  return null;
}
