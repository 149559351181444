import classnames from "classnames";

interface Props {
  className?: string;
  children: any;
}

export default function Card(props: Props) {
  const {className, children} = props;

  return (
    <div
      className={classnames('flex flex-col w-full rounded-xl shadow p-small bg-background-inkWhite-white_0', className)}>
      {children}
    </div>
  );
}