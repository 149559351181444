import React from "react";
import useTheme from "../../hooks/ui/useTheme";
import { ThemeInterface } from "../../services/exports/Interfaces";

interface Props {
  className?: string;
  style?: Object;
  color?: string;
}

export default function BackIcon(props: Props) {
  const { className, color, style } = props;
  const theme: ThemeInterface = useTheme();
  return (
    <svg
      className={className ?? "h-6 w-6"}
      viewBox="0 0 15 24"
      fill="none"
      style={style}
    >
      <path
        d="M4.99492 12L13.463 20.4681C14.271 21.276 14.271 22.586 13.463 23.394C12.655 24.202 11.345 24.202 10.537 23.394L0.605986 13.463C-0.201995 12.655 -0.201995 11.345 0.605986 10.537L10.537 0.605986C11.345 -0.201995 12.655 -0.201995 13.463 0.605986C14.271 1.41397 14.271 2.72396 13.463 3.53194L4.99492 12Z"
        fill={color ?? theme.color.brand.inkGrey.grey_3}
      />
    </svg>
  );
}
