export default {
  header: "Order Summary",
  table: {
    add_table_number: "Add table number",
  },
  buttons: {
    checkout: "Checkout",
    continue: "Continue",
    add_products: "+ Add products",
    add_items: "+ Add more items",
    order_ahead: 'Order ahead only',
  },
  empty_order_basket: {
    description: "Your basket is empty <br/> Add items to get started",
  },
  promo_code_banner: {
    single_use: '{{discount}} discount - {{code}}',
    multi_use: '{{discount}} discount on {{count}} orders - {{code}}',
    redeem: 'REDEEM',
    redeemed: 'REDEEMED',
  }
};
