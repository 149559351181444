import React from "react";
import useTheme from "../../hooks/ui/useTheme";
import { ThemeInterface } from "../../services/exports/Interfaces";

interface Props {
  className?: string;
  style?: Object;
  color?: string;
}

export default function DiscountIcon(props: Props) {
  const { className, color, style } = props;
  const theme = useTheme();
  return (
    <svg
      className={className ?? "h-6 w-6"}
      viewBox="0 0 18 19"
      fill="none"
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.8041 2.46948C9.50106 2.41615 9.19034 2.50531 8.96167 2.7112L1.76917 9.18736C1.35875 9.55691 1.32561 10.1892 1.69516 10.5996L6.79129 16.2595C7.16084 16.6699 7.79314 16.703 8.20357 16.3335L15.4325 9.82447C15.6402 9.63749 15.7602 9.37214 15.7634 9.09272L15.8171 4.37836C15.8226 3.88866 15.4727 3.46697 14.9904 3.3821L9.8041 2.46948ZM11.7485 8.67676C12.715 8.67676 13.4985 7.89326 13.4985 6.92676C13.4985 5.96026 12.715 5.17676 11.7485 5.17676C10.782 5.17676 9.99853 5.96026 9.99853 6.92676C9.99853 7.89326 10.782 8.67676 11.7485 8.67676Z"
        fill={color ?? theme.color.brand.inkGrey.grey_3}
      />
    </svg>
  );
}
