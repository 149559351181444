export default {
  titles: {
    main: 'We can’t accept more online orders.',
    unpaid_amount_left: 'We can’t accept more online orders. Please pay remaining bill here',
  },
  description: 'Online orders are not available now. Please contact your server if you have any questions.',
  buttons: {
    okay: 'Okay',
  },
};
