import {PaymentMethod, RoomServiceLocationType} from "../../../../services/exports/Constants";

export default {
  header: {
    checkout: "Kasse",
    order_summary: "Bestellübersicht",
    price_details: "Preis Details",
  },
  labels: {
    today: 'heute',
  },
  payment_breakdown: {
    subtotal: "Zwischensumme",
    promo_code: "Promo code",
    delivery_fee: "Liefergebühr",
    service_fee: {
      title: "Onlinezuschlag",
      description: 'Wir sind dankbar, wenn du hier bestellst, denn so sparen wir Tausende von EUR an Lieferando-Gebühren & am Telefon verbrachte Zeit und können dir den besten Service bieten. Wir hoffen es ist okay ist, dass wir einen Cent-Betrag auf den regulären Bestellpreis aufschlagen, um die Kosten für den Betrieb dieses Online Dienstes zu decken. Der Zuschlag ist nie mehr als Cent-Beträge und wir versuchen, dies mit Rabatten, Treueprämien und der bequemsten Bestell-Experience für dich auszugleichen. Bitte teile uns mit, wenn dies für dich nicht in Ordnung ist.',
    },
    payment_method_upcharge_fee: {
      [PaymentMethod.Paypal]: {
        title: 'PayPal Gebühr',
        description: 'Jedes Mal, wenn ein Kunde mit PayPal zahlt, nimmt PayPal 6-7% Gebühren. Also verlieren wir bei 100€ schon 6-7€. Daher müssen wir leider einen Aufschlag von 3,5% für die Nutzung von PayPal erheben. 🤯 Bitte erwäge, eine der anderen sicheren und verschlüsselten Zahlungsmethoden beim Bezahlen zu nutzen. Wir sind sehr dankbar für dein Verständnis und deine Unterstützung! 🙏',
      },
      [PaymentMethod.Paypal]: {
        title: 'PayPal Gebühr',
        description: 'Jedes Mal, wenn ein Kunde mit PayPal zahlt, nimmt PayPal 6-7% Gebühren. Also verlieren wir bei 100€ schon 6-7€. Daher müssen wir leider einen Aufschlag von 3,5% für die Nutzung von PayPal erheben. 🤯 Bitte erwäge, eine der anderen sicheren und verschlüsselten Zahlungsmethoden beim Bezahlen zu nutzen. Wir sind sehr dankbar für dein Verständnis und deine Unterstützung! 🙏',
      },
    },
    tax: "Taxes",
    points_applied: {
      title: "{{count}} pts angewandt",
      title_other: "{{count}} pts angewandt",
      description: "*Punkte werden automatisch angewandt",
    },
  },
  available_codes: {
    title: "Verfügbar Promo Codes",
    code: {
      copy: "KOPIEREN",
    },
  },
  minimum_value_error_label: {
    description:
      "Mindestbestellwert für diese Promo beträgt (abz. Lieferkosten & Trinkgeld)  ",
  },
  enter_promo_code: {
    label: 'Rabatt Code einlösen',
    placeholder: "Promo Code eingeben",
    button: {
      apply: "Anwenden",
      remove: "Entfernen",
    },
  },
  tips: {
    title: "Wähle ein Trinkgeld",
    description: "Unser Team wird dankbar sein 🙏",
  },
  pay_button: {
    total: "Gesamt",
    close_table: "Zahlen & Tisch verlassen",
    complete_payment: "Zahlung Abschließen",
    pay_and_place_order: "Zahlen & Bestellen",
    place_order: "Bestellen",
    add_items: "rtikel Hinzufügen",
    remove_items: "Artikel entfernen",
  },
  empty_order_basket: {
    title: "Los geht's",
    description: "Artikel hinzufügen, um loszulegen",
    custom_button: {
      add_products: "+ Artikel hinzufügen",
    },
  },
  loading: {
    processing:
      "Bitte verlasse nicht die Seite, während wir deine Zahlung bearbeiten.",
  },
  error_messages: {
    payment_unsuccessful: {
      title: "Zahlung fehlgeschlagen",
      description:
        "Bitte überprüfe deine Zahlungsinformationen und versuchen es erneut.",
    },
  },
};
