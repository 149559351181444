import InitialDataContext from "../context/InitialDataContext";
import { useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  CompanyDetailsInterface,
  InitialDataResponseInterface,
  InitialDataStoreInterface,
} from "../services/exports/Interfaces";
import InitialLoadingManager from "../services/api/InitialLoadingManager";
import { DataWrappedResponse } from "../services/exports/Types";
import { StoreInterface } from "../store/types";

interface Props {
  children: any;
}

export default function InitialDataProvider(props: Props): JSX.Element {
  const { children } = props;

  const { initialData: data } = useSelector((state: StoreInterface) => state);

  const [loading, setLoading] = useState(false);
  const toggleLoading = () => setLoading((prev) => !prev);

  const loadingRef = useRef(loading);
  loadingRef.current = loading;

  const dataRef = useRef(data);
  dataRef.current = data;

  const refresh = async (
    slug: string = null,
    hideLoading: boolean = false
  ): Promise<
    DataWrappedResponse<
      | InitialDataResponseInterface
      | CompanyDetailsInterface
      | InitialDataStoreInterface
    >
  > => {
    if (loadingRef.current) {
      return {
        success: true,
        response: {
          data,
        },
        status: 200,
      };
    }
   
    return forceRefresh(slug, hideLoading);
  };

  const forceRefresh = async (
    slug: string = null,
    hideLoading: boolean = false
  ): Promise<
    DataWrappedResponse<
      | InitialDataResponseInterface
      | CompanyDetailsInterface
      | InitialDataStoreInterface
    >
  > => {
    toggleLoading();
    const response = await InitialLoadingManager.init(
      slug ?? dataRef.current?.company?.slug,
      hideLoading
    );
    setTimeout(() => toggleLoading(), 300);

    return response;
  };

  const context = useMemo(
    () => ({
      data: dataRef.current,
      loading: loadingRef.current,
      refresh,
      forceRefresh,
    }),
    [dataRef.current, loadingRef.current]
  );

  return (
    <InitialDataContext.Provider value={context}>
      {children}
    </InitialDataContext.Provider>
  );
}
