import React from "react";
import useTheme from "../../hooks/ui/useTheme";
import { ThemeInterface } from "../../services/exports/Interfaces";

interface Props {
  className?: string;
  style?: Object;
  color?: string;
}

export default function MinusIcon(props: Props) {
  const { className, color, style } = props;
  const theme: ThemeInterface = useTheme();
  return (
    <svg
      className={className ?? "h-4 w-4"}
      viewBox="0 0 14 12"
      fill="none"
      style={style}
    >
      <path
        d="M12.0704 5.00002C12.5838 5.00002 13 5.44773 13 6.00002C13 6.5523 12.5838 7.00002 12.0704 7.00002H1.92958C1.41619 7.00002 1 6.5523 1 6.00002C1 5.44773 1.41619 5.00002 1.92958 5.00002H12.0704Z"
        fill={color ?? theme.color.brand.inkGrey.grey_3}
        stroke={color ?? theme.color.brand.inkGrey.grey_3}
        strokeWidth="0.55"
      />
    </svg>
  );
}
