export default {
  note: {
    title: 'Notes for the kitchen',
    placeholder: 'Have an allergy, dietary restrictions, or special requests?',
  },
  buttons: {
    add: "+ Add",
  },
  error_messages: {
    missing_fields: "Please select all required fields.",
    product_unavailable: "Sold out!",
  },
};
