import collect from "collect.js";
import ReduxActions from "../../store/ReduxActions";
import PartiesManager from "../api/PartiesManager";
import { PartyInterface, ProfileInterface } from "../exports/Interfaces";
import GlobalAppUtility from "../utilities/GlobalAppUtility";

export default class PartyResource {
  static async toggleAllOrdersPaidModal(
    oldParty: PartyInterface
  ): Promise<void> {
    if (!oldParty) {
      return;
    }

    const { response, success } = await PartiesManager.show(oldParty?.id);

    if (!success) {
      return;
    }

    let newParty = response?.data?.data;

    let lastPayment = collect(newParty?.order?.payments)?.last();

    if (
      lastPayment &&
      lastPayment?.user_id !== this.getProfile()?.id &&
      !oldParty?.deleted_at &&
      typeof newParty?.deleted_at === "string"
    ) {
      GlobalAppUtility.globalAppMethodsContext.toggleAllOrdersPaidModal({
        openModal: true,
        order: lastPayment,
        party: newParty,
      });
    }
  }

  private static getProfile(): ProfileInterface {
    return ReduxActions.getStore()?.profile?.profile;
  }
}
