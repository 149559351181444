import useDineInFlow from "../global/useDineInFlow";
import {useSelector} from "react-redux";
import collect, {Collection} from "collect.js";
import HelperMethods from "../../services/exports/HelperMethods";
import {useTranslation} from "react-i18next";
import { StoreInterface } from "../../store/types";

export interface TipOption {
  index: number;
  percentage: null | number;
  multiplier: null | number;
  description?: string;
  is_default?: boolean;
  is_zero?: boolean;
  is_custom?: boolean;
}

export default function useTips() {
  const { t } = useTranslation(undefined, { keyPrefix: 'Hooks:Order:UseTips' });

  const { company } = useSelector((store: StoreInterface) => store.initialData);
  const { isDineIn } = useDineInFlow();

  const options: Collection<TipOption> = collect([
    {
      index: 0,
      percentage: null,
      multiplier: null,
      description: t('zero_option'),
      is_default: !company?.default_tip_percentage && !isDineIn,
      is_zero: true,
    },
    {
      index: 1,
      percentage: 5,
      multiplier: 0.05,
      description: '👌',
      is_default: company?.default_tip_percentage === 5,
    },
    {
      index: 2,
      percentage: 10,
      multiplier: 0.1,
      description: '🤩',
      is_default: company?.default_tip_percentage === 10 || (!company?.default_tip_percentage && isDineIn),
    },
    {
      index: 3,
      percentage: 15,
      multiplier: 0.15,
      description: '🥳',
      is_default: company?.default_tip_percentage === 15,
    },
    {
      index: 4,
      percentage: null,
      multiplier: null,
      description: t('custom_option'),
      is_custom: true,
    },
  ]);

  const defaultOption: TipOption | null = options.firstWhere('is_default');
  const customOption: TipOption = options.firstWhere('is_custom');

  const calculate = (amount: null|number, option?: TipOption): number => {
    if (! amount || !option?.percentage) {
      return 0;
    }

    return HelperMethods.roundNumberWithPrecision(amount * option.multiplier, 2);
  }

  return {
    options,
    defaultOption,
    customOption,
    calculate
  };
}