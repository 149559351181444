import { OrderItemInterface } from "../../services/exports/Interfaces";
import { useMemo, useState } from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import classnames from "classnames";
import LineItem from "./LineItem";
import ChevronDownIcon from "../../assets/logo/ChevronDownIcon";
import {useTranslation} from "react-i18next";
import useAppMethods from "../../hooks/utility/useAppMethods";

interface Props {
  data: OrderItemInterface;
  isCanceled?: boolean;
  className?: string;
}

export default function SubOrderItem(props: Props) {
  const { t } = useTranslation(undefined, {keyPrefix: 'Components:DineIn:SubOrderItem'});

  const { data, isCanceled, className } = props;

  const { formatCurrency } = useAppMethods();

  const [isCollapsed, setIsCollapsed] = useState(true);
  const toggleIsCollapsed = () => setIsCollapsed(!isCollapsed);

  const renderLineItems = useMemo(() => {
    if (isCollapsed) {
      return null;
    }

    return (
      data?.line_items?.length > 0 &&
      data?.line_items.map((item, index) => (
        <LineItem
          data={item}
          className="mt-small"
          variant="secondary"
          key={`line-item-${item?.id}`}
        />
      ))
    );
  }, [isCollapsed, data?.line_items]);

  return (
    <Accordion
      style={{
        boxShadow: "none",
        backgroundColor: "transparent",
      }}
      sx={{
        "&:before": {
          display: "none",
        },
      }}
      expanded={!isCollapsed}
      onChange={toggleIsCollapsed}
      className={className}
    >
      <AccordionSummary
        expandIcon={<ChevronDownIcon className="h-3 w-3" />}
        style={{
          margin: 0,
          padding: 0,
          minHeight: 0,
        }}
        classes={{
          content: "!m-none",
        }}
      >
        <div className="w-full mr-small">
          <div className="flex justify-between">
            <div className="flex">
              <p
                className={classnames(
                  "text-tiny my-auto text-brand-inkGrey-grey_4",
                  { "text-brand-text-warning": isCanceled }
                )}
              >
                {
                  isCanceled
                    ? t('labels.cancelled_order', {number : data?.number})
                    : t('labels.order', {number : data?.number})
                }
              </p>
            </div>
            <div className="flex">
              {data.total_discount > 0 && !isCanceled && (
                <p
                  className={classnames(
                    "text-mini text-brand-text-grey my-auto mr-mini line-through",
                    {
                      "text-brand-text-warning": isCanceled,
                    }
                  )}
                >
                  {formatCurrency(data.sub_total)}
                </p>
              )}
              <p
                className={classnames("text-mini my-auto", {
                  "text-brand-text-warning line-through": isCanceled,
                })}
              >
                {formatCurrency(data.total)}
              </p>
            </div>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails
        style={{
          margin: 0,
          padding: 0,
        }}
      >
        {renderLineItems}
      </AccordionDetails>
    </Accordion>
  );
}
