export default {
  text_input: {
    note: {
      place_holder: "Note...",
    },
  },
  buttons: {
    cancel: "Cancel",
    save: "Save",
  },
};
