import classnames from "classnames";
import StarIcon from "../../assets/logo/StarIcon";
import useTheme from "../../hooks/ui/useTheme";

interface StarProps {
  containerClassName?: string;
  iconClassName?: string;
  fillColor?: string;
  defaultColor?: string;
}

interface Props {
  rating: number;
  starCount?: number;
  disabled?: boolean;
  starProps?: StarProps;
  setRating?: (rating: number) => void;
  onSelect?: (rating: number) => void;
}

const RatingStars = (props: Props): JSX.Element => {
  const { rating, starProps, starCount, disabled, setRating, onSelect } = props;

  const theme = useTheme();

  const stars = starCount
    ? Array.from({ length: starCount }, (_, i) => i + 1)
    : [1, 2, 3, 4, 5];

  function onRateSelected(index: number): void {
    if (disabled) {
      return;
    }

    onSelect && onSelect(index);

    if (index !== rating) {
      setRating && setRating(index);
    }
  }

  const renderStar = (index: number): JSX.Element => {
    return (
      <div
        onClick={() => onRateSelected(index)}
        key={`rating-start-${index}`}
        className={classnames(
          !disabled ? "cursor-pointer" : "",
          index === stars?.length
            ? classnames("mr-none", starProps?.containerClassName)
            : classnames("mr-mini", starProps?.containerClassName)
        )}
      >
        <StarIcon
          className={starProps?.iconClassName ?? "h-[44px] w-[46px]"}
          color={
            rating >= index
              ? starProps?.fillColor ?? theme.color.background.inkGreen.green_2
              : starProps?.defaultColor ??
                theme.color.background.inkGreen.green_1
          }
        />
      </div>
    );
  };

  const renderStars = () => stars?.map((item) => renderStar(item));

  return <div className="flex items-center">{renderStars()}</div>;
};

export default RatingStars;
