import { ProductInterface } from "../../services/exports/Interfaces";
import SeoResource from "../../services/resources/SeoResource";
import CustomButton from "../buttons/CustomButton";
import PriceComparison from "../payment/PriceComparison";
import { useTranslation } from "react-i18next";

interface Props {
    product: ProductInterface;
    addOrder: (product: ProductInterface) => void;
}

export default function UpSellItem(props: Props) {
    const { t } = useTranslation(null, { keyPrefix: "Components:List:UpSellItem" });

    const { product, addOrder } = props;

    return (
        <div className={container}>
            <div className={imageContainer}>
                {product?.thumbnail_url && (
                    <img
                        src={product?.thumbnail_url}
                        alt={SeoResource.toAltText(`${product?.name} image`)}
                        className={productImage}
                    />
                )}
            </div>
            <div className={wrapper}>
                <h6 className={textStyle}>{product?.name}</h6>
                <div className={priceWrapper}>
                    <PriceComparison actualPrice={product?.actual_price} originalPrice={product?.original_price} />
                </div>
                <CustomButton title={t("custom_button.add")} onClick={() => addOrder(product)} />
            </div>
        </div>
    );
}

const container =
    "w-full rounded-lg shadow border border-brand-inkGrey-grey-2 bg-background-inkWhite-white_1 overflow-hidden";

const wrapper = "p-small";

const imageContainer = "w-full lg:h-44 md:h-40 h-36 bg-brand-inkGrey-grey_1";

const productImage = "h-full w-full w-full object-cover";

const textStyle = "truncate mb-tiny";

const priceWrapper = "mb-xsmall";
