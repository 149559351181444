import { actionCreators } from "../../store/actions";
import ReduxActions from "../../store/ReduxActions";
import {
  CreateOrderRateInterface,
  OrderRateInterface,
} from "../exports/Interfaces";
import { DataWrappedResponse, DataWrapper } from "../exports/Types";
import BackendApiClient from "./BackendApiClient";

class OrderRatesManager {
  public create = async (
    orderId: number,
    data: CreateOrderRateInterface
  ): Promise<DataWrappedResponse<DataWrapper<OrderRateInterface>>> => {
    const request = await BackendApiClient.request({
      method: "POST",
      url: `/orders/${orderId}/rates`,
      data,
    });

    const { response, success } = request;

    if (success) {
      this.saveDataRedux(response?.data?.data);
    }

    return request;
  };

  private saveDataRedux(data: OrderRateInterface) {
    ReduxActions.dispatch(
      actionCreators.dineInFlow.updateOrder({
        rates: [data],
      })
    );
  }
}

export default new OrderRatesManager();
