import classNames from "classnames";
import React, { useContext } from "react";
import { VisibilityContext } from "react-horizontal-scrolling-menu";
import BackIcon from "../../../assets/logo/BackIcon";
import useTheme from "../../../hooks/ui/useTheme";

interface ButtonProps {
  children?: React.ReactNode;
  disabled?: boolean;
  className?: string;
  onClick?: VoidFunction;
}

function Arrow(props: ButtonProps) {
  const { disabled, className, children, onClick } = props;

  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={classNames(buttonContainer(disabled), className)}
    >
      {children}
    </button>
  );
}

export function LeftArrow(props: ButtonProps) {
  const { children, onClick } = props;
  const theme = useTheme();

  const { isFirstItemVisible } = useContext(VisibilityContext);

  return (
    <Arrow disabled={isFirstItemVisible} onClick={onClick}>
      {children ?? (
        <BackIcon className={backIconStyle} color={theme.color.text.grey} />
      )}
    </Arrow>
  );
}

export function RightArrow(props: ButtonProps) {
  const { children, onClick } = props;
  const theme = useTheme();

  const { isLastItemVisible } = useContext(VisibilityContext);

  return (
    <Arrow disabled={isLastItemVisible} onClick={onClick}>
      {children ?? (
        <div className="rotate-180">
          <BackIcon className={backIconStyle} color={theme.color.text.grey} />
        </div>
      )}
    </Arrow>
  );
}

const backIconStyle = "w-3 h-3";

const buttonContainer = (disabled: boolean) =>
  `flex flex-col justify-center items-center px-2 ${
    disabled ? "hidden" : "visible"
  } z-20`;
