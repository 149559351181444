import CircularProgress, {
  circularProgressClasses,
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import useScreenType from "../../hooks/utility/useScreenType";
import { ThemeInterface } from "../../services/exports/Interfaces";
import useTheme from "../../hooks/ui/useTheme";
import Box from "@mui/material/Box";

export interface CircularProgressBarProps extends CircularProgressProps {
    lg?: number | string;
    md?: number | string;
    sm?: number | string;
    size?: number | string;
    customColor?: string;
}

const CircularProgressBar = (props: CircularProgressBarProps): JSX.Element => {
    const { lg, md, sm, size } = props;

    const { customColor, ...forwardProps } = props;

    const theme: ThemeInterface = useTheme();

    const { isDesktop, isTablet } = useScreenType();

    function getSize() {
        if (size) {
            return size;
        }

        if (isDesktop) {
            return lg;
        }

        if (isTablet) {
            return md;
        }

        return sm;
    }

    return (
        <Box sx={{ position: "relative", display: "inline-flex" }}>
            <CircularProgress
                size={getSize()}
                thickness={4}
                style={{
                    color: customColor ?? theme.color.companyBrand.primary,
                }}
                {...forwardProps}
                sx={{
                    opacity: 0.2,
                }}
                value={100}
                variant="determinate"
            />

            <CircularProgress
                variant="determinate"
                sx={{
                    animationDuration: "550ms",
                    position: "absolute",
                    left: 0,
                    [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: "round",
                    },
                }}
                size={getSize()}
                thickness={4}
                style={{
                    color: customColor ?? theme.color.companyBrand.primary,
                }}
                {...forwardProps}
            />
        </Box>
    );
};

export default CircularProgressBar;
