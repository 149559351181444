import React from 'react';
import {Link as ReactLink} from "react-router-dom";

interface Props {
    to: string;
    text?: string
}

export default function Link(props: Props) {
    const { to, text } = props;

    return (
        <ReactLink
          target="_blank"
          rel="noopener noreferrer"
          to={to}
          className="hover:underline text-brand-text-blue"
        >{text ?? to}</ReactLink>
    )
}