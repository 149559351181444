import React from "react";
import useTheme from "../../hooks/ui/useTheme";
import { ThemeInterface } from "../../services/exports/Interfaces";

interface Props {
  className?: string;
  style?: Object;
  color?: string;
}

export default function ChevronDownIcon(props: Props) {
  const { className, color, style } = props;
  const theme = useTheme();

  return (
    <svg
      className={className ?? "h-4 w-4"}
      viewBox="0 0 11 6"
      fill="none"
      style={style}
    >
      <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.05824 5.76036L5.5 5.18182L4.94176 5.76036C5.25007 6.07988 5.74993 6.07988 6.05824 5.76036ZM5.5 4.02473L1.84772 0.239641C1.53941 -0.0798793 1.03954 -0.0798793 0.731232 0.239641C0.422923 0.55916 0.422923 1.0772 0.731232 1.39672L4.94176 5.76036L5.5 5.18182L6.05824 5.76036L10.2688 1.39672C10.5771 1.0772 10.5771 0.55916 10.2688 0.239641C9.96046 -0.0798793 9.46059 -0.0798793 9.15228 0.239641L5.5 4.02473Z"
          fill={color ?? theme.color.text.default}
      />
    </svg>
  );
}
