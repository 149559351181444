import { useRef } from "react";
import Lottie, { LottieProps } from "react-lottie-player";
import { motion } from "framer-motion";
import CustomButton from "../components-library/buttons/CustomButton";
import CustomModal from "../components-library/CustomModal";
import { MODAL_SIZES } from "../services/exports/Constants";
import CheckMarkAnimationWhite from "../assets/animations/CheckMarkAnimationWhite.json";
import useScreenType from "../hooks/utility/useScreenType";
import LoadingIndicator from "../components-library/loading/LoadingIndicator";
import Theme from "../components-library/Theme";
import ButtonFooter from "../components-library/footers/ButtonFooter";

export interface SuccessModalProps {
  open: boolean;
  buttonTitle: string;
  loading?: boolean;
  lottieProps?: LottieProps;
  animationDescription?: JSX.Element;
  children?: React.ReactNode;
  onConfirm: () => void;
}

const SuccessModal = (props: SuccessModalProps): JSX.Element => {
  const {
    open,
    lottieProps,
    animationDescription,
    loading,
    children,
    buttonTitle,
    onConfirm,
  } = props;

  const { isDesktop } = useScreenType();

  const headerRef = useRef<HTMLDivElement>(null);

  const screenHeight = window.innerHeight;

  function isFullScreen() {
    return !isDesktop;
  }

  const renderButton = (): JSX.Element => {
    return (
      <ButtonFooter className={buttonContainer}>
        <CustomButton title={buttonTitle} onClick={onConfirm} />
      </ButtonFooter>
    );
  };

  const renderHeader = (): JSX.Element => (
    <div className={headerContainer} ref={headerRef}>
      <Lottie
        animationData={CheckMarkAnimationWhite}
        loop={false}
        play
        style={{ height: screenHeight * 0.2 }}
        {...lottieProps}
      />
      {animationDescription && animationDescription}
    </div>
  );

  const renderBody = (): JSX.Element => {
    return (
      <motion.div
        className={wrapper}
        initial={{ y: 50, opacity: 0 }}
        animate={{
          y: 0,
          opacity: 1,
        }}
        transition={{ duration: 1, ease: "easeOut" }}
      >
        {children && children}
      </motion.div>
    );
  };

  const renderLoading = (): JSX.Element => {
    return (
      <div
        className={
          "w-full flex flex-col items-center justify-center bg-companyBrand-primary"
        }
        style={{
          height: isFullScreen() ? screenHeight : screenHeight * 0.6,
        }}
      >
        <LoadingIndicator
          lg={60}
          md={50}
          sm={40}
          customColor={Theme.color.text.light}
        />
      </div>
    );
  };

  return (
    <CustomModal open={open} size={MODAL_SIZES.SM} fullScreen={isFullScreen()}>
      <div className={container}>
        {loading ? (
          renderLoading()
        ) : (
          <>
            {renderHeader()}
            {renderBody()}
            {renderButton()}
          </>
        )}
      </div>
    </CustomModal>
  );
};

export default SuccessModal;

const container = "w-full h-full relative bg-companyBrand-primary";

const wrapper =
  "px-small py-small pb-[50px] bg-background-inkWhite-white_1 h-full w-full lg:relative absolute rounded-t-2xl";

const buttonContainer = "lg:sticky fixed bottom-0";

const headerContainer =
  "flex flex-col justify-center items-center px-small py-medium bg-blue-20 sticky top-0";
