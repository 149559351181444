export default {
  titles: {
    main: 'Wir können keine weiteren Online-Bestellungen annehmen.',
    unpaid_amount_left: 'Wir können keine weiteren Online-Bestellungen annehmen. Bitte bezahle die Restrechnung hier',
  },
  description: 'Online-Bestellungen sind derzeit nicht möglich. Bitte wende dich an das Personal, wenn du Fragen hast.',
  buttons: {
    okay: 'Okay',
  },
};
