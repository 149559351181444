import { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CheckoutScreen from "../pages/CheckoutScreen";
import OrderBasketScreen from "../pages/OrderBasketScreen";
import OrderDetailsScreen from "../pages/OrderDetailsScreen";
import PageNotFound from "../pages/PageNotFound";
import RestaurantDetailsScreen from "../pages/RestaurantDetailsScreen";
import { SCREENS } from "../services/exports/Constants";
import useScreenFallback from "../hooks/navigation/useScreenFallback";
import { RoutesInterface } from "../services/exports/Interfaces";
import TermsAndConditions from "../pages/legal/TermsAndConditions";
import PrivacyPolicy from "../pages/legal/PrivacyPolicy";
import CookiePolicy from "../pages/legal/CookiePolicy";
import DineInScreen from "../pages/dine-in/DineInScreen";
import TableScreen from "../pages/dine-in/TableScreen";
import MenuScreen from "../pages/dine-in/MenuScreen";
import ConfirmOrderScreen from "../pages/dine-in/ConfirmOrderScreen";
import SplitBillScreen from "../pages/dine-in/SplitBillScreen";
import PaymentSuccessfulScreen from "../pages/dine-in/PaymentSuccessfulScreen";
import GlobalAppUtility from "../services/utilities/GlobalAppUtility";
import ScrollToTop from "../components-library/navigation/ScrollToTop";
import GlobalModalsStack from "../modals/GlobalModalsStack";
import WelcomeScreen from "../pages/dine-in/WelcomeScreen";
import useDineInFlow from "../hooks/global/useDineInFlow";
import UploadGoogleReviewScreen from "../pages/UploadGoogleReviewScreen";
import RewardDetailsScreen from "../pages/RewardDetailsScreen";
import useTakeout from "../hooks/global/useTakeout";
import HomeScreen from "../pages/HomeScreen";
import useAppState from "../hooks/global/useAppState";
import useAppMethods from "../hooks/utility/useAppMethods";
import Impressum from "../pages/legal/Impressum";

function Router() {
  useDineInFlow({ initTable: true });

  useTakeout();

  const screenFallback = useScreenFallback();

  const companyRoutes: RoutesInterface[] = [
    {
      path: `${SCREENS.BASKET}`,
      element: <OrderBasketScreen />,
    },
    {
      path: `${SCREENS.ORDER_DETAILS}/:uuid`,
      element: <OrderDetailsScreen />,
    },
    {
      path: SCREENS.CHECKOUT,
      element: <CheckoutScreen />,
    },
    {
      path: SCREENS.TERMS_AND_CONDITIONS,
      element: <TermsAndConditions />,
    },
    {
      path: SCREENS.PRIVACY_POLICY,
      element: <PrivacyPolicy />,
    },
    {
      path: SCREENS.COOKIE_POLICY,
      element: <CookiePolicy />,
    },
    {
      path: SCREENS.IMPRESSUM,
      element: <Impressum />,
    },
    {
      path: SCREENS.DINE_IN,
      element: <DineInScreen />,
    },
    {
      path: SCREENS.DINE_IN_WELCOME,
      element: <WelcomeScreen />,
    },
    {
      path: SCREENS.TABLE,
      element: <TableScreen />,
      protectedType: "DineIn",
    },
    {
      path: SCREENS.MENU,
      element: <MenuScreen />,
    },
    {
      path: SCREENS.CONFIRM_ORDER,
      element: <ConfirmOrderScreen />,
      protectedType: "DineIn",
    },
    {
      path: SCREENS.SPLIT_BILL,
      element: <SplitBillScreen />,
      protectedType: "DineIn",
    },
    {
      path: `${SCREENS.PAYMENT_SUCCESSFUL}/:id`,
      element: <PaymentSuccessfulScreen />,
    },
    {
      path: SCREENS.UPLOAD_GOOGLE_REVIEW_SCREEN,
      element: <UploadGoogleReviewScreen />,
      protectedType: "Auth",
    },
    {
      path: SCREENS.REWARD_DETAILS_SCREEN,
      element: <RewardDetailsScreen />,
      protectedType: "Auth",
    },
    {
      path: "*",
      element: <PageNotFound />,
    },
  ];

  const ROUTES: RoutesInterface[] = [
    {
      path: SCREENS.NOT_FOUND_SCREEN,
      element: <PageNotFound />,
    },
    ...companyRoutes,
    {
      path: ":slug",
      children: [
        {
          path: "",
          element: <RestaurantDetailsScreen />,
        },
        ...companyRoutes,
      ],
    },
    {
      path: SCREENS.HOME_SCREEN,
      element: <HomeScreen />,
    },
    {
      path: "*",
      element: <PageNotFound />,
    },
  ];

  const GlobalAppMethods = useAppMethods();
  const GlobalAppState = useAppState();

  GlobalAppUtility.setGlobalAppMethodsContext(GlobalAppMethods);

  useEffect(() => {
    GlobalAppUtility.setGlobalAppStateContext(GlobalAppState);
  }, [GlobalAppState]);

  return (
    <>
      <BrowserRouter>
        <Routes>
          {ROUTES.map((route: RoutesInterface) => (
            <Route
              path={route.path}
              {...(route?.element ? { element: screenFallback(route) } : {})}
              key={route.path}
            >
              {route.children?.map((item) => (
                <Route
                  path={item.path}
                  element={screenFallback(item)}
                  key={item.path}
                />
              ))}
            </Route>
          ))}
        </Routes>
        <ScrollToTop />
        <GlobalModalsStack />
      </BrowserRouter>
    </>
  );
}

export default Router;
