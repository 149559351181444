import React from "react";
import classnames from "classnames";

interface Props {
  id?: string;
  value?: string;
  placeholder?: string;
  autoComplete?: string;
  inputRef?: React.RefObject<any>;
  type?: "text" | "number" | "uppercase";
  leftIcon?: any;
  containerStyle?: Object;
  inputContainerStyle?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  required?: boolean;
  className?: string;
  onChange?: (text: string) => void;
}

export default function TextInput(props: Props) {
  const {
    id,
    value,
    placeholder,
    autoComplete,
    inputRef,
    type,
    leftIcon,
    containerStyle,
    inputContainerStyle,
    disabled,
    autoFocus,
    required,
    className,
    onChange,
  } = props;

  function changeValue(e: any) {
    switch (type) {
      case "number":
        const isNum: Boolean =
          /^\d+$/.test(e.target.value) || e.target.value === "";
        return isNum ? onChange && onChange(e.target.value) : undefined;
      case "uppercase":
        return onChange && onChange(e.target.value.toUpperCase());
      default:
        return onChange && onChange(e.target.value);
    }
  }

  return (
    <div
      className={classnames(container, inputContainerStyle ?? "")}
      style={containerStyle}
    >
      {leftIcon && leftIcon}
      <input
        id={id}
        type={"text"}
        value={value}
        placeholder={placeholder}
        autoComplete={autoComplete}
        ref={inputRef}
        onChange={changeValue}
        className={classnames("w-full", className)}
        disabled={disabled}
        autoFocus={autoFocus}
        required={required}
      />
    </div>
  );
}

const container =
  "placeholder:text-grey text-brand-text-default border rounded border-brand-inkGrey-grey_2 flex flex-row px-small py-mini items-center";
