import useDineInFlow from "../../hooks/global/useDineInFlow";
import { useEffect, useMemo, useRef, useState } from "react";
import { SCREENS } from "../../services/exports/Constants";
import { useLocation, useParams } from "react-router-dom";
import useLoadingScreen from "../../hooks/ui/useLoadingScreen";
import CustomButton from "../../components-library/buttons/CustomButton";
import BulbIcon from "../../assets/logo/BulbIcon";
import Divider from "../../components-library/Divider";
import CheckCircleIcon from "../../assets/logo/CheckCircleIcon";
import RewardStatus from "../../components-library/rewards/RewardStatus";
import PartiesManager from "../../services/api/PartiesManager";
import DineInNavBar from "../../components-library/navigation/DineInNavBar";
import Section from "../../components-library/dine-in/Section";
import useToast from "../../hooks/utility/useToast";
import useGoogleReview from "../../hooks/google-review/useGoogleReview";
import RatingStars from "../../components-library/review/RatingStars";
import collect from "collect.js";
import RewardStatusResource from "../../services/resources/RewardStatusResource";
import useStore from "../../hooks/global/useStore";
import PointsEarnedModal from "../../modals/PointsEarnedModal";
import RewardsUnlockedModal from "../../modals/RewardsUnlockedModal";
import { Trans, useTranslation } from "react-i18next";
import OrderRatesManager from "../../services/api/OrderRatesManager";
import ButtonFooter from "../../components-library/footers/ButtonFooter";
import useInitialData from "../../hooks/global/useInitialData";
import useNavigate from "../../hooks/navigation/useNavigate";
import useAppMethods from "../../hooks/utility/useAppMethods";

interface LocationStateInterface {
  review?: boolean;
  rating?: number;
  prevPage?: SCREENS;
}

export default function PaymentSuccessfulScreen() {
  const { t } = useTranslation(undefined, { keyPrefix: "Pages:DineIn:PaymentSuccessfulScreen" });

  const location = useLocation();
  const locationState: LocationStateInterface = location?.state;

  const { navigate } = useNavigate();
  const { data, refresh } = useInitialData();
  const { formatCurrency } = useAppMethods();
  const toast = useToast();

  const { company } = data;
  const { profile } = useStore()?.profile;

  const { renderLoadingPage, loading, setLoading } = useLoadingScreen();


  let { id: partyId } = useParams();

  const { hasGooglePromotion, hasUploadedGoogleReview } = useGoogleReview();

  const { userPaid, unpaidAmount, party, setLocalParty } = useDineInFlow();

  const [rating, setRating] = useState<number>(0);

  const [
    eligibleForUploadGoogleReviewModal,
    setEligibleForUploadGoogleReviewModal,
  ] = useState<boolean>(false);

  const [showRewardsUnlockedModal, setShowRewardsUnlockedModal] =
    useState<boolean>(false);

  const [showPointsEarnedModal, setShowPointsEarnedModal] =
    useState<boolean>(false);

  const [loadingRewardsModal, setLoadingRewardsModal] =
    useState<boolean>(false);

  const [rewardsEvaluated, setRewardsEvaluated] = useState<boolean>(false);

  const companyRef = useRef(company);
  companyRef.current = company;

  const partyRef = useRef(party);
  partyRef.current = party;

  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    setEligibleForUploadGoogleReviewModal(
      hasGooglePromotion && !hasUploadedGoogleReview
    );
  }, [hasGooglePromotion, hasUploadedGoogleReview]);

  useEffect(() => {
    if (rewardsEvaluated && !loadingRewardsModal) {
      setLoading(false);
    }
  }, [rewardsEvaluated, loadingRewardsModal]);

  useEffect(() => {
    if (
      !loading &&
      !rating &&
      rewardsEvaluated &&
      !showPointsEarnedModal &&
      !showRewardsUnlockedModal
    ) {
      setTimeout(() => {
        toast(t("toasts.rate_experience"), { duration: Infinity });
      }, 500);
    }
  }, [
    loading,
    rewardsEvaluated,
    showPointsEarnedModal,
    showRewardsUnlockedModal,
  ]);

  async function initData(): Promise<void> {
    if (!partyId) {
      return;
    }

    await refresh();
    const { response, success } = await PartiesManager.show(+partyId);

    if (success) {
      setLocalParty(response?.data?.data);

      setRating(
        locationState?.rating ??
          collect(response?.data?.data?.order?.rates)?.firstWhere(
            "user_id",
            profile?.id
          )?.rate
      );

      if (locationState?.prevPage?.includes(SCREENS.CHECKOUT)) {
        initRewardsModal();
      }
    }
  }

  function initRewardsModal(): void {
    new Promise((resolve) => {
      toggleRewardsModal();
      resolve(true);
    }).finally(() => {
      setTimeout(() => setRewardsEvaluated(true));
    });
  }

  function toggleRewardsModal(): void {
    //When I purchase and unlock a reward
    // -> Only when after purchase, I unlock a reward
    // -> Thus, if e.g. I have not redeemed my reward, yet, I am not again presented with the reward unlocked Screen
    const reward = new RewardStatusResource({
      rewards: companyRef.current?.rewards,
      rewardBalance: companyRef.current?.reward_balance,
    }).getLastActiveReward();

    if (
      reward &&
      companyRef.current?.reward_balance -
        //@ts-ignore
        collect(partyRef.current?.order?.reward_account_operations)
          .where("value", ">", 0)
          .map((op) => op?.value)
          ?.sum() <
        reward?.cost
    ) {
      return setShowRewardsUnlockedModal(true);
    }
    return setShowPointsEarnedModal(true);
  }

  function goBack() {
    return navigate(SCREENS.DINE_IN);
  }

  function onConfirm() {
    if (unpaidAmount === 0) {
      toast.success(t("toasts.table_reset"), { duration: Infinity });
    }

    navigate(SCREENS.DINE_IN);
  }

  const renderPaymentStatus = useMemo(() => {
    if (unpaidAmount > 0) {
      return (
        <div className="flex flex-col">
          <div className="flex flex-col px-small mt-small">
            <BulbIcon className="h-8 w-8 mx-auto" />
            <h6 className="mt-small text-center">
              {t("text.not_all_paid", { name: profile?.first_name })}
            </h6>
          </div>
          <Section
            className="mt-small"
            title={t("labels.you_paid", {
              amount: formatCurrency(userPaid),
            })}
          >
            <Divider className="my-mini h-0.5" />
            <div className="flex justify-between">
              <p className="text-brand-text-warning text-mini">
                {t("labels.left_to_pay")}
              </p>
              <p className="text-brand-text-warning text-mini">
                {formatCurrency(unpaidAmount)}
              </p>
            </div>
          </Section>
        </div>
      );
    }

    return (
      <div className="flex flex-col">
        <div className="flex flex-col px-small mt-small">
          <CheckCircleIcon className="h-8 w-8 mx-auto" />
          <h6 className="mt-small text-center">
            <Trans
              values={{
                name: profile?.first_name,
              }}
              t={t}
            >
              text.all_paid
            </Trans>
          </h6>
        </div>
        <Section className="mt-small">
          <h6>
            {t("labels.you_paid", {
              amount: formatCurrency(userPaid),
            })}
          </h6>
          <Divider className="my-small h-0.5" />
          <p className="text-mini">{t("text.waiter_knows_the_bill_is_paid")}</p>
        </Section>
      </div>
    );
  }, [userPaid, unpaidAmount]);

  const renderRating = useMemo((): JSX.Element | undefined => {
    function onSelect(rate: number): void {
      if (rate >= 4 && eligibleForUploadGoogleReviewModal) {
        setTimeout(
          () =>
            navigate(SCREENS.UPLOAD_GOOGLE_REVIEW_SCREEN, {
              state: {
                prevPage: location.pathname,
                rating: rate,
                googleReviewHeaderTitle: t("titles.leave_google_review"),
              },
            }),
          500
        );
      }

      typeof partyRef.current?.order?.id === "number" &&
        OrderRatesManager.create(partyRef.current?.order?.id, { rate });
    }

    return (
      <Section
        className={"mt-small bg-background-inkGreen-green_0"}
        title={t("titles.rate_experience")}
      >
        <div className="flex flex-col">
          <div className="flex justify-center">
            <RatingStars
              rating={rating}
              setRating={setRating}
              onSelect={onSelect}
            />
          </div>
        </div>
      </Section>
    );
  }, [rating, eligibleForUploadGoogleReviewModal, partyRef.current]);

  const renderRewardsUnlockedModal = useMemo<JSX.Element>(() => {
    return (
      <RewardsUnlockedModal
        open={showRewardsUnlockedModal}
        setLoading={setLoadingRewardsModal}
        toggleModal={() => setShowRewardsUnlockedModal(false)}
      />
    );
  }, [showRewardsUnlockedModal, loadingRewardsModal]);

  const renderPointsEarnedModal = useMemo<JSX.Element>(() => {
    return (
      <PointsEarnedModal
        open={showPointsEarnedModal}
        setLoading={setLoadingRewardsModal}
        toggleModal={() => setShowPointsEarnedModal(false)}
      />
    );
  }, [showPointsEarnedModal, loadingRewardsModal]);

  return (
    <>
      <main className="h-full w-full relative">
        <header className="w-full sticky top-0 z-10">
          <DineInNavBar
            title={t("header")}
            rightClick={goBack}
            hideBackIcon
            className="bg-background-inkWhite-white_0"
          />
        </header>
        {renderPaymentStatus}
        {profile?.should_receive_receipts && (
          <Section className="mt-small" title={t("titles.receipt_sent")}>
            <p className="text-mini">{t("text.receipt_sent")}</p>
          </Section>
        )}
        {renderRating}
        <Section className="mt-small mb-[120px]">
          <RewardStatus
            rewards={company?.rewards}
            points={company?.reward_balance}
            hideDescription
          />
        </Section>

        <ButtonFooter className="fixed bottom-0">
          <CustomButton title={t("buttons.continue")} onClick={onConfirm} />
        </ButtonFooter>
      </main>
      {renderPointsEarnedModal}
      {renderRewardsUnlockedModal}
      {renderLoadingPage}
    </>
  );
}
