import {OpeningHoursInterface} from "../exports/Interfaces";
import moment, {Moment} from "moment";
import PickUpTimesResource from "./PickUpTimesResource";
import HelperMethods from "../exports/HelperMethods";

export class WorkScheduleUnitResource {
  private readonly data?: OpeningHoursInterface | null;

  public constructor(data?: OpeningHoursInterface) {
    this.data = data;
  }

  public openNow(): boolean {
    const range = PickUpTimesResource.createDateRange(this.data);

    if (! range) {
      return false;
    }

    return moment().isBetween(
      range[0],
      range[1],
    );
  }

  public opensBefore(unit?: OpeningHoursInterface): boolean {
    if (! this.data) {
      return false;
    }

    if (! unit) {
      return true;
    }

    if (
      this.data.open?.day > unit.open?.day
      && unit.open?.day === moment().day()
    ) {
      return false;
    }

    if (
      this.data.open?.day === unit.open?.day
      && this.data.open?.hour > unit.open?.hour
    ) {
      return false;
    }

    if (
      this.data.open?.day === unit.open?.day
      && this.data.open?.hour === unit.open?.hour
      && this.data.open?.minute > unit.open?.minute
    ) {
      return false;
    }

    return true;
  }

  public closesBefore(unit?: OpeningHoursInterface): boolean {
    if (! this.data) {
      return true;
    }

    if (! unit) {
      return false;
    }

    if (this.data.close?.day > unit.close?.day) {
      return false;
    }

    if (
      this.data.close?.day === unit.close?.day
      && this.data.close?.hour > unit.close?.hour
    ) {
      return false;
    }

    if (
      this.data.close?.day === unit.close?.day
      && this.data.close?.hour === unit.close?.hour
      && this.data.close?.minute > unit.close?.minute
    ) {
      return false;
    }

    return true;
  }

  public isValid(date: null|Moment = null): boolean {
    const _date = moment(date ?? undefined);

    return (this.openedOnPreviousDay(_date) && this.closesLaterOnSameDay(_date))
      || (
          this.opensOnSameDay(_date)
            && (
              this.closesLaterOnSameDay(_date)
                || this.closesNextDay(_date)
          )
      );
  }

  public openedOnPreviousDay(date: null|Moment = null): boolean {
    const _date = moment(date ?? undefined);

    return this.data?.open?.day === HelperMethods.getPreviousDayOfWeek(_date.day());
  }

  public opensOnSameDay(date: null|Moment = null): boolean {
    const _date = moment(date ?? undefined);

    return this.data?.open?.day === _date.day();
  }

  public closesOnSameDay(date: null|Moment = null): boolean {
    const _date = moment(date ?? undefined);

    return this.data?.close?.day === _date.day();
  }

  public closesLaterOnSameDay(date: null|Moment = null): boolean {
    const _date = moment(date ?? undefined);

    return this.closesOnSameDay(_date)
      && _date.isBefore(
        _date.clone().set({
          hour: this.data?.close?.hour,
          minute: this.data?.close?.minute,
        })
      );
  }

  public closesNextDay(date: null|Moment = null): boolean {
    const _date = moment(date ?? undefined);

    return this.data?.close?.day === HelperMethods.getNextDayOfWeek(_date.day());
  }
}