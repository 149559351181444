import moment from "moment";
import { actionCreators } from "../../store/actions";
import ReduxActions from "../../store/ReduxActions";
import { 
  AverageOrderPreparationTimeInterface,
  EventHelperActionInterface,
  FormatCurrencyOptions
 } from "./Interfaces";

class HelperMethods {
  triggerEventSideEffects = (eventAction: EventHelperActionInterface) => {
    ReduxActions.dispatch(actionCreators.eventHelper.setEvent(eventAction));
  };

  resetEventSideEffects = () => {
    ReduxActions.dispatch(actionCreators.eventHelper.resetEvent());
  };

  formatCurrency(amount: number, options?: FormatCurrencyOptions): string|number {
    const {
      currency = 'EUR',
      locale = null,
      trimInteger = false
    } = options;

    try {
      return new Intl.NumberFormat(locale ?? this.guessLocaleFromCurrency(currency), {
        style: 'currency',
        currency,
        minimumFractionDigits: Number.isInteger(amount) && trimInteger ? 0 : undefined,
      }).format(amount);
    } catch (error) {
      return amount;
    }
  }

  guessLocaleFromCurrency(currency: string) {
    return {
      EUR: 'de-DE',
      USD: 'en-US',
      GBP: 'en-UK',
    }[currency] ?? 'de-DE';
  }

  ceilNumberWithPrecision(number: number, decimalPlaces: number = 2) {
    try {
      const factorOfTen = Math.pow(10, decimalPlaces);

      return Math.ceil(number * factorOfTen) / factorOfTen;
    } catch (error) {
      return 0;
    }
  }

  roundNumberWithPrecision(number: number, decimalPlaces: number = 2) {
    try {
      const factorOfTen = Math.pow(10, decimalPlaces);

      return Math.round(number * factorOfTen) / factorOfTen;
    } catch (error) {
      return 0;
    }
  }

  getDay = (day?: number): string => moment().set({ day }).format('dddd');

  formatDistance(meters: number): string {
    if (!meters) {
      return null;
    }

    if (meters > 500) {
      return `${Math.round(meters / 1000).toFixed(1)} km`
    }

    return `${meters.toFixed(0)} m`;
  }

  formatTimeInterval(interval : AverageOrderPreparationTimeInterface) {
    if (interval?.max) {
      return `${interval?.min}-${interval?.max}`;
    }

    return `${interval?.min}`;
  }

  public getNextDayOfWeek(dayOfWeek: number): number {
    return dayOfWeek === 6
      ? 0
      : ++dayOfWeek;
  }

  public getPreviousDayOfWeek(dayOfWeek: number): number {
    return dayOfWeek === 0
      ? 6
      : --dayOfWeek;
  }
}

export default new HelperMethods();
