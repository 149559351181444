import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { StoreInterface } from "../store/types";
import { Helmet } from "react-helmet";
import { OpeningHoursInterface } from "../services/exports/Interfaces";
import collect from "collect.js";
import moment from "moment";
import AddressHelpers from "../services/helpers/AddressHelpers";
import useConfig from "../hooks/ui/useConfig";

export default function MetaInfo() {
    const { t } = useTranslation(null, { keyPrefix: "Components:MetaInfo" });

    const { company, franchise } = useSelector((state: StoreInterface) => state.initialData);
    const { logo_url } = useConfig();

    const title: string = company?.name
        ? t("seo.title", {
              name: company?.name,
              city: company?.city,
          })
        : t("default_browser_tag");

    const description: string = t("seo.description", {
        name: company?.name,
        city: company?.city,
    });

    function formatHour(hour: number, minute: number): string {
        return `${hour.toString().padStart(2, "0")}:${minute.toString().padStart(2, "0")}`;
    }

    const openingHours = useMemo(
        () =>
            collect(company?.work_schedule)
                ?.map((openingHour: OpeningHoursInterface) => {
                    return `${moment().weekday(openingHour?.open?.day).format("dd")} ${formatHour(
                        openingHour?.open?.hour,
                        openingHour?.open?.minute,
                    )} - ${formatHour(openingHour?.close?.hour, openingHour?.close?.minute)}`;
                })
                .implode(" "),
        [company?.work_schedule],
    );

    const menu: string = useMemo(
        () =>
            JSON.stringify({
                "@type": "Menu",
                name: "Our Menu",
                hasMenuSection: collect(company?.categories)
                    .map((category) => ({
                        "@type": "MenuSection",
                        name: category?.name,
                        hasMenuItem: collect(category?.products)
                            .map((product) => ({
                                "@type": "MenuItem",
                                name: product?.name,
                                description: product?.description,
                                image: product?.thumbnail_url,
                                price: product?.actual_price,
                                priceCurrency:
                                    typeof company?.currency === "string" ? company?.currency?.toUpperCase() : null,
                            }))
                            .toArray(),
                    }))
                    .toArray(),
            }),
        [company?.categories, company?.currency],
    );

    const address = AddressHelpers.format(company?.address, null, company?.city, null, null, company?.zip_code);

    function setNoScript(): void {
        const noScriptId = "body-noscript";

        const existingNoscript = document.getElementById(noScriptId);

        if (!franchise?.body_noscripts && existingNoscript) {
            document.body.removeChild(existingNoscript);
            return;
        }

        if (!franchise?.body_noscripts) {
            return;
        }

        if (existingNoscript) {
            existingNoscript.innerHTML = franchise?.body_noscripts;
            return;
        }

        const noscript = document.createElement("noscript");
        noscript.id = noScriptId;
        noscript.innerHTML = franchise?.body_noscripts;
        document.body.insertBefore(noscript, document.body.firstChild);
    }

    useEffect(() => {
        setNoScript();
    }, [franchise?.body_noscripts]);

    return (
        <div className="application">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{title}</title>
                <meta name="description" content={description} />
                <link rel="icon" id="favicon" href={logo_url} />
                <meta property="og:site_name" content={company?.name} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content={logo_url} />
                <meta property="og:type" content="website" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@FoodAmigos" />
                <meta name="twitter:creator" content="@FoodAmigos" />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:image" content={logo_url} />
                <script type="application/ld+json">
                    {`
                {
                    "@context": "http://schema.org",
                    "@graph": [
                      {
                        "@type": "Organization",
                        "name": "Parqet",
                        "url": "https://www.parqet.com/",
                        "logo": "https://www.parqet.com/logos/parqet_logo_pos.svg"
                        
                        "@type": "Restaurant",
                        "name": ${company?.name},
                        "legalName": ${company?.name},
                        "openingHours": ${openingHours},
                        "url": ${window.location.href},
                        "image": ${collect(company?.pictures).first()},
                        "address": ${address},
                        "location": ${address},
                        "keywords": [${company?.name}, ${company?.city}, "order online"],
                        "logo": ${logo_url},
                        "telephone": ${company?.phone_number},
                        "email": ${company?.email},
                        "latitude": ${company?.lat},
                        "longitude": ${company?.lon},
                        "hasMenu": ${menu},
                      }
                    ]
                }
              `}
                </script>
                {franchise?.head_scripts && (
                    <script type="text/javascript" id="head-script">
                        {franchise?.head_scripts}
                    </script>
                )}
            </Helmet>
        </div>
    );
}
