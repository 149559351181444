import React from 'react';
import {NavLink} from "react-router-dom";
import {SCREENS} from '../../../services/exports/Constants';
import PageStyle from '../../../styles/pages/terms-and-conditions/DE';

export default function DE() {
    return (
        <div className="p-small">
            <PageStyle />
            <h1 className="c12 break-words" id="h.2lm9u2ts1rf4"><span
                className="c11">Allgemeine Gesch&auml;ftsbedingungen (das &ldquo;Gesch&auml;ft&rdquo;)</span>
            </h1>
            <h2 className="c5" id="h.28vxlsz8rfly"><span className="c1">Einleitung</span></h2>
            <p className="c3"><span className="c0 c7">BITTE LESEN SIE DIESE NUTZUNGSBEDINGUNGEN SORGF&Auml;LTIG DURCH, BEVOR SIE DAS ONLINE-BESTELLSYSTEM NUTZEN</span>
            </p>
            <p className="c3"><span className="c4">Indem Sie auf das Online-Bestellsystem zugreifen, es nutzen oder anderweitig verwenden, erkl&auml;ren Sie sich mit den unten aufgef&uuml;hrten Nutzungsbedingungen sowie mit allen anderen Bedingungen, die Ihnen im Online-Bestellsystem zur Verf&uuml;gung gestellt werden (&quot;</span><span
                className="c0">Nutzungsbedingungen</span><span className="c2">&quot;), einverstanden.</span></p>
            <p className="c3"><span className="c4">Die Nutzungsbedingungen stellen eine rechtsverbindliche Vereinbarung zwischen dem Gesch&auml;ft (&quot;</span><span
                className="c0">uns</span><span className="c4">&quot;, &quot;</span><span className="c0">wir</span><span
                className="c4">&quot; oder &quot;</span><span
                className="c0">unser</span><span className="c2">&quot;) und Ihnen dar. Bitte lesen Sie die Nutzungsbedingungen sorgf&auml;ltig durch. Wenn Sie mit den Nutzungsbedingungen nicht einverstanden sind, d&uuml;rfen Sie nicht auf das Online-Bestellsystem zugreifen oder es nutzen und m&uuml;ssen vom Zugriff und der Nutzung absehen.</span>
            </p>
            <p className="c3"><span
                className="c4">Ihre Nutzung des Online-Bestellsystems unterliegt au&szlig;erdem den Bestimmungen unserer </span><span
                className="c13"><NavLink className="c6"
                                         to={SCREENS.PRIVACY_POLICY}>Datenschutzerkl&auml;rung</NavLink></span><span
                className="c4">&nbsp;und unserer </span><span
                className="c4">Cookie-Richtlinie erl&auml;utert</span><span className="c2">&nbsp;und Sie m&uuml;ssen sich auch mit der Datenschutzerkl&auml;rung und der Cookie-Richtlinie vertraut machen, bevor Sie das Online-Bestellsystem nutzen.</span>
            </p>
            <p className="c3"><span className="c2">Wir behalten uns das Recht vor die Nutzungsbedingungen gelegentlich zu &auml;ndern, soweit dies nach geltendem Recht zul&auml;ssig ist. Wir werden Sie &uuml;ber eine solche &Auml;nderung nach unserem Ermessen mittels Benachrichtigung &uuml;ber unsere Website, App und/oder auf andere Weise informieren. Sie sollten die Nutzungsbedingungen in regelm&auml;&szlig;igen Abst&auml;nden &uuml;berpr&uuml;fen und Sie erkl&auml;ren sich damit einverstanden, dass Ihre weitere Nutzung des Online-Bestellsystems nach der Ver&ouml;ffentlichung oder Versendung einer Benachrichtigung &uuml;ber &Auml;nderungen der Nutzungsbedingungen Ihr Einverst&auml;ndnis mit den aktualisierten Nutzungsbedingungen ab dem Datum ihres Inkrafttretens darstellt. Wenn Sie den &Auml;nderungen der Nutzungsbedingungen widersprechen, sollten Sie von der Nutzung des Online-Bestellsystems absehen.</span>
            </p>
            <h2 className="c5" id="h.k1ld1mpzyyfr"><span className="c1">1. Zweck</span></h2>
            <p className="c3"><span className="c4">Das Ziel des Online-Bestellsystems ist es, einen einfachen, bequemen und kosteng&uuml;nstigen Service anzubieten, der es Ihnen (und anderen Kunden) erm&ouml;glicht, Bestellungen f&uuml;r die Lieferung oder Abholung von Essen und/oder anderen Artikeln (die &quot;</span><span
                className="c0">Produkte</span><span className="c4">&quot;) bei uns aufzugeben. Ihnen wird ein Recht zur Nutzung des Online-Bestellsystems gem&auml;&szlig; den </span><span
                className="c13"><a className="c6"
                                   href="https://foodamigos.io/terms">FoodAmigos Allgemeinen Gesch&auml;ftsbedinungen</a></span><span
                className="c2">&nbsp;gew&auml;hrt, die eine separate und vorrangige verbindliche Vereinbarung zwischen Ihnen und FoodAmigos sind.</span>
            </p>
            <h2 className="c5" id="h.fgxj0050wiyb"><span className="c1">2. Haftungsausschluss und Haftung</span></h2>
            <p className="c3"><span className="c2">Sie best&auml;tigen hiermit und erkl&auml;ren sich damit einverstanden, dass das Online-Bestellsystem &bdquo;wie sie ist&ldquo; und &bdquo;wie verf&uuml;gbar&quot; zur Nutzung zur Verf&uuml;gung gestellt wird und dass wir keine Zusicherungen und Gew&auml;hrleistungen jedweder Art in Bezug auf das Online-Bestellsystem abgeben und dass wir unbeschadet der Allgemeing&uuml;ltigkeit des Vorstehenden keine Gew&auml;hrleistung oder Zusicherung in Bezug auf die Genauigkeit, Verf&uuml;gbarkeit, Zuverl&auml;ssigkeit, Sicherheit, Zwecktauglichkeit oder Leistungsf&auml;higkeit oder Best&auml;ndigkeit der Online-Bestellsystem oder ihrer Inhalte geben und keine Verantwortung daf&uuml;r &uuml;bernehmen. Es liegt in Ihrer alleinigen Verantwortung sicherzustellen, dass das Online-Bestellsystem f&uuml;r Ihre Belange geeignet ist.</span>
            </p>
            <p className="c3"><span className="c2">Sie best&auml;tigen und erkl&auml;ren sich damit einverstanden, dass wir das Online-Bestellsystem im Rahmen des geltenden Rechts jederzeit und ohne Vorank&uuml;ndigung vor&uuml;bergehend oder dauerhaft und ganz oder teilweise aus beliebigen Gr&uuml;nden &auml;ndern oder zur&uuml;cknehmen k&ouml;nnen und dass wir Ihnen oder anderen Parteien gegen&uuml;ber keine Haftung aus oder im Zusammenhang mit einer solchen &Auml;nderung oder Zur&uuml;cknahme &uuml;bernehmen. Wir geben keine Zusicherung oder Gew&auml;hrleistung daf&uuml;r ab, dass das Online-Bestellsystem unterbrechungs- oder fehlerfrei funktioniert oder dass das Online-Bestellsystem frei von Viren ist. Wir garantieren oder gew&auml;hrleisten nicht, dass das Online-Bestellsystem Ihren Anforderungen oder Spezifikationen entspricht oder dass Fehler oder Bugs in dem Online-Bestellsystem korrigiert werden.</span>
            </p>
            <p className="c3"><span className="c2">Alle Zusicherungen, Garantien, Gew&auml;hrleistungen, Bestimmungen und Bedingungen, ob ausdr&uuml;cklich oder stillschweigend per Gesetz oder anderweitig, in Bezug auf das Online-Bestellsystem oder die hierin enthaltenen Informationen (einschlie&szlig;lich jeglicher implizierten Garantie und Gew&auml;hrleistung bez&uuml;glich der Genauigkeit von Daten, der Nichtverletzung von Rechten Dritter, der Marktf&auml;higkeit oder der Eignung f&uuml;r einen bestimmten Zweck) werden hiermit im gr&ouml;&szlig;tm&ouml;glichen, nach geltendem Recht zul&auml;ssigen Umfang ausgeschlossen.</span>
            </p>
            <p className="c3"><span className="c2">Unsere Gesamthaftung Ihnen gegen&uuml;ber, ob aus Vertrag, unerlaubter Handlung (einschlie&szlig;lich Fahrl&auml;ssigkeit) nach dem Gesetz oder anderweitig, die sich aus oder in Verbindung mit den Nutzungsbedingungen, dem Online-Bestellsystem und/oder den Produkten ergibt, ist auf 100 &euro; beschr&auml;nkt.</span>
            </p>
            <p className="c3"><span className="c2">Wir best&auml;tigen, dass nichts in den Nutzungsbedingungen unsere Haftung f&uuml;r Tod oder K&ouml;rperverletzung aufgrund unserer Fahrl&auml;ssigkeit oder der Fahrl&auml;ssigkeit unserer Vertreter oder Mitarbeiter ausschlie&szlig;t oder einschr&auml;nkt, und Sie best&auml;tigen und stimmen zu, dass jedes Gesch&auml;ft kein Vertreter von FoodAmigos ist.</span>
            </p>
            <h2 className="c5" id="h.bvomjuc9cbot"><span className="c1">3. Verwendung von Daten</span></h2>
            <p className="c3"><span className="c2">Wenn Sie Ihre Bezahlkarte im Online-Bestellsystem angeben, werden diese Angaben von einem Dritt-Zahlungsdienstanbieter sicher gespeichert. Wir haben keinen Zugriff auf Ihre Bezahlkartendaten, mit Ausnahme der letzten 4 Ziffern Ihrer Bezahlkartennummer und des Kartenablaufdatums, die zur Identifizierung von Karten und Bestellungen dienen.</span>
            </p>
            <p className="c3"><span className="c2">Wenn Sie Ihre Mobiltelefonnummer, Ihren Namen und Ihre Adresse im Online-Bestellsystem angeben, erhalten wir diese Informationen, damit wir Ihre Bestellung durchf&uuml;hren und abschlie&szlig;en k&ouml;nnen.</span>
            </p>
            <p className="c3"><span className="c2">Weitere Informationen dazu, wie wir Informationen &uuml;ber Sie verarbeiten k&ouml;nnen, finden Sie in unserer Datenschutzerkl&auml;rung. Bitte lesen Sie auch die FoodAmigos Datenschutzerkl&auml;rung, um Informationen dar&uuml;ber zu erhalten, wie Ihre Daten verarbeitet werden.</span>
            </p>
            <h2 className="c5" id="h.ekhd8w7as7i9"><span className="c1">4. Websites Dritter</span></h2>
            <p className="c3"><span className="c2">Das Online-Bestellsystem kann Links zu anderen Websites enthalten. Diese Websites stehen nicht unter unserer Kontrolle und wir sind nicht verantwortlich oder haftbar f&uuml;r die Praktiken, Inhalte oder Verf&uuml;gbarkeit dieser Websites. Die Aufnahme von Links zu anderen Websites im Online-Bestellsystem impliziert weder eine Billigung oder F&ouml;rderung der Inhalte oder Praktiken auf diesen Websites noch eine Zugeh&ouml;rigkeit oder Verbindung zwischen uns und den Betreibern dieser Websites. Wir stellen Links zu solchen Websites nur zu Ihrem Komfort zur Verf&uuml;gung.</span>
            </p>
            <p className="c3"><span className="c2">Wenn Sie sich entscheiden, &uuml;ber einen Link im Online-Bestellsystem auf eine Website zuzugreifen, tun Sie dies auf Ihr eigenes Risiko, und wir sind weder Ihnen noch Dritten gegen&uuml;ber verantwortlich oder haftbar f&uuml;r Verluste, Kosten, Sch&auml;den oder sonstige Haftungsanspr&uuml;che, die sich aus oder in Verbindung mit dem Zugriff, der Nutzung oder dem Vertrauen auf die Website oder auf Inhalte, Waren, Praktiken oder Leistungen, die auf einer solchen Website verf&uuml;gbar sind, ergeben.</span>
            </p>
            <p className="c3"><span className="c2">Es ist Ihnen untersagt, von anderen Unternehmen, Leistungen oder anderen Websites aus auf das Online-Bestellsystem zu verlinken.</span>
            </p>
            <h2 className="c5" id="h.uan4c3ppa1qq"><span
                className="c1">5. Verpflichtungen und Nutzungsbeschr&auml;nkungen</span></h2>
            <p className="c3"><span className="c2">Das Online-Bestellsystem wird Ihnen in unserem Niederlassungsland zur Verf&uuml;gung gestellt, und wir geben keine Zusicherung oder Garantie, dass Informationen, Materialien oder Funktionen, die im Online-Bestellsystem enthalten sind, f&uuml;r die Verwendung in einer anderen Rechtsordnung als der unseres Niederlassungslandes geeignet oder verf&uuml;gbar sind.</span>
            </p>
            <p className="c3"><span className="c2">Sie erkl&auml;ren sich damit einverstanden, dass alle von Ihnen auf der Online-Bestellsystem bereitgestellten Informationen (einschlie&szlig;lich Ihres Namens und Ihrer Kontaktdaten) zum Zeitpunkt ihrer Bereitstellung in jeder Hinsicht wahr, richtig, aktuell und vollst&auml;ndig sind und dass Sie diese Informationen bei der sp&auml;teren Nutzung der Online-Bestellsystem aktualisieren werden, wenn sie sich &auml;ndern.</span>
            </p>
            <p className="c3"><span className="c2">Mit der Nutzung der Online-Bestellsystem erkl&auml;ren Sie sich auch damit einverstanden, dass:</span>
            </p>
            <p className="c3"><span className="c0">5.1</span><span
                className="c2">&nbsp;Sie sich an die Nutzungsbedingungen halten werden;</span></p>
            <p className="c3"><span className="c0">5.2</span><span className="c2">&nbsp;Sie sich nicht als eine andere nat&uuml;rliche oder juristische Person ausgeben oder einen falschen Namen oder einen Namen verwenden werden, zu dessen Verwendung in dem Online-Bestellsystem Sie nicht berechtigt sind;</span>
            </p>
            <p className="c3"><span className="c0">5.3</span><span className="c2">&nbsp;Sie 18 Jahre oder &auml;lter sind;</span>
            </p>
            <p className="c3"><span className="c0">5.4</span><span className="c2">&nbsp;Sie den vollen Betrag f&uuml;r alle Produkte, die Sie &uuml;ber das Online-Bestellsystem bestellt haben zahlen werden;</span>
            </p>
            <p className="c3"><span className="c0">5.5</span><span className="c2">&nbsp;Sie das Online-Bestellsystem nicht nutzen werden, um spekulative oder falsche Bestellungen aufzugeben;</span>
            </p>
            <p className="c3"><span className="c0">5.6</span><span className="c2">&nbsp;Sie das Online-Bestellsystem nicht verwenden werden, um Gesch&auml;fte oder die Preise von Gesch&auml;ften zu recherchieren;</span>
            </p>
            <p className="c3"><span className="c0">5.7</span><span className="c2">&nbsp;Sie keine automatisierten Systeme, Roboter oder Software verwenden werden, um Daten von dem Online-Bestellsystem zu extrahieren;</span>
            </p>
            <p className="c3"><span className="c2">Sie best&auml;tigen und erkl&auml;ren sich damit einverstanden, dass Sie das Online-Bestellsystem nicht f&uuml;r rechtswidrige oder von FoodAmigos nicht genehmigte Zwecke nutzen d&uuml;rfen, insbesondere nicht f&uuml;r:</span>
            </p>
            <p className="c3"><span className="c0">5.8</span><span className="c2">&nbsp;die &Uuml;bermittlung von Material, das eine strafbare Handlung darstellt, zu zivilrechtlicher Haftung f&uuml;hrt oder anderweitig gegen Gesetze, Vorschriften oder Verfahrensregeln verst&ouml;&szlig;t;</span>
            </p>
            <p className="c3"><span className="c0">5.9</span><span className="c2">&nbsp;die Beeintr&auml;chtigung anderer Personen bei der Nutzung des Online-Bestellsystems;</span>
            </p>
            <p className="c3"><span className="c0">5.10</span><span className="c2">&nbsp;die Besch&auml;digung, Deaktivierung oder Beeintr&auml;chtigung des Betriebs, der Funktionalit&auml;t oder der Verf&uuml;gbarkeit des Online-Bestellsystem (einschlie&szlig;lich der wissentlichen oder fahrl&auml;ssigen Einf&uuml;hrung von Viren, Trojanern, W&uuml;rmern, Logikbomben oder anderem b&ouml;sartigen oder sch&auml;dlichen Material in die Online-Bestellsystem) oder der Versuch, sich durch Hacking, Spoofing oder andere Mittel unbefugten Zugang zur Online-Bestellsystem oder zu damit verbundenen Netzwerken zu verschaffen;</span>
            </p>
            <p className="c3"><span className="c0">5.11</span><span className="c2">&nbsp;die Verbreitung von ungesetzlichem, verleumderischem, beleidigendem, bedrohlichem, sch&auml;dlichem, obsz&ouml;nem oder anderweitig anst&ouml;&szlig;igem Material auf dem oder &uuml;ber das Online-Bestellsystem;</span>
            </p>
            <p className="c3"><span className="c2">Sie d&uuml;rfen nicht auf das Online-Bestellsystem zugreifen oder es nutzen, wenn Sie unser direkter Konkurrent oder ein Vertreter unseres direkten Konkurrenten sind, au&szlig;er mit unserer ausdr&uuml;cklichen vorherigen schriftlichen Zustimmung. Dar&uuml;ber hinaus d&uuml;rfen Sie auf das Online-Bestellsystem nicht f&uuml;r kommerzielle Zwecke zugreifen (einschlie&szlig;lich f&uuml;r Zwecke des Kopierens oder Reproduzierens von Material auf der Online-Bestellsystem, der &Uuml;berwachung der Verf&uuml;gbarkeit, Leistung oder Funktionalit&auml;t der Online-Bestellsystem oder f&uuml;r andere Benchmarking- oder Wettbewerbszwecke).</span>
            </p>
            <h2 className="c5" id="h.tprgchr62d9n"><span className="c1">6. Bestellungen &amp; Bezahlung</span></h2>
            <p className="c3"><span className="c2">Es liegt in Ihrer Verantwortung sicherzustellen, dass jede Bestellung von Produkten, die Sie &uuml;ber das Online-Bestellsystem aufgeben, korrekt ist, bevor Sie eine solche Bestellung aufgeben. Alle Fragen, die Sie m&ouml;glicherweise zu den in dem Online-Bestellsystem angezeigten Produkten haben, sollten an uns gerichtet werden, indem Sie die auf unserer Homepage verf&uuml;gbaren Kontaktdaten des Gesch&auml;fts verwenden. Sie best&auml;tigen und akzeptieren, dass FoodAmigos nicht in der Lage ist, solche Fragen zu beantworten, und Sie best&auml;tigen und akzeptieren, dass Sie FoodAmigos in Bezug auf solche Fragen weder kontaktieren werden noch versuchen werden zu kontaktieren.</span>
            </p>
            <p className="c3"><span className="c2">Bitte beachten Sie, dass jede Bestellung, die Sie &uuml;ber das Online-Bestellsystem aufgeben, erst dann angenommen wird, wenn Sie eine Bestaetigung des Geschaefts erhalten, in der best&auml;tigt wird, dass wir Ihre Bestellung erhalten und angenommen ist. Das bedeutet, dass wir, wenn das Online-Bestellsystem angibt, dass Ihre Bestellung best&auml;tigt wurde, immer noch (nach unserem Ermessen) beschlie&szlig;en k&ouml;nnen, Ihre Bestellung abzulehnen. Wenn wir Ihre Bestellung nicht annehmen k&ouml;nnen, nachdem sie &uuml;ber das Online-Bestellsystem aufgegeben wurde, werden wir uns mit Ihnen in Verbindung setzen, um Ihnen dies zu best&auml;tigen.</span>
            </p>
            <p className="c3"><span className="c2">Bitte beachten Sie, dass die Angabe falscher pers&ouml;nlicher Daten durch Sie, wenn Sie eine Bestellung bei uns &uuml;ber das Online-Bestellsystem aufgeben, zu Verz&ouml;gerungen bei der Ausf&uuml;hrung Ihrer Bestellung f&uuml;hren kann oder dazu, dass wir Ihre Bestellung nicht ausf&uuml;hren k&ouml;nnen. Es liegt in Ihrer Verantwortung sicherzustellen, dass Sie Ihre korrekten pers&ouml;nlichen Daten in das Online-Bestellsystem eingegeben haben (einschlie&szlig;lich Ihrer Lieferadresse und Telefonnummer), und wir sind nicht haftbar f&uuml;r eine Verz&ouml;gerung oder Nichterf&uuml;llung Ihrer Bestellung aufgrund der Eingabe falscher pers&ouml;nlicher Daten in das Online-Bestellsystem.</span>
            </p>
            <p className="c3"><span className="c2">Es liegt in Ihrer Verantwortung, f&uuml;r die Sicherheit Ihres Mobiltelefons zu sorgen. Sie erkl&auml;ren sich damit einverstanden, dass Sie f&uuml;r alle Bestellungen und Kosten, die &uuml;ber Ihr Mobiltelefon im Online-Bestellsystem aufgegeben werden, verantwortlich und haftbar sind.</span>
            </p>
            <p className="c3"><span className="c2">Bitte beachten Sie, dass f&uuml;r den Fall, dass Sie zum Zeitpunkt der Abgabe Ihrer Bestellung im Online-Bestellsystem noch keine vollst&auml;ndige Zahlung geleistet haben, Sie allein verantwortlich und haftbar sind f&uuml;r alle ausstehenden Zahlungen, die in Bezug auf Ihre Bestellung zu dem Zeitpunkt f&auml;llig werden, in dem Sie Ihre Bestellung bei uns abholen oder wir sie an Sie ausliefern.</span>
            </p>
            <p className="c3"><span className="c2">Wir behalten uns das Recht vor, die Produkte und die Preise der Produkte, die im Online-Bestellsystem verf&uuml;gbar sind, nach eigenem Ermessen zu &auml;ndern.</span>
            </p>
            <h2 className="c5" id="h.irncfa29j04c"><span className="c1">7. Kommunikation</span></h2>
            <p className="c3"><span className="c2">Sie erkl&auml;ren sich damit einverstanden, dass wir Ihnen Mitteilungen und andere Kommunikation elektronisch entweder &uuml;ber das Online-Bestellsystem oder &uuml;ber andere elektronische Mittel zur Verf&uuml;gung stellen, und Sie best&auml;tigen und akzeptieren, dass alle Vertr&auml;ge, Mitteilungen, Informationen und andere Kommunikation, die wir Ihnen elektronisch zur Verf&uuml;gung stellen, den gesetzlichen Anforderungen an die Schriftform der Kommunikation entsprechen.</span>
            </p>
            <h2 className="c5" id="h.90mokj77v0q4"><span className="c1">8. Stornierung</span></h2>
            <p className="c3"><span className="c2">Sie best&auml;tigen und erkl&auml;ren sich damit einverstanden, dass wir eine von Ihnen im Online-Bestellsystem aufgegebene Bestellung entweder vor oder nach der Annahme einer solchen Bestellung stornieren k&ouml;nnen, wenn wir der Meinung sind, dass im Rahmen dieser Bestellung ungenaue Angaben gemacht wurden, oder wenn Umst&auml;nde au&szlig;erhalb unserer angemessenen Kontrolle auftreten, die die Ausf&uuml;hrung einer solchen Bestellung verhindern oder behindern.</span>
            </p>
            <h2 className="c5" id="h.si1p59d7me26"><span className="c1">9. K&uuml;ndigung</span></h2>
            <p className="c3"><span className="c2">Sie und wir k&ouml;nnen die Nutzungsbedingungen jederzeit und ohne Benachrichtigung des anderen sofort k&uuml;ndigen. Mit einer solchen K&uuml;ndigung verlieren Sie Ihr Recht auf Zugang und Nutzung der Online-Bestellsystem. Die folgenden Klauseln der Nutzungsbedingungen gelten nach der K&uuml;ndigung weiter fort: Klauseln 2, 3, 9, 10, 11 und 13.</span>
            </p>
            <h2 className="c5" id="h.122so1w1ij06"><span className="c1">10. Allgemeine Bedingungen</span></h2>
            <p className="c3"><span
                className="c2">Sollte ein Teil der Nutzungsbedingungen als ungesetzlich, ung&uuml;ltig oder aus irgendeinem Grund als nicht durchsetzbar erachtet werden, so gilt diese Bestimmung als von den Nutzungsbedingungen abtrennbar und hat keinen Einfluss auf die G&uuml;ltigkeit und Durchsetzbarkeit der &uuml;brigen Bestimmungen der Nutzungsbedingungen.</span>
            </p>
            <p className="c3"><span className="c2">Ein Vers&auml;umnis oder Verzug unsererseits bei der Aus&uuml;bung eines Rechts oder Rechtsmittels, das hierin oder durch das Gesetz vorgesehen ist, stellt keinen Verzicht auf dieses oder ein anderes Recht oder Rechtsmittel dar, noch wird dadurch die weitere Aus&uuml;bung dieses oder eines anderen Rechts oder Rechtsmittels verhindert oder eingeschr&auml;nkt. Eine einzelne oder teilweise Aus&uuml;bung eines solchen Rechts oder Rechtsmittels verhindert weder die weitere Aus&uuml;bung dieses oder eines anderen Rechts oder Rechtsmittels noch schr&auml;nkt es diese ein.</span>
            </p>
            <p className="c3"><span className="c2">Sie stimmen zu, dass zwischen Ihnen und uns aufgrund der Nutzungsbedingungen oder Ihrer Nutzung des Online-Bestellsystems keine Partnerschaft, Besch&auml;ftigung oder Agenturbeziehung besteht.</span>
            </p>
            <p className="c3"><span className="c2">Die Nutzungsbedingungen stellen die gesamte Vereinbarung zwischen Ihnen und uns in Bezug auf das Online-Bestellsystem dar und ersetzen und erl&ouml;schen alle fr&uuml;heren Vereinbarungen, Versprechen, Zusicherungen, Gew&auml;hrleistungen und Absprachen zwischen Ihnen und uns in Bezug auf das Online-Bestellsystem.</span>
            </p>
            <p className="c3"><span className="c2">Sie sind nicht berechtigt, die Nutzungsbedingungen ohne unsere vorherige schriftliche Zustimmung an eine Person abzutreten, zu erneuern oder anderweitig zu &uuml;bertragen. Sie erkl&auml;ren sich jedoch damit einverstanden, dass FoodAmigos seine Rechte und Pflichten aus den Nutzungsbedingungen an seine Partner oder eine Partei, die FoodAmigos ganz oder teilweise erwirbt, abtreten, erneuern oder anderweitig &uuml;bertragen kann.</span>
            </p>
            <h2 className="c5" id="h.kwlikjydjl3h"><span className="c1">11. Geltendes Recht</span></h2>
            <p className="c3"><span className="c2">Die Nutzungsbedingungen und alle Streitigkeiten oder Anspr&uuml;che (einschlie&szlig;lich au&szlig;ervertraglicher Streitigkeiten oder Anspr&uuml;che), die sich aus oder in Verbindung mit den Nutzungsbedingungen oder ihrem Gegenstand ergeben, unterliegen dem irischen Recht und sind in &Uuml;bereinstimmung mit diesem auszulegen, und alle derartigen Streitigkeiten oder Anspr&uuml;che sind ausschlie&szlig;lich vor den Gerichten Deutschlands oder den Gerichten des Landes unserer Niederlassung zu entscheiden.</span>
            </p>
            <h2 className="c5" id="h.ph43qa59r627"><span className="c1">12. Kontakt</span></h2>
            <p className="c3"><span className="c2">Alle Kommentare, Fragen und W&uuml;nsche sind willkommen. Bitte richten Sie diese an uns und, indem Sie die auf unserer Homepage verf&uuml;gbaren Kontaktdaten des Gesch&auml;fts verwenden. Bitte richten Sie auch etwaige Beschwerden oder rechtliche Hinweise an diese Adresse.</span>
            </p>
            <h2 className="c5" id="h.pi81019thdr2"><span className="c1">13. Definitionen</span></h2>
            <p className="c3"><span className="c4">&quot;</span><span className="c0">FoodAmigos</span><span
                className="c2">&quot; bedeutet Foodamigos GmbH, ein in Deutschland unter der unter der HRB Nummer 26506 eingetragenen Unternehmen mit der eingetragenen Adresse Charlotte-von-Stein Strasse 2, 53177 Bonn, Deutschland.</span>
            </p>
            <p className="c3"><span className="c4">&quot;</span><span className="c0">Online-Bestellsystem</span><span
                className="c2">&quot; bezeichnet die von FoodAmigos entwickelten Technologiel&ouml;sungen, die Ihnen &uuml;ber unsere Website, App oder eine andere Plattform zur Verf&uuml;gung gestellt werden und die es Ihnen erm&ouml;glichen, elektronisch Bestellungen von Produkten bei uns aufzugeben;</span>
            </p>
            <hr/>
            <p className="c9"><span className="c2"></span></p>
            <h2 className="c5" id="h.4w1trapalj2w"><span className="c1">Datenschutzerkl&auml;rung</span></h2>
            <p className="c14"><span className="c0 c7">Bitte lesen Sie unsere Datenschutzerkl&auml;rung, die auf der Website des Gesch&auml;fts verf&uuml;gbar ist.</span>
            </p>
            <h2 className="c5" id="h.vdjqyrq5decj"><span className="c1">Cookie-Richtlinie erl&auml;utert</span></h2>
            <p className="c14"><span className="c0">Bitte lesen Sie unsere Cookie-Richtlinie, die auf der Website des Gesch&auml;fts verf&uuml;gbar ist.</span>
            </p>
        </div>
    );
}