export default {
  header: "Bestellübersicht",
  table: {
    add_table_number: "Tischnummer hinzufügen",
  },
  buttons: {
    checkout: "Zur Kasse",
    continue: "Weiter",
  },
  empty_order_basket: {
    description: "Dein Warenkorb ist leer <br/> Artikel hinzufügen, um loszulegen",
  },
  promo_code_banner: {
    single_use: '{{discount}} Rabatt - {{code}}',
    multi_use: '{{discount}} Rabatt auf {{count}} Bestellungen - {{code}}',
    redeem: 'ANWENDEN',
    redeemed: 'EINGELÖST',
  }
};
