export default {
  order_methods: {
    delivery: 'Lieferung',
    pickup: 'Abholung',
    room_service: 'Vor Ort',
    table_service: 'Vor Ort',
  },
  time: '{{time}} min',
  asap: 'ASAP',
  unavailable: 'Nicht verfügbar',
};
