import React, {useCallback, useMemo} from "react";
import BackIcon from "../../assets/logo/BackIcon";
import CloseIcon from "../../assets/logo/CloseIcon";
import CreditCardIcon from "../../assets/logo/CreditCardIcon";
import GiroPayIcon from "../../assets/logo/GiroPayIcon";
import KlarnaIcon from "../../assets/logo/KlarnaIcon";
import PayPalIcon from "../../assets/logo/PayPalIcon";
import { PaymentMethod } from "../../services/exports/Constants";
import {
  ThemeInterface,
  PaymentOptionPropertiesInterface,
} from "../../services/exports/Interfaces";
import RadioButton from "../buttons/RadioButton";
import { Elements } from "@stripe/react-stripe-js";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import PaymentMethodElement from "./PaymentMethodElement";
import IbanIcon from "../../assets/logo/IbanIcon";
import useTheme from "../../hooks/ui/useTheme";
import ApplePayIcon from "../../assets/logo/ApplePayIcon";
import GooglePayIcon from "../../assets/logo/GooglePayIcon";
import { useTranslation } from "react-i18next";
import CashIcon from "../../assets/logo/payments/CashIcon";
import {loadStripe} from "@stripe/stripe-js";
import useStripeHelpers from "../../hooks/payment/useStripeHelpers";
import {useSelector} from "react-redux";
import {StoreInterface} from "../../store/types";
import FeedbackLabel from "../labels/tinyLabels/FeedbackLabel";
import usePaymentMethods from "../../hooks/payment/usePaymentMethods";
import classnames from "classnames";
import * as stripeJs from "@stripe/stripe-js";
import LoadingIndicator from "../loading/LoadingIndicator";

export enum MODE {
  preview = 'preview',
  edit = 'edit',
}

interface Props {
  item: PaymentOptionPropertiesInterface;
  selectedPaymentMethod?: PaymentOptionPropertiesInterface | null;
  mode?: MODE;
  openModal?: boolean;
  onClick?: (paymentMethod: PaymentOptionPropertiesInterface) => void;
  onRemove?: (paymentMethod: PaymentOptionPropertiesInterface) => void;
}

export default function PaymentMethodItem(props: Props) {
  const { t } = useTranslation(null, { keyPrefix: 'Components:Payment:PaymentMethodItem' });

  const { company } = useSelector((state: StoreInterface) => state.initialData);
  const { cached_order } = useSelector((state: StoreInterface) => state.order);

  const {
    item,
    selectedPaymentMethod,
    mode,
    openModal,
    onClick,
    onRemove,
  } = props;

  const _mode = mode ?? MODE.edit;

  const { loading: loadingElement, options} = useStripeHelpers();
  const { isPaymentMethodDisabled, isPaymentMethodSaved } = usePaymentMethods();
  const theme: ThemeInterface = useTheme();

  const stripePromise = useMemo<null|Promise<stripeJs.Stripe>>(
    () =>  !company?.service_provider?.stripe_key
      ? null
      : loadStripe(company?.service_provider?.stripe_key, {
        stripeAccount: company?.stripe_id
      }),
    [company?.service_provider?.stripe_key, company?.stripe_id]
  );

  const disabled = isPaymentMethodDisabled(item?.payment_method_id);
  const isActive = selectedPaymentMethod?.payment_method_id === item?.payment_method_id;

  const description = ({
    [PaymentMethod.Cash]: t(`descriptions.${PaymentMethod.Cash}`, { defaultValue: null }),
    [PaymentMethod.Paypal]: company?.has_paypal_upcharge
      ? t(`descriptions.${PaymentMethod.Paypal}`, { defaultValue: null })
      : null,
  })[item.payment_method_id] ?? null;

  function renderRadioButton() {
    return (
      <div className={radioButtonContainer}>
        <RadioButton active={isActive} />
      </div>
    );
  }

  function renderActionIcon() {
    function renderDeleteIcon() {
      switch (item?.payment_method_id) {
        default:
          return (
            <button
              onClick={() => onRemove && onRemove(item)}
              className={actionIconStyle}
            >
              <CloseIcon className={closeIconStyle} />
            </button>
          );
      }
    }

    function renderArrowIcon() {
      return (
        <button className={actionIconStyle}>
          <BackIcon className={backIconStyle(isActive)} />
        </button>
      );
    }

    if (_mode === MODE.preview) {
      return (
        <BackIcon color={theme.color.text.grey} className="h-4 w-4 rotate-180" />
      );
    }

    const shouldRenderArrow = [
      PaymentMethod.Card,
      PaymentMethod.SepaDebit,
      PaymentMethod.Sofort,
    ].includes(item.payment_method_id);

    if (isPaymentMethodSaved(item.payment_method_id)) {
      return renderDeleteIcon();
    }

    return shouldRenderArrow && renderArrowIcon();
  }

  function getPaymentIcon() {
    switch (item?.payment_method_id) {
      case PaymentMethod.Card:
        return (
          <CreditCardIcon
            className={creditCardIcon}
            color={theme.color.text.default}
          />
        );
      case PaymentMethod.Paypal:
        return <PayPalIcon className={payPalIcon} />;
      case PaymentMethod.Sofort:
        return <KlarnaIcon className={klarnaIcon} />;
      case PaymentMethod.Giropay:
        return <GiroPayIcon className={giroPayIcon} />;
      case PaymentMethod.SepaDebit:
        return <IbanIcon className={ibanIcon} />;
      case PaymentMethod.ApplePay:
        return <ApplePayIcon className={appleIcon} />;
      case PaymentMethod.GooglePay:
        return <GooglePayIcon className={googleIcon} />;
      case PaymentMethod.Cash:
        return <CashIcon className="h-5 w-6" />;
    }
  }

  const showElement = useCallback(() => {
    const condition1 = options?.clientSecret && !loadingElement && isActive;
    const condition2 = () => {
      switch (selectedPaymentMethod?.payment_method_id) {
        case PaymentMethod.Paypal:
        case PaymentMethod.Cash:
          return false;
        default:
          return true;
      }
    };

    return condition1 && condition2();
  }, [options, loadingElement, isActive, selectedPaymentMethod]);

  const isExpanded = useCallback(() => {
    const condition1 = isActive && !isPaymentMethodSaved(item.payment_method_id) && openModal;
    const condition2 = () => {
      switch (selectedPaymentMethod?.payment_method_id) {
        case PaymentMethod.Paypal:
        case PaymentMethod.Cash:
        case PaymentMethod.ApplePay:
        case PaymentMethod.GooglePay:
        case PaymentMethod.Giropay:
          return false;
        default:
          return true;
      }
    };

    return condition1 && condition2();
  }, [isActive, openModal, selectedPaymentMethod]);

  function getPaymentMethodName(item: PaymentOptionPropertiesInterface) {
    if (item?.payment_method_id === PaymentMethod.Card) {
      return t('payment_methods.card');
    }

    return item?.display_name;
  }

  function getTitle() {
    if (!item?.paymentMethodSaved) {
      return getPaymentMethodName(item);
    }

    switch (item?.payment_method_id) {
      case PaymentMethod.Card:
      case PaymentMethod.SepaDebit:
        return `${item?.display_name} ***${item?.details}`;
      case PaymentMethod.Cash:
        return company?.has_in_store_card_payments ? t('payment_methods.cash_or_card_in_store') : t('payment_methods.cash')
      default:
        return getPaymentMethodName(item);
    }
  }

  return (
    <div
      className={classnames(container, {'!cursor-pointer': !disabled || _mode === MODE.preview})}
      onClick={() => (!disabled || _mode === MODE.preview) && onClick && onClick(item)}
      data-test={`selected-payment-method-id-${item?.payment_method_id}`}
    >
      <Accordion
        expanded={isExpanded()}
        disabled={disabled}
        onChange={() => _mode === MODE.edit && onClick(item)}
        style={{
          boxShadow: "none",
          width: "100%",
          backgroundColor: theme.color.background.inkWhite.white_1,
        }}
      >
        <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
          <div className="w-full">
            <div className={wrapper}>
              {_mode === MODE.edit && renderRadioButton()}
              <div className={descriptionContainer}>
                <div className={iconContainer}>{getPaymentIcon()}</div>
                <div className="ml-mini">
                  <p>{getTitle()}</p>
                  {!disabled && description && (
                    <p className="text-tiny text-left">{description}</p>
                  )}
                </div>
              </div>
              {renderActionIcon()}
            </div>
            {disabled && (
              <div className="mt-small">
                <FeedbackLabel
                  type="Warning"
                  message={t('feedback.payment_method_disabled', {
                    paymentMethod: getTitle(),
                    orderMethod: t(`order_methods.${cached_order?.method}`),
                  })}
                />
              </div>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {(showElement() && stripePromise && options?.clientSecret) ? (
            <Elements
              key={`${company?.stripe_id}-${options?.clientSecret}`}
              stripe={stripePromise}
              options={options}
            >
              <PaymentMethodElement
                selectedPaymentMethod={selectedPaymentMethod}
              />
            </Elements>
          ) : (
            <div className="w-full flex justify-center">
              <LoadingIndicator className="mx-auto" lg={40} md={30} sm={20} />
            </div>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

const container =
  "border border-brand-inkGrey-grey_2 rounded mb-small w-full flex flex-col items-center justify-center bg-background-inkWhite-white_0";

const wrapper = "flex flex-row items-center w-full";

const radioButtonContainer = "mr-small";

const descriptionContainer = "flex flex-row items-center w-full";

const iconContainer =
  "h-8 w-12 border border-brand-inkGrey-grey_2 rounded flex justify-center items-center";

const creditCardIcon = "h-6 w-6";

const payPalIcon = "h-7 w-7";

const klarnaIcon = "h-6 w-6";

const giroPayIcon = "h-6 w-6";

const ibanIcon = "h-6 w-6";

const appleIcon = "h-8 w-8";

const googleIcon = "h-10 w-10";

const backIconStyle = (open: boolean) =>
  `h-4 w-4 ${open ? "rotate-90" : "-rotate-90"} `;

const closeIconStyle = "h-3 w-3";

const actionIconStyle =
  "before:relative after:content-[''] after:absolute after:-top-4 after:-bottom-4 after:-left-5 after:-right-5";
