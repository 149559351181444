import useStore from "../global/useStore";
import collect from "collect.js";

export default function useRewards() {
  const { company } = useStore()?.initialData;

  return {
    rewards: company?.rewards,
    rewardBalance: company?.reward_balance,
    maxReward: collect(company?.rewards)?.last(),
    availableReward: collect(company?.rewards)?.firstWhere(
      "is_available",
      true
    ),
  };
}
