import React, { useEffect, useState } from "react";
import NoInternetConnectionPage from "../../components-library/internet/NoInternetConnectionPage";
import { Detector } from "react-detect-offline";
import { RoutesInterface } from "../../services/exports/Interfaces";
import { useSelector } from "react-redux";
import { EventHelperTypes } from "../../services/exports/Constants";
import HelperMethods from "../../services/exports/HelperMethods";
import AuthRoute from "../../components-library/navigation/middleware/AuthRoute";
import DineInRoute from "../../components-library/navigation/middleware/DineInRoute";
import CompanySlugObserver from "../../components-library/navigation/middleware/CompanySlugObserver";
import {StoreInterface} from "../../store/types";

export default function useScreenFallback() {
  const { event } = useSelector((state: StoreInterface) => state.eventHelper);

  const [showNoInternetPage, setShowNoInternetPage] = useState(false);

  useEffect(() => {
    handleReduxSideEffects();
  }, [event]);

  async function handleReduxSideEffects() {
    try {
      const { type } = event;
      if (!type) {
        return;
      }

      return new Promise((resolve, reject) => {
        switch (type) {
          case EventHelperTypes.NO_INTERNET:
            setShowNoInternetPage(true);
        }
        resolve(true);
        reject(false);
      }).then(HelperMethods.resetEventSideEffects);
    } catch (error) {}
  }

  function renderNoInternetConnectionPage() {
    return <NoInternetConnectionPage />;
  }

  function render(route: RoutesInterface): React.ReactNode {
    return (
      <Detector
        render={({ online }) => {
          if (showNoInternetPage && !online) {
            return renderNoInternetConnectionPage();
          }

          switch (route?.protectedType) {
            case "Auth":
              return (
                <AuthRoute>
                  <CompanySlugObserver>{route.element}</CompanySlugObserver>
                </AuthRoute>
              );
            case "DineIn":
              return (
                <DineInRoute>
                  <CompanySlugObserver>{route.element}</CompanySlugObserver>
                </DineInRoute>
              );
            default:
              return (
                <CompanySlugObserver>{route?.element}</CompanySlugObserver>
              );
          }
        }}
      />
    );
  }

  return render;
}
