export default {
  rewards: "Meine Prämien",
  redeem_reward: "Prämie Einlösen",
  earn_points: "1. Sammel Punkte, jedes Mal wenn du {{threshold}} ausgibst",
  redeem_points:
    "2. Punkte einlösen für Rabatte und gratis Gerichte wenn du hier bestellst",

  google_rating: {
    review: "Bewerte uns auf Google und erhalte ein gratis {{product}} & mehr",
    info: "Info",
    status: {
      success: "Bewertung verifiziert & Prämie verdient",
    },
  },
  toast: {
    upload_success: "Google-Bewertung verifiziert & Belohnung verdient",
  },
  free_products: {
    title: "Gilt für folgende Artikel",
    point: "{{count}} Punkt",
    point_other: "{{count}} Punkte",
  },
};
