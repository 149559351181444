import React from "react";
import CheckmarkIcon from "../../../assets/logo/CheckmarkIcon";
import InfoIcon from "../../../assets/logo/InfoIcon";
import WarningIcon from "../../../assets/logo/WarningIcon";
import { FeedbackLabelInterface } from "../../../services/exports/Interfaces";
import Theme from "../../Theme";
import classnames from "classnames";

export default function FeedbackLabel(props: FeedbackLabelInterface) {
  const { message, type, errors, hideIcon, className, textClassname } = props;

  function getIcon() {
    if (hideIcon) {
      return null;
    }

    switch (type) {
      case "Success":
        return (
          <div className="bg-brand-primary rounded-full flex justify-center items-center w-5 h-5">
            <CheckmarkIcon
              color={Theme.color.text.light}
              className="h-2 w-2"
            />
          </div>
        );
      case "Error":
        return <WarningIcon color={Theme.color.brand.danger} />;
      case "Info":
        return <InfoIcon color={Theme.color.text.grey} fill />;
      case "Warning":
        return <InfoIcon color={Theme.color.text.default} fill />;
    }
  }

  function getBackgroundColor() {
    switch (type) {
      case "Success":
        return Theme.color.brand.inkPrimary.primary_2;
      case "Error":
        return Theme.color.brand.inkDanger.danger_4;
      case "Info":
        return Theme.color.brand.inkGrey.grey_2;
      case "Warning":
        return Theme.color.brand.warning;
      case "Notice":
        return Theme.color.brand.notice;
    }
  }

  function getLabelColor() {
    switch (type) {
      case "Success":
        return Theme.color.brand.primary;
      case "Error":
        return Theme.color.brand.danger;
      case "Info":
        return Theme.color.text.grey;
      case "Warning":
        return Theme.color.text.default;
    }
  }

  return (
    <div
      className={classnames(container, className)}
      style={{ backgroundColor: getBackgroundColor() }}
    >
      {getIcon() && (
        <div className="mr-mini">{getIcon()}</div>
      )}
      <h4 className={classnames('mini', textClassname)} style={{ color: getLabelColor() }}>{message}</h4>
      {errors && Object.values(errors)?.map((error) => (error && (
        <div>
          <p>
            •
            {error}
          </p>
        </div>
      )))}
    </div>
  );
}

const container = "flex flex-row px-small py-mini rounded items-center";
