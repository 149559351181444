import { useEffect } from "react";
import { useSelector } from "react-redux";
import { LogoInterface } from "../../services/exports/Interfaces";
import Theme from "../../components-library/Theme";
import collect from "collect.js";
import DomainResource from "../../services/resources/DomainResource";
import { SCREENS } from "../../services/exports/Constants";
import {useTranslation} from "react-i18next";
import {StoreInterface} from "../../store/types";

export default function useManifest(): void {
  const { t } = useTranslation(null, {keyPrefix: 'Hooks:SEO:UseManifest'});

  const { franchise, company } = useSelector((state: StoreInterface) => state.initialData);

  interface ManifestLogoInterface {
    src: string;
    sizes: string;
    type: string;
  }

  function getManifestLogos(): ManifestLogoInterface[] {
    return collect(franchise?.logos)
      ?.map((item: LogoInterface) => {
        return {
          src: item?.url,
          sizes: `${item?.width}x${item?.height}`,
          type: "image/jpg",
        };
      })
      ?.toArray();
  }

  var myDynamicManifest = {
    short_name: company?.name,
    name: company?.name,
    description: t('description', {
      name: company?.name,
      city: company?.city,
    }),
    start_url: `${DomainResource.getDomain()}${SCREENS.HOME_SCREEN}`,
    display: "standalone",
    theme_color: Theme.color.background.inkWhite.white_1,
    background_color: Theme.color.background.inkWhite.white_1,
    icons: getManifestLogos(),
  };

  function updateManifest(): void {
    const stringManifest = JSON.stringify(myDynamicManifest);
    const blob = new Blob([stringManifest], { type: "application/json" });
    const manifestURL = URL.createObjectURL(blob);
    document
      .querySelector("#my-manifest-placeholder")
      .setAttribute("href", manifestURL);
  }

  useEffect(() => {
    if (company) {
      updateManifest();
    }
  }, [company]);
}
