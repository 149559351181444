import { useLocation } from "react-router-dom";
import BackIcon from "../../assets/logo/BackIcon";
import GoogleIcon from "../../assets/logo/GoogleIcon";
import useGoogleReviewSuggestedProducts from "../../hooks/google-review/useGoogleReviewSuggestedProducts";
import useTheme from "../../hooks/ui/useTheme";
import { SCREENS } from "../../services/exports/Constants";
import useGoogleReview from "../../hooks/google-review/useGoogleReview";
import classnames from "classnames";
import useNavigate from "../../hooks/navigation/useNavigate";

interface Props {
  className?: string;
  containerClassName?: string;
}

const GoogleReviewCard = (props: Props): JSX.Element => {
  const { className, containerClassName } = props;

  const { navigate } = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const { hasGooglePromotion, hasUploadedGoogleReview } = useGoogleReview();
  const { message } = useGoogleReviewSuggestedProducts({ autoLoad: true });

  function onClick(): void {
    navigate(SCREENS.UPLOAD_GOOGLE_REVIEW_SCREEN, {
      state: { prevPage: location.pathname },
    });
  }

  if (!hasGooglePromotion || hasUploadedGoogleReview) {
    return null;
  }

  return (
    <div
      className={
        className ??
        classnames(
          "flex flex-row items-center justify-between px-xsmall lg:mt-none mt-mini lg:py-xsmall py-small cursor-pointer rounded-md border",
          containerClassName
        )
      }
      onClick={onClick}
    >
      <div className={"pr-mini"}>
        <div className="flex items-center">
          <div className={"mr-mini"}>
            <GoogleIcon className={"w-[16px] h-[16px]"} />
          </div>
          <p className="mini line-clamp-1 break-words">{message()}</p>
        </div>
      </div>

      <div className={"flex flex-row items-center"}>
        <div className={"rotate-180"}>
          <BackIcon className={"w-3 h-3"} color={theme.color.text.grey} />
        </div>
      </div>
    </div>
  );
};

export default GoogleReviewCard;
