import {ReactElement, useMemo, useState} from "react";
import useScrollPosition from "../hooks/utility/useScrollPosition";
import {Tooltip as BaseTooltip} from "@mui/material";
import ClickAwayListener from '@mui/material/ClickAwayListener';

interface Props {
  title: string|ReactElement;
  children: ReactElement;
  defaultOpen?: boolean;
  className?: string;
}

export default function Tooltip (props: Props): ReactElement {
  const { title, children, defaultOpen, className } = props;

  const [open, setOpen] = useState(defaultOpen ?? false);

  useScrollPosition({ onScroll: onPageScroll });

  function onPageScroll(): void {
    onClose();
  }

  function onClose(): void {
    setOpen(false);
  }

  return useMemo(
    () => (
      <ClickAwayListener onClickAway={onClose}>
        <BaseTooltip
          title={title}
          open={open}
          onClose={onClose}
          className={className}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          arrow
          placement="top"
          PopperProps={{
            disablePortal: true,
          }}
          classes={{
            popper: '!px-mini !w-full',
            tooltip: '!w-full !max-w-full !mb-mini !bg-background-inkWhite-white_0 !rounded-md !shadow !border !border-brand-inkGrey-grey_2 !text-brand-text-default !text-tiny !font-normal',
            arrow: '!text-background-inkWhite-white_0',
          }}
        >
          <button onClick={() => setOpen(true)}>{children}</button>
        </BaseTooltip>
      </ClickAwayListener>
    ),
    [title, open, children, className]
  );
};
