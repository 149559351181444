export default {
  text_input: {
    note: {
      place_holder: "Notiz...",
    },
  },
  buttons: {
    cancel: "Abbrechen",
    save: "Speichern",
  },
};
