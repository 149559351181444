import useDineInFlow from "../../hooks/global/useDineInFlow";
import { collect } from "collect.js";
import Section from "./Section";
import TableMemberIllustration from "../../assets/illustrations/TableMemberIllustration";
import { useTranslation } from "react-i18next";

interface Props {
  className?: string;
  onLeaveParty: () => void;
}

export default function CurrentPartySection(props: Props) {
  const { t } = useTranslation(undefined, {
    keyPrefix: "Components:DineIn:CurrentPartySection",
  });

  const { className, onLeaveParty } = props;

  const { party, userOrdered } = useDineInFlow();

  const renderNoMembers = (): JSX.Element => (
    <div>
      <div className=" w-full flex flex-col items-center py-small">
        <TableMemberIllustration className="w-5/12" />
      </div>
      <p className="mini text-brand-text-grey text-center">
        {t("titles.no_members")}
      </p>
    </div>
  );

  return (
    <Section className={className} title={t("titles.main")}>
      {party?.users?.length > 0 ? (
        <>
          <p className="text-mini text-brand-inkGrey-grey_4">
            {collect(party?.users)
              ?.filter((user) => !!user.first_name)
              ?.implode("first_name", ", ")}
          </p>
          {!userOrdered && party && (
            <button
              className="bg-brand-inkGrey-grey_1 p-mini w-fit rounded-md mt-small"
              onClick={onLeaveParty}
            >
              <p className="tiny semibold">{t("buttons.leave_party")}</p>
            </button>
          )}
        </>
      ) : (
        renderNoMembers()
      )}
    </Section>
  );
}
