import classnames from "classnames";
interface Props {
  title?: string;
  className?: string;
}
export default function HeaderBar(props: Props) {
  const { title, className } = props;
  return (
    <div className={classnames(container, className)}>
      <h4>{title}</h4>
    </div>
  );
}

const container =
  "w-full flex justify-center items-center py-small bg-background-inkWhite-white_1";
