import useDineInFlow from "../../hooks/global/useDineInFlow";
import classnames from "classnames";

interface Props {
  children?: React.ReactNode;
  className?: string;
  id?: string;
}

const ButtonFooter = (props: Props): JSX.Element => {
  const { children, className, id } = props;

  const { isDineIn } = useDineInFlow();

  return (
    children && (
      <div
        id={id}
        className={classnames(
          "bg-background-inkWhite-white_1 px-small py-mini border w-full",
          className,
          {
            "bg-background-inkWhite-white_0": isDineIn,
          }
        )}
      >
        {children}
      </div>
    )
  );
};

export default ButtonFooter;
