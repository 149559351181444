import useTheme from "../../hooks/ui/useTheme";
import { ThemeInterface } from "../../services/exports/Interfaces";
import classnames from "classnames";

interface Props {
  active: boolean;
  color?: string;
  containerStyle?: Object;
  wrapperStyle?: Object;
  className?: string;
  onClick?: (active: boolean) => void;
}

export default function RadioButton(props: Props) {
  const { active, color, containerStyle, wrapperStyle, className, onClick } = props;
  const theme: ThemeInterface = useTheme();

  function getColor() {
    return color ?? theme.color.companyBrand.primary;
  }

  return (
    <div
      className={classnames(container, className)}
      style={{ borderColor: getColor(), ...containerStyle }}
      onClick={() => onClick && onClick(!active)}
    >
      <div
        className={buttonContainer}
        style={{
          backgroundColor: active ? getColor() : "transparent",
          ...wrapperStyle,
        }}
      />
    </div>
  );
}

const container =
  "h-5 w-5 rounded-full border flex justify-center items-center hover:shadow cursor-pointer";

const buttonContainer = "h-3.5 w-3.5 rounded-full";
