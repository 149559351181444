import { actionCreators } from "../../store/actions";
import ReduxActions from "../../store/ReduxActions";
import {
  CreateOrderPaymentInterface,
  ErrorHandlerInterface,
  OrderPaymentInterface,
} from "../exports/Interfaces";
import {DataWrappedResponse, DataWrapper, Response} from "../exports/Types";
import BackendApiClient from "./BackendApiClient";

class OrderPaymentsManager {
  public create = async (
    orderId: number,
    data: CreateOrderPaymentInterface,
    errorHandlers?: ErrorHandlerInterface
  ): Promise<DataWrappedResponse<DataWrapper<OrderPaymentInterface>>|Response<any>> => {
    const request = await BackendApiClient.request({
      method: 'POST',
      url: `/orders/${orderId}/payments`,
      data,
    }, false, errorHandlers);

    const { response, success } = request;

    if (success) {
      this.savePaymentRedux(response?.data?.data);
    }

    return request;
  }

  public getStatus = async (id: number): Promise<DataWrappedResponse<DataWrapper<OrderPaymentInterface>>> => {
    const request = await BackendApiClient.request({
      method: 'GET',
      url: `/transactions/${id}/status`,
    });

    const { response, success } = request;

    if (success) {
      this.savePaymentRedux(response?.data?.data);
    }

    return request;
  }

  private savePaymentRedux(data: OrderPaymentInterface) {
    ReduxActions.dispatch(actionCreators.dineInFlow.updatePaymentInfo(data));
  }
}

export default new OrderPaymentsManager();
