import collect from "collect.js";
import {
  ModifierInterface,
  ProductComboItemInterface,
  ProductInterface,
  ModifierGroupInterface,
} from "../exports/Interfaces";
import {BasketLineItemModifierInterface, BasketLineItemInterface} from "../../store/types";
import {ProductType} from "../exports/Constants";
import {CompleteBasketItemInterface} from "../../context/types";

export default class BasketHelpers {
  public static toggleModifier(
    lineItem: BasketLineItemInterface,
    modifierGroup: ModifierGroupInterface,
    modifier: ModifierInterface,
  ): BasketLineItemInterface {
    if (modifierGroup.max_count === 1) {
      return {
        ...lineItem,
        modifiers: collect(lineItem.modifiers)
          .reject((item) => item.modifier_group_id === modifierGroup.id)
          .push({
            modifier_group_id: modifierGroup.id,
            modifier_id: modifier.id,
            quantity: 1
          })
          .toArray(),
      }
    }

    const isAdded = !!collect(lineItem.modifiers).first(
      (item) => item.modifier_group_id === modifierGroup.id && item.modifier_id === modifier.id
    );

    if (isAdded || !modifierGroup.max_count) {
      return {
        ...lineItem,
        modifiers: isAdded
          ? collect(lineItem.modifiers)
            .reject(
              (item) => item.modifier_group_id === modifierGroup.id && item.modifier_id === modifier.id
            )
            .toArray()
          : [
            ...lineItem.modifiers,
            {
              modifier_group_id: modifierGroup.id,
              modifier_id: modifier.id,
              quantity: 1
            }
          ],
      };
    }

    const selected = collect(lineItem.modifiers).where('modifier_group_id', modifierGroup.id).count();
    if (selected >= modifierGroup.max_count) {
      return;
    }

    return {
      ...lineItem,
      modifiers: [
        ...lineItem.modifiers,
        {
          modifier_group_id: modifierGroup.id,
          modifier_id: modifier.id,
          quantity: 1,
        }
      ]
    };
  }

  public static validateLineItem(item: CompleteBasketItemInterface): boolean {
    try {
      const { product, children, modifiers } = item;

      if (product.type === ProductType.Combo) {
        return collect(product.items).where('is_required', true).every(
          (item) => !!collect(children).firstWhere('combo_item_id', item.id)
          ) && collect(children).every(
            (child) => this.validateLineItem(child)
          );
      }

      return this.validateModifiers(product, modifiers);
    } catch (error) {
      return false;
    }
  }

  public static validateModifiers(product: ProductInterface, modifiers: BasketLineItemModifierInterface[]): boolean {
    try {
      return !this.findInvalidModifierGroup(product, modifiers);
    } catch (error) {
      return false;
    }
  }

  public static findInvalidModifierGroup(product: ProductInterface, modifiers: BasketLineItemModifierInterface[]): null|ModifierGroupInterface {
    return collect(product.modifier_groups)
      .filter((item: ModifierGroupInterface) => this.isModifierGroupRequired(item))
      .first(
        (item: ModifierGroupInterface) => collect(modifiers).where('modifier_group_id', item.id).isEmpty()
      );
  }

  public static isModifierGroupRequired(modifierGroup: ModifierGroupInterface): boolean {
    return modifierGroup?.is_required && collect(modifierGroup?.modifiers).where('is_available', true).isNotEmpty();
  }

  public static findInvalidComboItem(item: CompleteBasketItemInterface): null|ProductComboItemInterface {
    const { product, children } = item;

    if (product.type === ProductType.Product) {
      return null;
    }

    return collect(product.items)
      .where('is_required', true)
      .first(
        (item) => !collect(children).firstWhere('combo_item_id', item.id)
      );
  }
}
