export default {
  title: 'Info',
  sections: {
    work_schedule: 'Opening hours: Online ordering',
    delivery_schedule: 'Delivery times',
    delivery_costs: 'Delivery costs',
    website: 'Website',
  },
  labels: {
    delivery_fees: 'Min. amount - {{threshold}}, Fee - {{fee}}',
  },
};
