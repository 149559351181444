import React from "react";
import useTheme from "../../hooks/ui/useTheme";
import { ThemeInterface } from "../../services/exports/Interfaces";


interface Props {
  className?: string;
  style?: Object;
  color?: string;
}

export default function DiscountIllustration(props: Props) {
  const { className, color, style } = props;
  const theme: ThemeInterface = useTheme();
  return (
    <svg
      className={className ?? "lg:h-40 lg:w-40 md:w-36 md:h-36 w-24 h-24"}
      viewBox="0 0 677 518"
      fill="none"
      style={style}
    >
      <path
        d="M99.6965 267.727C99.6965 267.727 67.7195 271.987 70.9285 284.901C74.1355 297.812 132.497 302.12 132.497 302.12L99.6965 267.727ZM150.288 321.001C150.288 321.001 118.307 325.228 121.5 338.143C124.693 351.058 183.051 355.432 183.051 355.432L150.288 321.001ZM189.685 363.271C189.685 363.271 157.788 368.089 161.219 380.942C164.65 393.796 223.079 397.09 223.079 397.09L189.685 363.271Z"
        fill={"#F8AE9D"}
      />
      <path
        d="M73.6505 63.3209C74.4625 68.7939 70.1815 72.9409 62.1125 78.0189C56.0425 81.8369 50.5665 85.3939 42.5395 86.6799C36.9225 87.5809 31.5415 87.0859 27.0705 86.0219C22.2265 84.8689 16.0685 84.0399 15.0755 79.2549C13.7115 72.6819 24.0595 58.2589 42.2095 54.7349C62.1485 50.8669 72.8585 57.9709 73.6505 63.3209Z"
        fill="url(#paint0_linear_1029_476)"
      />
      <path
        d="M511.355 122.978C512.652 140.575 495.399 156.2 495.399 156.2C495.399 156.2 476.052 143.283 474.768 125.682C473.47 108.084 490.71 92.4629 490.71 92.4629C490.71 92.4629 510.057 105.38 511.355 122.978Z"
        fill="url(#paint1_linear_1029_476)"
      />
      <path
        d="M364.826 503.672C371.986 491.725 365.367 474.586 365.367 474.586C365.367 474.586 347.143 476.827 339.991 488.781C332.831 500.727 339.442 517.859 339.442 517.859C339.442 517.859 357.666 515.618 364.826 503.672Z"
        fill="url(#paint2_linear_1029_476)"
      />
      <path
        d="M301.512 16.9534C295.17 26.3165 297.618 39.0485 306.981 45.3912C316.344 51.7338 329.076 49.2852 335.419 39.9221C341.762 30.559 339.313 17.827 329.95 11.4844C320.587 5.14177 307.855 7.59034 301.512 16.9534Z"
        fill={color ?? theme.color.companyBrand.primary}
      />
      <path
        d="M133.085 464.388C133.085 473.698 140.632 481.245 149.942 481.245C159.252 481.245 166.799 473.698 166.799 464.388C166.799 455.078 159.252 447.531 149.942 447.531C140.632 447.531 133.085 455.078 133.085 464.388Z"
        fill={color ?? theme.color.companyBrand.primary}
      />
      <path
        d="M452.72 464.99C452.72 475.592 444.125 484.187 433.523 484.187C422.92 484.187 414.326 475.592 414.326 464.99C414.326 454.388 422.92 445.793 433.523 445.793C444.125 445.793 452.72 454.388 452.72 464.99Z"
        fill={color ?? theme.color.companyBrand.primary}
      />
      <path
        d="M439.209 92.1462C436.434 96.2427 430.863 97.314 426.767 94.539C422.67 91.764 421.599 86.1935 424.374 82.0971C427.149 78.0006 432.719 76.9293 436.816 79.7043C440.912 82.4793 441.984 88.0497 439.209 92.1462Z"
        fill={color ?? theme.color.companyBrand.primary}
      />
      <path
        d="M513.83 357.073C513.83 363.434 508.674 368.591 502.312 368.591C495.951 368.591 490.794 363.434 490.794 357.073C490.794 350.711 495.951 345.555 502.312 345.555C508.674 345.555 513.83 350.711 513.83 357.073Z"
        fill="#E1E4E5"
      />
      <path
        d="M541.771 430.856C541.771 436.579 537.132 441.218 531.409 441.218C525.687 441.218 521.047 436.579 521.047 430.856C521.047 425.133 525.687 420.494 531.409 420.494C537.132 420.494 541.771 425.133 541.771 430.856Z"
        fill="#E1E4E5"
      />
      <path
        d="M580.505 172.92C576.583 178.71 568.709 180.225 562.919 176.302C557.129 172.38 555.615 164.507 559.537 158.716C563.46 152.926 571.333 151.412 577.123 155.334C582.913 159.257 584.428 167.13 580.505 172.92Z"
        fill="#E1E4E5"
      />
      <path
        d="M231.253 468.704C234.975 467.707 238.8 469.915 239.798 473.637C240.795 477.359 238.586 481.185 234.864 482.183C231.142 483.18 227.317 480.971 226.319 477.249C225.322 473.527 227.531 469.701 231.253 468.704Z"
        fill="#E1E4E5"
      />
      <path
        d="M28.1007 368.008C28.1007 370.914 25.7448 373.27 22.8387 373.27C19.9325 373.27 17.5767 370.914 17.5767 368.008C17.5767 365.102 19.9325 362.746 22.8387 362.746C25.7448 362.746 28.1007 365.102 28.1007 368.008Z"
        fill="#E1E4E5"
      />
      <path
        d="M642.117 213.24C638.11 219.156 631.025 221.352 626.292 218.146C621.56 214.94 620.972 207.546 624.979 201.631C628.986 195.715 636.071 193.519 640.803 196.725C645.536 199.93 646.124 207.325 642.117 213.24Z"
        fill="#E1E4E5"
      />
      <path
        d="M8.84003 171.168C13.7223 169.86 18.7407 172.757 20.0489 177.639C21.3571 182.522 18.4597 187.54 13.5775 188.848C8.69518 190.156 3.6768 187.259 2.36859 182.377C1.06039 177.494 3.95775 172.476 8.84003 171.168Z"
        fill="#E1E4E5"
      />
      <path
        d="M81.6084 388.306V388.122C97.0714 387.03 97.3094 375.531 97.3094 375.531C97.3094 375.531 97.5564 388.211 115.423 388.211C97.5564 388.211 97.3094 400.892 97.3094 400.892C97.3094 400.892 97.0714 389.397 81.6084 388.306ZM557.512 473.084V472.987C565.987 472.411 566.118 466.341 566.118 466.341C566.118 466.341 566.253 473.034 576.047 473.034C566.253 473.034 566.118 479.728 566.118 479.728C566.118 479.728 565.987 473.66 557.512 473.084Z"
        fill="#E1E4E5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M87.1894 213.883L89.3684 120.049C89.9454 95.1845 110.549 75.5155 135.393 76.0925L229.226 78.2715C241.166 78.5485 252.483 83.5645 260.74 92.1915L436.524 276.332C453.685 294.309 453.023 322.79 435.047 339.951L339.035 431.605C321.059 448.766 292.577 448.104 275.417 430.128L99.6324 245.987C91.3938 237.358 86.9183 225.811 87.1894 213.883Z"
        fill={color ?? theme.color.companyBrand.primary}
        stroke={color ?? theme.color.companyBrand.primary}
        strokeWidth="32.644"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M168.353 161.196C168.916 160.633 169.583 160.187 170.318 159.883C171.052 159.579 171.84 159.422 172.635 159.422C173.43 159.422 174.218 159.578 174.952 159.883C175.687 160.187 176.355 160.633 176.917 161.195C177.479 161.757 177.925 162.425 178.23 163.16C178.534 163.894 178.691 164.682 178.691 165.477C178.691 166.272 178.534 167.059 178.23 167.794C177.926 168.529 177.48 169.196 176.917 169.759C175.782 170.894 174.242 171.532 172.636 171.532C171.03 171.533 169.49 170.895 168.354 169.759C167.218 168.624 166.58 167.083 166.58 165.478C166.58 163.872 167.218 162.331 168.353 161.196"
        stroke="url(#paint3_linear_1029_476)"
        strokeWidth="20.088"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M349.922 290.464C351.212 291.472 352.195 292.819 352.762 294.354C353.33 295.889 353.459 297.552 353.135 299.156C352.816 300.75 352.052 302.222 350.932 303.401C349.813 304.581 348.382 305.419 346.806 305.821L311.103 314.804L298.54 349.744C297.985 351.261 297.014 352.591 295.738 353.582C294.463 354.572 292.934 355.184 291.326 355.347C288.05 355.677 284.857 354.109 283.084 351.3L263.168 319.612L226.265 317.94C222.955 317.778 220.006 315.749 218.646 312.699C217.287 309.648 217.75 306.099 219.843 303.529L243.272 274.969L233.024 238.973C232.12 235.776 233.089 232.354 235.525 230.138C236.721 229.052 238.198 228.324 239.788 228.037C241.378 227.751 243.016 227.918 244.515 228.519L278.889 242.557L309.44 222.014C310.792 221.107 312.373 220.601 314 220.554C315.628 220.508 317.235 220.923 318.636 221.751C320.048 222.583 321.199 223.791 321.963 225.241C322.726 226.69 323.07 228.324 322.957 229.958L320.819 267.18L349.922 290.464Z"
        fill="white"
        stroke="white"
        strokeWidth="15.066"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M676.769 322.979L578.579 421.169L548.362 407.244C537.659 402.313 525.47 401.93 514.481 406.042C504.132 409.915 488.681 413.606 468.925 412.403C430.475 410.061 399.015 397.763 399.015 397.763L459.566 336.61C459.566 336.61 419.831 307.828 404.577 232.086C402.943 223.979 398.572 216.662 392.231 211.329L356.181 181.013C356.181 181.013 312.512 178.449 296.444 137.163C296.444 137.163 291.7 108.304 302.634 109.67C302.634 109.67 426.347 160.54 442.642 170.204C458.937 179.867 471.519 183.604 507.888 228.818C517.519 240.788 526.101 250.214 533.522 257.606C547.762 271.785 564.939 282.666 583.843 289.48L676.769 322.979Z"
        fill="#F8AE9D"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1029_476"
          x1="78.3655"
          y1="99.2139"
          x2="-6.95451"
          y2="28.2499"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#EEEEEE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1029_476"
          x1="511.102"
          y1="188.421"
          x2="464.789"
          y2="28.7679"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#EEEEEE" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1029_476"
          x1="387.874"
          y1="457.442"
          x2="298.561"
          y2="553.567"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#EEEEEE" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1029_476"
          x1="189.947"
          y1="165.13"
          x2="146.718"
          y2="165.633"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#EEEEEE" />
        </linearGradient>
      </defs>
    </svg>
  );
}
