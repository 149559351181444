import BackendApiClient from "./BackendApiClient";
import { DataWrappedResponse } from "../exports/Types";
import { PayPalCreateOrderInterface } from "../exports/Interfaces";

class PayPalManager {
  public createOrder = async (
    transactionId: number
  ): Promise<DataWrappedResponse<PayPalCreateOrderInterface>> =>
    BackendApiClient.request({
      method: "POST",
      url: `/transactions/${transactionId}/paypal-payments`,
    });

  public capturePayPalPayments = async (transactionId: number): Promise<any> =>
    BackendApiClient.request({
      method: "POST",
      url: `/transactions/${transactionId}/captured-paypal-payments`,
    });
}

export default new PayPalManager();
