import { useEffect, useMemo, useRef, useState } from "react";
import CheckoutButton from "../components-library/buttons/CheckoutButton";
import CompanyInfo from "../components-library/company/CompanyInfo";
import Footer from "../components-library/footers/Footer";
import LoadingMenuPage from "../components-library/loading/LoadingMenuPage";
import CompanyHeader from "../components-library/navigation/CompanyHeader";
import OngoingOrder from "../components-library/order/OngoingOrder";
import { ENVIRONMENTS, SCREENS } from "../services/exports/Constants";
import { OrderItemInterface, ProductComboInterface, ProductInterface } from "../services/exports/Interfaces";
import OrderBasketScreen from "./OrderBasketScreen";
import useScreenType from "../hooks/utility/useScreenType";
import useScrollPosition from "../hooks/utility/useScrollPosition";
import CookieConsent from "../components-library/CookieConsent";
import CompanyDetailsModal from "../modals/CompanyDetailsModal";
import ReduxActions from "../store/ReduxActions";
import { actionCreators } from "../store/actions";
import { useTranslation } from "react-i18next";
import useInitialData from "../hooks/global/useInitialData";
import useLoyaltyProgram from "../hooks/loyalty/useLoyaltyProgram";
import useAppState from "../hooks/global/useAppState";
import useNavigate from "../hooks/navigation/useNavigate";
import MenuCategoryList from "../components-library/menu/MenuCategoryList";
import LoadingPage from "../components-library/loading/LoadingPage";
import OrderCarousel from "../components-library/carousel/OrderCarousel";
import OrderManager from "../services/api/OrderManager";
import useAuth from "../hooks/global/useAuth";
import collect from "collect.js";
import PageLayout from "../components-library/layouts/PageLayout";
import OrderType from "../components-library/order-type/OrderType";
import useAppMethods from "../hooks/utility/useAppMethods";
import PoweredByFooter from "../components-library/footers/PoweredByFooter";
import useSessionStorage, { SESSION_STORAGE_KEYS } from "../hooks/global/useSessionStorage";
import { COMPLETION_STATUS } from "../modals/OrderMethodModal";
import DealsCarousel from "../components-library/carousel/DealsCarousel";
import SavingsBanner from "../components-library/banners/SavingsBanner";
import CashbackBalance from "../components-library/cashback/CashbackBalance";
import LoyaltyBalance from "../components-library/loyalty-program/LoyaltyBalance";

const RestaurantDetailsScreen = (): JSX.Element => {
    const { t } = useTranslation(null, { keyPrefix: 'Pages:RestaurantDetailsScreen' });

    const { data } = useInitialData();
    const { company, ongoing_orders } = data;

    const [ongoingOrder, setOngoingOrder] = useState(ongoing_orders?.data[0] ?? null);
    const ongoingOrderRef = useRef(ongoingOrder);
    ongoingOrderRef.current = ongoingOrder;

    const { isLoggedIn } = useAuth();
    const { isDesktop } = useScreenType();
    const { orderMethodModalProps, menuItemModalProps, menu } = useAppState();
    const { scrollPosition } = useScrollPosition();
    const { getItem } = useSessionStorage();

    const orderMethodInfoComplete =
        getItem(SESSION_STORAGE_KEYS.order_method_info_status) === COMPLETION_STATUS.complete;

    const { navigate } = useNavigate();
    const { toggleOrderMethodModal, saveMenu } = useAppMethods();

    const menuLoaded = menu?.menuLoaded;

    const [mounted, setMounted] = useState<boolean>(false);
    const [showCompanyDetailsModal, setShowCompanyDetailsModal] = useState(false);

    const [lastOrder, setLastOrder] = useState<OrderItemInterface | null>(null);

    const menuListRef = useRef(null);
    const scrollPositionRef = useRef(scrollPosition);
    scrollPositionRef.current = scrollPosition;

    const showHeader = import.meta.env.VITE_MODE !== ENVIRONMENTS.PROD;

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        if (ongoing_orders && ongoing_orders?.data?.length) {
            setOngoingOrder(ongoing_orders?.data[0]);
        }
    }, [ongoing_orders]);

    async function init(): Promise<void> {
        ReduxActions.dispatch(actionCreators.dineInFlow.reset());
        if (isLoggedIn) {
            const { response, success } = await OrderManager.getOrderHistory({
                limit: 1,
            });
            if (success) {
                setLastOrder(collect(response?.data?.data)?.first());
            }
        }
        setMounted(true);
    }

    const onProductClick = orderMethodInfoComplete || !company?.has_live_orders
        ? null
        : (item: ProductInterface | ProductComboInterface | null) => onToggleOrderMethodModal(item);

    function onToggleOrderMethodModal(item: ProductInterface | ProductComboInterface | null): void {
        toggleOrderMethodModal({
            openModal: !orderMethodModalProps?.openModal,
            onComplete: item ? () => menuListRef.current?.toggleProductModal(item) : null,
        });
    }

    function toggleCompanyDetailsModal(): void {
        setShowCompanyDetailsModal(!showCompanyDetailsModal);
    }

    const renderBanner = useMemo(
      () => ongoing_orders?.data?.length > 0 && (
          <div className="lg:mt-none mt-small lg:mb-small mb-small lg:mx-none mx-small">
            <OngoingOrder order={ongoingOrderRef.current} />
          </div>
        ),
      [ongoing_orders],
    );

  const renderLastOrder = (): JSX.Element => {
    return (
      lastOrder && (
        <div className="w-full lg:mt-medium my-small">
              <OrderCarousel
                order={lastOrder}
                onProductClick={onProductClick}
              />
            </div>
          )
        );
    };

    const renderCheckoutButton = useMemo<JSX.Element | undefined>(() => {
        return (
          !orderMethodModalProps?.openModal &&
          !menuItemModalProps?.openModal &&
          !isDesktop && (
            <div className={buttonContainer}>
                <div className="p-mini">
                    <SavingsBanner />
                </div>
                <CheckoutButton
                    title={t("checkout_button.title")}
                    onClick={() => navigate(SCREENS.BASKET)}
                    hideAddProductsButton
                />
            </div>
          )
        );
    }, [orderMethodModalProps, isDesktop, menuItemModalProps]);

    const renderCompanyDetailsModal = useMemo<JSX.Element>(() => {
        return (
          <CompanyDetailsModal
            data={company}
            openModal={showCompanyDetailsModal}
            toggleModal={toggleCompanyDetailsModal}
          />
        );
    }, [company, showCompanyDetailsModal]);

    const renderLoadingPage = useMemo<JSX.Element | null>(() => {
        return !mounted && menuLoaded ? <LoadingPage /> : null;
    }, [mounted, menuLoaded]);

    const renderLoadingMenu = useMemo<JSX.Element | null>(() => {
        return !menuLoaded ? (
          <LoadingMenuPage
            startAnimation={mounted}
            onAnimationComplete={() => {
              saveMenu({ menuLoaded: true });
            }}
          />
        ) : null;
    }, [menuLoaded, mounted]);

    const renderCookieBanner = useMemo<JSX.Element | null>(() => {
        return mounted ? <CookieConsent /> : null;
    }, [mounted]);

    const renderOrderType = () =>
        !isDesktop && (
            <div className="px-xlarge pb-mini border-b">
                <OrderType />
            </div>
        );

    return (
        <PageLayout
            header={
                showHeader && (
                    <header>
                        <CompanyHeader />
                    </header>
                )
            }
        >
            <div>
                <main className={container} data-test="restaurant-details-body">
                    <div className={mainContainer}>
                        <div className={mainGrid}>
                            <div className="lg:px-large pr-none">
                                <MenuCategoryList
                                  ref={menuListRef}
                                  ListHeaderComponent={
                                      <>
                                          <div>{renderBanner}</div>
                                          <CompanyInfo openInfoModal={toggleCompanyDetailsModal} />
                                          {renderOrderType()}
                                        <div className="bg-background-inkWhite-white_1 mb-medium">
                                          <div className="lg:px-none px-small lg:mt-none mt-small">
                                            <CashbackBalance/>
                                            <LoyaltyBalance/>
                                          </div>
                                        </div>
                                      </>
                                  }
                                  ListFooterComponent={<Footer/>}
                                  DiscountsComponent={<DealsCarousel />}
                                  LastOrderComponent={renderLastOrder()}
                                  onProductClick={onProductClick}
                                />
                            </div>
                            <div className="px-mini sticky lg:block hidden lg:bottom-mini mb-mini z-30">
                                <SavingsBanner />
                            </div>
                        </div>

                        <div
                            className={secondGrid}
                            style={{
                                height: window.innerHeight - document.getElementById("page-header")?.getBoundingClientRect()?.height ?? 0,
                                top: document.getElementById("page-header")?.getBoundingClientRect()?.height ?? 0,
                            }}
                        >
                            <OrderBasketScreen customParentContainer="w-full h-full" />
                        </div>
                    </div>
                    <PoweredByFooter />
                </main>
                {renderCheckoutButton}
                {renderCompanyDetailsModal}
                {renderLoadingPage}
                {renderLoadingMenu}
                {renderCookieBanner}
            </div>
        </PageLayout>
    );
};

export default RestaurantDetailsScreen;

const container = "h-screen";

const mainContainer = "grid grid-cols-4";

const mainGrid = "lg:col-span-3 col-span-4 w-full relative lg:pt-small";

const secondGrid = `sticky max-h-screen col-span-1 lg:flex hidden overflow-y-auto overscroll-contain bg-background-inkWhite-white_1 z-20 shadow-section border border-[12px] border-brand-inkGrey-grey_6 rounded-[12px]`;

const buttonContainer = "lg:hidden block fixed bottom-0 w-full z-50";
