import React from "react";
import useTheme from "../../hooks/ui/useTheme";
import { ThemeInterface } from "../../services/exports/Interfaces";

interface Props {
  type?: TYPE;
  className?: string;
  style?: Object;
  color?: string;
}

export enum TYPE {
  fill = 'fill',
  outline = 'outline',
}

export default function PlusCircleIcon(props: Props = { type: TYPE.fill }) {
  const {
    type,
    className,
    color,
    style,
  } = props;
  const theme: ThemeInterface = useTheme();

  const render = () => ({
    [TYPE.outline]: (
      <path
        d="M7.2 12.5H8.8V9.3H12V7.7H8.8V4.5H7.2V7.7H4V9.3H7.2V12.5ZM8 16.5C6.89333 16.5 5.85333 16.29 4.88 15.87C3.90667 15.45 3.06 14.88 2.34 14.16C1.62 13.44 1.05 12.5933 0.63 11.62C0.21 10.6467 0 9.60667 0 8.5C0 7.39333 0.21 6.35333 0.63 5.38C1.05 4.40667 1.62 3.56 2.34 2.84C3.06 2.12 3.90667 1.55 4.88 1.13C5.85333 0.71 6.89333 0.5 8 0.5C9.10667 0.5 10.1467 0.71 11.12 1.13C12.0933 1.55 12.94 2.12 13.66 2.84C14.38 3.56 14.95 4.40667 15.37 5.38C15.79 6.35333 16 7.39333 16 8.5C16 9.60667 15.79 10.6467 15.37 11.62C14.95 12.5933 14.38 13.44 13.66 14.16C12.94 14.88 12.0933 15.45 11.12 15.87C10.1467 16.29 9.10667 16.5 8 16.5ZM8 14.9C9.78667 14.9 11.3 14.28 12.54 13.04C13.78 11.8 14.4 10.2867 14.4 8.5C14.4 6.71333 13.78 5.2 12.54 3.96C11.3 2.72 9.78667 2.1 8 2.1C6.21333 2.1 4.7 2.72 3.46 3.96C2.22 5.2 1.6 6.71333 1.6 8.5C1.6 10.2867 2.22 11.8 3.46 13.04C4.7 14.28 6.21333 14.9 8 14.9Z"
        fill={color ?? theme.color.brand.secondary}
      />
    ),
  })[type] ?? (
    <path
      d="M9.325 15H10.825V10.85H15V9.35H10.825V5H9.325V9.35H5V10.85H9.325V15ZM10.0066 20C8.62775 20 7.33192 19.7375 6.11915 19.2125C4.90638 18.6875 3.84583 17.9708 2.9375 17.0625C2.02917 16.1542 1.3125 15.093 0.7875 13.879C0.2625 12.665 0 11.3678 0 9.9875C0 8.60718 0.2625 7.31003 0.7875 6.09602C1.3125 4.88201 2.02917 3.825 2.9375 2.925C3.84583 2.025 4.90701 1.3125 6.12103 0.7875C7.33503 0.2625 8.63218 0 10.0125 0C11.3928 0 12.69 0.2625 13.904 0.7875C15.118 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61445 20 9.99335C20 11.3723 19.7375 12.6681 19.2125 13.8809C18.6875 15.0936 17.975 16.1526 17.075 17.0579C16.175 17.9632 15.1167 18.6798 13.9 19.2079C12.6833 19.736 11.3855 20 10.0066 20Z"
      fill={color ?? theme.color.brand.secondary}
    />
  );

  return (
    <svg
      className={className ?? "h-4 w-4"}
      viewBox={`0 0 ${type === TYPE.outline ? 16 : 20} ${type === TYPE.outline ? 17 : 20}`}
      fill="none"
      style={style}
    >
      {render()}
    </svg>
  );
}
