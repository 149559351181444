export default {
  labels: {
    request_invoice: 'Rechnung anfordern',
  },
  modal: {
    title: 'Rechnungsinformation',
    form: {
      company_name: 'Unternehmen (Optional)',
      first_name: 'Vornam',
      last_name: 'Nachname',
      address: 'Strasse + Nummer',
      zip_code: 'PLZ',
      city: 'Stadt',
      tax_id: 'St-Nr./USt-IdNr. *',
      email: 'Email',
    },
    save_button: 'Speichern',
  },
};
