import collect from "collect.js";
import hexRgb from "hex-rgb";

class ColorResource {
  public getRgbValue(hex: string): string {
    if (!hex) {
      return;
    }
    const rbaArray = hexRgb(hex, { format: "array" })?.slice(0, 3);
    let rba = "";
    collect(rbaArray)?.each((value: number, index: number) => {
      if (index === rbaArray?.length - 1) {
        rba += value;
      } else {
        rba += `${value} `;
      }
    });
    return rba;
  }
}

export default new ColorResource();
