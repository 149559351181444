import { useTranslation } from "react-i18next";
import RewardIcon from "../../assets/logo/RewardIcon";
import useLoyaltyProgram from "../../hooks/loyalty/useLoyaltyProgram";
import useStore from "../../hooks/global/useStore";
import { SCREENS } from "../../services/exports/Constants";
import RewardStatusResource from "../../services/resources/RewardStatusResource";
import CircularProgressBar from "./CircularProgressBar";
import useNavigate from "../../hooks/navigation/useNavigate";

interface Props {
  progressBarSize?: number | string;
  iconClass?: string;
  className?: string;
  renderText?: (points: number) => void;
}

const LoyaltyProgress = (props: Props): JSX.Element => {
  const { t } = useTranslation(null, { keyPrefix: 'Components:Progress:LoyaltyProgress' });

  const { progressBarSize, iconClass, className, renderText} = props

  const { company } = useStore()?.initialData;

  const { navigate } = useNavigate();

  const { hasLoyaltyPromotion } = useLoyaltyProgram();

  const reward = new RewardStatusResource({
    rewards: company?.rewards,
    rewardBalance: company?.reward_balance,
  });

  function progress(): number {
    try {
      if (typeof company?.reward_balance !== "number") {
        return 0;
      }

      return (company?.reward_balance / reward.getNextReward()?.cost) * 100;  
    } catch (error) {
      return 0;
    }
  }

  function onClick(): void {
    navigate(SCREENS.REWARD_DETAILS_SCREEN, {
      state: {
        prevPage: window.location.pathname,
      },
    });
  }

  return (
    hasLoyaltyPromotion && (
      // @ts-ignore
      <button className="flex items-center" onClick={onClick}>
        <div className="relative flex items-center justify-center">
          <CircularProgressBar value={progress()} size={progressBarSize} />
          <div className="absolute">
            <RewardIcon fill className={iconClass} />
          </div>
        </div>
        {renderText ? renderText(company?.reward_balance ?? 0) : (
          <>
            <h6 className="ml-mini mr-tiny text-mini lg:block hidden text-companyBrand-primary">
              {t("point", {
                count: company?.reward_balance ?? 0,
              })}
            </h6>
            <h6 className="text-mini text-companyBrand-primary">
              {company?.reward_balance ?? 0}
            </h6>
          </>
        )}
      </button>
    )
  );
};

export default LoyaltyProgress;
