import React from 'react';
import {CompanyDetailsInterface} from "../../services/exports/Interfaces";
import useMaps from "../../hooks/location/useMaps";
import {GoogleMap, Marker} from '@react-google-maps/api';
import classnames from "classnames";
import DeliveryZoneGeometry from "./DeliveryZone";

interface Props {
  data: CompanyDetailsInterface;
  className?: string;
}

export default function CompanyLocation(props: Props) {
  const {data, className} = props;

  const {generateEmbedMapUrl} = useMaps();

  const renderMap = () => {
    if (!data) {
      return undefined;
    }

    if (!data.has_delivery && !data.has_multiple_locations && data.google_places_id) {
      return renderEmbedMap();
    }

    return renderDynamicMap();
  };

  const renderEmbedMap = () => (
    <iframe
      src={generateEmbedMapUrl()}
      className="h-full w-full"
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    />
  );

  const renderDynamicMap = () => data.lat && data.lon && (
    <GoogleMap
      mapContainerClassName="h-full w-full"
      center={{
        lat: data.lat,
        lng: data.lon,
      }}
      zoom={12}
      options={{
        mapTypeControl: false,
        streetViewControl: false,
      }}
    >
      {renderMarker()}
      {data?.delivery_zones?.map((item) => (
        <DeliveryZoneGeometry
          data={item}
          key={`delivery-zone-${item?.id}`}
        />
      ))}
    </GoogleMap>
  );

  const renderMarker = () => (
    <Marker
      label={data?.name}
      position={{
        lat: data.lat,
        lng: data.lon,
      }}
    />
  );

  return (
    <div className={classnames('w-full', className)}>
      {renderMap()}
    </div>
  );
}