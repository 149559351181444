import { useMemo, useState, useEffect } from "react";
import { FeedbackLabelInterface } from "../../services/exports/Interfaces";
import FeedbackLabel from "../../components-library/labels/tinyLabels/FeedbackLabel";
import useToast from "./useToast";

interface Props {
  renderErrors?: boolean;
  renderToasts?: boolean;
}

export default function useFeedbackHandler(
  props: Props = {
    renderErrors: true,
    renderToasts: false,
  }
) {
  const { renderErrors, renderToasts } = props;

  const toast = useToast();

  const [feedback, setFeedback] = useState<FeedbackLabelInterface>({
    message: "",
    errors: null,
  });

  useEffect(() => {
    if (renderToasts) {
      renderFeedbackToast();
    }
  }, [renderToasts, feedback]);

  const renderFeedbackToast = () => {
    if (!feedback.message) {
      return null;
    }

    if (feedback?.type === "Error") {
      return toast.error(feedback.message, {
        duration: Infinity,
      });
    }

    if (feedback?.type === "Success") {
      return toast.success(feedback.message, {
        duration: 6000,
      });
    }

    if (feedback?.type === "Warning") {
      return toast(feedback.message, {
        duration: Infinity,
        icon: "⚠️",
      });
    }

    return toast(feedback.message, {
      duration: 6000,
    });
  };

  const renderFeedbackLabel = useMemo(
    () =>
      feedback?.message && (
        <div className="mt-3">
          <FeedbackLabel
            message={feedback?.message}
            type={feedback?.type}
            errors={renderErrors ? feedback?.errors : undefined}
          />
        </div>
      ),
    [feedback, renderErrors]
  );

  const resetFeedback = () =>
    setFeedback({
      message: "",
      errors: null,
    });

  return {
    feedback,
    setFeedback,
    renderFeedbackLabel,
    renderFeedbackToast,
    resetFeedback,
  };
}
