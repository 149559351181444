import {createGlobalStyle} from "styled-components";

const pageStyle = createGlobalStyle`
    ul.lst-kix_2skaq8gcne7c-1 {
        list-style-type: none
    }

    ul.lst-kix_2skaq8gcne7c-0 {
        list-style-type: none
    }

    ul.lst-kix_2skaq8gcne7c-3 {
        list-style-type: none
    }

    ul.lst-kix_2skaq8gcne7c-2 {
        list-style-type: none
    }

    li.li-bullet-0:before {
        margin-left: -18pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 18pt
    }

    .lst-kix_2skaq8gcne7c-3 > li:before {
        content: "\\0025cf  "
    }

    .lst-kix_2skaq8gcne7c-2 > li:before {
        content: "\\0025a0  "
    }

    .lst-kix_2skaq8gcne7c-4 > li:before {
        content: "\\0025cb  "
    }

    .lst-kix_2skaq8gcne7c-1 > li:before {
        content: "\\0025cb  "
    }

    .lst-kix_2skaq8gcne7c-5 > li:before {
        content: "\\0025a0  "
    }

    ul.lst-kix_2skaq8gcne7c-5 {
        list-style-type: none
    }

    .lst-kix_2skaq8gcne7c-7 > li:before {
        content: "\\0025cb  "
    }

    ul.lst-kix_2skaq8gcne7c-4 {
        list-style-type: none
    }

    ul.lst-kix_2skaq8gcne7c-7 {
        list-style-type: none
    }

    .lst-kix_2skaq8gcne7c-8 > li:before {
        content: "\\0025a0  "
    }

    .lst-kix_2skaq8gcne7c-0 > li:before {
        content: "\\0025cf  "
    }

    .lst-kix_2skaq8gcne7c-6 > li:before {
        content: "\\0025cf  "
    }

    ul.lst-kix_2skaq8gcne7c-6 {
        list-style-type: none
    }

    ul.lst-kix_2skaq8gcne7c-8 {
        list-style-type: none
    }

    ol {
        margin: 0;
        padding: 0
    }

    table td, table th {
        padding: 0
    }

    .c15 {
        border-right-style: solid;
        padding: 8pt 12pt 8pt 0pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 52.5pt;
        border-top-color: #000000;
        border-bottom-style: solid
    }

    .c4 {
        border-right-style: solid;
        padding: 8pt 12pt 8pt 0pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 57.8pt;
        border-top-color: #000000;
        border-bottom-style: solid
    }

    .c11 {
        border-right-style: solid;
        padding: 8pt 12pt 8pt 0pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 195.8pt;
        border-top-color: #000000;
        border-bottom-style: solid
    }

    .c7 {
        border-right-style: solid;
        padding: 8pt 12pt 8pt 0pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 57pt;
        border-top-color: #000000;
        border-bottom-style: solid
    }

    .c8 {
        border-right-style: solid;
        padding: 8pt 12pt 8pt 0pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 93pt;
        border-top-color: #000000;
        border-bottom-style: solid
    }

    .c3 {
        background-color: #ffffff;
        color: #333333;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 10.5pt;
        font-family: "Helvetica Neue";
        font-style: normal
    }

    .c6 {
        background-color: #ffffff;
        -webkit-text-decoration-skip: none;
        color: #1155cc;
        font-weight: 400;
        text-decoration: underline;
        text-decoration-skip-ink: none;
        font-size: 10.5pt;
        font-family: "Helvetica Neue"
    }

    .c23 {
        padding-top: 0pt;
        padding-bottom: 8pt;
        line-height: 1.15;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    .c28 {
        padding-top: 0pt;
        padding-bottom: 36pt;
        line-height: 1.1;
        orphans: 2;
        widows: 2;
        text-align: center
    }

    .c0 {
        padding-top: 12pt;
        padding-bottom: 15pt;
        line-height: 1.15;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    .c18 {
        padding-top: 15pt;
        padding-bottom: 8pt;
        line-height: 1.1;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    .c22 {
        background-color: #ffffff;
        font-size: 10.5pt;
        font-family: "Helvetica Neue";
        color: #333333;
        font-weight: 400
    }

    .c17 {
        background-color: #ffffff;
        font-size: 9pt;
        font-family: "Helvetica Neue";
        color: #333333;
        font-weight: 700
    }

    .c14 {
        background-color: #ffffff;
        font-size: 10.5pt;
        font-family: "Helvetica Neue";
        color: #333333;
        font-weight: 700
    }

    .c1 {
        background-color: #ffffff;
        font-size: 9pt;
        font-family: "Helvetica Neue";
        color: #333333;
        font-weight: 400
    }

    .c27 {
        background-color: #ffffff;
        color: #333333;
        font-weight: 700;
        font-size: 24pt;
        font-family: "Helvetica Neue"
    }

    .c24 {
        background-color: #ffffff;
        color: #333333;
        font-weight: 700;
        font-size: 18pt;
        font-family: "Helvetica Neue"
    }

    .c30 {
        color: #000000;
        font-weight: 400;
        font-size: 11pt;
        font-family: "Arial"
    }

    .c2 {
        border-spacing: 0;
        border-collapse: collapse;
        margin-right: auto
    }

    .c13 {
        background-color: #ffffff;
        max-width: 468pt;
        padding: 72pt 72pt 72pt 72pt
    }

    .c16 {
        text-decoration: none;
        vertical-align: baseline;
        font-style: normal
    }

    .c26 {
        margin-left: 36pt;
        padding-left: 0pt
    }

    .c9 {
        color: inherit;
        text-decoration: inherit
    }

    .c29 {
        padding: 0;
        margin: 0
    }

    .c19 {
        vertical-align: baseline;
        font-style: normal
    }

    .c12 {
        height: 36pt
    }

    .c20 {
        height: 75.8pt
    }

    .c10 {
        height: 45.8pt
    }

    .c5 {
        height: 55.5pt
    }

    .c21 {
        height: 26.2pt
    }

    .c25 {
        height: 66pt
    }

    .title {
        padding-top: 0pt;
        color: #000000;
        font-size: 26pt;
        padding-bottom: 3pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    .subtitle {
        padding-top: 0pt;
        color: #666666;
        font-size: 15pt;
        padding-bottom: 16pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    li {
        color: #000000;
        font-size: 11pt;
        font-family: "Arial"
    }

    p {
        margin: 0;
        color: #000000;
        font-size: 11pt;
        font-family: "Arial"
    }

    h1 {
        padding-top: 20pt;
        color: #000000;
        font-size: 20pt;
        padding-bottom: 6pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h2 {
        padding-top: 18pt;
        color: #000000;
        font-size: 16pt;
        padding-bottom: 6pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h3 {
        padding-top: 16pt;
        color: #434343;
        font-size: 14pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h4 {
        padding-top: 14pt;
        color: #666666;
        font-size: 12pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h5 {
        padding-top: 12pt;
        color: #666666;
        font-size: 11pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h6 {
        padding-top: 12pt;
        color: #666666;
        font-size: 11pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        font-style: italic;
        orphans: 2;
        widows: 2;
        text-align: left
    }
`;

export default pageStyle;