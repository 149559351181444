import React, {useState, useRef} from "react";
import {
  ProductComboInterface,
  ProductComboItemInterface,
  ProductInterface,
} from "../../services/exports/Interfaces";
import useScreenType from "../../hooks/utility/useScreenType";
import { MODAL_SIZES } from "../../services/exports/Constants";
import CustomModal from "../../components-library/CustomModal";
import useAppState from "../../hooks/global/useAppState";
import useMenu from "../../hooks/menu/useMenu";
import collect from "collect.js";
import ComboItemProductContent from "./ComboItemProductContent";
import ComboContent from "./ComboContent";
import {BasketLineItemInterface} from "../../store/types";
import useAppMethods from "../../hooks/utility/useAppMethods";

export default function ComboModal(): JSX.Element {
  const {  comboModalProps } = useAppState();
  const {
    combo: comboProp,
    lineItem: lineItemProp,
  } = comboModalProps;

  const { toggleComboModal } = useAppMethods();
  const { isDesktop } = useScreenType();
  const { compileProduct } = useMenu();

  const combo: ProductComboInterface = compileProduct(comboProp.id) as ProductComboInterface;
  const product = combo.main_product;
  const items = collect(combo.items);

  const [scrollY, setScrollY] = useState<number>(0);

  const [comboItemRefs] = useState(
    items.keyBy('id').map(() => React.createRef())
  );

  const [collapsedItems, setCollapsedItems] = useState<number[]>(items.pluck('id').toArray());
  const toggleItem = (id: number) => setCollapsedItems(
    (current) => {
      return current.includes(id)
        ? collect(current).reject((collapsed) => collapsed === id).toArray()
        : [
          ...current,
          id,
        ]
    }
  );

  const [lineItem, setLineItem] = useState<BasketLineItemInterface>(lineItemProp ?? {
    product_id: combo.id,
    quantity: 1,
    note: null,
    children: [
      {
        product_id: product.id,
        quantity: 1,
        modifiers: [],
        combo_item_id: items.first().id,
      },
    ],
  });
  const updateLineItem = (field, value) => setLineItem((current): BasketLineItemInterface => ({
    ...current,
    [field]: value,
  }));
  const updateComboItem = (id, data) => setLineItem((current): BasketLineItemInterface => {
    const itemExists = !!collect(current.children).firstWhere('combo_item_id', id);

    return {
      ...current,
      // @ts-ignore
      children: collect(current.children)
        // @ts-ignore
        .when(
          itemExists,
          // @ts-ignore
          (collection) => collection.transform(
              (item) => item.combo_item_id === id
                ? {...item, ...data}
                : item
            ),
          (collection) => collection.push({
            quantity: 1,
            modifiers: [],
            combo_item_id: id,
            ...data,
          })
        )
        // @ts-ignore
        .toArray()
    };
  });

  const [selectedComboItem, setSelectedComboItem] = useState<ProductComboItemInterface>(null);
  const [selectedProduct, setSelectedProduct] = useState<ProductInterface>(null);

  const scrollYRef = useRef<number>(scrollY);
  scrollYRef.current = scrollY;

  const toggleModal = () => {
    if (selectedProduct) {
      return;
    }

    toggleComboModal();
  }

  function handleScroll(y: number): void {
    setScrollY(y);
  }

  const onSelectComboProduct = (comboItem?: ProductComboItemInterface, product?: ProductInterface): void => {
    if (product && !product.is_available) {
      return;
    }

    if (comboItem && product.modifier_groups?.length === 0) {
      return updateComboItem(comboItem.id, {
        product_id: product.id,
        combo_item_id: comboItem.id,
        quantity: 1,
        modifiers: [],
      });
    }

    setSelectedComboItem(comboItem);
    setSelectedProduct(product);

    if (!product) {
      const ref: React.RefObject<any> = comboItemRefs.get(selectedComboItem?.id);

      setTimeout(() => ref?.current?.scrollIntoView(), 5);
    }
  }

  const onSelectNullOption = (comboItem: ProductComboItemInterface): void => {
    if (comboItem.is_required) {
      return;
    }

    setLineItem((current) => ({
      ...current,
      children: collect(current.children)
        .where('combo_item_id', '!=', comboItem.id)
        .toArray(),
    }))
  }

  const renderContent = () => selectedProduct
    ? (
      <ComboItemProductContent
        data={selectedProduct}
        lineItemData={collect(lineItem.children).firstWhere('combo_item_id', selectedComboItem?.id)}
        comboItem={selectedComboItem}
        updateComboItem={updateComboItem}
        goBack={() => onSelectComboProduct()}
      />
    )
    : (
      <ComboContent
        data={combo}
        comboItemRefs={comboItemRefs}
        lineItem={lineItem}
        collapsedItems={collapsedItems}
        toggleItem={toggleItem}
        onSelectProduct={onSelectComboProduct}
        onSelectNullOption={onSelectNullOption}
        updateLineItem={updateLineItem}
        updateComboItem={updateComboItem}
      />
    );

  return (
    <CustomModal
      open={true}
      size={MODAL_SIZES.SM}
      fullScreen={!isDesktop}
      containerClassName="h-full"
      onClose={toggleModal}
      onScroll={handleScroll}
    >
      <div className="w-full h-full bg-background-inkWhite-white_1 relative">
        {renderContent()}
      </div>
    </CustomModal>
  );
};
