interface Props {
  name: string | null;
  className?: string;
  textClassName?: string;
}

const UserInitials = (props: Props): JSX.Element => {
  const { name, className, textClassName } = props;

  const initial: string = name?.charAt(0) ?? "";

  return (
    <div
      className={
        className ??
        "h-8 aspect-square rounded-full bg-brand-inkGrey-grey_2 flex justify-center items-center"
      }
    >
      <h6 className={textClassName}>{initial}</h6>
    </div>
  );
};

export default UserInitials;
