export default {
  animation_description: {
    reward_unlocked: "Reward unlocked",
  },
  reward_unlocked: {
    titles: {
      free_menu_item: "Free {{product}} unlocked 🥳",
      multiple_free_menu_item: "Free {{product}} & more unlocked 🥳",
      discount: "{{discount}}% unlocked 🥳",
    },
    descriptions: {
      free_menu_item: "Redeem 1 free {{product}} on your next order",
      multiple_free_menu_item:
        "Redeem 1 free {{product}} & more on your next order",
      discount: "Redeem {{discount}}% discount on your next order",
    },
  },
};
