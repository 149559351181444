import PlusIcon from "../../../assets/logo/PlusIcon";
import useTheme from "../../../hooks/ui/useTheme";
import {PaymentOptionPropertiesInterface, ThemeInterface} from "../../../services/exports/Interfaces";
import {useTranslation} from "react-i18next";
import PaymentMethodItem, {MODE} from "../../../components-library/payment/PaymentMethodItem";
import {useMemo, useState} from "react";
import PaymentMethodModal from "../../../modals/PaymentMethodModal";
import {useSearchParams} from "react-router-dom";
import {PaymentMethod} from "../../../services/exports/Constants";
import RequestInvoiceItem from "../../payment/RequestInvoiceItem";

interface Props {
  defaultPaymentMethod: PaymentOptionPropertiesInterface | null;
  testId?: string;
}

export default function PaymentInfoStep(props: Props) {
  const { t } = useTranslation(null, { keyPrefix: 'Components:Checkout:Stepper:PaymentInfoStep' });

  const { defaultPaymentMethod, testId } = props;

  const theme: ThemeInterface = useTheme();
  const [searchParams] = useSearchParams();

  const urlParams = Object.fromEntries([...searchParams]);
  const defaultPaymentMethodId: PaymentMethod | undefined = urlParams?.defaultPaymentMethodId as PaymentMethod ?? undefined;
  const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false);

  function renderEmptyPaymentMethod() {
    return (
      <button
        className="flex flex-row w-full px-small py-xsmall border rounded border-brand-inkGrey-grey_2 items-center justify-between"
        onClick={() => setShowPaymentMethodModal(true)}
        data-test={testId}
      >
        <p style={{ color: theme.color.brand.primary }}>
          {t('empty_payment_method.add')}
        </p>
        <PlusIcon color={theme.color.brand.primary} />
      </button>
    );
  }

  function renderPaymentMethod() {
    if (!defaultPaymentMethod) {
      return renderEmptyPaymentMethod();
    }

    return (
      <PaymentMethodItem
        item={defaultPaymentMethod}
        selectedPaymentMethod={defaultPaymentMethod}
        mode={MODE.preview}
        onClick={() => setShowPaymentMethodModal(true)}
      />
    );
  }

  const renderPaymentMethodModal = useMemo(() => {
    function onCloseModal(): void {
      return setShowPaymentMethodModal(false);
    }

    return (
      <PaymentMethodModal
        openModal={showPaymentMethodModal}
        defaultPaymentMethodId={defaultPaymentMethodId}
        onCloseModal={onCloseModal}
      />
    );
  }, [showPaymentMethodModal]);

  return (
    <div>
      {renderPaymentMethod()}
      <RequestInvoiceItem />

      {renderPaymentMethodModal}
    </div>
  );
}
