import Checkout from "./checkout";
import Combo from './combo';
import DineIn from "./dine-in";
import CashbackEarnedModal from "./CashbackEarnedModal";
import CompanyDetailsModal from "./CompanyDetailsModal";
import DownloadAppModal from "./DownloadAppModal";
import MenuItemsModal from "./MenuItemsModal";
import MultiPromoCodeModel from "./MultiPromoCodeModel";
import PaymentMethodModal from "./PaymentMethodModal";
import OrderMethodModal from "./OrderMethodModal";
import UpSellModal from "./UpSellModal";
import LoginModal from "./LoginModal";
import RewardsUnlockedModal from "./RewardsUnlockedModal";
import PointsEarnedModal from "./PointsEarnedModal";
import FreeProductsModal from "./FreeProductsModal";

export default {
  Checkout,
  Combo,
  DineIn,
  CashbackEarnedModal,
  CompanyDetailsModal,
  DownloadAppModal,
  MenuItemsModal,
  MultiPromoCodeModel,
  PaymentMethodModal,
  OrderMethodModal,
  UpSellModal,
  LoginModal,
  RewardsUnlockedModal,
  PointsEarnedModal,
  FreeProductsModal,
};
