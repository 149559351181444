export default {
  cashback: {
    title: 'Rate us & get',
    description: '{{value}} points ({{moneyValue}})',
  },
  product_suggestions: {
    title: "Rate us & get",
    description: "a free {{product}} & more ",
    types: {
      free_reward: {
        message_default: "Rate us & get a free {{product}}",
        message_short: "Get a free {{product}}",
        message_reward: "Free {{product}}",
        message_reward_prefix: "1 Free {{product}}",
      },
      multiple_free_rewards: {
        message_default: "Rate us & get a free {{product}} & more",
        message_short: "Get a free {{product}} & more",
        message_reward: "Free {{product}} & more",
        message_reward_prefix: "1 Free {{product}} & more",
      },
      discount: {
        message_default: "Rate us & get {{discount}}% off next order",
        message_short: "Get {{discount}}% off next order",
        message_reward: "{{discount}}% off",
        message_reward_prefix: "{{discount}}% off",
      },
    },
    points: "{{count}} pts",
  },
};
