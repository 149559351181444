export default {
  animation_description: {
    reward_unlocked: "Prämie verdient",
  },
  reward_unlocked: {
    titles: {
      free_menu_item: "Gratis {{product}} freigeschaltet 🥳",
      multiple_free_menu_item: "Gratis {{product}} & mehr freigeschaltet 🥳",
      discount: "{{discount}}% freigeschaltet 🥳",
    },
    descriptions: {
      free_menu_item:
        "Löse 1 gratis {{product}} bei deiner nächsten Bestellung ein",
      multiple_free_menu_item:
        "Löse 1 gratis {{product}} & mehr bei deiner nächsten Bestellung ein",
      discount: "Löse {{discount}}% Rabatt bei deiner nächsten Bestellung ein",
    },
  },
};
