export default {
  steps: {
    phone_number: {
      title: 'Your mobile number',
    },
    personal_info: {
      title: 'Personal information',
    },
    receipt_info: {
      title_enabled: 'Receipt will be sent',
      title_disabled: 'Do you want a receipt?',
    },
    order_info: {
      title: 'Order info',
    },
    payment_method: {
      title: 'Pay securely',
      securely: 'securely',
      titles: {
        cash: 'Cash',
        cash_or_card_in_store: 'Cash or card payment in restaurant',
      },
      descriptions: {
        cash_payment: 'Please check that you have the exact amount of cash',
        online_payment: 'all transactions are private and encrypted'
      },
    },
  },
  buttons: {
    confirm: 'Confirm',
  },
};
