export default {
  header: {
    payment_method: "Zahlungsmethoden",
  },
  confirm_cancel_modal: {
    title: "Entfernen {{name}}",
    description: "Bist du sicher, dass du {{name}} als Zahlungsmethode entfernen möchtest?",
    buttons: {
      yes: "Ja, entfernen",
      no: "Nein",
    },
  },

  custom_button: {
    save: "Speichern",
  },

  error_messages: {
    saving_payment_method_unsuccessful: "Zahlungsmethode konnte nicht gespeichert werden",
    removing_payment_method_unsuccessful:
      "Die Zahlungsmethode konnte nicht entfernt werden. Bitte versuche es erneut.",
  },
};
