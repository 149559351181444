import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CashbackAccount,
  CompanyDetailsInterface, Franchise,
  InitialDataStoreInterface,
} from "../../services/exports/Interfaces";
import { REDUX_STORE_SLICES } from "../../services/exports/Constants";

export default createSlice({
  name: REDUX_STORE_SLICES.INITIAL_DATA,
  initialState: {
    franchise: null,
    company: null,
    cashback_account: null,
    ongoing_orders: {
      data: [],
    },
    loading: false,
  },
  reducers: {
    setAllData: (state, action) => {
      try {
        const {
          franchise,
          company,
          cashback_account,
          ongoing_orders,
        } = action.payload;

        state.franchise = franchise;
        state.company = company;
        state.cashback_account = cashback_account;
        state.ongoing_orders = ongoing_orders;
      } catch (error) {}
    },
    setFranchise: (state: InitialDataStoreInterface, action: PayloadAction<Franchise>) => {
      state.franchise = action.payload;
    },
    setCompany: (state: InitialDataStoreInterface, action: PayloadAction<CompanyDetailsInterface>) => {
      state.company = action.payload;
    },
    updateCompany: (state: InitialDataStoreInterface, action: PayloadAction<Partial<CompanyDetailsInterface>>) => {
      state.company = {
        ...state.company,
        ...action.payload,
      };
    },
    setOngoingOrders: (state, action) => {
      try {
        state.ongoing_orders.data = action.payload;
      } catch (error) {}
    },
    addOngoingOrder: (state, action) => {
      try {
        state.ongoing_orders.data = [action.payload];
      } catch (error) {}
    },
    setCashbackAccount: (state: InitialDataStoreInterface, action: PayloadAction<CashbackAccount>) => {
      state.cashback_account = action.payload;
    },
    toggleLoading: (state, action: PayloadAction<boolean | undefined>) => {
      state.loading =
        action.payload !== undefined ? action.payload : !state.loading;
    },
  },
});

export const props = (state: InitialDataStoreInterface) => state;
