import {useSelector} from "react-redux";
import {StoreInterface} from "../../store/types";

export default function useLoyaltyProgram() {
  const { company } = useSelector((store: StoreInterface) => store.initialData);

  const data = company?.loyalty_program;

  return {
    loyalty: data,
    hasLoyaltyPromotion: !!data,
    reward: data?.reward,
    rewardThreshold: data?.reward_threshold,
    isPerEligibleItem: data?.is_per_item,
  };
}
