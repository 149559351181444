import { useMemo, useState } from "react";
import MinusIcon from "../../assets/logo/MinusIcon";
import PlusIcon from "../../assets/logo/PlusIcon";
import useTheme from "../../hooks/ui/useTheme";
import {OpeningHoursInterface, ThemeInterface} from "../../services/exports/Interfaces";
import OrderItemNote from "../note/CustomNote";
import ReduxActions from "../../store/ReduxActions";
import { actionCreators } from "../../store/actions";
import PriceComparison from "../payment/PriceComparison";
import { collect, Collection } from "collect.js";
import { useTranslation } from "react-i18next";
import FeedbackLabel from "../labels/tinyLabels/FeedbackLabel";
import WorkScheduleHelpers from "../../services/helpers/WorkScheduleHelpers";
import i18n from "i18next";
import TextTransformationResource from "../../services/resources/TextTransformationResource";
import { CompleteBasketItemInterface } from "../../context/types";
import { useSelector } from "react-redux";
import { ProductType } from "../../services/exports/Constants";
import useBasket from "../../hooks/basket/useBasket";
import { StoreInterface } from "../../store/types";
import useAppMethods from "../../hooks/utility/useAppMethods";

interface Props {
  data: CompleteBasketItemInterface;
  index: number;
  hideDiscount?: boolean;
}

export default function OrderBasketItem(props: Props) {
  const { t } = useTranslation(null, { keyPrefix: 'Components:Basket:OrderBasketItem' });

  const { company } = useSelector((store: StoreInterface) => store.initialData);
  const { data, index, hideDiscount } = props;

  const theme: ThemeInterface = useTheme();
  const { formatScheduleUnit } = useAppMethods();
  const { incrementItem, decrementItem } = useBasket();

  const [showNote, setShowNote] = useState<boolean>(false);
  const isCombo = data.product.type === ProductType.Combo;

  function toggleNote(): void {
    setShowNote(!showNote);
  }

  function renderNoteInfo() {
    const noteTitle = () => {
      if (data.note) {
        return t("edit_note");
      }

      return t("add_note");
    };

    return (
      <div onClick={toggleNote} className="cursor-pointer mt-mini">
        <h5 className={noteTitleStyle}>{noteTitle()}</h5>
        {data.note && <p className={noteStyle}>{data.note}</p>}
      </div>
    );
  }

  function renderDescription() {
    if (isCombo) {
      return (
        <ul className="list-disc pl-small mt-tiny">
          {data.children.map((item, idx) => (
            <li
              key={`basket-item-${index}-combo-item-${idx}`}
              className="text-tiny text-brand-inkGrey-grey_4"
            >
              {item.product?.name}
              {item.modifiers?.length > 0 && (
                <> ({collect(item.modifiers).implode('modifier.name', ', ')})</>
              )}
            </li>
          ))}
        </ul>
      );
    }

    return (
      data.modifiers?.length > 0 && (
        <div className="mt-mini break-words">
          <h6
            className="text-brand-text-grey tiny"
            style={{
              marginRight:
                data.modifiers?.length - 1 ? theme.dimension.padding.mini : 0,
            }}
          >
            {collect(data.modifiers)
              .filter((modifier) => !!modifier)
              .implode('modifier.name', ' • ')}
          </h6>
        </div>
      )
    );
  }

  function renderInfo() {
    return (
      <div className="flex flex-1 flex-row">
        <h4 className="mr-small mini">{data.quantity}x</h4>
        <div>
          <h4
            className="mini break-words line-clamp-2 semibold"
            style={{
              wordBreak: "break-word",
            }}
          >
            {data.product?.name}
          </h4>
          {renderDescription()}
          {renderNoteInfo()}
        </div>
      </div>
    );
  }

  function renderActionButtons(): JSX.Element {
    return (
      <div className="flex flex-row justify-end mt-mini">
        <button
          className={actionIconContainer}
          style={{ marginRight: theme.dimension.padding.mini }}
          onClick={() => decrementItem(index)}
        >
          <MinusIcon className={actionIcon} />
        </button>
        {!data.is_free && (
          <button
            className={actionIconContainer}
            onClick={() => incrementItem(index)}
          >
            <PlusIcon
              className={actionIcon}
              color={theme.color.companyBrand.primary}
            />
          </button>
        )}
      </div>
    );
  }

  function renderRightColumn() {
    return (
      <div className="ml-mini flex flex-col">
        <PriceComparison
          originalPrice={data.original_price}
          actualPrice={data.actual_price}
          hideDiscount={hideDiscount}
        />
        {renderActionButtons()}
      </div>
    );
  }

  const renderOrderItemNote = useMemo<JSX.Element>(() => {
    function onSave(note: string): void {
      if (!data) {
        return;
      }

      company?.id &&
        ReduxActions.dispatch(
          actionCreators.basket.updateItem({
            company_id: company.id,
            index,
            item: { note: new TextTransformationResource(note).reduceToText() },
          })
        );
    }

    return showNote ? (
      <div className={orderItemContainer}>
        <OrderItemNote
          defaultNote={data.note}
          toggleNote={toggleNote}
          onSave={onSave}
        />
      </div>
    ) : null;
  }, [showNote, data, company]);

  const getSoldOutItems = (): Collection<string> => {
    if (data.product.type === ProductType.Combo) {
      const soldOut: Collection<string> = collect(data.children)
        .pluck('product')
        .where('is_available', false)
        .merge(
          collect(data.children)
            .pluck('modifiers')
            .flatten(1)
            .pluck('modifier')
            .where('is_available', false)
            .toArray()
        )
        .pluck("name");

      return soldOut.isNotEmpty() ? soldOut : collect([data.product.name]);
    }

    return (
      collect(data.modifiers)
        .pluck('modifier')
        .where('is_available', false)
        // @ts-ignore
        .when(!data.product.is_available, (items) => items.push(data.product))
        // @ts-ignore
        .pluck('name')
    );
  };

  const renderWarnings = useMemo(() => {
    if (data.is_sold_out) {
      const soldOutItems = getSoldOutItems();

      return (
        <FeedbackLabel
          message={t("feedback.item_sold_out", {
            count: soldOutItems.count(),
            items: soldOutItems.implode(", "),
          })}
          type="Notice"
          className="mt-small"
          textClassname="tiny"
        />
      );
    }

    return (
      !data.is_available && (
        <FeedbackLabel
          message={t("feedback.item_unavailable", {
            schedule: WorkScheduleHelpers.formatSchedule(
              data?.menu?.schedule ?? company?.work_schedule,
              (item: OpeningHoursInterface) => `${formatScheduleUnit(item.open)} - ${formatScheduleUnit(item.close)}`
            ),
          })}
          type="Notice"
          className="mt-small"
          textClassname="tiny"
        />
      )
    );
  }, [data, company?.work_schedule, i18n.language]);

  return (
    <>
      <div className="p-small w-full rounded-2xl border border-brand-inkGrey-grey_2">
        <div className={container}>
          {renderInfo()}
          {renderRightColumn()}
        </div>
        {renderWarnings}
      </div>
      {renderOrderItemNote}
    </>
  );
}

const container = "flex flex-row justify-between";

const noteTitleStyle = "semibold text-companyBrand-primary tiny";

const noteStyle = "text-brand-text-grey italic tiny mt-mini break-all";

const orderItemContainer = "ml-[50px] mt-small";

const actionIconContainer =
  "h-8 w-8 rounded border border-brand-inkGrey-grey_2 flex flex-col justify-center items-center";

const actionIcon = "h-3 w-3";
