export default {
  banner: {
    description:
      "Get {{range}} off when you order via the free FoodAmigos app. Click here to get the app →",
  },
  labels: {
    deals: 'Deals',
    collecting_points: 'Already collecting points?',
    earn_points: 'Earn Points & get Rewards ',
  },
  checkout_button: {
    title: "Go to Basket",
  },
};
