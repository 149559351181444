import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import Section from "./Section";
import { useMemo, useState } from "react";
import ChevronDownIcon from "../../assets/logo/ChevronDownIcon";

interface Props {
  defaultExpanded?: boolean;
  className?: string;
  title: any;
  children: any;
  onChange?: (expanded: boolean) => void;
}

export default function CollapsibleSection(props: Props) {
  const { defaultExpanded, className, title, children, onChange } = props;

  const [expanded, setExpanded] = useState<boolean>(defaultExpanded ?? false);

  function toggleExpanded(): void {
    const newExpanded: boolean = !expanded;
    setExpanded(newExpanded);
    onChange && onChange(newExpanded);
  }

  const renderTitle = useMemo(() => {
    if (typeof title === "string") {
      return <h6>{title}</h6>;
    }

    return title;
  }, [title]);

  return (
    <Section className={className}>
      <Accordion
        expanded={expanded}
        onChange={toggleExpanded}
        style={{ boxShadow: "none", backgroundColor: "transparent" }}
      >
        <AccordionSummary
          style={{
            padding: 0,
            margin: 0,
            width: "100%",
            minHeight: 0,
          }}
          classes={{
            content: "!m-none",
          }}
          expandIcon={<ChevronDownIcon className="h-3 w-3" />}
        >
          {renderTitle}
        </AccordionSummary>
        <AccordionDetails style={{ padding: 0, marginTop: 0 }}>
          {children}
        </AccordionDetails>
      </Accordion>
    </Section>
  );
}
