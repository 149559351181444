import {ORDER_METHODS, RoomServiceLocationType} from "../../../../../services/exports/Constants";

export default {
  labels: {
    today: 'Today',
  },
  [RoomServiceLocationType.RoomService]: {
    title: 'On site',
    description: {
      asap: "in {{time}} min at {{location}}",
      scheduled: "{{date}} at {{time}} at {{location}}",
    },
    details_label: 'Room number',
  },
  [RoomServiceLocationType.TableService]: {
    title: 'Table service',
    description: {
      asap: "in {{time}} min at {{location}}",
      scheduled: "{{date}} at {{time}} at {{location}}",
    },
    details_label: 'Table number',
  },
  [ORDER_METHODS.PICKUP]: {
    title: "Pickup",
    description: {
      asap: "in {{time}} min at {{location}}",
      scheduled: "{{date}} at {{time}} at {{location}}",
    },
  },
  [ORDER_METHODS.DELIVERY]: {
    title: "Delivery",
    description: {
      asap: "in {{time}} min to",
      scheduled: "{{date}} at {{time}} to",
    },
  },
};
