import {ScheduledOrderSlotInterface} from "../exports/Interfaces";
import BackendApiClient from "./BackendApiClient";
import {DataWrappedResponse} from "../exports/Types";
import {actionCreators} from "../../store/actions";
import ReduxActions from "../../store/ReduxActions";
import {ORDER_METHODS} from "../exports/Constants";

class DisabledPreOrderDatesManager {
  public get = async (
    companyId: number,
    params,
  ): Promise<DataWrappedResponse<number[]>> => {
    if (typeof companyId !== "number") {
      return {
        response: null,
        success: false,
        status: 400,
      };
    }

    return  BackendApiClient.request({
      method: 'GET',
      url: `/companies/${companyId}/disabled-pre-order-dates`,
      params,
    });
  };
}

export default new DisabledPreOrderDatesManager();
