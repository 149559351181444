import AllOrdersPaidModal from './AllOrdersPaidModal';
import ChangeTablePartyModal from './ChangeTablePartyModal';
import ExitDineInModal from './ExitDineInModal';
import OrdersDisabledModal from './OrdersDisabledModal';
import OrderSuccessfulModal from './OrderSuccessfulModal';

export default {
  AllOrdersPaidModal,
  ChangeTablePartyModal,
  ExitDineInModal,
  OrdersDisabledModal,
  OrderSuccessfulModal,
};