import useStore from "../../hooks/global/useStore";
import WorkScheduleHelpers from "../../services/helpers/WorkScheduleHelpers";
import classnames from "classnames";
import useMenu from "../../hooks/menu/useMenu";
import useAppMethods from "../../hooks/utility/useAppMethods";
import {OpeningHoursInterface} from "../../services/exports/Interfaces";

interface Props {
  className?: string;
}

export default function MenuList(props: Props): JSX.Element {
  const { className } = props;

  const { menus, selectedMenu, setSelectedMenu } = useMenu();

  const { company } = useStore()?.initialData;

  const { formatScheduleUnit } = useAppMethods();

  return menus.count() === 1 ? null : (
    <div className={classnames('flex mt-small', className)}>
      {menus.map((item, index) => (
        <div
          key={`${selectedMenu?.id}-company-menu-${item?.id}-${index}`}
          className="mr-medium group cursor-pointer"
          onClick={() => setSelectedMenu(item)}
        >
          <h6 className={menuName(item?.id === selectedMenu?.id)}>
            {item?.name}
          </h6>
          <p className={menuSchedule(item?.id === selectedMenu?.id)}>
            {WorkScheduleHelpers.formatSchedule(
              item?.schedule ?? company?.work_schedule,
              (item: OpeningHoursInterface) => `${formatScheduleUnit(item.open)} - ${formatScheduleUnit(item.close)}`
            )}
          </p>
        </div>
      ))}
    </div>
  );
}

const menuName = (isSelected?: boolean) =>
  classnames("uppercase text-mini group-hover:text-companyBrand-primary", {
    "text-companyBrand-primary": isSelected,
  });

const menuSchedule = (isSelected?: boolean) =>
  classnames("mt-tiny text-tiny text-brand-inkGrey-grey_4 group-hover:text-companyBrand-primary", {
    "!text-companyBrand-primary": isSelected,
  });