import { ProductComboInterface, ProductInterface } from "../../../services/exports/Interfaces";
import classnames from "classnames";
import useProductIdentifiers from "../../../hooks/menu/useProductIdentifiers";

interface Props {
  product: ProductInterface|ProductComboInterface;
  className?: string;
}

export default function ProductIdentifiers(props: Props) {
  const { product, className} = props;
  const { names } = useProductIdentifiers(product);

  return product?.identifiers?.length > 0 && (
    <div className={classnames('py-tiny px-xmini w-fit rounded-lg bg-background-inkDisabled-disabled_3', className)}>
      <p className="text-tiny text-brand-inkGrey-grey_4 lowercase">
        {names.implode(' · ')}
      </p>
    </div>
  )
}