import {ORDER_METHODS, RoomServiceLocationType} from "../../../../services/exports/Constants";
import {ValidationErrors} from "../../../../modals/OrderMethodModal";

export default {
  header: {
    [ORDER_METHODS.ROOM_SERVICE]: {
      [RoomServiceLocationType.RoomService]: 'Bestellung zur Zimmerservice',
      [RoomServiceLocationType.TableService]: 'Bestellung Vor Ort',
      both: 'Bestellung zur Tisch/Zimmer-service',
    },
    [ORDER_METHODS.PICKUP]: 'Bestellung zur Abholung',
    [ORDER_METHODS.DELIVERY]: 'Bestellung zur Lieferung',
  },
  order_methods: {
    [ORDER_METHODS.ROOM_SERVICE]: {
      [RoomServiceLocationType.RoomService]: 'Zimmerservice',
      [RoomServiceLocationType.TableService]: 'Vor Ort',
      both: 'Tisch/Zimmer-service',
    },
    [ORDER_METHODS.PICKUP]: 'Abholung',
    [ORDER_METHODS.DELIVERY]: 'Lieferung',
  },
  text_inputs: {
    room_service_details: {
      placeholder: {
        [RoomServiceLocationType.RoomService]: 'Zimmernummer eingeben',
        [RoomServiceLocationType.TableService]: 'Tischnummer eingeben',
      },
    },
    address: {
      placeholder: "Suche nach deiner Adresse",
    },
    street_name: {
      placeholder: "Straße",
    },
    street_number: {
      placeholder: "Str-Nummer",
    },
    city: {
      placeholder: "Stadt",
    },
    zip: {
      placeholder: "PLZ",
    },
  },

  time_options: {
    asap: {
      title: "Schnellstmöglich ",
      description: "Fertig in {{time}} min",
    },
    scheduled: {
      title: "Planen für später",
    },
  },

  time_drop_down: {
    today: "Heute",
  },

  room_service: {
    location: 'Ort auswählen',
  },

  pickup: {
    address: 'Abholadresse',
    when: "Wann soll die Bestellung bereit sein?",
  },

  delivery: {
    where: "Wohin?",
    when: "Wann soll die Bestellung bereit sein?",
    address: "Deine Adresse",
    delivery_area: 'Unser Lieferradius',
  },

  order: {
    instructions: 'Bestellanweisungen',
    placeholder: 'Bestellanweisungen eingeben...',
},

  custom_button: {
    go_to_menu: "Zum Menü",
    start_order: "Bestellung starten",
  },

  labels: {
    pickup: 'Online Abholung',
    delivery: 'Lieferung',
    pickup_time: 'Abholzeitpunkt',
    delivery_time: 'Lieferzeitpunkt',
  },

  error_messages: {
    [ValidationErrors.address_not_found]: 'Leider konnten wir diese Adresse nicht finden. Bitte versuche es erneut, indem du zuerst die Straße im Suchfeld eingibst.',
    [ValidationErrors.address_not_serviceable]: 'Deine Adresse liegt außerhalb unserer Lieferzone. Möchtest du deine Adresse ändern oder auf Abholung umstellen? 😊',
    [ValidationErrors.no_drivers_available]: 'Tut uns leid, wir können derzeit nicht liefern. Magst du zum Abholen bestellen? 😊',

    room_service_unavailable: 'Es tut uns leid, dass wir derzeit keinen Vor Ort Service anbieten.',
    pickup_unavailable: 'Es tut uns leid, dass wir Moment keine Abholung anbieten.',
    delivery_unavailable:
      'Es tut uns leid, dass wir im Moment keine Lieferung anbieten.',
    delivery_paused:
      'Es tut uns leid, dass wir im Moment keine Lieferung anbieten.',
    outside_pickup_times: 'Wir bieten keine Abholung zu dieser Zeit an. Unsere Abholzeiten:',
    outside_delivery_times: 'Wir bieten keine Lieferung zu dieser Zeit an. Unsere Lieferzeiten:',
    orders_paused_inactivity:
      "Aufgrund der überwältigenden Anfrage können wir derzeit keine weiteren Online-Bestellungen annehmen. Bitte bestelle vor Ort oder versuche es hier erneut in 30 Minuten erneut.",
    orders_paused_manual_end_of_day:
      "Tut uns leid, wir sind für den Rest des Tages geschlossen.",
    orders_paused_manual_until_time:
      "Aufgrund der überwältigenden Anfrage können wir derzeit keine weiteren Online-Bestellungen annehmen. Bitte bestelle vor Ort oder versuche es hier erneut um {{pausedUntil}}",
    company_closed: "Leider sind wir zu dieser Uhrzeit geschlossen.",
    pickup_closed: "Wir nehmen Abholbestellungen bis {{time}} an, da unsere Vorbereitungszeit {{preparationTime}} Minuten beträgt.",
    delivery_closed: "Wir nehmen Lieferbestellungen bis {{time}} an, da unsere Lieferzeit {{preparationTime}} Minuten beträgt.",
    live_orders_off:
      "Es tut uns leid, wir können derzeit keine weiteren Online-Bestellungen annehmen. Wir danken für dein Verständnis.",
    orders_unavailable: '{{type}} ist zur Zeit nicht möglich. Der nächstmogliche {{typeTime}} ist {{day}} {{time}}.'
  },
};
