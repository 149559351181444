import { useEffect, useMemo, useState, useRef, useCallback } from "react";
import useDineInFlow from "../../hooks/global/useDineInFlow";
import { useLocation } from "react-router-dom";
import { PUSHER_EVENTS, SCREENS } from "../../services/exports/Constants";
import JoinPartySection from "../../components-library/dine-in/JoinPartySection";
import PartyCard from "../../components-library/dine-in/PartyCard";
import CustomButton from "../../components-library/buttons/CustomButton";
import {ButtonTypes, PartyInterface} from "../../services/exports/Interfaces";
import { useSelector } from "react-redux";
import PartiesManager from "../../services/api/PartiesManager";
import AuthorizationManager from "../../services/auth/AuthorizationManager";
import ProfileManager from "../../services/api/ProfileManager";
import useFeedbackHandler from "../../hooks/utility/useFeedbackHandler";
import usePusher from "../../hooks/global/usePusher";
import { tableChannel } from "../../services/pusher/Channels";
import ReduxActions from "../../store/ReduxActions";
import { actionCreators } from "../../store/actions";
import TablesManager from "../../services/api/TablesManager";
import DineInNavBar from "../../components-library/navigation/DineInNavBar";
import useLoadingScreen from "../../hooks/ui/useLoadingScreen";
import useAuth from "../../hooks/global/useAuth";
import MultiGroupTableIllustration from "../../assets/illustrations/MultiGroupTableIllustration";
import MultiUserIcon from "../../assets/logo/MultiUserIcon";
import Theme from "../../components-library/Theme";
import UserIcon from "../../assets/logo/UserIcon";
import collect from "collect.js";
import { useTranslation } from "react-i18next";
import ButtonFooter from "../../components-library/footers/ButtonFooter";
import useNavigate from "../../hooks/navigation/useNavigate";
import {StoreInterface} from "../../store/types";

interface LocationState {
  prevPath?: SCREENS;
}

export default function TableScreen() {
  const { t } = useTranslation(undefined, {
    keyPrefix: "Pages:DineIn:TableScreen",
  });

  const { table, party: cachedParty } = useDineInFlow();

  const { profile } = useSelector((state: StoreInterface) => state.profile);

  const { loading, setLoading, renderLoadingPage } = useLoadingScreen();

  const { navigate } = useNavigate();
  const location = useLocation() as any;

  const locationState: LocationState = location?.state;

  const { setFeedback, resetFeedback } = useFeedbackHandler({
    renderToasts: true,
  });

  const { connect, unsubscribe } = usePusher();

  const [firstName, setFirstName] = useState(profile?.first_name ?? "");

  const [loadedTable, setLoadedTable] = useState(false);

  const [loadingProfile, setLoadingProfile] = useState(false);

  const [partyCreatedToastMessage, setPartyCreatedToastMessage] =
    useState<string>("");

  const [loadingParty, setLoadingParty] = useState(false);

  enum TABS {
    NAME_SCREEN = 0,
    GROUP_SCREEN = 1,
  }

  const { isLoggedIn } = useAuth();

  const nameFilled = !!profile?.first_name;

  const initialScreenIndex = useCallback<() => TABS>(() => {
    return !nameFilled ? TABS.NAME_SCREEN : TABS.GROUP_SCREEN;
  }, []);

  const [screenIndex, setScreenIndex] = useState<TABS>(initialScreenIndex());

  const partyCreatedToastMessageRef = useRef<string>(partyCreatedToastMessage);
  partyCreatedToastMessageRef.current = partyCreatedToastMessage;

  const tableRef = useRef(table);
  tableRef.current = table;

  useEffect(() => {
    if (!table) {
      return navigate(SCREENS.HOME_SCREEN);
    }
  }, []);

  useEffect(() => {
    if (table?.id) {
      loadTable();
      connectPusher();
    }

    return () => disconnectPusher();
  }, [table?.id]);

  async function loadTable() {
    await TablesManager.show(table?.id);
    setLoadedTable(true);
  }

  async function connectPusher() {
    const pusher = await connect();

    pusher
      .subscribe(tableChannel(table?.id))
      .bind(PUSHER_EVENTS.PARTY_CREATED, partyCreatedHandler)
      .bind(PUSHER_EVENTS.PARTY_UPDATED, partyUpdatedHandler);
  }

  function disconnectPusher() {
    if (table?.id) {
      unsubscribe(tableChannel(table?.id), PUSHER_EVENTS.PARTY_CREATED);
    }
  }

  function partyCreatedHandler(data: PartyInterface) {
    ReduxActions.dispatch(
      actionCreators.dineInFlow.setTable({
        ...tableRef.current,
        parties: collect([...tableRef.current?.parties])
          .push(data)
          .unique("id")
          .toArray(),
      })
    );
  }

  function partyUpdatedHandler(data: PartyInterface) {
    if (data?.deleted_at || data?.table_id !== tableRef.current?.id) {
      return ReduxActions.dispatch(
        actionCreators.dineInFlow.setTable({
          ...tableRef.current,
          parties: collect([...tableRef.current?.parties])
            .reject((item) => item?.id === data?.id)
            .toArray(),
        })
      );
    }

    return ReduxActions.dispatch(
      actionCreators.dineInFlow.setTable({
        ...tableRef.current,
        parties: collect([...tableRef.current?.parties])
          .push(data)
          .unique("id")
          .toArray(),
      })
    );
  }

  function goBack() {
    if (screenIndex === TABS.GROUP_SCREEN) {
      if (initialScreenIndex() === TABS.NAME_SCREEN) {
        return setScreenIndex(TABS.NAME_SCREEN);
      }
      if (locationState?.prevPath) {
        return navigate(locationState?.prevPath);
      }
      return navigate(SCREENS.BASKET);
    }

    if (locationState?.prevPath) {
      return navigate(locationState?.prevPath);
    }
    return navigate(SCREENS.BASKET);
  }

  function toggleLoading(): void {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  async function joinPartyWithLoading(party?: PartyInterface) {
    if (loadingParty) {
      return;
    }

    setLoadingParty(true);
    await joinParty(party);
    setLoadingParty(false);
  }

  async function logIn() {
    if (!firstName) {
      return;
    }

    // Log user in
    if (!isLoggedIn) {
      setLoadingProfile(true);
      await AuthorizationManager.postRegister({
        first_name: firstName,
      });
      setLoadingProfile(false);
      toggleLoading();
      setScreenIndex(TABS.GROUP_SCREEN);
      return;
    }

    // Update user info if logged in
    setLoadingProfile(true);
    await ProfileManager.putProfile({
      first_name: firstName,
    });
    setLoadingProfile(false);
    toggleLoading();
    setScreenIndex(TABS.GROUP_SCREEN);
  }

  async function joinParty(party?: PartyInterface) {
    // same party was selected
    if (cachedParty && party && cachedParty.id === party.id) {
      return successHandler();
    }

    const { success } = await leaveCurrentParty();

    if (!success) {
      return errorHandler();
    }

    return createOrJoinParty(party);
  }

  async function leaveCurrentParty() {
    if (!cachedParty?.id) {
      return { success: true };
    }

    setLoadingParty(true);
    const request = await PartiesManager.leave(cachedParty?.id);
    setLoadingParty(false);

    return request;
  }

  async function createOrJoinParty(party?: PartyInterface) {
    if (party) {
      return joinExistingParty(party);
    }

    return createParty();
  }

  async function joinExistingParty(party: PartyInterface) {
    const { success } = await PartiesManager.join(party.id);

    if (!success) {
      return errorHandler();
    }

    const members = collect(party?.users)
      ?.filter((user) => !!user.first_name)
      ?.implode("first_name", ", ");

    return successHandler({
      toastMessage: t("toasts.joined_table", { members }),
    });
  }

  async function createParty() {
    const { success } = await PartiesManager.create({
      table_id: table?.id,
    });

    if (!success) {
      return errorHandler();
    }

    successHandler(
      partyCreatedToastMessageRef.current
        ? {
            toastMessage: partyCreatedToastMessageRef.current,
          }
        : undefined
    );
  }

  function successHandler(options?: Object) {
    navigate(
      locationState?.prevPath === SCREENS.DINE_IN
        ? SCREENS.DINE_IN
        : SCREENS.CONFIRM_ORDER,
      {
        state: {
          ...options,
          prevPath:
            locationState?.prevPath === SCREENS.DINE_IN
              ? undefined
              : window.location.pathname,
        },
      }
    );
    window.scrollTo(0, 0);
  }

  function errorHandler() {
    resetFeedback();
    setFeedback({
      type: "Error",
      message: t("feedback.general_error"),
    });
  }

  const renderParties = useMemo(() => {
    if (loading) {
      return null;
    }

    if (screenIndex === TABS.NAME_SCREEN) {
      return (
        <div className="py-small">
          <JoinPartySection firstName={firstName} setFirstName={setFirstName} />
        </div>
      );
    }

    const illustrationHeight: number = window.innerHeight * 0.25;

    return (
      <div>
        <div
          className={`bg-companyBrand-primary/10 flex flex-col justify-center items-center p-small`}
          style={{ height: illustrationHeight }}
        >
          <MultiGroupTableIllustration className="w-3/4" />
        </div>
        <div className="p-small">
          <h6>{t("titles.join_party")}</h6>
          <p className="mt-tiny mini text-brand-text-grey">
            {t("text.join_party")}
          </p>
          {table?.parties &&
            table.parties.map((item) => (
              <PartyCard
                key={`party-${item?.id}`}
                party={item}
                onJoin={() => joinPartyWithLoading(item)}
                className="mt-small"
              />
            ))}
        </div>
      </div>
    );
  }, [loading, table?.parties, isLoggedIn, firstName, nameFilled, screenIndex]);

  const renderButton = useMemo(() => {
    function onPartyNotFound(): void {
      new Promise((resolve) => {
        setPartyCreatedToastMessage(t("toasts.created_party"));
        resolve(true);
      }).then(() => joinPartyWithLoading(null));
    }

    if (screenIndex === TABS.NAME_SCREEN) {
      return (
        <CustomButton
          title={t("buttons.continue_to_order")}
          disabled={!firstName}
          onClick={logIn}
          loading={loadingProfile}
          className="mx-auto"
        />
      );
    }

    return (
      <div>
        <CustomButton
          title={
            <div className={buttonTextContainer}>
              <div className={buttonIconContainer}>
                <MultiUserIcon
                  color={Theme.color.text.default}
                  className="h-5 w-5"
                />
              </div>
              <p className="mini semibold">
                {t("buttons.here_with_someone_else")}
              </p>
            </div>
          }
          onClick={onPartyNotFound}
          className="mx-auto mb-mini"
          loading={loadingParty}
          buttonType={ButtonTypes.third}
        />
        <CustomButton
          title={
            <div className={buttonTextContainer}>
              <div className={buttonIconContainer}>
                <UserIcon
                  color={Theme.color.text.light}
                  type="outline"
                  className="h-4 w-4"
                />
              </div>
              <p className="mini semibold text-brand-text-light">
                {t("buttons.here_alone")}
              </p>
            </div>
          }
          onClick={() => joinPartyWithLoading(null)}
          className="mx-auto"
          loading={loadingParty}
          buttonType={ButtonTypes.secondary}
        />
      </div>
    );
  }, [
    table?.parties,
    isLoggedIn,
    nameFilled,
    firstName,
    loadingParty,
    loadingProfile,
    partyCreatedToastMessageRef.current,
    screenIndex,
  ]);

  return (
    <>
      <header>
        <DineInNavBar
          title={t("header.table", {
            table: table?.number,
          })}
          leftClick={goBack}
          hideCloseIcon
        />
      </header>
      <main className="relative h-full">
        <div className="h-full">
          <div className="h-full w-full relative overscroll-contain">
            <div className="pb-[120px]">{renderParties}</div>
            <ButtonFooter className="fixed bottom-0">
              {renderButton}
            </ButtonFooter>
          </div>
        </div>
      </main>
      {renderLoadingPage}
    </>
  );
}

const buttonTextContainer = "flex justify-center items-center -my-tiny";

const buttonIconContainer = "mr-mini";
