import {
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
  useLayoutEffect,
} from "react";
import { SCREENS } from "../../services/exports/Constants";
import CheckoutButton from "../../components-library/buttons/CheckoutButton";
import DineInNavBar from "../../components-library/navigation/DineInNavBar";
import PointsEarnedModal from "../../modals/PointsEarnedModal";
import useStore from "../../hooks/global/useStore";
import ReduxActions from "../../store/ReduxActions";
import { actionCreators } from "../../store/actions";
import useAuth from "../../hooks/global/useAuth";
import useLoyaltyProgram from "../../hooks/loyalty/useLoyaltyProgram";
import { useTranslation } from "react-i18next";
import useInitialData from "../../hooks/global/useInitialData";
import useDineInFlow from "../../hooks/global/useDineInFlow";
import LoadingPage from "../../components-library/loading/LoadingPage";
import UserIcon from "../../assets/logo/UserIcon";
import useTheme from "../../hooks/ui/useTheme";
import LoyaltyProgress from "../../components-library/progress/LoyaltyProgress";
import useNavigate from "../../hooks/navigation/useNavigate";
import MenuCategoryList from "../../components-library/menu/MenuCategoryList";
import { OrderItemInterface } from "../../services/exports/Interfaces";
import OrderManager from "../../services/api/OrderManager";
import collect from "collect.js";
import OrderCarousel from "../../components-library/carousel/OrderCarousel";
import PageLayout from "../../components-library/layouts/PageLayout";
import useBasket from "../../hooks/basket/useBasket";
import useAppMethods from "../../hooks/utility/useAppMethods";

const MenuScreen = (): JSX.Element => {
  const { t } = useTranslation(null, { keyPrefix: "Pages:DineIn:MenuScreen" });

  const { refresh, loading } = useInitialData();
  const theme = useTheme();
  const { quantity } = useBasket();
  const { toggleLoginModal } = useAppMethods();
  const { navigate } = useNavigate();
  const { isLoggedIn } = useAuth();
  const { hasLoyaltyPromotion } = useLoyaltyProgram();
  const { loadingTable } = useDineInFlow({
    autoLoadTable: true,
  });

  const { rewardInfoModalSeen } = useStore()?.dineInFlow;

  const [showLoadingPage, setShowLoadingPage] = useState<boolean>(true);

  const [lastOrder, setLastOrder] = useState<OrderItemInterface | null>(null);

  const headerRef = useRef(null);

  const isLoggedInRef = useRef(isLoggedIn);
  isLoggedInRef.current = isLoggedIn;

  useEffect(() => {
    init();
  }, []);

  useLayoutEffect(() => {
    if (!loadingTable && !loading) {
      setTimeout(() => setShowLoadingPage(false), 500);
    }
  }, [loadingTable, loading]);

  async function init() {
    toggleDineInStatus();
    requestLastOrder();
  }

  async function requestLastOrder(): Promise<void> {
    if (isLoggedInRef.current) {
      const { response, success } = await OrderManager.getOrderHistory({
        limit: 1,
      });
      if (success) {
        setLastOrder(collect(response?.data?.data)?.first());
      }
    }
  }

  async function reload(): Promise<void> {
    refresh();
    requestLastOrder();
  }

  function goBack(): void {
    return navigate(SCREENS.DINE_IN);
  }

  function toggleDineInStatus(): void {
    ReduxActions.dispatch(actionCreators.dineInFlow.setDineInStatus(true));
  }

  const renderHeader = useMemo<JSX.Element>(() => {
    return (
      <header className="w-full" ref={headerRef}>
        <DineInNavBar
          leftClick={goBack}
          customRightAction={
            <div
              className={
                "absolute w-[200px] flex items-center justify-end z-10"
              }
            >
              {!isLoggedIn ? (
                <button
                  className={"flex items-center justify-end"}
                  onClick={() =>
                    toggleLoginModal({
                      openModal: true,
                      onLoginSuccess: reload,
                      onCloseRedirect: () => null,
                    })
                  }
                >
                  <p className="mini semibold text-companyBrand-primary">
                    {t("login_for_points")}
                  </p>
                  <div className="w-[32px] h-[32px] ml-mini bg-companyBrand-primary flex items-center justify-center rounded-full shadow-icon">
                    <UserIcon color={theme.color.text.light} type={"outline"} />
                  </div>
                </button>
              ) : (
                <LoyaltyProgress />
              )}
            </div>
          }
        />
      </header>
    );
  }, [isLoggedIn]);

  const renderFooter = useMemo(
    () =>
      quantity > 0 && (
        <footer className="fixed bottom-0 flex flex-col w-full bg-background-inkWhite-white_0 z-50">
          <CheckoutButton
            title={t("buttons.view_order")}
            onClick={() => navigate(SCREENS.BASKET)}
          />
        </footer>
      ),
    [quantity]
  );

  const renderPointsEarnedModal = useMemo<JSX.Element>(() => {
    return (
      <PointsEarnedModal
        open={!rewardInfoModalSeen && !isLoggedIn && hasLoyaltyPromotion}
        buttonTitle={t("buttons.continue_to_menu")}
        type={"welcome_to_loyalty_program"}
        toggleModal={() =>
          ReduxActions.dispatch(
            actionCreators.dineInFlow.setRewardInfoModalSeen(true)
          )
        }
      />
    );
  }, [rewardInfoModalSeen, isLoggedIn, hasLoyaltyPromotion]);

  const renderLastOrder = (): JSX.Element => {
    return (
      lastOrder && (
        <>
          <div className="w-full">
            <OrderCarousel order={lastOrder} />
          </div>
        </>
      )
    );
  };

  const renderLoadingPage = useMemo<JSX.Element>(() => {
    return loadingTable || loading || showLoadingPage ? <LoadingPage /> : null;
  }, [loadingTable, loading, showLoadingPage]);

  return (
    <PageLayout header={renderHeader}>
      <>
        <main className="relative w-full h-full pt-small">
          <MenuCategoryList
            className="w-full relative h-screen overscroll-contain"
            categoryListClassName="pb-[200px]"
            LastOrderComponent={renderLastOrder()}
          />
          {renderFooter}
        </main>
        {renderPointsEarnedModal}
        {renderLoadingPage}
      </>
    </PageLayout>
  );
};

export default MenuScreen;
