import {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {useSelector} from "react-redux";
import CheckmarkButton from "../../../components-library/buttons/CheckmarkButton";
import CustomButton from "../../../components-library/buttons/CustomButton";
import FeedbackLabel from "../../../components-library/labels/tinyLabels/FeedbackLabel";
import ProfileManager from "../../../services/api/ProfileManager";
import AuthorizationManager from "../../../services/auth/AuthorizationManager";
import {
  ButtonTypes,
  FeedbackLabelInterface,
  OneTimePasswordInterface,
  ProfileInterface,
  ThemeInterface,
} from "../../../services/exports/Interfaces";
import PhoneNumberInput, {PhoneNumberInputProps,} from "../../../components-library/PhoneNumberInput";
import useTheme from "../../../hooks/ui/useTheme";
import useAlert from "../../../hooks/utility/useAlert";
import {ENVIRONMENTS, OTP_DELIVERY_CHANNELS, SCREENS} from "../../../services/exports/Constants";
import useDineInFlow from "../../../hooks/global/useDineInFlow";
import CurrentLocationManager from "../../../services/api/CurrentLocationManager";
import {Trans, useTranslation} from "react-i18next";
import useInitialData from "../../../hooks/global/useInitialData";
import {Link} from "react-router-dom";
import {StoreInterface} from "../../../store/types";
import useNavigate from "../../../hooks/navigation/useNavigate";
import OtpInput from 'react-otp-input';
import Countdown, { zeroPad } from 'react-countdown';
import moment from "moment";
import toast from "react-hot-toast";
import ChatIcon from "../../../assets/logo/ChatIcon";

interface Props {
  hideContinueButton?: boolean;
  phoneNumberInputProps?: Partial<PhoneNumberInputProps>;
  onLoginSucceed: () => void;
  toggleLoading?: (loading: boolean) => void;
  toggleDisabledStatus?: (disabled: boolean) => void;
  onCodeRequested?: () => void;
  onProceedWithoutLogin?: () => void;
}

const CODE_LENGTH = 4;

const PhoneNumberStep = forwardRef((props: Props, ref: any) => {
  const { t, i18n } = useTranslation(null, { keyPrefix: 'Components:Checkout:Stepper:PhoneNumberStep' });

  const {
    hideContinueButton,
    phoneNumberInputProps,
    toggleLoading,
    toggleDisabledStatus,
    onCodeRequested,
    onLoginSucceed,
    onProceedWithoutLogin,
  } = props;

  const { refresh } = useInitialData();
  const theme: ThemeInterface = useTheme();
  const alert = useAlert();
  const { generatePath } = useNavigate();
  const { isDineIn, party } = useDineInFlow();

  const { profile } = useSelector((state: StoreInterface) => state.profile);
  const { cached_order } = useSelector((state: StoreInterface) => state.order);
  const { company } = useSelector((state: StoreInterface) => state.initialData);

  const [phoneNumber, setPhoneNumber] = useState(profile?.phone_number ?? "");
  const [country, setCountry] = useState(null);
  const [code, setCode] = useState("");
  const [otp, setOtp] = useState<OneTimePasswordInterface>(null);

  const [hasEmailDeliveryChannel, setHasEmailDeliveryChannel] = useState(false);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [codeRequested, setCodeRequested] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resending, setResending] = useState(false);
  const [resendingViaChannel, setResendingViaChannel] = useState(null);
  const [retrySmsAfter, setRetrySmsAfter] = useState(null);
  const [feedback, setFeedback] = useState<FeedbackLabelInterface>({
    message: "",
  });

  useEffect(() => {
    setRetrySmsAfter(60);
  }, [codeRequested]);

  useEffect(() => {
    if (!profile?.phone_number) {
      fetchCurrentLocation();
    }
  }, []);

  useEffect(() => {
    setPhoneNumber(profile?.phone_number ?? "");
  }, [profile?.phone_number]);

  useEffect(() => {
    toggleLoading && toggleLoading(loading);
  }, [loading]);

  useEffect(() => {
    toggleDisabledStatus && toggleDisabledStatus(validateInput());
  }, [codeRequested, termsAgreed, code, phoneNumber]);

  useImperativeHandle(ref, () => ({
    onPress,
  }));

  async function fetchCurrentLocation() {
    if (country) {
      return;
    }

    const { success, response } = await CurrentLocationManager.get();

    if (!success) {
      return setCountry(company?.country ?? "DE");
    }

    return setCountry(response?.data?.country_code);
  }

  function onPress(): Promise<void> | void {
    if (phoneNumber === profile?.phone_number) {
      return onProceedWithoutLogin && onProceedWithoutLogin();
    }

    if (!codeRequested) {
      return requestCode(undefined, true);
    }

    requestLogin();
  }

  function validateInput(): boolean {
    if (codeRequested) {
      return (
        !termsAgreed || code?.length < CODE_LENGTH || phoneNumber?.length < 10
      );
    }

    return phoneNumber?.length < 10;
  }

  async function requestCode(delivery_channel = undefined, withLoading = false) {
    setResendingViaChannel(delivery_channel);
    withLoading && setLoading(true);
    setResending(true);
    const { response, success } = await AuthorizationManager.postOptCode({
      company_id: company?.id,
      phone_number: phoneNumber,
      lang: i18n.language,
      delivery_channel,
      country,
    });
    setResending(false);
    withLoading && setLoading(false);

    if (success) {
      setCodeRequested(true);
      setOtp(response.data.data);
      !hasEmailDeliveryChannel && setHasEmailDeliveryChannel(response.data.data.delivery_channel === OTP_DELIVERY_CHANNELS.EMAIL);
      response?.headers['retry-after'] && setRetrySmsAfter(response?.headers['retry-after']);

      if (import.meta.env.VITE_MODE !== ENVIRONMENTS.PROD) {
        setCode(response?.data?.data?.password);
      }

      resetErrorMessage();
      toast.success(t('toasts.code_sent'));
      setTimeout(() => onCodeRequested && onCodeRequested());

      return;
    }

    requestCodeErrorHandler(response);
  }

  function requestCodeErrorHandler(error: any) {
    const status = error?.response?.status;
    switch (status) {
      case 429:
        setRetrySmsAfter(error?.response?.headers['retry-after'] ?? null);
        setFeedback({
          type: "Error",
          message: t("error_messages.frequent_requests"),
        });
        break;
      default:
        setFeedback({
          type: "Error",
          message: t("error_messages.invalid_number"),
        });
        break;
    }

    setTimeout(() => onCodeRequested && onCodeRequested());
  }

  async function requestLogin() {
    setLoading(true);
    const successLogout = await AuthorizationManager.logout();

    if (!successLogout) {
      return alert.error({
        title: t("error_messages.change_phone_number_unsuccessful"),
      });
    }

    const { response, success } = await AuthorizationManager.postLogin({
      id: profile?.is_draft ? profile?.id : null,
      phone_number: phoneNumber,
      password: code,
      party_id: isDineIn ? party?.id : null,
    });

    if (success) {
      resetErrorMessage();
      requestInitialData().then(updateProfile);

      return;
    }

    loginErrorHandler(response);
  }

  function loginErrorHandler(error: any) {
    setLoading(false);
    setFeedback({
      type: "Error",
      message: t("error_messages.invalid_code"),
    });
  }

  function resetErrorMessage() {
    setFeedback({
      message: "",
    });
  }

  function computeProfileData(): ProfileInterface {
    return {
      delivery_street_name: cached_order?.delivery_street_name ?? undefined,
      delivery_street_number: cached_order?.delivery_street_number ?? undefined,
      delivery_city: cached_order?.delivery_city ?? undefined,
      delivery_state: cached_order?.delivery_state ?? undefined,
      delivery_country: cached_order?.delivery_country ?? undefined,
      delivery_zip_code: cached_order?.delivery_zip_code ?? undefined,
      delivery_lat: cached_order?.delivery_lat ?? undefined,
      delivery_lng: cached_order?.delivery_lng ?? undefined,
      delivery_company_name: cached_order?.delivery_company_name ?? undefined,
      delivery_doorbell_name: cached_order?.delivery_doorbell_name ?? undefined,
      customer_note: cached_order?.customer_note ?? undefined,
    };
  }

  async function requestInitialData() {
    setLoading(true);
    await refresh();
    setLoading(false);
  }

  async function updateProfile() {
    await ProfileManager.putProfile(computeProfileData());
    setLoading(false);

    if (onLoginSucceed) {
      return onLoginSucceed();
    }
  }

  function renderFeedbackLabel() {
    return feedback.message ? (
      <div className="mb-small">
        <FeedbackLabel type={feedback?.type} message={feedback.message} />
      </div>
    ) : undefined;
  }

  function renderPhoneNumberInput() {
    return (
      <div className="mt-small">
        <PhoneNumberInput
          placeholder={t("phone_number_input.placeholder")}
          value={phoneNumber}
          onChange={setPhoneNumber}
          onChangeCountry={setCountry}
          country={country}
          {...phoneNumberInputProps}
        />
      </div>
    );
  }

  function renderResendOptions() {
    return (
      <div>
        <h6 className="mt-small">{t(`otp.resend.options.${otp?.delivery_channel}.title`)}</h6>
        <CustomButton
          title={(
            <>
              {retrySmsAfter > 0 ? (
                <Countdown
                  date={moment().add(retrySmsAfter, 'seconds').toDate()}
                  renderer={({minutes, seconds}) => <span
                    className="mr-mini my-auto">({zeroPad(minutes)}:{zeroPad(seconds)})</span>}
                  onTick={() => setRetrySmsAfter(current => current - 1)}
                  onComplete={() => setRetrySmsAfter(null)}
                />
              ) : null}
              <ChatIcon className="h-6 w-6 mr-mini my-auto" />
              {t(`otp.resend.options.${OTP_DELIVERY_CHANNELS.SMS}.button`)}
            </>
          )}
          onClick={() => requestCode(OTP_DELIVERY_CHANNELS.SMS)}
          buttonType={ButtonTypes.sixth}
          className="mt-small !w-fit items-center"
          titleClass="flex"
          disabled={!!retrySmsAfter}
          loading={resending && resendingViaChannel === OTP_DELIVERY_CHANNELS.SMS}
        />
      </div>
    );
  }

  function renderAcceptTerms() {
    return (
      <div
        className="mt-small flex flex-row cursor-pointer select-none"
        onClick={() => setTermsAgreed(!termsAgreed)}
      >
        <div className="mr-mini">
          <CheckmarkButton
            testId="terms-accepted-checkbox"
            color={theme.color.brand.secondary}
            active={termsAgreed}
            className="my-auto"
          />
        </div>
        <h5 className="tiny my-auto">
          <Trans
            values={{
              terms: t("terms_and_conditions.terms"),
              conditions: t("terms_and_conditions.conditions"),
            }}
            components={{
              TermsLink: (
                <Link
                  to={generatePath(SCREENS.TERMS_AND_CONDITIONS)}
                  target="_blank"
                  className={linkStyle}
                  relative="path"
                />
              ),
              ConditionsLink: (
                <Link
                  to={generatePath(SCREENS.PRIVACY_POLICY)}
                  target="_blank"
                  className={linkStyle}
                />
              ),
            }}
            t={t}
          >
            terms_and_conditions.accept_terms
          </Trans>
        </h5>
      </div>
    );
  }

  function renderOtp() {
    return codeRequested ? (
      <div className="mt-small">
        <div className="mt-small">
          {otp && (
            <div className="mb-small">
              <p>
                <Trans
                  values={{
                    address: otp.delivery_address,
                  }}
                  t={t}
                >
                  otp.text.{otp.delivery_channel}
                </Trans>
              </p>
              <p className="text-tiny mt-small">{t(`otp.tip.${otp.delivery_channel}`)}</p>
            </div>
          )}
          <OtpInput
            value={code}
            onChange={(value) => setCode(value)}
            numInputs={4}
            shouldAutoFocus
            renderInput={(props) => (
              <input
                {...props}
                inputMode="numeric"
                className="bg-brand-inkGrey-grey_5 mr-mini h-10 !w-10 rounded-md"
              />
            )}
          />
          {renderResendOptions()}
          {renderAcceptTerms()}
        </div>
      </div>
    ) : null;
  }

  function renderContinueButton() {
    return (
      <div className="mt-small">
        {renderFeedbackLabel()}
        {!hideContinueButton && (
          <CustomButton
            testId="phone-number-continue-button"
            title={t("custom_button.continue")}
            buttonType={ButtonTypes.secondary}
            loading={loading}
            disabled={validateInput()}
            onClick={onPress}
          />
        )}
      </div>
    );
  }

  return (
    <div className="py-2" data-test={"phone-number-step"}>
      {renderPhoneNumberInput()}
      {renderOtp()}
      {renderContinueButton()}
    </div>
  );
});

export default PhoneNumberStep;

const linkStyle =
  "text-companyBrand-primary hover:underline decoration-companyBrand-primary decoration-solid underline-offset-4 inline";
