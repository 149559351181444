import OngoingOrder from './OngoingOrder';
import OrderTracker from "./OrderTracker";
import OrderTimeline from "./OrderTimeline";
import ScheduledOrderInfo from "./ScheduledOrderInfo";

export default {
  OngoingOrder,
  OrderTracker,
  OrderTimeline,
  ScheduledOrderInfo,
};