import Lottie from 'react-lottie-player';
import LoadingAnimationDots from "../../assets/animations/LoadingAnimationDots.json";

export default function LoadingIndicator({
  src = LoadingAnimationDots,
  style = {},
  className = null,
}): JSX.Element {

  return (
    <Lottie
      animationData={src}
      play
      loop
      style={style}
      className={className}
      rendererSettings={{
        preserveAspectRatio: 'xMidYMid slice',
      }}
    />
  );
}
