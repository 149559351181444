export default {
  header: {
    table: "Tisch {{table}}",
  },
  titles: {
    join_party: "Finde die Personen, mit denen du hier bist",
  },
  text: {
    join_party:
      "Wenn du dich einer Gruppe anschließt, kannst du gemeinsam bezahlen und die Rechnung untereinander aufteilen.",
  },
  toasts: {
    joined_table: "Du bist beigetreten {{members}}",
    created_party:
      "Super! Deine Freunde können deiner Gruppe beitreten und ihr könnt gemeinsam bestellen und bezahlen",
  },
  feedback: {
    general_error: "Ein Fehler ist aufgetreten, bitte versuche es erneut",
  },
  buttons: {
    continue_to_order: "Weiter zur Bestellung",
    here_with_someone_else: "Ich Bin Mit Jemand Anderem Hier 🤝",
    here_alone: "Ich Bin Allein Hier 👋",
  },
};
