import SuccessModal from "../SuccessModal";
import {useTranslation} from "react-i18next";

interface Props {
  open: boolean;
  onConfirm: () => void;
}

export default function OrderSuccessfulModal(props: Props) {
  const {t} = useTranslation(null, {keyPrefix: 'Modals:DineIn:OrderSuccessfulModal'});

  const { open, onConfirm } = props;

  return (
    <SuccessModal
      open={open}
      buttonTitle={t('buttons.okay')}
      onConfirm={onConfirm}
      lottieProps={{
        segments: [0, 50],
      }}
    >
      <h6>{t('title')}</h6>
      <p className="mini text-brand-text-grey mt-mini">{t('description')}</p>
    </SuccessModal>
  );
}
