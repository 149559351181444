import useTheme from "../../hooks/ui/useTheme";
import { ThemeInterface } from "../../services/exports/Interfaces";

interface Props {
  className?: string;
  style?: Object;
  color?: string;
}

export default function LabelIcon({ className, color, style }: Props) {
  const theme: ThemeInterface = useTheme();
  return (
    <svg
      className={className ?? "h-3 w-3"}
      viewBox="0 0 14 15"
      fill="none"
      style={style}
    >
      <path
        d="M7.33281 12.9807C7.09948 13.2141 6.8224 13.3307 6.50156 13.3307C6.18073 13.3307 5.90365 13.2141 5.67031 12.9807L1.49948 8.8099C1.27587 8.58628 1.16406 8.31163 1.16406 7.98594C1.16406 7.66024 1.27587 7.38559 1.49948 7.16198L6.63281 2.01406C6.73976 1.90712 6.86615 1.82205 7.01198 1.75885C7.15781 1.69566 7.31337 1.66406 7.47865 1.66406H11.6495C11.9703 1.66406 12.245 1.7783 12.4734 2.00677C12.7019 2.23524 12.8161 2.5099 12.8161 2.83073V7.00156C12.8161 7.16684 12.7845 7.3224 12.7214 7.46823C12.6582 7.61406 12.5731 7.74045 12.4661 7.8474L7.33281 12.9807ZM10.1911 5.16406C10.4342 5.16406 10.6408 5.07899 10.8109 4.90885C10.9811 4.73872 11.0661 4.53212 11.0661 4.28906C11.0661 4.04601 10.9811 3.83941 10.8109 3.66927C10.6408 3.49913 10.4342 3.41406 10.1911 3.41406C9.94809 3.41406 9.74149 3.49913 9.57135 3.66927C9.40122 3.83941 9.31615 4.04601 9.31615 4.28906C9.31615 4.53212 9.40122 4.73872 9.57135 4.90885C9.74149 5.07899 9.94809 5.16406 10.1911 5.16406ZM6.50156 12.1641L11.6495 7.00156V2.83073H7.47865L2.33073 7.99323L6.50156 12.1641Z"
        fill={color ?? '#000000'}
      />
    </svg>
  );
}
