import React from "react";
import CheckmarkIcon from "../../assets/logo/CheckmarkIcon";
import useTheme from "../../hooks/ui/useTheme";
import {
  ThemeInterface,
  RewardInterface,
} from "../../services/exports/Interfaces";
import {useTranslation} from "react-i18next";

interface Props {
  points?: number | null;
  reward?: RewardInterface | null;
  activeReward?: RewardInterface | null;
}

export default function DiscountCircleLabel(props: Props) {
  const {t} = useTranslation(null, {keyPrefix: 'Components:Rewards:DiscountCircleLabel'});

  const { points, reward, activeReward } = props;

  const theme: ThemeInterface = useTheme();
  const isUsed = getUsedStatus();
  const isActive = activeReward?.cost === reward?.cost;

  function getUsedStatus() {
    try {
      if (!reward) {
        return false;
      }

      return reward?.cost <= (points ?? 0) && !reward?.is_available;
    } catch (error) {
      return false;
    }
  }

  function getPoints() {
    return t('point', { count: reward?.cost });
  }

  function renderCircle() {
    function getDiscountPercentageTitle() {
      if (reward?.discount === 100) {
        return t('free');
      }

      return `${reward?.discount}%`;
    }

    function getBackgroundColor() {
      if (isUsed || isActive) {
        return theme.color.companyBrand.primary;
      }

      return 'transparent';
    }

    function getTextColor() {
      if (isUsed || isActive) {
        return theme.color.text.light;
      }

      return theme.color.companyBrand.primary;
    }

    return (
      <div
        className={circleContainer}
        style={{
          borderColor: theme.color.companyBrand.primary,
          backgroundColor: getBackgroundColor(),
        }}
      >
        <h6 className={circleLabelStyle} style={{ color: getTextColor() }}>
          {getDiscountPercentageTitle()}
        </h6>
      </div>
    );
  }

  return (
    <div className={container} style={{ opacity: isUsed ? 0.5 : 1 }}>
      {renderCircle()}
      <div className={pointsRow}>
        <p className={pointsStyle}>{getPoints()}</p>
        {reward?.is_available && !isUsed && (
          <div
            className={checkmarkContainer}
            style={{ backgroundColor: theme.color.companyBrand.primary }}
          >
            <CheckmarkIcon color={theme.color.text.light} className="h-2 w-2" />
          </div>
        )}
      </div>
    </div>
  );
}

const container = "flex flex-col items-center";

const pointsRow = "flex flex-row items-center mt-mini";

const checkmarkContainer =
  "w-4 h-4 rounded-full flex justify-center items-center ml-tiny";

const circleContainer =
  "w-[56px] h-[54px] border rounded-full flex justify-center items-center";

const circleLabelStyle = "mini semibold";

const pointsStyle = "mini";
