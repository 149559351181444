import BackendApiClient from "./BackendApiClient";
import { DataWrappedResponse, DataWrapper } from "../exports/Types";
import {
  GoogleReviewStatusInterface,
  PromotionCodeResponseInterface,
} from "../exports/Interfaces";

export default class PromotionManager {
  public getPromoCodes = async (companyId: number, promo?: string): Promise<DataWrappedResponse<PromotionCodeResponseInterface>> =>
    BackendApiClient.request({
      method: "GET",
      url: `/companies/${companyId}/promo-codes`,
      params: {
        promo: promo ?? null,
      },
    });

  public async getGoogleReviewStatus(companyId: number): Promise<DataWrappedResponse<DataWrapper<GoogleReviewStatusInterface>>> {
    if (typeof companyId !== "number") {
      return {
        response: null,
        success: false,
        status: 400,
      };
    }

    return BackendApiClient.requestAuthorized({
      method: "GET",
      url: `/companies/${companyId}/last-google-review`,
    });
  }

  public async uploadGoogleReview(
    companyId: number,
    data: FormData,
    onUploadProgress: (progressPercentage: number) => void
  ) {
    return BackendApiClient.requestAuthorized({
      method: "POST",
      url: `/companies/${companyId}/google-reviews`,
      data,
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        onUploadProgress(percentCompleted);
      },
    });
  }
}
