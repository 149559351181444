import {
  DeliveryProvider,
  ORDER_FULFILLMENT_STATUSES,
  ORDER_METHODS, ORDER_REJECTED_REASON
} from "../../../../../services/exports/Constants";

export default {
  labels: {
    today: 'Today',
  },
  order_status: {
    [ORDER_METHODS.ROOM_SERVICE]: {
      [ORDER_FULFILLMENT_STATUSES.REJECTED]: '❌ Order canceled',
      [ORDER_FULFILLMENT_STATUSES.CREATED]: 'Estimated time:',
      [ORDER_FULFILLMENT_STATUSES.REVIEWED]: 'Estimated time:',
      [ORDER_FULFILLMENT_STATUSES.ACCEPTED]: '✅ Confirmed time:',
      [ORDER_FULFILLMENT_STATUSES.PREPARING]: '✅ Confirmed time:',
      [ORDER_FULFILLMENT_STATUSES.READY]: '👍 Order is ready',
      [ORDER_FULFILLMENT_STATUSES.ON_DELIVERY]: '🚴🏻 Order is being delivered',
      [ORDER_FULFILLMENT_STATUSES.PICKED]: '😉 Order is complete',
    },
    [ORDER_METHODS.PICKUP]: {
      [ORDER_FULFILLMENT_STATUSES.REJECTED]: '❌ Order canceled',
      [ORDER_FULFILLMENT_STATUSES.CREATED]: 'Estimated pickup time:',
      [ORDER_FULFILLMENT_STATUSES.REVIEWED]: 'Estimated pickup time:',
      [ORDER_FULFILLMENT_STATUSES.ACCEPTED]: '✅ Confirmed pickup time:',
      [ORDER_FULFILLMENT_STATUSES.PREPARING]: '✅ Confirmed pickup time:',
      [ORDER_FULFILLMENT_STATUSES.READY]: '👍 Order is ready',
      [ORDER_FULFILLMENT_STATUSES.ON_DELIVERY]: '🚴🏻 Order is being delivered',
      [ORDER_FULFILLMENT_STATUSES.PICKED]: '😉 Order is complete',
    },
    [ORDER_METHODS.DELIVERY]: {
      [DeliveryProvider.Standalone]: {
        [ORDER_FULFILLMENT_STATUSES.REJECTED]: '❌ Order canceled',
        [ORDER_FULFILLMENT_STATUSES.CREATED]: 'Estimated delivery time:',
        [ORDER_FULFILLMENT_STATUSES.REVIEWED]: 'Estimated delivery time:',
        [ORDER_FULFILLMENT_STATUSES.ACCEPTED]: '✅ Confirmed delivery time:',
        [ORDER_FULFILLMENT_STATUSES.PREPARING]: '✅ Confirmed delivery time:',
        [ORDER_FULFILLMENT_STATUSES.READY]: '👍 Order is ready',
        [ORDER_FULFILLMENT_STATUSES.ON_DELIVERY]: '🚴🏻 Order is being delivered',
        [ORDER_FULFILLMENT_STATUSES.PICKED]: '😉 Order is complete',
      },
      [DeliveryProvider.FirstDelivery]: {
        [ORDER_FULFILLMENT_STATUSES.REJECTED]: '❌ Order canceled',
        [ORDER_FULFILLMENT_STATUSES.CREATED]: 'Delivery: Waiting for order confirmation',
        [ORDER_FULFILLMENT_STATUSES.REVIEWED]: 'Delivery: Waiting for order confirmation',
        [ORDER_FULFILLMENT_STATUSES.ACCEPTED]: '✅ Delivery confirmed',
        [ORDER_FULFILLMENT_STATUSES.PREPARING]: '✅ Delivery confirmed',
        [ORDER_FULFILLMENT_STATUSES.READY]: '✅ Delivery confirmed',
        [ORDER_FULFILLMENT_STATUSES.ON_DELIVERY]: '🚴🏻 Order is being delivered',
        [ORDER_FULFILLMENT_STATUSES.PICKED]: '😉 Order is complete',
      },
    },
  },
  time_label: {
    [DeliveryProvider.FirstDelivery]: {
      [ORDER_FULFILLMENT_STATUSES.CREATED]: 'Estimated time:',
      [ORDER_FULFILLMENT_STATUSES.REVIEWED]: 'Estimated time:',
      [ORDER_FULFILLMENT_STATUSES.ACCEPTED]: 'Confirmed time:',
      [ORDER_FULFILLMENT_STATUSES.PREPARING]: 'Confirmed time:',
      [ORDER_FULFILLMENT_STATUSES.READY]: 'Confirmed time:',
      [ORDER_FULFILLMENT_STATUSES.ON_DELIVERY]: 'Confirmed time:',
    },
  },
  warning_label: {
    pickup_time_changed: "{{company}} changed the time from {{initialTime}} to {{currentTime}}",
    order_rejected_reason: {
      [ORDER_REJECTED_REASON.AUTOMATIC]:
        "Unfortunately, we are currently facing overwhelming demand and can't prepare your order in time. We already sent your money back and you should receive it within 4 working days at the latest. If you do not receive the money within 4 days, please write to our digital partner {{provider}} at {{supportEmail}}. We apologize for this and would be happy if you try to order again in an 1 hour  in case you can wait. If you have any questions, please call the restaurant.",
      [ORDER_REJECTED_REASON.CLOSING_SOON]:
        "Unfortunately, we don’t have time to make your order before we close today. We already sent your money back and you should receive it within 4 working days at the latest. If you do not receive the money within 4 days, please write to our digital partner {{provider}} at {{supportEmail}}. If you have any questions, please call the restaurant.",
      [ORDER_REJECTED_REASON.ITEM_UNAVAILABLE]:
        "Unfortunately, we cannot currently offer the item(s) you ordered. We already sent your money back and you should receive it within 4 working days at the latest. If you do not receive the money within 4 days, please write to our digital partner {{provider}} at {{supportEmail}}. If you have any questions, please call the restaurant.",
      [ORDER_REJECTED_REASON.ORDER_TOO_BIG]:
        "Unfortunately, we cannot prepare your order in time. If it’s okay, please try again with smaller order(s). We already sent your money back and you should receive it within 4 working days at the latest. If you do not receive the money within 4 days, please write to our digital partner {{provider}} at {{supportEmail}}. If you have any questions, please call the restaurant.",
      [ORDER_REJECTED_REASON.CUSTOMER_MISTAKE]: '',
      [ORDER_REJECTED_REASON.OTHER]: '',
    },
  },
}