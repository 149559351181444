import { useEffect } from "react";
import RewardIcon from "../assets/logo/RewardIcon";
import CustomButton from "../components-library/buttons/CustomButton";
import ProductsCarousel from "../components-library/carousel/ProductsCarousel";
import CustomModal from "../components-library/CustomModal";
import ButtonFooter from "../components-library/footers/ButtonFooter";
import useGoogleReviewSuggestedProducts from "../hooks/google-review/useGoogleReviewSuggestedProducts";
import { MODAL_SIZES } from "../services/exports/Constants";
import { useTranslation } from "react-i18next";
import useRewards from "../hooks/loyalty/useRewards";
import useAppState from "../hooks/global/useAppState";
import useAppMethods from "../hooks/utility/useAppMethods";

const FreeProductsModal = (): JSX.Element => {
  const { t } = useTranslation(null, { keyPrefix: "Modals:FreeProductsModal" });

  const { maxReward } = useRewards();

  const { suggestedProducts, loading, findSuggestedProducts } =
    useGoogleReviewSuggestedProducts();

  const { openModal } = useAppState()?.freeProductsModalProps;

  const { toggleFreeProductsModal } = useAppMethods();

  useEffect(() => {
    if (openModal) {
      onDidFocus();
    }
  }, [openModal]);

  function onDidFocus(): void {
    findSuggestedProducts();
  }

  function onClose(): void {
    toggleFreeProductsModal({ openModal: false });
  }

  return (
    <CustomModal
      open={openModal}
      size={MODAL_SIZES.SM}
      loading={loading}
      onClose={onClose}
    >
      <div className="bg-background-inkWhite-white_1 p-small">
        <div className="flex items-center justify-center mb-small">
          <RewardIcon fill />
          <h6 className="ml-tiny">
            {t("free_items", { count: maxReward?.cost })}
          </h6>
        </div>
        <ProductsCarousel products={suggestedProducts} />
      </div>
      <ButtonFooter>
        <CustomButton title={t("button.okay")} onClick={onClose} />
      </ButtonFooter>
    </CustomModal>
  );
};

export default FreeProductsModal;
