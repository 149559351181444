import React from "react";

interface Props {
  className?: string;
  style?: Object;
  color?: string;
  standard?: boolean;
}

export default function GooglePayIcon(props: Props) {
  const { className, style } = props;

  return (
    <svg
      className={className ?? "h-4 w-4"}
      viewBox="0 0 26 11"
      fill="none"
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7935 8.75455V5.73359H13.3304C13.9602 5.73359 14.4918 5.51951 14.9251 5.09729L15.0291 4.99025C15.8207 4.11607 15.7687 2.75427 14.9251 1.94551C14.5033 1.51734 13.9255 1.28542 13.3304 1.29731H10.8633V8.75455H11.7935ZM11.7935 4.81562V2.21094H13.3537C13.6889 2.21094 14.0067 2.34177 14.2436 2.57964C14.7464 3.07916 14.7579 3.91171 14.2725 4.42908C14.0356 4.68479 13.7004 4.82751 13.3537 4.81562H11.7935ZM19.3686 4.05322C18.9699 3.67858 18.4268 3.48828 17.7393 3.48828C16.8552 3.48828 16.1908 3.8213 15.7517 4.48139L16.5721 5.01065C16.8726 4.5587 17.2828 4.33272 17.8028 4.33272C18.1321 4.33272 18.4499 4.4576 18.6984 4.68358C18.941 4.89766 19.0797 5.20689 19.0797 5.53397V5.754C18.7215 5.55181 18.2708 5.44476 17.7161 5.44476C17.069 5.44476 16.549 5.59938 16.1619 5.91456C15.7748 6.22974 15.5783 6.64601 15.5783 7.17527C15.5668 7.65696 15.769 8.11486 16.1272 8.42409C16.4912 8.75711 16.9535 8.92362 17.4966 8.92362C18.1379 8.92362 18.6464 8.63223 19.0335 8.04945H19.0739V8.75711H19.9637V5.61127C19.9637 4.95118 19.7673 4.42787 19.3686 4.05322ZM16.8439 7.81864C16.6532 7.67592 16.5376 7.44399 16.5376 7.19423C16.5376 6.91473 16.6647 6.68281 16.9132 6.49846C17.1675 6.31411 17.4853 6.21896 17.8609 6.21896C18.381 6.21301 18.7855 6.33195 19.0745 6.56982C19.0745 6.9742 18.9184 7.32506 18.6122 7.6224C18.3348 7.90784 17.9592 8.0684 17.5662 8.0684C17.3062 8.07435 17.0519 7.98515 16.8439 7.81864ZM21.9628 10.9966L25.0712 3.65234H24.0601L22.6214 7.31555H22.6041L21.1307 3.65234H20.1196L22.1592 8.42759L21.0036 10.9966H21.9628Z"
        fill="#3C4043"
      />
      <path
        d="M8.58232 5.08307C8.58232 4.79168 8.55921 4.50029 8.51298 4.21484H4.58984V5.8621H6.83742C6.74497 6.39136 6.44452 6.8671 6.00541 7.16444V8.23485H7.34586C8.13165 7.49151 8.58232 6.39136 8.58232 5.08307Z"
        fill="#4285F4"
      />
      <path
        d="M4.59014 9.26961C5.71104 9.26961 6.6586 8.88902 7.34616 8.23487L6.00571 7.16446C5.63015 7.42611 5.15059 7.57478 4.59014 7.57478C3.50391 7.57478 2.58523 6.81954 2.2559 5.80859H0.875V6.91469C1.57989 8.35975 3.01857 9.26961 4.59014 9.26961Z"
        fill="#34A853"
      />
      <path
        d="M2.25624 5.81177C2.08288 5.28251 2.08288 4.70567 2.25624 4.17046V3.07031H0.875119C0.279908 4.2775 0.279908 5.70473 0.875119 6.91192L2.25624 5.81177Z"
        fill="#FBBC04"
      />
      <path
        d="M4.59014 2.40212C5.18526 2.39022 5.75726 2.62215 6.18482 3.04437L7.37505 1.81933C6.61816 1.09383 5.62437 0.695397 4.59014 0.70729C3.01857 0.70729 1.57989 1.62309 0.875 3.06815L2.2559 4.17425C2.58524 3.15736 3.50391 2.40212 4.59014 2.40212Z"
        fill="#EA4335"
      />
    </svg>
  );
}
