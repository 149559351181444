import { useEffect, useState } from "react";
import FoodAmigosLogo from "../../assets/images/FoodAmigosLogo.png";
import { useTranslation } from "react-i18next";
import LinearProgressBar from "../progress/LinearProgressBar";
import {useSelector} from "react-redux";
import {StoreInterface} from "../../store/types";

interface Props {
  startAnimation: boolean;
  onAnimationComplete: () => void;
}

const LoadingMenuPage = (props: Props): JSX.Element => {
  const { t } = useTranslation(null, { keyPrefix: 'Components:Loading:LoadingMenuPage' });

  const { startAnimation, onAnimationComplete } = props;

  const { company } = useSelector((state: StoreInterface) => state.initialData);

  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    if (startAnimation) {
      setTimeout(() => {
        setProgress(100);
        setTimeout(() => onAnimationComplete(), 1700);
      }, 500);
    }
  }, [startAnimation]);

  return (
    <div
      className={
        "top-0 left-0 w-full h-full z-50 fixed inset-0 bg-background-inkWhite-white_1 flex flex-col justify-center items-center p-small"
      }
      data-test={"loading-table-page"}
    >
      <p className={"text-brand-text-grey mt-small"}>{t("loading_menu")}</p>
      <div className="lg:w-1/4 w-3/4 mt-small">
        <LinearProgressBar progress={progress} transitionDuration={"1.5s"} />
      </div>

      <div
        className={
          "flex flex-row mt-xsmall items-center justify-center w-full fixed bottom-10"
        }
      >
        <h6 className="mr-mini font-semibold">Powered by</h6>
        <img
          src={company?.service_provider?.logo_url ?? FoodAmigosLogo}
          alt="Logo"
          className="h-8"
        />
      </div>
    </div>
  );
};

export default LoadingMenuPage;
