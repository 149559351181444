export default {
  pickup: "{{time}} min Abholung: ",
  delivery: "{{time}} min Lieferung: ",
  distance: '{{distance}} entfernt',
  buttons: {
    order_here: 'Hier Bestellen',
  },
  labels: {
    available: 'Verfügbar',
    unavailable: 'Nicht verfügbar',
  },
};
