import {
  DeliveryProvider,
  ORDER_FULFILLMENT_STATUSES,
  ORDER_METHODS, ORDER_REJECTED_REASON
} from "../../../../../services/exports/Constants";

export default {
  labels: {
    today: 'Today',
  },
  order_status: {
    [ORDER_METHODS.ROOM_SERVICE]: {
      [ORDER_FULFILLMENT_STATUSES.REJECTED]: '❌ Bestellung storniert',
      [ORDER_FULFILLMENT_STATUSES.CREATED]: 'Geschätzte zeit:',
      [ORDER_FULFILLMENT_STATUSES.REVIEWED]: 'Geschätzte zeit:',
      [ORDER_FULFILLMENT_STATUSES.ACCEPTED]: '✅ Bestätigte zeit:',
      [ORDER_FULFILLMENT_STATUSES.PREPARING]: '✅ Bestätigte zeit:',
      [ORDER_FULFILLMENT_STATUSES.READY]: '👍 Bestellung ist fertig',
      [ORDER_FULFILLMENT_STATUSES.ON_DELIVERY]: '🚴🏻Die Bestellung wird geliefert',
      [ORDER_FULFILLMENT_STATUSES.PICKED]: '😉 Bestellung ist abgeschlossen',
    },
    [ORDER_METHODS.PICKUP]: {
      [ORDER_FULFILLMENT_STATUSES.REJECTED]: '❌ Bestellung storniert',
      [ORDER_FULFILLMENT_STATUSES.CREATED]: 'Geschätzte Abholzeit:',
      [ORDER_FULFILLMENT_STATUSES.REVIEWED]: 'Geschätzte Abholzeit:',
      [ORDER_FULFILLMENT_STATUSES.ACCEPTED]: '✅ Bestätigte Abholzeit:',
      [ORDER_FULFILLMENT_STATUSES.PREPARING]: '✅ Bestätigte Abholzeit:',
      [ORDER_FULFILLMENT_STATUSES.READY]: '👍 Bestellung ist fertig',
      [ORDER_FULFILLMENT_STATUSES.ON_DELIVERY]: '🚴🏻Die Bestellung wird geliefert',
      [ORDER_FULFILLMENT_STATUSES.PICKED]: '😉 Bestellung ist abgeschlossen',
    },
    [ORDER_METHODS.DELIVERY]: {
      [DeliveryProvider.Standalone]: {
        [ORDER_FULFILLMENT_STATUSES.REJECTED]: '❌ Bestellung storniert',
        [ORDER_FULFILLMENT_STATUSES.CREATED]: 'Geschätzte Lieferzeit:',
        [ORDER_FULFILLMENT_STATUSES.REVIEWED]: 'Geschätzte Lieferzeit:',
        [ORDER_FULFILLMENT_STATUSES.ACCEPTED]: '✅ Bestätigte Lieferzeit:',
        [ORDER_FULFILLMENT_STATUSES.PREPARING]: '✅ Bestätigte Lieferzeit:',
        [ORDER_FULFILLMENT_STATUSES.READY]: '👍 Bestellung ist fertig',
        [ORDER_FULFILLMENT_STATUSES.ON_DELIVERY]: '🚴🏻Die Bestellung wird geliefert',
        [ORDER_FULFILLMENT_STATUSES.PICKED]: '😉 Bestellung ist abgeschlossen',
      },
      [DeliveryProvider.FirstDelivery]: {
        [ORDER_FULFILLMENT_STATUSES.REJECTED]: '❌ Bestellung storniert',
        [ORDER_FULFILLMENT_STATUSES.CREATED]: 'Lieferung: Warten auf Bestellbestätigung',
        [ORDER_FULFILLMENT_STATUSES.REVIEWED]: 'Lieferung: Warten auf Bestellbestätigung',
        [ORDER_FULFILLMENT_STATUSES.ACCEPTED]: '✅ Lieferung bestätigt',
        [ORDER_FULFILLMENT_STATUSES.PREPARING]: '✅ Lieferung bestätigt',
        [ORDER_FULFILLMENT_STATUSES.READY]: '✅ Lieferung bestätigt',
        [ORDER_FULFILLMENT_STATUSES.ON_DELIVERY]: '🚴🏻Die Bestellung wird geliefert',
        [ORDER_FULFILLMENT_STATUSES.PICKED]: '😉 Bestellung ist abgeschlossen',
      },
    },
  },
  time_label: {
    [DeliveryProvider.FirstDelivery]: {
      [ORDER_FULFILLMENT_STATUSES.CREATED]: 'Geschätzte zeit:',
      [ORDER_FULFILLMENT_STATUSES.REVIEWED]: 'Geschätzte zeit:',
      [ORDER_FULFILLMENT_STATUSES.ACCEPTED]: 'Bestätigte zeit:',
      [ORDER_FULFILLMENT_STATUSES.PREPARING]: 'Bestätigte zeit:',
      [ORDER_FULFILLMENT_STATUSES.READY]: 'Bestätigte zeit:',
      [ORDER_FULFILLMENT_STATUSES.ON_DELIVERY]: 'Bestätigte zeit:',
    },
  },
  warning_label: {
    pickup_time_changed: "{{company}} änderte die Zeit von {{initialTime}} auf {{currentTime}}",
    order_rejected_reason: {
      [ORDER_REJECTED_REASON.AUTOMATIC]:
        "Leider können wir im Moment keine neuen Bestellungen annehmen, weil wir überlastet sind. Wir haben das Geld bereits an dich zurücküberwiesen und du solltest es spätestens innerhalb von 4 Werktagen erhalten. Solltest du das Geld nicht innerhalb von 4 Tagen erhalten, schreibe bitte an unseren digitalen Partner {{provider}} unter {{supportEmail}}. Wir entschuldigen uns dafür und würden uns freuen, wenn du in 1 Stunde noch einmal versuchst zu bestellen, falls du warten kannst.",
      [ORDER_REJECTED_REASON.CLOSING_SOON]:
        "Leider haben wir keine Zeit, um die Bestellung zu erledigen bevor wir schließen. Wir haben das Geld bereits an dich zurücküberwiesen und du solltest es spätestens innerhalb von 4 Werktagen erhalten. Solltest du das Geld nicht innerhalb von 4 Tagen erhalten, schreibe bitte an unseren digitalen Partner {{provider}} unter {{supportEmail}}. Bei Fragen möchten wir Dich bitten, das Restaurant anzurufen.",
      [ORDER_REJECTED_REASON.ITEM_UNAVAILABLE]:
        "Leider können wir den/die bestellten Artikel derzeit nicht anbieten. Wir haben das Geld bereits an dich zurücküberwiesen und du solltest es spätestens innerhalb von 4 Werktagen erhalten. Solltest du das Geld nicht innerhalb von 4 Tagen erhalten, schreibe bitte an unseren digitalen Partner {{provider}} unter {{supportEmail}}. Bei Fragen möchten wir Dich bitten, das Restaurant anzurufen.",
      [ORDER_REJECTED_REASON.ORDER_TOO_BIG]:
        "Leider können wir eine derart große Bestellung grad nicht vorbereiten. Wenn es in Ordnung ist, versuche es bitte noch einmal mit kleinerer(n) Bestellung(en). Wir haben das Geld bereits an dich zurücküberwiesen und du solltest es spätestens innerhalb von 4 Werktagen erhalten. Solltest du das Geld nicht innerhalb von 4 Tagen erhalten, schreibe bitte an unseren digitalen Partner {{provider}} unter {{supportEmail}}. Bei Fragen möchten wir Dich bitten, das Restaurant anzurufen.",
      [ORDER_REJECTED_REASON.CUSTOMER_MISTAKE]: '',
      [ORDER_REJECTED_REASON.OTHER]: '',
    },
  },
}