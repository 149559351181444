import BackIcon from "../../assets/logo/BackIcon";
import UserIcon from "../../assets/logo/UserIcon";
import { SCREENS } from "../../services/exports/Constants";
import { ThemeInterface } from "../../services/exports/Interfaces";
import useTheme from "../../hooks/ui/useTheme";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import useScreenType from "../../hooks/utility/useScreenType";
import useStore from "../../hooks/global/useStore";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import useNavigate from "../../hooks/navigation/useNavigate";
import useConfig from "../../hooks/ui/useConfig";

interface Props {
  title?: string;
  goBack?: () => void;
  showLogoOnMobile?: boolean;
}

export default function CheckoutNavBar(props: Props) {
  const { t } = useTranslation(null, { keyPrefix: 'Components:Navigation:CheckoutNavBar' });

  const { title, showLogoOnMobile, goBack } = props;

  const { profile } = useStore()?.profile;
  const { isDineIn } = useStore()?.dineInFlow;

  const { navigate } = useNavigate();
  const { logo_url } = useConfig();
  const theme: ThemeInterface = useTheme();
  const { isDesktop } = useScreenType();

  function _goBack() {
    if (goBack) {
      return goBack();
    }

    isDineIn ? navigate(SCREENS.DINE_IN) : navigate(SCREENS.HOME_SCREEN);
  }

  function renderLeftContainer() {
    function getDescription(): string {
      if (isDesktop) {
        return t("back");
      }

      return t("menu");
    }

    return (
      <button
        className={leftContainer}
        onClick={_goBack}
        data-test="checkout-nav-bar-back-button"
      >
        <div>
          <BackIcon
            className="h-4 w-4 mr-mini"
            color={theme.color.text.default}
          />
        </div>
        <div className="flex-none">
          <p className="mr-mini">{getDescription()}</p>
        </div>

        {logo_url && (
          <div className="shrink">
            <img
              src={logo_url}
              alt="Logo"
              className={companyLogoStyle}
            />
          </div>
        )}
      </button>
    );
  }

  function renderCenterContainer() {
    return (
      <div className={centerContainer}>
        {logo_url && showLogoOnMobile && !isDesktop ? (
          <button onClick={goBack}>
            <img
              src={logo_url}
              alt="Logo"
              className={companyLogoMobileStyle}
            />
          </button>
        ) : (
          <h4 className="text-center">{title}</h4>
        )}
      </div>
    );
  }

  function renderRightContainer() {
    const formattedNumber = () => {
      return formatPhoneNumberIntl(profile?.phone_number);
    };
    return profile?.phone_number ? (
      <div className={rightContainer}>
        <div className={phoneNumberContainer}>
          <p>{formattedNumber()}</p>
          <UserIcon
            style={{ marginLeft: theme.dimension.padding.tiny }}
            color={theme.color.text.grey}
          />
        </div>
      </div>
    ) : undefined;
  }

  return (
    <div
      className={classnames(container, {
        "bg-background-inkWhite-white_0": isDineIn,
      })}
    >
      {renderLeftContainer()}
      {renderCenterContainer()}
      {renderRightContainer()}
    </div>
  );
}

const container =
  "w-full bg-background-inkWhite-white_1 z-10 py-small items-center grid grid-cols-5 lg:px-medium px-small border-b border-brand-inkGrey-grey_2";

const leftContainer = "col-span-1 flex flex-row items-center";

const centerContainer = "col-span-3 flex justify-center items-center";

const rightContainer = "col-span-1 flex items-center justify-end";

const phoneNumberContainer =
  "lg:flex hidden flex-row rounded-full border border-brand-inkGrey-grey_2 px-small py-mini items-center";

const companyLogoStyle = "mr-4 h-12 lg:flex hidden object-contain";

const companyLogoMobileStyle = " h-[36px]";
