import { useRef, useMemo, useState } from "react";
import RewardIcon from "../../../assets/logo/RewardIcon";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { StoreInterface } from "../../../store/types";
import useAppMethods from "../../../hooks/utility/useAppMethods";
import ProductItem from "../product/ProductItem";
import {
  CategoryInterface,
  ProductComboInterface,
  ProductInterface,
  SmartPricingRule,
} from "../../../services/exports/Interfaces";
import useTheme from "../../../hooks/ui/useTheme";
import { useIntersection } from "../../../hooks/utility/useIntersection";
import useMenu from "../../../hooks/menu/useMenu";
import { CategoryType, PRODUCT_HEIGHT, SmartPricingRuleModifierType } from "../../../services/exports/Constants";
import { debounce } from "lodash";
import classnames from "classnames";

interface Props {
  category: CategoryInterface;
  index: number;
  isActive: boolean;
  isDesktop: boolean;
  loading?: boolean;
  toggleProduct: (product: ProductInterface | ProductComboInterface) => void;
  onChangeVisibleIndex: (index: number) => void;
}

export default function CategoryItem(props: Props) {
  const { t } = useTranslation(null, { keyPrefix: 'Components:Menu:CategoryItem' });

  const {
    category,
    index,
    isActive,
    isDesktop,
    toggleProduct,
    onChangeVisibleIndex,
  } = props;

  const theme = useTheme();
  const ref = useRef();

  const { formatCurrency } = useAppMethods();

  const { cached_order } = useSelector((state: StoreInterface) => state.order);
  const { company } = useSelector((state: StoreInterface) => state.initialData);
  const { selectedMenu } = useMenu();

  const freeCategory: boolean = category?.id === 0;
  const [visible, setVisible] = useState<boolean>(false);

  const visibleRef = useRef(visible);
  visibleRef.current = visible;

  const smartPricingRule: SmartPricingRule | null = useMemo(() => {
    if (!cached_order?.method) {
      return null;
    }

    const rule = [
      category?.smart_pricing_rules,
      selectedMenu?.smart_pricing_rules,
      company?.smart_pricing_rules,
    ]
    .flatMap((rules) => rules)
    .find((rule) => rule?.order_methods?.find((method) => method === cached_order?.method))

    return rule?.modifier_value < 0 ? rule : null;
  }, [
    cached_order?.method,
    category?.smart_pricing_rules,
    selectedMenu?.smart_pricing_rules,
    company?.smart_pricing_rules,
  ]);

  const onChangeVisibility = (visible: boolean) => {
    setVisible(visible);
    visible && onChangeVisibleIndexDebounce();
  };

  const onChangeVisibleIndexDebounce = useRef(
    debounce(() => {
      if (visibleRef.current) {
        onChangeVisibleIndex(index);
      }
    }, 0)
  ).current;

  useIntersection(
    ref,
    {
      rootMargin: "0px",
      threshold: 0,
    },
    false,
    onChangeVisibility
  );

  function getTitle(): string {
    if (freeCategory) {
      return t("free_menu_items");
    }

    return category?.name;
  }

  function getProductListSize(): number {
    const productsGap = theme.dimension.padding.small;

    const productsPerColumn = Math.ceil(
      category?.products?.length / (isDesktop ? 2 : 1)
    );

    return (
      productsPerColumn * PRODUCT_HEIGHT + (productsPerColumn - 1) * productsGap
    );
  }

  const renderProducts = useMemo(() => {
    const products = isActive
      ? category?.products
      : category?.products?.slice(0, 2);

    return (
      <div
        className={menuContainer}
        style={{
          gap: theme.dimension.padding.small,
          height: getProductListSize(),
        }}
      >
        {products?.map(
          (product: ProductInterface | ProductComboInterface, index) => (
            <ProductItem
              product={product}
              category={category}
              toggleProduct={() => toggleProduct(product)}
              key={`product-${product?.id}-${index}`}
            />
          )
        )}
      </div>
    );
  }, [category?.products, isActive, isDesktop, toggleProduct]);

  const renderDiscountBadge = () => {
    if (
      !smartPricingRule ||
      category?.type === CategoryType.MostPopular ||
      category?.type === CategoryType.FreeItems
    ) {
      return null;
    }

    const formattedDiscount = smartPricingRule.modifier_type === SmartPricingRuleModifierType.Percentage ?
            smartPricingRule.modifier_value + '%' :
            formatCurrency(smartPricingRule.modifier_value);

    return (
      <div className="ml-mini bg-companyBrand-primary px-mini py-tiny rounded-lg flex items-center justify-center">
        <span className="text-white text-tiny font-semibold">
          {t('discount_badge', { value: formattedDiscount })}
        </span>
      </div>
    );
  };

  return (
    <div ref={ref} key={category?.id?.toString()}>
      <div className="lg:mb-medium mb-small">
        <div className={categoryTitleContainer}>
          {freeCategory && (
            <div className={rewardIconContainer}>
              <RewardIcon fill />
            </div>
          )}
          <div className="flex items-center">
            <h5 className={categoryTitleStyle(freeCategory)}>{getTitle()}</h5>
            {renderDiscountBadge()}
          </div>
        </div>
        {category?.description && (
          <p className="text-brand-text-grey mini mt-mini">{category?.description}</p>
        )}
      </div>
      {renderProducts}
    </div>
  );
}

const menuContainer = "grid lg:grid-cols-2 grid-cols-1";

const categoryTitleContainer = "flex flex-row items-center";

const categoryTitleStyle = (free: boolean) => free ? 'text-companyBrand-primary' : 'text-brand-text-default';

const rewardIconContainer = "mr-mini flex";
