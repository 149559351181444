import { ConfigInterface } from "../exports/Interfaces";
import BackendApiClient from "./BackendApiClient";
import { DataWrappedResponse, DataWrapper } from "../exports/Types";

class ConfigManager {
    public get = async (slug: string): Promise<DataWrappedResponse<DataWrapper<ConfigInterface>>> =>
        BackendApiClient.request({
            method: "GET",
            url: `/store-config/${slug}`,
        });
}

export default new ConfigManager();
