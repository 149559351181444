import { useEffect, useMemo, useState } from "react";
import CustomButton from "../components-library/buttons/CustomButton";
import CustomModal from "../components-library/CustomModal";
import UpSellItem from "../components-library/list/UpSellItem";
import LoadingIndicator from "../components-library/loading/LoadingIndicator";
import CustomNavBar from "../components-library/navigation/CustomNavBar";
import useScreenType from "../hooks/utility/useScreenType";
import { MODAL_SIZES, SCREENS } from "../services/exports/Constants";
import {ButtonTypes, ProductInterface} from "../services/exports/Interfaces";
import useDineInFlow from "../hooks/global/useDineInFlow";
import { useTranslation } from "react-i18next";
import useNavigate from "../hooks/navigation/useNavigate";
import useAppMethods from "../hooks/utility/useAppMethods";

interface Props {
  openModal: boolean;
  products: ProductInterface[];
  toggleModal: () => void;
}

export default function UpSellModal(props: Props) {
  const { t } = useTranslation(null, { keyPrefix: "Modals:UpSellModal" });

  const { openModal, products, toggleModal } = props;

  const { navigate } = useNavigate();

  const { isDesktop } = useScreenType();
  const { isDineIn, userOrdered } = useDineInFlow();
  const { toggleMenuItemModal } = useAppMethods();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (openModal) {
      onDidFocus();
    }
  }, [openModal]);

  function onDidFocus() {
    if (!isDesktop) {
      setLoading(true);
      setTimeout(() => setLoading(false), 500);
    }
  }

  function openProductModal(product: ProductInterface | null) {
    toggleMenuItemModal({
      openModal: true,
      product,
      onCloseModal: () => onContinue(),
    });
  }

  function addOrder(product: ProductInterface) {
    toggleModal();
    openProductModal(product);
  }

  function onContinue() {
    if (isDineIn) {
      return navigate(userOrdered ? SCREENS.CONFIRM_ORDER : SCREENS.TABLE, {
        state: {
          prevPath: window.location.pathname,
        },
      });
    }

    navigate(SCREENS.CHECKOUT);
  }

  function renderHeaderBar() {
    return (
      <div className={headerContainer}>
        <CustomNavBar
          title={t("header.recommend")}
          rightClick={onContinue}
          hideBackIcon
        />
      </div>
    );
  }

  function renderUpSellProducts() {
    return (
      <div className={upSellContainer}>
        {products?.map((product: ProductInterface) => (
          <div className={upSellItemContainer} key={product?.id?.toString()}>
            <UpSellItem product={product} addOrder={addOrder} />
          </div>
        ))}
      </div>
    );
  }

  function renderSkipCheckoutButton() {
    return (
      <div className={skipCheckoutButtonContainer(isDineIn)}>
        <div className="lg:w-fit w-full mx-auto">
          <CustomButton
            title={t("skip_checkout_button.go_to_checkout")}
            buttonType={ButtonTypes.third}
            onClick={onContinue}
          />
        </div>
      </div>
    );
  }

  const renderLoadingPage = useMemo(() => {
    return loading ? (
      <div className={loadingContainer}>
        <div className="-mb-1.5">
          <LoadingIndicator lg={60} md={50} sm={40} />
        </div>
      </div>
    ) : null;
  }, [loading]);

  function isFullScreen() {
    return !isDesktop;
  }

  return (
    <CustomModal
      open={openModal}
      size={MODAL_SIZES.LG}
      fullScreen={isFullScreen()}
      onClose={() => toggleModal()}
    >
      <div className={container}>
        {renderHeaderBar()}
        {renderUpSellProducts()}
        {renderSkipCheckoutButton()}
        {renderLoadingPage}
      </div>
    </CustomModal>
  );
}

const container = `h-full bg-background-inkWhite-white_1`;

const headerContainer = "sticky top-0 z-10 mb-small";

const upSellContainer =
  "flex lg:flex-row flex-col justify-center lg:pb-[90px] pb-[70px] px-small bg-background-inkWhite-white_1";

const upSellItemContainer =
  "lg:px-small px-none lg:w-1/3 w-full lg:mb-none mb-medium";

const skipCheckoutButtonContainer = (isDineIn) =>
  `w-full px-small py-mini border-t border-brand-inkGrey-grey_2 lg:absolute fixed bottom-0 ${
    isDineIn
      ? "bg-background-inkWhite-white_0 rounded-t-xl"
      : "bg-background-inkWhite-white_1"
  } `;

const loadingContainer =
  "absolute inset-0 flex flex-col bg-background-inkWhite-white_1 justify-center items-center";
