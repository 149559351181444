import {CompanyDetailsInterface, CompanyLocation, GoogleAddressParamsInterface} from "../exports/Interfaces";

class AddressResource {
  public formatAddress(data: GoogleAddressParamsInterface): string {
    let result = "";

    if (data?.streetName) {
      result += data?.streetName;
    }

    if (data?.streetName && data?.streetNumber) {
      result += ` ${data?.streetNumber}`;
    }

    if (data?.streetName && data?.streetNumber && data?.zip) {
      result += `, ${data?.zip}`;
    }

    if (data?.streetName && data?.streetNumber && data?.city) {
      if (!data?.zip) {
        result += ",";
      }
      result += ` ${data?.city}`;
    }

    if (data?.streetName && data?.streetNumber && data?.country) {
      result += `, ${data?.country}`;
    }

    return result;
  }

  public compileAddress(data: CompanyDetailsInterface|CompanyLocation): string {
    return `${data.address}, ${data.zip_code} ${data.city}`;
  }
}

export default new AddressResource();
