export default {
  description:
    "Use Code: {{code}} to enjoy a discount on your next {{count}} orders when you order here.",
  discounts: {
    percentage: "{{discount}}% Discount",
    fixed: "{{discount}} Discount",
  },
  list: {
    item: {
      order: "{{orderNumber}}. Order",
    },
  },
  savings: {
    percentage: "You save a total of {{totalSaved}}%",
    fixed: "You save a total of {{totalSaved}}",
  },
  custom_button: {
    done: "Done",
  },
};
