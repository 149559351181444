import { Navigate } from "react-router-dom";
import { SCREENS } from "../../../services/exports/Constants";
import useDineInFlow from "../../../hooks/global/useDineInFlow";

const DineInRoute = ({ children }: { children: JSX.Element }): JSX.Element => {
  const { isDineIn } = useDineInFlow();

  if (!isDineIn) {
    return <Navigate to={SCREENS.DINE_IN} />;
  }

  return children;
};

export default DineInRoute;
