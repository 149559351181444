import React from "react";
import Theme from "../../components-library/Theme";

interface Props {
  className?: string;
  style?: Object;
  color?: string;
  standard?: boolean;
}

export default function GooglePayIcon(props: Props) {
  const { className, color, style, standard } = props;
  if (standard) {
    return (
      <svg
        className={className ?? "h-4 w-4"}
        viewBox="0 0 25 10"
        fill="none"
        style={style}
      >
        <path
          d="M10.9181 4.49603V7.40003H9.99609V0.227026H12.4401C12.7319 0.22066 13.0221 0.272271 13.2938 0.378869C13.5656 0.485468 13.8134 0.644934 14.0231 0.848026C14.4521 1.23403 14.6931 1.78603 14.6841 2.36203C14.6904 2.64855 14.6346 2.93305 14.5205 3.19595C14.4064 3.45886 14.2367 3.69393 14.0231 3.88503C13.5951 4.29303 13.0671 4.49703 12.4401 4.49603H10.9181ZM10.9181 1.11003V3.61503H12.4631C12.8061 3.62503 13.1361 3.49103 13.3751 3.24403C13.8591 2.77403 13.8711 1.99903 13.3991 1.51503L13.3751 1.49103C13.2587 1.36661 13.1172 1.26831 12.96 1.20263C12.8028 1.13695 12.6334 1.10539 12.4631 1.11003H10.9181V1.11003ZM16.8081 2.33203C17.4901 2.33203 18.0271 2.51403 18.4211 2.87803C18.8151 3.24203 19.0121 3.74203 19.0121 4.37503V7.40103H18.1311V6.71903H18.0911C17.7091 7.28003 17.2011 7.56103 16.5681 7.56103C16.0271 7.56103 15.5751 7.40103 15.2111 7.08003C15.0366 6.93277 14.897 6.7485 14.8026 6.54059C14.7081 6.33268 14.6612 6.10635 14.6651 5.87803C14.6651 5.37003 14.8571 4.96603 15.2411 4.66603C15.6251 4.36603 16.1371 4.21503 16.7791 4.21503C17.3261 4.21503 17.7771 4.31503 18.1311 4.51503V4.30403C18.1322 4.14839 18.0986 3.99447 18.0327 3.85347C17.9668 3.71248 17.8702 3.58797 17.7501 3.48903C17.5063 3.26895 17.1885 3.14895 16.8601 3.15303C16.3461 3.15303 15.9381 3.37003 15.6381 3.80403L14.8271 3.29303C15.2721 2.65203 15.9331 2.33203 16.8081 2.33203V2.33203ZM15.6151 5.89803C15.6146 6.01521 15.6421 6.13082 15.6951 6.23532C15.7481 6.33982 15.8252 6.43021 15.9201 6.49903C16.1241 6.65903 16.3771 6.74503 16.6361 6.73903C17.0251 6.73804 17.398 6.58308 17.6731 6.30803C17.9781 6.02003 18.1311 5.68403 18.1311 5.29603C17.8431 5.06703 17.4431 4.95203 16.9291 4.95203C16.5551 4.95203 16.2421 5.04203 15.9921 5.22303C15.7411 5.40803 15.6151 5.63103 15.6151 5.89803V5.89803ZM24.0721 2.49203L20.9951 9.56503H20.0441L21.1861 7.09003L19.1611 2.49203H20.1631L21.6261 6.01803H21.6461L23.0691 2.49203H24.0721V2.49203Z"
          fill={color ?? Theme.color.text.default}
        />
        <path
          d="M8.07909 4.7C8.07909 4.419 8.05609 4.139 8.00809 3.862H4.12109V5.449H6.34709C6.30166 5.70248 6.2053 5.94412 6.06385 6.15931C5.92239 6.3745 5.73878 6.55877 5.52409 6.701V7.732H6.85309C7.63109 7.016 8.07909 5.955 8.07909 4.7V4.7Z"
          fill="#4285F4"
        />
        <path
          d="M4.1195 8.72802C5.2315 8.72802 6.1685 8.36302 6.8515 7.73302L5.5225 6.70202C5.1525 6.95302 4.6765 7.09602 4.1195 7.09602C3.0445 7.09602 2.1325 6.37102 1.8055 5.39502H0.4375V6.45702C0.780345 7.1397 1.30625 7.71362 1.95645 8.11466C2.60666 8.5157 3.35556 8.72806 4.1195 8.72802V8.72802Z"
          fill="#34A853"
        />
        <path
          d="M1.80774 5.39498C1.63475 4.8832 1.63475 4.32876 1.80774 3.81698V2.75598H0.438735C0.150242 3.33036 0 3.96422 0 4.60698C0 5.24974 0.150242 5.8836 0.438735 6.45798L1.80774 5.39498Z"
          fill="#FBBC04"
        />
        <path
          d="M4.1195 2.11697C4.70717 2.10758 5.27499 2.32954 5.7005 2.73497L6.8775 1.55897C6.13109 0.858675 5.14294 0.473879 4.1195 0.484975C3.35556 0.484936 2.60666 0.697297 1.95645 1.09833C1.30625 1.49937 0.780345 2.07329 0.4375 2.75597L1.8065 3.81797C2.1325 2.84097 3.0445 2.11697 4.1195 2.11697V2.11697Z"
          fill="#EA4335"
        />
      </svg>
    );
  }
  return (
    <svg
      version="1.1"
      id="G_Pay_Acceptance_Mark"
      className={className ?? "h-4 w-4"}
      viewBox="0 0 1094 742"
      enableBackground="new 0 0 1094 742"
      style={style}
    >
      <path
        id="Base_1_"
        fill="#FFFFFF"
        d="M722.7,170h-352c-110,0-200,90-200,200l0,0c0,110,90,200,200,200h352c110,0,200-90,200-200l0,0
      C922.7,260,832.7,170,722.7,170z"
      />
      <path
        id="Outline"
        fill="#3C4043"
        d="M722.7,186.2c24.7,0,48.7,4.9,71.3,14.5c21.9,9.3,41.5,22.6,58.5,39.5
      c16.9,16.9,30.2,36.6,39.5,58.5c9.6,22.6,14.5,46.6,14.5,71.3s-4.9,48.7-14.5,71.3c-9.3,21.9-22.6,41.5-39.5,58.5
      c-16.9,16.9-36.6,30.2-58.5,39.5c-22.6,9.6-46.6,14.5-71.3,14.5h-352c-24.7,0-48.7-4.9-71.3-14.5c-21.9-9.3-41.5-22.6-58.5-39.5
      c-16.9-16.9-30.2-36.6-39.5-58.5c-9.6-22.6-14.5-46.6-14.5-71.3s4.9-48.7,14.5-71.3c9.3-21.9,22.6-41.5,39.5-58.5
      c16.9-16.9,36.6-30.2,58.5-39.5c22.6-9.6,46.6-14.5,71.3-14.5L722.7,186.2 M722.7,170h-352c-110,0-200,90-200,200l0,0
      c0,110,90,200,200,200h352c110,0,200-90,200-200l0,0C922.7,260,832.7,170,722.7,170L722.7,170z"
      />
      <g id="G_Pay_Lockup_1_">
        <g id="Pay_Typeface_3_">
          <path
            id="Letter_p_3_"
            fill="#3C4043"
            d="M529.3,384.2v60.5h-19.2V295.3H561c12.9,0,23.9,4.3,32.9,12.9
          c9.2,8.6,13.8,19.1,13.8,31.5c0,12.7-4.6,23.2-13.8,31.7c-8.9,8.5-19.9,12.7-32.9,12.7h-31.7V384.2z M529.3,313.7v52.1h32.1
          c7.6,0,14-2.6,19-7.7c5.1-5.1,7.7-11.3,7.7-18.3c0-6.9-2.6-13-7.7-18.1c-5-5.3-11.3-7.9-19-7.9h-32.1V313.7z"
          />
          <path
            id="Letter_a_3_"
            fill="#3C4043"
            d="M657.9,339.1c14.2,0,25.4,3.8,33.6,11.4c8.2,7.6,12.3,18,12.3,31.2v63h-18.3v-14.2h-0.8
          c-7.9,11.7-18.5,17.5-31.7,17.5c-11.3,0-20.7-3.3-28.3-10s-11.4-15-11.4-25c0-10.6,4-19,12-25.2c8-6.3,18.7-9.4,32-9.4
          c11.4,0,20.8,2.1,28.1,6.3v-4.4c0-6.7-2.6-12.3-7.9-17c-5.3-4.7-11.5-7-18.6-7c-10.7,0-19.2,4.5-25.4,13.6l-16.9-10.6
          C625.9,345.8,639.7,339.1,657.9,339.1z M633.1,413.3c0,5,2.1,9.2,6.4,12.5c4.2,3.3,9.2,5,14.9,5c8.1,0,15.3-3,21.6-9
          s9.5-13,9.5-21.1c-6-4.7-14.3-7.1-25-7.1c-7.8,0-14.3,1.9-19.5,5.6C635.7,403.1,633.1,407.8,633.1,413.3z"
          />
          <path
            id="Letter_y_3_"
            fill="#3C4043"
            d="M808.2,342.4l-64,147.2h-19.8l23.8-51.5L706,342.4h20.9l30.4,73.4h0.4l29.6-73.4H808.2z"
          />
        </g>
        <g id="G_Mark_1_">
          <path
            id="Blue_500"
            fill="#4285F4"
            d="M452.93,372c0-6.26-0.56-12.25-1.6-18.01h-80.48v33L417.2,387
          c-1.88,10.98-7.93,20.34-17.2,26.58v21.41h27.59C443.7,420.08,452.93,398.04,452.93,372z"
          />
          <path
            id="Green_500_1_"
            fill="#34A853"
            d="M400.01,413.58c-7.68,5.18-17.57,8.21-29.14,8.21c-22.35,0-41.31-15.06-48.1-35.36
          h-28.46v22.08c14.1,27.98,43.08,47.18,76.56,47.18c23.14,0,42.58-7.61,56.73-20.71L400.01,413.58z"
          />
          <path
            id="Yellow_500_1_"
            fill="#FABB05"
            d="M320.09,370.05c0-5.7,0.95-11.21,2.68-16.39v-22.08h-28.46
          c-5.83,11.57-9.11,24.63-9.11,38.47s3.29,26.9,9.11,38.47l28.46-22.08C321.04,381.26,320.09,375.75,320.09,370.05z"
          />
          <path
            id="Red_500"
            fill="#E94235"
            d="M370.87,318.3c12.63,0,23.94,4.35,32.87,12.85l24.45-24.43
          c-14.85-13.83-34.21-22.32-57.32-22.32c-33.47,0-62.46,19.2-76.56,47.18l28.46,22.08C329.56,333.36,348.52,318.3,370.87,318.3z"
          />
        </g>
      </g>
    </svg>
  );
}
