// @ts-nocheck
import { useEffect, useMemo } from "react";
import usePaymentRequest from "../../../hooks/payment/usePaymentRequest";
import {loadStripe} from "@stripe/stripe-js";
import {useSelector} from "react-redux";
import {StoreInterface} from "../../../store/types";
import {Elements,} from "@stripe/react-stripe-js";
import useStripeHelpers from "../../../hooks/payment/useStripeHelpers";
import collect, {Collection} from "collect.js";
import {PaymentMethod} from "../../../services/exports/Constants";
import * as stripeJs from "@stripe/stripe-js";

interface Props {
  children?: JSX.Element;
}

function Validator(props: Props) {
  const { children } = props;

  const { company } = useSelector((state: StoreInterface) => state.initialData);

  const { setEnabledWallets } = useStripeHelpers();

  const { initiated, result } = usePaymentRequest({
    options: {
      country: company.country,
      currency: typeof company.currency === 'string' ? company.currency.toLowerCase() : 'eur',
      total: {
        label: `Validation`,
        amount: Number((1 * 100).toFixed(0)),
      },
    },
  });

  useEffect(() => {
    if (initiated && result !== null) {
      setEnabledWallets(
        (current) => collect(current)
          .when(
            result?.googlePay,
            (collection: Collection<PaymentMethod>) => collection.push(PaymentMethod.GooglePay),
            (collection: Collection<PaymentMethod>) => collection.reject((item) => item === PaymentMethod.GooglePay),
          ).toArray()
      )
    }
  }, [initiated, result]);

  return children;
}

export default function GooglePayValidator(props: Props): JSX.Element {
  const { children } = props;

  const { company } = useSelector((state: StoreInterface) => state.initialData);

  const stripePromise = useMemo<null|Promise<stripeJs.Stripe>>(
    () =>  !company?.service_provider?.stripe_key
      ? null
      : loadStripe(company?.service_provider?.stripe_key, {
        stripeAccount: company?.stripe_id
      }),
    [company?.service_provider?.stripe_key, company?.stripe_id]
  );

  return stripePromise ? (
    <Elements stripe={stripePromise}>
      {company?.id && (
        <Validator key={`google-pay-status-validation-${company?.id}`}>{children}</Validator>
      )}
    </Elements>
  ) : children;
}
