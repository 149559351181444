import { actionCreators } from "../../store/actions";
import ReduxActions from "../../store/ReduxActions";
import {
  ErrorHandlerInterface,
  OrderDataInterface,
  OrderItemInterface,
  OrderInterface,
  OrderStatusResponseInterface,
} from "../exports/Interfaces";
import { DataWrappedResponse, DataWrapper } from "../exports/Types";
import BackendApiClient from "./BackendApiClient";

class OrderManager {
  public getOrder = async (
    uuid: string
  ): Promise<DataWrappedResponse<DataWrapper<OrderItemInterface>>> =>
    BackendApiClient.request({
      method: "GET",
      url: `/orders/${uuid}`,
    });

  public getOrderHistory = async ({
    limit = 10,
  }: {
    limit: number;
  }): Promise<DataWrappedResponse<DataWrapper<OrderItemInterface[]>>> => {
    const url = `/orders?filter[orders.company_id]=${
      ReduxActions.getStore()?.initialData?.company?.id
    }&limit=${limit}`;

    return BackendApiClient.request({
      method: "GET",
      url,
    });
  };

  public getOrderStatus = async (
    orderId: number
  ): Promise<DataWrappedResponse<DataWrapper<OrderStatusResponseInterface>>> =>
    BackendApiClient.request({
      method: "GET",
      url: `/orders/${orderId}/payment-status`,
    });

  public postOrder = async (
    data: OrderDataInterface,
    errorHandlers?: ErrorHandlerInterface
  ): Promise<DataWrappedResponse<DataWrapper<OrderInterface>>> => {
    const request = await BackendApiClient.request(
      {
        method: "POST",
        url: "/orders",
        data,
      },
      false,
      errorHandlers
    );

    const { response, success } = request;
    if (success) {
      this.saveOrderRedux(response?.data?.data);
    }

    return request;
  };

  public deleteOngoingOrder = async (id: number): Promise<DataWrappedResponse<DataWrapper<OrderInterface>>> =>
    BackendApiClient.request({
      method: "DELETE",
      url: `/order-fulfillments/${id}`,
    });

  public async draftOrder(
    data: OrderDataInterface
  ): Promise<DataWrappedResponse<DataWrapper<OrderInterface>>> {
    return BackendApiClient.request({
      method: 'POST',
      url: '/order-drafts',
      data,
    });
  }

  private saveOrderRedux(response: OrderInterface) {
    ReduxActions.dispatch(actionCreators.order.updateOrder(response));
  }

  public deleteOngoingOrderRedux() {
    ReduxActions.dispatch(actionCreators.initialData.setOngoingOrders([]));
  }
}

export default new OrderManager();
