import BackIcon from "../../assets/logo/BackIcon";
import BicycleIcon from "../../assets/logo/BicycleIcon";
import WalkingIcon from "../../assets/logo/WalkingIcon";
import useTheme from "../../hooks/ui/useTheme";
import {useTranslation} from "react-i18next";
import { ORDER_METHODS } from "../../services/exports/Constants";
import AddressHelpers from "../../services/helpers/AddressHelpers";
import moment from "moment";
import HelperMethods from "../../services/exports/HelperMethods";
import useCompanyLocation from "../../hooks/location/useCompanyLocation";
import useAppMethods from "../../hooks/utility/useAppMethods";
import {useSelector} from "react-redux";
import {StoreInterface} from "../../store/types";
import useOrderPreparationTime from "../../hooks/order/useOrderPreparationTime";

interface Props {
  onClick: () => void;
}

export default function OrderMethodInfo(props: Props) {
  const { t } = useTranslation(null, { keyPrefix: 'Components:Checkout:OrderMethodInfo' });

  const { company } = useSelector((state: StoreInterface) => state.initialData);
  const { cached_order: order } = useSelector((state: StoreInterface) => state.order);

  const { onClick } = props;

  const { formatDateTime, createScheduledOrderSlot } = useAppMethods();
  const companyLocation = useCompanyLocation(order?.scheduled_for);
  const theme = useTheme();

  const getPreparationTime = useOrderPreparationTime();

  const renderScheduledOrderInfo = (params = {}) => {
    const type = order?.method === ORDER_METHODS.ROOM_SERVICE
      ? order?.room_service_location?.type
      : order?.method;

    return t(`${type}.description.scheduled`, {
      date: moment(order?.scheduled_for).isSame(moment(), 'day')
        ? t('labels.today')
        : formatDateTime(order?.scheduled_for, { format: 'dddd, DD MMM' }),
      time: createScheduledOrderSlot(order?.scheduled_for),
      ...params,
    })
  }

  function renderRoomServiceInfo() {
    function getDescription() {
      const detailsLabel = t(`${order?.room_service_location?.type}.details_label`);
      const location = `${order?.room_service_location?.name}, ${order?.room_service_location?.street_name} ${order?.room_service_location?.street_number}, ${detailsLabel}: ${order?.room_service_details}`;

      if (order?.scheduled_for === null) {
        return t(`${order?.room_service_location?.type}.description.asap`, {
          time: HelperMethods.formatTimeInterval(getPreparationTime()),
          location: location,
        });
      }

      return renderScheduledOrderInfo({ location });
    }

    return (
      <div className={wrapper}>
        <WalkingIcon color={theme.color.text.grey} className={actionIcon} />
        <p className={paragraph}>
          <span className={highlighted}>
            {t(`${order.method}.title`)}
          </span>
          {getDescription()}
        </p>
      </div>
    );
  }

  function renderPickupInfo() {
    function getDescription() {
      const location = `${company?.name}, ${companyLocation?.address}`;

      if (order?.scheduled_for === null) {
        return t('pickup.description.asap', {
          time: HelperMethods.formatTimeInterval(getPreparationTime()),
          location: location,
        });
      }

      return renderScheduledOrderInfo({ location });
    }

    return (
      <div className={wrapper}>
        <WalkingIcon color={theme.color.text.grey} className={actionIcon} />
        <p className={paragraph}>
          <span className={highlighted}>
            {t('pickup.title')}
          </span>
          {getDescription()}
        </p>
      </div>
    );
  }

  function renderDeliveryInfo() {
    function getDescription() {
      if (order?.scheduled_for === null) {
        return t('delivery.description.asap', {
          time: HelperMethods.formatTimeInterval(getPreparationTime()),
        });
      }

      return renderScheduledOrderInfo();
    }

    return (
      <div className={wrapper}>
        <BicycleIcon color={theme.color.text.grey} className={actionIcon} />
        <p className={paragraph}>
          <span className={highlighted}>
            {t('delivery.title')}
            {' '}
          </span>
          {getDescription()}
          {' '}
          <span className={highlighted}>
            {AddressHelpers.format(
              order?.delivery_street_name,
              order?.delivery_street_number,
              null,
              null,
              order?.delivery_country,
            )}
          </span>
        </p>
      </div>
    );
  }

  function renderInfos() {
    if (order?.method === ORDER_METHODS.ROOM_SERVICE) {
      return renderRoomServiceInfo();
    }

    if (order?.method === ORDER_METHODS.PICKUP) {
      return renderPickupInfo();
    }

    if (order?.method === ORDER_METHODS.DELIVERY) {
      return renderDeliveryInfo();
    }

    return undefined;
  }

  return (
    <button className={container} onClick={onClick}>
      {renderInfos()}
      <div>
        <BackIcon color={theme.color.text.grey} className={backIcon} />
      </div>
    </button>
  );
}

const container =
  "flex flex-row w-full px-small py-xsmall border rounded border-brand-inkGrey-grey_2 items-center justify-between";

const wrapper = "w-full flex flex-row items-center";

const paragraph = "text-left text-brand-text-grey";

const highlighted = "text-left inline text-brand-text-default font-semibold mr-tiny";

const backIcon = "h-4 w-4 rotate-180";

const actionIcon = "h-8 w-8 mr-mini";