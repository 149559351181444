import { useEffect, useState } from "react";
import PresentAnimation from "../assets/animations/PresentAnimation.json";
import SuccessModal from "./SuccessModal";
import useStore from "../hooks/global/useStore";
import { ProductInterface } from "../services/exports/Interfaces";
import CompanyManager from "../services/api/CompanyManager";
import RewardStatusResource from "../services/resources/RewardStatusResource";
import collect from "collect.js";
import { useTranslation } from "react-i18next";
import ProductsCarousel from "../components-library/carousel/ProductsCarousel";
import useGoogleReviewSuggestedProducts from "../hooks/google-review/useGoogleReviewSuggestedProducts";

interface Props {
  open: boolean;
  toggleModal: () => void;
  setLoading?: (loading: boolean) => void;
}

const RewardsUnlockedModal = (props: Props): JSX.Element => {
  const { t } = useTranslation(null, { keyPrefix: 'Modals:RewardsUnlockedModal' });

  const { open, toggleModal, setLoading } = props;

  const { company } = useStore()?.initialData;

  const { suggestedProducts } = useGoogleReviewSuggestedProducts({
    autoLoad: true,
  });

  const [show, setShow] = useState<boolean>(false);

  const [discount, setDiscount] = useState<number>(0);
  const [freeProducts, setFreeProducts] = useState<ProductInterface[]>([]);

  const oneFreeReward: boolean = freeProducts?.length === 1;
  const multiFreeReward: boolean = freeProducts?.length > 1;

  const mostExpensiveFreeProduct = () => collect(freeProducts)?.first();

  useEffect(() => {
    if (open) {
      onDidFocus();
    } else {
      onDidBlur();
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      setLoading && setLoading(!show);
    } else {
      setLoading && setLoading(false);
    }
  }, [show, open]);

  function onDidFocus(): void {
    findUnlockedReward();
  }

  function onDidBlur(): void {
    setShow(false);
  }

  async function findUnlockedReward() {
    const reward = new RewardStatusResource({
      rewards: company?.rewards,
      rewardBalance: company?.reward_balance,
    }).getLastActiveReward();

    if (reward?.discount === 100) {
      return getFreeMenuItems();
    }

    setDiscount(reward?.discount);
    setTimeout(() => setShow(true), 1000);
  }

  async function getFreeMenuItems() {
    const { response, success } = await CompanyManager.getFreeMenuItems(
      company?.id
    );
    setTimeout(() => setShow(true), 500);
    if (success) {
      setFreeProducts(response?.data?.data);
    }
  }

  const renderRewardUnlocked = (): JSX.Element => {
    function getTitle(): string {
      // 1 Free item
      if (oneFreeReward) {
        return t("reward_unlocked.titles.free_menu_item", {
          product: mostExpensiveFreeProduct()?.name,
        });
      }

      // Multiple free items
      if (multiFreeReward) {
        return t("reward_unlocked.titles.multiple_free_menu_item", {
          product: mostExpensiveFreeProduct()?.name,
        });
      }

      // % discount
      return t("reward_unlocked.titles.discount", {
        discount,
      });
    }

    function getDescription(): string {
      // 1 Free item
      if (oneFreeReward) {
        return t("reward_unlocked.descriptions.free_menu_item", {
          product: mostExpensiveFreeProduct()?.name,
        });
      }

      // Multiple free items
      if (multiFreeReward) {
        return t("reward_unlocked.descriptions.multiple_free_menu_item", {
          product: mostExpensiveFreeProduct()?.name,
        });
      }

      // % discount
      return t("reward_unlocked.descriptions.discount", {
        discount,
      });
    }

    return (
      <div>
        <h6 className="mb-small">{getTitle()}</h6>
        <p className="mini text-brand-text-grey">{getDescription()}</p>
      </div>
    );
  };

  const renderAnimationDescription = (): JSX.Element => (
    <h4 className=" text-brand-text-light text-center">
      {t("animation_description.reward_unlocked")}
    </h4>
  );

  return (
    <SuccessModal
      open={show}
      animationDescription={renderAnimationDescription()}
      lottieProps={{
        animationData: PresentAnimation,
      }}
      buttonTitle={"Okay"}
      onConfirm={toggleModal}
    >
      {renderRewardUnlocked()}
      {suggestedProducts?.length > 0 && (
        <div className="mt-small">
          <ProductsCarousel products={suggestedProducts} />
        </div>
      )}
    </SuccessModal>
  );
};

export default RewardsUnlockedModal;
