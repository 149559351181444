import { useState } from "react";
import useTheme from "../../hooks/ui/useTheme";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { StoreInterface } from "../../store/types";
import ForwardIcon from "../../assets/logo/ForwardIcon";
import InvoiceIcon from "../../assets/logo/InvoiceIcon";
import RequestInvoiceModal from "../../modals/RequestInvoiceModal";

export default function RequestInvoiceItem() {
  const { t } = useTranslation(null, { keyPrefix: 'Components:Checkout:RequestInvoice' });

  const theme = useTheme();

  const { cached_order } = useSelector((state: StoreInterface) => state.order);
  const { profile } = useSelector((state: StoreInterface) => state.profile);

  const [showModal, setShowModal] = useState(false);

  const renderDescription = () => {
    let receiverName = null;

    if (profile?.invoice_first_name && profile?.invoice_last_name) {
      receiverName = profile.invoice_first_name + ' ' + profile.invoice_last_name;
    }

    if (profile?.invoice_company_name) {
      receiverName = profile.invoice_company_name;
    }

    if (cached_order?.invoice_data) {
      receiverName = cached_order.invoice_data.company_name || (cached_order.invoice_data.first_name + ' ' + cached_order.invoice_data.last_name);
    }

    return receiverName && (
      <div className="text-brand-text-grey text-tiny">
        {receiverName}
      </div>
    );
  }

  const renderButton = () => {
    return (
      <button className={container} onClick={() => setShowModal(true)}>
        <div className="w-full flex flex-row items-center">
          <div className={iconContainer}>
            <InvoiceIcon />
          </div>
          <div className="flex flex-col items-start">
            <div className="text-brand-text-default font-semibold text-mini">
              {t('labels.request_invoice')}
            </div>
            {renderDescription()}
          </div>
        </div>

        <ForwardIcon color={theme.color.text.grey} className="h-4 w-4" />
      </button>
    );
  }

  return (
    <>
      {renderButton()}

      <RequestInvoiceModal
        showModal={showModal}
        onCloseModal={() => setShowModal(false)}
      />
    </>
  );
}

const container =
  "flex flex-row w-full px-small py-xsmall border rounded border-brand-inkGrey-grey_2 items-center justify-between";

const iconContainer =
  "h-8 w-12 border border-brand-inkGrey-grey_2 rounded flex justify-center items-center mr-mini";