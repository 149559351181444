import useAuth from "../../hooks/global/useAuth";
import {useTranslation} from "react-i18next";
import useAppMethods from "../../hooks/utility/useAppMethods";
import useLoyaltyProgram from "../../hooks/loyalty/useLoyaltyProgram";
import useGoogleReviewSuggestedProducts from "../../hooks/google-review/useGoogleReviewSuggestedProducts";
import useRewards from "../../hooks/loyalty/useRewards";
import RewardStatusResource from "../../services/resources/RewardStatusResource";
import {useSelector} from "react-redux";
import {StoreInterface} from "../../store/types";
import React, {ReactElement} from "react";
import {SCREENS} from "../../services/exports/Constants";
import useNavigate from "../../hooks/navigation/useNavigate";
import LoyaltyProgress from "../progress/LoyaltyProgress";
import classnames from "classnames";

interface Props {
  className?: string;
}

export default function LoyaltyBalance(props: Props): ReactElement {
  const { t } = useTranslation(null, { keyPrefix: 'Components:LoyaltyProgram:LoyaltyBalance' });

  const { className } = props

  const { company } = useSelector((state: StoreInterface) => state.initialData);

  const { isLoggedIn } = useAuth();
  const { navigate } = useNavigate();
  const { toggleLoginModal } = useAppMethods();
  const { hasLoyaltyPromotion } = useLoyaltyProgram();
  const { message } = useGoogleReviewSuggestedProducts({ autoLoad: true });

  const { maxReward } = useRewards();
  const RewardStatus = new RewardStatusResource({
    rewards: company?.rewards,
    rewardBalance: company?.reward_balance,
  });

  const nextReward = RewardStatus.getNextReward();
  const activeReward = RewardStatus.getLastActiveReward();

  const onClick = () => {
    if (isLoggedIn) {
      return navigate(SCREENS.REWARD_DETAILS_SCREEN);
    }

    return toggleLoginModal({
      openModal: true,
      onCloseRedirect: () => null,
    })
  };

  function renderDescription(): string|ReactElement {
    // Case: Not logged in
    if (! isLoggedIn) {
      return `${t('status.not_logged_in.description', { count: maxReward?.cost })} = ${message({ type: 'reward' })}`;
    }

    // Case: Reward available and is matching reward balance or is last available reward
    if (
      activeReward &&
      (activeReward.cost === company.reward_balance || !nextReward)
    ) {
      // Case: Available Reward is free
      if (activeReward.discount === 100) {
        return (
          <span className="text-companyBrand-primary">
            {t('status.free_reward_available.description')}
          </span>
        );
      }

      // Case Available Reward is a discount
      return (
        <span className="text-companyBrand-primary">
          {t('status.discount_available.description', {
            discount: activeReward?.discount,
          })}
        </span>
      );
    }

    // Case: No available reward
    if (! nextReward) {
      return null;
    }

    // Case: Next reward is a free item
    if (nextReward.discount === 100) {
      return t('status.next_free_reward.description', {
        count: nextReward.cost - company.reward_balance,
      });
    }

    // Case: Next reward is a discount
    return t('status.next_discount.description', {
      count: nextReward?.cost - company?.reward_balance,
      discount: nextReward?.discount,
    });
  }

  return hasLoyaltyPromotion && (
    <div className={classnames('w-fit', className)}>
      {isLoggedIn ? (
        <div className="flex">
          <h5 className="my-auto mr-mini">{t('my_points')}</h5>
          <LoyaltyProgress
            progressBarSize={25}
            iconClass="!h-3.5 !w-3.5"
            renderText={(points) => (
              <h5 className="ml-tiny text-companyBrand-primary">{points}</h5>
            )}
          />
        </div>
      ) : (
        <div className="flex">
          <h5 className="my-auto">{t('loyalty_points')}</h5>
          <p className="my-auto text-tiny ml-mini">{t('already_have_points')}</p>
          <button
            onClick={onClick}
            className="text-companyBrand-primary text-tiny underline my-auto ml-tiny uppercase"
          >{t('log_in')}</button>
        </div>
      )}
      <div className="mt-small rounded-lg shadow py-small px-medium cursor-pointer" onClick={onClick}>
        <p className="text-tiny">{isLoggedIn ? renderDescription() : t('earn_points')}</p>
        <hr className="my-small"/>
        <h6>{!isLoggedIn ? renderDescription() : `${company?.reward_balance ?? 0} p`}</h6>
      </div>
    </div>
  );
}