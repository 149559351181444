import { useRef, useState } from "react";
import useFocus from "../utility/useFocus";
import useStore from "./useStore";
import { PartyInterface } from "../../services/exports/Interfaces";
import useInitialData from "./useInitialData";
import PartyResource from "../../services/resources/PartyResource";
import {useParams} from "react-router-dom";

export default function useAppFocus() {
  const { dineInFlow } = useStore();
  const { data, refresh } = useInitialData();

  const { slug } = useParams();

  const { franchise } = data;
  const { cached_order } = useStore()?.order;

  const [prevParty, setPrevParty] = useState<PartyInterface | null>(null);

  const cachedOrderRef = useRef(cached_order);
  cachedOrderRef.current = cached_order;

  const dineInFlowRef = useRef(dineInFlow);
  dineInFlowRef.current = dineInFlow;

  const prevPartyRef = useRef(prevParty);
  prevPartyRef.current = prevParty;

  const franchiseRef = useRef(franchise);
  franchiseRef.current = franchise;

  const slugRef = useRef(slug);
  slugRef.current = slug;

  useFocus(
    () => setTimeout(() => onFocus(), 50),
    () => onBlur()
    // true
  );

  async function onFocus(): Promise<void> {
    if (! slugRef.current && franchiseRef.current?.has_multiple_companies) {
      return;
    }

    await refresh(slugRef.current, true);

    if (dineInFlowRef.current?.isDineIn) {
      loadDineInData();
    } else {
      loadTakeOutData();
    }
  }

  function onBlur(): void {
    setPrevParty(dineInFlowRef.current?.party);
  }

  // --------------------------------------------------------

  function loadTakeOutData(): void {}

  //---------------------------------------------------------

  function loadDineInData(): void {
    loadParty();
  }

  async function loadParty(): Promise<void> {
    let party = dineInFlowRef.current?.party ?? prevPartyRef.current ?? null;

    PartyResource.toggleAllOrdersPaidModal(party);
  }
}
