import React, { useState, useMemo, useRef, useEffect } from "react";
import {
  ModifierInterface,
  ProductInterface,
  ModifierGroupInterface,
  RewardInterface,
} from "../../services/exports/Interfaces";
import CustomButton from "../../components-library/buttons/CustomButton";
import CounterButton from "../../components-library/buttons/CounterButton";
import CustomNavBar from "../../components-library/navigation/CustomNavBar";
import useAlert from "../../hooks/utility/useAlert";
import useScreenType from "../../hooks/utility/useScreenType";
import { MODAL_SIZES } from "../../services/exports/Constants";
import CustomModal from "../../components-library/CustomModal";
import classnames from "classnames";
import useOrderingDisabledStatus from "../../hooks/availability/useOrderingDisabledStatus";
import PriceComparison from "../../components-library/payment/PriceComparison";
import Theme from "../../components-library/Theme";
import CloseIcon from "../../assets/logo/CloseIcon";
import ProductIdentifiers from "../../components-library/menu/product/ProductIdentifiers";
import useDineInFlow from "../../hooks/global/useDineInFlow";
import useTheme from "../../hooks/ui/useTheme";
import { useTranslation } from "react-i18next";
import ButtonFooter from "../../components-library/footers/ButtonFooter";
import useAppState from "../../hooks/global/useAppState";
import useMenu from "../../hooks/menu/useMenu";
import { BasketLineItemInterface, StoreInterface } from "../../store/types";
import BasketHelpers from "../../services/helpers/BasketHelpers";
import useBasket from "../../hooks/basket/useBasket";
import TextTransformationResource from "../../services/resources/TextTransformationResource";
import collect, { Collection } from "collect.js";
import { useSelector } from "react-redux";
import useAppMethods from "../../hooks/utility/useAppMethods";
import ModifierGroupSingleSelect from "../../components-library/menu/modifier-group/ModifierGroupSingleSelect";
import ModifierGroupMultiSelect from "../../components-library/menu/modifier-group/ModifierGroupMultiSelect";
import TextArea from "../../components-library/inputs/TextArea";
import useOrderMethodsStatus from "../../hooks/availability/useOrderMethodsStatus";
import SeoResource from "../../services/resources/SeoResource";
import useGoogleAnalytics from "../../hooks/analytics/useGoogleAnalytics";
import EarnCashbackTag from "../../components-library/cashback/EarnCashbackTag";
import EarnPointsTag from "../../components-library/loyalty-program/EarnPointsTag";

const MenuItemsModal = (): JSX.Element => {
  const { t } = useTranslation(null, { keyPrefix: "Modals:MenuItemsModal" });

  const { company } = useSelector((store: StoreInterface) => store.initialData);
  const { menu, menuItemModalProps } = useAppState();
  const { screenPosition } = menu;

  const {
    isFreeItem,
    product: productProp,
    lineItem: lineItemProp,
    onCloseModal,
  } = menuItemModalProps;

  const alert = useAlert();
  const { isDesktop, isMobile } = useScreenType();
  const theme = useTheme();
  const { isDineIn } = useDineInFlow();
  const { findFreeProduct, compileProduct } = useMenu();
  const { compileItem, addItem } = useBasket();
  const { dineInCurrentlyOpen } = useOrderMethodsStatus();
  const { render: renderOrderingDisabled } = useOrderingDisabledStatus();
  const { formatCurrency, toggleMenuItemModal, toggleOrdersDisabledModal } = useAppMethods();
  const { trackViewItem } = useGoogleAnalytics();
  const hasFreeItem =
    collect(company?.rewards).last(
      (reward: RewardInterface) => reward?.is_available
    )?.discount === 100;

  const product =
    isFreeItem && hasFreeItem
      ? findFreeProduct(productProp?.id)
      : (compileProduct(productProp?.id) as ProductInterface);

  const [lineItem, setLineItem] = useState<BasketLineItemInterface>(
    lineItemProp ?? {
      product_id: product.id,
      quantity: 1,
      modifiers: [],
      note: null,
      is_free: isFreeItem && hasFreeItem,
    }
  );
  const updateLineItem = (field, value) =>
    setLineItem(
      (current): BasketLineItemInterface => ({
        ...current,
        [field]: value,
      })
    );

  const price = useMemo<number>(
    () => compileItem(lineItem).actual_price,
    [lineItem]
  );

  const [scrollY, setScrollY] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(!!product?.image_url);

  const headerRef = useRef<HTMLDivElement>(null);
  const noteRef = useRef<HTMLDivElement>(null);

  const scrollYRef = useRef<number>(scrollY);
  scrollYRef.current = scrollY;

  const [modifierGroupRefs] = useState<
    Collection<React.RefObject<HTMLDivElement>>
  >(
    collect(product?.modifier_groups ?? [])
      .keyBy("id")
      .map(() => React.createRef())
  );

  const scrollToModifierGroup = (id: number) => {
    const ref = modifierGroupRefs.get(id);

    ref.current &&
      ref.current.scrollIntoView({ block: "center", behavior: "smooth" });
  };

  const toggleModifier = (
    modifierGroup: ModifierGroupInterface,
    modifier: ModifierInterface
  ) => {
    const _lineItem = BasketHelpers.toggleModifier(
      lineItem,
      modifierGroup,
      modifier
    );
    _lineItem && setLineItem(_lineItem);
  };

  function validateInput() {
    if (isDineIn && !dineInCurrentlyOpen) {
      return toggleOrdersDisabledModal({ openModal: true });
    }

    const valid = BasketHelpers.validateLineItem(compileItem(lineItem));

    if (!valid) {
      const invalidModifierGroup = BasketHelpers.findInvalidModifierGroup(
        product,
        lineItem.modifiers
      );

      invalidModifierGroup && scrollToModifierGroup(invalidModifierGroup.id);

      return alert.error({
        description: t("error_messages.missing_fields"),
      });
    }

    addItem(lineItem);
    goBack();
  }

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 0);
  }, []);

  useEffect(() => {
    trackViewItem(product);
  }, []);

  function goBack(): void {
    closeModal();
    onCloseModal && onCloseModal();
  }

  function closeModal(): void {
    toggleMenuItemModal({
      openModal: false,
      product: null,
    });
  }

  function handleScroll(y: number): void {
    setScrollY(y);
  }

  const renderNote = useMemo<JSX.Element>(() => {
    function onFocus(): void {
      if (isDesktop) {
        setTimeout(() =>
          noteRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
          })
        );
      }
    }

    return (
      <div className="mb-small px-small">
        <h6>{t("note.title")}</h6>
        <div className="my-mini" ref={noteRef}>
          <TextArea
            value={lineItem.note}
            placeholder={t("note.placeholder")}
            onChange={(value) =>
              updateLineItem(
                "note",
                new TextTransformationResource(value).reduceToText()
              )
            }
            autoFocus={false}
            onFocus={onFocus}
          />
        </div>
      </div>
    );
  }, [lineItem.note, theme, screenPosition, isMobile, noteRef.current]);

  const renderProductInfo = (): JSX.Element => {
    return (
      <div className="mb-small px-small">
        <div className="flex justify-between">
          <h4 className="text-brand-text-default break-words mb-xsmall mr-small shrink">
            {product?.name}
          </h4>
          <div className="flex-none">
            <PriceComparison
              actualPrice={product?.actual_price}
              originalPrice={product?.original_price}
              originalPriceStyle={{
                fontSize: Theme.fonts.fontSizes.p,
              }}
              actualPriceStyle={{
                fontSize: Theme.fonts.fontSizes.p,
              }}
            />
          </div>
        </div>
        <div>
          <ProductIdentifiers product={product} className="mb-xsmall" />
          <p className={classnames("text-brand-text-grey break-words", "mini")}>
            {product?.description}
          </p>
        </div>
        <EarnPointsTag price={price} quantity={lineItem.quantity} className="mt-small" />
        <EarnCashbackTag amount={price} className="mt-small" />
      </div>
    );
  };

  const renderModifierGroup = (item: ModifierGroupInterface): JSX.Element => {
    if (item?.max_count === 1) {
      return (
        <ModifierGroupSingleSelect
          ref={modifierGroupRefs.get(item?.id)}
          modifierGroup={item}
          selectedModifiers={lineItem.modifiers}
          toggle={(modifier) => toggleModifier(item, modifier)}
        />
      );
    }

    return (
      <ModifierGroupMultiSelect
        ref={modifierGroupRefs.get(item?.id)}
        modifierGroup={item}
        selectedModifiers={lineItem.modifiers}
        toggle={(modifier) => toggleModifier(item, modifier)}
      />
    );
  };

  const renderCounterButton = (): JSX.Element => {
    return (
      <CounterButton
        count={lineItem.quantity}
        increase={() => updateLineItem("quantity", lineItem.quantity + 1)}
        decrease={() => updateLineItem("quantity", lineItem.quantity - 1)}
      />
    );
  };

  const renderAddButton = (): JSX.Element => {
    if (renderOrderingDisabled) {
      return renderOrderingDisabled;
    }

    if (!product?.is_available) {
      return (
        <div className={productUnavailableContainer}>
          <h4 className={productUnavailableTextStyle}>
            {t("error_messages.product_unavailable")}
          </h4>
        </div>
      );
    }

    if (product?.actual_price === 0) {
      return (
        <ButtonFooter className={buttonContainer}>
          <CustomButton title={t("buttons.add")} onClick={validateInput} />
        </ButtonFooter>
      );
    }

    return (
      <ButtonFooter className={addButtonContainer}>
        <div className={countButtonContainer}>{renderCounterButton()}</div>
        <div className={addButtonWrapper}>
          <CustomButton title={formatCurrency(price)} onClick={validateInput} />
        </div>
      </ButtonFooter>
    );
  };

  const renderHeaderBar = useMemo(() => {
    const shouldFadeInHeader =
      scrollYRef.current > (headerRef.current?.clientHeight ?? 0);

    if (!isDesktop && product?.image_url) {
      return (
        <button className={closeIconContainer} onClick={goBack}>
          <CloseIcon color={Theme.color.text.default} />
        </button>
      );
    }

    return (
      <div className={headerContainer} ref={headerRef}>
        {!!product?.image_url && (
          <div className={headerBackground(shouldFadeInHeader)} />
        )}

        <CustomNavBar
          rightClick={goBack}
          hideBackIcon
          transparent={!!product?.image_url}
        />
      </div>
    );
  }, [isDesktop, product?.image_url, scrollYRef.current, headerRef.current]);

  const renderProductPicture = useMemo((): JSX.Element | null => {
    return product?.image_url ? (
      <div
        className={productImageContainer}
        style={{
          marginTop: (headerRef.current?.clientHeight ?? 0) * -1.5,
        }}
      >
        <img
          src={product?.thumbnail_url}
          alt={SeoResource.toAltText(`${product?.name} image`)}
          className={productImage}
        />
        <img
          src={product?.image_url}
          alt={SeoResource.toAltText(`${product?.name} image`)}
          className={productImage}
        />
      </div>
    ) : null;
  }, [product?.image_url, headerRef.current, scrollYRef.current]);

  return (
    <CustomModal
      open={true}
      size={MODAL_SIZES.SM}
      fullScreen={!isDesktop}
      onClose={goBack}
      onScroll={handleScroll}
      loading={loading}
    >
      <div className={modalContainer}>
        {renderHeaderBar}
        {renderProductPicture}
        <div className={wrapper(!!product?.image_url)}>
          {renderProductInfo()}
          <div className="mb-small">
            {product?.modifier_groups?.map((item: ModifierGroupInterface) => (
              <div key={item?.id?.toString()}>{renderModifierGroup(item)}</div>
            ))}
          </div>
          {renderNote}
        </div>
        {renderAddButton()}
      </div>
    </CustomModal>
  );
};

export default MenuItemsModal;

const modalContainer =
  "w-full lg:h-fit h-full bg-background-inkWhite-white_1 relative";

const headerContainer = "sticky top-0 z-10 relative";

const headerBackground = (fadeIn: boolean) =>
  `bg-background-inkWhite-white_1 transition-opacity duration-500 ease-out border-b border-brand-inkGrey-grey_2 absolute inset-0 ${
    fadeIn ? "opacity-100" : "opacity-0"
  }`;

const closeIconContainer =
  "w-9 h-9 bg-background-inkWhite-white_1 shadow-icon rounded-full flex justify-center items-center fixed top-0 z-10 right-0 mr-small mt-small";

const productImageContainer = "relative w-full aspect-square";

const productImage = "object-cover absolute w-full h-full";

const wrapper = (hasOffset: boolean) =>
  `pt-small lg:pb-small pb-[100px] ${
    hasOffset
      ? "lg:-mt-[150px] -mt-[40px] border-t border-brand-inkGrey-grey_2"
      : ""
  } h-fit bg-background-inkWhite-white_1 relative`;

const buttonContainer = `lg:sticky fixed bottom-0`;

const addButtonContainer = `lg:sticky fixed bottom-0 grid grid-cols-2 gap-4 items-center`;

const countButtonContainer = "col-span-1";

const addButtonWrapper = "col-span-1";

const productUnavailableContainer =
  "lg:sticky fixed bottom-0 w-full flex flex-row items-center justify-center px-medium py-small bg-brand-inkGrey-grey_1 border-t border-brand-inkGrey-grey_2";

const productUnavailableTextStyle = "text-brand-text-grey text-center";
