import React, {useState, useEffect, useMemo} from "react";
import { useSelector } from "react-redux";
import CustomButton from "../../../components-library/buttons/CustomButton";
import CheckmarkTextInput from "../../../components-library/inputs/CheckmarkTextInput";
import useAlert from "../../../hooks/utility/useAlert";
import ProfileManager from "../../../services/api/ProfileManager";
import {useTranslation} from "react-i18next";
import {StoreInterface} from "../../../store/types";
import {ButtonTypes} from "../../../services/exports/Interfaces";

interface Props {
  onContinue: () => void;
}

const EMAIL_REGEX: RegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export default function ReceiptInfoStep(props: Props) {
  const {t} = useTranslation(null, { keyPrefix: 'Components:Checkout:Stepper:ReceiptInfoStep' });

  const { onContinue } = props;

  const alert = useAlert();
  const { profile } = useSelector((state: StoreInterface) => state.profile);

  const [firstName, setFirstName] = useState(profile?.first_name ?? "");
  const [lastName, setLastName] = useState(profile?.last_name ?? "");
  const [email, setEmail] = useState(profile?.email ?? "");
  const [loadingNoReceipt, setLoadingNoReceipt] = useState(false);
  const [loadingWithReceipt, setLoadingWithReceipt] = useState(false);

  const isFirstNameValid = useMemo<boolean>(() => firstName?.length > 0, [firstName]);
  const isLastNameValid = useMemo<boolean>(() => lastName?.length > 0, [lastName]);
  const isEmailValid = useMemo<boolean>(() => EMAIL_REGEX.test(email.toLocaleLowerCase()), [email]);
  const isValid = isFirstNameValid && isLastNameValid && isEmailValid;

  useEffect(() => {
    setFirstName(profile?.first_name ?? "");
    setLastName(profile?.last_name ?? "");
    setEmail(profile?.email ?? "");
  }, [profile]);

  function toggleLoading(shouldReceiveReceipts: boolean = true, value: boolean = true) {
    if (shouldReceiveReceipts) {
      return setLoadingWithReceipt(value);
    }

    return setLoadingNoReceipt(value);
  }

  async function updateProfile(shouldReceiveReceipts: boolean = true) {
    toggleLoading(shouldReceiveReceipts, true);
    const { success } = await ProfileManager.putProfile({
      first_name: firstName,
      last_name: lastName,
      email: email,
      should_receive_receipts: shouldReceiveReceipts,
    });
    toggleLoading(shouldReceiveReceipts, false);

    if (success) {
      return onContinue();
    }

    alert.error({
      description: t('error_messages.failed_update_profile'),
    });
  }

  function renderContinueButton() {
    return (
      <div className="mt-small">
        <CustomButton
          title={t('buttons.no_thanks')}
          buttonType={ButtonTypes.third}
          loading={loadingNoReceipt}
          onClick={() => updateProfile(false)}
        />
        <CustomButton
          title={t('buttons.send_receipt')}
          buttonType={ButtonTypes.secondary}
          loading={loadingWithReceipt}
          onClick={updateProfile}
          disabled={!isValid}
          className="mt-small"
        />
      </div>
    );
  }

  return (
    <div>
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-2 mb-small">
        <CheckmarkTextInput
          id="first-name"
          type="text"
          placeholder={t('text_inputs.first_name.placeholder')}
          value={firstName}
          isComplete={isFirstNameValid}
          onChange={setFirstName}
        />
        <CheckmarkTextInput
          id="last-name"
          type="text"
          placeholder={t('text_inputs.last_name.placeholder')}
          value={lastName}
          isComplete={isLastNameValid}
          onChange={setLastName}
        />
      </div>
      <CheckmarkTextInput
        id="email"
        type="email"
        placeholder={t('text_inputs.email.placeholder')}
        value={email}
        isComplete={isEmailValid}
        onChange={setEmail}
      />
      {renderContinueButton()}
    </div>
  );
}
