import {createGlobalStyle} from "styled-components";

const pageStyle = createGlobalStyle`
  ul.lst-kix_14qqezpp1pyb-3 {
    list-style-type: none
  }

  .lst-kix_7zadmqcdx2q7-1 > li:before {
    content: "\\0025cb   "
  }

  ul.lst-kix_14qqezpp1pyb-4 {
    list-style-type: none
  }

  ul.lst-kix_14qqezpp1pyb-1 {
    list-style-type: none
  }

  .lst-kix_7zadmqcdx2q7-0 > li:before {
    content: "\\0025cf   "
  }

  .lst-kix_7zadmqcdx2q7-2 > li:before {
    content: "\\0025a0   "
  }

  ul.lst-kix_14qqezpp1pyb-2 {
    list-style-type: none
  }

  ul.lst-kix_14qqezpp1pyb-7 {
    list-style-type: none
  }

  ul.lst-kix_14qqezpp1pyb-8 {
    list-style-type: none
  }

  ul.lst-kix_14qqezpp1pyb-5 {
    list-style-type: none
  }

  ul.lst-kix_14qqezpp1pyb-6 {
    list-style-type: none
  }

  .lst-kix_7zadmqcdx2q7-5 > li:before {
    content: "\\0025a0   "
  }

  .lst-kix_7zadmqcdx2q7-6 > li:before {
    content: "\\0025cf   "
  }

  .lst-kix_7zadmqcdx2q7-7 > li:before {
    content: "\\0025cb   "
  }

  .lst-kix_7zadmqcdx2q7-8 > li:before {
    content: "\\0025a0   "
  }

  .lst-kix_14qqezpp1pyb-8 > li:before {
    content: "\\0025a0   "
  }

  .lst-kix_14qqezpp1pyb-3 > li:before {
    content: "\\0025cf   "
  }

  .lst-kix_14qqezpp1pyb-4 > li:before {
    content: "\\0025cb   "
  }

  ul.lst-kix_7zadmqcdx2q7-7 {
    list-style-type: none
  }

  ul.lst-kix_7zadmqcdx2q7-6 {
    list-style-type: none
  }

  ul.lst-kix_7zadmqcdx2q7-8 {
    list-style-type: none
  }

  .lst-kix_14qqezpp1pyb-7 > li:before {
    content: "\\0025cb   "
  }

  .lst-kix_14qqezpp1pyb-5 > li:before {
    content: "\\0025a0   "
  }

  .lst-kix_14qqezpp1pyb-6 > li:before {
    content: "\\0025cf   "
  }

  li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt
  }

  .lst-kix_14qqezpp1pyb-0 > li:before {
    content: "\\0025cf   "
  }

  ul.lst-kix_7zadmqcdx2q7-3 {
    list-style-type: none
  }

  ul.lst-kix_7zadmqcdx2q7-2 {
    list-style-type: none
  }

  .lst-kix_7zadmqcdx2q7-4 > li:before {
    content: "\\0025cb   "
  }

  ul.lst-kix_7zadmqcdx2q7-5 {
    list-style-type: none
  }

  ul.lst-kix_7zadmqcdx2q7-4 {
    list-style-type: none
  }

  .lst-kix_7zadmqcdx2q7-3 > li:before {
    content: "\\0025cf   "
  }

  ul.lst-kix_14qqezpp1pyb-0 {
    list-style-type: none
  }

  .lst-kix_14qqezpp1pyb-1 > li:before {
    content: "\\0025cb   "
  }

  .lst-kix_14qqezpp1pyb-2 > li:before {
    content: "\\0025a0   "
  }

  ul.lst-kix_7zadmqcdx2q7-1 {
    list-style-type: none
  }

  ul.lst-kix_7zadmqcdx2q7-0 {
    list-style-type: none
  }

  ol {
    margin: 0;
    padding: 0
  }

  table td, table th {
    padding: 0
  }

  .c4 {
    border-right-style: solid;
    padding: 8pt 12pt 8pt 0pt;
    border-bottom-color: #000000;
    border-top-width: 0pt;
    border-right-width: 0pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 0pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 0pt;
    width: 59.9pt;
    border-top-color: #000000;
    border-bottom-style: solid
  }

  .c6 {
    border-right-style: solid;
    padding: 8pt 12pt 8pt 0pt;
    border-bottom-color: #000000;
    border-top-width: 0pt;
    border-right-width: 0pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 0pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 0pt;
    width: 54.7pt;
    border-top-color: #000000;
    border-bottom-style: solid
  }

  .c11 {
    border-right-style: solid;
    padding: 8pt 12pt 8pt 0pt;
    border-bottom-color: #000000;
    border-top-width: 0pt;
    border-right-width: 0pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 0pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 0pt;
    width: 98.3pt;
    border-top-color: #000000;
    border-bottom-style: solid
  }

  .c5 {
    border-right-style: solid;
    padding: 8pt 12pt 8pt 0pt;
    border-bottom-color: #000000;
    border-top-width: 0pt;
    border-right-width: 0pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 0pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 0pt;
    width: 194.4pt;
    border-top-color: #000000;
    border-bottom-style: solid
  }

  .c3 {
    border-right-style: solid;
    padding: 8pt 12pt 8pt 0pt;
    border-bottom-color: #000000;
    border-top-width: 0pt;
    border-right-width: 0pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 0pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 0pt;
    width: 60.6pt;
    border-top-color: #000000;
    border-bottom-style: solid
  }

  .c18 {
    -webkit-text-decoration-skip: none;
    color: #1155cc;
    font-weight: 400;
    text-decoration: underline;
    vertical-align: baseline;
    text-decoration-skip-ink: none;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal
  }

  .c15 {
    margin-left: 36pt;
    padding-top: 12pt;
    padding-left: 0pt;
    padding-bottom: 12pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .c1 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal
  }

  .c23 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 11pt
  }

  .c28 {
    color: #000000;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 24pt;
    font-family: "Arial";
    font-style: normal
  }

  .c16 {
    padding-top: 0pt;
    padding-bottom: 8pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .c14 {
    padding-top: 0pt;
    padding-bottom: 36pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: center
  }

  .c12 {
    padding-top: 18pt;
    padding-bottom: 4pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .c13 {
    padding-top: 12pt;
    padding-bottom: 15pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: center
  }

  .c0 {
    padding-top: 12pt;
    padding-bottom: 15pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .c17 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-family: "Arial";
    font-style: normal
  }

  .c20 {
    color: #000000;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 18pt;
    font-family: "Arial";
    font-style: normal
  }

  .c29 {
    border-spacing: 0;
    border-collapse: collapse;
    margin-right: auto
  }

  .c19 {
    text-decoration-skip-ink: none;
    -webkit-text-decoration-skip: none;
    color: #1155cc;
    text-decoration: underline
  }

  .c22 {
    background-color: #ffffff;
    max-width: 468pt;
    padding: 72pt 72pt 72pt 72pt
  }

  .c8 {
    padding: 0;
    margin: 0
  }

  .c7 {
    color: inherit;
    text-decoration: inherit
  }

  .c9 {
    height: 47.2pt
  }

  .c10 {
    font-weight: 700
  }

  .c2 {
    font-size: 9pt
  }

  .c25 {
    height: 69pt
  }

  .c24 {
    height: 80.2pt
  }

  .c26 {
    height: 25.5pt
  }

  .c27 {
    height: 58.5pt
  }

  .c21 {
    height: 36.8pt
  }

  .title {
    padding-top: 0pt;
    color: #000000;
    font-size: 26pt;
    padding-bottom: 3pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .subtitle {
    padding-top: 0pt;
    color: #666666;
    font-size: 15pt;
    padding-bottom: 16pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  li {
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
  }

  p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
  }

  h1 {
    padding-top: 20pt;
    color: #000000;
    font-size: 20pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h2 {
    padding-top: 18pt;
    color: #000000;
    font-size: 16pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h3 {
    padding-top: 16pt;
    color: #434343;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h4 {
    padding-top: 14pt;
    color: #666666;
    font-size: 12pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h5 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h6 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
  }
`;

export default pageStyle;