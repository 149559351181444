export default {
  title: 'Choose your store',
  form: {
    address: {
      placeholder: 'Enter address to find nearest location',
    },
  },
  toasts: {
    failed_to_load_stores: 'Failed to load stores, please refresh the page',
  },
};