import React from "react";
import Divider from "./Divider";

interface Props {
  children: any;
  className?: string;
  hideTopDivider?: boolean;
  hideBottomDivider?: boolean;
}

export default function Section(props: Props) {
  const { children, className, hideTopDivider, hideBottomDivider } = props;

  function renderDivider() {
    return <Divider />;
  }

  return (
    <div className={container}>
      {!hideTopDivider && renderDivider()}
      <div className={className ?? wrapper}>{children}</div>
      {!hideBottomDivider && renderDivider()}
    </div>
  );
}
const container = "w-full";

const wrapper = "p-medium";
