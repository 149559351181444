import { useRef } from "react";
import EnvironmentBanner from "../headers/EnvironmentBanner";
import { ENVIRONMENTS } from "../../services/exports/Constants";

interface Props {
  children: JSX.Element;
  header?: JSX.Element;
}

const PageLayout = (props: Props): JSX.Element => {
  const { children, header } = props;

  const headerRef = useRef<HTMLDivElement>(null);

  const showEnvBanner: boolean =
    import.meta.env.VITE_MODE !== ENVIRONMENTS.PROD;

  return (
    <>
      <div ref={headerRef} className="fixed top-0 w-full z-50" id="page-header">
        {showEnvBanner && <EnvironmentBanner />}
        {header && header}
      </div>
      <div
        style={{
          marginTop: headerRef?.current ? headerRef.current.getBoundingClientRect().height : 0,
        }}
      >
        {children}
      </div>
    </>
  );
};

export default PageLayout;
