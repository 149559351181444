import useTheme from "../../hooks/ui/useTheme";
import { ThemeInterface } from "../../services/exports/Interfaces";

interface Props {
  className?: string;
  style?: Object;
  color?: string;
}

export default function LockIcon(props: Props) {
  const { className, color, style } = props;
  const theme: ThemeInterface = useTheme();
  return (
    <svg
      className={className ?? "h-6 w-6"}
      viewBox="0 0 9 10"
      fill="none"
      style={style}
    >
      <path
        d="M1.21429 10C1.01587 10 0.847222 9.93056 0.708333 9.79167C0.569444 9.65278 0.5 9.48413 0.5 9.28571V4.11905C0.5 3.92063 0.569444 3.75198 0.708333 3.6131C0.847222 3.47421 1.01587 3.40476 1.21429 3.40476H2.04762V2.2619C2.04762 1.63492 2.26786 1.10119 2.70833 0.660714C3.14881 0.220238 3.68254 0 4.30952 0C4.93651 0 5.47024 0.220238 5.91071 0.660714C6.35119 1.10119 6.57143 1.63492 6.57143 2.2619V3.40476H7.40476C7.60317 3.40476 7.77183 3.47421 7.91071 3.6131C8.0496 3.75198 8.11905 3.92063 8.11905 4.11905V9.28571C8.11905 9.48413 8.0496 9.65278 7.91071 9.79167C7.77183 9.93056 7.60317 10 7.40476 10H1.21429ZM1.21429 9.28571H7.40476V4.11905H1.21429V9.28571ZM4.30952 7.61905C4.56349 7.61905 4.77976 7.53175 4.95833 7.35714C5.1369 7.18254 5.22619 6.97222 5.22619 6.72619C5.22619 6.4881 5.1369 6.27183 4.95833 6.07738C4.77976 5.88294 4.56349 5.78571 4.30952 5.78571C4.05556 5.78571 3.83929 5.88294 3.66071 6.07738C3.48214 6.27183 3.39286 6.4881 3.39286 6.72619C3.39286 6.97222 3.48214 7.18254 3.66071 7.35714C3.83929 7.53175 4.05556 7.61905 4.30952 7.61905ZM2.7619 3.40476H5.85714V2.2619C5.85714 1.83333 5.70635 1.46825 5.40476 1.16667C5.10317 0.865079 4.7381 0.714286 4.30952 0.714286C3.88095 0.714286 3.51587 0.865079 3.21429 1.16667C2.9127 1.46825 2.7619 1.83333 2.7619 2.2619V3.40476ZM1.21429 9.28571V4.11905V9.28571Z"
        fill={color ?? theme.color.brand.inkGrey.grey_3}
      />
    </svg>
  );
}
