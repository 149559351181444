export default {
  buttons: {
    continue: 'Weiter',
  },
  fields: {
    first_name: {
      placeholder: 'Vorname',
    },
    last_name: {
      placeholder: 'Nachname',
    },
    email: {
      label: 'Deine Email (Erhalte Updates zu deiner Bestellung, Quittung)',
      placeholder: 'Email',
    },
  },
  error_messages: {
    failed_update_profile: 'Deine persönlichen Daten konnten nicht aktualisiert werden',
  },
};
