import {
  ErrorHandlerInterface,
  OrderDataInterface,
} from "../exports/Interfaces";
import BackendApiClient from "./BackendApiClient";
import ReduxActions from "../../store/ReduxActions";
import { actionCreators } from "../../store/actions";
import {ORDER_METHODS} from "../exports/Constants";

export default class OrderValidationManager {
  public async validate(
    data: OrderDataInterface,
    errorHandlers?: ErrorHandlerInterface
  ): Promise<boolean> {
    const { success } = await BackendApiClient.request(
      {
        method: 'POST',
        url: '/order-validations',
        data,
      },
      false,
      errorHandlers
    );

    return success;
  }

  public clearDeliveryAddressRedux(data = {}): void {
    ReduxActions.dispatch(
      actionCreators.order.updateOrder({
        order_method: ORDER_METHODS.PICKUP,
        delivery_street_name: null,
        delivery_street_number: null,
        delivery_city: null,
        delivery_state: null,
        delivery_country: null,
        delivery_company_name: null,
        delivery_doorbell_name: null,
        delivery_zip_code: null,
        delivery_lat: null,
        delivery_lng: null,
        delivery_zone: null,
        ...data,
      })
    );
  }
}
