import {ORDER_FULFILLMENT_STATUSES} from '../../../../../services/exports/Constants';

export default {
  status: {
    [ORDER_FULFILLMENT_STATUSES.REJECTED]: {
      title: 'Order rejected',
    },
    [ORDER_FULFILLMENT_STATUSES.CREATED]: {
      title: 'Order received',
    },
    [ORDER_FULFILLMENT_STATUSES.REVIEWED]: {
      title: 'Your order has been seen',
    },
    [ORDER_FULFILLMENT_STATUSES.ACCEPTED]: {
      title: 'Order confirmed',
    },
  },
  waiting_confirmation: {
    title: 'Awaiting order confirmation',
    description: 'Hold tight...',
  },
  pickup_time: {
    title: 'Pickup time',
    description: 'Confirmed from {{name}}',
  },
  delivery_time: {
    title: 'Delivery time',
    description: 'Confirmed from {{name}}',
  },
};
