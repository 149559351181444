import {useEffect, useMemo, useRef, useState} from "react";
import CustomButton from "../components-library/buttons/CustomButton";
import TabBar from "../components-library/navigation/TabBar";
import RadioButton from "../components-library/buttons/RadioButton";
import TextInput from "../components-library/inputs/TextInput";
import LocationIcon from "../assets/logo/LocationIcon";
import FeedbackLabel from "../components-library/labels/tinyLabels/FeedbackLabel";
import {
  GoogleLocationInterface,
  OpeningHoursInterface,
  OrderDataInterface,
  ProfileInterface,
  RoomServiceLocation,
  ThemeInterface,
} from "../services/exports/Interfaces";
import {useSelector} from "react-redux";
import ReduxActions from "../store/ReduxActions";
import {actionCreators} from "../store/actions";
import ProfileManager from "../services/api/ProfileManager";
import HelperMethods from "../services/exports/HelperMethods";
import useCompanyHours from "../hooks/availability/useCompanyHours";
import collect from "collect.js";
import {
  DeliveryProvider,
  ERROR_CODES,
  MODAL_SIZES,
  ORDER_METHODS,
  ORDERS_PAUSED_REASON,
} from "../services/exports/Constants";
import BackIcon from "../assets/logo/BackIcon";
import useTheme from "../hooks/ui/useTheme";
import AutoComplete from "react-google-autocomplete";
import {GooglePlaceResource} from "../services/resources/GooglePlaceResource";
import CustomModal from "../components-library/CustomModal";
import useScreenType from "../hooks/utility/useScreenType";
import CustomNavBar from "../components-library/navigation/CustomNavBar";
import AuthorizationTokenStorage from "../services/auth/AuthorizationTokenStorage";
import CompanyLocation from "../components-library/company/CompanyLocation";
import AddressResource from "../services/resources/AddressResource";
import useMaps from "../hooks/location/useMaps";
import moment, {Moment} from "moment";
import PickUpTimesResource from "../services/resources/PickUpTimesResource";
import {useTranslation} from "react-i18next";
import DeliveryZonesManager from "../services/api/DeliveryZonesManager";
import useAppState from "../hooks/global/useAppState";
import {StoreInterface} from "../store/types";
import useAppMethods from "../hooks/utility/useAppMethods";
import useSessionStorage, {SESSION_STORAGE_KEYS,} from "../hooks/global/useSessionStorage";
import TextArea from "../components-library/inputs/TextArea";
import ScheduledOrderInfo from "../components-library/order/ScheduledOrderInfo";
import useOrderMethodsStatus from "../hooks/availability/useOrderMethodsStatus";
import useCompanyLocation from "../hooks/location/useCompanyLocation";
import DeliveryQuotesManager from "../services/api/DeliveryQuotesManager";
import useOpeningStatus from "../hooks/availability/useOpeningStatus";

export enum COMPLETION_STATUS {
  complete = "complete",
}

export enum TIME_OPTIONS {
  asap = 'asap',
  scheduled = 'scheduled',
}

export enum ValidationErrors {
  address_not_found = 'address_not_found',
  address_not_serviceable = 'address_not_serviceable',
  no_drivers_available = 'no_drivers_available',
}

export default function OrderMethodModal() {
  const { t } = useTranslation(null, { keyPrefix: 'Modals:OrderMethodModal' });

  const {
    openModal,
    orderMethod: orderMethodProp,
    buttonTitle,
    onComplete,
  } = useAppState().orderMethodModalProps;

  const { formatDateTime, formatTime, formatScheduleUnit, toggleOrderMethodModal } = useAppMethods();
  const theme: ThemeInterface = useTheme();
  const companyHours = useCompanyHours();
  const { isDesktop } = useScreenType();
  const { setItem } = useSessionStorage();
  const {
    visibleOrderMethods,
    takeoutCurrentlyOpen,
    takeoutOpenForFutureOrders,
    isOrderMethodCurrentlyOpen,
    isOrderMethodOpenForFutureOrders,
    renderOrderMethodBasedCopy,
  } = useOrderMethodsStatus();

  const { initialData, appStatus, order } = useSelector(
    (state: StoreInterface) => state
  );
  const { pickup_delivery_popup_seen } = appStatus;
  const { cached_order } = order;
  const { company } = initialData;

  const initialOrderMethod = orderMethodProp ?? cached_order?.method;

  const [orderMethod, setOrderMethod] = useState<ORDER_METHODS>(
    visibleOrderMethods.includes(initialOrderMethod)
      ? initialOrderMethod
      : visibleOrderMethods[0]
  );
  const [scheduleTime, setScheduleTime] = useState<Moment>(cached_order?.scheduled_for ? moment(cached_order.scheduled_for) : null);
  const [addressSearch, setAddressSearch] = useState("");
  const [roomServiceLocation, setRoomServiceLocation] = useState(
    getDefaultRoomServiceLocation()
  );
  const [roomServiceDetails, setRoomServiceDetails] = useState(
    cached_order?.room_service_details ?? null
  );
  const [streetName, setStreetName] = useState(cached_order?.delivery_street_name);
  const [streetNumber, setStreetNumber] = useState(cached_order?.delivery_street_number);
  const [city, setCity] = useState(cached_order?.delivery_city);
  const [state, setState] = useState(cached_order?.delivery_state);
  const [country, setCountry] = useState(cached_order?.delivery_country);
  const [zip, setZip] = useState(cached_order?.delivery_zip_code);
  const [lat, setLat] = useState(cached_order?.delivery_lat);
  const [lng, setLng] = useState(cached_order?.delivery_lng);
  const [orderInstructions, setOrderInstructions] = useState(cached_order?.order_instructions);

  const [loading, setLoading] = useState(false);
  const [selectedTimeInvalid, setSelectedTimeInvalid] = useState(false);
  const [validationError, setValidationError] = useState(null);
  const [useAutocompletePlace, setUseAutocompletePlace] = useState(false);
  const [place, setPlace] = useState<GooglePlaceResource | null>(null);
  const addressFilled = streetName || streetNumber || city || zip;

  const openingStatus = useOpeningStatus({ isPickup: orderMethod === ORDER_METHODS.PICKUP });

  const scheduleTimeRef = useRef(scheduleTime);
  scheduleTimeRef.current = scheduleTime;

  const hasAsapOrders = company?.has_asap_orders && isOrderMethodCurrentlyOpen(orderMethod);
  const hasScheduledOrders = company?.has_scheduled_orders || isOrderMethodOpenForFutureOrders(orderMethod);

  const [selectedTimeOption, setSelectedTimeOption] = useState(
    !hasAsapOrders || (scheduleTime && company?.has_scheduled_orders)
      ? TIME_OPTIONS.scheduled
      : TIME_OPTIONS.asap
  );
  const location = useCompanyLocation(selectedTimeOption === TIME_OPTIONS.asap ? null : scheduleTimeRef.current);
  const { openMap } = useMaps(location);

  const timeOptions = useMemo(
    () => collect()
      // @ts-ignore
      .when(hasAsapOrders, (data) => data.push({
        id: TIME_OPTIONS.asap,
        active: selectedTimeOption === TIME_OPTIONS.asap,
        title: t('time_options.asap.title'),
        description: t('time_options.asap.description', {
          time: HelperMethods.formatTimeInterval(
            orderMethod === ORDER_METHODS.DELIVERY
              ? company?.average_order_delivery_time
              : company?.average_order_preparation_time
          ),
        }),
        renderContent: () => null,
      }))
      // @ts-ignore
      .when(hasScheduledOrders, (data) => data.push({
        id: TIME_OPTIONS.scheduled,
        active: selectedTimeOption === TIME_OPTIONS.scheduled,
        title: company?.scheduled_order_option_title ?? t('time_options.scheduled.title'),
        renderContent: () => (
          <ScheduledOrderInfo
            scheduleTime={scheduleTime}
            orderMethod={orderMethod}
            setScheduleTime={setScheduleTime}
            isSelectedTimeInvalid={selectedTimeInvalid}
            setIsSelectedTimeInvalid={setSelectedTimeInvalid}
          />
        ),
      }))
      .toArray(),
    [hasAsapOrders, hasScheduledOrders, company, selectedTimeOption, scheduleTime, orderMethod, selectedTimeInvalid]
  )

  useEffect(() => {
    onDidFocus();
  }, []);

  useEffect(() => {
    selectedTimeOption === TIME_OPTIONS.asap && setSelectedTimeInvalid(false);
  }, [selectedTimeOption]);

  useEffect(() => {
    if (place) {
      setStreetName(place.getStreetName());
      setStreetNumber(place.getStreetNumber());
      setZip(place.getPostalCode());
      setCity(place.getCity());
      setState(place.getState());
      setCountry(place.getCountry());
      setLat(place.getLat());
      setLng(place.getLng());
    }
  }, [place]);

  useEffect(() => {
    if (orderMethod === ORDER_METHODS.ROOM_SERVICE && !roomServiceLocation) {
      setRoomServiceLocation(company.room_service_locations[0] ?? null);
    }
  }, [orderMethod]);

  function getDefaultRoomServiceLocation(): RoomServiceLocation {
    return (
      collect(company?.room_service_locations).firstWhere(
        "id",
        cached_order?.room_service_location_id
      ) ??
      company?.room_service_locations[0] ??
      null
    );
  }

  function onDidFocus() {
    togglePickUpDeliveryPopUpSeenStatus();
  }

  function togglePickUpDeliveryPopUpSeenStatus() {
    if (!pickup_delivery_popup_seen) {
      ReduxActions.dispatch(
        actionCreators.appStatus.setPickUpDeliveryPopupSeen(true)
      );
    }
  }

  function save() {
    if (! takeoutCurrentlyOpen && !takeoutOpenForFutureOrders) {
      return toggleModal();
    }

    if (orderMethod === ORDER_METHODS.DELIVERY) {
      return validateDeliveryDetails();
    }

    if (AuthorizationTokenStorage.getToken()) {
      return updateProfile();
    }

    cacheInfoRedux();
    toggleModal();
  }

  async function updateProfile(data: Partial<ProfileInterface> = {}) {
    setLoading(true);
    const { success } = await ProfileManager.putProfile({
      delivery_street_name: streetName,
      delivery_street_number: streetNumber,
      delivery_city: city,
      delivery_state: state,
      delivery_country: country,
      delivery_zip_code: zip,
      delivery_lat: lat,
      delivery_lng: lng,
      ...data,
    });
    setLoading(false);

    if (success) {
      cacheInfoRedux(data).then(toggleModal);
    }
  }

  function getData(data: Partial<OrderDataInterface> = {}): OrderDataInterface {
    return {
      method: orderMethod,
      scheduled_for: selectedTimeOption === TIME_OPTIONS.asap
        ? null
        : scheduleTimeRef.current,
      room_service_location_id: roomServiceLocation?.id,
      room_service_location: roomServiceLocation,
      room_service_details: roomServiceDetails,
      delivery_street_name: streetName,
      delivery_street_number: streetNumber,
      delivery_city: city,
      delivery_state: state,
      delivery_country: country,
      delivery_zip_code: zip,
      delivery_lat: lat,
      delivery_lng: lng,
      order_instructions: orderInstructions,
      ...data,
    };
  }

  async function cacheInfoRedux(data: Partial<OrderDataInterface> = {}) {
    setItem(
      SESSION_STORAGE_KEYS.order_method_info_status,
      COMPLETION_STATUS.complete
    );
    ReduxActions.dispatch(actionCreators.order.updateOrder(getData(data)));

    onComplete && setTimeout(() => onComplete(), 10);
  }

  async function getLocation(): Promise<
    GoogleLocationInterface<google.maps.LatLng>
  > {
    if (place && useAutocompletePlace) {
      return {
        success: true,
        location: place.getCoordinates(),
      };
    }

    return await new GooglePlaceResource().getLocation({
      streetName,
      streetNumber,
      zip,
      city,
      state: company.state,
      country: company.country,
    });
  }

  async function validateDeliveryDetails() {
    if (company?.delivery_provider === DeliveryProvider.Standalone) {
      return await getDeliveryZone();
    }

    return await getDeliveryQuote();
  }

  async function getDeliveryQuote() {
    setValidationError(null);
    setLoading(true);
    const { success: deliveryQuoteSuccess } = await DeliveryQuotesManager.create(company?.id, {
        dropoff_time: scheduleTimeRef.current,
        street_name: streetName,
        street_number: streetNumber,
        city: city,
        state: state,
        country: country,
        zip_code: zip,
        lat,
        lng,
      },
      {
        [ERROR_CODES.NO_DELIVERY_QUOTES_AVAILABLE]: handleNoDeliveryQuotesAvailable,
        [ERROR_CODES.ADDRESS_OUTSIDE_DELIVERY_ZONE]: handleAddressOutsideDeliveryZone,
      }
    );
    setLoading(false);

    if (!deliveryQuoteSuccess) {
      return;
    }

    await getDeliveryZone();
  }

  async function getDeliveryZone() {
    setValidationError(null);
    setLoading(true);
    const { success, location } = await getLocation();

    if (!success) {
      setLoading(false);

      return handleAddressNotFound();
    }

    setLat(location?.lat());
    setLng(location?.lng());

    const { success: deliveryZoneSearchSuccess } = await DeliveryZonesManager.search({
        company_id: company.id,
        lat: location?.lat(),
        lng: location?.lng(),
        zip,
      },
      {
        [ERROR_CODES.ADDRESS_NOT_FOUND]: handleAddressNotFound,
        [ERROR_CODES.ADDRESS_OUTSIDE_DELIVERY_ZONE]: handleAddressOutsideDeliveryZone,
      }
    );

    setLoading(false);

    if (!deliveryZoneSearchSuccess) {
      return;
    }

    if (AuthorizationTokenStorage.getToken()) {
      return updateProfile({
        delivery_lat: location?.lat(),
        delivery_lng: location?.lng(),
      });
    }

    cacheInfoRedux({
      delivery_lat: location?.lat(),
      delivery_lng: location?.lng(),
    });
    toggleModal();
  }

  const handleAddressNotFound = () => setValidationError(ValidationErrors.address_not_found);
  const handleAddressOutsideDeliveryZone = () => setValidationError(ValidationErrors.address_not_serviceable);
  const handleNoDeliveryQuotesAvailable = () => setValidationError(ValidationErrors.no_drivers_available);

  function toggleModal(): void {
    toggleOrderMethodModal({ openModal: false });
  }

  const cachePlace = (place) => {
    setPlace(new GooglePlaceResource(place));
    setUseAutocompletePlace(true);
    setAddressSearch(null);
  };

  const changeAddressField = (value: string, callback) => {
    callback(value);
    setUseAutocompletePlace(false);
  };

  const onSelectRoomServiceLocation = (id: string) =>
    setRoomServiceLocation(
      collect(company?.room_service_locations).firstWhere("id", parseInt(id))
    );

  const renderOptions = (): JSX.Element[] => {
    return timeOptions.map((item, index) => (
      <div key={`time-option-${item.id}`}>
        <div className={optionContainer} onClick={() => setSelectedTimeOption(item.id)}>
          <div className="mr-small">
            <RadioButton active={item.active} />
          </div>
          <div className={optionWrapper}>
            <p>{item.title}</p>
            {item?.description && (
              <p className="mt-mini text-brand-text-grey">
                {item?.description}
              </p>
            )}
          </div>
        </div>
        {timeOptions.length - 1 !== index && (
          <div className="my-small border-b border-brand-inkGrey-grey_2 w-full" />
        )}
        {item.active && item.renderContent()}
      </div>
    ));
  };

  const renderDeliveryNotAvailable = (): JSX.Element => {
    const getText = () => {
      if (!company?.has_delivery) {
        return t("error_messages.delivery_unavailable");
      }

      if (!company?.delivery_enabled) {
        return t("error_messages.delivery_paused");
      }
    };

    return (
      <div className={wrapper}>
        <div className={childWrapper}>
          <h4 className={closedOpeningHoursContainerTitle}>{getText()}</h4>
        </div>
      </div>
    );
  };

  const renderOrderMethodNotAvailable = (title): JSX.Element => {
    return (
      <div className={wrapper}>
        <div className={childWrapper}>
          <h4 className={closedOpeningHoursContainerTitle}>{title}</h4>
        </div>
      </div>
    );
  };

  const renderClosed = (
    title: string,
    schedule: OpeningHoursInterface[]
  ): JSX.Element => (
    <div className={wrapper}>
      <div className={childWrapper}>
        <h4 className={closedOpeningHoursContainerTitle}>{title}</h4>
        {schedule?.map((openHours: OpeningHoursInterface, index) => (
          <div
            className="flex flex-row justify-between items-center"
            key={`work-schedule-item-${index}`}
          >
            <p className={closedOpeningHoursContainerTitle}>
              {formatScheduleUnit(openHours?.open, { day: true, time: false })}
            </p>
            <p className={closedOpeningHoursContainerTitle}>
              {formatScheduleUnit(openHours?.open, { day: false })}
              {' - '}
              {formatScheduleUnit(openHours?.close, { day: false })}
            </p>
          </div>
        ))}
      </div>
    </div>
  );

  const renderRoomService = (): JSX.Element => {
    if (companyHours.pickupClosed && ! isOrderMethodOpenForFutureOrders(orderMethod)) {
      return renderClosed(
        t('error_messages.outside_pickup_times'),
        company?.work_schedule
      );
    }

    if (! isOrderMethodCurrentlyOpen(orderMethod) && ! isOrderMethodOpenForFutureOrders(orderMethod)) {
      return renderNotAvailableReason();
    }

    return (
      <div className={wrapper}>
        <div className={childWrapper}>
          <div className="my-medium">
            <h4 className="mb-small">{t("room_service.location")}</h4>
            <div className={selectTimeContainer}>
              <select
                value={roomServiceLocation?.id}
                onChange={(e) => onSelectRoomServiceLocation(e.target.value)}
                className={selectTimeWrapper}
              >
                {company.room_service_locations?.map((item) => (
                  <option
                    key={`room-service-location-${item.id}`}
                    value={item.id}
                  >{`${item.name} - ${item.street_name} ${item.street_number}`}</option>
                ))}
              </select>
              <BackIcon className={backArrowIcon} />
            </div>
            {!company?.table_number_hidden && (
              <div className="mt-small">
                <TextInput
                  id="room-service-details"
                  value={roomServiceDetails ?? ""}
                  placeholder={t(
                    `text_inputs.room_service_details.placeholder.${roomServiceLocation?.type}`
                  )}
                  onChange={setRoomServiceDetails}
                  containerStyle={{
                    borderColor: !streetName
                      ? theme.color.brand.danger
                      : undefined,
                  }}
                  inputContainerStyle="px-mini"
                  className="placeholder:text-brand-danger"
                  autoFocus
                />
              </div>
            )}
          </div>
          <h5 className={subHeaderStyle}>{t("pickup.when")}</h5>
          {renderOptions()}
        </div>
        {renderOrderInstructions()}
      </div>
    );
  };

  const renderPickup = (): JSX.Element => {
    if (companyHours?.pickupClosed && ! isOrderMethodOpenForFutureOrders(orderMethod)) {
      return renderClosed(
        t("error_messages.outside_pickup_times"),
        company?.work_schedule
      );
    }

    if (! isOrderMethodCurrentlyOpen(orderMethod) && ! isOrderMethodOpenForFutureOrders(orderMethod)) {
      return renderNotAvailableReason();
    }

    return (
      <div className={wrapper}>
        <div className={childWrapper}>
          <div className="my-medium">
            <h4>{t("pickup.address")}</h4>
            <div className="cursor-pointer" onClick={openMap}>
              <h6 className="mt-small font-normal">{company?.name}</h6>
              <h6 className="mt-small font-normal text-brand-inkGrey-grey_4 underline">
                {AddressResource.compileAddress(location ?? company)}
              </h6>
            </div>
          </div>
          <h5 className={subHeaderStyle}>{t("pickup.when")}</h5>
          {renderOptions()}
        </div>
        {renderOrderInstructions()}
      </div>
    );
  };

  const renderDelivery = (): JSX.Element => {
    if (companyHours?.deliveryClosed && ! isOrderMethodOpenForFutureOrders(orderMethod)) {
      return renderClosed(
        t("error_messages.outside_delivery_times"),
        company?.delivery_schedule
      );
    }

    if (! isOrderMethodCurrentlyOpen(orderMethod) && ! isOrderMethodOpenForFutureOrders(orderMethod)) {
      return renderNotAvailableReason();
    }

    return (
      <div className={viewsWrapper}>
        <div className={childWrapper}>
          <h4 className={subHeaderStyle}>{t("delivery.where")}</h4>
          <div>
            <div
              className={locationInputContainer}
              style={{ gap: theme.dimension.padding.small }}
            >
              <div className="col-span-3">
                <div className="placeholder:text-grey text-brand-text-default border rounded border-brand-inkGrey-grey_2 flex flex-row px-small py-mini items-center">
                  <LocationIcon
                    color={theme.color.text.grey}
                    style={{ marginRight: theme.dimension.padding.mini }}
                  />

                  <AutoComplete
                    apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}
                    onPlaceSelected={(place) => cachePlace(place)}
                    // @ts-ignore
                    onChange={(e) => setAddressSearch(e.target.value)}
                    // @ts-ignore
                    value={addressSearch ?? ""}
                    placeholder={t("text_inputs.address.placeholder")}
                    className="w-full"
                    options={{
                      types: [],
                      componentRestrictions: {
                        country: company.country,
                      },
                    }}
                  />
                </div>
              </div>
              {addressFilled && (
                <>
                  <h4 className="col-span-3">{t("delivery.address")}</h4>
                  <div className="col-span-2">
                    <TextInput
                      id="street-name"
                      value={streetName ?? ""}
                      placeholder={t("text_inputs.street_name.placeholder")}
                      onChange={(value) =>
                        changeAddressField(value, setStreetName)
                      }
                      containerStyle={{
                        borderColor: !streetName
                          ? theme.color.brand.danger
                          : undefined,
                      }}
                      className="placeholder:text-brand-danger"
                    />
                  </div>
                  <div className="col-span-1">
                    <TextInput
                      id="street-number"
                      value={streetNumber ?? ""}
                      placeholder={t("text_inputs.street_number.placeholder")}
                      onChange={(value) =>
                        changeAddressField(value, setStreetNumber)
                      }
                      containerStyle={{
                        borderColor: !streetNumber
                          ? theme.color.brand.danger
                          : undefined,
                      }}
                      className="placeholder:text-brand-danger"
                    />
                  </div>
                  <div className="col-span-2">
                    <TextInput
                      id="city"
                      value={city ?? ""}
                      placeholder={t("text_inputs.city.placeholder")}
                      onChange={(value) => changeAddressField(value, setCity)}
                      containerStyle={{
                        borderColor: !city
                          ? theme.color.brand.danger
                          : undefined,
                      }}
                      className="placeholder:text-brand-danger"
                    />
                  </div>
                  <div className="col-span-1">
                    <TextInput
                      id="zip"
                      value={zip ?? ""}
                      placeholder={t("text_inputs.zip.placeholder")}
                      type="number"
                      autoComplete="postal-code"
                      onChange={(value) => changeAddressField(value, setZip)}
                      containerStyle={{
                        borderColor: !zip
                          ? theme.color.brand.danger
                          : undefined,
                      }}
                      className="placeholder:text-brand-danger"
                    />
                  </div>
                </>
              )}
            </div>
            {validationError && (
              <div className="mb-small">
                <FeedbackLabel
                  message={t(`error_messages.${validationError}`)}
                  type="Error"
                />
              </div>
            )}
            {validationError === ValidationErrors.address_not_serviceable && company?.delivery_provider === DeliveryProvider.Standalone && renderMap()}
          </div>
          <h4 className={subHeaderStyle}>{t("delivery.when")}</h4>
          {renderOptions()}
        </div>
        {renderOrderInstructions()}
      </div>
    );
  };

  function renderCompanyClosedDescription() {
    //Company is not open
    if (!companyHours?.open) {
      return t("error_messages.company_closed");
    }

    if (companyHours?.ordersPausedReason === ORDERS_PAUSED_REASON.INACTIVITY) {
      return t("error_messages.orders_paused_inactivity");
    }

    //Company was manually paused
    if (companyHours?.ordersPausedReason === ORDERS_PAUSED_REASON.MANUAL) {
      //Company orders are paused till the end of the day
      if (companyHours?.orderArePausedTillEndOfDay) {
        return t("error_messages.orders_paused_manual_end_of_day");
      }

      //Company orders are not paused till the end of the day
      return t("error_messages.orders_paused_manual_until_time", {
        pausedUntil: formatTime(companyHours?.ordersPausedUntil),
      });
    }

    if (!company?.has_live_orders) {
      return t("error_messages.live_orders_off");
    }

    if (! isOrderMethodCurrentlyOpen(orderMethod)) {
      const time = PickUpTimesResource.createDate(
        orderMethod === ORDER_METHODS.DELIVERY ? company.next_delivery_schedule?.open : company?.next_work_schedule?.open
      );

      return t("error_messages.orders_unavailable", {
        type:
          orderMethod === ORDER_METHODS.DELIVERY
            ? t("labels.delivery")
            : t("labels.pickup"),
        typeTime:
          orderMethod === ORDER_METHODS.DELIVERY
            ? t("labels.delivery_time")
            : t("labels.pickup_time"),
        day: formatDateTime(time, { format: 'dddd' }),
        time: formatTime(time),
      });
    }

    return null;
  }

  const renderNotAvailableReason = (): JSX.Element => {
    return (
      <div className={closedOpeningHoursContainer}>
        <h4 className={closedOpeningHoursContainerTitle}>{openingStatus.details_text}</h4>
        <p className={closedOpeningHoursContainerDescription}>
          {renderCompanyClosedDescription()}
        </p>
      </div>
    );
  };

  function renderViews() {
    switch (orderMethod) {
      case ORDER_METHODS.DELIVERY:
        return company?.has_delivery && company?.delivery_enabled
          ? renderDelivery()
          : renderDeliveryNotAvailable();
      case ORDER_METHODS.ROOM_SERVICE:
        return company?.has_room_service
          ? renderRoomService()
          : renderOrderMethodNotAvailable(t("error_messages.room_service_unavailable"));
      default:
        return company?.has_pickup
          ? renderPickup()
          : renderOrderMethodNotAvailable(t("error_messages.pickup_unavailable"));
    }
  }

  const renderOrderInstructions = (): JSX.Element => {
    if (
      !company?.has_order_instructions ||
      !company?.order_instructions_title?.length
    ) {
      return null;
    }

    return (
      <div className={childWrapper}>
        <div className="my-medium">
          <h4 className="mb-mini">{t("order.instructions")}</h4>
          <div className="mb-small">
            <p className="mini text-brand-text-grey mb-small">
              {company?.order_instructions_title}
            </p>
          </div>
          <TextArea
            value={orderInstructions}
            onChange={setOrderInstructions}
            placeholder={t("order.placeholder")}
            textClassName="mini"
            maxLength={160}
          />
        </div>
      </div>
    );
  };

  const renderButton = (): JSX.Element => {
    function getTitle() {
      if (buttonTitle) {
        return buttonTitle;
      }

      if (! takeoutCurrentlyOpen && !takeoutOpenForFutureOrders) {
        return t("custom_button.go_to_menu");
      }

      return t("custom_button.start_order");
    }

    function isComplete(conditions: boolean[]): boolean {
      return collect(conditions)?.doesntContain(false);
    }

    function getDisabledStatus(): boolean {
      if (! isOrderMethodCurrentlyOpen(orderMethod) && ! isOrderMethodOpenForFutureOrders(orderMethod)) {
        return true;
      }

      if (orderMethod === ORDER_METHODS.ROOM_SERVICE) {
        return !isComplete([
          !!roomServiceLocation,
          !selectedTimeInvalid,
          !company?.table_number_hidden ? !!roomServiceDetails : true,
        ]);
      }

      if (orderMethod === ORDER_METHODS.DELIVERY) {
        return !isComplete([
          !!company?.has_delivery,
          !!company?.delivery_enabled,
          !selectedTimeInvalid,
          !!streetName,
          !!streetNumber,
          !!city,
          !!zip,
        ]);
      }

      return selectedTimeInvalid;
    }

    return (
      <div className={buttonContainer}>
        <CustomButton
          testId="start-order-button"
          title={getTitle()}
          loading={loading}
          onClick={save}
          disabled={getDisabledStatus()}
        />
      </div>
    );
  };

  const renderMap = (): JSX.Element => {
    return (
      <div>
        <h4 className="mb-small">{t("delivery.delivery_area")}</h4>
        <CompanyLocation data={company} className="h-80 mb-small" />
      </div>
    );
  };

  return (
    <CustomModal
      open={openModal}
      size={MODAL_SIZES.SM}
      fullScreen={!isDesktop}
      onClose={toggleModal}
    >
      <div className={container}>
        <div className={headerContainer}>
          <CustomNavBar
            title={renderOrderMethodBasedCopy(orderMethod, t, 'header')}
            hideBackIcon
            transparent
            rightClick={toggleModal}
          />
          <div className={wrapper}>
            <TabBar
              value={orderMethod}
              items={visibleOrderMethods.map((item) => ({
                label: renderOrderMethodBasedCopy(item, t, 'order_methods'),
                value: item,
              }))}
              onClick={setOrderMethod}
            />
          </div>
        </div>
        {renderViews()}
        {renderButton()}
      </div>
    </CustomModal>
  );
}

const container = "w-full bg-background-inkWhite-white_1 lg:pb-none pb-[100px]";

const headerContainer = "sticky top-0 bg-background-inkWhite-white_1 z-10";

const wrapper = "lg:px-medium px-small";

const viewsWrapper = "lg:px-medium px-small";

const childWrapper = "my-small";

const optionContainer = "flex flex-row cursor-pointer";

const optionWrapper = "w-full";

const selectTimeContainer =
  "w-full flex flex-row items-center p-mini border border-brand-inkGrey-grey_2 rounded appearance-none bg-background-inkWhite-white_1";

const selectTimeWrapper = "appearance-none w-full bg-transparent";

const locationInputContainer = "grid grid-cols-3 mb-small";

const buttonContainer = `w-full flex justify-center items-center lg:px-medium px-small py-mini border-t border-brand-inkGrey-grey_2 lg:relative lg:sticky fixed bottom-0 bg-background-inkWhite-white_1`;

const closedOpeningHoursContainer = "py-small px-medium";

const closedOpeningHoursContainerTitle = "mb-mini";

const closedOpeningHoursContainerDescription = "text-brand-danger";

const backArrowIcon = "lg:h-3.5 lg:w-3.5 md:w-3 md:h-3 w-2 h-2 -rotate-90";

const subHeaderStyle = "mb-small";
