import useTheme from "../../hooks/ui/useTheme";
import {
  AVAILABLE_LANGUAGES,
  ENVIRONMENTS,
} from "../../services/exports/Constants";
import { AvailableLanguageInterface } from "../../services/exports/Interfaces";
import AuthorizationManager from "../../services/auth/AuthorizationManager";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import LoyaltyProgress from "../progress/LoyaltyProgress";

const CompanyHeader = (): JSX.Element => {
  const { i18n } = useTranslation();

  const theme = useTheme();

  async function logout(): Promise<void> {
    const success = await AuthorizationManager.logout();
    if (success) {
      window.location.reload();
    }
  }

  const renderLanguages = (): JSX.Element => {
    const isActive = (item: AvailableLanguageInterface) => {
      return item?.language === i18n.language;
    };

    return import.meta.env.VITE_MODE !== ENVIRONMENTS.PROD ? (
      <div className={languageContainer}>
        {AVAILABLE_LANGUAGES?.map((item: AvailableLanguageInterface) => {
          const active = isActive(item);
          return (
            <button
              key={item?.language}
              className={languageItemContainer}
              style={{
                backgroundColor: active
                  ? theme.color.companyBrand.primary
                  : null,
              }}
              onClick={() => i18next.changeLanguage(item?.language)}
            >
              <p
                style={{
                  color: active
                    ? theme.color.text.light
                    : theme.color.text.default,
                }}
                className={languageStyle}
              >
                {item?.language}
              </p>
            </button>
          );
        })}
      </div>
    ) : null;
  };

  const renderLogoutButton = (): JSX.Element | null => {
    return import.meta.env.VITE_MODE !== ENVIRONMENTS.PROD ? (
      <button onClick={logout} className="mx-mini text-xtiny">
        Log out
      </button>
    ) : null;
  };

  return (
    <div>
      <div className={container}>
        {renderLanguages()}
        {renderLogoutButton()}
      </div>
    </div>
  );
};

export default CompanyHeader;

const container =
  "flex lg:px-medium px-small py-xsmall justify-end items-center lg:border lg:border-brand-inkGrey-grey_2 bg-background-inkWhite-white_1 shadow-section";

const languageContainer = "flex mr-tiny";

const languageItemContainer = "mr-tiny rounded px-tiny py-xTiny text-xtiny";

const languageStyle = "semibold tiny uppercase";
