export default {
  buttons: {
    continue: 'Continue',
  },
  fields: {
    first_name: {
      placeholder: 'First name',
    },
    last_name: {
      placeholder: 'Last name',
    },
    email: {
      label: 'Your Email (Receive order updates, receipt)',
      placeholder: 'Email',
    },
  },
  error_messages: {
    failed_update_profile: 'Failed to update your personal information',
  },
};
