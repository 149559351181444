import { useMemo, useState, useEffect, useRef } from "react";
import RewardStatus from "../components-library/rewards/RewardStatus";
import Divider from "../components-library/Divider";
import LoadingPage from "../components-library/loading/LoadingPage";
import { DividerSize } from "../components-library/Divider";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import Section from "../components-library/Section";
import useLoyaltyProgram from "../hooks/loyalty/useLoyaltyProgram";
import useInitialData from "../hooks/global/useInitialData";
import CheckoutNavBar from "../components-library/navigation/CheckoutNavBar";
import ProductsCarousel from "../components-library/carousel/ProductsCarousel";
import useGoogleReviewSuggestedProducts from "../hooks/google-review/useGoogleReviewSuggestedProducts";
import { useLocation } from "react-router-dom";
import { SCREENS } from "../services/exports/Constants";
import useNavigate from "../hooks/navigation/useNavigate";
import useRewards from "../hooks/loyalty/useRewards";
import ButtonFooter from "../components-library/footers/ButtonFooter";
import CustomButton from "../components-library/buttons/CustomButton";
import useDineInFlow from "../hooks/global/useDineInFlow";
import PageLayout from "../components-library/layouts/PageLayout";
import useAppMethods from "../hooks/utility/useAppMethods";

interface LocationState {
  prevPage?: SCREENS;
}

const RewardDetailsScreen = (): JSX.Element => {
  const { t, i18n } = useTranslation(null, { keyPrefix: "Pages:RewardDetailsScreen" });

  const { navigate } = useNavigate();
  const location = useLocation();
  const { data, refresh } = useInitialData();
  const { loyalty } = useLoyaltyProgram();
  const { formatCurrency } = useAppMethods();
  const { availableReward } = useRewards();
  const { isDineIn } = useDineInFlow();
  const { suggestedProducts } = useGoogleReviewSuggestedProducts({
    autoLoad: true,
  });

  const locationState: LocationState = location.state;
  const { company } = data;

  const [loading, setLoading] = useState<boolean>(true);

  const companyRef = useRef(company);
  companyRef.current = company;

  useEffect(() => {
    initScreen();
  }, []);

  async function initScreen(): Promise<void> {
    setLoading(true);
    await refresh();
    setTimeout(() => setLoading(false), 100);
  }

  function renderDivider(size?: DividerSize): JSX.Element {
    return (
      <div className={dividerContainer}>
        <Divider size={size} />
      </div>
    );
  }

  function renderSeparator(): JSX.Element {
    return <div className={separatorContainer} />;
  }

  function getMessage({
    defaultMessage,
    message_en,
    message_de,
  }: {
    defaultMessage: string;
    message_en: string;
    message_de: string;
  }): string {
    switch (i18n.language) {
      case "de":
        return message_de ?? defaultMessage;
      case "en":
        return message_en ?? defaultMessage;
      default:
        return defaultMessage;
    }
  }

  const renderRewardStatus = (): JSX.Element => {
    return (
      <Section className={reviewStatusContainer} hideTopDivider>
        <RewardStatus
          rewards={companyRef.current?.rewards}
          points={companyRef.current?.reward_balance}
          hideTitle
          hideDescription
        />
      </Section>
    );
  };

  const renderProductCarousel = (): JSX.Element | null => {
    return suggestedProducts?.length > 0 ? (
      <>
        <div className={classnames(innerWrapper, "lg:px-small px-none")}>
          {renderDivider()}
        </div>
        <div className={innerWrapper}>
          <h6>{t("free_products.title")}</h6>
          {renderSeparator()}
          <ProductsCarousel products={suggestedProducts} hideTitle />
        </div>
      </>
    ) : null;
  };

  const renderInfo = (): JSX.Element | null => {
    function showSecondDefaultMessage(): string {
      const defaultMessage: string = t("redeem_points");

      switch (i18n.language) {
        case "de":
          return !companyRef.current?.rewards_info_description_de
            ? defaultMessage
            : null;
        case "en":
          return !companyRef.current?.rewards_info_description_en
            ? defaultMessage
            : null;
        default:
          return null;
      }
    }

    return (
      <>
        {renderSeparator()}
        <p className={infoDescription}>
          {getMessage({
            defaultMessage: t("earn_points", {
              threshold: formatCurrency(loyalty?.reward_threshold),
            }),
            message_de: companyRef.current?.rewards_info_description_de,
            message_en: companyRef.current?.rewards_info_description_en,
          })}
        </p>
        {showSecondDefaultMessage() && (
          <>
            {renderSeparator()}
            <p className={infoDescription}>{showSecondDefaultMessage()}</p>
          </>
        )}
      </>
    );
  };

  const renderLoadingPage = useMemo(() => {
    return loading ? <LoadingPage /> : null;
  }, [loading]);

  const renderRedeemRewardsButton = useMemo(() => {
    return (
      availableReward && (
        <ButtonFooter className="fixed bottom-0">
          <div className="lg:w-1/2 mx-auto">
            <CustomButton
              title={t("redeem_reward")}
              onClick={() =>
                navigate(isDineIn ? SCREENS.MENU : SCREENS.HOME_SCREEN)
              }
            />
          </div>
        </ButtonFooter>
      )
    );
  }, [availableReward, isDineIn]);

  return (
    <PageLayout
      header={
        <header>
          <CheckoutNavBar
            title={""}
            showLogoOnMobile
            goBack={
              locationState?.prevPage
                ? () => navigate(locationState?.prevPage, { absolute: true })
                : undefined
            }
          />
        </header>
      }
    >
      <>
        <main className={wrapper}>
          <div className={innerWrapper}>
            {renderSeparator()}
            <h6>
              {getMessage({
                defaultMessage: t("rewards"),
                message_de: companyRef.current?.rewards_info_header_de,
                message_en: companyRef.current?.rewards_info_header_en,
              })}
            </h6>
            {renderSeparator()}
            {renderInfo()}
            {renderSeparator()}
            {renderRewardStatus()}
          </div>
          {renderProductCarousel()}
          {renderRedeemRewardsButton}
        </main>
        {renderLoadingPage}
      </>
    </PageLayout>
  );
};

export default RewardDetailsScreen;

const wrapper = "flex flex-col items-center pb-[100px]";

const innerWrapper = "lg:w-1/2 w-full lg:px-0 px-small";

const reviewStatusContainer =
  "p-small bg-background-inkWhite-white_0 rounded-xl shadow";

const dividerContainer = "my-medium w-full";

const separatorContainer = "mb-small";

const infoDescription =
  "break-words text-brand-text-grey mini whitespace-pre-line";
