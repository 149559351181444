import ApplePayValidator from "./ApplePayValidator";
import GooglePayValidator from "./GooglePayValidator";

interface Props {
  children?: JSX.Element;
}

export default function PaymentWalletsValidator(props: Props): JSX.Element {
  const { children } = props;

  return (
    <>
      <ApplePayValidator>{children}</ApplePayValidator>
      <GooglePayValidator>{children}</GooglePayValidator>
    </>
  );
}
