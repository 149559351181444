import React, { useState, useMemo, useRef, useEffect } from "react";
import {
    ModifierInterface,
    ProductComboItemInterface,
    ProductInterface,
    ModifierGroupInterface,
} from "../../services/exports/Interfaces";
import CustomButton from "../../components-library/buttons/CustomButton";
import CustomNavBar from "../../components-library/navigation/CustomNavBar";
import useAlert from "../../hooks/utility/useAlert";
import useScreenType from "../../hooks/utility/useScreenType";
import classnames from "classnames";
import useOrderingDisabledStatus from "../../hooks/availability/useOrderingDisabledStatus";
import Theme from "../../components-library/Theme";
import ProductIdentifiers from "../../components-library/menu/product/ProductIdentifiers";
import { useTranslation } from "react-i18next";
import ButtonFooter from "../../components-library/footers/ButtonFooter";
import BasketHelpers from "../../services/helpers/BasketHelpers";
import useDineInFlow from "../../hooks/global/useDineInFlow";
import BackIcon from "../../assets/logo/BackIcon";
import { BasketLineItemInterface } from "../../store/types";
import useBasket from "../../hooks/basket/useBasket";
import useAppMethods from "../../hooks/utility/useAppMethods";
import collect, { Collection } from "collect.js";
import ModifierGroupSingleSelect from "../../components-library/menu/modifier-group/ModifierGroupSingleSelect";
import ModifierGroupMultiSelect from "../../components-library/menu/modifier-group/ModifierGroupMultiSelect";
import useOrderMethodsStatus from "../../hooks/availability/useOrderMethodsStatus";
import SeoResource from "../../services/resources/SeoResource";

interface Props {
    data: ProductInterface;
    lineItemData: null | BasketLineItemInterface;
    comboItem: ProductComboItemInterface;
    updateComboItem: (id: number, data: Partial<BasketLineItemInterface>) => void;
    goBack: () => void;
}

export default function ComboItemProductContent(props: Props): JSX.Element {
    const { t } = useTranslation(null, { keyPrefix: "Modals:Combo:ComboItemProductContent" });

    const { data, lineItemData, comboItem, updateComboItem, goBack } = props;
    const defaultProduct = collect(comboItem.products).first();
    const extraPrice = data.actual_price - defaultProduct.actual_price;

    const alert = useAlert();
    const { isDesktop } = useScreenType();
    const { isDineIn } = useDineInFlow();
    const { dineInCurrentlyOpen } = useOrderMethodsStatus();
    const { render: renderOrderingDisabled } = useOrderingDisabledStatus();
    const { formatCurrency, toggleOrdersDisabledModal } = useAppMethods();
    const { compileItem } = useBasket();

    const [lineItem, setLineItem] = useState<BasketLineItemInterface>(
        lineItemData?.product_id === data.id
            ? lineItemData
            : {
                  product_id: data.id,
                  combo_item_id: comboItem.id,
                  quantity: 1,
                  note: null,
                  modifiers: [],
              },
    );

    const headerRef = useRef<HTMLDivElement>(null);
    const bodyRef = useRef<HTMLDivElement>(null);

    const scrollYRef = useRef<number>(scrollY);
    scrollYRef.current = scrollY;

    const [modifierGroupRefs] = useState<Collection<React.RefObject<HTMLDivElement>>>(
        collect(data?.modifier_groups ?? [])
            .keyBy("id")
            .map(() => React.createRef()),
    );

    useEffect(() => {
        bodyRef.current?.scrollIntoView({ block: "center" });
    }, []);

    const scrollToModifierGroup = (id) => {
        const ref = modifierGroupRefs.get(id);

        ref.current && ref.current.scrollIntoView({ block: "center", behavior: "smooth" });
    };

    const toggleModifier = (modifierGroup: ModifierGroupInterface, modifier: ModifierInterface) => {
        const _lineItem = BasketHelpers.toggleModifier(lineItem, modifierGroup, modifier);
        _lineItem && setLineItem(BasketHelpers.toggleModifier(lineItem, modifierGroup, modifier));
    };

    function onAddProduct() {
        if (isDineIn && !dineInCurrentlyOpen) {
            return toggleOrdersDisabledModal({ openModal: true });
        }

        const valid = BasketHelpers.validateLineItem(compileItem(lineItem));

        if (!valid) {
            const invalidModifierGroup = BasketHelpers.findInvalidModifierGroup(data, lineItem.modifiers);

            invalidModifierGroup && scrollToModifierGroup(invalidModifierGroup.id);

            return alert.error({
                description: t("error_messages.missing_fields"),
            });
        }

        updateComboItem(comboItem.id, lineItem);
        goBack();
    }

    const renderProductInfo = (): JSX.Element => {
        return (
            <div className="mb-small px-small">
                <div className="flex justify-between">
                    <h4 className="text-brand-text-default break-words mb-xsmall mr-small shrink">{data.name}</h4>
                    <div className="flex-none">
                        {extraPrice > 0 && (
                            <p className="text-brand-text-grey line-clamp-1 text-right">
                                + {formatCurrency(extraPrice)}
                            </p>
                        )}
                    </div>
                </div>
                <div>
                    <ProductIdentifiers product={data} className="mb-xsmall" />
                    <p className={classnames("text-brand-text-grey break-words", "mini")}>{data.description}</p>
                </div>
            </div>
        );
    };

    const renderModifierGroup = (item: ModifierGroupInterface): JSX.Element => {
        if (item?.max_count === 1) {
            return (
                <ModifierGroupSingleSelect
                    ref={modifierGroupRefs.get(item?.id)}
                    modifierGroup={item}
                    selectedModifiers={lineItem.modifiers}
                    toggle={(modifier) => toggleModifier(item, modifier)}
                />
            );
        }

        return (
            <ModifierGroupMultiSelect
                ref={modifierGroupRefs.get(item?.id)}
                modifierGroup={item}
                selectedModifiers={lineItem.modifiers}
                toggle={(modifier) => toggleModifier(item, modifier)}
            />
        );
    };

    const renderAddButton = (): JSX.Element => {
        if (renderOrderingDisabled) {
            return renderOrderingDisabled;
        }

        if (!data?.is_available) {
            return (
                <div className={productUnavailableContainer}>
                    <h4 className={productUnavailableTextStyle}>{t("error_messages.product_unavailable")}</h4>
                </div>
            );
        }

        return (
            <ButtonFooter className={addButtonContainer}>
                <CustomButton title={t("buttons.update_meal")} onClick={onAddProduct} />
            </ButtonFooter>
        );
    };

    const renderHeaderBar = useMemo(() => {
        const shouldFadeInHeader = scrollYRef.current > (headerRef.current?.clientHeight ?? 0);

        if (!isDesktop && data?.image_url) {
            return (
                <button className={backIconContainer} onClick={goBack}>
                    <BackIcon color={Theme.color.text.default} />
                </button>
            );
        }

        return (
            <div className={headerContainer} ref={headerRef}>
                {!!data?.image_url && <div className={headerBackground(shouldFadeInHeader)} />}

                <CustomNavBar leftClick={goBack} hideCloseIcon transparent={!!data?.image_url} />
            </div>
        );
    }, [isDesktop, data?.image_url, scrollYRef.current, headerRef.current]);

    const renderProductPicture = useMemo((): JSX.Element | null => {
        return data?.image_url ? (
            <div
                ref={bodyRef}
                className={productImageContainer}
                style={{
                    marginTop: (headerRef.current?.clientHeight ?? 0) * -1.5,
                }}
            >
                <img
                    src={data?.thumbnail_url}
                    alt={SeoResource.toAltText(`${data?.name} image`)}
                    className={productImage}
                />
                <img
                    src={data?.image_url}
                    alt={SeoResource.toAltText(`${data?.name} image`)}
                    className={productImage}
                />
            </div>
        ) : (
            <div ref={bodyRef} />
        );
    }, [data?.image_url, headerRef.current, bodyRef]);

    return (
        <>
            {renderHeaderBar}
            {renderProductPicture}
            <div className={wrapper(!!data?.image_url)}>
                {renderProductInfo()}
                <div className="mb-small">
                    {data?.modifier_groups?.map((item: ModifierGroupInterface) => (
                        <div key={item?.id?.toString()}>{renderModifierGroup(item)}</div>
                    ))}
                </div>
            </div>
            {renderAddButton()}
        </>
    );
}

const headerContainer = "sticky top-0 z-10 relative";

const headerBackground = (fadeIn: boolean) =>
    classnames(
        "bg-background-inkWhite-white_1 transition-opacity duration-500 ease-out border-b border-brand-inkGrey-grey_2 absolute inset-0",
        {
            "opacity-100": fadeIn,
            "opacity-0": !fadeIn,
        },
    );

const backIconContainer =
    "w-9 h-9 bg-background-inkWhite-white_1 shadow-icon rounded-full flex justify-center items-center fixed top-0 z-10 left-0 ml-small mt-small";

const productImageContainer = "relative w-full aspect-square";

const productImage = "object-cover absolute w-full h-full";

const wrapper = (hasOffset: boolean) =>
    classnames("pt-small h-full lg:pb-small pb-[100px] bg-background-inkWhite-white_1 relative", {
        "lg:-mt-[150px] -mt-[40px] border-t border-brand-inkGrey-grey_2": hasOffset,
    });

const addButtonContainer = `lg:sticky fixed bottom-0 items-center`;

const productUnavailableContainer =
    "lg:sticky fixed bottom-0 w-full flex flex-row items-center justify-center px-medium py-small bg-brand-inkGrey-grey_1 border-t border-brand-inkGrey-grey_2";

const productUnavailableTextStyle = "text-brand-text-grey text-center";
