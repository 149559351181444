import { useEffect, useMemo, useState } from "react";
import CustomModal from "../../components-library/CustomModal";
import LoadingIndicator from "../../components-library/loading/LoadingIndicator";
import CustomNavBar from "../../components-library/navigation/CustomNavBar";
import useScreenType from "../../hooks/utility/useScreenType";
import { MODAL_SIZES } from "../../services/exports/Constants";
import { ProductComboInterface, ProductInterface } from "../../services/exports/Interfaces";
import { useTranslation } from "react-i18next";
import ComboUpsellItem from "../../components-library/menu/combo-upsell/ComboUpsellItem";
import collect from "collect.js";
import useAppState from "../../hooks/global/useAppState";
import React from "react";
import useAppMethods from "../../hooks/utility/useAppMethods";

export default function ComboUpsellModal() {
  const { t } = useTranslation(null, { keyPrefix: 'Modals:Combo:ComboUpsellModal' });

  const { comboUpsellModalProps: props } = useAppState();
  const { toggleComboUpsellModal, toggleMenuItemModal, toggleComboModal } = useAppMethods();

  const { openModal, product, combos } = props;

  const { isDesktop } = useScreenType();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (openModal) {
      onDidFocus();
    }
  }, [openModal]);

  function onDidFocus() {
    if (!isDesktop) {
      setLoading(true);
      setTimeout(() => setLoading(false), 500);
    }
  }

  function openProductModal(item: ProductInterface) {
    toggleComboUpsellModal();
    toggleMenuItemModal({
      openModal: true,
      product: item,
    });
  }

  function openComboModal(item: ProductComboInterface) {
    toggleComboUpsellModal();
    toggleComboModal({
      openModal: true,
      combo: item,
    });
  }

  function renderHeaderBar() {
    return (
      <div className={headerContainer}>
        <CustomNavBar
          title={t('title')}
          rightClick={() => toggleComboUpsellModal()}
          hideBackIcon
          containerClassName="border-none !p-medium"
        />
      </div>
    );
  }

  const renderLoading = useMemo(() => {
    return loading ? (
      <div className={loadingContainer}>
        <div className="-mb-1.5">
          <LoadingIndicator lg={60} md={50} sm={40} />
        </div>
      </div>
    ) : null;
  }, [loading]);

  const renderDivider = () => {
    if (isDesktop) {
      return (
        <div className="relative ml-mini px-medium flex flex-col justify-between">
          <div className="absolute left-1/2 -translate-x-1/2 border-l h-full w-[1px]"></div>
          <h5 className="bg-background-inkWhite-white_1 py-small absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2">{t('or')}</h5>
        </div>
      );
    }

    return (
      <div className="relative mt-mini py-medium flex justify-between">
        <div className="absolute top-1/2 -translate-y-1/2 border-t w-full h-[1px]"></div>
        <h5 className="bg-background-inkWhite-white_1 px-small absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2">{t('or')}</h5>
      </div>
    );
  }

  return (
    <CustomModal
      open={openModal}
      size={MODAL_SIZES.MD}
      fullScreen={!isDesktop}
      onClose={() => toggleComboUpsellModal()}
    >
      <div className={container}>
        {renderHeaderBar()}
        <div className="px-medium pb-medium">
          {combos[0].description && (
            <p className="mb-small">{combos[0]?.description}</p>
          )}
          <div className="flex flex-col lg:flex-row">
            <ComboUpsellItem
              title={t('item_only')}
              buttonTitle={t('buttons.item_only')}
              items={[product.name]}
              price={product.actual_price}
              onSelect={() => openProductModal(product)}
            />
            {combos.map((item) => (
              <React.Fragment key={`combo-item-${item.id}`}>
                {renderDivider()}
                <ComboUpsellItem
                  title={t('make_it_combo')}
                  buttonTitle={t('buttons.make_it_combo')}
                  items={collect(item.items).pluck('name').toArray()}
                  price={item.actual_price}
                  discount={item.combo_discount}
                  onSelect={() => openComboModal(item)}
                  className="lg:ml-mini"
                />
              </React.Fragment>
            ))}
          </div>
          {renderLoading}
        </div>
      </div>
    </CustomModal>
  );
}

const container = `h-full bg-background-inkWhite-white_1`;

const headerContainer = "sticky top-0 z-10";

const loadingContainer =
  "absolute inset-0 flex flex-col bg-background-inkWhite-white_1 justify-center items-center";
