import * as stripeJs from "@stripe/stripe-js";
import moment, { Moment } from "moment";
import { Country } from "react-phone-number-input";
import {
  ACHIEVEMENT_TYPES,
  APP_STATE_REDUCER_TYPES,
  DELIVERY_ZONE_GEOMETRY_TYPES,
  ENVIRONMENTS,
  GOOGLE_REVIEW_STATUS_TYPES,
  ORDER_REJECTED_REASON,
  REWARD_ACCOUNT_OPERATION_SOURCE,
  ProductType,
  CategoryType,
  TaxBehaviour,
  ORDER_FULFILLMENT_STATUSES,
  ORDER_PAYMENT_STATUSES,
  ORDER_METHODS,
  RoomServiceLocationType,
  PaymentMethod,
  OTP_DELIVERY_CHANNELS,
  SmartPricingRuleModifierType,
  DeliveryProvider,
  DeliveryStatus,
  DropoffAction,
} from "./Constants";
import {
  COUPON_TYPES,
  DISCOUNT_TYPES,
  EventHelperTypes,
  ORDERS_PAUSED_REASON,
  PROMOTION_TYPES,
  PROMO_CODE_DISCOUNT_TYPES,
  PROMO_CODE_TYPES,
} from "./Constants";
import { DataWrappedResponse, FeedbackLabelTypes } from "./Types";
import Pusher from "pusher-js";
import { ConfirmCancelModalProps } from "../../modals/ConfirmCancelModal";
import { AllOrdersPaidModalProps } from "../../modals/dine-in/AllOrdersPaidModal";
import { Collection } from "collect.js";
import { BasketLineItemInterface } from "../../store/types";

export type IMap<V, K extends string | number = string | number> = {
  [index in K]: V;
};

export interface InitialDataStoreInterface {
  franchise: Franchise;
  company: CompanyDetailsInterface;
  cashback_account: null|CashbackAccount;
  ongoing_orders: OngoingOrdersInterface;
  loading: boolean;
}

export interface AppStatusInterface {
  pickup_delivery_popup_seen: boolean;
  environment: ENVIRONMENTS;
  firstTimeDineInVisitor: boolean;
  cookiesConcent: boolean | undefined;
}

export interface LocationInterface {
  country_code: Country;
  city: string;
}

export interface ConfigInterface {
  primary_color: string | null;
  logo_url: string | null;
}

export interface TableInterface {
  id: number;
  number: string;
  parties?: PartyInterface[];
  party: null | PartyInterface;
}

export interface PartyInterface {
  id: number;
  table_id?: number;
  users: UserInterface[];
  order: OrderItemInterface;
  table: TableInterface;
  customers: number[];
  deleted_at: null | string;
  created_at: string | null;
}

export interface UpdatePartyDataInterface {
  table_id: number;
}

export interface UserInterface {
  id: number;
  first_name: string;
  last_name: string;
  created_at: string | null;
}

export interface DineInFlowInterface {
  isDineIn: boolean;
  table: null | TableInterface;
  party: null | PartyInterface;
  paymentInfo: null | OrderPaymentInterface;
  firstTimeDineInVisitor: boolean;
  paymentInfoSeen: boolean;
  rewardInfoModalSeen: boolean;
  paymentNoteAccepted: boolean;
}

export interface ProfileStoreInterface {
  profile: ProfileInterface;
}

export interface PaymentOptionsStoreInterface {
  payment_options: PaymentOptionPropertiesInterface[];
}

export interface OrderStoreInterface {
  cached_order: Partial<OrderInterface>;
}

export interface ProductLikesStoreInterface {
  likedProductIds: number[];
}

export interface OngoingOrdersInterface {
  data: OrderItemInterface[];
}

export interface Franchise {
  id: number;
  name: string;
  slug: string;
  logo_url: string;
  logos: LogoInterface[];
  primary_color: string;
  country?: string;
  head_scripts: string | null;
  body_noscripts: string | null;
  has_multiple_companies: boolean;
  companies: CompanyDetailsInterface[];
}

export interface CompanyLocation {
  name?: null | string;
  day?: null | number;
  hour?: null | number;
  minute?: null | number;
  address: string;
  city: string;
  zip_code: null | string;
  country: Country;
  lat: number;
  lon: number;
}

export interface CompanyDetailsInterface {
  // General & profile fields
  id: number;
  currency: string;
  stripe_id: string;
  first_delivery_merchant_id: null|string;
  slug: string;
  name: string;
  email: null | string;
  phone_number: string;
  logo_url: null | string;
  pictures: string[];
  dine_in_pictures: string[];
  primary_color: null | string;
  instagram_link: string | null;
  website_link: string | null;
  impressum_link: string | null;
  legal_address: string | null;
  has_savings_banner: boolean;

  // Tax fields
  tax_behaviour: TaxBehaviour;
  tax_percentage: number;

  // Payment method & checkout fields
  supported_payment_methods: PaymentMethod[];
  hidden_payment_methods: IMap<ORDER_METHODS[]|string[], PaymentMethod>;
  has_in_store_card_payments: boolean;
  has_paypal_upcharge: boolean;
  default_tip_percentage: number | null;

  // Location fields
  has_multiple_locations: boolean;
  locations: CompanyLocation[];
  location_name: null | string;
  address: string;
  city: string;
  state: string;
  country: Country;
  zip_code: null | string;
  lat: number;
  lon: number;

  // Google place fields
  google_rating: string;
  google_rates_count: number;
  google_places_id: string;

  // Custom messages fields
  pickup_customer_message_en: null | string;
  pickup_customer_message_de: null | string;
  rewards_info_header_en: string | null;
  rewards_info_description_en: string | null;
  rewards_info_header_de: string | null;
  rewards_info_description_de: string | null;
  scheduled_order_option_title: string | null;

  // General order setting fields
  has_live_orders: boolean;
  hidden_order_methods: null|ORDER_METHODS[];
  has_asap_orders: boolean;
  has_scheduled_orders: boolean;
  has_pre_order: boolean;
  scheduled_orders_time_slot_interval: number;
  has_order_instructions: boolean;
  order_instructions_title: string | null;

  // Pickup fields
  has_pickup: boolean;
  average_order_preparation_time?: AverageOrderPreparationTimeInterface | null;
  last_order_gap_pickup: number;
  min_schedule_ahead_time_pickup: number;

  // Delivery fields
  has_delivery: boolean;
  delivery_enabled: boolean;
  delivery_provider: DeliveryProvider;
  average_order_delivery_time?: AverageOrderPreparationTimeInterface | null;
  last_order_gap_delivery: number;
  min_schedule_ahead_time_delivery: number;

  // Dine in fields
  dine_in_enabled: boolean;
  last_order_gap_dine_in: number;

  // Room service fields
  has_room_service: boolean;

  //Schedule fields
  open_now: boolean;

  // Pickup schedule fields
  has_work_schedule: boolean;
  work_schedule: OpeningHoursInterface[];
  current_work_schedule?: OpeningHoursInterface | null;
  next_work_schedule?: OpeningHoursInterface | null;

  // Delivery schedule fields
  has_delivery_schedule: boolean;
  open_now_for_delivery: boolean;
  delivery_schedule: OpeningHoursInterface[];
  current_delivery_schedule?: OpeningHoursInterface | null;
  next_delivery_schedule?: OpeningHoursInterface | null;

  // Paused orders fields
  orders_paused_until?: string | null;
  orders_pause_reason?: ORDERS_PAUSED_REASON | null;

  // Schedule exceptions
  closed_from: string | null;
  closed_until: string | null;

  // Related resources
  service_provider: ServiceProvider;
  cashback_program?: null|CashbackProgram;
  loyalty_program?: null|LoyaltyProgram;
  google_review_program?: null|LoyaltyProgram;
  smart_pricing_deals: SmartPricingRule[];
  smart_pricing_rules: SmartPricingRule[];
  reward_balance: number;
  rewards: RewardInterface[];
  delivery_zones: DeliveryZone[];
  room_service_locations: RoomServiceLocation[];
  table_number_hidden: boolean;

  // Menu
  menus: MenuInterface[];
  categories: IMap<CategoryInterface>;
  products: IMap<ProductInterface | ProductComboInterface>;
  modifier_groups: IMap<ModifierGroupInterface>;
  modifiers: IMap<ModifierInterface>;

  // Promocodes
  show_master_promo_code: boolean;
  promo_codes: CouponDetailsInterface[];
}

export interface ScheduleExceptionInterface {
  closed_from: string | null;
  closed_until: string | null;
}

export interface SmartPricingRule {
  id: number;
  name: string;
  order_methods: ORDER_METHODS[];
  modifier_type: SmartPricingRuleModifierType;
  modifier_value: number;
  is_visible: boolean;
}

export interface DeliveryZone {
  id: number;
  name: string;
  color: string;
  is_hidden: boolean;
  min_threshold: number;
  max_threshold: null|number;
  fee: number;
  geometry_type: DELIVERY_ZONE_GEOMETRY_TYPES;
  geometry_data:
    | google.maps.CircleOptions
    | google.maps.PolygonOptions
    | string[];
}

export interface DeliveryQuote {
  id: number;
  provider: string;
  price: number;
  customer_price: number;
  pickup_estimate: string;
  dropoff_estimate: string;
  expires_at: string;
}

export interface RoomServiceLocation {
  id: number;
  type: RoomServiceLocationType;
  name: string;
  street_name: string;
  street_number: string;
  city: string;
  zip_code: string;
  table_number_hidden: boolean;
}

export interface LogoInterface {
  height: number;
  width: number;
  url: string;
}

export interface ServiceProvider {
  name: string;
  website: string;
  logo_url: string;
  support_email: string;
  stripe_key: string;
  google_analytics_id: string;
}

export interface Promotion {
  id: number;
  reward: number;
  end_date: string;
}

export interface CashbackProgram extends Promotion {
  percentage: number;
  point_lifetime: number;
  welcome_bonus: null|number;
  min_spending_limit: null|number;
  max_spending_limit: null|number;
}

export interface LoyaltyProgram extends Promotion {
  reward_threshold: number;
  is_per_item: boolean;
}

export interface GoogleReviewProgram extends Promotion {}

export interface PromotionInterface {
  id: number;
  type: PROMOTION_TYPES;
  start_date: string;
  end_date: string | null;
  reward: number;
  details: PromotionDetailsInterface;
}

export interface PromotionDetailsInterface {
  invite_code?: string | null;
  invite_link?: string | null;
  reward_threshold?: number;
  discount_percentage?: number;
  discount_type?: DISCOUNT_TYPES;
  is_per_item?: boolean;
}

export interface OpeningHoursInterface {
  open?: OpeningHoursScheduleInterface;
  close?: OpeningHoursScheduleInterface;
}

export interface OpeningHoursScheduleInterface {
  day: number;
  hour: number;
  minute: number;
}

export interface AverageOrderPreparationTimeInterface {
  min?: number | null;
  max?: number | null;
}

export interface MenuInterface {
  id: number;
  name: string;
  is_active: boolean;
  schedule: OpeningHoursInterface[];
  sorting_order: null | number;

  category_ids: number[];
  smart_pricing_rules?: SmartPricingRule[];
}

export interface CategoryInterface {
  id: number;
  menu_id: number;
  type: CategoryType;
  name: string;
  description?: string;
  image_url: null | string;
  product_ids: number[];
  menu_ids: number[];
  products?: ProductInterface[] | ProductComboInterface[];
  smart_pricing_rules?: SmartPricingRule[];
}

export type OrderMethodPrices = {
  [method in ORDER_METHODS]: number;
};

export interface ProductInterface {
  id: number;
  type: ProductType.Product;
  name: string;
  base_price: number;
  original_price: number;
  actual_price: number;
  original_order_method_prices: null|OrderMethodPrices;
  actual_order_method_prices: null|OrderMethodPrices;
  discount: number;
  description: string;
  image_url: string;
  thumbnail_url: string;
  free_eligible: boolean;
  is_discounted: boolean;
  is_promotable: boolean;
  is_available: boolean;
  identifiers: string[];
  popularity_rank?: number;
  likes_count: number;

  category_ids: number[];
  modifier_group_ids: number[];
  modifier_groups?: ModifierGroupInterface[];
}

export interface ProductComboInterface {
  id: number;
  type: ProductType.Combo;
  name: string;
  description: string;
  base_price: number;
  original_price: number;
  actual_price: number;
  original_order_method_prices: null|OrderMethodPrices;
  actual_order_method_prices: null|OrderMethodPrices;
  discount: number;
  image_url: string;
  thumbnail_url: string;
  combo_discount: number;
  is_promotable: boolean;
  is_available: boolean;
  identifiers: string[];
  popularity_rank?: number;
  likes_count: number;

  category_ids: number[];
  main_product?: ProductInterface;
  items?: ProductComboItemInterface[];
}

export interface ProductComboItemInterface {
  id: number;
  name: string;
  is_required: boolean;

  product_ids: number[];
  products?: ProductInterface[] | ProductComboInterface[];
}

export interface LineItemInterface {
  id: number;
  product_id: number;
  combo_item_id: null | number;
  actual_price: number;
  description: string;
  image_url: string;
  name: string;
  note: string | null;
  original_price: number;
  quantity: number;
  thumbnail_url: string;
  product_original_price: number;
  product_actual_price: number;
  price: number;
  discount: number;
  children?: LineItemInterface[];
  modifiers: ModifierInterface[];
  category: LineItemCategoryInterface;
}

export interface LineItemCategoryInterface {
  id: number;
  name: string;
  sorting_order: number;
}

export interface ModifierInterface {
  id: number;
  modifier_group_id: number;
  name: string;
  original_price: number;
  actual_price: number;
  price: number;
  original_order_method_prices: null|OrderMethodPrices;
  actual_order_method_prices: null|OrderMethodPrices;
  is_available: boolean;
  is_visible: boolean;
  free_eligible: boolean;
}

export interface ModifierGroupInterface {
  id: number;
  is_required: boolean;
  is_variant: boolean;
  is_visible: boolean;
  max_count: number;
  name: string;

  modifier_ids: number[];
  modifiers?: ModifierInterface[];
}

export interface RewardInterface {
  id: number;
  cost: number;
  discount: number;
  is_available: boolean;
}

export interface PaymentOptionPropertiesInterface {
  id: number;
  payment_method_id: PaymentMethod;
  name: string;
  display_name: string;
  is_default: boolean;
  details: string;
  paymentMethodSaved?: boolean;
}

export interface EventHelperActionInterface {
  type: EventHelperTypes;
  payload?: any;
}

export interface StepItemInterface {
  step: number;
  open: boolean;
}

export interface RewardInterface {
  id: number;
  cost: number;
  discount: number;
  is_available: boolean;
}

export interface Delivery {
  id: number;
  provider: string;
  status: DeliveryStatus;
  runner_name: null|string;
  runner_phone_number: null|string;
  tracking_url: null|string;
  dropoff_estimate: null|string;
}

export interface OrderItemInterface {
  id: number;
  uuid: string;
  user_id: number;
  user?: null | UserInterface;
  order_id: number;
  order_uuid: string;
  method: ORDER_METHODS;
  number: number;
  code: string;
  created_at: string;
  complete_at: string | null;
  expires_at: string | null;
  paid_at: null | string;
  original_price: number;
  sub_total: number;
  total: number;
  total_amount: number;
  tax: number;
  total_discount: number;
  company: CompanyDetailsInterface;
  line_items: LineItemInterface[];
  tip: number;
  coupon_type: COUPON_TYPES;
  status: ORDER_FULFILLMENT_STATUSES;
  delivery_provider: null | DeliveryProvider;
  rejection_reason: ORDER_REJECTED_REASON;
  fulfillment_events: OrderFulfillmentEvent[];
  estimated_pickup_time: string | null;
  initial_confirmation_time: string | null;
  estimated_preparation_time: AverageOrderPreparationTimeInterface | null;
  customer_note: string | null;
  delivery_street_name?: string;
  delivery_street_number?: string;
  delivery_city: string | null;
  delivery_state: string | null;
  delivery_country: string | null;
  delivery_company_name: string | null;
  delivery_doorbell_name: string | null;
  delivery_zip_code: string | null;
  method_fee: number | null;
  payment_method_upcharge_fee: number | null;
  service_fee: number | null;
  delivery?: Delivery;
  cashback_account_operations?: CashbackAccountOperation[];
  reward_account_operations?: RewardAccountOperation[];
  achievements?: AchievementInterface[];
  sub_orders?: null | OrderItemInterface[];
  payments?: null | OrderPaymentInterface[];
  room_service_location?: RoomServiceLocation;
  room_service_details?: string;
  rates?: null | OrderRateInterface[];
  tax_behaviour: TaxBehaviour;
  order_instructions: string | null;
}

export interface CashbackAccount {
  balance: number;
}

export interface CashbackAccountOperation {
  id: number;
  value: number;
  created_at: string;
}

export interface RewardAccountOperation {
  id: number;
  value: number;
  source: REWARD_ACCOUNT_OPERATION_SOURCE;
  created_at: string;
}

export interface AchievementInterface {
  id: number;
  type: ACHIEVEMENT_TYPES;
  created_at: string;
}

export interface OrderPaymentInterface {
  id: number;
  user_id: number;
  user?: UserInterface;
  coupon_type: COUPON_TYPES;
  coupon_details?: CouponDetailsInterface | null;
  status: ORDER_PAYMENT_STATUSES;
  currency: string;
  products_amount: number;
  promo?: string;
  tip: number;
  tip_details?: TipItemInterface;
  service_fee?: number;
  total_discount: number;
  amount: number;
  total_amount: number;
  payment_method_id: null | number;
  created_at: string;
  paid_at: string;
  refunds: OrderPaymentInterface[];
}

export interface OrderRateInterface {
  id: number;
  user_id: number;
  user?: UserInterface;
  rate: number;
}

export interface OrderStatusResponseInterface {
  id: number;
  payment_status: ORDER_PAYMENT_STATUSES;
}

export interface OrderFulfillmentEvent {
  id: number;
  status: ORDER_FULFILLMENT_STATUSES;
  created_at: string;
}

export interface EventStatusCopyInterface {
  title: string;
  description?: string;
}

export interface ApiResponseInterface {
  response: any;
  success: boolean;
}

export interface EventHelperInterface {
  event: {
    type: EventHelperTypes;
    payload?: any;
  };
}

export interface EventHelperActionInterface {
  type: EventHelperTypes;
  payload?: any;
}

export enum ButtonTypes {
  primary = 'primary',
  secondary = 'secondary',
  third = 'third',
  forth = 'forth',
  fifth = 'fifth',
  sixth = 'sixth',
  disabled = 'disabled',
  danger = 'danger',
  warning = 'warning',
}

export enum ButtonSizes {
  normal = 'normal',
  small = 'small',
};

export interface PostOptDataInterface {
  company_id: number;
  phone_number: string;
  lang: string;
  delivery_channel?: OTP_DELIVERY_CHANNELS;
  country: string;
}

export interface PostLoginDataInterface {
  id?: number;
  first_name?: string;
  phone_number?: string;
  password?: string;
  party_id?: number;
}

export interface LoginResponseInterface {
  is_signup: boolean;
  auth_token: string;
  user: ProfileInterface;
}

export interface OneTimePasswordInterface {
  password?: string;
  expires_at: string;
  delivery_channel: OTP_DELIVERY_CHANNELS;
  delivery_address: string;
}

export interface ProfileInterface extends DeliveryAddressDataInterface {
  id?: number | null;
  is_draft?: boolean;
  phone_number?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  email?: string | null;
  country?: string | null;
  state?: string | null;
  city?: string | null;
  address?: string | null;
  zip_code?: string | null;
  lang?: string | null;
  timezone?: string | null;
  should_receive_receipts?: null | boolean;
  orders_count?: number;

  invoice_company_name?: string;
  invoice_first_name?: string;
  invoice_last_name?: string;
  invoice_address?: string;
  invoice_zip_code?: string;
  invoice_city?: string;
  invoice_tax_id?: string;
  invoice_email?: string;
}

export interface DeliveryAddressDataInterface {
  delivery_street_name?: string | null;
  delivery_street_number?: string | null;
  delivery_city?: string | null;
  delivery_state?: string | null;
  delivery_country?: string | null;
  delivery_zip_code?: string | null;
  delivery_dropoff_action?: DropoffAction | null;
  delivery_doorbell_name?: string | null;
  delivery_company_name?: string | null;
  delivery_lat?: number | null;
  delivery_lng?: number | null;
  customer_note?: string | null;
}

export interface OrderDataInterface {
  company_id?: number;
  payment_option_id?: number;
  room_service_location_id?: number;
  delivery_quote_id?: number;
  party_id?: number;
  id?: number;
  uuid?: string;
  method: ORDER_METHODS;
  coupon_details?: CouponDetailsInterface | null;
  room_service_details?: string;
  customer_note?: string;
  delivery_street_name?: string;
  delivery_street_number?: string;
  delivery_city?: string;
  delivery_state?: string;
  delivery_country?: string;
  delivery_zip_code?: string;
  delivery_lat?: number;
  delivery_lng?: number;
  delivery_dropoff_action?: DropoffAction;
  delivery_doorbell_name?: string;
  delivery_company_name?: string;
  scheduled_for?: Moment|string;
  order_instructions?: string | null;
  promo?: string | null;
  use_cashback?: boolean;
  sub_total?: number;
  total?: number;
  total_discount?: null | number;
  tip?: number;
  tip_details?: TipItemInterface;
  tip_percentage?: number;
  line_items?: BasketLineItemInterface[];
  service_fee?: number | null;
  method_fee?: number | null;
  payments?: null | OrderPaymentInterface[];
  room_service_location?: RoomServiceLocation;
  invoice_data?: InvoiceData;
}

export interface OrderInterface extends DeliveryAddressDataInterface {
  id: number;
  uuid: string | null;
  coupon_id: string | null;
  user_id: number | null;
  room_service_location_id?: number;
  method: ORDER_METHODS;
  coupon_type: COUPON_TYPES;
  coupon_details: CouponDetailsInterface;
  service_fee: number | null;
  method_fee: number | null;
  payment_method_upcharge_fee: number;
  total_discount: number | null;
  tip: number | null;
  sub_total: number;
  tax: number;
  total: number;
  room_service_details?: string;
  scheduled_for?: Moment | string | null;
  promo?: string | null;
  paid_at: string | null;
  line_items: OrderLineItem[];
  sub_orders?: null | OrderInterface[];
  payments?: null | OrderPaymentInterface[];
  reward_account_operations?: RewardAccountOperation[];
  delivery_zone?: null | DeliveryZone;
  delivery_quote?: null | DeliveryQuote;
  tax_behaviour: TaxBehaviour;
  room_service_location?: RoomServiceLocation;
  order_instructions: string | null;
  invoice_data?: InvoiceData;
}

export interface OrderLineItem {
  id: number;
  product_id: number;
  name: string;
  description: string | null;
  actual_price: number;
  quantity: number;
}

export interface CouponDetailsInterface {
  id?: number;
  code: string;
  referral_code_id?: number;
  discount_type: PROMO_CODE_DISCOUNT_TYPES;
  discount: number;
  type: PROMO_CODE_TYPES;
  expires_at: string;
  components: PromoComponentInterface[];
}

export interface PromoComponentInterface {
  id: number;
  discount: number;
  usage_threshold: number;
  is_used?: boolean;
}

export interface TipItemInterface {
  hidden?: boolean;
  percentage: number | null;
  price: number | null;
  active_index?: number;
  label?: string;
}

export interface PromotionCodeResponseInterface {
  data: PromotionCodeDataInterface[];
  links: {
    first: string;
    last: string;
    prev: string;
    next: string;
  };
  meta: {
    current_page: number;
    from: number;
    last_page: number;
    links: PaginatedLinkInterface[];
    path: string;
    per_page: number;
    to: number;
    total: number;
  };
}

export interface PaginatedLinkInterface {
  url: string;
  label: string;
  active: boolean;
}

export interface PromotionCodeDataInterface {
  id: number;
  code: string;
  type: PROMO_CODE_TYPES;
  discount?: number;
  discount_type: PROMO_CODE_DISCOUNT_TYPES;
  expires_at: string;
  components: PromoComponentInterface[];
}

export interface PurchaseDataInterface {
  payment_option_id: number;
}

export interface CreatePaymentOptionDataInterface {
  company_id: number;
  payment_method_id: string;
  external_payment_method_id?: string | null | stripeJs.PaymentMethod;
  details?: string;
}

export interface DefaultPaymentMethodDataInterface {
  payment_option_id: number;
}

export interface ClientSecretKeyResponseInterface {
  client_secret: string;
}

export interface InitialDataResponseInterface {
  franchise: Franchise;
  company: CompanyDetailsInterface;
  ongoing_orders: OngoingOrdersInterface;
  profile: ProfileInterface;
  payment_options: PaymentOptionPropertiesInterface[];
}

export interface ElementOptionInterface {
  clientSecret: string;
}

export interface PusherConfig {
  key?: string;
  base_url?: string;
  cluster?: string;
}

export interface PayPalCreateOrderInterface {
  id: string;
}

export interface PickUpDeliveryStatusInterface {
  label: string;
  status: boolean;
}

export interface PickUpDeliveryHookResultInterface {
  pickUpStatus: PickUpDeliveryStatusInterface;
  deliveryStatus: PickUpDeliveryStatusInterface;
}

//------ Globals interface ------------

export interface ThemeInterface {
  fonts: FontsInterface;
  color: ColorInterface;
  dimension: DimensionInterface;
  shadows: ShadowsInterface;
  hitSloop: HitSloopInterface;
}

export interface FontsInterface {
  fontSizes: FontSizesInterface;
  fontWeights: FontWeightsInterface;
}

export interface BreakPointsInterface {
  sm: number;
  md: number;
  lg: number;
  xl: number;
}

export interface FontSizesInterface {
  h1: number;
  h2: number;
  h3: number;
  h4: number;
  h5: number;
  h6: number;
  p: number;
  mini: number;
  tiny: number;
  nano: number;
}

export interface FontWeightsInterface {
  light: number;
  regular: number;
  medium: number;
  semiBold: number;
  bold: number;
}

export interface ColorInterface {
  background: {
    inkWhite: {
      white_0: string;
      white_1: string;
    };
    inkDisabled: {
      disabled_1: string;
      disabled_2: string;
      disabled_3: string;
    };
    inkGreen: {
      green_0: string;
      green_1: string;
      green_2: string;
    };
  };
  companyBrand: {
    primary: string;
    gradients: {
      primary: string[];
    };
  };
  brand: {
    primary: string;
    secondary: string;
    success: string;
    notice: string;
    warning: string;
    danger: string;
    inkGrey: {
      grey_0: string;
      grey_1: string;
      grey_2: string;
      grey_3: string;
      grey_4: string;
      grey_5: string;
      grey_6: string;
    };
    inkGreen: {
      green_0: string;
      green_1: string;
      green_2: string;
    };
    inkPrimary: {
      primary_1: string;
      primary_2: string;
      primary_3: string;
      primary_4: string;
      primary_5: string;
      primary_6: string;
      primary_7: string;
      primary_8: string;
      primary_9: string;
    };
    inkWarning: {
      warning_1: string;
    };
    inkDanger: {
      danger_4: string;
      danger_9: string;
    };
    themeColors: {
      color_1: string;
      color_2: string;
    };
  };
  text: {
    default: string;
    grey: string;
    light: string;
    disabled: string;
    red: string;
    warning: string;
    blue: string;
  };
  
  white: string;

  gradients: GradientsInterface;
}

export interface GradientsInterface {
  primary: string[];
  secondary: string[];
  third: string[];
  forth: string[];
  fifth: string[];
}

export interface DimensionInterface {
  padding: {
    large: number;
    medium: number;
    small: number;
    xsmall: number;
    mini: number;
    xmini: number;
    tiny: number;
    xTiny: number;
  };
  borderRadius: {
    large: number;
    small: number;
    mini: number;
    tiny: number;
  };
  borderWidth: {
    default: number;
    small: number;
    medium: number;
    large: number;
  };
  hitSlop: {
    regular: object;
  };
  breakPoints: BreakPointsInterface;
}

export interface ShadowsInterface {
  shading1: {
    elevation: number;
    shadowColor: string;
    shadowOffset: {
      height: number;
      width: number;
    };
    shadowRadius: number;
    shadowOpacity: number;
  };
  shading2: {
    elevation: number;
    shadowColor: string;
    shadowOffset: {
      height: number;
      width: number;
    };
    shadowRadius: number;
    shadowOpacity: number;
  };
}

export interface HitSloopInterface {
  regular: {
    top: number;
    bottom: number;
    left: number;
    right: number;
  };
  medium: {
    top: number;
    bottom: number;
    left: number;
    right: number;
  };
  large: {
    top: number;
    bottom: number;
    left: number;
    right: number;
  };
}

//---------------------------

export interface CompanyHoursHookInterface {
  open: boolean;
  openWholeDay: boolean;
  getCurrentDineInSchedule: () => OpeningHoursInterface | null;
  getCurrentWorkSchedule: () => OpeningHoursInterface | null;
  getNextWorkSchedule: () => OpeningHoursInterface | null;
  dineInOpenUntil: Moment;
  closedFrom: Moment;
  closedUntil: Moment;
  pickupClosed: boolean;
  deliveryClosed: boolean;
  dineInClosed: boolean;
  dineInOpensToday: boolean;
  orderTimeAvailable: boolean;
  opensToday: CompanyOpeningStatusInterface;
  opensStartingTomorrow: CompanyOpeningStatusInterface;
  ordersPausedUntil: string | null;
  orderArePausedTillEndOfDay: boolean;
  ordersPausedReason: ORDERS_PAUSED_REASON;
  ordersArePaused: boolean;
  ordersArePausedManually: boolean;
}

export interface CompanyOpeningStatusInterface {
  status: boolean;
  time: moment.Moment | null;
}

export interface FeedbackLabelInterface {
  message: string;
  type?: FeedbackLabelTypes;
  errors?: null | object;
  hideIcon?: boolean;
  className?: string;
  textClassname?: string;
}

export type AlertTypes = "Success" | "Error" | "Info" | "Warning";

export interface AlertContextInterface {
  error: (info: AlertInfoInterface) => void;
}

export interface CompanySlugContextInterface {
  slug: null | string;
  loading: boolean;
  updateSlug: (value: string) => void;
}

export interface InitialDataContextInterface {
  mounted: boolean;
  data: InitialDataStoreInterface;
  loading: boolean;
  refresh: (
    slug?: null | string,
    hideLoading?: boolean,
    updateBasket?: boolean
  ) => Promise<DataWrappedResponse<InitialDataResponseInterface>>;
}

export interface GlobalAppMethodsContextInterface {
  toggleLoginModal: (payload: Partial<LoginModalProps>) => void;
  toggleInfoModal: (payload: ConfirmCancelModalProps) => void;
  toggleAllOrdersPaidModal: (payload: AllOrdersPaidModalProps) => void;
  toggleComboUpsellModal: (payload?: ComboUpsellModalProps) => void;
  toggleMenuItemModal: (payload: MenuItemModalProps) => void;
  toggleComboModal: (payload?: ComboModalProps) => void;
  saveMenu: (payload: Partial<GlobalAppContextMenuInterface>) => void;
  toggleOrderMethodModal: (payload: Partial<OrderMethodModalProps>) => void;
  toggleChangeTablePartyModal: (
    payload: Partial<ChangeTablePartyModalProps>
  ) => void;
  toggleOrdersDisabledModal: (
    payload: Partial<OrdersDisabledModalProps>
  ) => void;
  toggleFreeProductsModal: (payload: Partial<FreeProductsModalProps>) => void;
  formatCurrency: (
    amount: number,
    options?: FormatCurrencyOptions
  ) => number | string;
  formatTime: (
    datetime: string|Moment,
    format?: undefined|string,
  ) => string;
  formatDateTime: (
    datetime: string|Moment,
    format?: {
      format?: undefined|string;
      dateFormat?: undefined|string;
      timeFormat?: undefined|string;
    }
  ) => string;
  formatScheduleUnit: (
    scheduleUnit: Moment|string|OpeningHoursScheduleInterface,
    format?: {
      prefix?: string;
      day?: boolean;
      time?: boolean;
    },
  ) => string,
  createScheduledOrderSlot: (time: string|Moment) => string,
}

export interface ScreenTypeContextInterface {
  isDesktop: boolean;
  isTablet: boolean;
  isMobile: boolean;
}

export interface PusherContextInterface {
  client: null | Pusher;
  isConnected: () => boolean;
  connect: () => Promise<Pusher>;
  disconnect: () => void;
  unbind: (eventsToUnbind: string | string[]) => void;
  unsubscribe: (channel: string, eventsToUnbind?: string | string[]) => void;
}

export interface OrderMethodModalProps {
  openModal: boolean;
  orderMethod?: ORDER_METHODS;
  buttonTitle?: string;
  onComplete?: () => void;
}

export interface ChangeTablePartyModalProps {
  openModal: boolean;
  onTableSelected?: () => void;
}

export interface OrdersDisabledModalProps {
  openModal: boolean;
  onConfirm?: () => void;
}

export interface FreeProductsModalProps {
  openModal: boolean;
  onConfirm?: () => void;
}

export interface GlobalAppStateContextInterface {
  loginModalProps: LoginModalProps;
  infoModalProps: ConfirmCancelModalProps;
  allOrderPaidModalProps: AllOrdersPaidModalProps;
  menu: Partial<GlobalAppContextMenuInterface>;
  comboUpsellModalProps: ComboUpsellModalProps;
  menuItemModalProps: MenuItemModalProps;
  comboModalProps: ComboModalProps;
  orderMethodModalProps: OrderMethodModalProps;
  changeTablePartyModalProps: ChangeTablePartyModalProps;
  ordersDisabledModalProps: OrdersDisabledModalProps;
  freeProductsModalProps: FreeProductsModalProps;
}

export interface GlobalAppContextReducerHookInterface {
  state: GlobalAppStateContextInterface;
  dispatch: (
    data: ReducerDispatchActionPayloadInterface<
      APP_STATE_REDUCER_TYPES,
      Partial<GlobalAppStateContextInterface>
    >
  ) => void;
}

export interface GlobalAppContextMenuInterface {
  screenPosition: number;
  activeCategoryId: number;
  menuLoaded: boolean;
}

export interface StripeContextInterface {
  loading: boolean;
  stripeMounted: boolean;
  options: ElementOptionInterface;
  stripe: null | stripeJs.Stripe;
  elements: null | stripeJs.StripeElements;
  paymentMethod: null | stripeJs.PaymentMethod;
  enabledWallets: PaymentMethod[];
  getClientSecret: (payload: PaymentOptionPropertiesInterface) => void;
  setPaymentMethod: (value: null | stripeJs.PaymentMethod) => void;
  setEnabledWallets: (value: any) => void;
  guessPaymentDestination: (paymentMethod: PaymentMethod) => boolean;
  setPaymentDestination: (direct: boolean) => void;
  setStripe: (value: null | stripeJs.Stripe) => void;
  setElements: (value: null | stripeJs.StripeElements) => void;
}

export interface MenuContextInterface {
  menus: Collection<MenuInterface>;
  selectedMenu: MenuInterface;
  setSelectedMenu: (value: MenuInterface) => void;
  categories: CategoryInterface[];
  combos: Collection<ProductComboInterface>;
  compileProduct: (id: number) => ProductInterface | ProductComboInterface;
  findProductCombo: (product: ProductInterface) => ProductComboInterface | null;
  findFreeProduct: (id: number) => ProductInterface;
  applyOrderMethodPrices: (item: ProductInterface|ProductComboInterface|ModifierInterface) => ProductInterface|ProductComboInterface|ModifierInterface;
}

export interface ReducerDispatchActionPayloadInterface<Type, Payload> {
  type: Type;
  payload?: Payload;
}

export interface AlertInfoInterface {
  type?: AlertTypes;
  title?: string;
  description?: string;
}

export interface PaginatedListLinksInterface {
  first: string;
  last: string;
  next: null;
  prev: null;
}

export interface PaginatedListMetaInterface {
  current_page: number;
  from: number;
  last_page: number;
  links: any[];
  path: string;
  per_page: number;
  to: number;
  total: number;
}

export interface AvailableLanguageInterface {
  language: string;
  languageName: string;
}

export interface RoutesInterface {
  path: string;
  element?: JSX.Element;
  protectedType?: "Auth" | "DineIn";
  children?: RoutesInterface[];
  loader?: JSX.Element;
}

export interface GoogleLocationInterface<Type> {
  success: boolean;
  location: Type | null;
}

export interface GoogleAddressParamsInterface {
  streetName: string;
  streetNumber: string;
  zip: string;
  city: string;
  state?: string;
  country: string;
}

export interface ScheduledOrderSlotInterface {
  start_time: string;
  end_time: string;
}

export interface ErrorHandlerInterface {
  [error_code: string | number]: () => void;
}

export interface PostPartyOrderComponentInterface {
  party_id: number;
  orders: number[];
  tip: null | number;
  promo?: null | string;
}

export interface CreateOrderPaymentInterface {
  payment_option_id?: number;
  amount: number;
  tip?: number;
  promo?: string;
  tip_details?: TipItemInterface;
}

export interface CreateOrderRateInterface {
  rate: number;
}

export interface GoogleReviewStatusInterface {
  id: number;
  status: GOOGLE_REVIEW_STATUS_TYPES;
  name: string;
  screenshot_url: string;
  created_at: string;
}

export interface ActionPayloadInterface<Type> {
  payload: Type;
  type: string;
}

export interface ComboUpsellModalProps {
  openModal: boolean;
  product: ProductInterface | null;
  combos: ProductComboInterface[] | null;
}

export interface MenuItemModalProps {
  openModal: boolean;
  isFreeItem?: boolean;
  product: Partial<ProductInterface> | null;
  lineItem?: BasketLineItemInterface | null;
  screenPosition?: number;
  onCloseModal?: () => void;
}

export interface ComboModalProps {
  openModal: boolean;
  combo: ProductComboInterface;
  lineItem?: BasketLineItemInterface | null;
}

export interface PaymentRequestButtonInterface {
  amount: number | null;
  validate: () => Promise<boolean>;
  confirmPayment: () => void;
  onStartPayment?: () => void;
  onCancelPayment?: () => void;
}

export interface PaymentRequestHookInterface {
  paymentRequest: stripeJs.PaymentRequest | null;
  result: stripeJs.CanMakePaymentResult | null;
  initiated: boolean;
}

export interface LoginModalProps {
  openModal: boolean;
  onLoginSuccess?: () => void;
  onCloseRedirect?: () => void;
}

export interface SearchDeliveryZoneParams {
  company_id: number;
  address?: null | string;
  zip?: null | string;
  lat?: null | number;
  lng?: null | number;
  throw?: boolean;
}

export interface CreateDeliveryQuotesParams {
  dropoff_time: null|string|Moment;
  street_name: string;
  street_number: string;
  city: string;
  state: null|string;
  country: string;
  zip_code: string;
  lat: null|number;
  lng: null|number;
  instructions?: null|string;
}

export interface GeolocationInterface {
  lat: number;
  lng: number;
}

export interface FormatCurrencyOptions {
  currency?: string;
  locale?: string;
  trimInteger?: boolean;
}

export interface InvoiceData {
  company_name?: string;
  first_name?: string;
  last_name?: string;
  address: string;
  zip_code: string;
  city: string;
  tax_id: string;
  email: string;
}