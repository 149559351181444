import { useEffect, useMemo, useState } from "react";
import LoadingPage from "../components-library/loading/LoadingPage";
import { useTranslation } from "react-i18next";
import InitialLoadingManager from "../services/api/InitialLoadingManager";
import toast from "react-hot-toast";
import CompanyCard from "../components-library/franchise/CompanyCard";
import RestaurantDetailsScreen from "./RestaurantDetailsScreen";
import AutoComplete from "react-google-autocomplete";
import LocationIcon from "../assets/logo/LocationIcon";
import useTheme from "../hooks/ui/useTheme";
import CloseIcon from "../assets/logo/CloseIcon";
import { GooglePlaceResource } from "../services/resources/GooglePlaceResource";
import { CompanyDetailsInterface, GeolocationInterface } from "../services/exports/Interfaces";
import { useGeolocated } from "react-geolocated";
import classnames from "classnames";
import collect from "collect.js";
import useInitialData from "../hooks/global/useInitialData";

export interface CompanyDistanceInterface {
    company: CompanyDetailsInterface;
    distance?: number;
}

export default function HomeScreen() {
    const { t } = useTranslation(null, { keyPrefix: "Pages:HomeScreen" });

    const theme = useTheme();
    const { loading: appLoading } = useInitialData();

    const [data, setData] = useState(null);

    const [loading, setLoading] = useState(false);
    const [mounted, setMounted] = useState(false);

    const [addressSearch, setAddressSearch] = useState("");
    const [coordinates, setCoordinates] = useState<GeolocationInterface>(null);

    const { coords, isGeolocationAvailable, isGeolocationEnabled, getPosition } = useGeolocated({
        positionOptions: {
            enableHighAccuracy: true,
            maximumAge: 0,
        },
        suppressLocationOnMount: true,
    });

    const companies = useMemo<CompanyDistanceInterface[]>(
        () =>
            data &&
            collect(data.companies)
                .map((item: CompanyDetailsInterface) => {
                    // @ts-ignore
                    const distance = new GooglePlaceResource(
                        { geometry: { location: coordinates as any } },
                        item,
                    ).calculateDistance({
                        lat: item.lat,
                        lng: item.lon,
                    });

                    return {
                        company: item,
                        distance,
                    };
                })
                .sortBy("distance")
                .toArray(),
        [data, coordinates],
    );

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (!mounted || !data?.companies?.length || data?.companies?.length < 2) {
            return;
        }

        getPosition();
    }, [mounted]);

    useEffect(() => {
        cacheBrowserLocation();
    }, [mounted, coords, isGeolocationAvailable, isGeolocationEnabled]);

    async function cacheBrowserLocation(): Promise<void> {
        if (!mounted || !coords || !isGeolocationAvailable || !isGeolocationEnabled) {
            return;
        }

        setCoordinates({
            lat: coords?.latitude,
            lng: coords?.longitude,
        });

        const { success, location } = await GooglePlaceResource.getPlaceFromCoordinates({
            lat: coords?.latitude,
            lng: coords?.longitude,
        });

        if (success) {
            setAddressSearch(new GooglePlaceResource(location).formatAddress());
        }
    }

    async function loadData() {
        setLoading(true);
        const { success, response } = await InitialLoadingManager.getFranchiseData();
        setLoading(false);

        if (!success) {
            setMounted(true);

            return toast.error(t("toasts.failed_to_load_stores"));
        }

        setData(response?.data?.data);
        setMounted(true);
    }

    const renderLoadingPage = useMemo(() => {
        return loading && !appLoading ? <LoadingPage /> : null;
    }, [loading, appLoading]);

    const onPlaceSelected = (place: google.maps.places.PlaceResult): void => {
        setAddressSearch(new GooglePlaceResource(place).formatAddress());
        setCoordinates({
            lat: place.geometry?.location?.lat(),
            lng: place.geometry?.location?.lng(),
        });
    };

    const clearAddress = () => {
        setAddressSearch("");
        setCoordinates(null);
    };

    function renderRemoveIcon() {
        return (
            <div
                className="ml-small bg-brand-text-grey rounded-full flex flex-none justify-center items-center w-5 h-5 cursor-pointer"
                onClick={clearAddress}
            >
                <CloseIcon className="h-2 w-2" color={theme.color.text.disabled} />
            </div>
        );
    }

    const renderAddressInput = () => (
        <div>
            <h4>{t("title")}</h4>
            <div className="mt-small flex items-center w-full px-small py-xsmall border border-companyBrand-primary rounded">
                <LocationIcon className="h-4 w-4 mr-small my-auto" color={theme.color.brand.inkGrey.grey_4} />
                <AutoComplete
                    apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}
                    onPlaceSelected={onPlaceSelected}
                    // @ts-ignore
                    onChange={(e) => setAddressSearch(e.target.value)}
                    // @ts-ignore
                    value={addressSearch ?? ""}
                    placeholder={t("form.address.placeholder")}
                    className="w-full text-brand-inkGrey-grey_4 text-mini overflow-ellipsis"
                    autoFocus={!coordinates}
                    options={{
                        types: ["address"],
                        componentRestrictions: {
                            country: data?.country,
                        },
                    }}
                />
                {addressSearch?.length > 0 && renderRemoveIcon()}
            </div>
        </div>
    );

    const renderScreen = () => {
        if (!mounted) {
            return renderLoadingPage;
        }

        if (data?.companies?.length < 2) {
            return <RestaurantDetailsScreen />;
        }

        return (
            <div className="lg:h-screen overflow-hidden relative">
                {data?.logo_url && (
                    <header className="sticky top-0 w-full px-small py-mini shadow-section bg-background-inkWhite-white_1 border-companyBrand-primary border-b">
                        <img src={data?.logo_url} alt="Logo" className="h-12" />
                    </header>
                )}
                <main className="h-full flex bg-background-inkWhite-white_1">
                    <div className="lg:p-medium p-small lg:w-1/2 w-full overflow-y-auto">
                        {renderAddressInput()}
                        {companies?.map((item, index) => (
                            <CompanyCard
                                data={item}
                                className={classnames("mt-small", {
                                    "mb-[65px]": index === companies.length - 1,
                                })}
                                key={`franchise-store-${item.company.id}`}
                            />
                        ))}
                    </div>
                    <div className="lg:w-1/2 lg:block hidden overflow-hidden">
                        <img src={data?.image_url} alt="cover image" className="object-cover w-full h-full" />
                    </div>
                    {renderLoadingPage}
                </main>
            </div>
        );
    };

    return renderScreen();
}
