import { createSlice } from "@reduxjs/toolkit";
import { REDUX_STORE_SLICES } from "../../services/exports/Constants";
import { EventHelperInterface } from "../../services/exports/Interfaces";

export default createSlice({
  name: REDUX_STORE_SLICES.EVENT_HELPER,
  initialState: {
    event: {
      type: "",
      payload: null,
    },
  },
  reducers: {
    setEvent: (state, action) => {
      try {
        state.event = action.payload;
      } catch (error) {}
    },
    resetEvent: (state) => {
      try {
        state.event = {
          type: "",
          payload: null,
        };
      } catch (error) {}
    },
  },
});

export const eventHelperProps = (state: EventHelperInterface) => state;
