import Pusher from "pusher-js";
import AuthorizationTokenStorage from "../auth/AuthorizationTokenStorage";
import { PusherConfig } from "../exports/Interfaces";
import {PUSHER_CONNECTION_STATES} from "../exports/Constants";

class PusherClient {
  private pusher: Pusher | null;
  private key: string = import.meta.env.VITE_PUSHER_APP_KEY;
  private base_url: string = import.meta.env.VITE_API_BASE_URL;
  private cluster: string = import.meta.env.VITE_PUSHER_APP_CLUSTER;

  constructor(config?: PusherConfig) {
    this.pusher = null;
    if (config?.base_url) {
      this.base_url = config?.base_url;
    }
    if (config?.cluster) {
      this.cluster = config?.cluster;
    }
    if (config?.key) {
      this.key = config?.key;
    }
  }

  connect = async () => {
    if (this.pusher === null) {
      const pusherConfig = await this.getPusherConfig();
      this.pusher = new Pusher(pusherConfig.key, pusherConfig);
      return this.pusher;
    }

    return this.pusher;
  };

  forceConnect = async (): Promise<Pusher> => {
    const pusherConfig = await this.getPusherConfig();
    this.pusher = new Pusher(pusherConfig.key, pusherConfig);

    return this.pusher;
  };

  disconnect = (): void => {
    this.pusher?.disconnect();
    this.pusher = null;
  };

  isConnected = (client: Pusher|null) => {
    return client
        && client?.connection
        && (
            client.connection.state === PUSHER_CONNECTION_STATES.CONNECTING
          || client.connection.state === PUSHER_CONNECTION_STATES.CONNECTED
        );
  }

  private getPusherConfig = async () => {
    return {
      key: this.key,
      cluster: this.cluster,
      encrypted: true,
      authEndpoint: `${this.base_url}/broadcasting/auth`,
      auth: {
        headers: {
          // 'Content-type': 'application/json',
          Accept: "application/json",
          Authorization: `Bearer ${AuthorizationTokenStorage.getToken()}`,
        },
      },
    };
  };
}

export default new PusherClient();
