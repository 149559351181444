import { useMemo, useEffect, useState } from "react";
import { PartyInterface } from "../../services/exports/Interfaces";
import { collect } from "collect.js";
import HelperMethods from "../../services/exports/HelperMethods";
import ReduxActions from "../../store/ReduxActions";
import { actionCreators } from "../../store/actions";
import useStore from "./useStore";
import {FLOWS, ORDER_METHODS, SCREENS} from "../../services/exports/Constants";
import useInitialData from "./useInitialData";
import PartiesManager from "../../services/api/PartiesManager";
import TablesManager from "../../services/api/TablesManager";
import useUrlParams from "../utility/useUrlParams";
import useAuth from "./useAuth";
import { useTranslation } from "react-i18next";
import useToast from "../utility/useToast";

interface Props {
  initTable?: boolean;
  autoLoadTable?: boolean;
}

export default function useDineInFlow(props?: Props) {
  const { t } = useTranslation(null, { keyPrefix: 'Hooks:Global:UseDineInFlow' });

  const {
    table,
    party: storedParty,
    paymentInfo,
    isDineIn,
    paymentNoteAccepted,
    firstTimeDineInVisitor,
  } = useStore()?.dineInFlow;

  const { refresh } = useInitialData();

  const { profile } = useStore()?.profile;

  const { cached_order } = useStore()?.order;

  const params = useUrlParams();

  const { flow, table_id } = params;

  const { isLoggedIn } = useAuth();

  const toast = useToast();

  const [localParty, setLocalParty] = useState<null | PartyInterface>(null);

  const [loadingTable, setLoadingTable] = useState<boolean>(
    !!props?.autoLoadTable
  );

  const party = localParty ?? storedParty;

  const masterOrder = party?.order;

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (
      params.get("flow") === "dine-in" ||
      window.location.pathname.includes(SCREENS.DINE_IN)
    ) {
      ReduxActions.dispatch(actionCreators.dineInFlow.setDineInStatus(true));

      if (props?.initTable) {
        refresh();
      }
    }
  }, []);

  useEffect(() => {
    if (props?.autoLoadTable) {
      loadTable();
    }
  }, []);

  useEffect(() => {
    if (isDineIn) {
      return ReduxActions.dispatch(
        actionCreators.order.updateOrder({
          method: ORDER_METHODS.DINE_IN,
        })
      );
    }

    if (cached_order?.method === ORDER_METHODS.DINE_IN) {
      ReduxActions.dispatch(
        actionCreators.order.updateOrder({
          method: ORDER_METHODS.PICKUP,
        })
      );
    }
  }, [isDineIn, cached_order?.method]);

  const userOrders = useMemo(
    () =>
      masterOrder?.sub_orders && profile?.id
        ? collect(masterOrder?.sub_orders).where("user_id", profile?.id)
        : collect(),
    [masterOrder, profile]
  );

  const payments = useMemo(
    () => collect(masterOrder?.payments).whereNotNull("paid_at"),
    [masterOrder]
  );
  const userPayments = useMemo(
    () =>
      collect(masterOrder?.payments)
        .where("user_id", profile?.id)
        .whereNotNull("paid_at"),
    [masterOrder, profile]
  );
  const userPaidOriginal = useMemo(
    () => userPayments.sum("products_amount"),
    [userPayments]
  );
  const userPaid = useMemo(
    () => userPayments.sum("total_amount"),
    [userPayments]
  );
  const paidAmount = useMemo(() => payments.sum("products_amount"), [payments]);
  const unpaidAmount = useMemo(
    () =>
      paidAmount > masterOrder?.total
        ? 0
        : HelperMethods.roundNumberWithPrecision(
            masterOrder?.total - paidAmount
          ),
    [masterOrder, paidAmount, payments]
  );

  const userOrdered = useMemo(
    () => collect(party?.customers).contains(profile?.id),
    [party?.customers, profile]
  );

  const orderRate = useMemo(
    () => collect(party?.order?.rates).firstWhere("user_id", profile?.id),
    [party?.order?.rates, profile]
  );

  const isBillSplittable = useMemo(
    () => party?.customers?.length > 1,
    [party?.customers?.length]
  );

  async function loadTable(): Promise<void> {
    setLoadingTable(true);
    if (table_id && flow === FLOWS.DINE_IN) {
      if (party?.id) {
        await PartiesManager.update(party?.id, {
          table_id: +table_id,
        });
      }

      const { success, response } = await TablesManager.show(+table_id);
      setLoadingTable(false);

      if (success) {
        toast.success(
          t("toasts.table_selected", {
            number: response.data.data.number,
          })
        );
        window.history.replaceState(null, "", window.location?.pathname);
      }

      return;
    }

    if (table) {
      await TablesManager.show(table?.id);
      return setLoadingTable(false);
    }

    if (flow !== FLOWS.DINE_IN || !table_id) {
      isLoggedIn && (await PartiesManager.loadCurrentParty());
    }

    return setLoadingTable(false);
  }

  return {
    isDineIn,
    table,
    party,
    paymentInfo,
    masterOrder,
    userOrders,
    payments,
    userPayments,
    userPaid,
    userPaidOriginal,
    paidAmount,
    unpaidAmount,
    userOrdered,
    orderRate,
    isBillSplittable,
    paymentNoteAccepted,
    loadingTable,
    firstTimeDineInVisitor,
    setLocalParty,
    loadTable,
  };
}
