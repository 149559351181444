import {
  COUPON_TYPES,
  PaymentMethod,
  RoomServiceLocationType,
} from "../../../../services/exports/Constants";

export default {
  title: 'Order placed - Nr. {{number}}!',
  subtitle: 'Keep checking your phone for when the driver arrives (tracking link below)',
  header: {
    order_canceled: "Order canceled",
    track_order: "Your order",
    order_info: 'Order info & updates are sent to your email {{email}}',
  },
  order_type: {
    pickup: "pickup",
    delivery: "delivery",
  },
  order_status_toast: {
    order_reviewed: "#{{number}} Your order has been seen",
    order_confirmed: "#{{number}} Confirmed time {{time}}",
    order_preparation_up_time_changed:
      "#{{number}} {{type}} time changed to {{time}}",
    order_preparing: "Your order #{{number}} is being prepared",
    order_rejected: "#{{number}} order rejected",
  },
  room_service_instructions: {
    title: 'Instructions',
    location_types: {
      [RoomServiceLocationType.RoomService]: 'On site, room number {{details}}',
      [RoomServiceLocationType.TableService]: 'On site, table number {{details}}',
    },
    location_types_without_number: {
      [RoomServiceLocationType.RoomService]: 'On site',
      [RoomServiceLocationType.TableService]: 'On site',
    },
  },
  pickup_instructions: {
    title: "Pickup Instructions",
    description: "🗣 Tell us your order number when you pick up your order.",
  },
  delivery_instructions: {
    title: "Delivery instructions",
    address: "Your address",
    note: "Note: ",
  },
  rewards: {
    title: "Your rewards",
  },
  order_details: {
    title: "Order details",
  },
  payment_breakdown: {
    free: "Free",
  },
  price_details: {
    title: "Price details",
    original: "Original",
    tip: "Tip",
    total: "Total",
    delivery_fee: "Delivery fee",
    payment_method_upcharge_fee: {
      [PaymentMethod.Paypal]: 'PayPal fee',
    },
    service_fee: "Online processing",
    tax: 'Taxes',
    discount: {
      [COUPON_TYPES.REWARD]: 'Points applied',
      [COUPON_TYPES.CASHBACK]: 'Loyalty points',
      [COUPON_TYPES.PROMO_CODE]: 'Promo applied',
    }
  },
  review_form: {
    title: "How do you like {{name}}?",
    description: "Your feedback is anonymous and helps us improve.",
  },
  upload_google_review: {
    title: "Review us & get rewarded",
  },
  toast_messages: {
    share_experience: "How do you like us? Please share below 😊",
  },
  error_messages: {
    failed_to_load: "Failed to load order. Please try again.",
  },
};
