import useTheme from "../../hooks/ui/useTheme";
import { ThemeInterface } from "../../services/exports/Interfaces";
import classnames from "classnames";

interface Props {
  id?: string;
  value: string;
  placeholder: string;
  type?: string;
  rows?: number;
  maxLength?: number;
  textClassName?: string;
  containerStyle?: Object;
  disableLineBreak?: boolean;
  autoFocus?: boolean;
  onChange?: (text: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
}

export default function TextArea(props: Props) {
  const {
    id,
    value,
    placeholder,
    rows,
    maxLength,
    textClassName,
    containerStyle,
    disableLineBreak,
    autoFocus,
    onChange,
    onFocus,
    onBlur,
  } = props;

  const theme: ThemeInterface = useTheme();

  function onKeyDown(e: any): void {
    if (e.keyCode === 13 && disableLineBreak) {
      e.preventDefault();
    }
  }

  function renderMaxLength() {
    return maxLength ? (
      <div className={maxLengthContainer}>
        <h5
          style={{
            color:
              value?.length >= maxLength
                ? theme.color.brand.danger
                : theme.color.brand.inkGrey.grey_3,
          }}
          className={"tiny"}
        >
          {value?.length ?? 0}/{maxLength}
        </h5>
      </div>
    ) : null;
  }

  return (
    <div className={container} style={containerStyle}>
      <textarea
        id={id}
        value={value}
        defaultValue={value}
        placeholder={placeholder}
        rows={rows ?? 3}
        onChange={(e) => onChange && onChange(e.target.value)}
        className={classnames("w-full", textClassName)}
        maxLength={maxLength}
        autoFocus={autoFocus}
        onKeyDown={onKeyDown}
        onFocus={() => onFocus && onFocus()}
        onBlur={() => onBlur && onBlur()}
      />
      {renderMaxLength()}
    </div>
  );
}

const container =
  "placeholder:text-grey text-brand-text-default border rounded border-brand-inkGrey-grey_2 p-mini items-center";

const maxLengthContainer = "flex justify-end";
