import { useState, useEffect } from "react";

export const useIntersection = (
  element: any,
  options?: IntersectionObserverInit,
  disable?: boolean,
  onChange?: (visible: boolean) => void
) => {
  const [isVisible, setState] = useState(false);

  useEffect(() => {
    if (disable) {
      return;
    }

    if ("IntersectionObserver" in window) {
      const observer = new IntersectionObserver(([entry]) => {
        setState(entry.isIntersecting);
      }, options);

      element.current && observer.observe(element.current);
      const copyElement = element.current;

      return () => observer.unobserve(copyElement);
    }
  }, []);

  useEffect(() => {
    onChange && onChange(isVisible);
  }, [isVisible]);

  return isVisible;
};
