import { useEffect, useState } from "react";
import PromotionManager from "../../services/api/PromotionManager";
import {GOOGLE_REVIEW_STATUS_TYPES} from "../../services/exports/Constants";
import {GoogleReviewStatusInterface, RewardInterface} from "../../services/exports/Interfaces";
import { DataWrappedResponse, DataWrapper } from "../../services/exports/Types";
import RewardStatusResource from "../../services/resources/RewardStatusResource";
import useToast from "../utility/useToast";
import { useTranslation } from "react-i18next";
import useInitialData from "../global/useInitialData";
import useAuth from "../global/useAuth";
import useCashbackProgram from "../cashback/useCashbackProgram";

interface Props {
  autoLoad?: boolean;
}

export default function useGoogleReview(props?: Props) {
  const { t } = useTranslation(null, { keyPrefix: 'Hooks:GoogleReview:UseGoogleReview' });

  const { mounted, data } = useInitialData();
  const { company } = data;

  const toast = useToast();
  const { isLoggedIn } = useAuth();
  const { hasCashbackProgram} = useCashbackProgram();

  const [loading, setLoading] = useState<boolean>(false);
  const [googleStatusData, setGoogleStatusData] = useState(null);
  const [hasUploadedGoogleReview, setHasUploadedGoogleReview] = useState(false);

  const { google_review_program } = company;

  useEffect(() => {
    if ((mounted && props?.autoLoad) || props?.autoLoad === undefined) {
      requestGoogleReviewStatus();
    }
  }, [props?.autoLoad, mounted, isLoggedIn]);

  useEffect(() => {
    if (!!googleStatusData) {
      setHasUploadedGoogleReview(
        googleStatusData?.status !== GOOGLE_REVIEW_STATUS_TYPES.UNDETERMINED
      );
    }
  }, [googleStatusData]);

  async function requestGoogleReviewStatus(): Promise<
    DataWrappedResponse<DataWrapper<GoogleReviewStatusInterface>>
  > {
    setLoading(true);
    const request = await new PromotionManager().getGoogleReviewStatus(company?.id);
    setLoading(false);

    const { response, success } = request;

    if (success) {
      setGoogleStatusData(response?.data?.data);
    }

    return request;
  }

  function throwGoogleReviewUploadedToast({
    rewards,
    rewardBalance,
  }: {
    rewards?: RewardInterface[];
    rewardBalance?: number;
  }): string|void {
    if (hasCashbackProgram) {
      return toast.success(
        `${t('toast_messages.reward_not_available.part_1')}${t('toast_messages.reward_not_available.part_2', {pointsEarned: google_review_program?.reward})}`,
        { duration: Infinity }
      );
    }

    //Copy reward available:
    const reward = new RewardStatusResource({
      rewards,
      rewardBalance,
    }).getLastActiveReward();

    if (reward) {
      return toast.success(
        `${t("toast_messages.reward_available.part_1")}${t(
          "toast_messages.reward_available.part_2",
          {
            count: google_review_program?.reward,
          }
        )}`,
        { duration: Infinity }
      );
    }

    //Copy reward unavailable:
    const nextReward = new RewardStatusResource({
      rewards,
      rewardBalance,
    }).getNextReward();

    if (nextReward) {
      return toast.success(
        `${t("toast_messages.reward_not_available.part_1")}${t(
          "toast_messages.reward_not_available.part_2",
          {
            pointsEarned: google_review_program?.reward,
          }
        )}`,
        { duration: Infinity }
      );
    }
  }

  return {
    loading,
    googleStatusData,
    googlePromotion: google_review_program,
    hasGooglePromotion: !!google_review_program,
    hasUploadedGoogleReview,
    requestGoogleReviewStatus,
    throwGoogleReviewUploadedToast,
  };
}
