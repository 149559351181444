import { useState, useEffect } from "react";
import CustomNavBar from "../components-library/navigation/CustomNavBar";
import CustomButton from "../components-library/buttons/CustomButton";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ReduxActions from "../store/ReduxActions";
import { actionCreators } from "../store/actions";
import ButtonFooter from "../components-library/footers/ButtonFooter";
import { StoreInterface } from "../store/types";
import TextInput from "../components-library/inputs/TextInput";

interface Props {
  showModal: boolean;
  onCloseModal: () => void;
}

export default function RequestInvoiceModal({ showModal, onCloseModal }: Props) {
  const { t } = useTranslation(null, { keyPrefix: 'Components:Checkout:RequestInvoice' });

  const { cached_order } = useSelector((state: StoreInterface) => state.order);
  const { profile } = useSelector((state: StoreInterface) => state.profile);

  const [companyName, setCompanyName] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [zipCode, setZipCode] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [taxId, setTaxId] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  const isValid = (!!companyName || (!!firstName && !!lastName)) && !!address && !!zipCode && !!city && !!taxId && !!email;

  useEffect(() => {
    return showModal ? prefillData() : clearData();
  }, [showModal]);

  const clearData = () => {
    setCompanyName('');
    setFirstName('');
    setLastName('');
    setAddress('');
    setZipCode('');
    setCity('');
    setTaxId('');
    setEmail('');
  }

  const prefillData = () => {
    if (cached_order?.invoice_data) {
      setCompanyName(cached_order.invoice_data.company_name ?? '');
      setFirstName(cached_order.invoice_data.first_name ?? '');
      setLastName(cached_order.invoice_data.last_name ?? '');
      setAddress(cached_order.invoice_data.address);
      setZipCode(cached_order.invoice_data.zip_code);
      setCity(cached_order.invoice_data.city);
      setTaxId(cached_order.invoice_data.tax_id);
      setEmail(cached_order.invoice_data.email);

      return;
    }

    setCompanyName(profile?.invoice_company_name ?? '');
    setFirstName(profile?.invoice_first_name ?? profile?.first_name);
    setLastName(profile?.invoice_last_name ?? profile?.last_name);
    setAddress(profile?.invoice_address ?? '');
    setZipCode(profile?.invoice_zip_code ?? '');
    setCity(profile?.invoice_city ?? '');
    setTaxId(profile?.invoice_tax_id ?? '');
    setEmail(profile?.email ?? profile?.invoice_email);
  }

  const save = () => {
    ReduxActions.dispatch(
      actionCreators.order.updateOrder({
        invoice_data: {
          company_name: companyName,
          first_name: firstName,
          last_name: lastName,
          address,
          zip_code: zipCode,
          city,
          tax_id: taxId,
          email,
        },
      })
    );

    onCloseModal();
  }

  const renderHeaderBar = () => {
    return (
      <CustomNavBar
        containerClassName="w-full sticky top-0 z-50"
        title={t("modal.title")}
        rightClick={onCloseModal}
        hideBackIcon
      />
    );
  }

  const renderForm = () => {
    return (
      <div className={formContainer}>
        <div className={formRow}>
          <TextInput
            inputContainerStyle={inputWrap}
            className={inputStyle}
            value={companyName}
            onChange={setCompanyName}
            placeholder={t("modal.form.company_name")}
          />
        </div>
        <div className={formRow}>
          <TextInput
            inputContainerStyle={inputWrap}
            className={inputStyle}
            value={firstName}
            onChange={setFirstName}
            placeholder={t("modal.form.first_name")}
          />
          <TextInput
            inputContainerStyle={inputWrap}
            className={inputStyle}
            value={lastName}
            onChange={setLastName}
            placeholder={t("modal.form.last_name")}
          />
        </div>
        <div className={formRow}>
          <TextInput
            inputContainerStyle={inputWrap}
            className={inputStyle}
            value={address}
            onChange={setAddress}
            placeholder={t("modal.form.address")}
          />
        </div>
        <div className={formRow}>
          <TextInput
            inputContainerStyle={inputWrap}
            className={inputStyle}
            value={zipCode}
            onChange={setZipCode}
            placeholder={t("modal.form.zip_code")}
          />
          <TextInput
            inputContainerStyle={inputWrap}
            className={inputStyle}
            value={city}
            onChange={setCity}
            placeholder={t("modal.form.city")}
          />
        </div>
        <div className={formRow}>
          <TextInput
            inputContainerStyle={inputWrap}
            className={inputStyle}
            value={taxId}
            onChange={setTaxId}
            placeholder={t("modal.form.tax_id")}
          />
        </div>
        <div className={formRow}>
          <TextInput
            inputContainerStyle={inputWrap}
            className={inputStyle}
            value={email}
            onChange={setEmail}
            placeholder={t("modal.form.email")}
          />
        </div>
      </div>
    );
  }

  const renderSaveButton = () => {
    return (
      <ButtonFooter className="fixed bottom-0 flex justify-center items-center">
        <CustomButton
          containerStyle="lg:w-1/2 w-full"
          title={t("modal.save_button")}
          onClick={save}
          type="submit"
          disabled={!isValid}
        />
      </ButtonFooter>
    );
  }

  return showModal && (
    <div className={container}>
      {renderHeaderBar()}
      <div className="h-full relative">
        {renderForm()}
        {renderSaveButton()}
      </div>
    </div>
  );
}

const container = "w-screen h-screen bg-background-inkWhite-white_1 fixed inset-0 z-50 overflow-auto overscroll-contain";

const formContainer = "px-small mx-auto w-full lg:w-1/2 pt-small pb-large";

const formRow = "flex flex-row my-small gap-small";

const inputWrap = "w-full";

const inputStyle = "text-mini";