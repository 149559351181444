import {
  AverageOrderPreparationTimeInterface,
} from '../../services/exports/Interfaces';
import { DeliveryProvider, ORDER_METHODS } from '../../services/exports/Constants';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
import { StoreInterface } from '../../store/types';

type OrderPreparationTimeFunction = (method?: ORDER_METHODS) => AverageOrderPreparationTimeInterface;

export default function useOrderPreparationTime(): OrderPreparationTimeFunction {
  const { company } = useSelector((state: StoreInterface) => state.initialData);
  const { cached_order: order } = useSelector((state: StoreInterface) => state.order);

  const getDeliveryTime = (): AverageOrderPreparationTimeInterface => {
    if (
      company?.delivery_provider !== DeliveryProvider.Standalone &&
      order?.delivery_quote?.dropoff_estimate
    ) {
      return {
        min: moment(order?.delivery_quote?.dropoff_estimate).diff(moment(), 'minute'),
        max: moment(order?.delivery_quote?.dropoff_estimate).add(10, 'minutes').diff(moment(), 'minute'),
      };
    }

    return company?.average_order_delivery_time;
  }

  return (method?: ORDER_METHODS): AverageOrderPreparationTimeInterface => {
    return ({
      [ORDER_METHODS.PICKUP]: company?.average_order_preparation_time,
      [ORDER_METHODS.ROOM_SERVICE]: company?.average_order_preparation_time,
      [ORDER_METHODS.DELIVERY]: getDeliveryTime(),
    })[method ?? order?.method] ?? company?.average_order_preparation_time;
  };
}
