interface Props {
  className?: string;
  style?: Object;
  color?: string;
  standard?: boolean;
}

export default function VisaIcon(props: Props) {
  const { className, style } = props;

  return (
    <svg
      className={className ?? "h-4 w-4"}
      viewBox="0 0 25 9"
      fill="none"
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.53775 8.26304H4.44772L2.88045 2.19717C2.80607 1.91814 2.64812 1.67146 2.41578 1.5552C1.83595 1.26302 1.19702 1.0305 0.5 0.913224V0.679687H3.86688C4.33155 0.679687 4.68006 1.0305 4.73815 1.43792L5.55133 5.81344L7.64035 0.679687H9.67229L6.53775 8.26304ZM10.8341 8.26304H8.86023L10.4856 0.679687H12.4594L10.8341 8.26304ZM15.0129 2.77962C15.071 2.37118 15.4195 2.13765 15.8261 2.13765C16.465 2.07901 17.161 2.19628 17.7419 2.48745L18.0904 0.854716C17.5095 0.62118 16.8706 0.503906 16.2908 0.503906C14.375 0.503906 12.981 1.55431 12.981 3.01215C12.981 4.12119 13.9684 4.70351 14.6654 5.05432C15.4195 5.40412 15.7099 5.63766 15.6518 5.98746C15.6518 6.51215 15.071 6.74569 14.4912 6.74569C13.7942 6.74569 13.0971 6.57079 12.4592 6.27862L12.1107 7.91236C12.8077 8.20352 13.5618 8.3208 14.2588 8.3208C16.4069 8.37842 17.7419 7.32902 17.7419 5.75392C17.7419 3.77038 15.0129 3.65412 15.0129 2.77962ZM24.6502 8.26304L23.0829 0.679687H21.3995C21.051 0.679687 20.7024 0.913224 20.5863 1.26302L17.6841 8.26304H19.716L20.1216 7.15501H22.6182L22.8506 8.26304H24.6502ZM21.6897 2.71875L22.2695 5.57679H20.6442L21.6897 2.71875Z"
        fill="#172B85"
      />
    </svg>
  );
}
