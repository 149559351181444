import {ReactElement} from "react";
import { Switch as BaseSwitch, SwitchProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import collect from "collect.js";

interface Props extends SwitchProps {
  onStateText?: string;
  offStateText?: string;
}

export default function Switch(props: Props): ReactElement {
  const { onStateText, offStateText, ...rest } = props;

  const textStyles = collect({})
    // @ts-ignore
    .when(
      onStateText,
      (collection) => collection.merge({
        "&:after": {
          color: "white",
          fontSize: "12px",
          position: "absolute",
          top: "8px",
          left: "8px",
          content: `"${onStateText}"`,
        },
      }),
    )
    // @ts-ignore
    .when(
      offStateText,
      (collection) => collection.merge({
        "&:before": {
          color: "white",
          fontSize: "12px",
          position: "absolute",
          top: "8px",
          content: `"${offStateText}"`,
          right: "8px"
        },
      }),
    ).all()

  const useStyles = makeStyles({
    root: {
      width: "68px",
      height: "32px",
      padding: "0px"
    },
    switchBase: {
      color: "#818181",
      padding: "1px",
      transitionDuration: '300ms',
      "&$checked": {
        "& + $track": {
          backgroundColor: "#23bf58"
        }
      }
    },
    thumb: {
      color: "white",
      width: "28px",
      height: "28px",
      margin: "1px"
    },
    track: {
      borderRadius: "28px",
      backgroundColor: "#818181",
      opacity: "1 !important",
      transition: "background-color 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;",
      ...textStyles,
    },
    checked: {
      color: "#23bf58 !important",
      transform: "translateX(33px) !important",
    }
  });

  const classes = useStyles();

  return (
    <BaseSwitch
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked
      }}
      {...rest}
    />
  );
}