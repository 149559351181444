import Banners from "./banners";
import Basket from "./basket";
import Buttons from "./buttons";
import Cashback from "./cashback";
import Carousel from "./carousel";
import Checkout from "./checkout";
import Company from "./company";
import Delivery from "./delivery";
import DineIn from "./dine-in";
import Footers from "./footers";
import Franchise from "./franchise";
import Internet from "./internet";
import List from "./list";
import Loading from "./loading";
import LoyaltyProgram from "./loyalty-program";
import Menu from "./menu";
import Navigation from "./navigation";
import OrderType from "./order-type";
import Payment from "./payment";
import Promotion from "./promotion";
import Rewards from "./rewards";
import MetaInfo from "./MetaInfo";
import Error from "./error";
import Note from "./note";
import Order from "./order";
import CookieConsent from "./CookieConsent";
import Progress from "./progress";

export default {
  Banners,
  Basket,
  Buttons,
  Carousel,
  Cashback,
  Checkout,
  Company,
  Delivery,
  DineIn,
  Footers,
  Franchise,
  Internet,
  List,
  Loading,
  LoyaltyProgram,
  Menu,
  Navigation,
  OrderType,
  Payment,
  Promotion,
  Rewards,
  MetaInfo,
  Error,
  Note,
  Order,
  CookieConsent,
  Progress,
};
