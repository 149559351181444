interface Props {
  className?: string;
  style?: Object;
  color?: string;
  standard?: boolean;
}

export default function MasterCardIcon(props: Props) {
  const { className, style } = props;

  return (
    <svg
      className={className ?? "h-4 w-4"}
      viewBox="0 0 26 16"
      fill="none"
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0712 13.5456C11.7548 14.6501 10.0472 15.3169 8.1812 15.3169C4.01773 15.3169 0.642578 11.9974 0.642578 7.90258C0.642578 3.80777 4.01773 0.488281 8.1812 0.488281C10.0472 0.488281 11.7548 1.15505 13.0712 2.25954C14.3876 1.15505 16.0953 0.488281 17.9612 0.488281C22.1247 0.488281 25.4998 3.80777 25.4998 7.90258C25.4998 11.9974 22.1247 15.3169 17.9612 15.3169C16.0953 15.3169 14.3876 14.6501 13.0712 13.5456Z"
        fill="#ED0006"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0713 13.5458C14.6922 12.1858 15.7201 10.1623 15.7201 7.90258C15.7201 5.64289 14.6922 3.61931 13.0713 2.25939C14.3877 1.15498 16.0952 0.488281 17.9611 0.488281C22.1246 0.488281 25.4997 3.80777 25.4997 7.90258C25.4997 11.9974 22.1246 15.3169 17.9611 15.3169C16.0952 15.3169 14.3877 14.6502 13.0713 13.5458Z"
        fill="#F9A000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0715 13.5439C14.6923 12.184 15.7201 10.1605 15.7201 7.90085C15.7201 5.64125 14.6923 3.61773 13.0715 2.25781C11.4506 3.61773 10.4229 5.64124 10.4229 7.90085C10.4229 10.1604 11.4506 12.184 13.0715 13.5439Z"
        fill="#FF5E00"
      />
    </svg>
  );
}
