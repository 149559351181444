import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import AuthorizationTokenStorage from "../auth/AuthorizationTokenStorage";
import { EventHelperTypes } from "../exports/Constants";
import { store } from "../../store";
import HelperMethods from "../exports/HelperMethods";
import ApiMockUtility from "../utilities/ApiMockUtility";
import { Response } from "../exports/Types";
import { ErrorHandlerInterface } from "../exports/Interfaces";
import { collect } from "collect.js";
import GlobalAppUtility from "../utilities/GlobalAppUtility";
import i18n from "i18next";

class BackendApiClient {
  private client: AxiosInstance;
  private translationPath = 'Services:Api:BackendApiClient';

  private authToken = null;
  constructor() {
    this.client = axios.create({
      baseURL: this.getApiBaseUrl(),
      headers: {
        Authorization: `Bearer ${this.authToken}`,
        'x-preferred-locale': i18n.language,
      },
    });
  }

  public async request(
    configuration: AxiosRequestConfig,
    test?: boolean,
    errorHandlers?: ErrorHandlerInterface
  ): Promise<Response<any>> {
    if (await this.getAuthToken()) {
      if (test) {
        return new ApiMockUtility(configuration).requestAuthorized();
      }

      return this.requestAuthorized(configuration, errorHandlers);
    }

    if (test) {
      return new ApiMockUtility(configuration).request();
    }

    return this.requestAsync(configuration, errorHandlers);
  }

  async requestAsync(
    configuration: AxiosRequestConfig,
    errorHandlers?: ErrorHandlerInterface
  ): Promise<Response<any>> {
    try {
      const response = await this.client.request(configuration);

      return {
        success: true,
        response,
        status: response.status,
      };
    } catch (error: any) {
      this.errorHandler(error, errorHandlers);

      return {
        success: false,
        response: error,
        status: error?.response?.status,
      };
    }
  }

  async requestAuthorized(
    configuration: AxiosRequestConfig,
    errorHandlers?: ErrorHandlerInterface
  ): Promise<Response<any>> {
    const newConfig = await this.createRequestConfig(configuration);

    // Check if user token is not null
    // @ts-ignore
    if (!newConfig?.headers?.Authorization.split("Bearer ")[1]) {
      return {
        success: false,
        response: null,
        status: 401,
      };
    }

    try {
      const response = await this.client.request(newConfig);

      return {
        success: true,
        response,
        status: response.status,
      };
    } catch (error: any) {
      this.errorHandler(error, errorHandlers);

      return {
        success: false,
        response: error,
        status: error?.response?.status,
      };
    }
  }

  private errorHandler(error: any, errorHandlers?: ErrorHandlerInterface) {
    switch (error?.response?.status) {
      case 0:
        return this.dispatchNoInternet();
      case 401:
        return this.handleInvalidToken();
      case 412:
        const onConfirm = collect(errorHandlers)
          .keys()
          .contains(error?.response?.data?.error_code)
          ? errorHandlers[error?.response?.data?.error_code]
          : null;

        return GlobalAppUtility.globalAppMethodsContext.toggleInfoModal({
          openModal: true,
          title: error?.response?.data?.title,
          description: error?.response?.data?.description,
          primaryButtonTitle: i18n.t(`${this.translationPath}.info_modal.button_title`),
          onConfirm,
        });
    }
  }

  async createRequestConfig(
    configuration: AxiosRequestConfig
  ): Promise<AxiosRequestConfig> {
    const token = await this.getAuthToken();

    return {
      ...configuration,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
        'x-preferred-locale': i18n.language,
        ...configuration?.headers,
      },
    };
  }

  public handleInvalidToken(): void {
    if (store.getState().eventHelper.event.type || !this.authToken) {
      return;
    }

    HelperMethods.triggerEventSideEffects({
      type: EventHelperTypes.LOG_OUT,
      payload: null,
    });
  }

  private getApiBaseUrl(): string | undefined {
    return import.meta.env.VITE_API_BASE_URL;
  }

  async getAuthToken(): Promise<string> {
    const { authToken } = this;
    if (authToken) {
      return authToken;
    }

    return AuthorizationTokenStorage.getToken() ?? "";
  }

  public deleteAuthToken(): void {
    this.authToken = null;
  }

  public dispatchNoInternet(): void {
    HelperMethods.triggerEventSideEffects({
      type: EventHelperTypes.NO_INTERNET,
      payload: null,
    });
  }
}

export default new BackendApiClient();
