import React from "react";
import useTheme from "../../hooks/ui/useTheme";

interface Props {
  className?: string;
  style?: Object;
  color?: string;
}

export default function ReceiptIcon(props: Props) {
  const { className, color, style } = props;
  const theme = useTheme();

  return (
    <svg
      className={className ?? "h-4 w-4"}
      viewBox="0 0 13 14"
      fill="none"
      style={style}
    >
      <path
        d="M1.98496 14C1.47163 14 1.03704 13.8221 0.681211 13.4662C0.325378 13.1104 0.147461 12.6758 0.147461 12.1625V9.975H2.36996V0L3.41996 1.05L4.46996 0L5.50246 1.05L6.55246 0L7.60246 1.05L8.65246 0L9.70246 1.05L10.7525 0L11.8025 1.05L12.8525 0V12.1625C12.8525 12.6758 12.6745 13.1104 12.3187 13.4662C11.9629 13.8221 11.5283 14 11.015 14H1.98496ZM11.015 12.95C11.2483 12.95 11.4379 12.8771 11.5837 12.7312C11.7295 12.5854 11.8025 12.3958 11.8025 12.1625V1.75H3.41996V9.975H10.2275V12.1625C10.2275 12.3958 10.3004 12.5854 10.4462 12.7312C10.592 12.8771 10.7816 12.95 11.015 12.95ZM4.34746 4.515V3.465H8.54746V4.515H4.34746ZM4.34746 6.86V5.81H8.54746V6.86H4.34746ZM10.175 4.515C10.035 4.515 9.91246 4.4625 9.80746 4.3575C9.70246 4.2525 9.64996 4.13 9.64996 3.99C9.64996 3.85 9.70246 3.7275 9.80746 3.6225C9.91246 3.5175 10.035 3.465 10.175 3.465C10.315 3.465 10.4375 3.5175 10.5425 3.6225C10.6475 3.7275 10.7 3.85 10.7 3.99C10.7 4.13 10.6475 4.2525 10.5425 4.3575C10.4375 4.4625 10.315 4.515 10.175 4.515ZM10.175 6.7725C10.035 6.7725 9.91246 6.72 9.80746 6.615C9.70246 6.51 9.64996 6.3875 9.64996 6.2475C9.64996 6.1075 9.70246 5.985 9.80746 5.88C9.91246 5.775 10.035 5.7225 10.175 5.7225C10.315 5.7225 10.4375 5.775 10.5425 5.88C10.6475 5.985 10.7 6.1075 10.7 6.2475C10.7 6.3875 10.6475 6.51 10.5425 6.615C10.4375 6.72 10.315 6.7725 10.175 6.7725ZM1.96746 12.95H9.17746V11.025H1.19746V12.1625C1.19746 12.3958 1.27038 12.5854 1.41621 12.7312C1.56204 12.8771 1.74579 12.95 1.96746 12.95ZM1.19746 12.95V11.025V12.95Z"
        fill={color ?? theme.color.background.inkWhite.white_0}
      />
    </svg>
  );
}
