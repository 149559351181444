import { useEffect, useMemo, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import CurrencyInput from "../inputs/CurrencyInput";
import useTips, { TipOption } from "../../hooks/order/useTips";
import { Collection } from "collect.js";
import useAppMethods from "../../hooks/utility/useAppMethods";
import useTheme from "../../hooks/ui/useTheme";
import PencilIcon from "../../assets/logo/PencilIcon";

interface Props {
  price: number;
  defaultTip?: string;
  activeIndex: number;
  setActiveIndex: (index: number) => void;
  onChangeTip: (tip: number) => void;
}

interface Item extends TipOption {
  is_selected: boolean;
  amount: number;
}

export default function Tips(props: Props) {
  const { price, defaultTip, activeIndex, setActiveIndex, onChangeTip } = props;

  const theme = useTheme();

  const { formatCurrency } = useAppMethods();

  const { options, calculate } = useTips();

  const [customTipAmount, setCustomTipAmount] = useState<number>(
    parseFloat(defaultTip)
  );

  const items = useMemo<Collection<Item>>(
    () =>
      options.map((item) => ({
        ...item,
        is_selected: activeIndex === item.index,
        amount: item.is_custom ? customTipAmount : calculate(price, item),
      })),
    [options, activeIndex, price, customTipAmount]
  );

  const predefinedItems = useMemo<Item[]>(
    () =>
      items.filter((item: Item) => !item.is_zero && !item.is_custom).toArray(),
    [items]
  );

  const zeroOption = items.first();
  const customOption = items.firstWhere("is_custom");
  const selectedOption = items.firstWhere("is_selected");

  useEffect(() => {
    selectedOption?.percentage && onSelect(selectedOption);
  }, [price]);

  const onChangeCustomTip = (value: number) => {
    setCustomTipAmount(value);
    onChangeTip(value);
  };

  const onSelect = (option: Item) => {
    setActiveIndex(option.index);
    onChangeTip(option?.is_custom ? customTipAmount : calculate(price, option));
  };

  function renderTips() {
    return (
      <div className="w-full grid grid-cols-4 gap-2 flex-nowrap">
        {predefinedItems.map((item: Item) => {
          return (
            <div
              key={`tip-option-${item.index}`}
              className={itemContainer(item.is_selected)}
              onClick={() => onSelect(item)}
              data-test={`tip-item-id-${item.index + 1}`}
            >
              <h5 className="mini semibold">{item.percentage}%</h5>
              <h6 className="tiny">{formatCurrency(item.amount)}</h6>
              {item.description && (
                <p className="text-tiny">{item.description}</p>
              )}
            </div>
          );
        })}
        <div
          className={itemContainer(customOption.is_selected)}
          onClick={() => onSelect(customOption)}
        >
          <div className="w-full h-full flex justify-center items-center">
            <PencilIcon
              color={
                customOption.is_selected
                  ? theme.color.text.light
                  : theme.color.text.default
              }
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Accordion
        expanded={customOption.is_selected}
        style={{ boxShadow: "none", backgroundColor: "transparent" }}
      >
        <AccordionSummary
          style={{
            padding: 0,
            margin: 0,
            width: "100%",
            minHeight: 0,
          }}
          classes={{
            content: "!m-none",
          }}
        >
          {renderTips()}
        </AccordionSummary>
        <AccordionDetails style={{ padding: 0, marginTop: 0 }}>
          <div className="mt-small">
            <CurrencyInput
              id="custom-tip"
              value={customOption.amount}
              onChange={onChangeCustomTip}
            />
          </div>
        </AccordionDetails>
      </Accordion>
      <button
        className={noTipButton(zeroOption.is_selected)}
        onClick={() => onSelect(zeroOption)}
      >
        <p className="mini semibold">{zeroOption.description}</p>
      </button>
    </div>
  );
}

const itemContainer = (active: boolean) =>
  `${
    active
      ? "text-white bg-brand-secondary"
      : "border border-brand-inkGrey-grey_2"
  } py-tiny px-xsmall rounded flex flex-col justify-center items-center`;

const noTipButton = (active: boolean) =>
  `${
    active
      ? "text-white bg-brand-secondary"
      : "border border-brand-inkGrey-grey_2"
  } mt-mini py-mini w-full rounded flex justify-center items-center`;
