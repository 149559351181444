import {Trans, useTranslation} from "react-i18next";
import classnames from "classnames";
import {ReactElement} from "react";
import useSessionStorage, {SESSION_STORAGE_KEYS} from "../../hooks/global/useSessionStorage";
import {COMPLETION_STATUS} from "../../modals/OrderMethodModal";
import {ENVIRONMENTS} from "../../services/exports/Constants";
import useOrderMethodsStatus from "../../hooks/availability/useOrderMethodsStatus";
import {useSelector} from "react-redux";
import {StoreInterface} from "../../store/types";

interface Props {
  className?: string;
}

export default function SavingsBanner(props: Props): ReactElement {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Banners:SavingsBanner' });

  const { className } = props;

  const { company } = useSelector((state: StoreInterface) => state.initialData);

  const { getItem } = useSessionStorage();

  const { deliveryEnabled } = useOrderMethodsStatus();
  const orderMethodInfoComplete = getItem(SESSION_STORAGE_KEYS.order_method_info_status) === COMPLETION_STATUS.complete;

  return !orderMethodInfoComplete
    && !!company?.has_savings_banner
    && import.meta.env.VITE_MODE === ENVIRONMENTS.PROD
    && (
      <div className={classnames('w-full flex justify-center items-center p-mini rounded-lg bg-[#FDF4EA]', className)}>
        <p className="my-auto text-center lg:text-mini text-tiny">
          <Trans t={t}>{deliveryEnabled ? 'text_delivery' : 'text'}</Trans>
        </p>
      </div>
    );
}