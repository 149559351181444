import { getSubdomain } from "tldts";

class DomainResource {
    public extractSubdomain(): string {
        if (window.location.hostname === "localhost") {
            return import.meta.env.VITE_DEFAULT_LOCAL_SUBDOMAIN;
        }

        const subdomainParts = getSubdomain(window.location.href).split(".");

        return subdomainParts[0] === "www" ? subdomainParts[1] : subdomainParts[0];
    }

    public getDomain(): string {
        if (window.location.hostname === "localhost") {
            return `http://localhost:3000`;
        }

        return `https://${getSubdomain(window.location.href)}.${import.meta.env.VITE_BASE_DOMAIN}`;
    }

    public isHttpsEnabled(): boolean {
        return window.location.protocol === "https:";
    }
}

export default new DomainResource();
