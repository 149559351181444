import { actionCreators } from "../../store/actions";
import ReduxActions from "../../store/ReduxActions";
import BackendApiClient from "./BackendApiClient";
import { Response, DataWrappedResponse, DataWrapper } from "../exports/Types";
import {CashbackAccount, ProfileInterface} from "../exports/Interfaces";

class ProfileManager {
  public getProfile = async (): Promise<
    DataWrappedResponse<DataWrapper<ProfileInterface>>
  > => {
    const request = await BackendApiClient.request({
      method: "GET",
      url: "/profile",
    });
    const { response, success } = request;

    if (success) {
      ReduxActions.dispatch(this.profileAction(response?.data?.data));
    }

    return request;
  };

  public putProfile = async (
    data: ProfileInterface
  ): Promise<Response<ProfileInterface>> => {
    const request = await BackendApiClient.request({
      method: "PUT",
      url: "/profile",
      data,
    });
    const { response, success } = request;

    if (success) {
      ReduxActions.batchActions([
        this.profileAction(response?.data?.data),
        this.orderAction(response?.data?.data),
      ]);
    }

    return request;
  };

  public getCashbackAccount = async (companyId: number): Promise<
    DataWrappedResponse<DataWrapper<CashbackAccount>>
  > => {
    const request = await BackendApiClient.request({
      method: "GET",
      url: `/companies/${companyId}/cashback-account`,
    });
    const { response, success } = request;

    if (success) {
      ReduxActions.dispatch(
        actionCreators.initialData.setCashbackAccount(response.data.data)
      );
    }

    return request;
  };

  private profileAction(response: ProfileInterface) {
    return actionCreators.profile.setProfile(response);
  }

  private orderAction(response: ProfileInterface) {
    return actionCreators.order.updateOrder({
      delivery_doorbell_name: response?.delivery_doorbell_name,
      delivery_company_name: response?.delivery_company_name,
      customer_note: response?.customer_note,
      delivery_street_name: response?.delivery_street_name,
      delivery_street_number: response?.delivery_street_number,
      delivery_city: response?.delivery_city,
      delivery_state: response?.delivery_state,
      delivery_country: response?.delivery_country,
      delivery_zip_code: response?.delivery_zip_code,
      delivery_lat: response?.delivery_lat,
      delivery_lng: response?.delivery_lng,
    });
  }
}

export default new ProfileManager();
