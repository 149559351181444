import DineIn from "./dine-in";
import CheckoutScreen from "./CheckoutScreen";
import ErrorScreen from "./ErrorScreen";
import HomeScreen from "./HomeScreen";
import RestaurantDetailsScreen from "./RestaurantDetailsScreen";
import PageNotFound from "./PageNotFound";
import OrderDetailsScreen from "./OrderDetailsScreen";
import OrderBasketScreen from "./OrderBasketScreen";
import RewardDetailsScreen from "./RewardDetailsScreen";
import UploadGoogleReviewScreen from "./UploadGoogleReviewScreen";

export default {
  DineIn,
  CheckoutScreen,
  ErrorScreen,
  HomeScreen,
  RestaurantDetailsScreen,
  PageNotFound,
  OrderDetailsScreen,
  OrderBasketScreen,
  RewardDetailsScreen,
  UploadGoogleReviewScreen,
};
