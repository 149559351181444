import GoogleReviewCard from "./GoogleReviewCard";
import LoyaltyCard from "./LoyaltyCard";
import classnames from "classnames";

interface Props {
  className?: string;
}
const PromotionsList = (props: Props): JSX.Element => {
  const { className } = props;

  return (
    <div className={classnames('grid lg:grid-cols-2 grid-cols-1 lg:gap-2', className)}>
      <LoyaltyCard />
      <GoogleReviewCard containerClassName="border-t" />
    </div>
  );
};

export default PromotionsList;
