import { useEffect, useMemo, useRef, useState } from "react";
import CustomModal from "../../components-library/CustomModal";
import { MODAL_SIZES, SCREENS } from "../../services/exports/Constants";
import BackIcon from "../../assets/logo/BackIcon";
import TablesManager from "../../services/api/TablesManager";
import { TableInterface } from "../../services/exports/Interfaces";
import useFeedbackHandler from "../../hooks/utility/useFeedbackHandler";
import CustomButton from "../../components-library/buttons/CustomButton";
import useDineInFlow from "../../hooks/global/useDineInFlow";
import LoadingIndicator from "../../components-library/loading/LoadingIndicator";
import PartiesManager from "../../services/api/PartiesManager";
import Divider from "../../components-library/Divider";
import { Picker } from "react-mobile-style-picker";
import { collect } from "collect.js";
import DineInNavBar from "../../components-library/navigation/DineInNavBar";
import useToast from "../../hooks/utility/useToast";
import useStore from "../../hooks/global/useStore";
import { useTranslation } from "react-i18next";
import ButtonFooter from "../../components-library/footers/ButtonFooter";
import useAppState from "../../hooks/global/useAppState";
import useNavigate from "../../hooks/navigation/useNavigate";
import useAppMethods from "../../hooks/utility/useAppMethods";

export default function ChangeTablePartyModal() {
  const { t } = useTranslation(null, { keyPrefix: "Modals:DineIn:ChangeTablePartyModal" });

  const { navigate } = useNavigate();
  const { toggleChangeTablePartyModal } = useAppMethods();
  const { table, party, userOrdered } = useDineInFlow();

  const { openModal, onTableSelected } = useAppState().changeTablePartyModalProps;
  const { company } = useStore()?.initialData;
  const { rewardInfoModalSeen } = useStore()?.dineInFlow;

  const ref = useRef(onTableSelected);
  ref.current = onTableSelected;

  const { feedback, setFeedback, renderFeedbackLabel, resetFeedback } =
    useFeedbackHandler({ renderToasts: true });

  const toast = useToast();

  const [changeTable, setChangeTable] = useState(userOrdered || !party?.id);

  const [selectedTableId, setSelectedTableId] = useState(table?.id);

  const [tables, setTables] = useState<TableInterface[]>([]);

  const [loadingTables, setLoadingTables] = useState(false);

  const [updatingTable, setUpdatingTable] = useState(false);

  useEffect(() => {
    if (userOrdered || !party?.id) {
      setChangeTable(true);
    }
  }, [openModal]);

  useEffect(() => {
    if (userOrdered || !party?.id) {
      setChangeTable(true);
    }
  }, [userOrdered, party?.id]);

  useEffect(() => {
    if (changeTable && company?.id) {
      loadTables();
    }
  }, [changeTable, company?.id]);

  const goBack = () => {
    if (userOrdered || !party?.id) {
      return;
    }

    setChangeTable(false);
  };

  function toggleLoading() {
    setTimeout(() => setLoadingTables(false), 500);
  }

  function toggleModal(): void {
    toggleChangeTablePartyModal({ openModal: false });
  }

  async function loadTables() {
    setLoadingTables(true);
    const { success, response } = await TablesManager.get(company?.id);
    toggleLoading();

    if (!success) {
      return setFeedback({
        type: "Error",
        message: t("feedback.failed_to_load_tables"),
      });
    }

    setTables(response?.data?.data);
    if (!selectedTableId) {
      setSelectedTableId(collect(response?.data?.data).first()?.id);
    }

    resetFeedback();
  }

  async function updateTable() {
    setUpdatingTable(true);
    if (party?.id) {
      const { success } = await PartiesManager.update(party?.id, {
        table_id: selectedTableId,
      });

      !success && handleError();
    }

    const { success } = await TablesManager.show(selectedTableId);
    !success && handleError();
    setUpdatingTable(false);
    setChangeTable(false);
    onTableSelected && onTableSelected();
    toggleModal();

    if (table?.id !== selectedTableId && rewardInfoModalSeen) {
      setTimeout(() => toast.success(t("toasts.table_changed")), 500);
    }
  }

  const handleError = () =>
    setFeedback({
      type: "Error",
      message: t("feedback.failed_to_load_table_data"),
    });

  const renderTablePicker = useMemo(() => {
    if (loadingTables) {
      return (
        <div className="mx-auto py-small w-fit">
          <LoadingIndicator lg={60} md={50} sm={40} />
        </div>
      );
    }

    return (
      changeTable && (
        <>
          <Picker
            className="w-full my-small"
            value={selectedTableId}
            onChange={(value) => setSelectedTableId(value)}
          >
            {tables.map((item) => (
              <Picker.Item key={`table-option-${item?.id}`} value={item?.id}>
                {item?.number}
              </Picker.Item>
            ))}
          </Picker>

          <ButtonFooter>
            <CustomButton
              title={t("buttons.confirm")}
              loading={updatingTable}
              onClick={updateTable}
            />
          </ButtonFooter>
        </>
      )
    );
  }, [
    changeTable,
    loadingTables,
    tables,
    selectedTableId,
    updatingTable,
    onTableSelected,
  ]);

  const renderContent = useMemo(
    () =>
      !changeTable && (
        <>
          <div className={action} onClick={() => setChangeTable(true)}>
            <h6>{t("titles.table")}</h6>
            <BackIcon className={actionIcon} />
          </div>
          <Divider />
          <div
            className={action}
            onClick={() => {
              navigate(SCREENS.TABLE, {
                state: {
                  prevPath: window.location.pathname,
                },
              });
              toggleModal();
            }}
          >
            <h6>{t("titles.group")}</h6>
            <BackIcon className={actionIcon} />
          </div>
        </>
      ),
    [changeTable]
  );

  const renderFeedback = useMemo(
    () =>
      feedback?.message && (
        <>
          {renderFeedbackLabel}
          <div className="px-small mb-small">
            <CustomButton
              title={t("buttons.reload")}
              className="mt-small"
              onClick={loadTables}
              loading={loadingTables}
            />
          </div>
        </>
      ),
    [feedback, loadingTables]
  );

  const getTitle = () => {
    if (changeTable) {
      return table?.id ? t("titles.change_table") : t("titles.select_table");
    }

    return t("titles.main");
  };

  return (
    <CustomModal
      open={openModal}
      size={MODAL_SIZES.SM}
      onClose={toggleModal}
      position="bottom"
      className="w-full"
    >
      <div className={container}>
        <div className={headerContainer}>
          <div className="border-b rounded-t-lg">
            <DineInNavBar
              title={getTitle()}
              hideBackIcon={!changeTable || userOrdered || !party?.id}
              transparent
              leftClick={goBack}
              rightClick={toggleModal}
              className="rounded-t-lg"
            />
          </div>
          <div>
            {renderContent}
            {!feedback?.message && renderTablePicker}
            {renderFeedback}
          </div>
        </div>
      </div>
    </CustomModal>
  );
}

const container = "w-full bg-background-inkWhite-white_1 rounded-t-lg";

const headerContainer =
  "sticky top-0 bg-background-inkWhite-white_1 z-10 rounded-t-lg";

const action = "flex justify-between p-small";

const actionIcon = "h-4 w-4 rotate-180";
