import React from "react";
import CloseIcon from "../assets/logo/CloseIcon";
import { ThemeInterface } from "../services/exports/Interfaces";
import useTheme from "../hooks/ui/useTheme";
import CustomModal from "../components-library/CustomModal";
import { MODAL_SIZES } from "../services/exports/Constants";

interface Props {
  title: string;
  description: string;
  openModal: boolean;
  toggleModal: () => void;
}

export default function AlertModal(props: Props) {
  const { title, description, openModal, toggleModal } = props;
  const theme: ThemeInterface = useTheme();

  return (
    <CustomModal open={openModal} size={MODAL_SIZES.SM} onClose={toggleModal}>
      <div className={container}>
        <div className={wrapper}>
          {title && <h4 className={titleStyle}>{title}</h4>}
          {description && <p>{description}</p>}
        </div>
        <div onClick={toggleModal} className="cursor-pointer">
          <CloseIcon
            className={closeIconStyle}
            color={theme?.color.text.default}
          />
        </div>
      </div>
    </CustomModal>
  );
}

const container =
  "w-full h-full p-medium flex flex-row justify-between bg-background-inkWhite-white_1";

const wrapper = "pr-small";

const titleStyle = "mb-mini";

const closeIconStyle = "h-4 w-4";
