import AppMethodsContext from "../context/AppMethodsContext";
import { useCallback, useContext, useMemo } from "react";
import {
  ChangeTablePartyModalProps, ComboModalProps, ComboUpsellModalProps,
  FreeProductsModalProps,
  GlobalAppContextMenuInterface,
  GlobalAppMethodsContextInterface,
  LoginModalProps,
  OrdersDisabledModalProps,
  OrderMethodModalProps, OpeningHoursScheduleInterface,
} from "../services/exports/Interfaces";
import { APP_STATE_REDUCER_TYPES } from "../services/exports/Constants";
import { ConfirmCancelModalProps } from "../modals/ConfirmCancelModal";
import { AllOrdersPaidModalProps } from "../modals/dine-in/AllOrdersPaidModal";
import AppStateContext from "../context/AppStateContext";
import { MenuItemModalProps, FormatCurrencyOptions } from "../services/exports/Interfaces";
import { useSelector } from "react-redux";
import { StoreInterface } from "../store/types";
import HelperMethods from "../services/exports/HelperMethods";
import moment, { Moment } from "moment-timezone";
import PickUpTimesResource from "../services/resources/PickUpTimesResource";

interface Props {
  children: any;
}

export default function AppMethodsProvider(props: Props): JSX.Element {
  const { children } = props;

  const { dispatch } = useContext(AppStateContext);

  const { company } = useSelector((state: StoreInterface) => state.initialData);

  const formatTime = useCallback<(datetime: string|Moment, format?: undefined|string) => string>(
    (datetime, format = undefined) => {
      const _format = format ?? (company?.country === 'US' ? 'h:mm a' : 'HH:mm')

      return moment(datetime).format(_format);
    },
    [company?.country]
  );

  const formatDateTime = useCallback<(datetime: string|Moment, format: { format?: undefined|string, dateFormat?: undefined|string, timeFormat?: undefined|string }) => string>(
    (datetime: string|Moment, {
      format = undefined,
      dateFormat = undefined,
      timeFormat = undefined,
    } = {}) => {
      const _dateFormat = dateFormat ?? 'DD.MM.YYYY';
      const _timeFormat = timeFormat ?? (company?.country === 'US' ? 'h:mm a' : 'HH:mm')
      const _format = format ?? `${_dateFormat} ${_timeFormat}`.trim();

      return moment(datetime).format(_format);
    },
    [company?.country]
  );

  const formatScheduleUnit = useCallback(
    (scheduleUnit: Moment|string|OpeningHoursScheduleInterface, {
        prefix = undefined,
        day = undefined,
        time = true,
      } = {}): string => ((prefix ?? '') + ' ' + formatDateTime(
      (typeof scheduleUnit === 'string' || moment.isMoment(scheduleUnit))
        ? moment(scheduleUnit)
        : PickUpTimesResource.createDate(scheduleUnit),
      {
        dateFormat: day ? 'dddd' : '',
        timeFormat: time ? undefined : '',
      }
    )).trim(),
    [formatDateTime]
  );

  const context: GlobalAppMethodsContextInterface = useMemo(
    () => ({
      toggleLoginModal: (payload: LoginModalProps) => {
        try {
          dispatch({
            type: APP_STATE_REDUCER_TYPES.TOGGLE_LOGIN_MODAL,
            payload: {
              loginModalProps: payload,
            },
          });
        } catch (error) {}
      },
      toggleInfoModal: (payload: ConfirmCancelModalProps) => {
        try {
          dispatch({
            type: APP_STATE_REDUCER_TYPES.TOGGLE_INFO_MODAL,
            payload: {
              infoModalProps: payload,
            },
          });
        } catch (error) {}
      },
      toggleAllOrdersPaidModal: (payload: AllOrdersPaidModalProps) => {
        try {
          dispatch({
            type: APP_STATE_REDUCER_TYPES.TOGGLE_ALL_ORDERS_PAID_MODAL,
            payload: {
              allOrderPaidModalProps: payload,
            },
          });
        } catch (error) {}
      },
      toggleComboUpsellModal: (payload?: ComboUpsellModalProps) => {
        try {
          if (! payload) {
            return dispatch({
              type: APP_STATE_REDUCER_TYPES.TOGGLE_COMBO_UPSELL_MODAL,
              payload: {
                comboUpsellModalProps: {
                  openModal: false,
                  product: null,
                  combos: null,
                },
              },
            });
          }

          dispatch({
            type: APP_STATE_REDUCER_TYPES.TOGGLE_COMBO_UPSELL_MODAL,
            payload: {
              comboUpsellModalProps: payload,
            },
          });
        } catch (error) {}
      },
      toggleMenuItemModal: (payload?: MenuItemModalProps) => {
        try {
          if (! payload) {
            return dispatch({
              type: APP_STATE_REDUCER_TYPES.TOGGLE_MENU_ITEM_MODAL,
              payload: {
                menuItemModalProps: {
                  openModal: false,
                  product: null,
                  lineItem: null,
                },
              },
            });
          }

          dispatch({
            type: APP_STATE_REDUCER_TYPES.TOGGLE_MENU_ITEM_MODAL,
            payload: {
              menuItemModalProps: payload,
            },
          });
        } catch (error) {}
      },
      toggleComboModal: (payload?: ComboModalProps) => {
        try {
          if (! payload) {
            return dispatch({
              type: APP_STATE_REDUCER_TYPES.TOGGLE_COMBO_MODAL,
              payload: {
                comboModalProps: {
                  openModal: false,
                  combo: null,
                  lineItem: null,
                },
              },
            });
          }

          dispatch({
            type: APP_STATE_REDUCER_TYPES.TOGGLE_COMBO_MODAL,
            payload: {
              comboModalProps: payload,
            },
          });
        } catch (error) {}
      },
      toggleFreeProductsModal: (payload: FreeProductsModalProps) => {
        try {
          dispatch({
            type: APP_STATE_REDUCER_TYPES.TOGGLE_FREE_PRODUCTS_MODAL,
            payload: {
              freeProductsModalProps: payload,
            },
          });
        } catch (error) {}
      },
      saveMenu: (data: GlobalAppContextMenuInterface) => {
        try {
          dispatch({
            type: APP_STATE_REDUCER_TYPES.SAVE_MENU,
            payload: {
              menu: data,
            },
          });
        } catch (error) {}
      },
      toggleOrderMethodModal: (payload: OrderMethodModalProps) => {
        try {
          dispatch({
            type: APP_STATE_REDUCER_TYPES.TOGGLE_ORDER_METHOD_MODAL,
            payload: {
              orderMethodModalProps: payload,
            },
          });
        } catch (error) {}
      },
      toggleChangeTablePartyModal: (payload: ChangeTablePartyModalProps) => {
        try {
          dispatch({
            type: APP_STATE_REDUCER_TYPES.TOGGLE_CHANGE_TABLE_PARTY_MODAL,
            payload: {
              changeTablePartyModalProps: payload,
            },
          });
        } catch (error) {}
      },
      toggleOrdersDisabledModal: (payload: OrdersDisabledModalProps) => {
        try {
          dispatch({
            type: APP_STATE_REDUCER_TYPES.TOGGLE_ORDERS_DISABLED_MODAL,
            payload: {
              ordersDisabledModalProps: payload,
            },
          });
        } catch (error) {}
      },
      formatCurrency: (amount: number, options?: FormatCurrencyOptions): number|string => HelperMethods.formatCurrency(
        amount,
        {
          ...options,
          currency: options?.currency ?? company?.currency ?? 'EUR',
        },
      ),
      formatTime,
      formatDateTime,
      formatScheduleUnit,
      createScheduledOrderSlot: (time: Moment|string): string => {
        return formatTime(time) + ' - ' + formatTime(moment(time).add(company?.scheduled_orders_time_slot_interval, 'minutes'));
      },
    }),
    [
      formatTime,
      formatDateTime,
      formatScheduleUnit,
      company?.currency,
      company?.scheduled_orders_time_slot_interval
    ]
  );

  return (
    <AppMethodsContext.Provider value={context}>
      {children}
    </AppMethodsContext.Provider>
  );
}
