import Lottie from "react-lottie-player";
import CheckMarkAnimation from "../../assets/animations/CheckMarkAnimation.json";
import CustomModal from "../../components-library/CustomModal";
import { SCREENS } from "../../services/exports/Constants";
import CustomButton from "../../components-library/buttons/CustomButton";
import {
  OrderPaymentInterface,
  PartyInterface,
} from "../../services/exports/Interfaces";
import {useTranslation} from "react-i18next";
import useAppState from "../../hooks/global/useAppState";
import useNavigate from "../../hooks/navigation/useNavigate";
import useAppMethods from "../../hooks/utility/useAppMethods";

export interface AllOrdersPaidModalProps {
  openModal: boolean;
  order?: OrderPaymentInterface;
  party?: PartyInterface;
}

const AllOrdersPaidModal = (): JSX.Element => {
  const {t} = useTranslation(null, {keyPrefix: 'Modals:DineIn:AllOrdersPaidModal'});

  const { openModal, order, party } = useAppState()?.allOrderPaidModalProps;

  const { toggleAllOrdersPaidModal } = useAppMethods();
  const { navigate } = useNavigate();

  function onContinue(): void {
    navigate(`${SCREENS.PAYMENT_SUCCESSFUL}/${party?.id}`);
    toggleAllOrdersPaidModal({ openModal: false });
  }

  return (
    <CustomModal open={openModal} fullScreen>
      <div className="w-full h-full">
        <div className="w-full h-full px-small flex flex-col justify-center">
          <div className="mb-small">
            <Lottie
              animationData={CheckMarkAnimation}
              loop={false}
              play
              style={{ height: window.innerHeight * 0.15 }}
            />
          </div>
          <h4 className="mb-small text-center">
            {t('title', { user: order?.user?.first_name })}
          </h4>
          <p className="text-center mini text-brand-text-grey">
            {t('description')}
          </p>
        </div>
      </div>
      <div
        className={
          "w-full flex justify-center items-center lg:px-medium px-small py-xsmall border-t border-brand-inkGrey-grey_2 lg:relative fixed bottom-0 bg-background-inkWhite-white_0 rounded-t-xl"
        }
      >
        <CustomButton
          title={t('buttons.continue')}
          onClick={onContinue}
        />
      </div>
    </CustomModal>
  );
};

export default AllOrdersPaidModal;
