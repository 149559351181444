import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { REDUX_STORE_SLICES } from "../../services/exports/Constants";
import {
  PaymentOptionPropertiesInterface,
  PaymentOptionsStoreInterface,
} from "../../services/exports/Interfaces";
import collect from "collect.js";

export default createSlice({
  name: REDUX_STORE_SLICES.PAYMENT_OPTIONS,
  initialState: {
    payment_options: [],
  },
  reducers: {
    setPaymentOptions: (state: PaymentOptionsStoreInterface, action: PayloadAction<PaymentOptionPropertiesInterface[]>) => {
      try {
        state.payment_options = action.payload;
      } catch (error) {}
    },
    addPaymentOption: (state: PaymentOptionsStoreInterface, action) => {
      try {
        state.payment_options = collect(state.payment_options)
          .push(action.payload)
          .map((paymentMethod: PaymentOptionPropertiesInterface) => {
            return {
              ...paymentMethod,
              is_default:
                paymentMethod?.payment_method_id === action.payload?.payment_method_id
                  ? true
                  : false,
            };
          })
          .toArray();
      } catch (error) {}
    },
    removePaymentOption: (state: PaymentOptionsStoreInterface, action) => {
      const { removePaymentOptionId, newDefaultPaymentOption } = action.payload;

      try {
        state.payment_options = collect(state.payment_options)
          .reject((item) => item.id === removePaymentOptionId)
          .map((paymentMethod: PaymentOptionPropertiesInterface) => {
            return {
              ...paymentMethod,
              is_default:
                paymentMethod?.payment_method_id ===
                newDefaultPaymentOption?.payment_method_id
                  ? true
                  : false,
            };
          })
          .toArray()
      } catch (error) {}
    },
    updatePaymentOption: (state: PaymentOptionsStoreInterface, action) => {
      try {
        state.payment_options = collect(state.payment_options)
          .map((paymentMethod: PaymentOptionPropertiesInterface) => {
            return {
              ...paymentMethod,
              is_default: paymentMethod?.id === action.payload?.id ? true : false,
            };
          })
          .toArray();
      } catch (error) {}
    },
  },
});

export const props = (state: PaymentOptionsStoreInterface) => state;
