import {ReactElement, useEffect, useMemo, useState} from "react";
import StripeContext from "../context/StripeContext";
import PaymentManager from "../services/api/PaymentManager";
import AuthorizationTokenStorage from "../services/auth/AuthorizationTokenStorage";
import {PaymentMethod,} from "../services/exports/Constants";
import {ElementOptionInterface, PaymentOptionPropertiesInterface,} from "../services/exports/Interfaces";
import * as stripeJs from "@stripe/stripe-js";
import {loadStripe, PaymentMethod as StripePaymentMethod} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import {useSelector} from "react-redux";
import {StoreInterface} from "../store/types";
import collect from "collect.js";
import useLoading from "../hooks/global/useLoading";
import CompanySlugObserver from "../components-library/navigation/middleware/CompanySlugObserver";

interface Props {
  children: any;
}

export default function StripeProvider(props: Props): ReactElement {
  const { children } = props;

  const { company } = useSelector((state: StoreInterface) => state.initialData);

  const { setLoading: setGlobalLoading } = useLoading();

  const [loading, setLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<ElementOptionInterface>({ clientSecret: null });
  const [paymentMethod, setPaymentMethod] = useState<StripePaymentMethod>(null);
  const [stripe, setStripe] = useState<stripeJs.Stripe>(null);
  const [elements, setElements] = useState<stripeJs.StripeElements>(null);
  const [isDirect, setIsDirect] = useState(true);
  const [enabledWallets, setEnabledWallets] = useState([]);

  const stripePromise = useMemo<null|Promise<stripeJs.Stripe>>(
    () => !company?.service_provider?.stripe_key
      ? null
      : loadStripe(
        company?.service_provider?.stripe_key,
        // @ts-ignore
        collect().when(isDirect, (options) => options.put('stripeAccount', company?.stripe_id)).all()
      ),
    [company?.service_provider?.stripe_key, company?.stripe_id, isDirect]
  );
  const stripeMounted = stripePromise !== null;

  useEffect(() => {
    stripeMounted && setTimeout(() => setGlobalLoading(false), 1000);
  }, [stripeMounted]);

  const guessPaymentDestination = (paymentMethod: PaymentMethod) => paymentMethod !== PaymentMethod.Paypal && paymentMethod !== PaymentMethod.ApplePay;
  const setPaymentDestination = (direct: boolean) => setIsDirect(direct);

  const context = useMemo(
    () => ({
      loading,
      stripeMounted,
      options,
      stripe,
      elements,
      paymentMethod,
      enabledWallets,
      setStripe,
      setElements,
      getClientSecret,
      setPaymentMethod,
      setEnabledWallets,
      guessPaymentDestination,
      setPaymentDestination,
    }),
    [loading, options, stripe, elements, company, paymentMethod, enabledWallets, stripeMounted]
  );

  async function getClientSecret(
    paymentOption: PaymentOptionPropertiesInterface
  ): Promise<void> {
    if (
      !AuthorizationTokenStorage.getToken() ||
      !paymentOption?.payment_method_id ||
      paymentOption?.payment_method_id === PaymentMethod.Cash ||
      paymentOption?.payment_method_id === PaymentMethod.ApplePay ||
      paymentOption?.payment_method_id === PaymentMethod.GooglePay ||
      paymentOption?.payment_method_id === PaymentMethod.PaypalStandalone ||
      paymentOption?.payment_method_id === PaymentMethod.Paypal ||
      paymentOption?.payment_method_id === PaymentMethod.Giropay
    ) {
      return;
    }

    const data = {
      company_id: company?.id,
      payment_methods: [paymentOption?.payment_method_id],
    };

    setLoading(true);
    const { response, success } = await PaymentManager.postClientSecretKey(data);

    if (success) {
      setOptions({
        clientSecret: response?.data?.client_secret
      });
    }

    setTimeout(() => setLoading(false), 20);
  }

  return (
    <StripeContext.Provider value={context}>
      {stripePromise ? (
        <Elements
          key={`company-${company?.stripe_id}-${isDirect}`}
          stripe={stripePromise}
        >
          {children}
        </Elements>
      ) : <CompanySlugObserver>{null}</CompanySlugObserver>}
    </StripeContext.Provider>
  );
}
