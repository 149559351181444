import React, {useEffect, useState} from 'react';
import {ScheduledOrderSlotInterface} from "../../services/exports/Interfaces";
import {Moment} from "moment";
import moment from "moment";
import {useSelector} from "react-redux";
import {StoreInterface} from "../../store/types";
import {useTranslation} from "react-i18next";
import {ORDER_METHODS} from "../../services/exports/Constants";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import {InputLabel} from "@mui/material";
import ScheduledOrderSlotsManager from "../../services/api/ScheduledOrderSlotsManager";
import LoadingIndicator from "../loading/LoadingIndicator";
import collect from "collect.js";
import DisabledPreOrderDatesManager from "../../services/api/DisabledPreOrderDatesManager";
import FeedbackLabel from "../labels/tinyLabels/FeedbackLabel";
import useAppMethods from "../../hooks/utility/useAppMethods";
import useOrderMethodsStatus from "../../hooks/availability/useOrderMethodsStatus";

interface Props {
  scheduleTime: null|Moment;
  orderMethod: ORDER_METHODS;
  isSelectedTimeInvalid: boolean;
  setScheduleTime: (value: Moment) => void;
  setIsSelectedTimeInvalid: (value: boolean) => void;
}

export default function ScheduledOrderInfo(props: Props) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Order:ScheduledOrderInfo' });

  const { scheduleTime, orderMethod, isSelectedTimeInvalid, setScheduleTime, setIsSelectedTimeInvalid } = props;

  const { company } = useSelector((state: StoreInterface) => state.initialData);

  const { formatTime } = useAppMethods();
  const { isOrderMethodOpenForFutureOrders } = useOrderMethodsStatus();

  const [currentMonth, setCurrentMonth] = useState<Moment>(scheduleTime);
  const [disabledDates, setDisabledDates] = useState<number[]>(null);
  const [loadingDisabledDates, setLoadingDisabledDates] = useState(false);

  const [slots, setSlots] = useState<ScheduledOrderSlotInterface[]>([]);
  const [loadingSlots, setLoadingSlots] = useState(false);

  useEffect(() => {
    setIsSelectedTimeInvalid(
      scheduleTime
      && disabledDates
      && (
        scheduleTime.isBefore(moment())
        || (scheduleTime.isSame(currentMonth, 'month') && disabledDates.includes(scheduleTime.date()))
      )
    );
  }, [scheduleTime, disabledDates]);

  useEffect(() => {
    loadDisabledDates();
  }, [currentMonth?.month()]);

  useEffect(() => {
    !scheduleTime && disabledDates && setDefaultDate();
  }, [disabledDates]);

  useEffect(() => {
    loadSlots();
  }, [scheduleTime?.format('DD-MM-YYYY'), orderMethod]);

  const isTimeAvailable = (time: Moment, useSlots?: ScheduledOrderSlotInterface[]) =>
    !!collect(useSlots ?? slots)
      .first((item) => moment(item.start_time).isSame(time));

  async function loadDisabledDates() {
    setLoadingDisabledDates(true);
    const { success, response } = await DisabledPreOrderDatesManager.get(company?.id, {
      order_method: orderMethod,
      month: (currentMonth?.month() ?? moment().month()) + 1,
      year: currentMonth?.year() ?? moment().year(),
    })

    if (! success) {
      return setLoadingDisabledDates(false);
    }

    setDisabledDates(response.data);
    setLoadingDisabledDates(false);
  }

  function setDefaultDate() {
    for (let i = 0; i < 30; i++) {
      const date = moment().add(i, 'days').set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

      if (! shouldDisableDate(date)) {
        setScheduleTime(date);

        break;
      }
    }
  }

  async function loadSlots() {
    setLoadingSlots(true);

    const date = isOrderMethodOpenForFutureOrders(orderMethod) || moment(scheduleTime ?? undefined).isSame(moment(), 'day')
      ? moment(scheduleTime ?? undefined)
      : moment();
    const { success, response } = await ScheduledOrderSlotsManager.getSlots(company?.id, {
      order_method: orderMethod,
      date: date.format('DD-MM-YYYY')
    });

    if (! success) {
      return setLoadingSlots(false);
    }

    if (response.data.length === 0) {
      setSlots(response.data);

      return setLoadingSlots(false);
    }

    if (! currentMonth) {
      setCurrentMonth(moment(response.data[0].start_time));
    }
    if (! scheduleTime || ! isTimeAvailable(scheduleTime, response.data)) {
      setScheduleTime(moment(response.data[0].start_time));
    }

    setSlots(response.data);
    setLoadingSlots(false);
  }

  const renderTimePicker = () => {
    if (loadingSlots) {
      return (
        <div className="flex justify-center">
          <LoadingIndicator />
        </div>
      );
    }

    return (
      <FormControl>
        <InputLabel id="time-select-label">{t('labels.time')}</InputLabel>
        <Select
          labelId="time-select-label"
          id="time-select"
          value={scheduleTime?.unix()}
          label={t('labels.time')}
          onChange={(e) => setScheduleTime(moment.unix(e.target.value as number))}
          MenuProps={{
            style: { zIndex: 9999 },
          }}
        >
          {slots.map((item) => (
            <MenuItem
              value={moment(item.start_time).unix()}
              key={`scheduled-order-slot-${item.start_time}`}
            >
              {formatTime(item.start_time)}
              {' - '}
              {formatTime(item.end_time)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }

  const shouldDisableDate = (date: Moment) => collect(disabledDates ?? []).contains(date.date());

  return (
    company?.has_scheduled_orders && (
      <div className="mt-small">
        <div className="grid grid-cols-2 gap-2">
          <DatePicker
            label={t('labels.date')}
            value={scheduleTime}
            onChange={setScheduleTime}
            onMonthChange={(date) => setCurrentMonth(date)}
            format="dddd, DD MMM"
            views={['day']}
            slotProps={{
              dialog: {
                style: { zIndex: 9999 },
              },
              popper: {
                style: { zIndex: 9999 },
              },
            }}
            shouldDisableDate={shouldDisableDate}
            disabled={!isOrderMethodOpenForFutureOrders(orderMethod)}
            disablePast
            loading={loadingDisabledDates}
            renderLoading={() => <LoadingIndicator />}
          />
          {renderTimePicker()}
        </div>
        {isSelectedTimeInvalid && !loadingSlots && (
          <FeedbackLabel
            message={t('feedback.selected_time_invalid')}
            type="Notice"
            className="mt-small"
          />
        )}
      </div>
    )
  )
}