import { CompanyLocation } from "../../services/exports/Interfaces";
import moment, {Moment} from "moment";
import {useSelector} from "react-redux";
import {StoreInterface} from "../../store/types";
import collect from "collect.js";

export default function useCompanyLocation(datetime: string|Moment = undefined): CompanyLocation {
  const { company } = useSelector((store: StoreInterface) => store.initialData);

  const date = moment(datetime ?? undefined);

  function defaultLocation(): CompanyLocation {
    return {
      address: company?.address,
      city: company?.city,
      zip_code: company?.zip_code,
      country: company?.country,
      lat: company?.lat,
      lon: company?.lon,
    };
  }

  if (! company?.has_multiple_locations) {
    return collect(company?.locations).first() ?? defaultLocation();
  }

  return collect(company?.locations).filter(
    (item: CompanyLocation) => item.day === date.day() && (
      item.hour < date.hour()
      || (item.hour === date.hour() && item.minute <= date.minute())
    )
  ).sort(
    (a: CompanyLocation, b: CompanyLocation) => (b.hour * 1000 + b.minute) - (a.hour * 1000 + a.minute)
  ).first() ?? collect(company?.locations).firstWhere('day', date.day()) ?? defaultLocation();
}
