import React from "react";

interface Props {
  illustration: any;
  title: string;
  description: string;
}

export default function IllustrationExplainer(props: Props) {
  const { illustration, title, description } = props;
  return (
    <div className={container}>
      <div className="mb-small">{illustration}</div>
      <h6 className={titleStyle}>{title}</h6>
      <p className={descriptionStyle}>{description}</p>
    </div>
  );
}

const container = "flex flex-col items-center";

const titleStyle = "mb-xsmall text-center";

const descriptionStyle = "text-center";
