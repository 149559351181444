import { CategoryInterface } from "../../../services/exports/Interfaces";
import classnames from "classnames";

interface Props {
  key: string;
  itemId?: string;
  category?: CategoryInterface;
  focusedCategoryId?: number;
  onClick: (id?: number) => void;
}

export default function Card(props: Props) {
  const { category, focusedCategoryId, onClick } = props;
  const active = focusedCategoryId === category?.id;

  return (
    <div
      className={labelContainer}
      onClick={() => onClick(category?.id)}
      key={category?.id}
    >
      <p className={titleStyle}>{category?.name}</p>
      {active && (
        <div className="h-[5px] w-full absolute px-xxsmall bottom-0">
          <div className="h-full w-full bg-companyBrand-primary rounded-t-lg" />
        </div>
      )}
    </div>
  );
}

const labelContainer = `cursor-pointer relative px-xxsmall py-small mr-tiny rounded flex justify-center items-center`;

const titleStyle =
  "relative whitespace-nowrap mini text-brand-text-grey lg:hover:text-companyBrand-primary select-none uppercase";
