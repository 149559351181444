import React from "react";
import useTheme from "../../hooks/ui/useTheme";
import { ThemeInterface } from "../../services/exports/Interfaces";

interface Props {
  className?: string;
  style?: Object;
  color?: string;
}

export default function CutleryIcon(props: Props) {
  const { className, color, style } = props;
  const theme: ThemeInterface = useTheme();

  return (
    <svg
      className={className ?? "h-4 w-4"}
      viewBox="0 0 17 16"
      fill="none"
      style={style}
    >
      <path
          d="M1.5451 16L0.608412 15.0633L9.9753 5.69642C9.6482 4.98275 9.57386 4.24307 9.75228 3.47736C9.9307 2.71165 10.3544 1.99427 11.0235 1.3252C11.6926 0.671009 12.5029 0.250986 13.4544 0.0651347C14.406 -0.120716 15.1866 0.0911537 15.7962 0.700745C16.4206 1.3252 16.6325 2.10578 16.4318 3.04247C16.231 3.97916 15.7813 4.80434 15.0825 5.518C14.4729 6.14246 13.7927 6.55133 13.0418 6.74462C12.291 6.9379 11.5885 6.8933 10.9343 6.61081L9.44005 8.10505L16.3983 15.0633L15.4616 16L8.50336 9.04174L1.5451 16ZM4.33287 8.17196L1.67891 5.518C0.920642 4.75973 0.526638 3.86021 0.496901 2.81945C0.467165 1.77868 0.809131 0.85686 1.5228 0.0539836L6.98682 5.518L4.33287 8.17196Z"
          fill={color ?? theme.color.companyBrand.primary}
      />
    </svg>
  );
}
