import {ReactElement, useCallback, useEffect, useMemo, useRef, useState} from "react";
import StepperItem from "./StepperItem";
import collect from "collect.js";

export interface StepItem<Step = string> {
  id: Step;
  title: (isActive: boolean) => any;
  description?: (isActive: boolean) => any;
  is_required: boolean;
  is_collapsable: boolean;
  is_complete: boolean;
  render: (openFirstIncompleteStep: () => void) => any;
}

interface Props<Step = string> {
  steps: StepItem<Step>[];
  onProgress?: (complete: boolean) => void;
}

export default function Stepper(props: Props): ReactElement {
  const { steps, onProgress } = props;

  const [activeStep, setActiveStep] = useState(null);

  const activeStepRef = useRef(activeStep);
  activeStepRef.current = activeStep;

  const requiredSteps = useMemo<StepItem[]>(
    () => collect(steps).where('is_required', true).toArray(),
    [steps]
  );

  const incompleteSteps = useMemo<StepItem[]>(
    () => collect(requiredSteps).where('is_complete', false).toArray(),
    [requiredSteps]
  );

  useEffect(() => {
    !activeStep && openFirstIncompleteStep();
  }, [requiredSteps]);

  useEffect(() => {
    onProgress && onProgress(incompleteSteps.length === 0);
  }, [incompleteSteps]);

  const isStepExpandable = useCallback(
    (index: number) => index === 0 || requiredSteps[index]?.is_complete || requiredSteps[index - 1]?.is_complete,
    [requiredSteps]
  );

  const toggleStep = (step: string) => {
    setActiveStep((current) => current === step ? null : step);
  }

  function openFirstIncompleteStep(): void {
    setActiveStep(
      collect(requiredSteps)
        // @ts-ignore
        .when(
          activeStep,
          (collection) => collection.where('id', '!=', activeStep)
        )
        // @ts-ignore
        .firstWhere('is_complete', false)
        ?.id
    );
  }
  
  return (
    <>
      {requiredSteps.map((item, index) => {
        const isActive = item.id === activeStepRef.current;

        return (
          <StepperItem
            number={index + 1}
            title={item.title(isActive)}
            description={typeof item.description === 'function' ? item.description(isActive) : null}
            isActive={isActive}
            isComplete={item.is_complete}
            isCollapsable={item.is_collapsable}
            isExpandable={isStepExpandable(index)}
            onToggle={() => toggleStep(item.id)}
          >{item.render(openFirstIncompleteStep)}</StepperItem>
        );
      })}
    </>
  );
}