export default {
  pickup: "{{time}} min Pickup: ",
  delivery: "{{time}} min Delivery: ",
  distance: '{{distance}} away',
  buttons: {
    order_here: 'Order Here',
  },
  labels: {
    available: 'Available',
    unavailable: 'Unavailable',
  },
};
