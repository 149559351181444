import { useEffect, useState, useRef } from "react";
import {
  ProductComboInterface,
  ProductInterface,
} from "../../../services/exports/Interfaces";
import classnames from "classnames";
import PriceComparison from "../../payment/PriceComparison";
import { useTranslation } from "react-i18next";
import useProductLikes from "../../../hooks/global/useProductLikes";
import ProductManager from "../../../services/api/ProductManager";
import {
  PRODUCT_HEIGHT,
  ProductType,
} from "../../../services/exports/Constants";
import useProductIdentifiers from "../../../hooks/menu/useProductIdentifiers";
import SeoResource from "../../../services/resources/SeoResource";
import ThumbUpIcon from "../../../assets/logo/ThumbUpIcon";
import HeartIcon from "../../../assets/logo/HeartIcon";

interface Props {
  product: ProductInterface | ProductComboInterface;
  showCaseProduct?: boolean;
  toggleProduct?: () => void;
  className?: string;
}

export default function MultiSelectProduct(props: Props) {
  const { t } = useTranslation(null, { keyPrefix: 'Components:Menu:MultiSelectMenuItems' });

  const { product, showCaseProduct, toggleProduct, className } = props;

  const { emojis } = useProductIdentifiers(product);

  const { likedProductIds, likeProduct, unlikeProduct } = useProductLikes();

  const isLiked = likedProductIds.includes(product.id);
  const initiallyLiked = useRef(isLiked);

  const [likesCount, setLikesCount] = useState(product.likes_count);

  useEffect(() => {
    if (initiallyLiked.current === isLiked) {
      setLikesCount(product.likes_count);

      return;
    }

    setLikesCount(isLiked ? product.likes_count + 1 : product.likes_count - 1);
  }, [likedProductIds]);

  useEffect(() => {
    initiallyLiked.current = isLiked;
    setLikesCount(product.likes_count);
  }, [product.likes_count]);

  function likeProductHandler(e: React.TouchEvent | React.MouseEvent) {
    e.stopPropagation();

    likeProduct(product.id);

    ProductManager.likeProduct(product.id).then(({ success }) => {
      !success && unlikeProduct(product.id);
    });
  }

  function unlikeProductHandler(e: React.TouchEvent | React.MouseEvent) {
    e.stopPropagation();

    unlikeProduct(product.id);

    ProductManager.unlikeProduct(product.id).then(({ success }) => {
      !success && likeProduct(product.id);
    });
  }

  function renderSoldOutOverlay() {
    return (
      <div className={soldOutOverlayContainer}>
        {!product?.thumbnail_url && (
          <p className="text-white absolute top-1/2 -translate-y-1/2 left-5">
            {t("labels.sold_out")}
          </p>
        )}
      </div>
    );
  }

  function renderPriceRow(): JSX.Element {
    const originalPrice =
      product.base_price +
      (product.type === ProductType.Combo ? product.combo_discount ?? 0 : 0);

    return (
      <div className={priceRowContainer}>
        <div className="h-full flex flex-col justify-end">
          <PriceComparison
            actualPrice={product?.actual_price}
            originalPrice={originalPrice}
          />
        </div>
        <div className="flex flex-row items-center">
          {emojis.isNotEmpty() && (
            <p className="text-right">
              {emojis.implode(" ")}
            </p>
          )}
          <div
            className="ml-small flex items-center"
            onClick={isLiked ? unlikeProductHandler : likeProductHandler}
          >
            <HeartIcon filled={isLiked} />
            <span className="font-semibold text-tiny text-brand-inkGrey-grey_4 ml-tiny w-3">
              {likesCount}
            </span>
          </div>
        </div>
      </div>
    );
  }

  function renderProductImage(): JSX.Element {
    return (
      product?.thumbnail_url && (
        <div className={productImageContainer}>
          <div className={productImage}>
            <img
              src={product?.thumbnail_url}
              alt={SeoResource.toAltText(`${product?.name} image`)}
              className={productImage}
            />
          </div>
          {!product?.is_available && (
            <p className="text-white absolute top-1/2 -translate-y-1/2 w-full text-center z-10">
              {t("labels.sold_out")}
            </p>
          )}
        </div>
      )
    );
  }

  return (
    <article
      className={classnames(container(showCaseProduct), className)}
      style={{ height: PRODUCT_HEIGHT }}
      onClick={() => toggleProduct && toggleProduct()}
    >
      {renderProductImage()}
      <div className={wrapper(showCaseProduct)}>
        <div className={infoContainer}>
          <div className={infoWrapper}>
            {product?.popularity_rank && (
              <div className="flex items-center mb-mini">
                <ThumbUpIcon />
                <span className="font-semibold text-brand-text-default text-tiny ml-tiny">
                  {t("labels.most_popular", { rank: product?.popularity_rank })}
                </span>
              </div>
            )}
            <h6 className={titleStyle}>{product?.name}</h6>
            <div className="grid grid-cols-1">
              <p className={classnames(descriptionStyle, "mini")}>
                {product?.description}
              </p>
            </div>
          </div>
        </div>
        {!showCaseProduct && renderPriceRow()}
      </div>

      {!product?.is_available && renderSoldOutOverlay()}
    </article>
  );
}

const container = (showCaseProduct?: boolean) =>
  `w-full border bg-background-inkWhite-white_1 rounded-2xl border-brand-inkGrey-grey_2 flex flex-row select-none relative ${
    !showCaseProduct ? "cursor-pointer" : ""
  } overflow-hidden`;

const wrapper = (showCaseProduct: boolean) =>
  `px-small py-xsmall flex flex-col flex-1 ${
    showCaseProduct ? "justify-center" : "justify-between"
  } relative`;

const titleStyle =
  "text-brand-text-default line-clamp-2 break-words mb-tiny mr-tiny";

const descriptionStyle =
  "text-brand-text-grey line-clamp-2 break-words";

const productImageContainer =
  "h-full overflow-hidden lg:py-none lg:pl-none py-small pl-mini relative flex-none";

const productImage =
  "relative h-full bg-brand-inkGrey-grey_2 aspect-square lg:rounded-tl-lg lg:rounded-bl-lg lg:rounded-tr-none lg:rounded-br-none md:rounded-lg sm:rounded-lg object-cover";

const soldOutOverlayContainer =
  "top-0 left-0 absolute h-full w-full bg-brand-secondary bg-opacity-50";

const priceRowContainer =
  "flex flex-row justify-between items-center w-full relative";

const infoContainer = "flex flex-row justify-between";

const infoWrapper = "flex flex-1 flex-col";
