import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  DineInFlowInterface,
  OrderItemInterface,
  OrderPaymentInterface,
  PartyInterface,
  TableInterface,
} from "../../services/exports/Interfaces";
import { collect } from "collect.js";
import moment from "moment";
import { REDUX_STORE_SLICES } from "../../services/exports/Constants";

const initialState: DineInFlowInterface = {
  isDineIn: false,
  table: null,
  party: null,
  paymentInfo: null,
  firstTimeDineInVisitor: true,
  paymentInfoSeen: false,
  rewardInfoModalSeen: false,
  paymentNoteAccepted: false,
};

export default createSlice({
  name: REDUX_STORE_SLICES.DINE_IN_FLOW,
  initialState,
  reducers: {
    reset: (state: DineInFlowInterface) => {
      state.isDineIn = false;
      state.table = null;
      state.party = null;
      state.paymentInfo = null;
    },
    setDineInStatus: (
      state: DineInFlowInterface,
      action: PayloadAction<boolean>
    ) => {
      try {
        state.isDineIn = action.payload;
      } catch (error) {}
    },
    setPaymentNoteAccepted: (
      state: DineInFlowInterface,
      action: PayloadAction<boolean>
    ) => {
      try {
        state.paymentNoteAccepted = action.payload;
      } catch (error) {}
    },
    setTable: (
      state: DineInFlowInterface,
      action: PayloadAction<TableInterface>
    ) => {
      try {
        state.table = action.payload;
      } catch (error) {}
    },
    replaceTableParty: (
      state: DineInFlowInterface,
      action: PayloadAction<PartyInterface>
    ) => {
      try {
        state.table = {
          ...state.table,
          parties: collect(state.table.parties)
            .transform((item) =>
              item?.id === action.payload?.id ? action.payload : item
            )
            .toArray(),
        };
      } catch (error) {}
    },
    setParty: (
      state: DineInFlowInterface,
      action: PayloadAction<PartyInterface>
    ) => {
      try {
        state.party = action.payload;
      } catch (error) {}
    },
    resetParty: (state: DineInFlowInterface) => {
      try {
        state.party = null;
      } catch (error) {}
    },
    closeParty: (state: DineInFlowInterface) => {
      try {
        state.party = {
          ...state?.party,
          deleted_at: moment().format(),
        };
      } catch (error) {}
    },
    resetOrder: (state: DineInFlowInterface) => {
      try {
        state.party = {
          ...state.party,
          order: null,
        };
      } catch (error) {}
    },
    setOrder: (
      state: DineInFlowInterface,
      action: PayloadAction<OrderItemInterface>
    ) => {
      try {
        state.party = {
          ...state.party,
          order: action.payload,
        };
      } catch (error) {}
    },
    updateOrder: (
      state: DineInFlowInterface,
      action: PayloadAction<Partial<OrderItemInterface>>
    ) => {
      try {
        state.party = {
          ...state.party,
          order: {
            ...state.party?.order,
            ...action.payload,
          },
        };
      } catch (error) {}
    },
    setPaymentInfo: (
      state: DineInFlowInterface,
      action: PayloadAction<OrderPaymentInterface>
    ) => {
      try {
        state.paymentInfo = action.payload;
      } catch (error) {}
    },
    updatePaymentInfo: (
      state: DineInFlowInterface,
      action: PayloadAction<OrderPaymentInterface>
    ) => {
      try {
        state.paymentInfo = {
          ...state.paymentInfo,
          ...action.payload,
        };
      } catch (error) {}
    },
    resetPaymentInfo: (state: DineInFlowInterface) => {
      try {
        state.paymentInfo = null;
      } catch (error) {}
    },
    setFirstTimeDineInVisitor: (state: DineInFlowInterface, action) => {
      try {
        state.firstTimeDineInVisitor = action.payload;
      } catch (error) {}
    },
    setPaymentInfoSeen: (state: DineInFlowInterface, action) => {
      try {
        state.paymentInfoSeen = action.payload;
      } catch (error) {}
    },
    setRewardInfoModalSeen: (state: DineInFlowInterface, action) => {
      try {
        state.rewardInfoModalSeen = action.payload;
      } catch (error) {}
    },
  },
});

export const props = (state: DineInFlowInterface) => state;
