import { createSlice } from "@reduxjs/toolkit";
import { REDUX_STORE_SLICES } from "../../services/exports/Constants";
import { ActionPayloadInterface, ProductLikesStoreInterface } from "../../services/exports/Interfaces";

const initialState: ProductLikesStoreInterface = {
  likedProductIds: [],
}

export default createSlice({
  name: REDUX_STORE_SLICES.PRODUCT_LIKES,
  initialState,
  reducers: {
    likeProduct: (state: ProductLikesStoreInterface, action: ActionPayloadInterface<number>): void => {
      const productId = action.payload;

      state.likedProductIds = state.likedProductIds.concat(productId);
    },
    unlikeProduct: (state: ProductLikesStoreInterface, action: ActionPayloadInterface<number>): void => {
      const productId = action.payload;

      state.likedProductIds = state.likedProductIds.filter((id) => id !== productId);
    },
  },
});