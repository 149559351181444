export default {
  titles: {
    main: 'Ändern',
    change_table: 'Tisch Nr. wechseln (siehe Sticker)',
    select_table: 'Tisch Nr. auswählen (siehe Sticker)',
    table: 'Tisch',
    group: 'Gruppe',
  },
  buttons: {
    confirm: 'Bestätigen',
    reload: 'Neu laden',
  },
  feedback: {
    failed_to_load_tables: 'Etwas ist schief gelaufen, bitte neu laden',
    failed_to_load_table_data: 'Die Tischdaten konnten nicht geladen werden, bitte versuche es erneut',
  },
  toasts: {
    table_changed: 'Du hast erfolgreich den Tisch geändert',
  },
};
