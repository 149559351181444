import {useState} from "react";
import TextArea from "../inputs/TextArea";
import useTheme from "../../hooks/ui/useTheme";
import {useTranslation} from "react-i18next";
import useScreenType from "../../hooks/utility/useScreenType";
import CustomButton from "../buttons/CustomButton";
import {RemoveScroll} from "react-remove-scroll";
import {ButtonSizes, ButtonTypes} from "../../services/exports/Interfaces";

interface Props {
  defaultNote?: string;
  toggleNote: () => void;
  onSave?: (note: string) => void;
  onCancel?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
}

export default function CustomNote(props: Props) {
  const { t } = useTranslation(null, {
    keyPrefix: "Components:Note:CustomNote",
  });

  const { defaultNote, toggleNote, onSave, onCancel, onFocus, onBlur } = props;

  const theme = useTheme();

  const { isDesktop } = useScreenType();

  const [note, setNote] = useState<string>(defaultNote ?? "");

  function _onSave(): void {
    onSave && onSave(note);
    toggleNote();
  }

  function _onCancel(): void {
    onCancel && onCancel();
    toggleNote();
  }

  if (isDesktop) {
    return (
      <div>
        <TextArea
          placeholder={t("text_input.note.place_holder")}
          value={note}
          onChange={setNote}
          maxLength={80}
          textClassName="mini"
          autoFocus
          containerStyle={{ borderColor: theme.color.companyBrand.primary }}
          onFocus={() => onFocus && onFocus()}
          onBlur={() => onBlur && onBlur()}
        />
        <div className={buttonWrapper}>
          <button className={buttonContainer} onClick={_onCancel}>
            <h5 className={buttonTitleStyle}>{t("buttons.cancel")}</h5>
          </button>
          <button className={buttonContainer} onClick={_onSave}>
            <h5 className={buttonTitleStyle}>{t("buttons.save")}</h5>
          </button>
        </div>
      </div>
    );
  }

  return (
    <RemoveScroll
      enabled={true}
      className="fixed inset-0"
      style={{ zIndex: 1000 }}
    >
      <div className="fixed inset-0 bg-black/60" onClick={_onCancel} />
      <div
        className="w-full left-0 fixed bottom-0 bg-background-inkWhite-white_0 p-small rounded-t-xl shadow"
        style={{ zIndex: 1000 }}
      >
        <TextArea
          placeholder={t("text_input.note.place_holder")}
          value={note}
          onChange={setNote}
          maxLength={80}
          textClassName="mini"
          autoFocus
          containerStyle={{ borderColor: theme.color.companyBrand.primary }}
          onFocus={() => onFocus && onFocus()}
          onBlur={() => onBlur && onBlur()}
        />
        <div className="grid grid-cols-2 gap-4 mt-small">
          <CustomButton
            buttonSize={ButtonSizes.small}
            buttonType={ButtonTypes.danger}
            title={t("buttons.cancel")}
            onClick={_onCancel}
          />
          <CustomButton
            buttonSize={ButtonSizes.small}
            title={t("buttons.save")}
            onClick={_onSave}
          />
        </div>
      </div>
    </RemoveScroll>
  );
}

const buttonWrapper = "flex flex-row justify-end mt-xsmall";

const buttonContainer = "px-1 rounded ml-small";

const buttonTitleStyle = "mini semibold text-companyBrand-primary";
