import { useMemo, useRef, createRef, useEffect } from "react";
import classnames from "classnames";
import { CategoryInterface } from "../../../services/exports/Interfaces";
import { AnimatePresence, motion } from "framer-motion";
import CloseIcon from "../../../assets/logo/CloseIcon";
import useTheme from "../../../hooks/ui/useTheme";
import { useTranslation } from "react-i18next";
import useMenu from "../../../hooks/menu/useMenu";
import useScrollPosition from "../../../hooks/utility/useScrollPosition";

interface Props {
  open: boolean;
  categories: CategoryInterface[];
  focusedCategoryId: number;
  menuSideBarStickyPosition?: number;
  setOpen: (open: boolean) => void;
  onSelectCategory: (category: CategoryInterface) => void;
}

const DesktopMenuSideBar = (props: Props): JSX.Element => {
  const {
    open,
    categories,
    focusedCategoryId,
    menuSideBarStickyPosition,
    setOpen,
    onSelectCategory,
  } = props;

  const { t } = useTranslation(null, {
    keyPrefix: "Components:Menu:MenuNavigationBar:DesktopMenuSideBar",
  });

  const { selectedMenu } = useMenu();

  const theme = useTheme();

  const { scrollPosition } = useScrollPosition();

  const openRef = useRef(open);
  openRef.current = open;

  const listItemHeight: number = 50;

  const animationDuration: number = 0.3;

  const itemListRefs = categories?.reduce((acc: any, value: any) => {
    acc[value?.id] = createRef();
    return acc;
  }, {});

  const menuButton = document.getElementById("menu-button");

  useEffect(() => {
    if (open) {
      scrollToElement(focusedCategoryId);
    }
  }, [open]);

  function closeModal(): void {
    setOpen(false);
  }

  function scrollToElement(id: number): void {
    try {
      itemListRefs[id]?.current?.scrollIntoView({ block: "center" });
    } catch (error) {}
  }

  const renderBackgroundOverlay = (): JSX.Element => {
    return (
      <AnimatePresence>
        <motion.div
          className={`fixed inset-0 z-50`}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          transition={{
            duration: animationDuration,
            ease: "easeOut",
          }}
          onClick={closeModal}
        />
      </AnimatePresence>
    );
  };

  const renderCategories = useMemo(
    () =>
      categories &&
      categories?.map((item, index) => {
        const isActive = focusedCategoryId === item?.id;
        return (
          <div
            className={classnames(
              "flex items-center px-small cursor-pointer text-default hover:text-companyBrand-primary relative",
              {
                "text-companyBrand-primary": isActive,
              },
              {
                "border-b": index !== categories?.length - 1,
              }
            )}
            style={{ height: listItemHeight }}
            onClick={() => onSelectCategory(item)}
            ref={itemListRefs[item?.id]}
          >
            {isActive && (
              <div
                className={
                  "w-[5px] h-full bg-companyBrand-primary absolute left-0"
                }
              />
            )}
            <h6 className="text-mini">
              {item?.id > 0 ? item?.name : t("free")}
            </h6>
          </div>
        );
      }),
    [categories, focusedCategoryId, itemListRefs]
  );

  const renderArrow = useMemo((): JSX.Element => {
    const arrowWidth = 14;
    return (
      <div
        className="overflow-hidden absolute top-0 drop-shadow-2xl"
        style={{
          top:
            menuButton?.getBoundingClientRect()?.top -
            menuSideBarStickyPosition,
          left: -arrowWidth,
          width: arrowWidth,
          zIndex: 60,
        }}
      >
        <div className=" h-[20px] bg-background-inkWhite-white_0 -rotate-45 transform origin-top-right -mr-tiny"></div>
      </div>
    );
  }, [scrollPosition, menuSideBarStickyPosition]);

  const renderMenuModal = (): JSX.Element => {
    return (
      <AnimatePresence>
        <div
          className="flex relative"
          style={{
            left: menuButton?.getBoundingClientRect()?.left + 50,
            maxHeight: window.innerHeight - menuSideBarStickyPosition - 10,
            top: menuSideBarStickyPosition,
          }}
        >
          {renderArrow}
          <motion.div
            className="min-w-[300px] z-50 rounded-lg drop-shadow-2xl overflow-clip"
            initial={{
              x: -10,
              opacity: 0,
            }}
            animate={{
              x: 0,
              opacity: 1,
            }}
            transition={{
              duration: animationDuration,
              ease: "easeOut",
            }}
          >
            <div className="w-full h-full bg-background-inkWhite-white_0 rounded-lg relative overflow-auto">
              <div className="flex items-center justify-between p-small border-b sticky top-0 bg-background-inkWhite-white_0 z-10">
                <h6>{selectedMenu?.name ?? t("full_menu")}</h6>
                <button
                  className="w-9 h-9 bg-background-inkWhite-white_1 shadow-icon rounded-full flex justify-center items-center"
                  onClick={closeModal}
                >
                  <CloseIcon color={theme.color.text.default} />
                </button>
              </div>
              {renderCategories}
            </div>
          </motion.div>
        </div>
      </AnimatePresence>
    );
  };

  return open ? (
    <div className="fixed inset-0" style={{ zIndex: 1000 }}>
      {renderBackgroundOverlay()}
      {renderMenuModal()}
    </div>
  ) : null;
};

export default DesktopMenuSideBar;
