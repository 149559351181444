import { useEffect, useMemo, useState } from "react";
import LoadingPage from "../../components-library/loading/LoadingPage";
import useInitialData from "../global/useInitialData";

interface Props {
    showInitialLoading?: boolean;
    initialLoadingTimeout?: number;
}

export default function useLoadingScreen(
    props: Props = {
        showInitialLoading: true,
        initialLoadingTimeout: 1000,
    },
) {
    const { showInitialLoading, initialLoadingTimeout } = props;

    const { loading: appLoading } = useInitialData();

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        showInitialLoading && toggleLoading(initialLoadingTimeout);
    }, []);

    function toggleLoading(timeout: number = 1000) {
        setTimeout(() => setLoading(false), timeout);
    }

    const renderLoadingPage = useMemo(() => {
        return loading && !appLoading ? <LoadingPage /> : null;
    }, [loading, appLoading]);

    return {
        loading,
        setLoading,
        toggleLoading,
        renderLoadingPage,
    };
}
