import LoadingIndicator from "./LoadingIndicator";

interface Props {
  containerStyle?: Object;
}

export default function LoadingPage(props: Props) {
  const { containerStyle } = props;

  return (
    <div className={container} style={containerStyle} data-test="loading-page">
      <div className={loadingWrapper}>
        <div className="-mb-tiny">
          <LoadingIndicator lg={60} md={50} sm={40} />
        </div>
      </div>
    </div>
  );
}

const container =
  "h-screen w-full z-50 fixed top-0 bg-background-inkWhite-white_1 flex justify-center items-center";

const loadingWrapper =
  "bg-background-inkWhite-white_1 lg:h-24 lg:w-24 md:h-20 md:w-20 h-16 w-16 rounded-xl flex justify-center items-center shadow border";
