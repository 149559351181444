import {DeliveryZone} from "../../services/exports/Interfaces";
import {DELIVERY_ZONE_GEOMETRY_TYPES} from "../../services/exports/Constants";
import {Circle, Polygon} from "@react-google-maps/api";
import React from "react";

interface Props {
  data: DeliveryZone;
}

export default function DeliveryZoneGeometry(props: Props): JSX.Element {
  const { data } = props;

  const options = {
    draggable: false,
    editable: false,
    fillColor: data?.color,
    fillOpacity: 0.4,
    strokeColor: data?.color,
    strokeWeight: 2,
  };

  function render(): JSX.Element {
    if (data?.geometry_type === DELIVERY_ZONE_GEOMETRY_TYPES.CIRCLE) {
      return 'center' in data?.geometry_data
        && 'radius' in data?.geometry_data
        && (
          <Circle
            center={data.geometry_data.center}
            radius={data.geometry_data.radius}
            options={options}
          />
        );
    }

    if (data.geometry_type === DELIVERY_ZONE_GEOMETRY_TYPES.POLYGON) {
      return "paths" in data?.geometry_data
        && (
          <Polygon
            paths={data.geometry_data.paths}
            options={options}
          />
        );
    }

    return null;
  }

  return render();
}