export default {
  header: {
    review_us: "Review us on Google",
    upload: "Upload Google Review",
    not_now: "Not now",
  },
  product_suggestions: {
    title: "Rate us & get",
    description: "a Free {{product}} & more ",
    types: {
      free_reward: "a Free {{product}}",
      multiple_free_rewards: "a Free {{product}} & More",
      discount: "{{discount}}% off on your next order",
    },
  },
  steps: {
    how_it_works: "How it works",
    step_1: {
      title: "Step",
      description: "Write a review about {{name}} on Google",
    },
    step_2: {
      title: "Step",
      description:
        "Upload a screenshot of your posted review (see example below)",
    },
  },
  buttons: {
    upload_screenshot: "Upload Screenshot",
    leave_google_review: "Leave Google Review",
    submit_screenshot: "Submit Screenshot",
  },
  enter_name: {
    title: "Name posted with your Google review",
    place_holder: "Enter name",
  },
  screen_shot_uploaded: {
    title: "Screenshot uploaded",
    description: "Scroll down to view full screenshot",
  },
  loading: {
    processing: "Your posted review is being reviewed",
  },
  error_messages: {
    upload_failed: "Failed to upload screenshot. Please try again.",
    review_already_uploaded:
      "It seems you had already uploaded a screenshot of a Google review. You can be rewarded maximum once for a Google review 😊",
    name_missing: {
      description:
        "Please enter the name posted with your Google review to help us match your info.",
      button: {
        title: "Okay",
      },
    },
  },
};
