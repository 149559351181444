export default {
  order_methods: {
    delivery: 'Delivery',
    pickup: 'Pickup',
    room_service: 'On site',
    table_service: 'On site',
  },
  time: '{{time}} min',
  asap: 'ASAP',
  unavailable: 'Unavailable',
};
