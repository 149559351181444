import React from "react";
import CheckmarkIcon from "../../assets/logo/CheckmarkIcon";
import useTheme from "../../hooks/ui/useTheme";
import { ThemeInterface } from "../../services/exports/Interfaces";
import classnames from "classnames";

interface Props {
  active: boolean;
  color?: string;
  testId?: string;
  className?: string;
  onClick?: (active: boolean) => void;
}

export default function CheckmarkButton(props: Props) {
  const { active, color, testId, className, onClick } = props;

  const theme: ThemeInterface = useTheme();

  function getColor() {
    return color ?? theme.color.companyBrand.primary;
  }

  return (
    <div
      data-test={testId}
      className={classnames(container, className)}
      style={{
        borderColor: getColor(),
        backgroundColor: active ? getColor() : "transparent",
      }}
      onClick={() => onClick && onClick(!active)}
    >
      {active && (
        <CheckmarkIcon className="h-3 w-3" color={theme.color.text.light} />
      )}
    </div>
  );
}

const container =
  "h-5 w-5 rounded border flex justify-center items-center hover:shadow cursor-pointer";
