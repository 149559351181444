interface GooglePlaceHookInterface {
  getPlace: (id: number | string) => string;
}

export default function useGooglePace(): GooglePlaceHookInterface {
  function getPlace(id: number | string): string {
    return `https://search.google.com/local/writereview?placeid=${id}`;
  }

  return {
    getPlace,
  };
}
