import React from "react";
import useTheme from "../../hooks/ui/useTheme";
import { ThemeInterface } from "../../services/exports/Interfaces";

interface Props {
  className?: string;
  style?: Object;
  color?: string;
}

export default function MultiGroupTableIllustration(props: Props) {
  const { className, color, style } = props;
  const theme: ThemeInterface = useTheme();
  return (
    <svg
      className={className ?? "lg:h-40 lg:w-40 md:w-36 md:h-36 w-24 h-24"}
      viewBox="0 0 286 121"
      fill="none"
      style={style}
    >
      <path
        d="M143.614 1.8V6H142.853V2.54033H142.829L141.846 3.16787V2.47061L142.89 1.8H143.614Z"
        fill="white"
      />
      <path
        d="M52.8027 108.555L56.4454 107.719L54.9552 93.2715L49.5791 94.5048L52.8027 108.555Z"
        fill="#FDB797"
      />
      <path
        d="M55.6187 101.576L50.4348 102.765L48.7773 99.5258L48.6834 99.4109C47.9482 98.5084 47.54 97.3836 47.5255 96.2195L43.2169 84.8222L42.8646 84.6547C42.592 84.5251 42.3503 84.3388 42.1554 84.1083C41.9606 83.8778 41.8172 83.6084 41.7348 83.318C41.6524 83.0277 41.6329 82.7231 41.6776 82.4246C41.7222 82.1261 41.8301 81.8406 41.9939 81.5871L41.5378 80.3806L28.6668 80.4043L28.654 80.4006L12.8486 75.967L14.5082 66.0742L18.9542 68.871C20.4192 68.7759 45.5154 67.235 47.8338 72.8592C49.8984 77.8676 52.8735 86.4241 55.0057 92.7202C55.112 93.0029 55.2442 93.2751 55.4005 93.5335C55.7322 94.0444 55.9269 94.6321 55.9659 95.24C55.991 95.5045 56.0415 95.7659 56.1165 96.0208C57.0027 98.6701 55.6526 101.506 55.6389 101.534L55.6187 101.576Z"
        fill="#1A1A1A"
      />
      <path
        d="M51.5723 107.348L52.7566 107.076L56.9491 104.135L59.8586 105.446L59.8589 105.446C61.2594 105.125 62.7302 105.373 63.9477 106.136C65.1652 106.9 66.0297 108.115 66.351 109.516L66.3904 109.687L52.8233 112.8L51.5723 107.348Z"
        fill="#1A1A1A"
      />
      <path
        d="M101.228 101.316C101.022 101.406 100.798 101.443 100.574 101.425C100.351 101.406 100.135 101.332 99.9476 101.21C99.8514 101.147 99.7667 101.304 99.8622 101.367C100.07 101.501 100.308 101.582 100.555 101.603C100.802 101.624 101.05 101.584 101.278 101.487C101.301 101.48 101.319 101.464 101.33 101.444C101.342 101.423 101.345 101.399 101.339 101.377C101.332 101.354 101.316 101.335 101.296 101.324C101.275 101.312 101.25 101.31 101.228 101.316H101.228Z"
        fill="white"
      />
      <path
        d="M90.8065 106.255C90.6964 106.45 90.5395 106.615 90.3499 106.735C90.1603 106.854 89.944 106.925 89.7203 106.941C89.6056 106.948 89.6325 107.125 89.7465 107.117C89.9936 107.099 90.2324 107.02 90.4422 106.889C90.6519 106.757 90.8262 106.576 90.9498 106.361C90.9632 106.342 90.9687 106.318 90.9652 106.295C90.9618 106.272 90.9496 106.251 90.9313 106.236C90.9122 106.222 90.8885 106.216 90.8651 106.22C90.8417 106.223 90.8207 106.236 90.8065 106.255H90.8065Z"
        fill="white"
      />
      <path
        d="M89.8086 31.2911C90.287 31.2911 90.6749 30.9033 90.6749 30.4248C90.6749 29.9464 90.287 29.5586 89.8086 29.5586C89.3302 29.5586 88.9424 29.9464 88.9424 30.4248C88.9424 30.9033 89.3302 31.2911 89.8086 31.2911Z"
        fill="#1A1A1A"
      />
      <path
        d="M78.9625 37.0977L90.6408 29.7607C90.7465 29.6943 90.8645 29.65 90.9877 29.6305C91.111 29.611 91.2369 29.6167 91.3579 29.6472C91.4788 29.6777 91.5924 29.7324 91.6916 29.808C91.7909 29.8837 91.8737 29.9786 91.9353 30.0872C91.9968 30.1957 92.0357 30.3156 92.0495 30.4396C92.0634 30.5636 92.0521 30.6891 92.0161 30.8086C91.9801 30.928 91.9203 31.039 91.8402 31.1347C91.7602 31.2304 91.6616 31.3089 91.5504 31.3654L79.2556 37.6149C79.1874 37.6496 79.1083 37.6567 79.035 37.6346C78.9617 37.6125 78.8997 37.563 78.8619 37.4964C78.8242 37.4298 78.8136 37.3511 78.8323 37.2769C78.851 37.2026 78.8976 37.1384 78.9625 37.0977V37.0977Z"
        fill="#E4E4E4"
      />
      <path
        d="M55.5332 52.8979C55.4412 52.7611 55.3932 52.5995 55.3956 52.4347C55.398 52.2699 55.4506 52.1098 55.5465 51.9757C55.7442 51.7111 56.0073 51.5025 56.31 51.3703C56.9349 51.0523 57.608 50.8396 58.3022 50.7408C58.8297 50.6659 59.4055 50.642 59.8146 50.3008C60.2211 49.9618 60.4478 49.3279 60.9744 49.275C61.4836 49.2239 61.8751 49.7812 62.3856 49.8158C63.015 49.8584 63.427 49.1219 64.0395 48.9708C64.4494 48.8697 64.8709 49.0481 65.2554 49.2226L67.5629 50.2694L68.7457 50.806C69.1588 50.9934 69.6247 51.2458 69.7027 51.6928C69.8099 52.3068 69.127 52.7401 68.543 52.9581C64.3734 54.4969 59.7915 54.4975 55.6215 52.9597"
        fill={color ?? theme.color.companyBrand.primary}
      />
      <path
        d="M134.256 64.2096C201.447 64.2096 255.916 61.1069 255.916 57.2796C255.916 53.4523 201.447 50.3496 134.256 50.3496C67.0647 50.3496 12.5957 53.4523 12.5957 57.2796C12.5957 61.1069 67.0647 64.2096 134.256 64.2096Z"
        fill="white"
      />
      <path
        d="M120.514 86.7344H99.7237C98.7669 86.7344 97.9912 87.51 97.9912 88.4669C97.9912 89.4237 98.7669 90.1994 99.7237 90.1994H120.514C121.471 90.1994 122.246 89.4237 122.246 88.4669C122.246 87.51 121.471 86.7344 120.514 86.7344Z"
        fill="#1A1A1A"
      />
      <path
        d="M130.59 55.845L123.723 88.4641L125.512 96.8129L128.888 112.569V119.651C128.889 119.661 128.888 119.67 128.886 119.68C128.885 119.836 128.856 119.992 128.802 120.138H126.087C126.032 119.992 126.004 119.836 126.003 119.68C126.001 119.67 126 119.661 126.001 119.651V112.873L122.936 98.5723L122.316 95.6848L121.117 90.0888L120.771 88.468V88.4661L121.113 86.8414L121.377 85.5882L127.764 55.2521C127.803 55.0664 127.878 54.8902 127.985 54.7335C128.092 54.5768 128.228 54.4427 128.387 54.3389C128.546 54.2351 128.724 54.1636 128.91 54.1285C129.097 54.0933 129.288 54.0953 129.474 54.1343C129.659 54.1732 129.836 54.2484 129.992 54.3555C130.149 54.4625 130.283 54.5994 130.386 54.7583C130.49 54.9172 130.561 55.0949 130.596 55.2814C130.631 55.4679 130.629 55.6594 130.59 55.845L130.59 55.845Z"
        fill="#1A1A1A"
      />
      <path
        d="M101.168 88.4652V119.65C101.168 119.66 101.168 119.67 101.166 119.679C101.165 119.836 101.136 119.991 101.081 120.137H98.367C98.3121 119.991 98.2834 119.836 98.2822 119.679C98.2807 119.67 98.28 119.66 98.2804 119.65V88.4652C98.2804 88.0823 98.4325 87.7151 98.7032 87.4444C98.974 87.1736 99.3412 87.0215 99.7241 87.0215C100.107 87.0215 100.474 87.1736 100.745 87.4444C101.016 87.7151 101.168 88.0823 101.168 88.4652Z"
        fill="#1A1A1A"
      />
      <path
        d="M103.189 83.2676H117.049C118.427 83.2676 119.749 83.8152 120.724 84.7899C121.699 85.7646 122.246 87.0866 122.246 88.4651H97.9912C97.9912 87.0866 98.5388 85.7646 99.5135 84.7899C100.488 83.8152 101.81 83.2676 103.189 83.2676Z"
        fill="#1A1A1A"
      />
      <path
        d="M123.979 98.5711H99.724C99.3411 98.5711 98.9739 98.419 98.7031 98.1482C98.4324 97.8775 98.2803 97.5103 98.2803 97.1273C98.2803 96.7444 98.4324 96.3772 98.7031 96.1065C98.9739 95.8357 99.3411 95.6836 99.724 95.6836H123.979C124.362 95.6836 124.729 95.8357 125 96.1065C125.271 96.3772 125.423 96.7444 125.423 97.1273C125.423 97.5103 125.271 97.8775 125 98.1482C124.729 98.419 124.362 98.5711 123.979 98.5711Z"
        fill="#1A1A1A"
      />
      <path
        d="M11.155 90.25H35.62C36.5769 90.25 37.3525 89.4743 37.3525 88.5175V87.9925C37.3525 87.0357 36.5769 86.26 35.62 86.26H11.155C10.1982 86.26 9.42254 87.0357 9.42254 87.9925V88.5175C9.42254 89.4743 10.1982 90.25 11.155 90.25Z"
        fill="#1A1A1A"
      />
      <path
        d="M10.9035 88.2788L10.4607 90.1037L8.836 96.7873L8.13537 99.6748L4.88205 113.069V118.881C4.8829 119.165 4.79843 119.443 4.63954 119.679C4.51462 119.87 4.34475 120.028 4.14485 120.138H2.73184C2.53193 120.028 2.36207 119.87 2.23715 119.679C2.07823 119.443 1.99372 119.165 1.99455 118.881V112.722L5.60975 97.8422L7.94287 88.2326L0.031103 50.647C-0.00805323 50.4615 -0.0102604 50.2701 0.0246056 50.0837C0.0594716 49.8973 0.130728 49.7197 0.234301 49.5609C0.337874 49.4021 0.471734 49.2652 0.628224 49.1582C0.784715 49.0511 0.960768 48.976 1.14632 48.937C1.33187 48.8981 1.52328 48.8961 1.70961 48.9311C1.89594 48.9662 2.07352 49.0377 2.23222 49.1414C2.39091 49.2451 2.5276 49.3791 2.63448 49.5358C2.74135 49.6924 2.81631 49.8685 2.85506 50.0541L10.1682 84.7869L10.5051 86.3885L10.8977 88.2557L10.9035 88.2788Z"
        fill="#1A1A1A"
      />
      <path
        d="M36.8016 88.2543V118.879C36.8024 119.164 36.7179 119.442 36.559 119.678C36.434 119.869 36.2642 120.026 36.0643 120.136H34.6494C34.4504 120.026 34.2813 119.868 34.1566 119.678C33.9977 119.442 33.9132 119.164 33.9141 118.879V88.2543C33.9141 87.8714 34.0662 87.5042 34.3369 87.2334C34.6077 86.9626 34.9749 86.8105 35.3578 86.8105C35.7407 86.8105 36.108 86.9626 36.3787 87.2334C36.6495 87.5042 36.8016 87.8714 36.8016 88.2543Z"
        fill="#1A1A1A"
      />
      <path
        d="M37.3525 88.2539H9.42252V87.4664C9.42252 86.7839 9.55695 86.108 9.81815 85.4774C10.0794 84.8468 10.4622 84.2738 10.9448 83.7912C11.4275 83.3086 12.0004 82.9257 12.631 82.6645C13.2616 82.4033 13.9375 82.2689 14.62 82.2689H32.155C33.5335 82.2689 34.8555 82.8165 35.8302 83.7912C36.8049 84.7659 37.3525 86.0879 37.3525 87.4664V88.2539H37.3525Z"
        fill="#1A1A1A"
      />
      <path
        d="M35.3582 99.6746H7.42812C7.04522 99.6746 6.678 99.5225 6.40724 99.2518C6.13649 98.981 5.98438 98.6138 5.98438 98.2309C5.98438 97.848 6.13649 97.4807 6.40724 97.21C6.678 96.9392 7.04522 96.7871 7.42812 96.7871H35.3582C35.7411 96.7871 36.1083 96.9392 36.379 97.21C36.6498 97.4807 36.8019 97.848 36.8019 98.2309C36.8019 98.6138 36.6498 98.981 36.379 99.2518C36.1083 99.5225 35.7411 99.6746 35.3582 99.6746Z"
        fill="#1A1A1A"
      />
      <path
        d="M71.2531 112.266L68.2188 110.814L72.3869 98.1309L76.8657 100.273L71.2531 112.266Z"
        fill="#FDB797"
      />
      <path
        d="M91.8166 111.118L88.5538 111.923L83.8262 99.4403L88.6421 98.252L91.8166 111.118Z"
        fill="#FDB797"
      />
      <path
        d="M79.3109 57.1397C79.7437 57.8426 80.4342 58.3485 81.2348 58.5494C82.0355 58.7503 82.8829 58.6302 83.5963 58.2148C83.7398 58.128 83.8758 58.0295 84.0029 57.9202L93.5964 63.4256L93.1798 57.7741L84.1335 53.4125C83.6413 52.918 82.9989 52.6009 82.307 52.5108C81.6152 52.4207 80.913 52.5627 80.3105 52.9145C79.9636 53.1228 79.662 53.3984 79.4232 53.725C79.1845 54.0517 79.0135 54.4228 78.9203 54.8165C78.8272 55.2102 78.8138 55.6186 78.8808 56.0176C78.9479 56.4166 79.0941 56.7981 79.3109 57.1397Z"
        fill="#FDB797"
      />
      <path
        d="M89.4717 61.3503L91.1675 62.3837L98.7836 67.028C99.2838 67.341 99.8409 67.5523 100.423 67.6499C101.005 67.7475 101.6 67.7294 102.175 67.5967C102.75 67.464 103.294 67.2192 103.774 66.8765C104.254 66.5337 104.662 66.0996 104.975 65.599C105.539 64.6699 105.738 63.5648 105.535 62.4972L102.15 45.5856C102.025 44.9217 101.758 44.2927 101.367 43.7414C100.977 43.1902 100.472 42.7298 99.8869 42.3917C99.302 42.0536 98.6511 41.8457 97.9785 41.7824C97.3059 41.719 96.6276 41.8017 95.9898 42.0246C94.9179 42.4013 94.0298 43.1722 93.5061 44.1805C92.9824 45.1888 92.8625 46.3587 93.1709 47.4522L96.7104 59.454L91.2848 56.9245L89.4717 61.3503Z"
        fill="#E6E6E6"
      />
      <path
        d="M85.4625 107.619L80.2412 97.1476L80.2419 97.1236L80.7574 77.9167C80.7638 77.7459 80.7776 77.5743 80.7985 77.4055C80.8895 76.6353 81.133 75.891 81.5147 75.216C81.8963 74.5409 82.4086 73.9486 83.0215 73.4735C83.6085 73.0142 84.2812 72.6764 85.0001 72.4797C85.7191 72.2831 86.47 72.2316 87.2091 72.3283L102.139 74.2604L102.657 72.1432L102.754 72.108L117.059 66.8652L117.142 66.9995C119.015 70.048 120.325 76.1374 120.682 77.9311L120.687 77.9569C120.694 77.9947 120.699 78.0176 120.7 78.0243C121.437 81.0852 118.394 86.1557 115.362 86.9179C108.977 88.5454 103.267 87.2759 96.6543 85.8056C94.2832 85.2782 91.8327 84.7332 89.1335 84.262L89.2137 85.8506C89.2795 87.0734 89.5335 88.2787 89.9668 89.4239C90.4481 90.8335 90.8282 91.9468 90.0244 94.2682L89.6355 95.3911C89.8372 95.9486 89.9067 96.5454 89.8384 97.1343C89.7701 97.7232 89.5659 98.2882 89.2419 98.7847L89.1638 98.9042L92.6071 107.617L85.4625 107.619Z"
        fill="#1A1A1A"
      />
      <path
        d="M76.8846 102.991L70.6582 102.412L72.8641 95.3573C73.3193 90.751 74.4663 86.2396 76.2664 81.9753L79.2752 74.8447C79.2822 74.686 79.2955 74.5263 79.3149 74.3698C79.406 73.5997 79.6494 72.8554 80.0311 72.1803C80.4127 71.5053 80.925 70.913 81.5379 70.4379C82.1251 69.9788 82.7977 69.6411 83.5166 69.4445C84.2355 69.2479 84.9864 69.1963 85.7254 69.2928L100.634 71.2221L101.283 65.7723L115.592 63.8574L115.658 63.964C118.159 68.0335 119.747 71.2193 120.655 73.9901C120.701 74.1314 120.741 74.2755 120.776 74.4192C121.517 77.5008 119.666 80.625 116.65 81.3836C108.962 83.3435 99.7179 83.2891 87.5637 81.2115L81.6814 95.21C81.1192 96.5471 80.4231 97.8238 79.6038 99.0207L76.8846 102.991Z"
        fill="#1A1A1A"
      />
      <path
        d="M98.6011 32.3206C102.584 32.3206 105.814 29.0195 105.814 24.9474C105.814 20.8753 102.584 17.5742 98.6011 17.5742C94.6178 17.5742 91.3887 20.8753 91.3887 24.9474C91.3887 29.0195 94.6178 32.3206 98.6011 32.3206Z"
        fill="#FDB797"
      />
      <path
        d="M100.686 71.4789L99.651 67.7715L99.3966 65.9428L97.9092 65.9924L94.9899 59.2542L93.8561 57.4774C93.6248 57.234 93.4154 56.9999 93.2276 56.7751C92.0462 55.4398 91.0989 53.9144 90.4258 52.2634C89.9541 51.0772 89.6771 49.8226 89.6056 48.548C89.394 44.7832 90.981 40.8 94.4576 36.3715C94.4006 36.2148 94.3626 36.0518 94.3444 35.886C94.2822 35.348 94.435 34.8073 94.7695 34.3814C95.104 33.9555 95.5931 33.6789 96.1305 33.6118L99.4837 33.2352C99.7444 33.2057 100.008 33.2283 100.26 33.3018C100.512 33.3752 100.747 33.498 100.951 33.6631C101.164 33.8338 101.341 34.0452 101.472 34.285C101.602 34.5248 101.683 34.7882 101.711 35.0597C101.756 35.4798 101.673 35.9037 101.474 36.2762C103.183 36.4932 104.794 37.1987 106.112 38.308C107.43 39.4173 108.4 40.8833 108.906 42.5303C110.913 46.276 112.512 50.226 113.677 54.3125C113.906 55.1129 113.804 55.9376 113.705 56.7352C113.614 57.4719 113.519 58.2337 113.678 58.993C113.84 59.7653 114.228 60.54 114.64 61.3602C115.111 62.2995 115.598 63.2709 115.73 64.261L115.739 64.3306L100.686 71.4789Z"
        fill="#B4B4B4"
      />
      <path
        opacity="0.2"
        d="M90.5137 52.2258C91.1839 53.8672 92.1259 55.3839 93.3002 56.7121C93.5014 56.9523 93.7138 57.1882 93.9361 57.4246L95.0771 59.2148C99.2829 56.0623 102.69 43.6497 102.69 43.6497L97.9451 42.7266L90.5137 52.2258Z"
        fill="black"
      />
      <path
        d="M87.6401 33.0099C88.0557 33.7237 88.1763 34.5714 87.9763 35.3728C87.7764 36.1741 87.2716 36.8658 86.5695 37.3007C86.4253 37.3868 86.2743 37.4609 86.118 37.5221L86.4539 48.5784L81.666 45.5644L82.0811 35.5273C81.8773 34.8593 81.9005 34.1428 82.1468 33.4894C82.3932 32.836 82.8489 32.2825 83.4429 31.9154C83.7889 31.7061 84.1733 31.5682 84.5735 31.51C84.9736 31.4518 85.3814 31.4744 85.7726 31.5764C86.1639 31.6785 86.5308 31.8579 86.8515 32.1041C87.1723 32.3503 87.4404 32.6583 87.6401 33.0099Z"
        fill="#FDB797"
      />
      <path
        d="M86.8756 44.4047L81.6104 45.1534L81.8367 56.0569C81.8499 56.8348 82.0617 57.5963 82.452 58.2692C82.8424 58.9422 83.3984 59.5041 84.0671 59.9016C84.884 60.3822 85.835 60.5843 86.7768 60.4774C87.7186 60.3705 88.6002 59.9603 89.2886 59.3088L101.719 47.3522C102.594 46.5225 103.117 45.3892 103.182 44.1854C103.247 42.9815 102.847 41.7987 102.067 40.8802C101.68 40.4229 101.206 40.0478 100.672 39.7773C100.138 39.5067 99.5547 39.3463 98.9574 39.3054C98.3601 39.2645 97.7607 39.344 97.1947 39.5392C96.6287 39.7344 96.1077 40.0413 95.6626 40.4417C95.5397 40.5515 95.4229 40.6679 95.3126 40.7903L87.1045 50.2329L86.8756 44.4047Z"
        fill="#E6E6E6"
      />
      <path
        d="M21.6544 32.5353C25.7739 32.5353 29.1135 29.1958 29.1135 25.0763C29.1135 20.9567 25.7739 17.6172 21.6544 17.6172C17.5349 17.6172 14.1953 20.9567 14.1953 25.0763C14.1953 29.1958 17.5349 32.5353 21.6544 32.5353Z"
        fill="#FDB797"
      />
      <path
        d="M48.9995 44.9443C48.5988 44.8348 48.1789 44.8144 47.7695 44.8847C47.3601 44.955 46.9711 45.1141 46.6298 45.351C46.2886 45.5879 46.0034 45.8967 45.7944 46.2557C45.5855 46.6148 45.4577 47.0152 45.4203 47.4289L39.2227 49.366L39.4812 53.4193L48.195 50.561C48.8942 50.5778 49.5753 50.3376 50.1092 49.8859C50.6431 49.4342 50.9928 48.8023 51.092 48.11C51.1911 47.4176 51.0329 46.713 50.6472 46.1296C50.2616 45.5461 49.6753 45.1244 48.9995 44.9443V44.9443Z"
        fill="#FDB797"
      />
      <path
        d="M44.9568 51.6493L40.3248 53.4024L40.2063 53.6322C39.9224 54.1831 39.4312 54.5986 38.8408 54.7874C38.2505 54.9761 37.6094 54.9226 37.0584 54.6387L26.6472 58.5792C26.6472 58.5792 21.9977 53.9264 18.6875 49.7741L19.8157 42.8348C20.077 41.2248 20.6324 39.6766 21.4541 38.2676C28.2428 39.7562 32.1397 49.2463 32.1397 49.2463L44.3747 46.9118L44.9568 51.6493Z"
        fill={color ?? theme.color.companyBrand.primary}
      />
      <path
        d="M20.5952 32.1348C18.7279 32.6392 16.6691 31.9686 15.2118 30.6968C13.7545 29.4249 12.8429 27.6226 12.3177 25.761C11.933 24.3976 11.7506 22.8842 12.3576 21.6042C12.9647 20.3243 14.5985 19.4607 15.859 20.1072C15.0814 19.2013 15.4699 17.7029 16.378 16.9279C17.2862 16.1529 18.5332 15.9307 19.7209 15.8093C21.4446 15.633 23.225 15.6133 24.8653 16.1717C26.5055 16.7301 27.9958 17.9509 28.5091 19.6058C29.0334 19.8499 29.6238 19.9133 30.1879 19.7859C30.7521 19.6585 31.258 19.3477 31.6266 18.902C31.7216 19.4138 31.6453 19.9428 31.4094 20.4069C31.1736 20.871 30.7913 21.2445 30.3219 21.4695L32.835 20.6547C33.2672 21.5696 32.5099 22.7018 31.5414 22.9948C30.573 23.2878 29.5276 22.9675 28.6085 22.5443C27.6895 22.121 26.7989 21.5859 25.8007 21.4209C24.8024 21.2558 23.6299 21.5766 23.1681 22.4768C22.9263 22.9484 22.9137 23.5006 22.7733 24.0116C22.6328 24.5226 22.2772 25.0524 21.7489 25.0943C21.4151 25.1207 21.0883 24.9429 20.7555 24.9792C20.3428 25.0243 20.027 25.3981 19.9171 25.7984C19.8343 26.2063 19.8332 26.6266 19.9141 27.0349L20.5525 31.9144"
        fill="#1A1A1A"
      />
      <path
        d="M11.3544 81.6315C11.353 81.6315 11.3516 81.6314 11.3502 81.631L11.2912 81.621L11.2741 81.5637C11.0597 80.8423 10.7868 80.104 10.5229 79.3899C10.1342 78.401 9.80605 77.3894 9.54018 76.3607C9.33094 75.3772 9.18586 74.3811 9.10586 73.3787C9.03224 72.651 8.9561 71.8985 8.82665 71.1577C8.76917 70.8289 8.987 70.5174 9.19767 70.2161C9.39299 69.9369 9.5775 69.673 9.53878 69.4235C9.49918 69.1683 9.21617 68.888 8.94248 68.6169C8.64247 68.3198 8.33225 68.0125 8.28833 67.6892C7.6904 63.277 7.41209 58.8274 7.45558 54.3752C7.51738 48.2151 7.79578 44.0163 8.28299 41.8952C9.59311 36.192 15.6459 35.1512 16.5933 35.0227C16.6942 34.754 16.8494 34.5088 17.0491 34.3026C17.2488 34.0964 17.4889 33.9335 17.7543 33.824C18.0197 33.7146 18.3048 33.6609 18.5918 33.6664C18.8789 33.6719 19.1617 33.7364 19.4227 33.8559L23.7233 35.8246C24.5957 36.2253 25.3763 36.8012 26.0167 37.5164C26.6571 38.2316 27.1436 39.0709 27.4459 39.982L28.0848 41.9297L28.4048 55.0042L26.6314 64.2112L30.0964 69.4087C11.6291 79.5477 11.4139 81.6315 11.3544 81.6315Z"
        fill={color ?? theme.color.companyBrand.primary}
      />
      <path
        d="M42.8385 115.525L46.5757 115.525L48.3536 101.109L42.8379 101.11L42.8385 115.525Z"
        fill="#FDB797"
      />
      <path
        d="M47.7943 111.082H42.502L41.1528 109.273L40.7876 107.569L40.4733 107.265C40.0469 106.856 39.7712 106.314 39.6909 105.729C39.6106 105.143 39.7304 104.548 40.031 104.039L36.1954 86.1437C35.1909 86.0554 23.8621 85.0425 15.9871 83.6487C13.9717 83.2921 12.3982 82.3124 11.3102 80.737C8.63682 76.8656 9.78202 70.6017 9.79388 70.5389L9.80709 70.4691L9.87773 70.4611L28.272 68.3965L28.2968 68.4082C28.9072 68.6974 43.2735 75.5176 45.6212 77.8653C46.6814 78.9254 49.0465 85.2241 49.5761 91.9204C49.6709 93.12 49.3296 94.3417 48.9994 95.5232C48.6906 96.6287 48.3712 97.7717 48.4343 98.8524C48.5317 100.52 48.6239 102.269 48.7086 104.051C48.7221 104.335 48.9361 104.629 49.143 104.913C49.3591 105.21 49.5633 105.49 49.5762 105.784C49.702 108.63 47.8419 111.021 47.8231 111.045L47.7943 111.082Z"
        fill="#1A1A1A"
      />
      <path
        d="M40.8262 114.07L42.0413 114.07L46.7852 112.141L49.3278 114.07H49.3281C50.765 114.07 52.143 114.64 53.1591 115.656C54.1751 116.673 54.7459 118.051 54.7461 119.487V119.663L40.8264 119.664L40.8262 114.07Z"
        fill="#1A1A1A"
      />
      <path
        d="M50.552 54.2738C50.1937 54.0635 49.7938 53.9343 49.3802 53.8954C48.9666 53.8564 48.5495 53.9085 48.1583 54.0482C47.7671 54.1878 47.4112 54.4116 47.1158 54.7036C46.8204 54.9957 46.5926 55.349 46.4485 55.7386L39.9602 55.9916L39.0859 59.0138L48.31 59.486C48.9806 59.6847 49.7007 59.6306 50.334 59.3338C50.9673 59.037 51.4697 58.5182 51.7461 57.8757C52.0224 57.2333 52.0535 56.5117 51.8334 55.8479C51.6134 55.184 51.1574 54.624 50.552 54.2738Z"
        fill="#FDB797"
      />
      <path
        d="M45.2217 59.81L38.5569 59.7269L37.7132 59.7155L24.1533 59.5446C24.1533 59.5446 20.471 56.0316 17.2646 52.4148L17.7141 49.1642C18.1524 45.9741 19.7349 43.0519 22.167 40.9414C26.7593 44.0099 29.4225 50.0838 29.4225 50.0838L33.8878 52.0819C37.0303 52.2224 40.0925 53.1192 42.8144 54.6961L44.2828 55.5469L45.2217 59.81Z"
        fill={color ?? theme.color.companyBrand.primary}
      />
      <path
        d="M94.198 117.267L91.9804 117.815L91.0678 115.819L90.5708 118.162L84.6892 119.614C84.4072 119.684 84.1104 119.661 83.8421 119.55C83.5738 119.439 83.348 119.245 83.1977 118.997C83.0473 118.748 82.9804 118.458 83.0065 118.169C83.0327 117.88 83.1506 117.607 83.3431 117.389L87.2394 112.986L86.7171 110.87L91.7301 109.945L94.198 117.267Z"
        fill="#1A1A1A"
      />
      <path
        d="M70.7051 117.953L68.5903 117.089L69.0278 114.939L67.2461 116.541L61.6373 114.251C61.3684 114.142 61.1417 113.949 60.9901 113.701C60.8386 113.453 60.7702 113.164 60.795 112.874C60.8198 112.585 60.9364 112.311 61.1278 112.093C61.3192 111.874 61.5754 111.723 61.859 111.661L67.6006 110.395L68.4246 108.377L73.0209 110.581L70.7051 117.953Z"
        fill="#1A1A1A"
      />
      <path
        d="M63.3398 59.1088H61.6073C59.7445 59.1067 57.9585 58.3658 56.6413 57.0486C55.3241 55.7313 54.5832 53.9454 54.5811 52.0826V51.9863H70.3661V52.0826C70.3639 53.9454 69.623 55.7313 68.3058 57.0486C66.9886 58.3658 65.2026 59.1067 63.3398 59.1088Z"
        fill="#1A1A1A"
      />
      <path
        d="M61.6075 49.4854C61.6075 49.9638 60.7413 50.3516 60.7413 50.3516C60.7413 50.3516 59.875 49.9638 59.875 49.4854C59.875 49.007 60.7413 48.6191 60.7413 48.6191C60.7413 48.6191 61.6075 49.007 61.6075 49.4854Z"
        fill="#1A1A1A"
      />
      <path
        d="M66.8057 49.4854C66.8057 49.9638 65.9395 50.3516 65.9395 50.3516C65.9395 50.3516 65.0732 49.9638 65.0732 49.4854C65.0732 49.007 65.9395 48.6191 65.9395 48.6191C65.9395 48.6191 66.8057 49.007 66.8057 49.4854Z"
        fill="#1A1A1A"
      />
      <path
        d="M63.3399 51.2159C63.3399 51.6943 62.4737 52.0821 62.4737 52.0821C62.4737 52.0821 61.6074 51.6943 61.6074 51.2159C61.6074 50.7374 62.4737 50.3496 62.4737 50.3496C62.4737 50.3496 63.3399 50.7374 63.3399 51.2159Z"
        fill="#1A1A1A"
      />
      <path
        d="M57.2764 47.6544C57.7548 47.6544 58.1427 47.2665 58.1427 46.7881C58.1427 46.3097 57.7548 45.9219 57.2764 45.9219C56.798 45.9219 56.4102 46.3097 56.4102 46.7881C56.4102 47.2665 56.798 47.6544 57.2764 47.6544Z"
        fill="#1A1A1A"
      />
      <path
        d="M44.6782 44.9266L58.3404 46.8135C58.464 46.8306 58.5829 46.8725 58.6898 46.9368C58.7967 47.001 58.8895 47.0863 58.9626 47.1875C59.0356 47.2886 59.0874 47.4035 59.1149 47.5252C59.1423 47.647 59.1447 47.773 59.1221 47.8957C59.0994 48.0184 59.0521 48.1352 58.9831 48.2391C58.914 48.343 58.8246 48.4319 58.7202 48.5002C58.6159 48.5686 58.4987 48.6152 58.3759 48.637C58.2531 48.6589 58.1271 48.6556 58.0055 48.6274L44.5702 45.5113C44.4956 45.494 44.4305 45.4486 44.3886 45.3846C44.3466 45.3205 44.3309 45.2427 44.3448 45.1674C44.3587 45.0921 44.4011 45.025 44.4632 44.9802C44.5253 44.9353 44.6023 44.9161 44.6782 44.9266Z"
        fill="#E4E4E4"
      />
      <path
        d="M117.903 21.7482C116.677 20.3185 116.094 18.4217 115.114 16.7982C114.134 15.1747 112.428 13.7217 110.627 14.0995C109.385 14.36 108.425 15.4559 107.939 16.6778C107.453 17.8997 107.362 19.2473 107.277 20.5674C107.307 17.8545 105.519 15.2246 103.057 14.3589C100.595 13.4933 97.649 14.4589 96.102 16.6383C95.4268 16.0126 94.5456 15.6563 93.6253 15.637C92.705 15.6176 91.8096 15.9366 91.1088 16.5335C89.725 17.7703 89.4188 20.1243 90.4365 21.7018C91.4541 23.2793 93.6581 23.867 95.2754 22.9921L95.8471 23.5181C95.5253 24.6437 96.1494 25.9733 97.1986 26.3971C98.0493 26.7407 99.2504 26.6835 99.5687 27.5763C99.7912 28.2001 99.3754 28.866 98.9331 29.3454C98.4908 29.8247 97.9657 30.3198 97.9126 30.9831C97.8323 31.984 98.8666 32.6851 99.8068 32.8963C102.074 33.4055 104.59 32.3556 105.896 30.3548C105.921 30.3163 105.949 30.2776 105.978 30.2389C106.712 29.3014 107.246 28.2242 107.549 27.0732C107.851 25.9223 107.916 24.7216 107.739 23.5447C107.666 23.0823 107.74 22.7383 108.059 22.6288C109.437 22.1554 113.298 23.1489 114.466 24.0468C115.635 24.9447 116.57 26.133 117.696 27.0888C118.821 28.0447 120.256 28.782 121.685 28.5217C122.829 28.3132 123.803 27.4823 124.458 26.48C125.113 25.4777 125.49 24.3094 125.827 23.1483C123.296 24.6104 119.833 23.9984 117.903 21.7482Z"
        fill="#1A1A1A"
      />
      <path
        d="M229.155 61.8553C229.092 61.5052 229.106 61.1454 229.197 60.8013C229.287 60.4572 229.452 60.1372 229.68 59.8638C229.908 59.5904 230.192 59.3702 230.515 59.2188C230.837 59.0674 231.188 58.9885 231.544 58.9875L234.357 50.8086L237.1 54.369L234.008 61.59C233.97 62.1878 233.714 62.751 233.289 63.1727C232.863 63.5944 232.298 63.8454 231.699 63.8781C231.101 63.9108 230.512 63.723 230.043 63.3502C229.574 62.9773 229.258 62.4454 229.155 61.8553H229.155Z"
        fill="#FDB797"
      />
      <path
        d="M164.91 88.2324H183.621C184.483 88.2324 185.181 88.9305 185.181 89.7917C185.181 90.6528 184.483 91.3509 183.621 91.3509H164.91C164.049 91.3509 163.351 90.6528 163.351 89.7917C163.351 88.9305 164.049 88.2324 164.91 88.2324Z"
        fill="#1A1A1A"
      />
      <path
        d="M155.843 60.4326L162.023 89.7898L160.414 97.3036L157.375 111.484V117.858C157.374 117.867 157.375 117.875 157.376 117.884C157.377 118.025 157.403 118.164 157.453 118.296H159.895C159.945 118.164 159.971 118.025 159.972 117.884C159.973 117.875 159.974 117.867 159.973 117.858V111.758L162.732 98.8872L163.289 96.2884L164.369 91.252L164.681 89.7933V89.7915L164.372 88.3293L164.135 87.2014L158.386 59.899C158.352 59.7319 158.284 59.5732 158.188 59.4322C158.092 59.2912 157.969 59.1705 157.826 59.0771C157.683 58.9836 157.523 58.9193 157.355 58.8877C157.187 58.8561 157.015 58.8579 156.848 58.8929C156.681 58.928 156.522 58.9956 156.381 59.092C156.24 59.1884 156.12 59.3116 156.026 59.4546C155.933 59.5975 155.869 59.7575 155.837 59.9253C155.806 60.0932 155.808 60.2655 155.843 60.4326L155.843 60.4326Z"
        fill="#1A1A1A"
      />
      <path
        d="M182.322 89.7896V117.856C182.322 117.865 182.322 117.874 182.324 117.882C182.325 118.023 182.351 118.163 182.4 118.294H184.843C184.892 118.163 184.918 118.023 184.919 117.882C184.921 117.874 184.921 117.865 184.921 117.856V89.7896C184.921 89.445 184.784 89.1145 184.54 88.8708C184.297 88.6271 183.966 88.4902 183.621 88.4902C183.277 88.4902 182.946 88.6271 182.703 88.8708C182.459 89.1145 182.322 89.445 182.322 89.7896Z"
        fill="#1A1A1A"
      />
      <path
        d="M180.503 85.1133H168.029C166.788 85.1133 165.598 85.6061 164.721 86.4834C163.844 87.3606 163.351 88.5504 163.351 89.791H185.181C185.181 88.5504 184.688 87.3606 183.811 86.4834C182.933 85.6061 181.744 85.1133 180.503 85.1133Z"
        fill="#1A1A1A"
      />
      <path
        d="M161.792 98.8859H183.622C183.966 98.8859 184.297 98.749 184.54 98.5053C184.784 98.2616 184.921 97.9311 184.921 97.5865C184.921 97.2419 184.784 96.9114 184.54 96.6677C184.297 96.424 183.966 96.2871 183.622 96.2871H161.792C161.447 96.2871 161.117 96.424 160.873 96.6677C160.63 96.9114 160.493 97.2419 160.493 97.5865C160.493 97.9311 160.63 98.2616 160.873 98.5053C161.117 98.749 161.447 98.8859 161.792 98.8859Z"
        fill="#1A1A1A"
      />
      <path
        d="M208.184 99.7773L207.408 106.072C205.642 105.76 203.9 105.384 202.181 104.944V99.7791L208.184 99.7773Z"
        fill="#FDB797"
      />
      <path
        d="M230.256 52.3021C230.698 52.0557 231.188 51.909 231.692 51.8724C232.196 51.8358 232.701 51.9101 233.174 52.0901C233.646 52.2702 234.073 52.5516 234.424 52.9145C234.776 53.2774 235.044 53.713 235.209 54.1907L247.493 54.2052L243.972 59.53L232.847 58.6999C232.026 58.924 231.152 58.8393 230.389 58.4618C229.627 58.0843 229.029 57.4403 228.71 56.6517C228.391 55.8631 228.372 54.9847 228.657 54.1831C228.942 53.3814 229.511 52.7122 230.256 52.3021L230.256 52.3021Z"
        fill="#FDB797"
      />
      <path
        d="M236.954 59.5002L259.078 61.698L259.136 61.6234C259.248 61.4807 270.322 47.305 272.183 43.9304C273.327 41.903 273.744 39.545 273.364 37.248L273.351 37.1918L273.307 37.1546C272.405 36.3985 271.272 35.9724 270.095 35.9466C268.918 35.9208 267.768 36.2968 266.833 37.0126C262.34 40.4928 258.717 44.9697 256.25 50.0901L254.85 53.0043L238.615 53.7009L236.954 59.5002Z"
        fill={color ?? theme.color.companyBrand.primary}
      />
      <path
        d="M228.078 100.449L222.847 111.268C222.376 111.236 221.906 111.195 221.437 111.143C221.065 111.107 220.697 111.064 220.326 111.014L220.19 111.052L220.096 110.984C219.597 110.921 219.098 110.848 218.599 110.766C218.388 110.731 218.177 110.697 217.964 110.659C217.865 110.642 217.766 110.624 217.667 110.604L218.077 109.53L218.561 108.256L220.481 103.214L223.253 95.9349L225.154 97.7117L228.078 100.449Z"
        fill="#FDB797"
      />
      <path
        d="M247.528 92.7765L253.391 103.267C253.094 103.635 252.791 103.996 252.48 104.35C252.237 104.634 251.99 104.911 251.737 105.187L251.69 105.319L251.581 105.357C251.242 105.73 250.896 106.097 250.543 106.459C250.393 106.612 250.244 106.765 250.092 106.918C250.02 106.989 249.949 107.06 249.876 107.13L249.231 106.177L248.466 105.051L245.437 100.586L241.064 94.1395L243.609 93.6015L247.528 92.7765Z"
        fill="#FDB797"
      />
      <path
        d="M251.916 99.9967C250.241 100.806 248.531 101.55 246.788 102.23L245.083 99.3626L250.578 96.9492L251.916 99.9967Z"
        fill="#FDB797"
      />
      <path
        d="M276.745 86.5H258.034C257.173 86.5 256.475 87.1981 256.475 88.0592C256.475 88.9204 257.173 89.6185 258.034 89.6185H276.745C277.606 89.6185 278.304 88.9204 278.304 88.0592C278.304 87.1981 277.606 86.5 276.745 86.5Z"
        fill="#1A1A1A"
      />
      <path
        d="M285.812 58.7002L279.632 88.0574L281.242 95.5712L284.281 109.752V116.126C284.281 116.134 284.28 116.143 284.279 116.152C284.278 116.292 284.252 116.432 284.203 116.564H281.76C281.71 116.432 281.685 116.292 281.684 116.152C281.682 116.143 281.682 116.134 281.682 116.126V110.025L278.924 97.1547L278.366 94.556L277.286 89.5196L276.975 88.0608V88.0591L277.283 86.5969L277.52 85.469L283.269 58.1666C283.304 57.9994 283.371 57.8408 283.467 57.6998C283.564 57.5588 283.687 57.4381 283.83 57.3446C283.973 57.2512 284.132 57.1869 284.3 57.1553C284.468 57.1237 284.64 57.1254 284.807 57.1605C284.975 57.1956 285.133 57.2632 285.274 57.3596C285.415 57.4559 285.536 57.5791 285.629 57.7221C285.722 57.8651 285.786 58.0251 285.818 58.1929C285.849 58.3607 285.847 58.5331 285.812 58.7002L285.812 58.7002Z"
        fill="#1A1A1A"
      />
      <path
        d="M259.333 88.0572V116.124C259.333 116.132 259.333 116.141 259.332 116.15C259.33 116.291 259.305 116.43 259.255 116.562H256.812C256.763 116.43 256.737 116.291 256.736 116.15C256.735 116.141 256.734 116.132 256.734 116.124V88.0572C256.734 87.7126 256.871 87.3821 257.115 87.1384C257.359 86.8947 257.689 86.7578 258.034 86.7578C258.378 86.7578 258.709 86.8947 258.953 87.1384C259.196 87.3821 259.333 87.7126 259.333 88.0572Z"
        fill="#1A1A1A"
      />
      <path
        d="M261.152 83.3809H273.626C274.867 83.3809 276.057 83.8737 276.934 84.7509C277.811 85.6282 278.304 86.818 278.304 88.0586H256.475C256.475 86.818 256.967 85.6282 257.845 84.7509C258.722 83.8737 259.912 83.3809 261.152 83.3809Z"
        fill="#1A1A1A"
      />
      <path
        d="M279.863 97.1534H258.034C257.689 97.1534 257.359 97.0165 257.115 96.7729C256.871 96.5292 256.734 96.1987 256.734 95.8541C256.734 95.5094 256.871 95.1789 257.115 94.9353C257.359 94.6916 257.689 94.5547 258.034 94.5547H279.863C280.208 94.5547 280.538 94.6916 280.782 94.9353C281.026 95.1789 281.163 95.5094 281.163 95.8541C281.163 96.1987 281.026 96.5292 280.782 96.7729C280.538 97.0165 280.208 97.1534 279.863 97.1534Z"
        fill="#1A1A1A"
      />
      <path
        d="M280.101 68.6217L278.602 75.0423C278.749 75.9725 278.797 76.9155 278.748 77.8559C278.037 78.8088 277.304 79.7426 276.549 80.6573C275.853 81.5011 275.138 82.3286 274.404 83.14C273.533 84.1067 272.635 85.0486 271.712 85.9657C269.307 86.7211 266.473 87.0295 263.51 87.0503C262.488 87.0589 261.45 87.0312 260.409 86.9758C256.187 86.754 251.916 86.0766 248.418 85.3732C247.493 85.1896 246.623 85.0024 245.823 84.8205C244.307 84.4792 243.039 84.1604 242.121 83.9179C241.086 83.6476 240.497 83.4726 240.497 83.4726L236.563 90.0613L232.99 96.0419L226.481 106.941C226.041 106.981 225.601 107.014 225.16 107.042C224.692 107.074 224.223 107.101 223.754 107.121C223.384 107.14 223.015 107.154 222.642 107.165L222.516 107.204L222.412 107.172L220.742 106.64L220.179 106.461L218.153 105.817C218.153 105.817 218.606 104.393 219.397 102.017C219.688 101.146 220.024 100.144 220.402 99.0391C221.766 95.044 223.656 89.6785 225.813 84.053C226.452 82.3829 227.114 80.6885 227.795 79.0028C228.353 77.6185 228.921 76.2395 229.496 74.8794C230.496 72.5215 235.326 71.2498 241.014 70.5828C241.303 70.5499 241.594 70.517 241.887 70.4858C244.785 70.1809 247.86 70.0232 250.736 69.9505C252.176 69.9141 253.566 69.9002 254.858 69.8985C255.276 69.8985 255.682 69.8997 256.076 69.902H256.078C257.143 69.9072 258.124 69.9228 258.987 69.9401C259.155 69.9436 259.318 69.9488 259.477 69.9522H259.479C259.586 69.9539 259.692 69.9574 259.796 69.9591C261.682 70.0077 262.84 70.0666 262.84 70.0666L263.288 66.5461L263.38 65.8358L263.467 65.1601V65.1584L263.477 65.0735L263.548 65.0718L264.796 65.0475L275.281 64.8431L281.008 64.7305L280.101 68.6217Z"
        fill="#1A1A1A"
      />
      <path
        d="M253.208 99.355C252.781 99.5733 252.349 99.7881 251.916 99.996C250.241 100.805 248.531 101.549 246.788 102.229C246.291 102.425 245.792 102.614 245.287 102.796C245.232 102.718 245.175 102.64 245.118 102.562C242.713 99.2614 239.657 94.9059 236.564 90.0601C235.27 88.0331 233.969 85.9194 232.706 83.7607L241.85 79.4918L242.876 79.0137L245.825 84.8193L248.454 89.9977L249.04 91.1498L253.208 99.355Z"
        fill="#1A1A1A"
      />
      <path
        d="M266.481 27.171C271.134 27.171 274.905 23.3993 274.905 18.7466C274.905 14.094 271.134 10.3223 266.481 10.3223C261.828 10.3223 258.057 14.094 258.057 18.7466C258.057 23.3993 261.828 27.171 266.481 27.171Z"
        fill="#FDB797"
      />
      <path
        d="M191.327 116.525L194.69 116.524L196.29 103.551L191.326 103.551L191.327 116.525Z"
        fill="#FDB797"
      />
      <path
        d="M195.787 112.528H191.024L189.81 110.9L189.481 109.367L189.198 109.093C188.815 108.725 188.566 108.238 188.494 107.711C188.422 107.183 188.53 106.647 188.8 106.189L185.348 90.0838C184.444 90.0043 174.248 89.0927 167.161 87.8384C165.347 87.5174 163.931 86.6357 162.952 85.2178C160.546 81.7336 161.576 76.096 161.587 76.0395L161.599 75.9766L161.662 75.9695L178.217 74.1113L178.239 74.1219C178.789 74.3821 191.719 80.5203 193.831 82.6332C194.786 83.5874 196.914 89.2562 197.391 95.2829C197.476 96.3624 197.169 97.462 196.872 98.5254C196.594 99.5203 196.306 100.549 196.363 101.522C196.451 103.022 196.534 104.596 196.61 106.2C196.622 106.456 196.815 106.72 197.001 106.976C197.196 107.243 197.379 107.496 197.391 107.76C197.504 110.321 195.83 112.474 195.813 112.495L195.787 112.528Z"
        fill="#1A1A1A"
      />
      <path
        d="M189.516 115.217L190.609 115.217L194.879 113.48L197.167 115.217H197.167C198.461 115.217 199.701 115.73 200.615 116.645C201.53 117.559 202.043 118.799 202.044 120.093V120.251L189.516 120.251L189.516 115.217Z"
        fill="#1A1A1A"
      />
      <path
        d="M203.107 115.136L206.47 115.136L208.071 102.162L203.106 102.162L203.107 115.136Z"
        fill="#FDB797"
      />
      <path
        d="M207.567 111.139H202.804L201.59 109.512L201.262 107.978L200.979 107.704C200.595 107.336 200.347 106.849 200.274 106.322C200.202 105.795 200.31 105.259 200.581 104.801L197.129 88.6952C196.224 88.6156 186.029 87.7041 178.941 86.4497C177.127 86.1287 175.711 85.247 174.732 83.8291C172.326 80.3449 173.356 74.7073 173.367 74.6508L173.379 74.588L173.443 74.5808L189.997 72.7227L190.02 72.7332C190.569 72.9935 203.499 79.1316 205.612 81.2446C206.566 82.1987 208.695 87.8675 209.171 93.8942C209.256 94.9738 208.949 96.0733 208.652 97.1367C208.374 98.1316 208.087 99.1604 208.144 100.133C208.231 101.634 208.314 103.208 208.39 104.812C208.403 105.067 208.595 105.332 208.781 105.587C208.976 105.855 209.16 106.107 209.171 106.371C209.284 108.932 207.61 111.085 207.593 111.106L207.567 111.139Z"
        fill="#1A1A1A"
      />
      <path
        d="M201.296 113.83L202.389 113.83L206.659 112.094L208.947 113.83H208.948C210.241 113.83 211.481 114.344 212.396 115.258C213.31 116.172 213.824 117.413 213.824 118.706V118.864L201.296 118.865L201.296 113.83Z"
        fill="#1A1A1A"
      />
      <path
        d="M266.203 26.8041C268.172 27.7492 270.596 27.4291 272.477 26.3172C274.357 25.2053 275.741 23.392 276.708 21.4331C277.417 19.9985 277.931 18.3555 277.521 16.8091C277.111 15.2626 275.475 13.9668 273.942 14.4249C274.992 13.5792 274.87 11.8352 274.021 10.7874C273.172 9.73958 271.833 9.23573 270.539 8.85598C268.662 8.30485 266.689 7.91584 264.752 8.19775C262.816 8.47965 260.909 9.52807 259.998 11.2599C259.366 11.4228 258.697 11.3714 258.097 11.1137C257.496 10.8559 256.999 10.4064 256.681 9.83549C256.47 10.3843 256.446 10.9874 256.612 11.5514C256.778 12.1154 257.126 12.6089 257.601 12.9556L254.978 11.5327C254.31 12.4595 254.917 13.8729 255.932 14.3979C256.947 14.9229 258.174 14.7828 259.282 14.5023C260.39 14.2218 259.083 16.5613 260.225 16.5838C261.368 16.6063 262.603 17.2042 262.931 18.2992C263.102 18.8726 263.002 19.4885 263.053 20.0849C263.103 20.6812 263.389 21.3429 263.967 21.4982C264.332 21.5964 264.732 21.4664 265.094 21.5753C265.543 21.7105 265.816 22.1906 265.856 22.6578C265.895 23.1249 265.749 23.5855 265.604 24.0315C265.032 25.7937 266.867 24.8059 266.295 26.5682L266.203 26.8041Z"
        fill="#1A1A1A"
      />
      <path
        d="M282.732 51.6888C282.663 44.731 282.347 39.9892 281.796 37.5931C280.317 31.1517 273.482 29.9771 272.411 29.8315C272.297 29.5279 272.122 29.251 271.896 29.0181C271.671 28.7851 271.4 28.6011 271.1 28.4774C270.8 28.3538 270.478 28.2931 270.154 28.2993C269.829 28.3054 269.51 28.3782 269.215 28.5131L264.359 30.7376C263.374 31.1903 262.492 31.8407 261.769 32.6484C261.045 33.456 260.496 34.4038 260.154 35.4327L259.433 37.633L259.071 52.3991L260.267 58.6101L260.268 58.6136L261.074 62.7976L260.519 63.6292L257.354 68.3762L257.3 68.4594L257.299 68.4611L257.16 68.6673C257.352 68.7729 257.543 68.8769 257.73 68.9808C258.215 69.2477 258.688 69.5093 259.149 69.7657C259.26 69.828 259.369 69.8887 259.478 69.9511H259.48C259.549 69.9892 259.619 70.0273 259.686 70.0654C261.415 71.0321 262.989 71.9278 264.407 72.7525C264.605 72.8668 264.8 72.9812 264.993 73.0938C271.542 76.9244 274.861 79.2702 276.55 80.6562C277.306 79.7414 278.039 78.8076 278.749 77.8547C279.393 76.9937 280.018 76.1176 280.622 75.2265C280.727 74.5301 280.798 73.8336 280.868 73.151C280.951 72.3298 281.037 71.4791 281.183 70.6423C281.247 70.2716 281.001 69.9199 280.764 69.5803C280.544 69.2632 280.336 68.9653 280.379 68.6846C280.424 68.3953 280.743 68.08 281.053 67.7733C281.391 67.4372 281.741 67.0907 281.791 66.7251C281.93 65.7064 282.053 64.6842 282.16 63.6586C282.337 61.9902 282.473 60.3166 282.567 58.6378C282.701 56.3197 282.756 54.0034 282.732 51.6888Z"
        fill={color ?? theme.color.companyBrand.primary}
      />
      <path
        d="M250.663 76.2494C250.805 75.7646 251.053 75.3171 251.387 74.9383C251.722 74.5594 252.135 74.2586 252.599 74.0568C253.062 73.8551 253.564 73.7574 254.069 73.7706C254.574 73.7839 255.07 73.9077 255.522 74.1334L264.309 65.5488L265.519 71.8163L256.99 79.0075C256.561 79.742 255.877 80.2933 255.068 80.5571C254.259 80.8209 253.381 80.7788 252.601 80.4389C251.821 80.0989 251.193 79.4846 250.836 78.7125C250.479 77.9403 250.417 77.064 250.663 76.2494L250.663 76.2494Z"
        fill="#FDB797"
      />
      <path
        d="M260.487 76.7078L277.83 62.7983L277.82 62.7042C277.8 62.5241 275.793 44.6477 274.761 40.9349C274.161 38.6854 272.809 36.7091 270.93 35.334L270.881 35.3027L270.824 35.3069C269.65 35.398 268.543 35.8864 267.684 36.6914C266.825 37.4964 266.266 38.5701 266.099 39.7354C265.324 45.3659 265.868 51.0995 267.688 56.4837L268.727 59.5452L257.615 71.4026L260.487 76.7078Z"
        fill={color ?? theme.color.companyBrand.primary}
      />
      <path
        d="M171.669 27.0185C175.821 27.0185 179.186 23.653 179.186 19.5014C179.186 15.3499 175.821 11.9844 171.669 11.9844C167.518 11.9844 164.152 15.3499 164.152 19.5014C164.152 23.653 167.518 27.0185 171.669 27.0185Z"
        fill="#FDA57D"
      />
      <path
        d="M161.571 76.755L161.53 76.6975C159.341 73.6318 162.634 66.2651 163.131 65.1972C162.541 65.0058 162.036 64.6158 161.701 64.0937C161.366 63.5716 161.223 62.9497 161.296 62.3338L161.51 60.5137L161.733 58.7283L160.134 57.1293L160.129 57.0895L159.209 49.0378L157.871 43.6899C157.367 41.6683 157.41 39.5488 157.996 37.5493C158.581 35.5497 159.689 33.7419 161.204 32.3117L164.296 31.8206L168.055 28.1992H174.116L176.194 30.1848C180.28 33.5339 181.25 32.079 185.857 38.3836L182.558 47.0746L184.402 59.8931L184.403 62.2128L185.364 63.2802C185.709 63.6646 185.961 64.1246 186.098 64.6233C186.234 65.1221 186.253 65.6459 186.151 66.153L186.025 66.7845C186.513 66.9937 186.944 67.3169 187.281 67.7269C187.619 68.137 187.853 68.622 187.964 69.1413L188.302 70.7152C188.59 71.096 190.495 73.6357 190.495 74.33C190.495 74.4343 190.418 74.535 190.253 74.6468C188.955 75.5258 181.78 77.3177 178.605 76.0471C175.222 74.6947 161.777 76.7235 161.641 76.7442L161.571 76.755Z"
        fill="#A28A6F"
      />
      <path
        d="M157.752 46.2645L164.002 45.041C166.314 48.493 167.555 57.932 167.555 57.932L174.465 72.2631C174.89 72.5212 175.238 72.8872 175.476 73.3239C175.713 73.7605 175.83 74.2523 175.816 74.7491C175.801 75.2458 175.655 75.7298 175.393 76.1518C175.13 76.5738 174.76 76.9187 174.321 77.1513C173.882 77.3839 173.389 77.496 172.892 77.4761C172.396 77.4562 171.913 77.305 171.494 77.038C171.075 76.771 170.734 76.3977 170.506 75.956C170.278 75.5144 170.171 75.0202 170.197 74.5239L161.184 57.5441L157.752 46.2645Z"
        fill="#FDA57D"
      />
      <path
        d="M157.717 47.7474L157.539 38.4577L160.48 34.5228C161.061 33.7523 161.845 33.1596 162.744 32.8115C163.644 32.4633 164.623 32.3737 165.57 32.5527C166.853 32.8041 167.993 33.5332 168.759 34.5927C169.525 35.6523 169.86 36.9631 169.697 38.2603C169.197 42.1939 168.064 47.1166 165.5 48.2303L165.474 48.2416L168.131 57.5362L158.276 58.913L157.717 47.7474Z"
        fill="#A18A6F"
      />
      <path
        d="M194.838 46.9278C194.838 46.9278 187.337 41.0648 183.269 40.2227L179.668 45.4761L189.406 51.4437L208.365 56.9255C208.697 57.2943 209.12 57.5686 209.593 57.7206C210.065 57.8727 210.569 57.8971 211.054 57.7913C211.539 57.6855 211.987 57.4534 212.353 57.1184C212.719 56.7835 212.99 56.3576 213.138 55.8841C213.286 55.4106 213.307 54.9063 213.197 54.4224C213.087 53.9386 212.851 53.4923 212.514 53.129C212.176 52.7656 211.748 52.4982 211.273 52.3537C210.798 52.2093 210.294 52.193 209.811 52.3066L194.838 46.9278Z"
        fill="#FDA57D"
      />
      <path
        d="M181.642 48.1042L173.825 43.0817L172.193 38.4479C171.876 37.5371 171.82 36.5557 172.031 35.6147C172.242 34.6736 172.713 33.8105 173.389 33.1227C174.312 32.1966 175.552 31.6555 176.858 31.6088C178.165 31.562 179.441 32.0132 180.427 32.871C183.418 35.475 187.271 36.3478 186.769 39.0981L188.209 39.3083L195.571 44.8994L191.23 53.8541L181.642 48.1042Z"
        fill="#A18A6F"
      />
      <path
        d="M163.143 14.5631C163.987 13.2572 165.571 12.3869 167.107 12.6315C167.25 11.6515 167.659 10.7296 168.289 9.96598C168.92 9.20239 169.748 8.62645 170.683 8.30087C171.619 7.97529 172.625 7.91255 173.594 8.11948C174.562 8.32641 175.455 8.79508 176.176 9.47445C176.628 9.11472 177.285 9.09581 177.822 9.30973C178.35 9.55003 178.81 9.91908 179.159 10.3831C180.04 11.4819 180.607 12.7991 180.799 14.1944C180.991 15.5897 180.801 17.011 180.249 18.3069C180.437 17.6246 179.753 16.9828 179.062 16.8297C178.371 16.6766 177.647 16.8215 176.946 16.7218C176.048 16.594 175.241 16.0732 174.342 15.956C173.586 15.8574 172.826 16.0512 172.089 16.2454C171.352 16.4397 170.594 16.6377 169.837 16.5478C169.081 16.458 168.106 19.3262 168.119 21.3465C168.121 21.7293 168.042 22.2208 167.668 22.3021C167.208 22.4021 166.941 21.7662 166.502 21.5929C166.111 21.4385 165.643 21.7246 165.507 22.1221C165.452 22.3254 165.446 22.5389 165.49 22.745C165.533 22.951 165.626 23.1436 165.759 23.3066C166.029 23.6299 166.349 23.9075 166.707 24.1287L166.526 24.279C166.226 24.6759 165.603 24.6931 165.17 24.4475C164.737 24.2019 164.452 23.765 164.196 23.3384C163.394 22.0058 162.692 20.586 162.409 19.0571C162.126 17.5281 162.299 15.8689 163.143 14.5631Z"
        fill="#1A1A1A"
      />
      <path
        opacity="0.2"
        d="M277.684 49.4246L273.339 60.0305C272.64 61.7375 271.962 63.4548 271.234 65.1498C270.579 66.676 269.832 68.2088 268.729 69.467C267.616 70.7369 266.118 71.7678 264.408 71.9693C264.08 72.008 264.076 72.5282 264.408 72.4891C265.97 72.305 267.371 71.5282 268.502 70.454C269.725 69.2923 270.587 67.8126 271.291 66.294C272.075 64.6014 272.742 62.8513 273.449 61.1256L275.67 55.703L278.185 49.5627C278.312 49.2534 277.809 49.1187 277.684 49.4246Z"
        fill="black"
      />
      <path
        d="M226.183 59.0529C226.259 59.2751 226.404 59.4674 226.596 59.6022C226.789 59.737 227.019 59.8074 227.254 59.8032L231.844 59.6851C232.08 59.6805 232.309 59.5994 232.496 59.454C232.683 59.3085 232.817 59.1066 232.88 58.8783L233.529 51.9331C233.76 52.0375 234.01 52.0891 234.263 52.084C234.483 52.0785 234.7 52.0297 234.901 51.9406C235.101 51.8514 235.283 51.7235 235.434 51.5642C235.586 51.4049 235.704 51.2174 235.783 51.0123C235.862 50.8071 235.9 50.5884 235.895 50.3687C235.889 50.149 235.84 49.9325 235.751 49.7316C235.662 49.5307 235.534 49.3493 235.375 49.1978C235.216 49.0463 235.028 48.9277 234.823 48.8487C234.618 48.7697 234.399 48.7319 234.179 48.7374C234.078 48.7371 233.977 48.7476 233.879 48.7687C233.771 48.6524 233.64 48.5599 233.495 48.4971C233.35 48.4344 233.193 48.4028 233.034 48.4044L225.974 48.5867C225.916 48.5888 225.857 48.5961 225.8 48.6083C225.645 48.6371 225.498 48.6988 225.369 48.7892C225.24 48.8796 225.132 48.9965 225.052 49.1322C224.972 49.2679 224.922 49.4191 224.906 49.5757C224.889 49.7323 224.906 49.8907 224.956 50.0401L226.183 59.0529ZM233.662 51.4616L234.125 49.8066C234.177 49.6154 234.178 49.4137 234.128 49.2219C234.15 49.2219 234.17 49.2112 234.193 49.2112C234.51 49.2046 234.816 49.324 235.045 49.5431C235.274 49.7623 235.407 50.0633 235.414 50.3801C235.422 50.697 235.303 51.0039 235.085 51.2335C234.866 51.4631 234.566 51.5967 234.249 51.605C234.044 51.6114 233.841 51.5618 233.662 51.4616Z"
        fill="#1A1A1A"
      />
      <path
        d="M225.8 48.6085C225.95 49.4685 227.641 50.0731 229.675 49.968C231.545 49.8819 233.073 49.2253 233.354 48.4472C233.25 48.4165 233.142 48.4021 233.034 48.4046L225.974 48.5869C225.915 48.589 225.857 48.5963 225.8 48.6085Z"
        fill="#1A1A1A"
      />
      <path
        d="M210.895 49.6905C210.944 49.5411 210.961 49.3827 210.945 49.2261C210.928 49.0695 210.878 48.9183 210.798 48.7826C210.718 48.6469 210.61 48.53 210.481 48.4396C210.352 48.3492 210.205 48.2875 210.05 48.2587C209.993 48.2465 209.935 48.2392 209.876 48.2371L202.816 48.0547C202.658 48.0532 202.501 48.0848 202.355 48.1475C202.21 48.2103 202.079 48.3028 201.972 48.4191C201.873 48.398 201.772 48.3875 201.671 48.3878C201.227 48.3767 200.797 48.5423 200.475 48.8482C200.154 49.1541 199.967 49.5753 199.956 50.0191C199.944 50.4629 200.11 50.8929 200.416 51.2146C200.722 51.5363 201.143 51.7233 201.587 51.7344C201.84 51.7395 202.091 51.6879 202.321 51.5835L202.971 58.5287C203.033 58.757 203.168 58.9589 203.355 59.1044C203.541 59.2498 203.77 59.3309 204.007 59.3355L208.597 59.4536C208.831 59.4578 209.062 59.3874 209.254 59.2526C209.447 59.1178 209.591 58.9255 209.668 58.7033L210.895 49.6905ZM201.602 51.2554C201.285 51.2471 200.984 51.1135 200.766 50.8839C200.547 50.6543 200.429 50.3474 200.436 50.0305C200.443 49.7137 200.576 49.4127 200.805 49.1935C201.034 48.9744 201.341 48.855 201.658 48.8616C201.68 48.8616 201.7 48.8723 201.722 48.8723C201.672 49.0641 201.673 49.2658 201.726 49.457L202.188 51.112C202.009 51.2122 201.806 51.2618 201.602 51.2554Z"
        fill="#1A1A1A"
      />
      <path
        d="M210.051 48.2589C209.9 49.1189 208.209 49.7235 206.176 49.6184C204.306 49.5323 202.777 48.8757 202.497 48.0976C202.601 48.0669 202.709 48.0525 202.817 48.055L209.877 48.2373C209.935 48.2394 209.993 48.2467 210.051 48.2589Z"
        fill="#1A1A1A"
      />
      <path
        d="M223.217 110.092L221.95 110.293L216.858 109.329L214.356 111.496L214.355 111.496C212.858 111.734 211.465 112.478 210.484 113.566C209.503 114.654 209.014 115.996 209.124 117.298L209.138 117.457L223.645 115.158L223.217 110.092Z"
        fill="#1A1A1A"
      />
      <path
        d="M253.891 102.252L253.05 103.22L248.529 105.755L248.006 109.024L248.005 109.024C247.011 110.168 246.423 111.634 246.371 113.098C246.318 114.562 246.806 115.905 247.727 116.831L247.84 116.944L257.476 105.857L253.891 102.252Z"
        fill="#1A1A1A"
      />
    </svg>
  );
}
