import { LeftArrow, RightArrow } from "./Arrows";
import React, { useEffect, useRef } from "react";
import useMenu from "../../../hooks/menu/useMenu";
import useScreenType from "../../../hooks/utility/useScreenType";
import { VisibilityContext, ScrollMenu as BasicScrollMenu } from "react-horizontal-scrolling-menu";

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

interface Props {
  children: any;
  focusedCategoryId: null | number;
  jumpToCategory: (id: number) => void;
}

export default function ScrollMenu(props: Props): JSX.Element {
  const { children, focusedCategoryId, jumpToCategory } = props;

  const { categories } = useMenu();

  const { isDesktop: fullScreen } = useScreenType();

  const apiRef = useRef({} as scrollVisibilityApiType);

  useEffect(() => {
    try {
      setTimeout(() => {
        apiRef.current?.scrollToItem?.(
          apiRef.current?.getItemElementById(focusedCategoryId),
          "smooth",
          "start"
        );
      });
    } catch (error) {}
  }, [focusedCategoryId]);
  
  function scrollToEnd(): void {
    try {
      const id = categories[categories?.length - 1]?.id;

      jumpToCategory(id);
    } catch (error) {}
  }

  function scrollToBeginning(): void {
    try {
      jumpToCategory(categories[0]?.id);
    } catch (error) {}
  }

  function onWheel(
    apiObj: scrollVisibilityApiType,
    ev: React.WheelEvent
  ): void {
    const isTouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isTouchpad) {
      ev.stopPropagation();
      return;
    }

    if (ev.deltaY < 0) {
      apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
      apiObj.scrollPrev();
    }
  }

  return (
    <BasicScrollMenu
      onWheel={onWheel}
      apiRef={apiRef}
      LeftArrow={fullScreen ? <div className="flex items-center pr-small" onClick={scrollToBeginning}><LeftArrow /></div> : null}
      RightArrow={fullScreen ? <div className="flex items-center pl-small" onClick={scrollToEnd}><RightArrow /></div> : null}
      wrapperClassName="w-full overflow-auto"
      scrollContainerClassName="scrollbar-hide"
    >
      {children}
    </BasicScrollMenu>
  );
}
