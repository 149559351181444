import {
  ProductComboItemInterface,
  ProductInterface
} from "../../../services/exports/Interfaces";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import BackIcon from "../../../assets/logo/BackIcon";
import AccordionDetails from "@mui/material/AccordionDetails";
import React, {forwardRef, useMemo} from "react";
import {useTranslation} from "react-i18next";
import useTheme from "../../../hooks/ui/useTheme";
import PlusCircleIcon from "../../../assets/logo/PlusCircleIcon";
import ComboItemProduct from "./ComboItemProduct";
import classnames from "classnames";
import collect from "collect.js";
import {BasketLineItemInterface} from "../../../store/types";
import CheckmarkIcon from "../../../assets/logo/CheckmarkIcon";

interface Props {
  data: ProductComboItemInterface;
  lineItemData: BasketLineItemInterface;
  isCollapsed: boolean;
  className?: string;
  onCollapse: () => void;
  onSelect: (product: ProductInterface) => void;
  onSelectNullOption: () => void;
}


const ComboItem= forwardRef((props: Props, ref: React.Ref<HTMLDivElement>): JSX.Element => {
  const { t } = useTranslation(null, { keyPrefix: 'Components:Menu:Combo:ComboItem' });

  const theme = useTheme();

  const {
    data,
    lineItemData,
    isCollapsed,
    className,
    onCollapse,
    onSelect,
    onSelectNullOption,
  } = props;

  const defaultProductPrice = data.is_required
    ? collect(data.products).first()?.actual_price ?? 0
    : 0;

  const renderIcon = () => isCollapsed
    ? (
      <div className="flex">
        {lineItemData ? <CheckmarkIcon className="h-4 w-4 mr-xsmall my-auto" color={theme.color.brand.inkGrey.grey_4} /> : null}
        <PlusCircleIcon className="h-5 w-5 my-auto" />
      </div>
    ) : (
      <BackIcon
        className={"h-4 w-4 -rotate-90"}
        color={theme.color.text.default}
      />
    );

  const renderNullOption = useMemo(() => !data.is_required && (
    <ComboItemProduct
      data={{
        name: t('null_option', { name: data.name }),
        is_available: true,
      } as ProductInterface}
      lineItemData={lineItemData}
      extraCost={0}
      onCLick={onSelectNullOption}
      className="mt-small"
    />
  ), [data.is_required, lineItemData]);

  return (
    <Accordion
      style={{
        boxShadow: "none",
        backgroundColor: "transparent",
        margin: 0,
      }}
      sx={{
        "&:before": {
          display: "none",
        },
      }}
      expanded={!isCollapsed}
      onChange={onCollapse}
      className={className}
      ref={ref}
    >
      <AccordionSummary
        expandIcon={renderIcon()}
        style={{
          margin: 0,
          minHeight: 0,
        }}
        classes={{
          content: "!m-none",
        }}
        className="px-small !py-mini !bg-brand-inkGrey-grey_6"
      >
        <div>
          <h6>{data.name}</h6>
          <p className="text-tiny text-brand-inkGrey-grey_4">{t('description', {name: data.name})}</p>
        </div>
      </AccordionSummary>
      <AccordionDetails className="!p-small">
        {data.products.map((item, index) => (
          <ComboItemProduct
            data={item}
            lineItemData={lineItemData}
            extraCost={item.actual_price - defaultProductPrice}
            onCLick={() => onSelect(item)}
            key={`combo-item-${data.id}-product-${item.id}`}
            className={classnames({'mt-small': index > 0})}
          />
        ))}
        {renderNullOption}
      </AccordionDetails>
    </Accordion>
  );
});

export default ComboItem;