// @ts-nocheck
import {useTranslation} from "react-i18next";
import CustomCarousel, {Item} from "./CustomCarousel";
import useScreenType from "../../hooks/utility/useScreenType";
import classnames from "classnames";
import {ReactElement, useMemo} from "react";
import collect, {Collection} from "collect.js";
import {useSelector} from "react-redux";
import {StoreInterface} from "../../store/types";
import DiscountCard from "../discount/DiscountCard";
import useGoogleReview from "../../hooks/google-review/useGoogleReview";
import GoogleIcon from "../../assets/logo/GoogleIcon";
import PlusCircleIcon, {TYPE} from "../../assets/logo/PlusCircleIcon";
import useTheme from "../../hooks/ui/useTheme";
import {SCREENS} from "../../services/exports/Constants";
import useNavigate from "../../hooks/navigation/useNavigate";
import {useLocation} from "react-router-dom";

interface Props {
  className?: string;
}

export enum DEAL_TYPES {
  GOOGLE_REVIEW_PROGRAM = 'google_review_program',
  SMART_PRICING_RULE = 'smart_pricing_rule',
}

const DealsCarousel = (props: Props): ReactElement => {
  const { className } = props;

  const { t } = useTranslation(null, { keyPrefix: 'Components:Carousel:DealsCarousel' });

  const { company } = useSelector((state: StoreInterface) => state.initialData);

  const theme = useTheme();
  const location = useLocation();
  const { navigate } = useNavigate();
  const { isDesktop } = useScreenType();
  const { hasGooglePromotion, hasUploadedGoogleReview, googlePromotion } = useGoogleReview();

  const onGoogleReviewProgramClick = () => navigate(SCREENS.UPLOAD_GOOGLE_REVIEW_SCREEN, {
    state: { prevPage: location.pathname },
  })

  const items = useMemo<Item[]>(
    () => collect(company?.smart_pricing_deals)
      .map((item) => ({
        data: item,
        render: () => (
          <DiscountCard
            title={item.name}
            description={t(`deals.${DEAL_TYPES.SMART_PRICING_RULE}.description`)}
          />
        )
      }))
      .when(
        hasGooglePromotion && !hasUploadedGoogleReview,
        (collection: Collection<Item>) => collection.prepend({
          data: googlePromotion,
          render: () => (
            <DiscountCard
              title={t(`deals.${DEAL_TYPES.GOOGLE_REVIEW_PROGRAM}.title`, { reward: googlePromotion.reward })}
              description={(
                <div className="flex">
                  <p className="text-tiny text-brand-inkGrey-grey_4 my-auto">{t(`deals.${DEAL_TYPES.GOOGLE_REVIEW_PROGRAM}.description`)}</p>
                  <div className="p-tiny shadow-icon ml-tiny my-auto rounded-full">
                    <GoogleIcon className="h-4 w-4" />
                  </div>
                </div>
              )}
              footer={(
                <button className="flex">
                  <PlusCircleIcon
                    type={TYPE.outline}
                    color={theme.color.companyBrand.primary}
                    className="h-4 w-4 my-auto"
                  />
                  <p className="my-auto ml-tiny text-brand-inkGrey-grey_4 text-mini">{t(`deals.${DEAL_TYPES.GOOGLE_REVIEW_PROGRAM}.buttons.add`)}</p>
                </button>
              )}
              className="cursor-pointer"
              onClick={onGoogleReviewProgramClick}
            />
          )
        }).toArray()
      ),
    [
      company?.smart_pricing_deals,
      hasGooglePromotion,
      hasUploadedGoogleReview,
      googlePromotion,
    ],
  );

  return items.length > 0 && (
    <div className={classnames("relative w-full", className)}>
      <CustomCarousel
        items={items}
        itemsPerSlide={isDesktop ? 3.5 : 1.25}
        slideJump={isDesktop ? 3 : 2}
        itemHeight={120}
        arrowsPosition="top"
        arrowsClassName="lg:mr-medium mr-small"
        itemsClassName="lg:pl-none pl-small"
        title={
          <h5 className="lg:text-medium lg:ml-none ml-small uppercase">
            {t('title')}
          </h5>
        }
        headerClassName="mb-mini"
      />
    </div>
  );
};

export default DealsCarousel;
