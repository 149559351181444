import BellIcon from "../assets/logo/BellIcon";
import CustomButton from "../components-library/buttons/CustomButton";
import IllustrationExplainer from "../components-library/IllustrationExplainer";
import { SCREENS } from "../services/exports/Constants";
import {useTranslation} from "react-i18next";
import useConfig from "../hooks/ui/useConfig";

export default function ErrorScreen() {
  const { t } = useTranslation(null, {keyPrefix: 'Pages:ErrorScreen'});

  const { logo_url } = useConfig();

  // eslint-disable-next-line no-return-assign
  const navigateHome = (): string => (window.location.href = SCREENS.HOME_SCREEN);

  function renderHeader() {
    return (
      <header className={headerContainer}>
        {logo_url && (
          <img src={logo_url} alt="Logo" className={companyLogo} />
        )}
      </header>
    );
  }

  return (
    <>
      {renderHeader()}
      <main className={container} data-test="error-screen">
        <IllustrationExplainer
          illustration={<BellIcon className="h-20 w-20" />}
          title={t('header')}
          description={t('text')}
        />
        <div className={buttonContainer}>
          <CustomButton
            onClick={navigateHome}
            title={t('button')}
          />
        </div>
      </main>
    </>
  );
}
const container =
  "fixed inset-0 flex flex-col h-full w-full bg-background-inkWhite-white_1 justify-center items-center lg:w-1/2 w-full mx-auto px-small";

const headerContainer =
  "border-brand-inkGrey-grey_2 border-b flex flex-row justify-center py-mini bg-background-inkWhite-white_1";

const companyLogo = "h-12";

const buttonContainer = "lg:mt-large mt-medium";
