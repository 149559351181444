import useCashbackProgram from "../../hooks/cashback/useCashbackProgram";
import useAuth from "../../hooks/global/useAuth";
import {useTranslation} from "react-i18next";
import RewardIcon from "../../assets/logo/RewardIcon";
import useAppMethods from "../../hooks/utility/useAppMethods";

export default function CashbackBalance() {
  const { t } = useTranslation(null, { keyPrefix: 'Components:Cashback:CashbackBalance' });

  const { isLoggedIn } = useAuth();
  const { formatCurrency, toggleLoginModal } = useAppMethods();
  const { hasCashbackProgram, cashbackProgram, balance, formattedBalance } = useCashbackProgram();

  const onLoginClick = () => toggleLoginModal({
    openModal: true,
    onCloseRedirect: () => null,
  })

  return hasCashbackProgram && (
    <div className="w-fit">
      {isLoggedIn ? (
        <h5>{t('my_points')}</h5>
      ) : (
        <div className="flex">
        <h5 className="my-auto">{t('loyalty_points')}</h5>
          <p className="my-auto text-tiny ml-mini">{t('already_have_points')}</p>
          <button
            onClick={onLoginClick}
            className="text-companyBrand-primary text-tiny underline my-auto ml-tiny uppercase"
          >{t('log_in')}</button>
        </div>
      )}
      <div className="mt-small rounded-lg shadow py-small px-medium">
        <p className="text-tiny">{t('description', { percentage: cashbackProgram.percentage, amount: formatCurrency(1) })}</p>
        <hr className="my-small" />
        {isLoggedIn ? (
          <h6>{balance} p ({formattedBalance})</h6>
        ) : (
          <h6>500 p = {formatCurrency(5)}</h6>
        )}
      </div>
    </div>
  );
}