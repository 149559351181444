import { useEffect, useImperativeHandle, useState, forwardRef } from "react";
import StarAnimationWhite from "../assets/animations/StarAnimationWhite.json";
import SuccessModal from "./SuccessModal";
import RewardStatus from "../components-library/rewards/RewardStatus";
import { ProductInterface } from "../services/exports/Interfaces";
import collect from "collect.js";
import useStore from "../hooks/global/useStore";
import CompanyManager from "../services/api/CompanyManager";
import useDineInFlow from "../hooks/global/useDineInFlow";
import RewardStatusResource from "../services/resources/RewardStatusResource";
import useLoyaltyProgram from "../hooks/loyalty/useLoyaltyProgram";
import { useTranslation } from "react-i18next";
import ProductsCarousel from "../components-library/carousel/ProductsCarousel";
import useGoogleReviewSuggestedProducts from "../hooks/google-review/useGoogleReviewSuggestedProducts";
import useAppMethods from "../hooks/utility/useAppMethods";

interface Props {
  open: boolean;
  buttonTitle?: string;
  type?: "welcome_to_loyalty_program" | "first_point_earned";
  toggleModal: () => void;
  setLoading?: (loading: boolean) => void;
}

const PointsEarnedModal = forwardRef((props: Props, ref: any): JSX.Element => {
  const { t } = useTranslation(null, { keyPrefix: 'Modals:PointsEarnedModal' });

  const { open, buttonTitle, type, toggleModal, setLoading } = props;

  const { company } = useStore()?.initialData;

  const { formatCurrency } = useAppMethods();
  const { isDineIn } = useDineInFlow();
  const {
    loyalty,
    reward: loyaltyReward,
    rewardThreshold: loyaltyRewardThreshold,
  } = useLoyaltyProgram();
  const { suggestedProducts } = useGoogleReviewSuggestedProducts({
    autoLoad: true,
  });

  const [show, setShow] = useState<boolean>(false);
  const [discount, setDiscount] = useState<number>(0);
  const [freeProducts, setFreeProducts] = useState<ProductInterface[]>([]);

  const oneFreeReward: boolean = freeProducts?.length === 1;
  const multiFreeReward: boolean = freeProducts?.length > 1;
  const mostExpensiveFreeProduct = () => collect(freeProducts)?.first();
  const hasReward: boolean = !!new RewardStatusResource({
    rewards: company?.rewards,
    rewardBalance: company?.reward_balance,
  }).getLastActiveReward();

  useImperativeHandle(ref, () => ({
    show,
  }));

  useEffect(() => {
    if (open) {
      onDidFocus();
    } else {
      onDidBlur();
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      setLoading && setLoading(!show);
    } else {
      setLoading && setLoading(false);
    }
  }, [show, open]);

  function onDidFocus(): void {
    findUnlockedReward();
  }

  function onDidBlur(): void {
    setShow(false);
  }

  async function findUnlockedReward(): Promise<void> {
    const reward = collect(company?.rewards)?.last();

    if (reward?.discount === 100) {
      return getFreeMenuItems();
    }

    setDiscount(reward?.discount);
    setTimeout(() => setShow(true), 1000);
  }

  async function getFreeMenuItems() {
    const { response, success } = await CompanyManager.getFreeMenuItems(
      company?.id
    );

    setTimeout(() => setShow(true), 500);
    if (success) {
      setFreeProducts(response?.data?.data);
    }
  }

  const renderFirstTimePointsEarned = (): JSX.Element => {
    function getTitle(): string {
      // 1 Free item
      if (oneFreeReward) {
        return t("points_earned.titles.free_menu_item", {
          product: mostExpensiveFreeProduct()?.name,
        });
      }

      // >=2 Free items
      if (multiFreeReward) {
        return t("points_earned.titles.multiple_free_menu_item", {
          product: mostExpensiveFreeProduct()?.name,
        });
      }

      // No free item/ % discount
      return t("points_earned.titles.no_free_menu_item", {
        discount,
      });
    }

    return (
      <div>
        <h6 className="mb-small">{getTitle()}</h6>
        <div className="bg-background-inkWhite-white_0 p-small rounded-lg shadow">
          <RewardStatus
            rewards={company?.rewards}
            points={company?.reward_balance ?? 0}
            hideTitle
            hideDescription
          />
        </div>
      </div>
    );
  };

  const renderAnimationDescription = (): JSX.Element => {
    function getTitle(): string {
      switch (type) {
        case "welcome_to_loyalty_program":
          return t("animation_description.order_here_earn_rewards");
        default:
          return t("animation_description.order_here_earn_points");
      }
    }

    function getDescription(): string | undefined {
      if ((isDineIn && !hasReward) || type === "welcome_to_loyalty_program") {
        if (loyalty?.is_per_item) {
          return t("animation_description.menu_item_reward_threshold", {
            threshold: formatCurrency(loyaltyRewardThreshold),
            count: loyaltyReward,
          });
        }

        return t("animation_description.order_reward_threshold", {
          threshold: formatCurrency(loyaltyRewardThreshold),
          count: loyaltyReward,
        });
      }
    }

    return (
      <div>
        <h4 className=" text-brand-text-light text-center">{getTitle()}</h4>
        {getDescription() && (
          <p className="text-center text-brand-text-light">
            {getDescription()}
          </p>
        )}
      </div>
    );
  };

  return (
    <SuccessModal
      open={show}
      animationDescription={renderAnimationDescription()}
      lottieProps={{
        animationData: StarAnimationWhite,
      }}
      buttonTitle={buttonTitle ?? t("button.okay")}
      onConfirm={toggleModal}
    >
      {renderFirstTimePointsEarned()}
      {suggestedProducts?.length > 0 && (
        <div className="mt-small">
          <ProductsCarousel products={suggestedProducts} />
        </div>
      )}
    </SuccessModal>
  );
});

export default PointsEarnedModal;
