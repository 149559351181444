import {useSelector} from "react-redux";
import {StoreInterface} from "../../store/types";

export enum SESSION_STORAGE_KEYS {
  order_method_info_status = 'order_method_info_status',
}

export default function useSessionStorage() {
  const { company } = useSelector((state: StoreInterface) => state.initialData);

  const composeKey = (key: SESSION_STORAGE_KEYS) => `company-${company?.id}-${key}`;

  const getItem = (key: SESSION_STORAGE_KEYS, defaultValue?: string): null|string => sessionStorage.getItem(composeKey(key)) ?? defaultValue;
  const setItem = (key: SESSION_STORAGE_KEYS, value: string) => sessionStorage.setItem(composeKey(key), value);

  return {
    getItem,
    setItem,
  };
}
