import collect from "collect.js";
import PlusIcon from "../../../assets/logo/PlusIcon";
import useTheme from "../../../hooks/ui/useTheme";
import HelperMethods from "../../../services/exports/HelperMethods";
import { ORDER_PRODUCT_HEIGHT } from "../../../services/exports/Constants";
import {LineItemInterface, ProductComboInterface} from "../../../services/exports/Interfaces";
import useMenu from "../../../hooks/menu/useMenu";
import useAppMethods from "../../../hooks/utility/useAppMethods";

interface Props {
  product: LineItemInterface;
  toggleProduct: () => void;
}

const MultiOrderProduct = (props: Props): JSX.Element => {
  const { product, toggleProduct } = props;

  const theme = useTheme();
  const { compileProduct } = useMenu();
  const { formatCurrency } = useAppMethods();

  const data = compileProduct(product.product_id);

  function getItemPrice(item: LineItemInterface): number {
    if (item.children?.length > 0) {
      return collect(product.children)
        .reduce(
          (carry, child) => carry + getItemPrice(child),
          0
        ) - ((data as ProductComboInterface)?.combo_discount ?? 0)
    }

    return HelperMethods.roundNumberWithPrecision(
      item?.original_price / item?.quantity - +(collect(item?.modifiers).where('is_available', false).sum('original_price'))
    );
  }

  return (
    <div
      className="w-full border bg-background-inkWhite-white_1 rounded-2xl border-brand-inkGrey-grey_2 flex flex-col cursor-pointer overflow-hidden p-xsmall"
      onClick={toggleProduct}
      style={{ height: ORDER_PRODUCT_HEIGHT }}
    >
      <div className="flex justify-between">
        <h6
          className="text-brand-text-default line-clamp-1 break-words mr-small"
        >
          {product?.name}
        </h6>
        <div
          className="h-[22px] w-[22px] rounded border border-brand-inkGrey-grey_2 flex flex-col justify-center items-center shadow-icon flex-none"
        >
          <PlusIcon
            className="h-2.5 w-2.5"
            color={theme?.color?.companyBrand?.primary}
          />
        </div>
      </div>
      <div className="flex flex-col flex-1">
        <div className="flex flex-col flex-1">
          {product?.modifiers?.length > 0 && (
            <p className="text-brand-text-grey line-clamp-1 break-words mini mt-[6px]">
              {collect(product?.modifiers)?.implode('name', ' · ')}
            </p>
          )}
          {product?.note && (
            <p className="text-brand-text-grey italic mini mt-[6px] line-clamp-1 break-words">
              {product?.note}
            </p>
          )}
        </div>
        <p className="text-brand-text-grey">{formatCurrency(getItemPrice(product))}</p>
      </div>
    </div>
  );
};

export default MultiOrderProduct;
