import BackendApiClient from "../api/BackendApiClient";

const TOKEN_KEY = "AMIGO_FRONTEND_WEB_API_AUTH_TOKEN";

class AuthorizationTokenStorage {
  getToken = (): null | string => localStorage.getItem(TOKEN_KEY);

  saveToken = async (token: string): Promise<boolean> =>
    new Promise((resolve) => {
      localStorage.setItem(TOKEN_KEY, token);
      resolve(true);
    });

  deleteToken = (): void => {
    localStorage.removeItem(TOKEN_KEY);
    BackendApiClient.deleteAuthToken();
  };
}

export default new AuthorizationTokenStorage();
