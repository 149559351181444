export default {
  title: 'Info',
  sections: {
    work_schedule: 'Öffnungszeiten: Online-Bestellung',
    delivery_schedule: 'Lieferzeiten',
    delivery_costs: 'Lieferkosten',
    website: 'Website',
  },
  labels: {
    delivery_fees: 'Min. - {{threshold}}, Liefergebühr - {{fee}}',
  },
};
