import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";
import "./services/localization/Localization";
import "./styles/index.css";
import "react-multi-carousel/lib/styles.css";
import "react-mobile-style-picker/dist/index.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

declare global {
  interface Window {
    Cypress: any;
    store: any;
    gtag: any;
  }
}

if (!import.meta.env.DEV) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: import.meta.env.PROD ? 0.1 : 0,
    replaysOnErrorSampleRate: import.meta.env.PROD ? 0.25 : 0,
    normalizeDepth: 10,
    environment: import.meta.env.MODE,
    attachStacktrace: true,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

if (window.Cypress) {
  window.store = store;
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
