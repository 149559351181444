import {useState, useEffect} from "react";
import { useStripe } from "@stripe/react-stripe-js";
import * as stripeJs from "@stripe/stripe-js";
import { PaymentRequestHookInterface } from "../../services/exports/Interfaces";

export default function usePaymentRequest({
  options,
  onPaymentMethod,
  onCancel,
}: {
  options: stripeJs.PaymentRequestOptions;
  onPaymentMethod?: (event: stripeJs.PaymentRequestPaymentMethodEvent) => any;
  onCancel?: () => void;
}): PaymentRequestHookInterface {
  const stripe = useStripe();

  const [initiated, setInitiated] = useState<boolean>(false);

  const [paymentRequest, setPaymentRequest] =
    useState<stripeJs.PaymentRequest | null>(null);

  const [canMakePayment, setCanMakePayment] = useState<boolean>(false);

  const [result, setResult] = useState<stripeJs.CanMakePaymentResult | null>(
    null
  );

  useEffect(() => {
    initPaymentRequest();
  }, [stripe, options, paymentRequest]);

  const initPaymentRequest = () => {
    if (! stripe) {
      return;
    }

    if (paymentRequest && !paymentRequest.isShowing()) {
      paymentRequest.update(options);

      return setPaymentRequest(paymentRequest);
    }

    return setPaymentRequest(stripe.paymentRequest(options));
  }

  useEffect(() => {
    let subscribed = true;
    if (paymentRequest) {
      paymentRequest.canMakePayment().then((res) => {
        setInitiated(true);
        if (res && subscribed) {
          setCanMakePayment(true);
          setResult(res);
        } else {
          setResult({
            applePay: false,
            googlePay: false,
          });
        }
      });
    }

    return () => {
      subscribed = false;
    };
  }, [paymentRequest]);

  useEffect(() => {
    if (paymentRequest) {
      onPaymentMethod && paymentRequest.on("paymentmethod", onPaymentMethod);
      onCancel && paymentRequest.on("cancel", onCancel);
    }

    return () => {
      if (paymentRequest) {
        paymentRequest.off("paymentmethod", onPaymentMethod);
        paymentRequest.off("cancel", onCancel);
      }
    };
  }, [paymentRequest, onPaymentMethod, onCancel]);

  return {
    paymentRequest: canMakePayment ? paymentRequest : null,
    result,
    initiated,
  };
}
