import useTheme from "../../hooks/ui/useTheme";

interface Props {
  className?: string;
  style?: Object;
  color?: string;
}

export default function ServeTableIllustration(props: Props) {
  const { className, color, style } = props;

  const theme = useTheme();

  return (
    <svg
      className={className ?? "lg:h-40 lg:w-40 md:w-36 md:h-36 w-24 h-24"}
      viewBox="0 0 672 416"
      fill="none"
      style={style}
    >
      <path
        d="M336 416C521.568 416 672 410.627 672 404C672 397.373 521.568 392 336 392C150.432 392 0 397.373 0 404C0 410.627 150.432 416 336 416Z"
        fill="url(#paint0_linear_1634_1325)"
      />
      <path
        d="M392.913 63.2673C392.133 66.1763 393.86 69.1663 396.769 69.9458C399.678 70.7253 402.668 68.9989 403.447 66.09C404.227 63.181 402.5 60.1909 399.591 59.4114C396.682 58.632 393.692 60.3583 392.913 63.2673Z"
        fill="#E1E4E5"
      />
      <path
        d="M439.899 187.359C439.387 189.27 440.521 191.235 442.432 191.747C444.344 192.259 446.309 191.125 446.821 189.213C447.333 187.302 446.199 185.337 444.287 184.825C442.376 184.313 440.411 185.447 439.899 187.359Z"
        fill="#E1E4E5"
      />
      <path
        d="M441.412 274.268C440.632 277.177 442.359 280.167 445.268 280.946C448.177 281.726 451.167 279.999 451.946 277.09C452.726 274.181 450.999 271.191 448.09 270.412C445.181 269.632 442.191 271.359 441.412 274.268Z"
        fill="#E1E4E5"
      />
      <path
        d="M484.412 242.268C483.632 245.177 485.359 248.167 488.268 248.946C491.177 249.726 494.167 247.999 494.946 245.09C495.726 242.181 493.999 239.191 491.09 238.412C488.181 237.632 485.191 239.359 484.412 242.268Z"
        fill="#E1E4E5"
      />
      <path
        d="M468.66 340.867L454.589 346.368L455.481 331.287L469.548 325.79L468.66 340.867V340.867Z"
        stroke="#E1E4E5"
        strokeWidth="4.267"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M502.249 179.887L484.041 198.095C483.378 198.758 483.378 199.833 484.041 200.497C484.705 201.16 485.78 201.16 486.443 200.497L504.651 182.289C505.314 181.625 505.314 180.55 504.651 179.887C503.988 179.224 502.913 179.224 502.249 179.887Z"
        fill="#E1E4E5"
      />
      <path
        d="M500.467 192.923L495.216 198.174C494.553 198.837 494.553 199.913 495.216 200.576C495.879 201.239 496.955 201.239 497.618 200.576L502.869 195.325C503.532 194.662 503.532 193.586 502.869 192.923C502.206 192.26 501.13 192.26 500.467 192.923Z"
        fill="#E1E4E5"
      />
      <path
        d="M541.933 156H542.064C542.838 166.517 551 166.679 551 166.679C551 166.679 542 166.848 542 179C542 166.848 533 166.679 533 166.679C533 166.679 541.158 166.517 541.933 156Z"
        fill="#E1E4E5"
      />
      <path
        d="M469.845 227.504C470.046 229.062 470.731 230.254 471.38 230.159C472.018 230.077 472.384 228.742 472.183 227.196C471.982 225.637 471.297 224.444 470.648 224.539C469.998 224.621 469.644 225.957 469.845 227.504Z"
        fill="#B55E63"
      />
      <path
        d="M430.406 352.033C430.631 352.033 430.855 351.998 431.079 351.915L510.313 323.351C510.797 323.16 511.189 322.787 511.404 322.313C511.618 321.838 511.64 321.298 511.463 320.808C511.286 320.317 510.925 319.915 510.457 319.687C509.989 319.458 509.45 319.421 508.955 319.584L429.721 348.148C429.277 348.308 428.904 348.619 428.668 349.027C428.432 349.435 428.347 349.914 428.429 350.378C428.511 350.843 428.754 351.263 429.116 351.566C429.477 351.869 429.934 352.034 430.406 352.033Z"
        fill={color ?? theme.color.brand.inkGrey.grey_3}
      />
      <path
        d="M512.921 352.034C513.391 352.032 513.847 351.865 514.206 351.562C514.566 351.258 514.807 350.838 514.888 350.374C514.968 349.91 514.882 349.433 514.646 349.026C514.41 348.619 514.038 348.309 513.595 348.149L433.417 319.112C432.922 318.947 432.382 318.983 431.912 319.21C431.442 319.437 431.079 319.839 430.901 320.329C430.723 320.82 430.743 321.361 430.957 321.837C431.17 322.313 431.562 322.687 432.047 322.879L512.225 351.915C512.473 351.998 512.697 352.034 512.921 352.034Z"
        fill={color ?? theme.color.brand.inkGrey.grey_3}
      />
      <path
        d="M423.357 403.08C424.184 403.077 424.983 402.776 425.605 402.231C426.228 401.687 426.633 400.935 426.746 400.116L441.057 293.264H500.937L515.024 400.116C515.081 400.561 515.227 400.991 515.451 401.38C515.676 401.769 515.975 402.11 516.331 402.384C516.688 402.657 517.095 402.857 517.529 402.972C517.964 403.088 518.416 403.117 518.862 403.057C519.307 402.998 519.737 402.853 520.126 402.628C520.515 402.404 520.855 402.105 521.129 401.748C521.402 401.392 521.602 400.985 521.717 400.55C521.833 400.116 521.861 399.664 521.802 399.219L507.325 289.391C507.216 288.568 506.813 287.813 506.19 287.265C505.566 286.718 504.765 286.416 503.936 286.415H438.058C437.229 286.413 436.429 286.713 435.805 287.259C435.182 287.804 434.778 288.557 434.669 289.379L419.956 399.207C419.836 400.107 420.079 401.018 420.63 401.739C421.181 402.461 421.996 402.935 422.896 403.057C423.05 403.08 423.203 403.08 423.357 403.08Z"
        fill={color ?? theme.color.brand.inkGrey.grey_3}
      />
      <path
        d="M457.14 289.839H499.921C513.477 289.839 527.99 282.424 530.068 269.021C532.691 252.096 533.593 234.95 532.76 217.844C531.886 199.837 523.29 183.093 509.132 171.934C509.132 171.934 500.583 209.177 511.328 260.59C507.243 280.133 457.14 289.839 457.14 289.839Z"
        fill={color ?? theme.color.brand.inkGrey.grey_3}
      />
      <path
        d="M175.867 407.027H226.89C227.268 406.342 227.481 405.362 227.563 404.193H175.453C175.111 405.929 175.867 407.027 175.867 407.027Z"
        fill="#6A4B27"
      />
      <path
        d="M209.13 407.096H260.154C260.532 406.411 260.744 405.431 260.827 404.262H208.717C208.387 405.986 209.13 407.096 209.13 407.096Z"
        fill="#6A4B27"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M416.329 358.715C416.329 358.715 423.162 372.515 417.56 376.864C411.951 381.213 397.133 389.365 397.133 389.365L436.39 386.897L427.664 356.799L416.329 358.715Z"
        fill="#F8AE9D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M432.501 371.201C431.266 371.791 433.297 377.336 428.951 376.946C424.605 376.558 421.877 370.826 418.672 371.198C415.467 371.57 413.433 377.342 407.331 381.362C401.23 385.383 392.184 390.178 392.79 394.22C393.397 398.263 407.632 399.345 414.843 398.007C422.055 396.67 425.421 393.771 430.1 394.002C434.779 394.233 437.719 393.66 439.186 390.656C440.652 387.651 433.927 370.521 432.501 371.201ZM475.467 237.08C463.582 237.611 397.433 252.241 385.415 254.727C373.397 257.213 363.237 270.663 372.401 286.237C382.694 303.72 409.252 353.371 415.7 364.564C418.891 370.104 434.992 366.998 431.21 357.699C424.16 340.366 420.886 296.822 402.911 280.945C402.911 280.945 463.36 291.466 489.472 279.415C508.449 270.655 504.156 235.797 475.467 237.08Z"
        fill="#212223"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M392.558 162.893C392.558 162.893 392.693 155.828 389.31 150.774C385.927 145.72 385.793 140.352 383.233 140.528C380.673 140.703 383.208 153.181 383.208 153.181C383.208 153.181 379.959 151.94 377.626 150.37C375.292 148.801 368.216 141.062 366.563 142.434C363.759 144.77 368.275 157.95 370.734 160.585C373.202 163.221 385.38 170.586 385.38 170.586L421.244 206.716L426.607 187.536L392.558 162.893Z"
        fill="#F8AE9D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M464.292 159.704C458.764 172.527 448.641 196.083 444.468 203.111C439.238 211.912 430.991 215.79 421.335 209.26C419.314 207.894 413.513 203.115 413.513 203.115C414.371 194.75 417.496 187.758 422.883 182.137L426.542 185.164C426.542 185.164 436.917 158.566 445.264 143.159C452.492 129.829 472.708 140.209 464.292 159.704ZM488.187 109.929V121.276L496.52 114.567C496.52 114.567 499.081 103.696 501.164 95.6222C502.47 90.5582 505.652 73.4232 492.841 74.2252C482.346 55.7252 451.781 67.1752 451.537 89.7772C451.245 116.962 451.484 127.546 451.484 127.546C468.767 128.538 478.496 126.039 488.187 109.929Z"
        fill="#212223"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M496.701 131.533C496.701 131.533 488.949 129.713 488.681 117.013C488.412 104.312 488.6 99.9769 488.6 99.9769L471.527 99.6689C471.527 99.6689 471.341 113.823 471.338 118.943C471.331 131.391 462.177 132.412 462.177 132.412C462.177 132.412 463.741 142.683 479.212 143.552C491.291 144.233 496.701 131.533 496.701 131.533Z"
        fill="#F8AE9D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M492.581 93.6351C500.197 93.3401 498.955 104.49 491.043 104.475C491.043 104.475 486.447 120.314 470.493 120.314C457.535 120.314 456.693 101.853 456.575 98.1702C456.449 94.2442 456.433 81.3441 456.433 81.3441L492.877 81.3271L492.581 93.6351Z"
        fill="#F8AE9D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M493.169 93.5606C490.012 94.0816 490.395 96.1716 489.537 95.9886C485.179 95.0616 467.537 96.2846 465.266 86.5836C462.44 92.6436 461.749 94.1336 455.525 95.7606V78.0006L494.889 74.2246C494.889 74.2246 494.278 93.3766 493.169 93.5606Z"
        fill="#212223"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M494.967 130.233C485.24 136.19 473.995 138.375 463.526 131.905C446.443 165.59 442.185 205.781 446.294 250.203C471.206 260.065 492.142 258.125 509.967 246.853L494.967 130.233Z"
        fill="#FFFAF3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M373.744 359.269C373.744 359.269 373.95 375.512 360.566 381.585C347.19 387.657 337.66 387.291 338.873 391.987C340.086 396.681 374.902 396.491 378.153 392.82C380.925 389.696 386.951 360.612 388.61 362.836L373.744 359.269Z"
        fill="#F8AE9D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M383.464 382.208C381.413 380.732 379.688 389.243 374.117 386.876C368.545 384.51 372.939 376.687 369.433 375.244C365.926 373.801 359.156 381.559 350.163 383.444C341.171 385.328 333.869 386.978 331.794 390.478C330.007 393.487 331.794 394.448 331.794 394.448C331.794 394.448 340.451 398.771 345.831 399.723C353.645 401.105 376.23 402.026 378.705 399.505C380.225 397.959 384.49 382.947 383.464 382.208ZM512.704 232.06C512.704 232.06 529.982 268.55 505.7 281.081C482.217 293.198 411.918 278.866 411.918 278.866C411.918 278.866 413.174 288.98 411.918 298C408.637 321.559 393.034 360.809 389.732 365.741C385.167 372.561 371.26 367.418 370.998 361.739C370.736 356.06 381.482 283.531 383.116 269.7C384.742 255.869 389.874 253.86 404.864 250.079C408.308 249.21 449.034 239.089 452.765 239.384C484.821 241.919 512.704 232.06 512.704 232.06Z"
        fill="#212223"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M494.541 130.26C478.431 183.033 468.769 257.852 519.084 244.836C492.356 195.626 528.69 173.478 520.455 148.046C517.639 139.35 494.751 129.577 494.541 130.26ZM464.378 131.535C458.23 146.955 455.275 159.053 452.518 181.686C450.218 200.536 451.369 239.435 451.369 239.435L443.772 240.929C443.772 240.929 438.341 180.832 440.156 173.094C443.204 160.11 445.268 152.523 449.292 140.653C451.508 134.138 464.378 131.535 464.378 131.535Z"
        fill="#212223"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M491.16 190.172C491.16 190.172 481.24 180.24 477.805 179.087C474.37 177.935 455.552 176.55 455.68 179.155C455.824 181.945 467.933 181.784 469.052 183.157C470.172 184.529 471.729 195.214 475.961 196.267C480.186 197.321 488.942 200.439 488.942 200.439L527.919 220.578L523.766 200.441L491.16 190.172Z"
        fill="#F8AE9D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M521.014 148.119C532.837 164.805 542.931 190.222 546.806 200.132C550.681 210.041 546.772 224.988 529.734 222.053C527.706 221.704 524.406 220.42 524.406 220.42C518.141 211.833 517.043 204.46 519.381 197.895L521.584 198.826C521.584 198.826 504.65 168.81 500.515 160.764C493.609 147.326 510.6 133.417 521.014 148.119Z"
        fill="#212223"
      />
      <path
        d="M287 230.554V235.853C287 238.908 288.875 241.373 291.205 241.373H583.446C585.765 241.373 587.652 238.908 587.652 235.853V230.554C587.652 227.513 585.777 225.033 583.446 225.033H291.205C288.887 225.048 287 227.513 287 230.554Z"
        fill="#E1E4E5"
      />
      <path
        d="M301.943 248.908H564.996C566.176 248.908 567.133 248.129 567.133 247.161V237.962H299.818V247.161C299.818 248.129 300.774 248.908 301.943 248.908ZM579.213 404.485L541.946 249.998H555.041L592.296 404.485H579.213ZM323.942 404.485L361.209 249.998H348.125L310.859 404.485H323.942Z"
        fill="#E1E4E5"
      />
      <path
        d="M244.358 68.025C248.729 81.6674 255.489 94.4249 264.322 105.703L229.248 130.184L232.704 139.17C232.704 139.17 279.127 124.896 284.319 118.482C288.144 113.74 267.682 80.2415 267.682 80.2415C267.682 80.2415 256.783 61.4184 244.358 68.025Z"
        fill="white"
      />
      <path
        opacity="0.7"
        d="M284.252 118.482C279.06 124.912 232.638 139.17 232.638 139.17L229.182 130.184L264.32 105.703C263.211 104.321 262.15 102.922 261.105 101.524C259.019 98.6407 257.082 95.6523 255.302 92.5705C250.759 84.8199 247.093 76.5873 244.372 68.025C256.797 61.4184 267.631 80.2415 267.631 80.2415C267.631 80.2415 288.078 113.74 284.252 118.482Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M261.106 101.604C259.019 98.7207 257.083 95.7323 255.303 92.6505C255.553 89.8293 256.576 87.1317 258.26 84.8545C259.813 87.3221 260.863 90.0717 261.351 92.9458C261.84 95.8199 261.756 98.7621 261.106 101.604Z"
        fill="black"
      />
      <path
        d="M161.445 54.3296L150.322 49.2662L143.988 41.3094L152.588 39.2197L157.121 42.2578C157.121 42.2578 165.448 42.9329 167.376 49.3466L161.445 54.3296Z"
        fill="#FDA57D"
      />
      <path
        d="M202.419 7.37639C202.419 7.37639 206.003 1.18776 216.291 0.126852C226.579 -0.934054 240.21 4.56337 242.299 20.2519C244.389 35.9405 248.825 44.5563 248.825 44.5563C248.825 44.5563 215.712 53.2204 206.454 50.1663C197.195 47.1121 182.712 11.8933 202.419 7.37639Z"
        fill="#263238"
      />
      <path
        d="M265.928 283.244C260.993 292.342 256.01 302.132 251.22 311.937C244.919 324.877 238.972 337.849 233.892 349.454C228.539 361.687 224.247 372.376 221.467 379.803C218.895 380.092 211.131 375.463 208.896 371.267C212.88 347.389 218.931 323.902 226.98 301.071C231.995 287.359 238.136 273.873 245.514 262.958C215.342 177.154 222.736 145.005 222.736 145.005L257.682 143.397C257.682 143.397 262.971 175.353 267.198 208.129C269.4 225.312 271.313 242.721 272.02 255.87V255.998C272.555 265.469 270.445 274.903 265.928 283.244Z"
        fill="#212527"
      />
      <path
        opacity="0.2"
        d="M237.108 237.4C232.688 222.821 229.505 210.186 227.238 199.352C229.489 190.881 232.655 183.632 236.883 179.275C236.995 179.275 243.168 202.873 237.108 237.4Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M233.876 349.422C228.524 361.654 224.232 372.344 221.451 379.77C218.879 380.059 211.115 375.43 208.881 371.235C212.864 347.356 218.915 323.869 226.964 301.038C229.52 315.248 232.382 333.203 233.876 349.422Z"
        fill="black"
      />
      <path
        d="M214.201 397.371C208.479 389.768 207.772 388.675 204.766 384.303C202.146 380.541 201.02 380.011 198.882 377.021C197.082 374.53 199.493 372.858 202.772 371.572C204.38 370.929 207.723 369.354 210.311 368.212L219.506 378.741C217.706 382.567 216.098 389.302 218.75 394.028C221.403 398.754 216.484 400.409 214.201 397.371Z"
        fill={color ?? theme.color.brand.inkGrey.grey_3}
      />
      <path
        opacity="0.3"
        d="M214.201 397.371C208.479 389.768 207.772 388.675 204.766 384.303C202.146 380.541 201.02 380.011 198.882 377.021C197.082 374.53 199.493 372.858 202.772 371.572C204.38 370.929 207.723 369.354 210.311 368.212L219.506 378.741C217.706 382.567 216.098 389.302 218.75 394.028C221.403 398.754 216.484 400.409 214.201 397.371Z"
        fill="white"
      />
      <path
        d="M234.262 399.992C224.746 399.992 223.492 399.848 218.188 399.671C213.606 399.494 212.513 400.073 208.832 399.992C205.762 399.992 205.875 396.986 206.823 393.563C207.305 391.859 208.029 388.274 208.688 385.525L222.528 384.513C224.489 388.258 228.957 393.595 234.278 394.318C239.438 395.041 238.055 399.992 234.262 399.992Z"
        fill={color ?? theme.color.brand.inkGrey.grey_3}
      />
      <path
        opacity="0.4"
        d="M234.262 399.992C224.746 399.992 223.492 399.848 218.188 399.671C213.606 399.494 212.513 400.073 208.832 399.992C205.762 399.992 205.875 396.986 206.823 393.563C207.305 391.859 208.029 388.274 208.688 385.525L222.528 384.513C224.489 388.258 228.957 393.595 234.278 394.318C239.438 395.041 238.055 399.992 234.262 399.992Z"
        fill="white"
      />
      <path
        d="M244.358 139.523C244.358 139.523 234.183 218.288 228.284 256.127C222.16 295.911 221.372 359.758 222.915 385.782C220.681 387.068 212.547 388.643 208.159 387.261C208.159 387.261 187.423 306.793 201.199 260.049C188.645 167.991 206.809 145.455 206.809 145.455L244.358 139.523Z"
        fill="#212223"
      />
      <path
        d="M262.616 158.041C259.84 159.11 256.764 159.099 253.996 158.009C251.228 156.92 248.969 154.832 247.667 152.158L244.355 145.326L241.414 149.586C239.012 153.077 235.733 155.873 231.906 157.693C228.08 159.514 223.841 160.293 219.617 159.954L198.126 158.266C198.126 158.266 204.893 136.228 206.163 132.547C205.102 120.604 201.839 105.671 194.477 82.7164C192.082 75.2419 196.084 66.8028 196.084 66.8028C196.084 66.8028 206.098 66.5456 207.111 66.4331C208.124 66.3206 209.362 66.192 210.503 66.0956C218.243 65.2786 226.033 65.0154 233.811 65.3079C235.74 65.4204 237.717 65.5812 239.662 65.7419C240.289 65.7419 247.345 65.8705 247.956 65.9348C247.956 65.9348 257.697 77.0904 258.292 86.1242C259.019 93.0645 259.287 100.045 259.096 107.021C259.096 115.219 258.806 123.304 258.26 134.829C259.883 140.922 262.616 158.041 262.616 158.041Z"
        fill={color ?? theme.color.brand.inkGrey.grey_3}
      />
      <path
        opacity="0.7"
        d="M240.965 89.9503C230.74 80.7276 219.399 72.8218 207.209 66.4175C208.318 66.2889 209.459 66.1764 210.601 66.0799C218.341 65.2629 226.13 64.9997 233.908 65.2923C235.837 65.4048 237.815 65.5655 239.76 65.7263C244.582 76.7854 240.965 89.9503 240.965 89.9503Z"
        fill="white"
      />
      <path
        d="M211.275 39.7822C213.574 47.2086 216.532 60.8718 211.822 66.0477C211.822 66.0477 218.895 64.7296 234.326 73.5383C238.473 67.1086 235.162 65.244 235.162 65.244C226.578 63.5401 226.128 57.3354 226.948 51.4844L211.275 39.7822Z"
        fill="#FDA57D"
      />
      <path
        opacity="0.2"
        d="M217.705 44.5557L226.963 51.4676C226.745 52.8441 226.654 54.2377 226.69 55.6309C223.475 55.2772 218.653 51.9177 217.978 48.6064C217.638 47.2845 217.545 45.9112 217.705 44.5557Z"
        fill="black"
      />
      <path
        d="M210.602 66.0801L213.543 59.6504C213.543 59.6504 223.927 60.7917 226.145 62.3187C227.697 63.5151 228.998 65.0065 229.971 66.707C229.587 64.9315 229.077 63.1857 228.444 61.4829C227.962 60.7274 229.183 59.6504 229.183 59.6504L232.398 63.8458L236.449 65.4532C236.449 65.4532 237.912 71.6418 235.42 75.6765C232.929 79.7112 210.602 66.0801 210.602 66.0801Z"
        fill="white"
      />
      <path
        opacity="0.7"
        d="M210.602 66.0801L213.543 59.6504C213.543 59.6504 223.927 60.7917 226.145 62.3187C227.697 63.5151 228.998 65.0065 229.971 66.707C229.587 64.9315 229.077 63.1857 228.444 61.4829C227.962 60.7274 229.183 59.6504 229.183 59.6504L232.398 63.8458L236.449 65.4532C236.449 65.4532 237.912 71.6418 235.42 75.6765C232.929 79.7112 210.602 66.0801 210.602 66.0801Z"
        fill="white"
      />
      <path
        d="M224.682 70.79C224.726 72.0147 224.965 73.2247 225.389 74.3745C226.209 75.2908 228.765 73.2172 230.356 71.7544C231.144 71.0471 231.706 70.4685 231.706 70.4685C232.899 71.1648 234.134 71.7875 235.403 72.3331C236.432 72.751 237.364 72.9761 237.654 72.6385C238.457 71.7383 236.496 68.2824 236.496 68.2824C236.496 68.2824 236.77 64.7782 235.484 63.4601C234.487 62.4956 232.462 65.0675 231.513 66.5142L231.031 67.2697L229.906 66.9161C227.848 66.2892 223.637 65.0675 222.849 65.389C222.062 65.7105 222.303 68.4431 224.682 70.79Z"
        fill={color ?? theme.color.brand.inkGrey.grey_3}
      />
      <path
        opacity="0.2"
        d="M227.816 69.6165C227.671 70.4523 230.484 69.5039 230.484 69.5039C230.685 70.2418 230.646 71.0243 230.372 71.7383C231.159 71.031 231.722 70.4523 231.722 70.4523C232.915 71.1486 234.15 71.7713 235.419 72.3169C234.574 71.8631 233.777 71.3245 233.04 70.7095C233.045 70.3008 232.985 69.894 232.863 69.5039C232.863 69.5039 234.889 70.0505 235.033 69.1021C235.178 68.1537 232.912 68.877 232.912 68.877C232.912 68.877 232.799 67.0285 231.609 66.498L231.127 67.2535L230.002 66.8999C230.277 67.5642 230.435 68.2713 230.468 68.9896C230.468 68.9896 227.977 68.7645 227.816 69.6165Z"
        fill="black"
      />
      <path
        d="M230.082 66.9798C230.082 66.9798 231.336 66.369 231.689 66.6262C232.151 67.2642 232.479 67.989 232.653 68.7569C232.827 69.5249 232.844 70.3202 232.702 71.0949C232.457 71.2891 232.161 71.4077 231.85 71.4362C231.539 71.4648 231.226 71.402 230.95 71.2556C230.95 71.2556 231.272 67.8478 230.082 66.9798Z"
        fill={color ?? theme.color.brand.inkGrey.grey_3}
      />
      <path
        d="M204.428 24.9453C207.112 36.985 207.996 42.1127 215.117 47.6905C225.807 56.0653 239.92 50.9054 240.836 38.5764C241.688 27.4208 237.139 9.64258 224.537 6.04193C221.855 5.19042 218.995 5.07023 216.252 5.69381C213.509 6.31739 210.981 7.66212 208.932 9.58904C206.882 11.516 205.384 13.9552 204.592 16.6548C203.8 19.3545 203.743 22.2165 204.428 24.9453Z"
        fill="#FDA57D"
      />
      <path
        d="M233.232 22.8404L235.515 21.876C235.515 21.876 234.631 23.8692 233.232 22.8404Z"
        fill="#263238"
      />
      <path
        d="M216.95 20.2518C217.027 20.2519 217.103 20.2345 217.173 20.2012C217.242 20.1678 217.303 20.1192 217.352 20.0589C217.816 19.4934 218.412 19.0508 219.087 18.7705C219.763 18.4901 220.497 18.3806 221.226 18.4515C221.301 18.4652 221.379 18.4629 221.453 18.4447C221.528 18.4265 221.598 18.3928 221.658 18.3458C221.719 18.2988 221.769 18.2395 221.805 18.1719C221.841 18.1042 221.863 18.0297 221.869 17.9532C221.883 17.7924 221.836 17.6321 221.738 17.504C221.64 17.376 221.497 17.2896 221.338 17.262C220.415 17.1554 219.481 17.2837 218.621 17.635C217.761 17.9863 217.004 18.5491 216.419 19.2713C216.32 19.3975 216.275 19.5576 216.293 19.717C216.311 19.8764 216.391 20.0223 216.516 20.1232C216.639 20.2186 216.794 20.2645 216.95 20.2518Z"
        fill="#263238"
      />
      <path
        d="M236.691 17.3908C236.764 17.4006 236.839 17.3956 236.911 17.3763C236.983 17.357 237.05 17.3237 237.109 17.2783C237.219 17.1642 237.282 17.0114 237.282 16.8523C237.282 16.6932 237.219 16.5404 237.109 16.4263C236.514 15.655 235.749 15.0317 234.873 14.6051C233.998 14.1785 233.035 13.96 232.061 13.967C231.986 13.9748 231.912 13.9979 231.846 14.0348C231.779 14.0718 231.721 14.1218 231.675 14.1819C231.628 14.242 231.594 14.3109 231.575 14.3845C231.556 14.4581 231.552 14.5348 231.563 14.6099C231.586 14.7703 231.668 14.9164 231.793 15.0202C231.918 15.124 232.076 15.1782 232.238 15.1725C233.011 15.1774 233.772 15.3597 234.463 15.7053C235.154 16.0509 235.757 16.5505 236.224 17.1658C236.282 17.2335 236.354 17.2885 236.434 17.3272C236.514 17.366 236.602 17.3876 236.691 17.3908Z"
        fill="#263238"
      />
      <path
        d="M220.535 24.5596L222.818 23.5791C222.818 23.5791 221.934 25.6366 220.535 24.5596Z"
        fill="#263238"
      />
      <path
        d="M210.601 31.9223C210.601 31.9223 216.516 23.3065 212.015 10.5434C212.015 10.5434 225.389 4.11368 235.516 13.9673C235.516 13.9673 226.096 -2.57324 206.405 5.35141C186.714 13.2761 201.583 32.1634 204.01 33.7387C206.437 35.314 210.601 31.9223 210.601 31.9223Z"
        fill="#263238"
      />
      <path
        d="M202.034 35.3459C202.812 36.3846 203.787 37.2588 204.905 37.9182C206.022 38.5776 207.259 39.0091 208.544 39.1877C211.871 39.5735 212.82 36.2139 211.309 33.1277C209.943 30.3468 206.487 26.7944 203.513 27.8392C200.539 28.8841 200.025 32.7097 202.034 35.3459Z"
        fill="#FDA57D"
      />
      <path
        opacity="0.2"
        d="M199.51 99.3219C198.112 94.2745 196.456 88.7771 194.511 82.701C194.504 82.6584 194.504 82.615 194.511 82.5724C196.119 81.4954 198.015 81.5276 200.121 83.2957C200.775 88.6361 200.569 94.0468 199.51 99.3219Z"
        fill="black"
      />
      <path
        d="M196.183 66.7875C196.183 66.7875 192.036 66.1285 177.087 83.0065L167.651 49.0254L159.389 53.9602C159.389 53.9602 160.659 100.833 167.86 104.868C173.164 107.841 197.806 89.9507 197.806 89.9507C197.806 89.9507 204.847 77.911 196.183 66.7875Z"
        fill="white"
      />
      <path
        opacity="0.7"
        d="M196.183 66.7875C196.183 66.7875 192.036 66.1285 177.087 83.0065L167.651 49.0254L159.389 53.9602C159.389 53.9602 160.659 100.833 167.86 104.868C173.164 107.841 197.806 89.9507 197.806 89.9507C197.806 89.9507 204.847 77.911 196.183 66.7875Z"
        fill="white"
      />
      <path
        d="M117.777 30.5669C117.657 30.5678 117.538 30.5451 117.427 30.5001C117.317 30.4551 117.216 30.3886 117.131 30.3045L107.826 21.1207C107.663 20.9445 107.575 20.7122 107.58 20.4726C107.585 20.2329 107.683 20.0044 107.852 19.8349C108.022 19.6654 108.25 19.5681 108.49 19.5632C108.729 19.5583 108.962 19.6463 109.138 19.8088L118.422 28.9521C118.549 29.0831 118.635 29.2485 118.668 29.4277C118.702 29.6068 118.682 29.7919 118.611 29.9598C118.54 30.1277 118.421 30.2709 118.269 30.3717C118.117 30.4725 117.939 30.5263 117.756 30.5265L117.777 30.5669Z"
        fill="#263238"
      />
      <path
        d="M125.911 17.1035C125.871 17.101 125.833 17.0906 125.797 17.0729C125.761 17.0553 125.729 17.0308 125.703 17.0008C125.677 16.9708 125.657 16.9359 125.644 16.8981C125.631 16.8603 125.626 16.8204 125.629 16.7806C125.629 16.6595 125.851 13.8337 127.647 13.0264C127.684 13.0098 127.724 13.0009 127.765 13.0001C127.805 12.9993 127.845 13.0066 127.883 13.0217C127.921 13.0367 127.955 13.0592 127.984 13.0877C128.013 13.1163 128.035 13.1503 128.051 13.1878C128.071 13.2243 128.082 13.2647 128.084 13.3061C128.087 13.3476 128.08 13.389 128.065 13.4276C128.049 13.4661 128.026 13.5008 127.995 13.5291C127.965 13.5574 127.929 13.5788 127.889 13.5915C126.436 14.2374 126.254 16.8008 126.254 16.821C126.244 16.903 126.203 16.9781 126.139 17.0307C126.075 17.0832 125.994 17.1093 125.911 17.1035Z"
        fill="#263238"
      />
      <path
        d="M136.065 32.4638H115.76L118.182 19.8891C118.248 19.5203 118.442 19.1867 118.73 18.9467C119.018 18.7068 119.381 18.576 119.756 18.5772H132.089C132.462 18.5799 132.824 18.7121 133.111 18.9514C133.398 19.1906 133.593 19.522 133.663 19.8891L136.065 32.4638Z"
        fill="#263238"
      />
      <path
        opacity="0.2"
        d="M136.065 32.4638H115.76L118.182 19.8891C118.248 19.5203 118.442 19.1867 118.73 18.9467C119.018 18.7068 119.381 18.576 119.756 18.5772H132.089C132.462 18.5799 132.824 18.7121 133.111 18.9514C133.398 19.1906 133.593 19.522 133.663 19.8891L136.065 32.4638Z"
        fill="white"
      />
      <path
        opacity="0.6"
        d="M136.065 32.4643H115.76L117.294 24.3907C117.438 24.4512 117.592 24.4823 117.748 24.4823C117.904 24.4823 118.058 24.4512 118.202 24.3907C118.686 24.3907 119.13 24.2292 119.332 23.7448C119.553 23.2953 119.839 22.8805 120.18 22.5135C120.272 22.3937 120.387 22.2941 120.519 22.2211C120.651 22.1482 120.797 22.1034 120.947 22.0897C121.181 22.1216 121.396 22.2361 121.553 22.4126C121.751 22.6205 121.902 22.8682 121.997 23.1392C122.178 23.6035 122.178 24.1081 122.38 24.5521C122.582 24.9962 123.914 25.5815 124.277 24.7136C124.327 24.5683 124.368 24.42 124.399 24.2695V23.6237C124.484 23.3117 124.682 23.0423 124.954 22.8673C125.226 22.6922 125.553 22.6237 125.872 22.675C126.154 22.6924 126.422 22.8029 126.634 22.9893C126.845 23.1757 126.989 23.4272 127.043 23.7044C127.022 24.0607 127.022 24.4179 127.043 24.7741C127.077 24.935 127.155 25.0833 127.268 25.2023C127.382 25.3213 127.526 25.4065 127.685 25.4484C127.844 25.4902 128.012 25.4871 128.169 25.4394C128.327 25.3916 128.468 25.301 128.577 25.1778C128.788 24.9515 128.941 24.6768 129.022 24.3777C129.103 24.0787 129.109 23.7643 129.041 23.4622C129.031 23.3211 129.031 23.1795 129.041 23.0383C129.041 22.8828 129.074 22.729 129.136 22.5867C129.199 22.4443 129.29 22.3163 129.404 22.2108C129.639 22.0035 129.914 21.8457 130.212 21.7465C130.477 21.6098 130.776 21.5523 131.073 21.5809C131.37 21.6095 131.653 21.7231 131.887 21.908C132.107 22.2227 132.233 22.5939 132.25 22.9778C132.329 23.5303 132.611 24.034 133.039 24.3918C133.468 24.7495 134.013 24.9361 134.571 24.9154L136.065 32.4643Z"
        fill="white"
      />
      <path
        d="M110.045 27.0747C110.893 32.1005 117.412 36.0162 125.344 36.0162C133.277 36.0162 139.816 32.1005 140.664 27.0747H110.045Z"
        fill="black"
      />
      <path
        opacity="0.7"
        d="M110.045 27.0747C110.893 32.1005 117.412 36.0162 125.344 36.0162C133.277 36.0162 139.816 32.1005 140.664 27.0747H110.045Z"
        fill="black"
      />
      <path
        d="M134.308 34.1797H116.404V36.0164H134.308V34.1797Z"
        fill={color ?? theme.color.brand.inkGrey.grey_3}
      />
      <path
        opacity="0.6"
        d="M134.308 34.1797H116.404V36.0164H134.308V34.1797Z"
        fill="white"
      />
      <path
        d="M127.263 17.6697C127.263 17.9372 127.184 18.1986 127.035 18.421C126.887 18.6434 126.676 18.8167 126.428 18.9191C126.181 19.0214 125.909 19.0482 125.647 18.996C125.385 18.9439 125.144 18.8151 124.955 18.6259C124.766 18.4368 124.637 18.1959 124.585 17.9335C124.532 17.6712 124.559 17.3993 124.662 17.1522C124.764 16.9051 124.937 16.6939 125.16 16.5453C125.382 16.3967 125.643 16.3174 125.911 16.3174C126.27 16.3174 126.614 16.4598 126.867 16.7134C127.121 16.9671 127.263 17.311 127.263 17.6697Z"
        fill={color ?? theme.color.brand.inkGrey.grey_3}
      />
      <path
        d="M147.529 30.5667C147.36 30.5651 147.194 30.5236 147.044 30.4456L133.582 22.372C133.478 22.3115 133.387 22.2311 133.314 22.1353C133.242 22.0394 133.189 21.9301 133.159 21.8137C133.129 21.6973 133.122 21.5761 133.139 21.4571C133.156 21.3381 133.197 21.2237 133.259 21.1205C133.328 21.0169 133.417 20.9282 133.52 20.8596C133.624 20.7911 133.741 20.7441 133.863 20.7215C133.986 20.6989 134.111 20.7011 134.233 20.7281C134.354 20.755 134.469 20.8061 134.571 20.8783L148.033 28.851C148.202 28.9566 148.333 29.1137 148.406 29.2992C148.479 29.4847 148.49 29.6888 148.438 29.8811C148.386 30.0735 148.273 30.244 148.117 30.3675C147.96 30.4909 147.768 30.5608 147.569 30.5667H147.529Z"
        fill="#263238"
      />
      <path
        d="M155.642 17.1035C155.565 17.1037 155.491 17.0746 155.434 17.0221C155.378 16.9696 155.344 16.8977 155.339 16.821C155.339 16.821 155.157 14.2374 153.684 13.5915C153.612 13.5566 153.556 13.4946 153.53 13.4189C153.503 13.3432 153.508 13.2601 153.543 13.1878C153.558 13.1503 153.581 13.1163 153.609 13.0877C153.638 13.0592 153.673 13.0367 153.71 13.0217C153.748 13.0066 153.788 12.9993 153.829 13.0001C153.869 13.0009 153.909 13.0098 153.946 13.0264C155.743 13.8337 155.965 16.6595 155.965 16.7806C155.965 16.8629 155.934 16.9421 155.877 17.0021C155.821 17.0621 155.744 17.0984 155.662 17.1035H155.642Z"
        fill="#263238"
      />
      <path
        d="M145.488 32.4638H165.793L163.392 19.8891C163.321 19.522 163.126 19.1906 162.839 18.9513C162.552 18.7121 162.191 18.5799 161.817 18.5771H149.485C149.111 18.5799 148.75 18.7121 148.463 18.9513C148.176 19.1906 147.98 19.522 147.91 19.8891L145.488 32.4638Z"
        fill="#263238"
      />
      <path
        opacity="0.2"
        d="M145.488 32.4638H165.793L163.392 19.8891C163.321 19.522 163.126 19.1906 162.839 18.9513C162.552 18.7121 162.191 18.5799 161.817 18.5771H149.485C149.111 18.5799 148.75 18.7121 148.463 18.9513C148.176 19.1906 147.98 19.522 147.91 19.8891L145.488 32.4638Z"
        fill="white"
      />
      <path
        opacity="0.6"
        d="M145.51 32.4642H165.815L164.281 24.3905C164.134 24.4516 163.976 24.483 163.817 24.483C163.657 24.483 163.5 24.4516 163.352 24.3905C162.888 24.3905 162.444 24.2291 162.222 23.7446C162.016 23.2916 161.736 22.8757 161.395 22.5134C161.197 22.2753 160.915 22.1233 160.607 22.0896C160.381 22.1276 160.175 22.2415 160.022 22.4125C159.819 22.6203 159.661 22.8676 159.558 23.1391C159.396 23.6033 159.396 24.108 159.195 24.552C158.993 24.9961 157.661 25.5814 157.176 24.7135C157.157 24.566 157.157 24.4168 157.176 24.2694V23.6235C157.095 23.3132 156.901 23.0442 156.633 22.8688C156.364 22.6935 156.04 22.6243 155.723 22.6749C155.441 22.6922 155.173 22.8028 154.961 22.9892C154.749 23.1755 154.605 23.4271 154.552 23.7043C154.552 24.0676 154.552 24.4309 154.552 24.774C154.518 24.9349 154.44 25.0831 154.326 25.2022C154.213 25.3212 154.069 25.4064 153.91 25.4483C153.75 25.4901 153.583 25.487 153.426 25.4392C153.268 25.3915 153.127 25.3009 153.018 25.1777C152.807 24.9514 152.654 24.6767 152.573 24.3776C152.492 24.0785 152.485 23.7642 152.554 23.4621C152.554 23.3208 152.554 23.1795 152.554 23.0382C152.55 22.8814 152.514 22.7271 152.448 22.5848C152.382 22.4425 152.287 22.3152 152.171 22.2107C151.941 21.9951 151.665 21.8361 151.363 21.7464C151.101 21.6114 150.805 21.5548 150.512 21.5834C150.218 21.6121 149.939 21.7247 149.708 21.9079C149.488 22.2226 149.362 22.5937 149.345 22.9776C149.223 23.4895 148.927 23.943 148.507 24.2598C148.087 24.5767 147.569 24.7371 147.044 24.7135L145.51 32.4642Z"
        fill="white"
      />
      <path
        d="M139.778 27.0747C140.626 32.1005 147.145 36.0162 155.098 36.0162C163.05 36.0162 169.57 32.1005 170.397 27.0747H139.778Z"
        fill="#020202"
      />
      <path
        opacity="0.7"
        d="M139.778 27.0747C140.626 32.1005 147.145 36.0162 155.098 36.0162C163.05 36.0162 169.57 32.1005 170.397 27.0747H139.778Z"
        fill="black"
      />
      <path
        d="M164.039 34.1797H146.136V36.0164H164.039V34.1797Z"
        fill={color ?? theme.color.brand.inkGrey.grey_3}
      />
      <path
        opacity="0.6"
        d="M164.039 34.1797H146.136V36.0164H164.039V34.1797Z"
        fill="white"
      />
      <path
        d="M154.31 17.6697C154.31 18.0231 154.45 18.3619 154.7 18.6117C154.95 18.8615 155.289 19.0019 155.642 19.0019C155.911 19.0059 156.175 18.9294 156.401 18.7824C156.626 18.6354 156.803 18.4244 156.907 18.1765C157.012 17.9286 157.041 17.655 156.989 17.3908C156.938 17.1266 156.808 16.8838 156.618 16.6935C156.428 16.5031 156.185 16.3739 155.921 16.3224C155.657 16.2708 155.383 16.2993 155.135 16.4042C154.887 16.509 154.676 16.6855 154.529 16.9109C154.382 17.1364 154.306 17.4006 154.31 17.6697Z"
        fill={color ?? theme.color.brand.inkGrey.grey_3}
      />
      <path
        d="M190.841 30.5676C190.721 30.5697 190.602 30.5475 190.491 30.5024C190.38 30.4573 190.28 30.3902 190.195 30.3052C190.021 30.1311 189.924 29.8952 189.924 29.6492C189.924 29.4032 190.021 29.1673 190.195 28.9932L199.5 19.8095C199.674 19.6357 199.91 19.5381 200.156 19.5381C200.402 19.5381 200.638 19.6357 200.812 19.8095C200.981 19.986 201.075 20.221 201.075 20.4655C201.075 20.7099 200.981 20.9449 200.812 21.1214L191.527 30.3052C191.437 30.3933 191.329 30.462 191.211 30.5071C191.093 30.5523 190.967 30.5728 190.841 30.5676Z"
        fill="#263238"
      />
      <path
        d="M182.708 17.1047C182.669 17.0932 182.633 17.074 182.602 17.0484C182.571 17.0228 182.545 16.9912 182.526 16.9555C182.507 16.9197 182.496 16.8806 182.492 16.8403C182.489 16.8001 182.493 16.7596 182.506 16.7212C182.816 15.6052 183.026 14.4636 183.132 13.3101C183.132 13.2298 183.164 13.1528 183.22 13.096C183.277 13.0392 183.354 13.0073 183.434 13.0073C183.515 13.0073 183.592 13.0392 183.648 13.096C183.705 13.1528 183.737 13.2298 183.737 13.3101C183.606 14.5076 183.363 15.6902 183.011 16.8423C183.001 16.9154 182.965 16.9824 182.909 17.0307C182.853 17.079 182.782 17.1053 182.708 17.1047Z"
        fill="#263238"
      />
      <path
        d="M172.556 32.4638H192.861L190.459 19.8891C190.389 19.522 190.194 19.1906 189.907 18.9514C189.619 18.7121 189.258 18.5799 188.885 18.5772H176.552C176.177 18.576 175.814 18.7068 175.526 18.9467C175.238 19.1867 175.044 19.5203 174.978 19.8891L172.556 32.4638Z"
        fill="#263238"
      />
      <path
        opacity="0.2"
        d="M172.556 32.4638H192.861L190.459 19.8891C190.389 19.522 190.194 19.1906 189.907 18.9514C189.619 18.7121 189.258 18.5799 188.885 18.5772H176.552C176.177 18.576 175.814 18.7068 175.526 18.9467C175.238 19.1867 175.044 19.5203 174.978 19.8891L172.556 32.4638Z"
        fill="white"
      />
      <path
        opacity="0.6"
        d="M172.556 32.4642H192.881L191.347 24.3905C191.2 24.4516 191.042 24.483 190.883 24.483C190.723 24.483 190.566 24.4516 190.419 24.3905C189.954 24.3905 189.51 24.2291 189.288 23.7446C189.082 23.2916 188.802 22.8757 188.461 22.5134C188.263 22.2753 187.981 22.1233 187.673 22.0896C187.447 22.1276 187.241 22.2415 187.088 22.4125C186.885 22.6203 186.727 22.8676 186.624 23.1391C186.462 23.6033 186.462 24.108 186.261 24.552C186.059 24.9961 184.727 25.5814 184.242 24.7135C184.223 24.566 184.223 24.4168 184.242 24.2694V23.6235C184.161 23.3132 183.967 23.0442 183.699 22.8688C183.43 22.6935 183.106 22.6243 182.789 22.6749C182.506 22.6946 182.237 22.8056 182.022 22.9912C181.807 23.1768 181.659 23.427 181.598 23.7043C181.619 24.0606 181.619 24.4177 181.598 24.774C181.564 24.9349 181.486 25.0831 181.372 25.2022C181.259 25.3212 181.114 25.4064 180.955 25.4483C180.796 25.4901 180.629 25.487 180.471 25.4392C180.314 25.3915 180.173 25.3009 180.064 25.1777C179.853 24.9514 179.7 24.6767 179.619 24.3776C179.538 24.0785 179.531 23.7642 179.6 23.4621C179.61 23.3209 179.61 23.1793 179.6 23.0382C179.605 22.8819 179.575 22.7265 179.512 22.5833C179.449 22.4401 179.355 22.3128 179.237 22.2107C179.007 21.9951 178.731 21.8361 178.429 21.7464C178.167 21.6114 177.871 21.5548 177.578 21.5834C177.284 21.6121 177.005 21.7247 176.774 21.9079C176.554 22.2226 176.428 22.5937 176.411 22.9776C176.286 23.4876 175.988 23.9388 175.569 24.2551C175.15 24.5713 174.635 24.7332 174.11 24.7135L172.556 32.4642Z"
        fill="white"
      />
      <path
        d="M198.594 27.0747C197.747 32.1005 191.227 36.0162 183.275 36.0162C175.322 36.0162 168.803 32.1005 167.955 27.0747H198.594Z"
        fill="black"
      />
      <path
        opacity="0.7"
        d="M198.594 27.0747C197.747 32.1005 191.227 36.0162 183.275 36.0162C175.322 36.0162 168.803 32.1005 167.955 27.0747H198.594Z"
        fill="black"
      />
      <path
        d="M174.312 36.0361H192.215V34.1994H174.312V36.0361Z"
        fill={color ?? theme.color.brand.inkGrey.grey_3}
      />
      <path
        opacity="0.6"
        d="M174.312 36.0361H192.215V34.1994H174.312V36.0361Z"
        fill="white"
      />
      <path
        d="M181.357 17.6697C181.357 17.9372 181.437 18.1986 181.585 18.421C181.734 18.6434 181.945 18.8167 182.192 18.9191C182.439 19.0214 182.711 19.0482 182.974 18.996C183.236 18.9439 183.477 18.8151 183.666 18.6259C183.855 18.4368 183.984 18.1959 184.036 17.9335C184.088 17.6712 184.062 17.3993 183.959 17.1522C183.857 16.9051 183.683 16.6939 183.461 16.5453C183.239 16.3967 182.977 16.3174 182.71 16.3174C182.351 16.3174 182.007 16.4598 181.754 16.7134C181.5 16.9671 181.357 17.311 181.357 17.6697Z"
        fill={color ?? theme.color.brand.inkGrey.grey_3}
      />
      <path
        d="M176.329 42.9999H130.855L109.682 38.2163H197.502L176.329 42.9999Z"
        fill="#263238"
      />
      <path
        opacity="0.7"
        d="M176.329 42.9999H130.855L109.682 38.2163H197.502L176.329 42.9999Z"
        fill={color ?? theme.color.brand.inkGrey.grey_3}
      />
      <path
        d="M199.885 39.71H107.301C107 39.7127 106.701 39.6557 106.422 39.5422C106.143 39.4288 105.889 39.2612 105.675 39.0492C105.461 38.8371 105.291 38.5848 105.176 38.3067C105.06 38.0287 105 37.7304 105 37.4292C105.005 36.8225 105.25 36.2424 105.681 35.8152C106.112 35.3881 106.694 35.1484 107.301 35.1484H199.885C200.492 35.1484 201.074 35.3881 201.505 35.8152C201.936 36.2424 202.181 36.8225 202.186 37.4292C202.186 37.7304 202.126 38.0287 202.011 38.3067C201.895 38.5848 201.725 38.8371 201.511 39.0492C201.297 39.2612 201.043 39.4288 200.764 39.5422C200.485 39.6557 200.186 39.7127 199.885 39.71Z"
        fill="#263238"
      />
      <path
        opacity="0.8"
        d="M199.885 39.71H107.301C107 39.7127 106.701 39.6557 106.422 39.5422C106.143 39.4288 105.889 39.2612 105.675 39.0492C105.461 38.8371 105.291 38.5848 105.176 38.3067C105.06 38.0287 105 37.7304 105 37.4292C105.005 36.8225 105.25 36.2424 105.681 35.8152C106.112 35.3881 106.694 35.1484 107.301 35.1484H199.885C200.492 35.1484 201.074 35.3881 201.505 35.8152C201.936 36.2424 202.181 36.8225 202.186 37.4292C202.186 37.7304 202.126 38.0287 202.011 38.3067C201.895 38.5848 201.725 38.8371 201.511 39.0492C201.297 39.2612 201.043 39.4288 200.764 39.5422C200.485 39.6557 200.186 39.7127 199.885 39.71Z"
        fill={color ?? theme.color.brand.inkGrey.grey_3}
      />
      <defs>
        <linearGradient
          id="paint0_linear_1634_1325"
          x1="350.328"
          y1="429.456"
          x2="350.302"
          y2="365.883"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#EEEEEE" />
        </linearGradient>
      </defs>
    </svg>
  );
}
