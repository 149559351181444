import BackendApiClient from "./BackendApiClient";
import {
  DataWrapper,
  DataWrappedResponse,
} from "../exports/Types";
import { CouponDetailsInterface, ProductInterface } from "../exports/Interfaces";

class CompanyManager {
  public getSuggestedProducts = async (
    companyId: number,
    ids: number[]
  ): Promise<any> =>
    BackendApiClient.request({
      method: "GET",
      url: `/companies/${companyId}/recommended-products`,
      params: {
        selected_product_ids: ids,
      },
    });

  public async getFreeMenuItems(
    companyId: number
  ): Promise<DataWrappedResponse<DataWrapper<ProductInterface[]>>> {
    if (typeof companyId !== "number") {
      return {
        response: null,
        success: false,
        status: 400,
      };
    }

    return BackendApiClient.request({
      method: "GET",
      url: `/companies/${companyId}/free-eligible-products`,
    });
  }

  public async getPromoCodeDetails(
    companyId: number, 
    code: string
  ): Promise<DataWrappedResponse<DataWrapper<CouponDetailsInterface>>> {
    return BackendApiClient.request({
      method: "GET",
      url: `/companies/${companyId}/promo-codes/${code}`,
    });
  }
}

export default new CompanyManager();
