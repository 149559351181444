import {ORDER_METHODS, PaymentMethod} from "../../../../../services/exports/Constants";

export default {
    payment_methods: {
        card: 'Credit or debit card',
        cash: 'Cash',
        cash_or_card_in_store: 'Cash or card payment in restaurant',
    },
    order_methods: {
        [ORDER_METHODS.ROOM_SERVICE]: 'Room/Table service',
        [ORDER_METHODS.PICKUP]: 'Pickup',
        [ORDER_METHODS.DELIVERY]: 'Delivery',
        [ORDER_METHODS.DINE_IN]: 'Dine in',
    },
    descriptions: {
        [PaymentMethod.Cash]: 'Please check that you have the exact amount of cash',
        [PaymentMethod.Paypal]: 'Every time a customer pays with PayPal, PayPal charges 6-7% fees. So on 100€, we lose 6-7€. Therefore, we unfortunately have to add a surcharge of 3.5% for using PayPal. 🤯 Please consider using one of the other secure and encrypted payment methods at checkout. We are very grateful for your understanding and support! 🙏',
    },
    feedback: {
        payment_method_disabled: 'We do not accept {{paymentMethod}} payments for {{orderMethod}} orders'
    },
};