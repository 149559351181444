import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import AppStatusSlice from "./slices/AppStatusSlice";
import BasketSlice from "./slices/BasketSlice";
import DineInFlowSlice from "./slices/DineInFlowSlice";
import EventHelperSlice from "./slices/EventHelperSlice";
import InitialDataSlice from "./slices/InitialDataSlice";
import OrderSlice from "./slices/OrderSlice";
import PaymentOptionsSlice from "./slices/PaymentOptionsSlice";
import ProfileSlice from "./slices/ProfileSlice";
import ProductLikesSlice from "./slices/ProductLikesSlice";
import InterceptorMiddleware from "./middleware/InterceptorMiddleware";
import RootReducerMiddleware from "./middleware/RootReducerMiddleware";
import * as Sentry from "@sentry/react";

let rehydrationComplete;
let rehydrationFailed;

const rehydrationPromise = new Promise((resolve, reject) => {
  rehydrationComplete = resolve;
  rehydrationFailed = reject;
});

export function rehydration() {
  return rehydrationPromise;
}

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: (action) => {
    try {
      return action.type?.startWith('initialData/')
        ? null
        : action;
    } catch (e) {
      return null;
    }
  },
  stateTransformer: (state) => ({
    ...state,
    initialData: null,
  })
});

const persistConfig = {
  key: "root",
  storage,
};

export const reducersCombined = combineReducers({
  appStatus: AppStatusSlice.reducer,
  basket: BasketSlice.reducer,
  dineInFlow: DineInFlowSlice.reducer,
  eventHelper: EventHelperSlice.reducer,
  initialData: InitialDataSlice.reducer,
  order: OrderSlice.reducer,
  paymentOptions: PaymentOptionsSlice.reducer,
  profile: ProfileSlice.reducer,
  productLikes: ProductLikesSlice.reducer,
});

const reducerPersisted = persistReducer(persistConfig, reducersCombined);

const configure = () => configureStore({
  reducer: (state, actions) =>
    RootReducerMiddleware(state, actions, reducerPersisted),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(InterceptorMiddleware),
  enhancers: [sentryReduxEnhancer],
});

export const store = configure();
export const persistor = persistStore(store, null, () => {
  // this will be invoked after rehydration is complete
  rehydrationComplete();
});
