import {
  useEffect,
  useMemo,
  useState,
  useImperativeHandle,
  forwardRef
} from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ReduxActions from "../../store/ReduxActions";
import { actionCreators } from "../../store/actions";
import useTheme from "../../hooks/ui/useTheme";
import useAlert from "../../hooks/utility/useAlert";
import { StoreInterface } from "../../store/types";
import LabelIcon from "../../assets/logo/LabelIcon";
import CheckmarkTextInput from "../inputs/CheckmarkTextInput";
import { ButtonTypes, CouponDetailsInterface, ThemeInterface } from "../../services/exports/Interfaces";
import CompanyManager from "../../services/api/CompanyManager";
import { COUPON_TYPES, PROMO_CODE_TYPES } from "../../services/exports/Constants";
import MultiPromoCodeModal from "../../modals/MultiPromoCodeModal";
import useBasket from "../../hooks/basket/useBasket";
import MinimumValueErrorLabel from "../labels/MinimumValueErrorLabel";
import useAppMethods from "../../hooks/utility/useAppMethods";

const PromoCodeInput = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({ setPromoCode, removePromoCode }));

  const { t } = useTranslation(null, { keyPrefix: "Pages:CheckoutScreen" });

  const theme: ThemeInterface = useTheme();
  const alert = useAlert();
  const { formatCurrency } = useAppMethods();

  const { subtotal } = useBasket();

  const { cached_order } = useSelector((state: StoreInterface) => state.order);
  const { company } = useSelector((state: StoreInterface) => state.initialData);
  const { profile } = useSelector((state: StoreInterface) => state.profile);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [promoCodeInput, setPromoCodeInput] = useState<string>(cached_order?.coupon_details?.code ?? '');
  const [isApplied, setIsApplied] = useState<boolean>(!!cached_order?.coupon_details?.code);
  const [showMultiPromoCodeModal, setShowMultiPromoCodeModal] = useState(false);

  useEffect(() => {
    if (cached_order?.coupon_type !== COUPON_TYPES.PROMO_CODE && isApplied) {
      setPromoCodeInput('');
      setIsApplied(false);
    }
  }, [cached_order?.coupon_type, cached_order?.coupon_details?.code]);

  useEffect(() => {
    if (cached_order?.coupon_details?.code && profile?.id) {
      actualizePromoCode();
    }

  }, []);

  const actualizePromoCode = async () => {
    setIsLoading(true);
    const { response, success } = await CompanyManager.getPromoCodeDetails(company.id, cached_order.coupon_details.code);
    setIsLoading(false);

    if (!success) {
      removePromoCode();

      return;
    }

    const cachedComponent = cached_order.coupon_details.components.find((component) => !component.is_used);
    const actualComponent = response.data.data.components.find((component) => !component.is_used);

    if (cachedComponent.id !== actualComponent.id) {
      setPromoCode(response.data.data);
    }
  };

  const applyPromoCode = async (code?: string): Promise<any> => {
    setIsLoading(true);
    const { response, success } = await CompanyManager.getPromoCodeDetails(
      company.id,
      code ?? promoCodeInput
    );
    setIsLoading(false);

    if (!success) {
      applyPromoErrorHandler(response);

      return;
    }

    setPromoCode(response.data.data);
  };

  function applyPromoErrorHandler(error: any): void {
    if (error?.response?.status === 417) {
      alert.error({
        title: error?.response?.data?.title,
        description: error?.response?.data?.description,
      });
    }

    removePromoCode();
  }

  const setPromoCode = (promoCode: CouponDetailsInterface) => {
    ReduxActions.dispatch(
      actionCreators.order.updateOrder({
        coupon_type: COUPON_TYPES.PROMO_CODE,
        coupon_details: promoCode,
      })
    );

    setPromoCodeInput(promoCode.code);
    setIsApplied(true);

    if (promoCode.type === PROMO_CODE_TYPES.MULTI_PROMO_CODE) {
      toggleMultiPromoCodeModal();
    }
  };

  const removePromoCode = (): void => {
    setPromoCodeInput('');

    ReduxActions.dispatch(
      actionCreators.order.updateOrder({
        coupon_type: null,
        coupon_details: null,
      })
    );

    setIsApplied(false);
  }

  const toggleMultiPromoCodeModal = () => {
    setShowMultiPromoCodeModal(!showMultiPromoCodeModal);
  }

  const renderMultiPromoCodeModal = useMemo(() => {
    return (
      <MultiPromoCodeModal
        openModal={showMultiPromoCodeModal}
        toggleModal={toggleMultiPromoCodeModal}
      />
    );
  }, [showMultiPromoCodeModal]);

  const hasSubtotalExceededThreshold = (): boolean => {
    if (!cached_order?.coupon_details?.components) {
      return false;
    }

    const component = cached_order.coupon_details.components.find((component) => !component.is_used);

    return subtotal >= component.usage_threshold;
  }

  const renderMinimumValueError = useMemo(() => {
    if (
      !cached_order?.coupon_details
      || hasSubtotalExceededThreshold()
      || ! cached_order?.coupon_details?.components
      || cached_order?.coupon_details?.components?.length === 0
    ) {
      return null;
    }

    return (
      <div className="mt-mini">
        <MinimumValueErrorLabel
          description={t("minimum_value_error_label.description")}
          value={formatCurrency(cached_order?.coupon_details?.components[0]?.usage_threshold)}
        />
      </div>
    );
  }, [cached_order?.coupon_details, subtotal]);

  return (
    <>
      <label htmlFor="promo-code" className="font-semibold text-mini flex items-center">
        <LabelIcon className="h-4 w-4 mr-tiny" />
        {t('enter_promo_code.label')}
      </label>
      <CheckmarkTextInput
        type="uppercase"
        placeholder={t('enter_promo_code.placeholder')}
        value={promoCodeInput}
        onChange={setPromoCodeInput}
        onClick={isApplied ? removePromoCode : applyPromoCode}
        loading={isLoading}
        containerStyle={{ backgroundColor: theme.color.background.inkWhite.white_0 }}
        inputStyle="text-tiny"
        buttonContainerStyle="py-xxsmall rounded-lg"
        buttonFontStyle="text-tiny"
        buttonTitle={isApplied ? t('enter_promo_code.button.remove') : t('enter_promo_code.button.apply')}
        buttonType={isApplied ? ButtonTypes.danger : ButtonTypes.primary}
        disabled={isApplied}
        id="promo-code"
        className="mt-mini"
      />

      {renderMinimumValueError}
      {renderMultiPromoCodeModal}
    </>
  );
});

export default PromoCodeInput;
