export default {
  my_points: 'My points',
  loyalty_points: 'Loyalty points',
  already_have_points: 'Do you already have points?',
  log_in: 'Log in',
  earn_points: 'Earn Points for Free Items',
  status: {
    not_logged_in: {
      title: "Order & earn loyalty points",
      description: "{{count}} pts",
    },
    next_discount: {
      title: "My Reward points",
      description: "You’re {{count}} point from a {{discount}}% discount",
      description_other:
        "You’re {{count}} points from a {{discount}}% discount",
    },
    next_free_reward: {
      title: "My Reward points",
      description: "You’re {{count}} point from a free item",
      description_other: "You’re {{count}} points from a free item",
    },
    discount_available: {
      title: "My Reward points",
      description: "{{discount}}% discount available & auto applied",
    },
    free_reward_available: {
      title: "My Reward points",
      description: "Free item unlocked",
    },
  },
};