import {
  ModifierInterface,
} from "../../../services/exports/Interfaces";
import RadioButton from "../../buttons/RadioButton";
import classnames from "classnames";
import {useTranslation} from "react-i18next";
import useAppMethods from "../../../hooks/utility/useAppMethods";

interface Props {
  data: ModifierInterface;
  isSelected: boolean;
  toggle: (modifier: ModifierInterface) => void;
}

export default function ModifierSingleSelect(props: Props) {
  const { t } = useTranslation(null, { keyPrefix: 'Components:Menu:Modifier:ModifierSingleSelect' });

  const { data, isSelected, toggle } = props;

  const { formatCurrency } = useAppMethods();

  function toggleModifier(): void {
    if (!data?.is_available) {
      return;
    }

    toggle(data);
  }

  function getPrice() {
    const isDiscount: boolean = data?.actual_price !== data?.original_price;

    return data?.is_available ? (
      <div className={priceContainer}>
        <h4 className={priceStyle(isDiscount, data?.is_available)}>
          + {formatCurrency(data?.original_price)}
        </h4>
        {isDiscount && (
          <h4 className={highlightedPriceStyle}>
            + {formatCurrency(data?.actual_price)}
          </h4>
        )}
      </div>
    ) : null;
  }

  return (
    <button
      className={classnames(container, {
        "touchable-highlight": data?.is_available,
      })}
      onClick={toggleModifier}
    >
      <div className="mr-mini">
        <RadioButton active={isSelected} />
      </div>
      <div className="flex-1">
        <p
          className={classnames("mr-mini text-left", {
            "opacity-25": !data?.is_available,
          })}
        >
          {data?.name}
        </p>
      </div>
      {getPrice()}
      {!data?.is_available && (
        <div className="flex-none">
          <p className="ml-mini text-companyBrand-primary">
            {t('labels.sold_out')}
          </p>
        </div>
      )}
    </button>
  );
}

const container =
  "flex flex-row w-full items-center mb-small before:relative after:content-[''] after:absolute after:-top-0 after:-bottom-0";

const priceContainer = "flex flex-row items-center flex-none";

const priceStyle = (isDiscount?: boolean, isAvailable?: boolean) =>
  `text-brand-text-grey line-clamp-1 text-right mini ${
    isDiscount ? "line-through" : ""
  } ${!isAvailable ? "opacity-25" : ""}`;

const highlightedPriceStyle =
  "text-brand-text-default text-right mini semibold line-clamp-1 ml-mini";
