import {useMemo, useState, useEffect, useRef, ReactElement} from "react";
import CustomModal from "../components-library/CustomModal";
import CustomNavBar from "../components-library/navigation/CustomNavBar";
import useScreenType from "../hooks/utility/useScreenType";
import { MODAL_SIZES, SCREENS } from "../services/exports/Constants";
import LoadingIndicator from "../components-library/loading/LoadingIndicator";
import CustomButton from "../components-library/buttons/CustomButton";
import PhoneNumberStep from "../components-library/checkout/stepper/PhoneNumberStep";
import useGoogleReviewSuggestedProducts from "../hooks/google-review/useGoogleReviewSuggestedProducts";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useDineInFlow from "../hooks/global/useDineInFlow";
import ButtonFooter from "../components-library/footers/ButtonFooter";
import useAuth from "../hooks/global/useAuth";
import ProductsCarousel from "../components-library/carousel/ProductsCarousel";
import RewardIcon from "../assets/logo/RewardIcon";
import GoogleIcon from "../assets/logo/GoogleIcon";
import useRewards from "../hooks/loyalty/useRewards";
import useAppState from "../hooks/global/useAppState";
import useNavigate from "../hooks/navigation/useNavigate";
import useAppMethods from "../hooks/utility/useAppMethods";
import useLoyaltyProgram from "../hooks/loyalty/useLoyaltyProgram";
import useCashbackProgram from "../hooks/cashback/useCashbackProgram";
import StarIcon from "../assets/logo/StarIcon";
import useGoogleReview from "../hooks/google-review/useGoogleReview";
import useTheme from "../hooks/ui/useTheme";

const LoginModal = (): JSX.Element => {
  const { t } = useTranslation(null, { keyPrefix: 'Modals:LoginModal' });

  const { openModal, onLoginSuccess, onCloseRedirect } = useAppState().loginModalProps;

  const theme = useTheme();
  const { formatCurrency, toggleLoginModal } = useAppMethods();
  const { isDesktop } = useScreenType();
  const { navigate } = useNavigate();
  const location = useLocation();
  const { isLoggedIn } = useAuth();
  const { isDineIn } = useDineInFlow();
  const { maxReward } = useRewards();
  const { hasLoyaltyPromotion } = useLoyaltyProgram();
  const { googlePromotion } = useGoogleReview()
  const { hasCashbackProgram, cashbackProgram, calculateExpectedCashback, formatPointsAsMoney } = useCashbackProgram();

  const {
    loading: loadingProducts,
    message: ratingMessage,
    suggestedProducts,
  } = useGoogleReviewSuggestedProducts({ autoLoad: true });

  const [initialLoading, setInitialLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);

  const phoneNumberStepRef = useRef(null);
  const bodyRef = useRef<HTMLDivElement>(null);

  const isLoggedInRef = useRef(isLoggedIn);
  isLoggedInRef.current = isLoggedIn;

  const isGoogleReview = location?.pathname?.includes(
    SCREENS.UPLOAD_GOOGLE_REVIEW_SCREEN
  );

  useEffect(() => {
    if (openModal) {
      onDidFocus();
    }
  }, [openModal]);

  function onDidFocus(): void {
    setInitialLoading(true);
    setTimeout(() => {
      setInitialLoading(false);
    }, 1000);
    setTimeout(() => {
      scrollIntoView();
    }, 1500);
  }

  function isFullScreen(): boolean {
    return !isDesktop;
  }

  function handleLoginSuccess(): void {
    setInitialLoading(true);
    setTimeout(() => {
      closeModal();
      onLoginSuccess && onLoginSuccess();
    }, 1000);
  }

  function closeModal(): void {
    toggleLoginModal({ openModal: false });

    if (onCloseRedirect) {
      return onCloseRedirect();
    }

    if (!isLoggedInRef.current) {
      navigate(-1);
    }
  }

  function scrollIntoView(): void {
    bodyRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  }

  function renderSeparator(): JSX.Element {
    return <div className={separator} />;
  }

  const renderHeaderBar = (): JSX.Element => {
    const getTitle = (): JSX.Element => {
      if (isGoogleReview) {
        return (
          <div className="w-full flex items-center justify-center lg:relative fixed lg:left-none left-0 lg:top-0 top-[23px]">
            <div className="mr-mini">
              <GoogleIcon className="w-5 h-5" />
            </div>
            {t("header.upload_google_review_get_free_rewards")}
          </div>
        );
      }

      return (
        <div className="w-full flex items-center justify-center lg:relative fixed lg:left-none left-0 lg:top-0 top-[23px]">
          <div className="mr-mini">
            <RewardIcon fill />
          </div>
          {
            hasCashbackProgram
            ? t('header.cashback', { value: 500, moneyValue: formatCurrency(5) })
            : t('header.earn_points_get_free_rewards', { count: maxReward?.cost })
          }
        </div>
      );
    };

    return (
      <div className={headerContainer}>
        <CustomNavBar
          title={getTitle()}
          rightClick={closeModal}
          hideBackIcon
          containerClassName={isDineIn ? "bg-background-inkWhite-white_0" : ""}
        />
      </div>
    );
  };

  const renderLoadingPage = useMemo<JSX.Element>(() => {
    return initialLoading || loadingProducts ? (
      <div className={loadingContainer}>
        <div className={loadingOuterWrapper}>
          <div className={loadingWrapper}>
            <div className="-mb-[10px]">
              <LoadingIndicator lg={60} md={50} sm={40} />
            </div>
          </div>
        </div>
      </div>
    ) : null;
  }, [initialLoading, loadingProducts]);

  const renderCashbackBenefits = (): ReactElement => {
    if (! hasCashbackProgram) {
      return null;
    }

    if (isGoogleReview) {
      return (
        <div>
          <div className="flex justify-center gap-4">
            {[1, 2, 3, 4, 5].map(
              (item) => (
                <StarIcon
                  key={item}
                  className="h-8 w-8"
                  color={theme.color.companyBrand.primary}
                />
              )
            )}
          </div>
          <p className="mt-small text-center">{t('header.rate_us')}</p>
          <h5 className="text-center text-companyBrand-primary">{t('cashback_reward', { value: googlePromotion.reward, moneyValue: formatPointsAsMoney(googlePromotion.reward) })}</h5>
        </div>
      );
    }

    return (
      <>
        <p className="text-tiny">{t('cashback_benefits.text')}</p>
        <h6 className="mt-small">{t('cashback_benefits.title')}</h6>
        <p className="text-tiny mt-small">
          {t('cashback_benefits.earn', { value: calculateExpectedCashback(1), amount: formatCurrency(1) })}
        </p>
        <p className="text-tiny mt-small">{t('cashback_benefits.redeem')}</p>
        {cashbackProgram.max_spending_limit && (
          <p className="text-tiny mt-small">
            {t('cashback_benefits.max_spending_limit', { value: formatPointsAsMoney(cashbackProgram.max_spending_limit) })}
          </p>
        )}
        <p className="text-tiny mt-small">
          {t('cashback_benefits.point_lifetime', { value: cashbackProgram.point_lifetime })}
        </p>
      </>
    )
  }

  const renderSuggestedProducts = (): ReactElement => {
    if (! hasLoyaltyPromotion) {
      return null;
    }

    const loyaltyDescription = (
      <>
        <h6 className="mb-tiny">{t("product_suggestions.loyalty.title")}</h6>
        <p className="tiny">{t("product_suggestions.loyalty.description")}</p>
      </>
    );

    const googleReviewDescription = (
      <>
        <p className="text-center mb-tiny">
          {t("product_suggestions.googleReview.title")}
        </p>
        <h6 className="text-companyBrand-primary text-center">
          {ratingMessage({ type: "rewardWithPrefix" })}
        </h6>
      </>
    );

    return (
      <>
        <div className="mb-small">
          {isGoogleReview ? googleReviewDescription : loyaltyDescription}
        </div>
        <ProductsCarousel products={suggestedProducts} />
      </>
    );
  };

  const renderPhoneNumber = (): JSX.Element => {
    return (
      <div className="mt-medium">
        <h6 className={enterPhoneNumberTitleStyle}>
          {t("enter_phone_number.title")}
        </h6>
        <p className={enterPhoneNumberDescriptionStyle}>
          {t("enter_phone_number.description")}
        </p>
        {renderSeparator()}
        <PhoneNumberStep
          ref={phoneNumberStepRef}
          hideContinueButton
          phoneNumberInputProps={{
            inputContainerStyle: "bg-brand-text-disabled",
          }}
          toggleLoading={setLoading}
          toggleDisabledStatus={setButtonDisabled}
          onCodeRequested={scrollIntoView}
          onLoginSucceed={handleLoginSuccess}
        />
      </div>
    );
  };

  return (
    <CustomModal
      open={openModal}
      size={MODAL_SIZES.MD}
      fullScreen={isFullScreen()}
      onClose={() => closeModal()}
    >
      <div className={container}>
        {renderHeaderBar()}
        <div className={wrapper} ref={bodyRef}>
          {renderCashbackBenefits()}
          {renderSuggestedProducts()}
          {renderPhoneNumber()}
        </div>
        <ButtonFooter className={buttonContainer}>
          <CustomButton
            title={t("button.continue")}
            loading={loading}
            disabled={buttonDisabled}
            onClick={() => phoneNumberStepRef.current?.onPress()}
          />
        </ButtonFooter>
        {renderLoadingPage}
      </div>
    </CustomModal>
  );
};

export default LoginModal;

const container =
  "h-fit bg-background-inkWhite-white_1 lg:h-fit h-full relative";

const wrapper =
  "lg:px-medium px-small pt-small pb-[100px] h-fit bg-background-inkWhite-white_1";

const headerContainer = "sticky top-0 z-10";

const loadingContainer =
  "w-full h-full z-50 absolute top-0 bg-background-inkWhite-white_1 flex flex-col justify-center items-center";

const loadingOuterWrapper =
  "w-full h-full z-50 fixed top-0 flex flex-col justify-center items-center";

const loadingWrapper =
  "bg-background-inkWhite-white_1 lg:h-24 lg:w-24 md:h-20 md:w-20 h-16 w-16 rounded-lg flex justify-center items-center";

const buttonContainer = "lg:sticky fixed bottom-0";

const enterPhoneNumberTitleStyle = "mb-mini";

const separator = "mb-small";

const enterPhoneNumberDescriptionStyle = "tiny";
