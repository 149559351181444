import ConfirmOrderScreen from './ConfirmOrderScreen';
import DineInScreen from './DineInScreen';
import MenuScreen from './MenuScreen';
import PaymentSuccessfulScreen from './PaymentSuccessfulScreen';
import SplitBillScreen from './SplitBillScreen';
import TableScreen from './TableScreen';
import WelcomeScreen from './WelcomeScreen';

export default {
  ConfirmOrderScreen,
  DineInScreen,
  MenuScreen,
  PaymentSuccessfulScreen,
  SplitBillScreen,
  TableScreen,
  WelcomeScreen,
}