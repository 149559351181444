export default {
  header: {
    payment_method: "Payment Methods",
  },
  confirm_cancel_modal: {
    title: "Remove {{name}}",
    description: "Are you sure you want to remove {{name}} as a payment method?",
    buttons: {
      yes: "Yes, remove",
      no: "No",
    },
  },

  custom_button: {
    save: "Save",
  },

  error_messages: {
    saving_payment_method_unsuccessful: "Failed to save payment method",
    removing_payment_method_unsuccessful:
      "Failed to remove payment method. Please try again.",
  },
};
