import {
  LineItemInterface,
} from "../../services/exports/Interfaces";
import PriceComparison from "../payment/PriceComparison";
import collect from "collect.js";

interface Props {
  data: LineItemInterface;
  className?: string;
}

export default function OrderLineItem(props: Props) {
  const { data, className } = props;

  return (
    <div className={className}>
      <div className="flex flex-row justify-between items-center">
        <div className="shrink">
          <p className="mini">
            {data.quantity}x {data.name}
          </p>
        </div>
        <div className="flex flex-none">
          <PriceComparison
            actualPrice={data.actual_price}
            originalPrice={data.original_price}
          />
        </div>
      </div>
      {data.modifiers.length > 0 && (
        <p className="mt-mini break-words text-brand-text-grey tiny">
          {collect(data.modifiers).implode('name', ' • ')}
        </p>
      )}
      {data.children.length > 0 && (
        <ul className="list-disc pl-small mt-tiny">
          {data.children.map((item) => (
            <li
              key={`line-item-${data.id}-child-${item.id}`}
              className="text-tiny text-brand-inkGrey-grey_4"
            >
              {item.name}
              {item.modifiers?.length > 0 && (
                <>
                  {' '}
                  ({collect(item.modifiers).implode('name', ', ')})
                </>
              )}
            </li>
          ))}
        </ul>
      )}
      {data.note && (
        <p className="text-brand-text-grey italic tiny mt-mini">{data?.note}</p>
      )}
    </div>
  );
}