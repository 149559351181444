export default {
  steps: {
    payment_note: {
      title: 'Zahlungs-Hinweis: Kein Bargeld',
    },
    phone_number: {
      title: 'Handynummer',
      subtext:
        'We need your phone number to send you order updates and verify you are a real person.',
    },
    personal_info: {
      title: 'Für wen ist die Bestellung gedacht? 😊',
    },
  },
};
