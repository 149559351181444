import { OpeningHoursInterface } from "../../services/exports/Interfaces";
import { collect, Collection } from "collect.js";
import WorkScheduleUnit from "./WorkScheduleUnit";

interface Props {
  title: string;
  schedule: OpeningHoursInterface[];
  className?: string;
}

interface WorkScheduleGroup {
  day: number;
  hours: OpeningHoursInterface[];
}

export default function WorkSchedule(props: Props) {
  const { title, schedule, className } = props;

  const groupedSchedule = collect(
    collect(schedule)
      .groupBy("open.day")
      .map((hours: Collection<OpeningHoursInterface>, day) => ({
        day,
        hours: hours.toArray(),
      }))
      .toArray()
  )
    .sortBy((item: WorkScheduleGroup) => (+item.day === 0 ? 7 : +item.day))
    .toArray();

  return (
    <div className={className}>
      <h4>{title}</h4>
      <div className="flex flex-col mt-small">
        {groupedSchedule.map((item: WorkScheduleGroup, index) => (
          <WorkScheduleUnit
            day={item?.day}
            hours={item?.hours}
            classNames={index !== 0 ? "border-t" : ""}
            key={`${title}-unit-${item?.day}`}
          />
        ))}
      </div>
    </div>
  );
}
