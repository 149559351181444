export default {
  animation_description: {
    order_here_earn_rewards: "Order here and earn rewards",
    order_here_earn_points: "You earn points when you order here",
    menu_item_reward_threshold:
      "1 menu item (min. {{threshold}}) = {{count}} point",
    menu_item_reward_threshold_other:
      "1 menu item (min. {{threshold}}) = {{count}} points",
    order_reward_threshold: "1 order (min. {{threshold}}) = {{count}} point",
    order_reward_threshold_other:
      "1 order (min. {{threshold}}) = {{count}} points",
  },
  points_earned: {
    titles: {
      no_free_menu_item: "Get a {{discount}}% discount",
      free_menu_item: "Get 1 free {{product}}",
      multiple_free_menu_item: "Get 1 free {{product}} & more",
    },
  },
  button: {
    okay: "Okay",
  },
};
