import { SCREENS } from "../../services/exports/Constants";
import useNavigate from "../../hooks/navigation/useNavigate";
import {ButtonTypes, OrderItemInterface} from "../../services/exports/Interfaces";
import CustomModal from "../../components-library/CustomModal";
import {useTranslation} from "react-i18next";
import CustomButton from "../../components-library/buttons/CustomButton";

type Props = {
  open: boolean;
  order: OrderItemInterface;
  onConfirm: () => void;
  toggleModal: () => void;
}

const DuplicateOrderModal = (props: Props): JSX.Element => {
  const { t } = useTranslation(undefined, { keyPrefix: 'Modals:Checkout:DuplicateOrderModal' });

  const { open, order, onConfirm, toggleModal } = props;

  const { navigate } = useNavigate();

  const _onConfirm = () => {
    onConfirm();
    toggleModal();
  }

  return (
    <CustomModal open={open}>
      <div className="w-full flex flex-col p-medium">
        <p>{t('text')}</p>
        <div className="mt-medium flex justify-between">
          <CustomButton
            title={t('buttons.see_existing_order')}
            onClick={() => navigate(`${SCREENS.ORDER_DETAILS}/${order?.order_uuid}`)}
            className="mr-small p-xsmall"
            titleClass="text-mini"
          />
          <CustomButton
            title={t('buttons.proceed_new_order')}
            buttonType={ButtonTypes.forth}
            className="p-xsmall"
            titleClass="text-mini"
            onClick={_onConfirm}
          />
        </div>
      </div>
    </CustomModal>
  );
};

export default DuplicateOrderModal;
