import useTheme from "../../hooks/ui/useTheme";

interface Props {
  className?: string;
  style?: Object;
  color?: string;
}

export default function ThumbUpIcon(props: Props) {
  const { className, color, style } = props;
  const theme = useTheme();

  return (
    <svg
      className={className ?? "h-5 w-5"}
      viewBox="0 0 19 20"
      fill="none"
      style={style}
    >
      <path
        d="M6.63475 15.2115H12.0193C12.2449 15.2115 12.4497 15.1582 12.6337 15.0518C12.8176 14.9454 12.9499 14.7897 13.0307 14.5845L14.9385 10.1463C14.9782 10.0436 15.0079 9.94417 15.0278 9.848C15.0478 9.75183 15.0577 9.65892 15.0577 9.56925V8.94225C15.0577 8.72942 14.9892 8.55442 14.852 8.41725C14.7148 8.28008 14.5398 8.2115 14.327 8.2115H9.4385L10.0208 5.06225C10.0531 4.90792 10.0443 4.76217 9.99425 4.625C9.94425 4.48783 9.86733 4.36733 9.7635 4.2635L9.30775 3.80775L5.4385 8C5.33717 8.104 5.26117 8.221 5.2105 8.351C5.16 8.48117 5.13475 8.627 5.13475 8.7885V13.7115C5.13475 14.124 5.28158 14.4771 5.57525 14.7707C5.86908 15.0646 6.22225 15.2115 6.63475 15.2115ZM9.50175 19.5C8.18775 19.5 6.95267 19.2507 5.7965 18.752C4.64033 18.2533 3.63467 17.5766 2.7795 16.7218C1.92433 15.8669 1.24725 14.8617 0.74825 13.706C0.249417 12.5503 0 11.3156 0 10.0017C0 8.68775 0.249333 7.45267 0.748 6.2965C1.24667 5.14033 1.92342 4.13467 2.77825 3.2795C3.63308 2.42433 4.63833 1.74725 5.794 1.24825C6.94967 0.749417 8.18442 0.5 9.49825 0.5C10.8123 0.5 12.0473 0.749333 13.2035 1.248C14.3597 1.74667 15.3653 2.42342 16.2205 3.27825C17.0757 4.13308 17.7528 5.13833 18.2518 6.294C18.7506 7.44967 19 8.68442 19 9.99825C19 11.3123 18.7507 12.5473 18.252 13.7035C17.7533 14.8597 17.0766 15.8653 16.2218 16.7205C15.3669 17.5757 14.3617 18.2528 13.206 18.7518C12.0503 19.2506 10.8156 19.5 9.50175 19.5ZM9.5 18C11.7333 18 13.625 17.225 15.175 15.675C16.725 14.125 17.5 12.2333 17.5 10C17.5 7.76667 16.725 5.875 15.175 4.325C13.625 2.775 11.7333 2 9.5 2C7.26667 2 5.375 2.775 3.825 4.325C2.275 5.875 1.5 7.76667 1.5 10C1.5 12.2333 2.275 14.125 3.825 15.675C5.375 17.225 7.26667 18 9.5 18Z"
        fill={color ?? theme.color.brand.danger}
      />
    </svg>
  );
}
