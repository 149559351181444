import { useMemo, useReducer } from "react";
import {
  GlobalAppStateContextInterface,
  ReducerDispatchActionPayloadInterface,
} from "../services/exports/Interfaces";
import { APP_STATE_REDUCER_TYPES } from "../services/exports/Constants";
import AppStateContext from "../context/AppStateContext";

interface Props {
  children: any;
}

export default function AppStateProvider(props: Props): JSX.Element {
  const { children } = props;

  const initialState: GlobalAppStateContextInterface = {
    loginModalProps: {
      openModal: false,
    },
    infoModalProps: {
      openModal: false,
    },
    allOrderPaidModalProps: {
      openModal: false,
    },
    freeProductsModalProps: {
      openModal: false,
    },
    comboUpsellModalProps: {
      openModal: false,
      product: null,
      combos: null,
    },
    menuItemModalProps: {
      openModal: false,
      product: null,
    },
    comboModalProps: {
      openModal: false,
      combo: null,
    },
    menu: {
      screenPosition: 0,
      activeCategoryId: null,
      menuLoaded: false,
    },
    orderMethodModalProps: {
      openModal: false,
    },
    changeTablePartyModalProps: {
      openModal: false,
      onTableSelected: undefined,
    },
    ordersDisabledModalProps: {
      openModal: false,
      onConfirm: undefined,
    },
  };

  const [state, dispatch] = useReducer<
    (
      prevState: GlobalAppStateContextInterface,
      action: ReducerDispatchActionPayloadInterface<
        APP_STATE_REDUCER_TYPES,
        Partial<GlobalAppStateContextInterface>
      >
    ) => any
  >(
    (
      prevState: GlobalAppStateContextInterface,
      action: ReducerDispatchActionPayloadInterface<
        APP_STATE_REDUCER_TYPES,
        Partial<GlobalAppStateContextInterface>
      >
    ) => {
      const { payload } = action;
      switch (action.type) {
        case APP_STATE_REDUCER_TYPES.TOGGLE_LOGIN_MODAL:
          return {
            ...prevState,
            loginModalProps: payload?.loginModalProps,
          };
        case APP_STATE_REDUCER_TYPES.TOGGLE_INFO_MODAL:
          return {
            ...prevState,
            infoModalProps: payload?.infoModalProps,
          };
        case APP_STATE_REDUCER_TYPES.TOGGLE_ALL_ORDERS_PAID_MODAL:
          return {
            ...prevState,
            allOrderPaidModalProps: payload?.allOrderPaidModalProps,
          };
        case APP_STATE_REDUCER_TYPES.SAVE_MENU:
          return {
            ...prevState,
            menu: {
              ...prevState.menu,
              ...payload.menu,
            },
          };
        case APP_STATE_REDUCER_TYPES.TOGGLE_COMBO_UPSELL_MODAL:
          return {
            ...prevState,
            comboUpsellModalProps: payload?.comboUpsellModalProps,
          };
        case APP_STATE_REDUCER_TYPES.TOGGLE_MENU_ITEM_MODAL:
          return {
            ...prevState,
            menuItemModalProps: payload?.menuItemModalProps,
          };
        case APP_STATE_REDUCER_TYPES.TOGGLE_COMBO_MODAL:
          return {
            ...prevState,
            comboModalProps: payload?.comboModalProps,
          };
        case APP_STATE_REDUCER_TYPES.TOGGLE_ORDER_METHOD_MODAL:
          return {
            ...prevState,
            orderMethodModalProps: payload?.orderMethodModalProps,
          };
        case APP_STATE_REDUCER_TYPES.TOGGLE_CHANGE_TABLE_PARTY_MODAL:
          return {
            ...prevState,
            changeTablePartyModalProps: payload?.changeTablePartyModalProps,
          };
        case APP_STATE_REDUCER_TYPES.TOGGLE_ORDERS_DISABLED_MODAL:
          return {
            ...prevState,
            ordersDisabledModalProps: payload?.ordersDisabledModalProps,
          };
        case APP_STATE_REDUCER_TYPES.TOGGLE_FREE_PRODUCTS_MODAL:
          return {
            ...prevState,
            freeProductsModalProps: payload?.freeProductsModalProps,
          };
      }
    },
    initialState
  );

  const context = useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state, dispatch]
  );

  return (
    <AppStateContext.Provider value={context}>
      {children}
    </AppStateContext.Provider>
  );
}
