import React from "react";
import useTheme from "../../hooks/ui/useTheme";

interface Props {
  className?: string;
  style?: Object;
  color?: string;
}

export default function SearchIllustration(props: Props) {
  const { className, color, style } = props;
  const theme = useTheme();
  return (
    <svg
      className={className ?? "lg:w-1/4 md:w-1/2 w-3/4"}
      viewBox="0 0 692 423"
      fill="none"
      style={style}
    >
      <path
        d="M205.087 0.766735C312.559 -5.47127 425.236 27.0157 408.674 74.1097C392.11 121.204 497.224 57.3837 539.17 74.3627C569.035 86.4497 557.097 123.25 571.544 138.078C604.669 172.074 638.55 129.264 680.71 154.951C722.87 180.638 636.553 420.382 592.508 421.72L90.1499 418.668L54.0999 415.428C54.0999 415.428 -45.6201 360.892 25.7139 310.585C73.9469 276.569 39.2419 251.867 33.9959 236.294C27.9729 218.413 13.9199 187.186 75.1529 164.27C136.387 141.354 120.577 91.4877 119.07 81.1647C117.566 70.8367 97.6179 7.00373 205.087 0.766735V0.766735Z"
        fill="url(#paint0_linear_1335_513)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M222.75 231.213C176.591 214.412 152.79 163.377 169.589 117.224C186.387 71.0699 237.425 47.2739 283.584 64.0739C329.743 80.8749 353.544 131.91 336.745 178.064C319.947 224.218 268.909 248.013 222.75 231.213Z"
        fill="#E1E4E5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M229.997 210.843C194.995 198.104 176.947 159.405 189.685 124.408C202.423 89.4099 241.124 71.3659 276.125 84.1059C311.127 96.8449 329.175 135.544 316.437 170.542C303.699 205.539 264.998 223.583 229.997 210.843Z"
        fill="white"
      />
      <path
        d="M266.657 230.915C264.293 223.639 268.275 215.825 275.551 213.461C282.826 211.097 290.641 215.078 293.005 222.354L303.817 255.63L277.469 264.19L266.657 230.915V230.915Z"
        fill="#E1E4E5"
      />
      <path
        d="M301.127 246.54L272.915 255.706C269.601 256.783 267.787 260.343 268.864 263.657L309.779 389.58C310.856 392.894 314.415 394.708 317.73 393.631L345.942 384.465C349.256 383.388 351.07 379.828 349.993 376.514L309.078 250.591C308.002 247.277 304.442 245.463 301.127 246.54Z"
        fill="#0A0A0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M444.267 354.875L434.468 339.513C422.441 320.658 405.397 305.529 385.249 295.822L345.065 276.465C338.587 273.344 331.489 271.724 324.298 271.723H315.77L319.011 282.479L273.348 271.326C268.035 270.028 262.805 273.738 262.273 279.181C262.118 280.762 262.385 282.355 263.046 283.798C263.708 285.242 264.739 286.484 266.037 287.399L278.66 296.301C270.751 300.111 269.654 310.93 276.637 316.25L283.051 321.137C276.963 324.902 276.611 333.628 282.376 337.871L291.681 344.72C291.106 347.63 291.516 350.648 292.845 353.298C294.174 355.949 296.348 358.083 299.023 359.362L308.973 364.118C310.446 364.823 311.98 365.396 313.555 365.828L359.566 378.479L410.914 412.903L444.267 354.875V354.875Z"
        fill="#F8AE9D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M529.334 421L418.033 314.424L341.408 391.678L372.153 421H529.334V421Z"
        fill={color ?? theme.color.companyBrand.primary}
      />
      <path
        d="M421 340C417.686 340 415 342.686 415 346C415 349.314 417.686 352 421 352C424.314 352 427 349.314 427 346C427 342.686 424.314 340 421 340Z"
        fill="white"
      />
      <path
        d="M218.747 412.583C159.144 390.824 117.454 338.929 92.978 299.229C66.402 256.127 53.806 217.684 53.682 217.299L51.051 218.348C51.175 218.733 63.86 257.467 90.601 300.86C115.313 340.957 157.435 393.38 217.777 415.411L218.747 412.583Z"
        fill={color ?? theme.color.companyBrand.primary}
      />
      <path
        d="M56.013 203.329C61.435 213.58 62.381 225.23 59.478 235.327C50.004 233.009 41.217 226.292 35.794 216.041C30.366 205.79 29.419 194.139 32.323 184.043C41.802 186.361 50.584 193.078 56.013 203.329ZM50.1759 242.531C55.105 244.467 59.5755 247.411 63.3015 251.174C67.0275 254.937 69.9268 259.436 71.8139 264.384C62.9199 269.045 51.98 269.904 41.619 265.825C36.691 263.887 32.2214 260.942 28.4957 257.18C24.7699 253.417 21.8699 248.918 19.981 243.971C28.875 239.305 39.8149 238.452 50.1759 242.531ZM58.349 302.812C73.665 300.624 85.3039 293.384 84.3459 286.641C83.3869 279.898 70.194 276.206 54.878 278.395C39.562 280.584 27.923 287.824 28.881 294.566C29.84 301.309 43.033 305.001 58.349 302.812ZM79.366 337.093C94.682 334.905 106.321 327.665 105.362 320.922C104.404 314.179 91.211 310.487 75.894 312.676C60.578 314.865 48.9389 322.105 49.8979 328.847C50.8559 335.59 64.049 339.282 79.366 337.093Z"
        fill={color ?? theme.color.companyBrand.primary}
      />
      <path
        d="M74.871 259.197C75.474 248.377 79.974 238.606 86.597 231.936C92.487 238.381 95.911 248.009 95.308 258.829C94.705 269.649 90.205 279.42 83.581 286.09C77.686 279.645 74.268 270.017 74.871 259.197ZM96.81 294.675C97.254 283.849 101.618 273.995 108.14 267.207C114.131 273.533 117.691 283.09 117.247 293.916C116.804 304.742 112.439 314.596 105.911 321.385C99.932 315.058 96.367 305.501 96.81 294.675ZM112.387 348.029C116.621 345.788 121.275 345.717 125.201 347.424C124.001 351.847 121.068 355.796 116.84 358.037C112.605 360.278 107.951 360.349 104.025 358.641C105.219 354.219 108.153 350.27 112.387 348.029V348.029ZM195.647 415.89C172.478 388.053 166.794 350.485 166.106 323.873C165.357 294.979 170.171 272.395 170.22 272.17L168.62 271.933C168.571 272.158 163.718 294.911 164.465 323.995C165.156 350.871 170.902 388.824 194.357 417.007L195.647 415.89V415.89Z"
        fill={color ?? theme.color.companyBrand.primary}
      />
      <path
        d="M175.378 265.946C175.113 272.56 172.238 278.581 167.908 282.73C163.898 278.868 161.489 273.033 161.754 266.418C162.016 259.803 164.892 253.782 169.221 249.633C173.234 253.496 175.64 259.33 175.378 265.946V265.946ZM161.251 283.616C163.128 285.985 164.489 288.721 165.248 291.647C166.007 294.573 166.147 297.625 165.659 300.608C159.933 300.356 154.288 297.641 150.346 292.656C148.47 290.286 147.109 287.551 146.35 284.625C145.591 281.7 145.451 278.648 145.938 275.665C151.665 275.914 157.309 278.631 161.251 283.616V283.616ZM147.99 315.707C156.176 319.021 163.997 318.788 165.458 315.186C166.919 311.583 161.468 305.977 153.283 302.663C145.097 299.349 137.276 299.582 135.815 303.184C134.353 306.787 139.805 312.393 147.99 315.707ZM148.525 338.653C156.711 341.967 164.532 341.733 165.993 338.131C167.455 334.529 162.003 328.922 153.818 325.608C145.632 322.294 137.811 322.528 136.35 326.13C134.889 329.732 140.34 335.339 148.525 338.653V338.653Z"
        fill={color ?? theme.color.companyBrand.primary}
      />
      <path
        d="M168.655 298.924C172.057 293.758 177.081 290.227 182.263 288.836C183.32 293.706 182.248 299.439 178.846 304.606C175.444 309.772 170.42 313.302 165.238 314.694C164.178 309.822 165.254 304.09 168.655 298.924ZM169.303 322.725C172.628 317.51 177.609 313.9 182.775 312.421C183.916 317.261 182.931 322.999 179.606 328.214C176.281 333.429 171.301 337.039 166.132 338.516C164.996 333.679 165.979 327.94 169.303 322.725V322.725ZM161.683 353.521C164.415 353.63 166.732 354.93 168.18 356.899C166.318 358.738 163.738 359.845 161.009 359.737C158.277 359.629 155.96 358.329 154.512 356.359C156.37 354.52 158.95 353.413 161.683 353.521Z"
        fill={color ?? theme.color.companyBrand.primary}
      />
      <path
        d="M265.006 421.177C259.716 413.91 230.278 375.277 210.814 368.363C189.33 360.731 208.965 389.142 208.965 389.142C208.965 389.142 171.71 346.059 157.482 349.434C144.441 352.528 156.629 378.916 156.629 378.916C156.629 378.916 142.471 364.092 124.796 363.58C107.594 363.083 135.224 416.304 136.722 419.084L265.006 421.177V421.177ZM566.87 379.113C525.374 334.31 512.431 271.711 508.881 227.091C505.025 178.644 511.099 140.323 511.159 139.945L508.446 139.68C508.383 140.064 502.26 178.666 506.13 227.433C509.709 272.497 522.79 335.736 564.8 381.097L566.87 379.113Z"
        fill={color ?? theme.color.companyBrand.primary}
      />
      <path
        d="M519.28 129.065C519.433 140.194 515.132 150.534 508.219 157.861C501.123 151.712 496.544 142.115 496.396 130.989C496.242 119.86 500.543 109.52 507.457 102.193C514.555 108.337 519.126 117.937 519.28 129.065ZM497.094 159.903C500.463 163.716 502.999 168.19 504.539 173.039C506.08 177.887 506.591 183.005 506.04 188.062C496.38 188.117 486.636 184.03 479.553 175.989C472.47 167.948 469.525 157.627 470.605 147.835C480.267 147.776 490.011 151.862 497.094 159.903V159.903ZM477.653 214.89C491.728 219.772 504.869 218.728 507.005 212.559C509.141 206.389 499.462 197.43 485.388 192.549C471.313 187.667 458.171 188.711 456.035 194.881C453.9 201.05 463.578 210.009 477.653 214.89V214.89ZM480.618 253.362C494.693 258.244 507.834 257.2 509.97 251.031C512.106 244.861 502.428 235.902 488.353 231.021C474.278 226.139 461.137 227.183 459.001 233.353C456.865 239.522 466.543 248.481 480.618 253.362Z"
        fill={color ?? theme.color.companyBrand.primary}
      />
      <path
        d="M510.93 184.992C516.191 176.035 524.329 169.69 532.925 166.922C535.143 175.01 533.853 184.725 528.591 193.682C523.33 202.639 515.192 208.985 506.596 211.752C504.374 203.662 505.669 193.949 510.93 184.992ZM514.162 224.892C519.289 215.86 527.347 209.384 535.909 206.471C538.264 214.502 537.122 224.216 531.995 233.249C526.868 242.281 518.81 248.757 510.243 251.668C507.895 243.647 509.032 233.93 514.162 224.892ZM504.104 277.231C508.712 277.186 512.729 279.176 515.342 282.361C512.375 285.602 508.132 287.675 503.528 287.723C498.92 287.768 494.904 285.779 492.29 282.593C495.252 279.35 499.496 277.276 504.104 277.231ZM530.758 353.501C530.758 353.501 508.294 303.842 515.803 292.762C526.182 277.448 539.087 324.438 539.718 320.307C545.662 281.303 544.417 232.111 572.22 224.657C588.023 220.42 571.176 261.396 573.137 271.954C575.098 282.511 601.39 244.819 617.581 224.875C633.771 204.934 643.686 215.936 634.302 240.129C625.227 263.523 592.347 290.936 599.707 293.282C607.067 295.627 648.336 263.038 654.555 275.048C662.182 289.774 598.609 315.769 604.988 318.703C611.368 321.636 641.65 307.849 641.65 319.578C641.65 331.307 599.802 334.241 600.891 339.313C602.654 347.525 628.94 326.499 630.993 337.895C633.092 349.549 595.309 350.433 596.628 359.55C597.387 364.788 606.903 359.306 608.624 366.508C612.549 382.93 553.007 405.945 553.007 405.945L530.758 353.501V353.501Z"
        fill={color ?? theme.color.companyBrand.primary}
      />
      <path
        d="M474.898 360.786C462.555 366.556 465.886 380.887 465.886 380.887C465.886 380.887 432.423 354.808 419.911 370.029C407.399 385.251 413.976 415.084 428.649 419.936C443.321 424.785 570.948 421.386 570.948 421.386C570.948 421.386 486 355.597 474.898 360.786V360.786Z"
        fill={color ?? theme.color.companyBrand.primary}
      />
      <path
        d="M531.094 419.994C531.094 419.994 570.293 369.771 586.677 362.074C604.137 353.873 588.998 382.289 588.998 382.289C588.998 382.289 626.33 345.688 635.099 354.827C643.868 363.965 634.504 372.517 634.504 372.517C634.504 372.517 665.764 366.719 670.324 378.409C677.328 396.369 625.911 405.3 625.911 405.3C625.911 405.3 650.543 399.905 645.614 411.024C638.184 427.789 531.094 419.994 531.094 419.994Z"
        fill={color ?? theme.color.companyBrand.primary}
      />
      <path
        d="M570.948 421.261C570.948 421.261 508.487 425.668 496.324 414.506C484.157 403.344 496.226 383.121 496.226 383.121C496.226 383.121 474.792 366.212 475.125 355.171C475.458 344.13 489.422 349.733 489.422 349.733C489.422 349.733 465.757 319.321 471.367 313.535C481.823 302.764 509.184 334.375 509.184 334.375C509.184 334.375 501.065 319.337 509.63 316.95C522.549 313.348 532.054 350.436 532.054 350.436C532.054 350.436 529.643 335.789 538.549 337.735C554.533 341.224 570.948 421.261 570.948 421.261V421.261Z"
        fill={color ?? theme.color.companyBrand.primary}
      />
      <path
        d="M114.632 185.093C119.859 185.093 124.097 189.331 124.097 194.558C124.097 199.786 119.859 204.023 114.632 204.023C109.405 204.023 105.167 199.786 105.167 194.558C105.167 189.331 109.405 185.093 114.632 185.093Z"
        fill={color ?? theme.color.companyBrand.primary}
      />
      <path
        d="M294.468 28.3961C291.564 28.3961 289.21 30.7502 289.21 33.6541C289.21 36.558 291.564 38.9121 294.468 38.9121C297.372 38.9121 299.726 36.558 299.726 33.6541C299.726 30.7502 297.372 28.3961 294.468 28.3961Z"
        fill="#E1E4E5"
      />
      <path
        d="M544.766 73.6168C541.862 73.6168 539.508 75.9709 539.508 78.8748C539.508 81.7787 541.862 84.1328 544.766 84.1328C547.67 84.1328 550.024 81.7787 550.024 78.8748C550.024 75.9709 547.67 73.6168 544.766 73.6168Z"
        fill="#E1E4E5"
      />
      <path
        d="M89.9179 100.96C87.3039 100.96 85.1849 103.079 85.1849 105.693C85.1849 108.307 87.3039 110.426 89.9179 110.426C92.5318 110.426 94.6509 108.307 94.6509 105.693C94.6509 103.079 92.5318 100.96 89.9179 100.96Z"
        fill="#E1E4E5"
      />
      <path
        d="M413.141 41.0001C421.779 43.3146 426.905 52.1933 424.59 60.8312C422.276 69.4691 413.397 74.5952 404.759 72.2806C396.121 69.9661 390.995 61.0875 393.31 52.4496C395.624 43.8117 404.503 38.6856 413.141 41.0001Z"
        fill={color ?? theme.color.companyBrand.primary}
      />
      <defs>
        <linearGradient
          id="paint0_linear_1335_513"
          x1="360.755"
          y1="658.161"
          x2="352.829"
          y2="-458.855"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#EEEEEE" />
        </linearGradient>
      </defs>
    </svg>
  );
}
