import collect from "collect.js";
import { actionCreators } from "../../store/actions";
import ReduxActions from "../../store/ReduxActions";
import {
  ClientSecretKeyResponseInterface,
  CreatePaymentOptionDataInterface,
  DefaultPaymentMethodDataInterface,
  PaymentOptionPropertiesInterface,
} from "../exports/Interfaces";
import BackendApiClient from "./BackendApiClient";
import { DataWrappedResponse } from "../exports/Types";

class PaymentManager {
  getPaymentOptions = async () =>
    BackendApiClient.request({
      method: "GET",
      url: "/payment-options",
    });

  public postClientSecretKey = async (
    data: any
  ): Promise<DataWrappedResponse<ClientSecretKeyResponseInterface>> =>
    BackendApiClient.request({
      method: "POST",
      url: "/stripe/setup-intents",
      data,
    });

  postPaymentOption = async (data: CreatePaymentOptionDataInterface) => {
    const request = await BackendApiClient.request({
      method: "POST",
      url: "/payment-options",
      data,
    });
    const { response, success } = request;

    if (success) {
      const { data } = response.data;
      this.addPaymentOptionToRedux(data);
    }

    return request;
  };

  putDefaultPaymentOption = async (data: DefaultPaymentMethodDataInterface) => {
    const request = await BackendApiClient.request({
      method: "POST",
      url: "/default-payment-options",
      data,
    });
    const { response, success } = request;

    if (success) {
      const { data } = response.data;
      this.updatePaymentOptionRedux(data);
    }

    return request;
  };

  removePaymentOption = async (id?: number) => {
    const request = await BackendApiClient.request({
      method: "DELETE",
      url: `/payment-options/${id}`,
    });
    const { response, success } = request;

    if (success) {
      this.removePaymentOptionRedux(id, response?.data?.default_payment_option);
    }

    return request;
  };

  addPaymentOptionToRedux = (data: PaymentOptionPropertiesInterface) => {
    const action = actionCreators.paymentOptions.addPaymentOption(data);
    ReduxActions.dispatch(action);
  };

  removePaymentOptionRedux = (id: number, response) => {
    const payload = {
      removePaymentOptionId: id,
      newDefaultPaymentOption: response,
    };
    const action = actionCreators.paymentOptions.removePaymentOption(payload);
    ReduxActions.dispatch(action);
  };

  updatePaymentOptionRedux = (data: PaymentOptionPropertiesInterface) => {
    const action = actionCreators.paymentOptions.updatePaymentOption(data);
    ReduxActions.dispatch(action);
  };
}

export default new PaymentManager();
