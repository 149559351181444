export default class TextTransformationResource {
  private text: string = "";

  constructor(text?: string) {
    if (text) {
      this.text = text;
    }
  }

  public reduceToText(): string {
    try {
      return this.text.replace(/\s\s+/g, " ");
    } catch (error) {}
  }
}
