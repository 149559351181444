import {
  ProductComboInterface,
  ProductInterface,
} from "../../../services/exports/Interfaces";
import classnames from "classnames";
import CustomLabel from "../../labels/tinyLabels/CustomLabel";
import Theme from "../../Theme";
import RadioButton from "../../buttons/RadioButton";
import PriceComparison from "../../payment/PriceComparison";
import { useTranslation } from "react-i18next";
import { PRODUCT_HEIGHT } from "../../../services/exports/Constants";
import { useMemo } from "react";
import useProductIdentifiers from "../../../hooks/menu/useProductIdentifiers";
import useBasket from "../../../hooks/basket/useBasket";
import SeoResource from "../../../services/resources/SeoResource";

interface Props {
  product: ProductInterface | ProductComboInterface;
  showCaseProduct?: boolean;
  toggleProduct: () => void;
  className?: string;
}

export default function SingleSelectProduct(props: Props) {
  const { t } = useTranslation(null, {
    keyPrefix: "Components:Menu:SingleSelectMenuItems",
  });

  const { product, showCaseProduct, toggleProduct, className } = props;

  const { emojis } = useProductIdentifiers(product);
  const { freeItem } = useBasket();

  const isSelected = useMemo(
    () => freeItem?.product_id === product?.id,
    [freeItem?.product_id, product?.id]
  );

  function renderRadioButton(): JSX.Element {
    return !showCaseProduct ? (
      <div className="flex-none">
        <RadioButton
          active={isSelected}
          containerStyle={{ width: 21, height: 21 }}
          wrapperStyle={{ width: 15, height: 15 }}
        />
      </div>
    ) : null;
  }

  function renderSoldOutOverlay() {
    return (
      <div className={soldOutOverlayContainer}>
        {!product?.thumbnail_url && (
          <p className="text-white absolute top-1/2 -translate-y-1/2 left-5">
            {t("labels.sold_out")}
          </p>
        )}
      </div>
    );
  }

  function renderPriceRow(): JSX.Element {
    return (
      <div className={priceRowContainer}>
        <div className="h-full flex flex-col justify-end">
          <PriceComparison
            actualPrice={product?.actual_price}
            originalPrice={product?.original_price}
          />
        </div>
        <div className={customLabelContainer}>
          {emojis.isNotEmpty() && (
            <p className="mb-mini text-right">{emojis.implode(" ")}</p>
          )}
          <CustomLabel
            backgroundColor={Theme.color.brand.themeColors.color_1}
            textColor={Theme.color.brand.primary}
            label={t("labels.free")}
          />
        </div>
      </div>
    );
  }

  function renderProductImage(): JSX.Element {
    return (
      product?.thumbnail_url && (
        <div className={productImageContainer}>
          <div className={productImage}>
            <img
              src={product?.thumbnail_url}
              alt={SeoResource.toAltText(`${product?.name} image`)}
              className={productImage}
            />
          </div>
          {!product?.is_available && (
            <p className="text-white absolute top-1/2 -translate-y-1/2 w-full text-center z-10">
              {t("labels.sold_out")}
            </p>
          )}
        </div>
      )
    );
  }

  return (
    <label
      className={classnames(container(showCaseProduct), className)}
      style={{ height: PRODUCT_HEIGHT }}
      onClick={toggleProduct}
    >
      {renderProductImage()}
      <div className={wrapper(showCaseProduct)}>
        <div className={infoContainer}>
          <div className={infoWrapper}>
            <div className="grid grid-cols-5">
              <div className="col-span-4">
                <h6 className={titleStyle}>{product?.name}</h6>
              </div>
              <div className="cols-span-1 flex justify-end">
                {renderRadioButton()}
              </div>
            </div>
            <div className="grid grid-cols-1">
              <p className={classnames(descriptionStyle, "mini")}>
                {product?.description}
              </p>
            </div>
          </div>
        </div>
        {!showCaseProduct && renderPriceRow()}
      </div>

      {!product?.is_available && renderSoldOutOverlay()}
    </label>
  );
}

const container = (showCaseProduct?: boolean) =>
  `w-full border bg-background-inkWhite-white_1 rounded-2xl border-brand-inkGrey-grey_2 flex flex-row ${
    !showCaseProduct ? "cursor-pointer" : ""
  } overflow-hidden`;

const wrapper = (showCaseProduct: boolean) =>
  `p-small flex flex-col flex-1 ${
    showCaseProduct ? "justify-center" : "justify-between"
  } relative`;

const titleStyle =
  "text-brand-text-default line-clamp-2 break-words mb-tiny mr-tiny";

const descriptionStyle = "text-brand-text-grey line-clamp-2 break-words";

const productImageContainer =
  "h-full overflow-hidden lg:py-none lg:pl-none py-small pl-mini relative flex-none";
const productImage =
  "h-full aspect-square bg-brand-inkGrey-grey_2 lg:rounded-tl-lg lg:rounded-bl-lg lg:rounded-tr-none lg:rounded-br-none md:rounded-lg sm:rounded-lg object-cover";

const soldOutOverlayContainer =
  "top-0 left-0 absolute h-full w-full bg-brand-secondary bg-opacity-50";

const priceRowContainer =
  "flex flex-row justify-between items-center w-full relative";

const customLabelContainer = "flex flex-col";

const infoContainer = "flex flex-row justify-between";

const infoWrapper = "flex flex-1 flex-col";
