import moment, {Moment} from "moment";
import {OpeningHoursInterface, OpeningHoursScheduleInterface} from "../exports/Interfaces";

class PickUpTimesResource {
  public createDateRange(schedule: null|OpeningHoursInterface): null|Moment[] {
    if (! schedule) {
      return null;
    }

    const open = this.createDate(schedule.open);
    const close = this.createDate(schedule.close);

    return [
      open.isAfter(close) ? open.subtract(1, 'week') : open,
      close,
    ];
  }

  public createDate(times: OpeningHoursScheduleInterface, time?: Moment|string|null) {
    const _time = moment(time ?? undefined);

    const result = _time.clone().startOf('isoWeek').day(times?.day === 0 ? 7 : 0).add(times?.day, 'days').set({
      hour: times?.hour,
      minute: times?.minute,
      second: 0,
      millisecond: 0,
    });

    return (times?.day !== 0 && times?.day < _time.day() || _time.day() === 0 && times?.day > _time.day())
      ? result.add(1, 'week')
      : result;
  }
}

export default new PickUpTimesResource();
