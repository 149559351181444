import {ORDER_METHODS, RoomServiceLocationType} from "../../../../../services/exports/Constants";

export default {
  more_info: 'More info',
  labels: {
    available: 'Available',
    unavailable: 'Unavailable',
  },
  buttons: {
    change_store: 'Change store',
  },
  promo_code_banner: {
    single_use: '🔥 {{discount}} discount - code: {{code}}',
    multi_use: '🔥 {{discount}} discount on {{count}} orders - code: {{code}}',
  },
  order_method_info: {
    fee: {
      [ORDER_METHODS.PICKUP]: 'Pickup: {{fee}}',
      [ORDER_METHODS.DELIVERY]: 'Delivery: {{fee}}',
      [ORDER_METHODS.ROOM_SERVICE]: 'On site: {{fee}}',
      [ORDER_METHODS.DINE_IN]: 'On site: {{fee}}',
    },
    min_order: 'MIN: {{minOrder}}',
  },
};
