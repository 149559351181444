export default {
  rewards: "My Rewards",
  redeem_reward: "Redeem Reward Now",
  earn_points: "1. Earn points whenever you spend {{threshold}}",
  redeem_points:
    "2. Redeem points for discounts & free food when you order here",

  google_rating: {
    review: "Review us on Google to get a free {{product}} & more ",
    info: "Info",
    status: {
      success: "Review successfully uploaded & reward earned",
    },
  },
  toast: {
    upload_success: "Google review successfully verified & reward earned",
  },
  free_products: {
    title: "Applies to following items",
    point: "{{count}} Point",
    point_other: "{{count}} Points",
  },
};
