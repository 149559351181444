import { motion } from "framer-motion";
import CircularProgress, { circularProgressClasses } from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import useScreenType from "../../hooks/utility/useScreenType";
import Theme from "../Theme";

interface Props {
    color: string;
    logo?: string | null;
}

const LoadingApp = (props: Props): JSX.Element | null => {
    const { logo, color } = props;

    const { isDesktop, isTablet } = useScreenType();

    function getSize(): number {
        if (isDesktop) {
            return 180;
        }

        if (isTablet) {
            return 160;
        }

        return 140;
    }

    const renderLoading = (color: string): JSX.Element => {
        return (
            <Box sx={{ position: "relative", display: "inline-flex" }}>
                <CircularProgress
                    size={getSize()}
                    thickness={2}
                    style={{
                        color,
                    }}
                    sx={{
                        opacity: 0.2,
                    }}
                    value={100}
                    variant="determinate"
                />

                <CircularProgress
                    variant="indeterminate"
                    sx={{
                        animationDuration: "550ms",
                        position: "absolute",
                        left: 0,
                        [`& .${circularProgressClasses.circle}`]: {
                            strokeLinecap: "round",
                        },
                    }}
                    size={getSize()}
                    thickness={2}
                    style={{
                        color,
                    }}
                />
            </Box>
        );
    };

    return (
        <motion.div
            className="w-screen h-screen flex justify-center items-center p-small fixed inset-0 bg-background-inkWhite-white_1"
            style={{ zIndex: 10000 }}
            initial={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
        >
            <motion.div
                exit={{ y: -80, scale: 0.3 }}
                transition={{ duration: 1 }}
                className="relative flex items-center justify-center"
                key="1"
            >
                {logo && (
                    <motion.div
                        className="absolute"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5 }}
                    >
                        <div className="lg:h-[60px] lg:w-[60px] h-[50px] w-[50px] relative">
                            <img src={logo ?? ""} alt={"restaurant_logo"} className="object-contain" />
                        </div>
                    </motion.div>
                )}
                <div className="absolute" key={Theme.color.brand.inkGrey.grey_6}>
                    {renderLoading(Theme.color.brand.inkGrey.grey_6)}
                </div>
                {color && (
                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
                        {renderLoading(color)}
                    </motion.div>
                )}
            </motion.div>
        </motion.div>
    );
};

export default LoadingApp;
