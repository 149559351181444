import React from 'react';
import classnames from "classnames";

export type DividerSize = "large" | "medium" | "small";

interface Props {
  size?: DividerSize;
  className?: string;
}

export default function Divider(props: Props) {
  const { size, className } = props;

  function getSize(): string {
    switch (size) {
      case "small":
        return "h-[2px]";
      case "medium":
        return "h-[3px]";
      default:
        return "h-[4px]";
    }
  }

  return <div className={classnames(divider(getSize()), className)} />;
}

const divider = (size: string) => `w-full ${size} bg-brand-inkGrey-grey_1`;
