import {useSelector} from "react-redux";
import {StoreInterface} from "../../store/types";
import useAppMethods from "../utility/useAppMethods";
import {useCallback} from "react";

export default function useCashbackProgram() {
  const { company, cashback_account } = useSelector((store: StoreInterface) => store.initialData);

  const { formatCurrency } = useAppMethods();

  const balance = cashback_account?.balance ?? 0;
  const formattedBalance = balance > 0 ? formatPointsAsMoney(balance) : null;
  const multiplier = company?.cashback_program?.percentage ? company.cashback_program.percentage / 100 : 0;

  function formatPointsAsMoney(value: number): string|number {
    return formatCurrency(value / 100);
  }

  const calculateExpectedCashback = useCallback(
    (amount: number): number => Math.round(amount * 100 * multiplier),
    [multiplier],
  );

  const calculateSpendableCashback = useCallback(
    (amount: number): number => {
      const centAmount = amount * 100;
      const value = (balance >= centAmount ? centAmount : balance);

      if (company?.cashback_program?.min_spending_limit && value < company?.cashback_program?.min_spending_limit) {
        return 0;
      }

      if (company?.cashback_program?.max_spending_limit && value >= company?.cashback_program?.max_spending_limit) {
        return company?.cashback_program?.max_spending_limit;
      }

      return value;
    },
    [
      balance,
      company?.cashback_program?.min_spending_limit,
      company?.cashback_program?.max_spending_limit,
    ],
  );

  return {
    hasCashbackProgram: !!company?.cashback_program,
    cashbackProgram: company?.cashback_program,
    account: cashback_account,
    balance,
    formattedBalance,
    calculateExpectedCashback,
    calculateSpendableCashback,
    formatPointsAsMoney,
  };
}