import React from "react";

interface Props {
  label?: string;
  backgroundColor?: string;
  textColor?: string;
}

export default function CustomLabel(props: Props) {
  const { label, backgroundColor, textColor } = props;

  const container =
    "flex flex-wrap justify-center items-center rounded px-mini py-tiny relative overflow-hidden";
 
  return (
    <div className={container} style={{ backgroundColor: backgroundColor }}>
      <p className="text-center tiny semibold" style={{ color: textColor }}>
        {label}
      </p>
    </div>
  );
}
