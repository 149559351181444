import React from "react";

interface Props {
  className?: string;
  style?: Object;
  color?: string;
}

export default function PayPalIcon(props: Props) {
  const { className, color, style } = props;
  return (
    <svg
      className={className ?? "h-4 w-4"}
      viewBox="0 0 62 17"
      fill="none"
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.601 2.99959C47.101 2.99959 45.901 3.39959 44.901 3.59959L44.601 5.89959C45.101 5.69959 46.501 5.29959 47.701 5.19959C48.901 5.19959 49.601 5.39959 49.401 6.49959C45.801 6.49959 43.401 7.19959 42.901 9.59959C42.201 13.5996 46.601 13.6996 48.301 11.8996L48.101 12.9996H51.301L52.701 6.49959C53.201 3.79959 50.801 2.89959 48.601 2.99959ZM48.801 9.69959C48.601 10.4996 47.901 10.7996 47.101 10.8996C46.401 10.8996 45.801 10.4996 46.201 9.69959C46.601 9.09959 47.501 9.09959 48.201 9.09959H49.001C48.901 8.99959 48.801 9.39959 48.801 9.69959V9.69959Z"
        fill="#32A6CE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.601 5.89961C45.101 5.69961 46.501 5.29961 47.701 5.19961C48.901 5.19961 49.601 5.39961 49.401 6.49961C45.801 6.49961 43.401 7.19961 42.901 9.59961C42.201 13.5996 46.601 13.6996 48.301 11.8996L48.101 12.9996H51.301L52.701 6.49961C53.301 3.79961 50.801 3.09961 48.601 3.09961L44.601 5.89961ZM48.801 9.69961C48.601 10.4996 47.901 10.7996 47.101 10.8996C46.401 10.8996 45.801 10.4996 46.201 9.69961C46.601 9.09961 47.501 9.09961 48.201 9.09961H49.001C48.901 8.99961 48.801 9.39961 48.801 9.69961V9.69961Z"
        fill="#1A80AD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.4008 0L52.8008 13H56.0008L58.7008 0H55.4008V0Z"
        fill="#32A6CE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.7008 0L52.8008 13H56.0008L58.7008 0H55.4008H57.7008Z"
        fill="#1A80AD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.5 0H34.6L32 13H35.5L36.4 9H38.9C41.3 9 43.3 7.6 43.8 5.1C44.3 2.2 42.2 0 40.5 0ZM40.4 4.5C40.2 5.4 39.3 6 38.4 6H36.8L37.5 3H39.2C40.1 3 40.6 3.6 40.4 4.5V4.5Z"
        fill="#32A6CE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.5004 0H36.5004L31.9004 13H35.4004L36.3004 9H38.8004C41.2004 9 43.2004 7.6 43.7004 5.1C44.3004 2.2 42.2004 0 40.5004 0V0ZM40.4004 4.5C40.2004 5.4 39.3004 6 38.4004 6H36.8004L37.5004 3H39.2004C40.1004 3 40.6004 3.6 40.4004 4.5V4.5Z"
        fill="#1A80AD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4006 2.99959C15.9006 2.99959 14.7006 3.39959 13.8006 3.59959L13.5006 5.89959C13.9006 5.69959 15.4006 5.29959 16.6006 5.19959C17.8006 5.19959 18.5006 5.39959 18.3006 6.49959C14.8006 6.49959 12.4006 7.19959 11.9006 9.59959C11.2006 13.5996 15.5006 13.6996 17.2006 11.8996L17.0006 12.9996H20.2006L21.6006 6.49959C22.1006 3.79959 19.6006 2.89959 17.4006 2.99959ZM17.7006 9.69959C17.5006 10.4996 16.8006 10.7996 16.0006 10.8996C15.3006 10.8996 14.7006 10.4996 15.2006 9.69959C15.6006 9.09959 16.5006 9.09959 17.1006 9.09959H17.9006C17.8006 8.99959 17.7006 9.39959 17.7006 9.69959Z"
        fill="#21789E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6002 5.89961C14.0002 5.69961 15.5002 5.29961 16.7002 5.19961C17.9002 5.19961 18.6002 5.39961 18.4002 6.49961C14.9002 6.49961 12.5002 7.19961 12.0002 9.59961C11.3002 13.5996 15.6002 13.6996 17.3002 11.8996L17.0002 12.9996H20.2002L21.6002 6.49961C22.2002 3.79961 19.7002 3.09961 17.5002 3.09961L13.6002 5.89961ZM17.7002 9.69961C17.5002 10.4996 16.8002 10.7996 16.0002 10.8996C15.3002 10.8996 14.7002 10.4996 15.2002 9.69961C15.6002 9.09961 16.5002 9.09961 17.1002 9.09961H17.9002C17.8002 8.99961 17.7002 9.39961 17.7002 9.69961Z"
        fill="#1A5B80"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.9004 3H26.1004L26.6004 8.6L29.8004 3H33.1004L25.5004 17H21.9004L24.2004 12.8L22.9004 3V3Z"
        fill="#21789E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.1004 3.2L26.6004 8.7L29.8004 3H33.1004L25.5004 17H21.9004L24.2004 12.9"
        fill="#1A5B80"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.50039 0H3.50039L0.900391 13H4.40039L5.30039 9H7.80039C10.2004 9 12.2004 7.6 12.7004 5.1C13.3004 2.2 11.2004 0 9.50039 0ZM9.40039 4.5C9.20039 5.4 8.30039 6 7.40039 6H5.80039L6.50039 3H8.20039C9.10039 3 9.60039 3.6 9.40039 4.5V4.5Z"
        fill="#21789E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.50039 0H6.80039L0.900391 13H4.40039L5.30039 9H7.80039C10.2004 9 12.2004 7.6 12.7004 5.1C13.3004 2.2 11.2004 0 9.50039 0ZM9.40039 4.5C9.20039 5.4 8.30039 6 7.40039 6H5.80039L6.50039 3H8.20039C9.10039 3 9.60039 3.6 9.40039 4.5V4.5Z"
        fill="#1A5B80"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7992 6.5C14.0992 6.7 12.2992 7.5 11.8992 9.5C11.1992 13.5 15.4992 13.6 17.1992 11.8L16.9992 13H20.1992L20.6992 10.6L16.7992 6.5ZM17.6992 9.7C17.4992 10.5 16.7992 10.8 15.9992 10.9C15.2992 10.9 14.6992 10.5 15.1992 9.7C15.5992 9.1 16.4992 9.1 17.0992 9.1H17.8992C17.7992 9 17.6992 9.4 17.6992 9.7ZM26.7992 8.5L26.5992 8.8L28.6992 10.8L33.0992 3H29.7992L26.7992 8.5ZM4.39922 13L5.29922 9L1.19922 13H4.39922Z"
        fill="#06435E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.3992 13L36.2992 8.8V8.9L32.1992 13H35.3992ZM47.8992 6.5C45.1992 6.7 43.3992 7.5 42.9992 9.5C42.2992 13.5 46.6992 13.6 48.3992 11.8L48.0992 13H51.2992L51.7992 10.6L47.8992 6.5V6.5ZM48.7992 9.7C48.5992 10.5 47.8992 10.8 47.0992 10.9C46.3992 10.9 45.7992 10.5 46.1992 9.7C46.5992 9.1 47.4992 9.1 48.1992 9.1H48.9992C48.8992 9 48.7992 9.4 48.7992 9.7V9.7ZM55.0992 7.1L52.7992 13H55.9992L56.8992 9L55.0992 7.1Z"
        fill="#2273AA"
      />
      <path
        d="M59.4004 2.10039V0.700392H58.9004V0.500391H60.2004V0.700392H59.7004V2.10039H59.4004ZM60.4004 2.10039V0.500391H60.7004L61.1004 1.60039C61.1004 1.70039 61.2004 1.80039 61.2004 1.80039C61.2004 1.70039 61.2004 1.70039 61.3004 1.50039L61.7004 0.400391H62.0004V2.00039H61.8004V0.600391L61.3004 2.00039H61.0004L60.5004 0.600391V2.00039H60.4004V2.10039Z"
        fill="#32A6CE"
      />
    </svg>
  );
}
