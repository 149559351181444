import classnames from "classnames";
import useTheme from "../../hooks/ui/useTheme";
import { ThemeInterface } from "../../services/exports/Interfaces";
import useOrderingDisabledStatus from "../../hooks/availability/useOrderingDisabledStatus";
import ButtonFooter from "./ButtonFooter";
import useBasket from "../../hooks/basket/useBasket";
import useAppMethods from "../../hooks/utility/useAppMethods";
import LoadingIndicator from "../loading/LoadingIndicator";
import OrderBasketCashback from "../cashback/OrderBasketCashback";

interface Props {
  title?: string;
  loading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

export default function CartFooter(props: Props) {
  const { title, loading, disabled, onClick } = props;

  const theme: ThemeInterface = useTheme();
  const { render: renderOrderingEnabled } = useOrderingDisabledStatus();
  const { formatCurrency } = useAppMethods();
  const { quantity, subtotal, total } = useBasket();

  if (renderOrderingEnabled) {
    return renderOrderingEnabled;
  }

  return quantity > 0 ? (
    <ButtonFooter className="z-50">
      <OrderBasketCashback
        subtotal={subtotal}
        total={total}
        className="mb-xsmall"
      />
      <div className={classnames(buttonStyle, {
        'justify-between': !loading,
        'justify-center': loading,
        'bg-companyBrand-primary': !disabled,
        'bg-background-inkDisabled-disabled_3': disabled,
      })} onClick={onClick}>
        {loading && <LoadingIndicator customColor={theme.color.white} lg={20} md={20} sm={20} />}
        {!loading && (
          <>
            <span>{title}</span>
            <div>
              {subtotal !== total && (
                <span className="line-through mr-mini">{formatCurrency(subtotal)}</span>
              )}
              <span>{formatCurrency(total)}</span>
            </div>
          </>
        )}
      </div>
    </ButtonFooter>
  ) : null;
}

const buttonStyle = 'flex flex-row w-full rounded py-xsmall px-xxsmall font-semibold text-mini text-white';
