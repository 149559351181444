import { useEffect } from "react";
import useAuth from "../../../hooks/global/useAuth";
import useAppMethods from "../../../hooks/utility/useAppMethods";

const AuthRoute = ({ children }: { children: JSX.Element }): JSX.Element => {
  const { isLoggedIn } = useAuth();

  const { toggleLoginModal } = useAppMethods();

  useEffect(() => {
    !isLoggedIn &&
      toggleLoginModal({
        openModal: true,
      });
  }, [isLoggedIn]);

  if (isLoggedIn) {
    return children;
  }

  return null;
};

export default AuthRoute;
