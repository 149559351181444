import MinimumValueErrorLabel from "../labels/MinimumValueErrorLabel";
import { useSelector } from "react-redux";
import classnames from "classnames";
import useDeliveryThreshold from "../../hooks/order/useDeliveryThreshold";
import {useTranslation} from "react-i18next";
import {StoreInterface} from "../../store/types";
import useAppMethods from "../../hooks/utility/useAppMethods";

interface Props {
  className?: string;
}

export default function DeliveryThreshold(props: Props) {
  const { t } = useTranslation(null, {keyPrefix: 'Components:Delivery:DeliveryThreshold'});

  const { className } = props;

  const { company } = useSelector((state: StoreInterface) => state.initialData);

  const { formatCurrency } = useAppMethods();
  const {
    hasDeliveryThreshold,
    isWithinThreshold,
    isWithinMinThreshold,
    isWithinMaxThreshold,
    minThreshold,
    maxThreshold,
    amountTillMinThreshold,
  } = useDeliveryThreshold();

  return hasDeliveryThreshold && !isWithinThreshold && (
    <>
      {!isWithinMinThreshold && (
        <div className={classnames(container, className)}>
          <MinimumValueErrorLabel
            description={t('min.title')}
            value={formatCurrency(amountTillMinThreshold)}
            type="Warning"
          />
          <h5 className="mt-mini tiny">
            {t('min.description', {
              name: company?.name,
              threshold: formatCurrency(minThreshold),
            })}
          </h5>
        </div>
      )}
      {!isWithinMaxThreshold && (
        <div className={classnames(container, className)}>
          <MinimumValueErrorLabel
            description={t('max.title')}
            type="Warning"
          />
          <h5 className="mt-mini tiny">
            {t('max.description', {
              name: company?.name,
              threshold: formatCurrency(maxThreshold),
            })}
          </h5>
        </div>
      )}
    </>
  );
}

const container = "bg-background-inkWhite-white_1";
