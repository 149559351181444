import collect from "collect.js";
import useTheme from "../../hooks/ui/useTheme";
import classnames from "classnames";

interface Props {
  className?: string;
  style?: Object;
  color?: string;
  fill?: boolean;
  gradient?: string[];
}

export default function RewardIcon(props: Props) {
  const { className, style, gradient, fill } = props;

  const theme = useTheme();

  const gradient1 = collect(
    gradient ?? theme.color.companyBrand.gradients.primary
  )?.get(0);

  const gradient2 = collect(
    gradient ?? theme.color.companyBrand.gradients.primary
  )?.get(1);

  return !fill ? (
    <svg
      className={className ?? "h-6 w-6"}
      viewBox="0 0 54 54"
      fill="none"
      style={style}
    >
      <circle
        cx="27"
        cy="27"
        r="25"
        fill="#FBFBFC"
        stroke={gradient1}
        strokeWidth="4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9621 40.5618C18.6826 40.5618 18.4045 40.4754 18.1696 40.3053C17.7646 40.011 17.554 39.5169 17.6256 39.0201L18.8797 30.24L13.9576 25.3167C13.5998 24.959 13.4729 24.4325 13.6268 23.9505C13.7807 23.4686 14.1911 23.1136 14.6893 23.0312L22.1116 21.7933L25.8539 14.3091C26.0847 13.8488 26.591 13.5451 27.0689 13.5626C27.5832 13.5653 28.0503 13.8596 28.2758 14.3226L31.8587 21.6826L39.4484 23.0339C39.9439 23.1217 40.3489 23.4781 40.5001 23.9573C40.6513 24.4379 40.523 24.9617 40.1666 25.3167L35.2432 30.24L36.4987 39.0201C36.5689 39.5196 36.3583 40.0164 35.9479 40.3093C35.5388 40.6036 34.9988 40.6428 34.552 40.416L27.1566 36.6712L19.5601 40.4214C19.3711 40.5159 19.1659 40.5618 18.9621 40.5618Z"
        fill="url(#paint1_linear_5745_30397)"
      />
      <defs>
        <linearGradient
          id="paint1_linear_5745_30397"
          x1="13.5625"
          y1="13.5619"
          x2="46.1306"
          y2="26.1089"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.71875" stopColor={gradient1} />
          <stop offset="1" stopColor={gradient2} />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg
      className={
        className
          ? classnames(
              "h-6 w-6 rounded-full bg-companyBrand-primary",
              className
            )
          : "h-6 w-6 rounded-full bg-companyBrand-primary"
      }
      viewBox="0 0 50 50"
      fill="none"
      style={style}
    >
      <circle cx="25" cy="25" r="25" fill="url(#paint0_linear_5701_30113)" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9621 38.5618C16.6826 38.5618 16.4045 38.4754 16.1696 38.3053C15.7646 38.011 15.554 37.5169 15.6256 37.0201L16.8797 28.24L11.9576 23.3167C11.5998 22.959 11.4729 22.4325 11.6268 21.9505C11.7807 21.4686 12.1911 21.1136 12.6893 21.0312L20.1116 19.7933L23.8539 12.3091C24.0847 11.8488 24.591 11.5451 25.0689 11.5626C25.5832 11.5653 26.0503 11.8596 26.2758 12.3226L29.8587 19.6826L37.4484 21.0339C37.9439 21.1217 38.3489 21.4781 38.5001 21.9573C38.6513 22.4379 38.523 22.9617 38.1666 23.3167L33.2432 28.24L34.4987 37.0201C34.5689 37.5196 34.3583 38.0164 33.9479 38.3093C33.5388 38.6036 32.9988 38.6428 32.552 38.416L25.1566 34.6712L17.5601 38.4214C17.3711 38.5159 17.1659 38.5618 16.9621 38.5618Z"
        fill="#FBFBFC"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5701_30113"
          x1="0"
          y1="0"
          x2="60.3113"
          y2="23.2353"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.71875" stopColor={gradient1} />
          <stop offset="1" stopColor={gradient2} />
        </linearGradient>
      </defs>
    </svg>
  );
}
