import useDineInFlow from "../hooks/global/useDineInFlow";
import ConfirmCancelModal from "./ConfirmCancelModal";
import { SCREENS } from "../services/exports/Constants";
import useAppState from "../hooks/global/useAppState";
import useNavigate from "../hooks/navigation/useNavigate";
import useAppMethods from "../hooks/utility/useAppMethods";

const InfoModal = (): JSX.Element => {
  const props = useAppState()?.infoModalProps;

  const { toggleInfoModal } = useAppMethods();
  const { isDineIn } = useDineInFlow();
  const { navigate } = useNavigate();

  function _onConfirm(): void {
    if (props?.onConfirm) {
      props?.onConfirm();

      return toggleInfoModal({ openModal: false });
    }

    navigate(isDineIn ? SCREENS.DINE_IN : SCREENS.HOME_SCREEN);
    toggleInfoModal({ openModal: false });
  }

  return (
    <ConfirmCancelModal
      {...props}
      onConfirm={_onConfirm}
      customGradient={
        "bg-gradient-to-r from-companyBrand-gradients-primary-color_1 via-companyBrand-gradients-primary-color_1 to-companyBrand-gradients-primary-color_2"
      }
    />
  );
};

export default InfoModal;
