import {ORDER_METHODS, PaymentMethod} from "../../../../../services/exports/Constants";

export default {
    payment_methods: {
        card: 'Kredit- oder Debitkarte',
        cash: 'Barzahlung',
        cash_or_card_in_store: 'Barzahlung oder Kartenzahlung im Restaurant',
    },
    order_methods: {
        [ORDER_METHODS.ROOM_SERVICE]: 'Vor Ort',
        [ORDER_METHODS.PICKUP]: 'Abholung',
        [ORDER_METHODS.DELIVERY]: 'Lieferung',
        [ORDER_METHODS.DINE_IN]: 'Dine in',
    },
    descriptions: {
        [PaymentMethod.Cash]: 'Bitte prüfe, dass du möglichst den genauen Bargeldbetrag besitzt',
        [PaymentMethod.Paypal]: 'Jedes Mal, wenn ein Kunde mit PayPal zahlt, nimmt PayPal 6-7% Gebühren. Also verlieren wir bei 100€ schon 6-7€. Daher müssen wir leider einen Aufschlag von 3,5% für die Nutzung von PayPal erheben. 🤯 Bitte erwäge, eine der anderen sicheren und verschlüsselten Zahlungsmethoden beim Bezahlen zu nutzen. Wir sind sehr dankbar für dein Verständnis und deine Unterstützung! 🙏',
    },
    feedback: {
        payment_method_disabled: 'Wir akzeptieren keine {{paymentMethod}} für {{orderMethod}} Bestellungen'
    },
};