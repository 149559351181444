export default {
  title: 'Welcome to {{company}} 🤗',
  text: 'Try our order from your phone experience. The fastest way to order and pay 📱',
  buttons: {
    skip: 'Skip',
    got_it: 'Got It 👍',
  },
  features: {
    1: {
      title: '1. Click through the menu & order here',
    },
    2: {
      title: '2. We bring your order to the table',
    },
    3: {
      title: '3. Pay on your phone when you’re ready',
      description: '*You can even split the bill with friends who sit on the same table 🖖',
    },
  },
};
