import CustomNavBar from "./CustomNavBar";

interface Props {
  title?: string;
  backIconTitle?: string;
  hideBackIcon?: boolean;
  hideCloseIcon?: boolean;
  customRightAction?: any;
  transparent?: boolean;
  className?: string;
  showCompanyLogo?: boolean;
  rightClick?: () => void;
  leftClick?: () => void;
}

export default function DineInNavBar(props: Props) {
  const {
    title,
    backIconTitle,
    hideBackIcon,
    hideCloseIcon,
    customRightAction,
    transparent,
    className,
    showCompanyLogo,
    rightClick,
    leftClick,
  } = props;

  return (
    <CustomNavBar
      title={title}
      backIconTitle={backIconTitle}
      hideBackIcon={hideBackIcon}
      hideCloseIcon={hideCloseIcon}
      customRightAction={customRightAction}
      transparent={transparent}
      containerClassName={className ?? "bg-background-inkWhite-white_0"}
      showCompanyLogo={showCompanyLogo}
      rightClick={rightClick}
      leftClick={leftClick}
    />
  );
}
