export default {
  steps: {
    payment_note: {
      title: 'Payment notice: No cash',
    },
    phone_number: {
      title: 'Phone number',
      subtext:
        'We need your phone number to send you order updates and verify you are a real person.',
    },
    personal_info: {
      title: 'Who is this order for? 😊',
    },
  },
};
