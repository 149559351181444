import {
  PartyInterface,
  UpdatePartyDataInterface,
} from "../exports/Interfaces";
import BackendApiClient from "./BackendApiClient";
import { DataWrapper, DataWrappedResponse } from "../exports/Types";
import { actionCreators } from "../../store/actions";
import ReduxActions from "../../store/ReduxActions";

class PartiesManager {
  public create = async (
    data
  ): Promise<DataWrappedResponse<PartyInterface>> => {
    const request = await BackendApiClient.request({
      method: 'POST',
      url: `/parties`,
      data,
    });

    const { success, response } = request;

    if (success) {
      this.saveDataRedux(response?.data?.data);
    } else {
      this.errorHandler(response);
    }

    return request;
  };

  public show = async (
    id: number
  ): Promise<DataWrappedResponse<DataWrapper<PartyInterface>>> => {
    const request = await BackendApiClient.request({
      method: 'GET',
      url: `/parties/${id}`,
    });

    const { success, response } = request;

    if (success) {
      this.saveDataRedux(response?.data?.data);
    } else {
      this.errorHandler(response);
    }

    return request;
  };

  public loadCurrentParty = async (): Promise<
    DataWrappedResponse<DataWrapper<PartyInterface>>
  > => {
    const request = await BackendApiClient.request({
      method: 'GET',
      url: `/current-party`,
    });

    const { success, response } = request;

    if (success) {
      this.saveDataRedux(response?.data?.data);
    } else {
      this.errorHandler(response);
    }

    return request;
  };

  public update = async (
    id: number,
    data: UpdatePartyDataInterface
  ): Promise<DataWrappedResponse<PartyInterface>> => {
    const request = await BackendApiClient.request({
      method: 'PUT',
      url: `/parties/${id}`,
      data,
    });

    const { success, response } = request;

    if (success) {
      this.saveDataRedux(response?.data?.data);
    } else {
      this.errorHandler(response);
    }

    return request;
  };

  public delete = async (id: number): Promise<DataWrappedResponse<PartyInterface>> => {
    const request = await BackendApiClient.request({
      method: 'DELETE',
      url: `/parties/${id}`,
    });

    const { success, response } = request;

    if (success) {
      this.saveDataRedux(response?.data?.data);
    } else {
      this.errorHandler(response);
    }

    return request;
  };

  public join = async (
    id: number
  ): Promise<DataWrappedResponse<DataWrapper<PartyInterface>>> => {
    const request = await BackendApiClient.request({
      method: "POST",
      url: `/party-memberships`,
      data: {
        party_id: id,
      },
    });

    const { success, response } = request;

    if (success) {
      ReduxActions.dispatch(
        actionCreators.dineInFlow.replaceTableParty(response?.data?.data)
      );
      this.saveDataRedux(response?.data?.data);
    } else {
      this.errorHandler(response);
    }

    return request;
  };

  public leave = async (
    id: number
  ): Promise<DataWrappedResponse<PartyInterface>> => {
    const request = await BackendApiClient.request({
      method: "DELETE",
      url: `/party-memberships`,
      data: {
        party_id: id,
      },
    });

    const { success, response } = request;

    if (success) {
      const resetPartyAction = actionCreators.dineInFlow.resetParty();
      const replacePartyDataAction =
        actionCreators.dineInFlow.replaceTableParty(response?.data?.data);

      ReduxActions.batchActions([resetPartyAction, replacePartyDataAction]);
    } else {
      this.errorHandler(response);
    }

    return request;
  };

  private errorHandler(error: any) {}

  private saveDataRedux(data: PartyInterface) {
    if (data?.deleted_at) {
      return ReduxActions.dispatch(actionCreators.dineInFlow.resetParty());
    }

    ReduxActions.dispatch(actionCreators.dineInFlow.setParty(data));

    if (data?.table) {
      const table = ReduxActions.getStore()?.dineInFlow?.table ?? {};

      ReduxActions.dispatch(
        actionCreators.dineInFlow.setTable({
          ...table,
          ...data.table,
        })
      );
    }
  }
}

export default new PartiesManager();
