export default {
  toast_messages: {
    reward_available: {
      part_1: "Your review is successfully verified. ",
      part_2: "You earned {{count}} point and earned a reward 🥳",
      part_2_other: "You earned {{count}} points and earned a reward 🥳",
    },
    reward_not_available: {
      part_1: "Your review is successfully verified. ",
      part_2: "You earned {{pointsEarned}} pts.",
    },
  },
};
