import React from "react";
import useTheme from "../../hooks/ui/useTheme";

interface Props {
  className?: string;
  style?: Object;
  color?: string;
}

export default function RefreshIcon(props: Props) {
  const { className, color, style } = props;
  const theme = useTheme();
  return (
    <svg
      className={className ?? "h-4 w-4"}
      viewBox="0 0 74 74"
      fill="none"
      style={style}
    >
      <path
        d="M0.515625 48.1758C0.515625 53.1888 1.57358 57.5752 3.6895 61.335C5.80537 65.0947 8.91407 68.0163 13.0156 70.0996C17.1172 72.1829 22.1465 73.2246 28.1035 73.2246H35.8672C37.1042 73.2246 38.0889 72.8421 38.8213 72.0771C39.5537 71.3122 39.9199 70.3763 39.9199 69.2695C39.9199 68.1953 39.5537 67.2757 38.8213 66.5107C38.0889 65.7458 37.1042 65.3633 35.8672 65.3633H28.1035C23.7415 65.3633 20.0957 64.5902 17.166 63.0439C14.2363 61.4977 12.0391 59.39 10.5742 56.7207C9.1094 54.0514 8.37701 51.0566 8.37701 47.7363C8.37701 44.3834 9.1094 41.4212 10.5742 38.8496C12.0391 36.278 14.2363 34.2679 17.166 32.8193C20.0957 31.3708 23.7415 30.6465 28.1035 30.6465H52.2246L64.7734 30.0605L63.9922 27.9609L54.2754 36.0176L43.9238 46.0762C43.5657 46.4017 43.2972 46.7842 43.1182 47.2236C42.9391 47.6631 42.8496 48.1595 42.8496 48.7129C42.8496 49.8848 43.2077 50.8288 43.9238 51.5449C44.6399 52.261 45.584 52.6191 46.7559 52.6191C47.8301 52.6191 48.7741 52.2122 49.5879 51.3984L71.9023 29.6699C72.7487 28.8236 73.1719 27.8307 73.1719 26.6914C73.1719 25.5521 72.7487 24.5755 71.9023 23.7617L49.5879 2.0332C48.7741 1.2194 47.8301 0.8125 46.7559 0.8125C45.584 0.8125 44.6399 1.17057 43.9238 1.8867C43.2077 2.60284 42.8496 3.53057 42.8496 4.6699C42.8496 5.25583 42.9391 5.7604 43.1182 6.1836C43.2972 6.6068 43.5657 6.99743 43.9238 7.3555L54.2754 17.4141L63.9922 25.4707L64.7734 23.3711L52.2246 22.7852H28.8848C22.765 22.7852 17.5973 23.8594 13.3818 26.0078C9.16634 28.1563 5.9681 31.1348 3.7871 34.9434C1.60612 38.7519 0.515625 43.1627 0.515625 48.1758Z"
        fill={color ?? theme.color.brand.inkGrey.grey_3}
      />
    </svg>
  );
}
