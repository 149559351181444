import { useState, useEffect, useRef } from "react";
import collect from "collect.js";
import CompanyManager from "../../services/api/CompanyManager";
import {ProductInterface, RewardInterface} from "../../services/exports/Interfaces";
import { useSelector } from "react-redux";
import Highlighter from "react-highlight-words";
import Theme from "../../components-library/Theme";
import { useTranslation } from "react-i18next";
import {StoreInterface} from "../../store/types";
import useCashbackProgram from "../cashback/useCashbackProgram";

interface MessageConfigInterface {
  highlightWords?: boolean;
  type?: "short" | "reward" | "rewardWithPrefix";
}

interface GoogleReviewSuggestedProductsHookInterface {
  findSuggestedProducts: () => Promise<void>;
  suggestedProducts: ProductInterface[];
  loading: boolean;
  getTitle: (className?: string) => any;
  message: (config?: MessageConfigInterface) => any;
}

interface Props {
  autoLoad?: boolean;
}

export default function useGoogleReviewSuggestedProducts(props?: Props): GoogleReviewSuggestedProductsHookInterface {
  const { t } = useTranslation(null, { keyPrefix: 'Hooks:GoogleReview:UseGoogleReviewSuggestedProducts' });

  const { company } = useSelector((state: StoreInterface) => state.initialData);
  const { google_review_program } = company;

  const { hasCashbackProgram, formatPointsAsMoney} = useCashbackProgram();

  const [loading, setLoading] = useState<boolean>(false);
  const [suggestedProducts, setSuggestedProducts] = useState<ProductInterface[]>([]);

  const companyRef = useRef(company);
  companyRef.current = company;

  useEffect(() => {
    if (props?.autoLoad && !!companyRef.current?.id) {
      findSuggestedProducts();
    }
  }, [props?.autoLoad, companyRef.current?.id]);

  async function findSuggestedProducts(): Promise<void> {
    if (!collect(companyRef.current?.rewards)?.firstWhere("discount", 100)) {
      return findDiscountedProducts();
    }

    setLoading(true);
    const { response, success } = await CompanyManager.getFreeMenuItems(
      companyRef.current?.id
    );

    if (!success) {
      return findDiscountedProducts();
    }

    if (response?.data?.data?.length === 0) {
      return findDiscountedProducts();
    }

    setSuggestedProducts(
      collect(response?.data?.data).map((product: ProductInterface) => {
          return {
            ...product,
            free_eligible: true,
          };
        })
        .toArray()
    );
    toggleInitialLoading();
  }

  function findDiscountedProducts(): void {
    const discountedProducts: ProductInterface[] = collect(companyRef.current?.products)
      .sortByDesc((product: ProductInterface) => product?.actual_price)
      .filter(
        (item: ProductInterface) => !!item?.thumbnail_url && item?.is_promotable
      )
      .toArray();

    if (discountedProducts?.length !== 0) {
      setSuggestedProducts(discountedProducts);
      toggleInitialLoading();

      return;
    }

    setSuggestedProducts(
      collect(companyRef.current?.products)
        .sortByDesc((product: ProductInterface) => product?.actual_price)
        .filter((item: ProductInterface) => !!item?.thumbnail_url)
        .toArray()
    );
    toggleInitialLoading();
  }

  const toggleInitialLoading = () => setTimeout(() => setLoading(false), 500);

  const getTitle = (className?: string) => {
    if (hasCashbackProgram) {
      return (
        <div className={className}>
          <p className="text-center">{t('cashback.title')}</p>
          <h5 className="text-center text-companyBrand-primary">
            {t('cashback.description', { value: google_review_program.reward, moneyValue: formatPointsAsMoney(google_review_program.reward) })}
          </h5>
        </div>
      )
    }

    const product = collect(suggestedProducts).first();

    return (
      <div className={className}>
        <p>{t('product_suggestions.title')}</p>
        <h5 className="text-companyBrand-primary">
          {t('product_suggestions.description', { product: product?.name })}
        </h5>
      </div>
    );
  }

  function getMessage(config?: MessageConfigInterface): any {
    const Highlight = ({children, highlightIndex}) => (
      <div className="inline text-companyBrand-primary">{children}</div>
    );

    const product = collect(suggestedProducts)?.first();
    const reward = collect(companyRef.current?.rewards)?.last(
      (reward: RewardInterface) =>
        (companyRef.current?.reward_balance ?? 0) + google_review_program?.reward >=
          reward?.cost && reward?.discount !== 100
    );

    if (product?.free_eligible) {
      if (suggestedProducts?.length > 1) {
        const message = () => {
          switch (config?.type) {
            case "short":
              return t(
                "product_suggestions.types.multiple_free_rewards.message_short",
                { product: product?.name }
              );
            case "reward":
              return t(
                "product_suggestions.types.multiple_free_rewards.message_reward",
                { product: product?.name }
              );
            case "rewardWithPrefix":
              return t(
                "product_suggestions.types.multiple_free_rewards.message_reward_prefix",
                { product: product?.name }
              );
            default:
              return t(
                "product_suggestions.types.multiple_free_rewards.message_default",
                { product: product?.name }
              );
          }
        };

        return config?.highlightWords ? (
          <Highlighter
            activeClassName=""
            highlightStyle={{ color: Theme.color.companyBrand.primary }}
            searchWords={[product?.name, "& more", "& mehr"]}
            autoEscape={true}
            textToHighlight={message()}
            highlightTag={Highlight}
          />
        ) : (
          message()
        );
      }

      const message = () => {
        switch (config?.type) {
          case "short":
            return t("product_suggestions.types.free_reward.message_short", {
              product: product?.name,
            });
          case "reward":
            return t("product_suggestions.types.free_reward.message_reward", {
              product: product?.name,
            });
          case "rewardWithPrefix":
            return t(
              "product_suggestions.types.free_reward.message_reward_prefix",
              {
                product: product?.name,
              }
            );
          default:
            return t("product_suggestions.types.free_reward.message_default", {
              product: product?.name,
            });
        }
      };

      return config?.highlightWords ? (
        <Highlighter
          activeClassName=""
          highlightStyle={{ color: Theme.color.companyBrand.primary }}
          searchWords={[product?.name, "& more", "& mehr"]}
          autoEscape={true}
          textToHighlight={message()}
          highlightTag={Highlight}
        />
      ) : (
        message()
      );
    }

    const message = () => {
      switch (config?.type) {
        case "short":
          return t("product_suggestions.types.discount.message_short", {
            discount: reward?.discount,
          });
        case "reward":
          return t("product_suggestions.types.discount.message_reward", {
            discount: reward?.discount,
          });
        case "rewardWithPrefix":
          return t("product_suggestions.types.discount.message_reward_prefix", {
            discount: reward?.discount,
          });
        default:
          return t("product_suggestions.types.discount.message_default", {
            discount: reward?.discount,
          });
      }
    };

    return config?.highlightWords ? (
      <Highlighter
        activeClassName=""
        highlightStyle={{ color: Theme.color.companyBrand.primary }}
        searchWords={[reward?.discount?.toString(), "%"]}
        autoEscape={true}
        textToHighlight={message()}
        highlightTag={Highlight}
      />
    ) : (
      message()
    );
  }

  return {
    findSuggestedProducts,
    suggestedProducts,
    loading,
    getTitle,
    message: getMessage,
  };
}
