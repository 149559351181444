import { CSSProperties } from "react";
import {
  ENVIRONMENTS,
} from "../../services/exports/Constants";

export default function EnvironmentBanner() {
  function getStyle(): CSSProperties {
    const backgroundColor = () => {
      switch (import.meta.env.VITE_MODE) {
        case ENVIRONMENTS.DEV:
          return "#000000";
        case ENVIRONMENTS.STAGING:
          return "#F89A2A";
        default:
          return "";
      }
    };

    return {
      zIndex: 1000,
      backgroundColor: backgroundColor(),
      height: 25,
    };
  }

  return (
    <>
      <div
        className={environmentBannerContainer}
        style={getStyle()}
        id={"env-banner"}
      >
        <p className={environmentBannerTextStyle}>
          {import.meta.env.VITE_MODE}
        </p>
      </div>
    </>
  );
}

const environmentBannerContainer =
  "w-full flex items-center justify-center";

const environmentBannerTextStyle = "tiny semibold text-brand-text-light";
