import { collect, Collection } from "collect.js";
import ReduxActions from "../../store/ReduxActions";
import {
  CompanyDetailsInterface, GeolocationInterface,
  GoogleAddressParamsInterface,
  GoogleLocationInterface,
} from "../exports/Interfaces";
import AddressHelpers from "../helpers/AddressHelpers";

export class GooglePlaceResource {
  private data: google.maps.places.PlaceResult;
  private addressComponents: Collection<google.maps.GeocoderAddressComponent>;
  private company: CompanyDetailsInterface | null;

  constructor(
    data?: google.maps.places.PlaceResult | null,
    company?: CompanyDetailsInterface
  ) {
    if (data) {
      this.data = data;
      this.addressComponents = collect(data.address_components);
    }
    if (company) {
      this.company = company;
    } else {
      this.company = this.getCompany();
    }
  }

  public getStreetName(): string {
    return (
      this.addressComponents.first((component) =>
        component.types.includes("route")
      )?.long_name ?? ""
    );
  }

  public getStreetNumber(): string {
    return (
      this.addressComponents.first((component) =>
        component.types.includes("street_number")
      )?.long_name ?? ""
    );
  }

  public getPostalCode(): string {
    return (
      this.addressComponents.first((component) =>
        component.types.includes("postal_code")
      )?.long_name ?? ""
    );
  }

  public getCity(): string {
    return (
      this.addressComponents.first((component) =>
        component.types.includes("locality")
      )?.long_name ?? ""
    );
  }

  public getState(): null|string {
    return (
      this.addressComponents.first((component) =>
        component.types.includes("administrative_area_level_1")
      )?.long_name ?? null
    );
  }

  public getCountry(): null|string {
    return (
      this.addressComponents.first((component) =>
        component.types.includes("country")
      )?.short_name ?? null
    );
  }

  public formatAddress(): string {
    return `${this.getStreetName()} ${this.getStreetNumber()}, ${this.getPostalCode()} ${this.getCity()}`;
  }

  public getLat(): number {
    return this.getCoordinates()?.lat();
  }

  public getLng(): number {
    return this.getCoordinates()?.lng();
  }

  public getCoordinates(): google.maps.LatLng {
    return this.data?.geometry?.location;
  }

  public async getLocation(
    data: GoogleAddressParamsInterface
  ): Promise<GoogleLocationInterface<google.maps.LatLng>> {
    try {
      const geocoder = new google.maps.Geocoder();

      const { results } = await geocoder.geocode({
        address: AddressHelpers.format(data.streetName, data.streetNumber, data.city, data.state, data.country),
      });

      const location = GooglePlaceResource.filterResult(results)?.geometry.location;

      return {
        success: !!location,
        location,
      };
    } catch (e) {
      return {
        success: false,
        location: null,
      };
    }
  }

  public static async getPlaceFromCoordinates(
    coordinates: GeolocationInterface
  ): Promise<GoogleLocationInterface<google.maps.GeocoderResult>> {
    try {
      const geocoder = new google.maps.Geocoder();

      const { results } = await geocoder.geocode({
        location: coordinates,
      });

      const location = GooglePlaceResource.filterResult(results);

      return {
        success: !!location,
        location,
      };
    } catch (e) {
      return {
        success: false,
        location: null,
      };
    }
  }

  public calculateDistance(coordinates?: GeolocationInterface): number {
    const location = this.getCoordinates();

    if (
      !location
      || !coordinates
      || !location.lat
      || !location.lng
      || !coordinates.lng
      || !coordinates.lng
    ) {
      return null;
    }

    try {
      return google.maps.geometry.spherical.computeDistanceBetween(this.getCoordinates(), coordinates);
    } catch (e) {
      console.error('Failed to calculate distance between 2 coordinates', e);

      return null;
    }
  }

  private getCompany(): CompanyDetailsInterface {
    if (this.company) {
      return this.company;
    }

    return ReduxActions.getStore()?.initialData?.company;
  }

  private static filterResult(results: google.maps.GeocoderResult[]): google.maps.GeocoderResult {
    return collect(results).first(
      (item) =>
        item.types.includes("street_address") ||
        item.types.includes("street_number") ||
        item.types.includes("neighborhood") ||
        item.types.includes("premise") ||
        item.types.includes("subpremise")
    );
  }
}
