export default {
  toasts: {
    member_joined: '{{user}} joined your table',
    member_left: '{{user}} left your table',
    member_ordered: '{{user}} ordered {{lineItems}}',
    table_closed: '{{user}} closed the table',
    all_paid: '{{user}} paid {{amount}}. All paid!',
    left_to_pay: '{{user}} paid {{amount}}. Left to pay {{leftToPay}}',
    refund_created: 'We refunded you {{amount}}. You should receive the money within 2-3 days at the latest',
    order_canceled: 'Sorry, we had to cancel your order',
    table_changed: '{{user}} changed your table to {{table}}'
  },
};