import { useEffect, useState } from "react";

interface Props {
  onScroll?: (scrollPosition: number) => void;
}

const useScrollPosition = (props?: Props) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrolling, setScrolling] = useState(false);

  let removed = false;
  let handle = null;

  useEffect(() => {
    const updatePosition = () => {
      setScrollPosition(window.pageYOffset);
      props?.onScroll && props?.onScroll(window.pageYOffset);

      if (handle) {
        clearTimeout(handle);
        setScrolling(true);
      }

      handle = setTimeout(() => setScrolling(false), 100);
    };

    window.addEventListener("scroll", updatePosition);
    updatePosition();

    return () => {
      if (removed) {
        return;
      }

      removed = true;
      if (handle) {
        clearTimeout(handle);
      }

      window.removeEventListener("scroll", updatePosition);
    };
  }, []);

  return { scrollPosition, scrolling };
};

export default useScrollPosition;
