import {ReactElement, useMemo, useState} from "react";
import PhoneNumberStep from "../../checkout/stepper/PhoneNumberStep";
import PersonalInfoStep, { Fields } from "../../checkout/stepper/PersonalInfoStep";
import {useSelector} from "react-redux";
import {StoreInterface} from "../../../store/types";
import useDineInFlow from "../../../hooks/global/useDineInFlow";
import {useTranslation} from "react-i18next";
import PaymentNoteStep from "./PaymentNoteStep";
import Stepper, {StepItem} from "../../stepper/Stepper";
import LoadingPage from "../../loading/LoadingPage";
import ReduxActions from "../../../store/ReduxActions";
import {actionCreators} from "../../../store/actions";

export enum Step {
  PaymentNote = 'payment_note',
  PhoneNumber = 'phone_number',
  PersonalInfo = 'personal_info',
}

interface Props {
  total: number;
  onProgress?: (complete: boolean) => void;
}

export default function ConfirmOrderStepper(props: Props): ReactElement {
  const { t } = useTranslation(null, { keyPrefix: 'Components:DineIn:Stepper:ConfirmOrderStepper' });

  const { total, ...rest } = props

  const { profile } = useSelector((state: StoreInterface) => state.profile);

  const { paymentNoteAccepted } = useDineInFlow();

  const [loading, setLoading] = useState(false);

  function onLoginSucceed() {
    setLoading(true);
    setTimeout(() => window.location.reload(), 1000);
  }

  const steps = useMemo<StepItem<Step>[]>(
    () => [
      {
        id: Step.PaymentNote,
        is_required: total > 0,
        is_collapsable: true,
        is_complete: paymentNoteAccepted,
        title: (isActive: boolean) => t('steps.payment_note.title'),
        render: (openFirstIncompleteStep) => (
          <PaymentNoteStep
            onConfirm={() => {
              ReduxActions.dispatch(actionCreators.dineInFlow.setPaymentNoteAccepted(true));
              openFirstIncompleteStep();
            }}
          />
        )
      },
      {
        id: Step.PhoneNumber,
        is_required: true,
        is_collapsable: true,
        is_complete: !!profile?.phone_number,
        title: (isActive: boolean) => isActive ? t('steps.phone_number.title') : (profile?.phone_number ?? t('steps.phone_number.title')),
        render: (openFirstIncompleteStep) => (
          <div>
            <p className="mini">{t('steps.phone_number.subtext')}</p>
            <PhoneNumberStep
              onLoginSucceed={onLoginSucceed}
              onProceedWithoutLogin={openFirstIncompleteStep}
            />
          </div>
        )
      },
      {
        id: Step.PersonalInfo,
        is_required: true,
        is_collapsable: true,
        is_complete: !!profile?.first_name,
        title: (isActive: boolean) => isActive
          ? t('steps.personal_info.title')
          : (profile?.first_name ?? t('steps.personal_info.title')),
        render: (openFirstIncompleteStep) => (
          <PersonalInfoStep
            fields={[Fields.FirstName]}
            onContinue={openFirstIncompleteStep}
          />
        )
      },
    ],
    [
      paymentNoteAccepted,
      total,
      profile?.phone_number,
      profile?.first_name,
    ],
  );

  return (
    <>
      <Stepper steps={steps} {...rest} />
      {loading && <LoadingPage />}
    </>
  );
}