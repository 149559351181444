import { createGlobalStyle } from "styled-components";
import Theme from "../components-library/Theme";

const getFontSize = (type, font) => {
  switch (type) {
    case Theme.dimension.breakPoints.sm:
      return padPixel(font);
    case Theme.dimension.breakPoints.md:
      return padPixel(font);
    default:
      return padPixel(font);
  }
};

const padPixel = (type) => {
  return type + "px";
};

const GlobalStyle = createGlobalStyle`
    cursor:'pointer'; 
    body, html{
      overflow-x: clip;
      overflow: visible;
    }
    body {
        margin: 0;
        padding: 0;
        background-color: #FBFBFC;
        font-family: Inter;
        -webkit-tap-highlight-color: transparent;
        -webkit-touch-callout: none;
        outline: none !important;
        position: relative;
    }
    header {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 50;
    }
    input {
      outline-width: 0px;
      font-size:  ${getFontSize(null, Theme.fonts.fontSizes.p)}; 
      font-weight: ${Theme.fonts.fontWeights.regular};
      line-height: 140%;
      background-color: transparent;
      outline: none !important;
    }
    textarea {
      outline-width: 0px;
      font-size:  ${getFontSize(null, Theme.fonts.fontSizes.p)}; 
      background-color: transparent;
      outline: none !important;
      resize: none;
    }
    select {
      outline: none !important;
    }
    button {
      user-select: none;
      position:relative;
    }
    button:after {
      content:'';
      position:absolute;
      top:-5px; bottom:-5px; 
      left:-5px; right:-5px; 
    }
    label {
      user-select: none;
      position:relative;
    }
    label:after {
      content:'';
      position:absolute;
      top:-2px; bottom:-2px; 
      left:-2px; right:-2px; 
    }
    a {
      user-select: none;
      position:relative;
    }
    a:after {
      content:'';
      position:absolute;
      top:-2px; bottom:-2px; 
      left:-2px; right:-2px; 
    }
    .bg-scrolling-element-when-modal-active {
      /* when modal active */
      touch-action: none;
      -webkit-overflow-scrolling: none;
      overflow: hidden;
      /* Other browsers */
      overscroll-behavior: none;
  }
    h1 {
      font-size: ${getFontSize(null, Theme.fonts.fontSizes.h1)};
      font-weight: ${Theme.fonts.fontWeights.medium};
      line-height: 48px;
      margin-bottom: 0px;
      padding-bottom: 0px;
  }
    h2 {
        font-size: ${getFontSize(null, Theme.fonts.fontSizes.h2)};
        font-weight: ${Theme.fonts.fontWeights.medium};
        line-height: 40px;
        margin-bottom: 0px;
        padding-bottom: 0px;
    }
    h3 {
        font-size:  ${getFontSize(null, Theme.fonts.fontSizes.h3)};
        font-weight: ${Theme.fonts.fontWeights.medium};
        line-height: 34px;
        margin-bottom: 0px;
        padding-bottom: 0px;
    }
    h4 {
        font-size:  ${getFontSize(null, Theme.fonts.fontSizes.h4)};
        font-weight: ${Theme.fonts.fontWeights.semiBold};
        line-height: 24px;
        margin-bottom: 0px;
        padding-bottom: 0px;
    }
    h5 {
        font-size:  ${getFontSize(null, Theme.fonts.fontSizes.h5)}; 
        font-weight: ${Theme.fonts.fontWeights.semiBold};
        line-height: 140%;
        margin-bottom: 0px;
        padding-bottom: 0px;
    }
    h6 {
      font-size:  ${getFontSize(null, Theme.fonts.fontSizes.h6)}; 
      font-weight: ${Theme.fonts.fontWeights.semiBold};
      line-height: 140%;
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
    p {
        font-size:  ${getFontSize(null, Theme.fonts.fontSizes.p)}; 
        font-weight: ${Theme.fonts.fontWeights.regular};
        line-height: 140%;
        margin-bottom: 0px;
        padding-bottom: 0px;
    }
    .mini {
      font-size:  ${getFontSize(null, Theme.fonts.fontSizes.mini)}; 
      font-weight: ${Theme.fonts.fontWeights.regular};
      line-height: 140%;
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
    .tiny {
      font-size:  ${getFontSize(null, Theme.fonts.fontSizes.tiny)}; 
      font-weight: ${Theme.fonts.fontWeights.regular};
      line-height: 140%;
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
    .nano {
      font-size:  ${getFontSize(null, Theme.fonts.fontSizes.nano)}; 
      font-weight: ${Theme.fonts.fontWeights.regular};
      line-height: 140%;
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
    .bold {
      font-weight: ${Theme.fonts.fontWeights.bold};
    }
    .semibold {
      font-weight: ${Theme.fonts.fontWeights.semiBold};
    }
    .medium {
      font-weight: ${Theme.fonts.fontWeights.medium};
    }
    .regular {
      font-weight: ${Theme.fonts.fontWeights.regular};
    }
    .touchable-highlight {
      -webkit-tap-highlight-color: white;
    }
`;

export default GlobalStyle;
