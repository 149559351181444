export default {
  header: {
    rate_us: 'Rate us & get',
    cashback: '{{value}} points = {{moneyValue}}',
    earn_points_get_free_rewards: "{{count}} points = rewards",
    upload_google_review_get_free_rewards: "Review = rewards",
  },
  cashback_reward: '{{value}} points ({{moneyValue}})',
  cashback_benefits: {
    text: 'Welcome to our loyalty program. Keep collecting points on every order and use them to get discounts. 🤩',
    title: 'Benefits',
    earn: '✅ Earn {{value}} points for every {{amount}} spent.',
    redeem: '✅ Redeem your points to get up to 100% off on your next order.',
    max_spending_limit: '✅ Maximum discount of {{value}} per order.',
    point_lifetime: '✅ Each loyalty point is valid for {{value}} days.',
  },
  enter_phone_number: {
    title: 'Phone number',
    description: 'We need your phone number, so that we can make sure you are the right person that we reward',
    place_holder: 'Phone',
  },
  button: {
    continue: "Continue",
  },
  product_suggestions: {
    loyalty: {
      title: "Points for discounts & free food",
      description: "Earn points for discounts & free food",
    },
    googleReview: {
      title: "Rate us & get",
    },
  },
};
