import i18n from "i18next";
import { PaymentOptionPropertiesInterface } from "./Interfaces";
import Theme from "../../components-library/Theme";

const TranslationPath = "Services:Exports:Constants";

export const APPLE_STORE_APP_LINK =
  "https://apps.apple.com/app/foodamigos-rabatte-pr%C3%A4mien/id1571297875";

export const GOOGLE_PLAY_STORE_APP_LINK =
  "https://play.google.com/store/apps/details?id=com.joinamigo.app";

export const AVAILABLE_LANGUAGES = [
  {
    language: "de",
    languageName: "German",
  },
  {
    language: "en",
    languageName: "English",
  },
];

export enum OTP_DELIVERY_CHANNELS {
  SMS = 'sms',
  EMAIL = 'email',
}

export enum ORDERS_PAUSED_REASON {
  MANUAL = 'manual',
  INACTIVITY = 'inactivity',
}

export enum PROMOTION_TYPES {
  REFERRAL_CAMPAIGN = 1,
  GOOGLE_REVIEW_REWARD = 2,
  LOYALTY_PROGRAM = 3,
}

export enum DISCOUNT_TYPES {
  NOT_DETERMINED = 0,
  DISCOUNT_PERCENTAGE = 1,
  ONE_PLUS_ONE = 2,
  BUY_ONE_GET_ONE = 3,
}

export enum DEAL_TYPES {
  REFERRAL_CODE = 1,
}

export enum EventHelperTypes {
  LOG_OUT = "log_out",
  DISPLAY_INFO_MODAL = "display_info_modal",
  SHOW_MENU_ITEMS_MODAL = "show_menu_modal",
  TOGGLE_PAYMENT_METHOD = "toggle_payment_method",
  NO_INTERNET = "no_internet",
}

export enum COUPON_TYPES {
  REWARD = 1,
  CASHBACK = 2,
  PROMO_CODE = 3,
}

export enum PROMO_CODE_DISCOUNT_TYPES {
  FIXED_AMOUNT = 'fixed_amount',
  PERCENTAGE = 'percentage',
}

export enum ORDER_METHODS {
  PICKUP = 'pickup',
  DELIVERY = 'delivery',
  DINE_IN = 'dine_in',
  ROOM_SERVICE = 'room_service',
}

export enum ORDER_PAYMENT_STATUSES {
  PENDING = 'pending',
  PAID = 'paid',
  PARTIALLY_REFUNDED = 'partially_refunded',
  REFUNDED = 'refunded',
}

export enum ORDER_FULFILLMENT_STATUSES {
  CREATED = 'created',
  REVIEWED = 'reviewed',
  ACCEPTED = 'accepted',
  PREPARING = 'preparing',
  READY = 'ready',
  ON_DELIVERY = 'on_delivery',
  PICKED = 'picked',
  REJECTED = 'rejected',
}

export enum ORDER_REJECTED_REASON {
  AUTOMATIC = 'auto',
  CLOSING_SOON = 'closing_soon',
  ITEM_UNAVAILABLE = 'item_unavailable',
  ORDER_TOO_BIG = 'order_too_big',
  CUSTOMER_MISTAKE = 'customer_mistake',
  OTHER = 'other',
}

export enum FLOWS {
  DEFAULT = "default",
  DINE_IN = "dine-in",
}

export enum SCREENS {
  NOT_FOUND_SCREEN = "404",
  HOME_SCREEN = "/",
  DINE_IN = "dine-in",
  DINE_IN_WELCOME = "dine-in/welcome",
  MENU = "menu",
  TABLE = "table",
  CATEGORY_MENU = "category-menu",
  CONFIRM_ORDER = "confirm-order",
  SPLIT_BILL = "split-bill",
  CHECKOUT = "checkout",
  BASKET = "basket",
  ORDER_DETAILS = "order-details",
  PAYMENT_SUCCESSFUL = "payment-successful",
  TERMS_AND_CONDITIONS = "terms-and-conditions",
  PRIVACY_POLICY = "privacy-policy",
  COOKIE_POLICY = "cookie-policy",
  IMPRESSUM = "impressum",
  UPLOAD_GOOGLE_REVIEW_SCREEN = "upload-google-review",
  REWARD_DETAILS_SCREEN = "reward-details",
}

export enum PaymentMethod {
  Cash = 'cash',
  ApplePay = 'apple_pay',
  GooglePay = 'google_pay',
  PaypalStandalone = 'paypal_standalone',
  Card = 'card',
  Paypal = 'paypal',
  Sofort = 'sofort',
  Giropay = 'giropay',
  SepaDebit = 'sepa_debit',
  BacsDebit = 'bacs_debit',
}

export const PAYMENT_METHODS = (): PaymentOptionPropertiesInterface[] => [
  {
    id: 1,
    payment_method_id: PaymentMethod.Card,
    name: "Credit Card",
    display_name: "Credit Card",
    is_default: false,
    details: "",
    paymentMethodSaved: false,
  },
  {
    id: 3,
    payment_method_id: PaymentMethod.ApplePay,
    name: "Apple Pay",
    display_name: "Apple Pay",
    is_default: false,
    details: "",
    paymentMethodSaved: false,
  },
  {
    id: 4,
    payment_method_id: PaymentMethod.GooglePay,
    name: "Google Pay",
    display_name: "Google Pay",
    is_default: false,
    details: "",
    paymentMethodSaved: false,
  },
  {
    id: 6,
    payment_method_id: PaymentMethod.Giropay,
    name: "Giro Pay",
    display_name: i18n.t(TranslationPath + ".payment_methods.giropay"),
    is_default: false,
    details: "",
    paymentMethodSaved: false,
  },
  {
    id: 5,
    payment_method_id: PaymentMethod.Sofort,
    name: "Sofort",
    display_name: i18n.t(TranslationPath + ".payment_methods.sofort"),
    is_default: false,
    details: "",
    paymentMethodSaved: false,
  },
  // {
  //   id: 2,
  //   payment_method_id: PaymentMethod.PaypalStandalone,
  //   name: "PayPal",
  //   display_name: "PayPal",
  //   is_default: false,
  //   details: "",
  //   paymentMethodSaved: false,
  // },
  {
    id: 2,
    payment_method_id: PaymentMethod.Paypal,
    name: "PayPal",
    display_name: "PayPal",
    is_default: false,
    details: "",
    paymentMethodSaved: false,
  },
  // {
  //   id: 7,
  //   payment_method_id: PaymentMethod.SepaDebit,
  //   name: "Sepa Debit",
  //   display_name: i18n.t(TranslationPath + ".payment_methods.iban"),
  //   is_default: false,
  //   details: "",
  //   paymentMethodSaved: false,
  // },
  {
    id: 8,
    payment_method_id: PaymentMethod.Cash,
    name: "Cash",
    display_name: i18n.t(TranslationPath + ".payment_methods.cash"),
    is_default: false,
    details: "",
    paymentMethodSaved: false,
  },
];

export enum API_ROUTES {
  INITIAL_DATA = "/initial-data",
}

export enum PROMO_CODE_TYPES {
  ONE_TIME_PROMO_CODE = 'single_use',
  MULTI_PROMO_CODE = 'multi_use',
}

export enum PUSHER_EVENTS {
  ORDER_UPDATED = "order.updated",
  ORDER_PAID = "order.paid",
  ORDER_FAILED = "order.failed",
  TRANSACTION_PAID = "transaction.paid",
  PARTY_CREATED = "party.created",
  PARTY_UPDATED = "party.updated",
  PARTY_DELETED = "party.deleted",
  PARTY_TABLE_UPDATED = "party_table.updated",
  PARTY_MEMBERSHIP_CREATED = "party_membership.created",
  PARTY_MEMBERSHIP_DELETED = "party_membership.deleted",
  PARTY_ORDER_CREATED = "party_order.created",
  REFUND_CREATED = "refund.created",
  SUB_ORDER_CANCELLED = "sub_order.cancelled",
  ORDERS_PAUSED = "orders.paused",
  ORDERS_UNPAUSED = "orders.unpaused",
}

export enum PAYMENT_ERROR_RESPONSE_STATUS {
  PAYMENT_METHOD_AUTHORIZATION_REQUIRED_CODE = 402,
}

export enum SCREEN_TYPES {
  DESKTOP = "Desktop",
  TABLET = "Tablet",
  MOBILE = "Mobile",
}

export enum APP_STATE_REDUCER_TYPES {
  TOGGLE_LOGIN_MODAL = "toggle_login_modal",
  TOGGLE_INFO_MODAL = "toggle_info_modal",
  TOGGLE_ALL_ORDERS_PAID_MODAL = "toggle_all_orders_paid_modal",
  TOGGLE_COMBO_UPSELL_MODAL = "toggle_combo_upsell_modal",
  TOGGLE_MENU_ITEM_MODAL = "toggle_menu_item_modal",
  TOGGLE_COMBO_MODAL = "toggle_combo_modal",
  SAVE_MENU = "save_menu",
  TOGGLE_ORDER_METHOD_MODAL = "toggle_order_method_modal",
  TOGGLE_CHANGE_TABLE_PARTY_MODAL = "toggle_change_table_party_modal",
  TOGGLE_ORDERS_DISABLED_MODAL = "toggle_orders_disabled_modal",
  TOGGLE_FREE_PRODUCTS_MODAL = "toggle_free_products_modal",
}

export enum STRIPE_PROVIDER_STATE_REDUCER_TYPES {
  TOGGLE_LOADING = "toggle_loading",
  SET_OPTIONS = "set_options",
  SET_PAYMENT_METHOD = "set_payment_method",
}

export enum MODAL_SIZES {
  XS = "lg:w-3/12 w-screen mx-small",
  SM = "lg:w-4/12 w-screen",
  MD = "lg:w-1/2 w-screen",
  LG = "lg:w-8/12 w-screen",
  XL = "lg:w-5/6 w-screen",
}

export enum MODAL_POSITION {
  TOP = "top",
  CENTER = "center",
}

export enum ERROR_CODES {
  ORDER_TIME_NOT_AVAILABLE = "order_time_not_available",
  PICKUP_CLOSED = "pickup_closed",
  DELIVERY_CLOSED = "delivery_closed",
  ITEMS_OUT_OF_STOCK = "items_out_of_stock",
  DINE_IN_CLOSED = "dine_in_closed",
  PROMO_CODE_INVALID = "promo_code_invalid",
  PROMO_CODE_THRESHOLD_NOT_REACHED = "promo_code_usage_threshold_not_reached",
  ADDRESS_OUTSIDE_DELIVERY_ZONE = 'address_outside_delivery_zone',
  NO_DELIVERY_QUOTES_AVAILABLE = 'no_delivery_quotes_available',
  ADDRESS_NOT_FOUND = "address_not_found",
  CARD_EXCEPTION = 'card_exception',
  ORDER_FULFILLMENT_FAILED = 'order_fulfillment_failed',
}

export enum PUSHER_CONNECTION_STATES {
  INITIALIZED = "initialized",
  CONNECTING = "connecting",
  CONNECTED = "connected",
  UNAVAILABLE = "unavailable",
  FAILED = "failed",
  DISCONNECTED = "disconnected",
}

export enum DELIVERY_ADDRESS_VALIDATION_ERRORS {
  NO_ERROR = 0,
  LOCATION_NOT_FOUND = 1,
  DELIVERY_ADDRESS_NOT_IN_DELIVERY_AREA = 2,
}

export enum GOOGLE_REVIEW_STATUS_TYPES {
  UNDETERMINED = 0,
  IN_REVIEW = 1,
  APPROVED = 2,
  REJECTED = 3,
}

export enum ENVIRONMENTS {
  DEV = "Development",
  STAGING = "Staging",
  PROD = "Production",
}

export enum ACHIEVEMENT_TYPES {
  FIRST_LOYALTY_POINT_EARNED = 1,
}

export enum REWARD_ACCOUNT_OPERATION_SOURCE {
  INITIAL_BALANCE = 0,
  IN_APP_PURCHASE = 1,
  LOYALTY_TABLET = 2,
  IN_APP_LOYALTY_PROGRAM = 3,
  WEB_ORDERS_LOYALTY_PROGRAM = 4,
  GOOGLE_REVIEW_REWARD = 5,
  REFERRAL_CAMPAIGN_REWARD = 6,
  REFUND = 7,
  STAMP_CARD = 8,
}

export enum REDUX_STORE_SLICES {
  APP_STATUS = "appStatus",
  DINE_IN_FLOW = "dineInFlow",
  EVENT_HELPER = "eventHelper",
  INITIAL_DATA = "initialData",
  ORDER_BASKET = "orderBasket",
  BASKET = "basket",
  ORDER = "order",
  PROFILE = "profile",
  PAYMENT_OPTIONS = "paymentOptions",
  PRODUCT_LIKES = "productLikes",
}

export enum DELIVERY_ZONE_GEOMETRY_TYPES {
  CIRCLE = "circle",
  POLYGON = "polygon",
  ZIP = "zip",
}

export const PRODUCT_HEIGHT = 165;

export const ORDER_PRODUCT_HEIGHT = 130;

export enum PRODUCT_IDENTIFIERS {
  VEGAN = "vgn",
  VEGETARIAN = "vgt",
  GLUTEN_FREE = "gf",
  DAIRY_FREE = "df",
  HALAL = "hal",
  SPICY = "h&s",
}

export enum ProductType {
  Product = 'product',
  Combo = 'combo',
}

export enum CategoryType {
  Default = 'default',
  Favourites = 'favourites',
  FreeItems = 'free_items',
  Combos = 'combos',
  MostPopular = 'most_popular',
}

export enum TaxBehaviour {
  Inclusive = 'inclusive',
  Exclusive = 'exclusive',
}

export enum RoomServiceLocationType {
  RoomService = 'room_service',
  TableService = 'table_service',
}

export enum SmartPricingRuleModifierType {
  Absolute = 'absolute',
  FixedValue = 'fixed_value',
  Percentage = 'percentage',
}

export enum DeliveryProvider {
  Standalone = 'standalone',
  FirstDelivery = 'first_delivery',
}

export enum DeliveryStatus {
   PendingAssign = 'pending_assign',
   PendingMerchant = 'pending_merchant',
   Scheduled = 'scheduled',
   RunnerAssigned = 'runner_assigned',
   EnRoutePickup = 'en_route_pickup',
   ArrivedPickup = 'arrived_pickup',
   PickedUp = 'picked_up',
   EnRouteDropoff = 'en_route_dropoff',
   ArrivedDropoff = 'arrived_dropoff',
   Completed = 'completed',
   Cancelled = 'cancelled',
}

export enum DropoffAction {
  RingDoorBell = 'ring_door_bell',
  Sms = 'sms',
}