import { ProductComboInterface, ProductInterface } from "../../services/exports/Interfaces";
import {PRODUCT_IDENTIFIERS} from "../../services/exports/Constants";
import collect from "collect.js";
import {useTranslation} from "react-i18next";

export default function useProductIdentifiers(product: ProductInterface|ProductComboInterface) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Hooks:Menu:UseProductIdentifiers' });

  const identifiers = product?.identifiers ?? [];

  const isVegan = identifiers?.includes(PRODUCT_IDENTIFIERS.VEGAN);
  const isVegetarian = identifiers?.includes(PRODUCT_IDENTIFIERS.VEGETARIAN);

  // Vegan overrides vegetarian
  const filtered = collect(identifiers)
    // @ts-ignore
    .when(
      isVegan && isVegetarian,
      (collection) => collection.reject(
        (item: string) => item === PRODUCT_IDENTIFIERS.VEGETARIAN,
      )
    );

  return {
    identifiers,
    isVegan,
    isVegetarian,
    // @ts-ignore
    names: filtered.map((item) => t(`${item}.name`, { defaultValue: item })).whereNotNull(),
    // @ts-ignore
    emojis: filtered.map((item) => t(`${item}.emoji`, { defaultValue: null })).whereNotNull(),
  };
}