export default {
  min: {
    title: 'Lieferung an deinen Standort: Benötigter Betrag, um den Mindestbestellwert zu erreichen. (Zwischensumme abz. Lieferkosten & Trinkgeld)',
    description: 'Leider kannst du noch nicht bestellen. {{name}} liefert erst ab einem Mindestbestellwert von {{threshold}} (abz. Lieferkosten & Trinkgeld)',
  },
  max: {
    title: 'Maximaler Bestellwert für Lieferung erreicht (Gesamtbetrag exkl. Lieferkosten)',
    description: 'Deine Bestellung hat den maximal erlaubten Wert von {{threshold}} für Online-Lieferungen erreicht. Für Bestellungen, die diesen Betrag überschreiten, kontaktiere uns bitte direkt, damit wir die Lieferung für dich organisieren können. Danke für dein Verständnis!',
  },
};
