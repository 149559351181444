import {ORDER_METHODS, RoomServiceLocationType} from "../../../../services/exports/Constants";
import {ValidationErrors} from "../../../../modals/OrderMethodModal";

export default {
  header: {
    [ORDER_METHODS.ROOM_SERVICE]: {
      [RoomServiceLocationType.RoomService]: 'Order on site',
      [RoomServiceLocationType.TableService]: 'Order on site',
      both: 'Order on site',
    },
    [ORDER_METHODS.PICKUP]: 'Order for Pickup',
    [ORDER_METHODS.DELIVERY]: 'Order for Delivery',
  },
  order_methods: {
    [ORDER_METHODS.ROOM_SERVICE]: {
      [RoomServiceLocationType.RoomService]: 'On site',
      [RoomServiceLocationType.TableService]: 'On site',
      both: 'On site',
    },
    [ORDER_METHODS.PICKUP]: 'Pickup',
    [ORDER_METHODS.DELIVERY]: 'Delivery',
  },
  text_inputs: {
    room_service_details: {
      placeholder: {
        [RoomServiceLocationType.RoomService]: 'Enter room number',
        [RoomServiceLocationType.TableService]: 'Enter table number',
      },
    },
    address: {
      placeholder: "Search your address",
    },
    street_name: {
      placeholder: "Street",
    },
    street_number: {
      placeholder: "Street Number",
    },
    city: {
      placeholder: "City",
    },
    zip: {
      placeholder: "ZIP",
    },
  },
  time_options: {
    asap: {
      title: "ASAP",
      description: "Ready in {{time}} min",
    },
    scheduled: {
      title: "Schedule for later",
    },
  },
  time_drop_down: {
    today: "Today",
  },

  room_service: {
    location: 'Select location',
  },

  pickup: {
    address: 'Pickup address',
    when: "When?",
  },

  delivery: {
    where: "Where to?",
    when: "When?",
    address: "Your address",
    delivery_area: 'Our delivery radius',
  },

  order: {
    instructions: 'Order Instructions',
    placeholder: 'Enter order instructions...',
  },

  custom_button: {
    go_to_menu: "Go To Menu",
    start_order: "Start Order",
  },

  labels: {
    pickup: 'Online Pickup',
    delivery: 'Delivery',
    pickup_time: 'pickup time',
    delivery_time: 'delivery time',
  },

  error_messages: {
    [ValidationErrors.address_not_found]: 'Sorry, we couldn’t find that address. Please try again by entering first the street in the search address field.',
    [ValidationErrors.address_not_serviceable]: 'Your address is outside of our delivery zone. Would you like to change your address or switch to order for pickup? 😊',
    [ValidationErrors.no_drivers_available]: 'Sorry, we can’t deliver at the moment. Would you like to order for pickup? 😊',

    room_service_unavailable: 'We are sorry that we currently don’t offer on-site service.',
    pickup_unavailable: 'We are sorry that we currently don’t offer pickup.',
    delivery_unavailable: 'We are sorry that we currently don’t offer delivery.',
    delivery_paused: 'We are sorry that we currently don’t offer delivery.',
    outside_pickup_times: 'We don’t offer pickup at this time. Our pickup times:',
    outside_delivery_times: 'We don’t offer delivery at this time. Our delivery times:',
    orders_paused_inactivity:
      "Due to overwhelming demand we are not able to accept more online orders right now. Please order in store or try again in 30 minutes.",
    orders_paused_manual_end_of_day:
      "Sorry, we are closed for the rest of the day.",
    orders_paused_manual_until_time:
      "Due to overwhelming demand we are not able to accept more online orders right now. Please order in store or try again from {{pausedUntil}}",
    company_closed: "Unfortunately, we are not open at this time.",
    pickup_closed: "We accept pickup orders until {{time}} because our preparation time is {{preparationTime}} minutes.",
    delivery_closed: "We accept delivery orders until {{time}} because our delivery time is {{preparationTime}} minutes.",
    live_orders_off:
      "We’re sorry, we can currently not accept more online orders. Thank you for your understanding.",
    orders_unavailable: '{{type}} is not available at this time. The next possible {{typeTime}} is {{day}} {{time}}.'
  },
};
