export default {
  sections: {
    use_cashback: {
      min_spending_limit_not_reached: 'Löse deine Punkte ein, sobald du min. {{value}} Punkte hast',
      balance: 'Nutze {{value}}p ({{moneyValue}})',
      max_spending_limit: 'Maximal {{value}} Punkte pro Bestellung',
    },
    expected_cashback: 'Du verdienst <highlight>{{value}} Punkte</highlight> mit dieser Bestellung',
  },
  states: {
    on: 'Ja',
    off: 'Nein',
  },
  buttons: {
    log_in: 'Einloggen, um mit Punkten zu zahlen',
  },
};