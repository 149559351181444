import useTheme from "../../hooks/ui/useTheme";

interface Props {
  className?: string;
  style?: Object;
  color?: string;
}

export default function StarIcon(props: Props) {
  const { className, color, style } = props;
  const theme = useTheme();

  return (
    <svg
      className={className ?? "h-4 w-4"}
      viewBox="0 0 43 42"
      fill="none"
      style={style}
    >
      <path
        d="M20.7806 34.7307C21.2255 34.4622 21.7825 34.4622 22.2274 34.7307L33.0834 41.2829C34.1436 41.9228 35.4516 40.9723 35.1702 39.7663L32.2893 27.417C32.1712 26.9109 32.3432 26.381 32.736 26.0408L42.3294 17.73C43.2654 16.9191 42.765 15.3816 41.531 15.2769L28.9019 14.2054C28.3845 14.1615 27.9338 13.8349 27.7312 13.3568L22.793 1.70538C22.3106 0.567142 20.6974 0.56714 20.215 1.70538L15.2768 13.3568C15.0741 13.8349 14.6235 14.1615 14.1061 14.2054L1.47694 15.2769C0.242942 15.3816 -0.25743 16.9191 0.678607 17.73L10.272 26.0408C10.6648 26.381 10.8367 26.9109 10.7187 27.417L7.83777 39.7663C7.55642 40.9723 8.86435 41.9228 9.92459 41.2829L20.7806 34.7307Z"
        fill={color ?? theme.color.background.inkGreen.green_1}
      />
    </svg>
  );
}
