import {OpeningHoursInterface} from "../exports/Interfaces";
import collect, {Collection} from "collect.js";
import PickUpTimesResource from "../resources/PickUpTimesResource";
import moment, {Moment} from "moment";
import {WorkScheduleUnitResource} from "../resources/WorkScheduleUnitResource";

class WorkScheduleHelpers {
  isOpen = (schedule: OpeningHoursInterface[], time?: Moment|string|null): boolean => {
    const datetime = moment(time ?? undefined);

    return !!this
      .getCurrentDaySchedule(schedule, time)
      .first(
        (item: OpeningHoursInterface) => datetime.isBetween(
          PickUpTimesResource.createDate(item.open, time),
          PickUpTimesResource.createDate(item.close, time)
        )
      )
  };

  getCurrentDaySchedule = (schedule: OpeningHoursInterface[], time?: Moment|string|null): Collection<OpeningHoursInterface> => {
    const datetime = moment(time ?? undefined);

    return collect(schedule).filter(
      (item) => new WorkScheduleUnitResource(item).isValid(datetime)
    );
  };


  formatSchedule = (schedule: OpeningHoursInterface[], formatter: (item: OpeningHoursInterface) => string): string => {
    return collect(schedule)
      .filter((item) => {
        const scheduleUnit = new WorkScheduleUnitResource(item);

        return scheduleUnit.opensOnSameDay()
          || (scheduleUnit.openedOnPreviousDay() && scheduleUnit.closesOnSameDay());
      })
      .map((item) => formatter(item))
      .unique()
      .implode(', ');
  }
}

export default new WorkScheduleHelpers();