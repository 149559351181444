import useAppFocus from "../../../hooks/global/useAppFocus";

interface Props {
  children: any;
}

export default function AppFocusObserver(props: Props): JSX.Element {
  const { children } = props;

  useAppFocus();

  return children;
}