import React from "react";
import useTheme from "../../hooks/ui/useTheme";

interface Props {
  className?: string;
  style?: Object;
  color?: string;
}

export default function SideBarIcon(props: Props) {
  const { className, color, style } = props;
  const theme = useTheme();
  return (
    <svg
      className={className ?? "h-6 w-6"}
      viewBox="0 0 14 10"
      fill="none"
      style={style}
    >
      <path
        d="M0.945312 9.02634V8.00547H13.1958V9.02634H0.945312ZM0.945312 5.4533V4.43242H13.1958V5.4533H0.945312ZM0.945312 1.88025V0.859375H13.1958V1.88025H0.945312Z"
        fill={color ?? theme.color.brand.inkGrey.grey_3}
      />
    </svg>
  );
}
