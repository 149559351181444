import React from "react";
import useTheme from "../../hooks/ui/useTheme";

interface Props {
  className?: string;
  style?: Object;
  color?: string;
}

export default function PencilIcon(props: Props) {
  const { className, color, style } = props;
  const theme = useTheme();

  return (
    <svg
      className={className ?? "h-4 w-4"}
      viewBox="0 0 16 17"
      fill="none"
      style={style}
    >
      <path
        d="M1.30969 15.0399H2.27012L11.94 5.37007L10.9795 4.40964L1.30969 14.0795V15.0399ZM14.7121 4.43146L11.9181 1.63747L12.8349 0.720687C13.0823 0.473302 13.3879 0.349609 13.7517 0.349609C14.1155 0.349609 14.4211 0.473302 14.6685 0.720687L15.6289 1.68112C15.8763 1.92851 16 2.2341 16 2.5979C16 2.96171 15.8763 3.2673 15.6289 3.51468L14.7121 4.43146ZM13.7954 5.34824L2.794 16.3496H0V13.5556L11.0014 2.55425L13.7954 5.34824ZM11.4598 4.88985L10.9795 4.40964L11.94 5.37007L11.4598 4.88985Z"
        fill={color ?? theme.color.text.default}
      />
    </svg>
  );
}
