import {ReactElement, useMemo, useState} from "react";
import PhoneNumberStep from "./PhoneNumberStep";
import PersonalInfoStep from "./PersonalInfoStep";
import ReceiptInfoStep from "./ReceiptInfoStep";
import {ORDER_METHODS, PaymentMethod} from "../../../services/exports/Constants";
import OrderInfoStep from "./OrderInfoStep";
import PaymentInfoStep from "./PaymentInfoStep";
import {useSelector} from "react-redux";
import {StoreInterface} from "../../../store/types";
import useDineInFlow from "../../../hooks/global/useDineInFlow";
import useAppMethods from "../../../hooks/utility/useAppMethods";
import {useTranslation} from "react-i18next";
import usePaymentMethods from "../../../hooks/payment/usePaymentMethods";
import LockIcon from "../../../assets/logo/LockIcon";
import useTheme from "../../../hooks/ui/useTheme";
import Stepper, { StepItem } from "../../stepper/Stepper";
import {PaymentOptionPropertiesInterface} from "../../../services/exports/Interfaces";
import LoadingPage from "../../loading/LoadingPage";
import useAuth from "../../../hooks/global/useAuth";

export enum Step {
  PhoneNumber = 'phone_number',
  PersonalInfo = 'personal_info',
  ReceiptInfo = 'receipt_info',
  OrderInfo = 'order_info',
  PaymentInfo = 'payment_info',
}

interface Props {
  total: number;
  paymentOption: PaymentOptionPropertiesInterface;
  onProgress?: (complete: boolean) => void;
}

export default function CheckoutStepper(props: Props): ReactElement {
  const { t } = useTranslation(null, { keyPrefix: 'Components:Checkout:Stepper:CheckoutStepper' });

  const { total, paymentOption, ...rest } = props;

  const { profile } = useSelector((state: StoreInterface) => state.profile);
  const { cached_order } = useSelector((state: StoreInterface) => state.order);
  const { company } = useSelector((store: StoreInterface) => store.initialData);

  const theme = useTheme();
  const { isLoggedIn } = useAuth();
  const { isDineIn } = useDineInFlow();
  const { toggleOrderMethodModal } = useAppMethods();
  const { isPaymentMethodDisabled } = usePaymentMethods();

  const [loading, setLoading] = useState(false);

  function onLoginSucceed() {
    setLoading(true);
    setTimeout(() => window.location.reload(), 1000);
  }

  const steps = useMemo<StepItem<Step>[]>(
    () => [
      {
        id: Step.PhoneNumber,
        is_required: true,
        is_collapsable: true,
        is_complete: !!profile?.phone_number,
        title: (isActive: boolean) => isActive ? t('steps.phone_number.title') : (profile?.phone_number ?? t('steps.phone_number.title')),
        render: (openFirstIncompleteStep) => (
          <PhoneNumberStep
            onLoginSucceed={onLoginSucceed}
            onProceedWithoutLogin={openFirstIncompleteStep}
          />
        )
      },
      {
        id: Step.PersonalInfo,
        is_required: !isDineIn,
        is_collapsable: true,
        is_complete: !!profile?.first_name
          && !!profile?.last_name
          && !!profile?.email,
        title: (isActive: boolean) => isActive
          ? t('steps.personal_info.title')
          : (profile?.first_name && profile?.last_name ? `${profile?.first_name} ${profile?.last_name}` : t('steps.personal_info.title')),
        render: (openFirstIncompleteStep) => <PersonalInfoStep onContinue={openFirstIncompleteStep} />
      },
      {
        id: Step.ReceiptInfo,
        is_required: isDineIn,
        is_collapsable: true,
        is_complete: total === 0 || profile?.should_receive_receipts !== null,
        title: (isActive: boolean) => profile?.should_receive_receipts ? t('steps.receipt_info.title_enabled') : t('steps.receipt_info.title_disabled'),
        render: (openFirstIncompleteStep) => <ReceiptInfoStep onContinue={openFirstIncompleteStep} />
      },
      {
        id: Step.OrderInfo,
        is_required: !isDineIn,
        is_collapsable: false,
        is_complete: cached_order?.method !== ORDER_METHODS.DELIVERY || (
          !!cached_order?.delivery_street_name
          && !!cached_order?.delivery_street_number
          && !!cached_order?.delivery_city
          && !!cached_order?.delivery_zip_code
        ),
        title: (isActive: boolean) => t('steps.order_info.title'),
        render: (openFirstIncompleteStep) => (
          <OrderInfoStep
            onClickInfo={() => toggleOrderMethodModal({
              openModal: true,
              buttonTitle: t('buttons.confirm'),
            })}
            onContinue={openFirstIncompleteStep}
          />
        )
      },
      {
        id: Step.PaymentInfo,
        is_required: total > 0,
        is_collapsable: !isLoggedIn,
        is_complete: !!paymentOption && !isPaymentMethodDisabled(paymentOption.payment_method_id),
        title: (isActive: boolean) => {
          if (isActive || !paymentOption) {
            return t('steps.payment_method.title');
          }

          return ({
            [PaymentMethod.Card]: `${paymentOption.display_name} ***${paymentOption.details}`,
            [PaymentMethod.SepaDebit]: `${paymentOption.display_name} ***${paymentOption.details}`,
            [PaymentMethod.Cash]: company?.has_in_store_card_payments ? t('steps.payment_method.titles.cash_or_card_in_store') : t('steps.payment_method.titles.cash'),
          })[paymentOption.payment_method_id] ?? paymentOption.display_name;
        },
        description: (isActive) => {
          if (paymentOption?.payment_method_id === PaymentMethod.Cash) {
            return (
              <p className="nano text-brand-text-grey">
                {t('steps.payment_method.descriptions.cash_payment')}
              </p>
            );
          }

          return (
            <div className="flex items-center">
              <LockIcon
                color={theme.color.text.grey}
                className="h-2 w-2 mr-tiny"
              />
              <p className="nano text-brand-text-grey">
                {t('steps.payment_method.descriptions.online_payment')}
              </p>
            </div>
          );
        },
        render: () => <PaymentInfoStep defaultPaymentMethod={paymentOption} />
      },
    ],
    [
      isDineIn,
      isLoggedIn,
      profile?.phone_number,
      profile?.first_name,
      profile?.last_name,
      profile?.email,
      total,
      profile?.should_receive_receipts,
      cached_order?.method,
      cached_order?.delivery_street_name,
      cached_order?.delivery_street_number,
      cached_order?.delivery_city,
      cached_order?.delivery_zip_code,
      paymentOption,
      isPaymentMethodDisabled,
      company?.has_in_store_card_payments,
    ],
  );

  return (
    <>
      <Stepper steps={steps} {...rest} />
      {loading && <LoadingPage />}
    </>
  );
}