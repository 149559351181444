export default {
  status: {
    open: 'Open',
    closed: 'Closed',
  },
  offline_until: "Offline until",
  online_orders_paused: "Online Orders Paused",
  online_orders_paused_until: "Online Orders Paused until",
  reopens: "Reopens",
  opens_today: "Opens Today",
  opens: "Opens",
  open: "Open",
  open_whole_day: 'Open whole day',
  outside_ordering_times: 'Outside ordering times',
};
