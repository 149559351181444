import {useEffect, useMemo} from "react";
import {ButtonTypes, PaymentRequestButtonInterface} from "../../services/exports/Interfaces";
import CustomButton from "../buttons/CustomButton";
import ApplePayIcon from "../../assets/logo/ApplePayIcon";
import Theme from "../Theme";
import GooglePayIcon from "../../assets/logo/GooglePayIcon";
import usePaymentRequest from "../../hooks/payment/usePaymentRequest";
import { useTranslation } from "react-i18next";
import useStripeHelpers from "../../hooks/payment/useStripeHelpers";
import {useSelector} from "react-redux";
import {StoreInterface} from "../../store/types";

function PaymentRequestButton(
  props: PaymentRequestButtonInterface
): JSX.Element {
  const { t } = useTranslation(null, { keyPrefix: 'Components:Payment:StripePaymentRequestButton' });

  const { amount, validate, confirmPayment, onStartPayment, onCancelPayment } = props;

  const { company } = useSelector((state: StoreInterface) => state.initialData);

  const { setPaymentMethod } = useStripeHelpers();

  useEffect(() => {
    validate && validate();
  }, []);

  const { paymentRequest, result } = usePaymentRequest({
    options: {
      country: company.country ?? "DE",
      currency: (company.currency ?? 'eur').toLowerCase() ?? 'eur',
      total: {
        label: t('title', { company: company.name }),
        amount: Number(((amount ?? 0) * 100).toFixed(0)),
      },
    },
    onPaymentMethod: ({ complete, paymentMethod, ...data }) => {
      new Promise((resolve) => {
        setPaymentMethod(paymentMethod);
        resolve(true);
      }).then(confirmPayment);
      complete("success");
    },
    onCancel: () => onCancelPayment && onCancelPayment(),
  });

  function openPaymentDialog(): void {
    onStartPayment && onStartPayment();
    paymentRequest.show();
  }

  function getTitle(): JSX.Element {
    function getIcon(): JSX.Element | null {
      if (result?.applePay) {
        return (
          <ApplePayIcon
            standard
            color={Theme.color.text.light}
            className="ml-[6px] mr-tiny h-7 w-12"
          />
        );
      }
      if (result?.googlePay) {
        return (
          <GooglePayIcon
            standard
            color={Theme.color.text.light}
            className="ml-[6px] mr-tiny h-5 w-12"
          />
        );
      }
      return null;
    }

    return (
      <div className="inline-flex items-center">
        {t("button.title")} {getIcon()}
      </div>
    );
  }

  return (
    <CustomButton
      title={getTitle()}
      onClick={openPaymentDialog}
      buttonType={ButtonTypes.secondary}
      loading={!paymentRequest}
    />
  );
}

export default function StripePaymentRequestButton(
  props: PaymentRequestButtonInterface
): JSX.Element | null {
  const { amount } = props;

  return useMemo(
    () =>
      amount > 0 && (
          <PaymentRequestButton {...props} />
      ),
    [amount]
  );
}
