import React from 'react';
import PageStyle from '../../../styles/pages/privacy-policy/DE';

export default function DE() {
    return (
        <div className="p-small">
            <PageStyle />
            <h1 className="c24 break-words" id="h.s22kexbih4st"><span className="c4 c16">DATENSCHUTZERKL&Auml;RUNG (&ldquo;Foodamigos GmbH&rdquo;, &ldquo;FoodAmigos&rdquo;)</span>
            </h1>
            <p className="c5"><span className="c6">FoodAmigos</span><span className="c3">&nbsp;verpflichtet sich, die Datenschutzrechte aller Besucher von (die &quot;</span><span
                className="c6">Website</span><span className="c3 c4">&quot;) zu wahren.</span></p>
            <p className="c5"><span className="c3">Diese Datenschutzerkl&auml;rung gilt f&uuml;r die Nutzung der Website und der von angebotenen Leistungen Foodamigos GmbH handelnd als FoodAmigos (&quot;</span><span
                className="c6">FoodAmigos</span><span className="c3">&quot;, &quot;</span><span
                className="c6">wir</span><span className="c3">&quot;, &quot;</span><span
                className="c6">uns</span><span className="c3">&quot; oder &quot;</span><span
                className="c6">unser</span><span className="c3">&quot;. FoodAmigos ist in Deutschland ein unter der HRB Nummer 26506 eingetragenes Unternehmen mit der eingetragenen Adresse Charlotte-von-Stein Strasse 2, 53177 Bonn, Deutschland. Diese Datenschutzerkl&auml;rung enth&auml;lt Hinweise zu der Art und Weise, wie wir personenbezogene Daten in &Uuml;bereinstimmung mit unseren Verpflichtungen gem&auml;&szlig; der Datenschutzgrundverordnung (Verordnung (EU) 2016/679) (&quot;</span><span
                className="c6">DSGVO</span><span className="c3">&quot;), dem Data Protection Act 2018 und allen anderen Gesetzen, die f&uuml;r uns in Bezug auf die Verarbeitung personenbezogener Daten gelten (zusammen als &quot;</span><span
                className="c6">Datenschutzgesetze</span><span className="c3">&quot; bezeichnet), verarbeiten. In dieser Datenschutzerkl&auml;rung haben die Begriffe &quot;</span><span
                className="c6">Verantwortlicher</span><span className="c3">&quot;, &quot;</span><span
                className="c6">personenbezogene Daten</span><span className="c3">&quot; und &quot;</span><span
                className="c6">Verarbeitung</span><span className="c3 c4">&quot; (und ihre Ableitungen) die Bedeutungen, die diesen Begriffen in den Datenschutzgesetzen zugewiesen werden.</span>
            </p>
            <p className="c5"><span className="c3 c4">Personenbezogene Daten sind im Allgemeinen solche Informationen, durch die eine Person individuell identifiziert werden kann, und Verarbeitung umfasst im Allgemeinen T&auml;tigkeiten, die im Zusammenhang mit Daten durchgef&uuml;hrt werden k&ouml;nnen, wie z. B. deren Erhebung, Verwendung, Speicherung und Offenlegung. Im Zusammenhang mit der Website sind wir der Verantwortliche f&uuml;r die Verarbeitung personenbezogener Daten.</span>
            </p>
            <p className="c5"><span className="c3 c4">Zweck dieser Datenschutzerkl&auml;rung ist es, unsere Datenverarbeitungst&auml;tigkeiten zu erl&auml;utern, einschlie&szlig;lich wie und warum wir personenbezogene Daten verarbeiten. Dar&uuml;ber hinaus beschreibt diese Datenschutzerkl&auml;rung unsere Pflichten und Verantwortlichkeiten in Bezug auf den Schutz personenbezogener Daten. Die Art und Weise, auf die wir personenbezogene Daten verarbeiten, entwickelt sich mit der Zeit weiter, und wir werden diese Datenschutzerkl&auml;rung gelegentlich aktualisieren, um &Auml;nderungen Rechnung zu tragen.</span>
            </p>
            <h2 className="c2" id="h.65cz1z2y6ty7"><span
                className="c12 c4">Von uns erhobene personenbezogene Daten</span></h2>
            <p className="c5"><span className="c3 c4">Wir erheben personenbezogene Daten von Ihnen, wenn Sie uns diese direkt zur Verf&uuml;gung stellen, wenn Dritte uns personenbezogene Daten von Ihnen zur Verf&uuml;gung stellen oder wenn personenbezogene Daten von Ihnen im Zusammenhang mit Ihrer Nutzung unserer Website automatisch erhoben werden. Wir geben Ihre personenbezogenen Daten soweit in dieser Datenschutzerkl&auml;rung nicht anders beschrieben nicht an Dritte weiter.</span>
            </p>
            <p className="c5"><span className="c3 c4">Beim Betrieb und der Pflege unserer Website erheben und verarbeiten wir die folgenden Informationen:</span>
            </p>
            <h3 className="c2" id="h.rgfsnchlo3l4"><span className="c17">Direkt von Ihnen erhobene personenbezogene Daten</span>
            </h3>
            <p className="c5"><span className="c3 c4">Wir erhalten personenbezogene Daten direkt von Ihnen, wenn Sie uns diese im Rahmen einer Essensbestellung zur Verf&uuml;gung stellen, wie zum Beispiel:</span>
            </p>
            <ul className="c11 lst-kix_db0zr17d8gnt-0 start">
                <li className="c5 c8 li-bullet-0"><span className="c3 c4">Name;</span></li>
                <li className="c5 c8 li-bullet-0"><span className="c3 c4">E-Mail-Adresse;</span></li>
                <li className="c5 c8 li-bullet-0"><span className="c3 c4">Postanschrift;</span></li>
                <li className="c5 c8 li-bullet-0"><span className="c3 c4">Telefonnummer;</span></li>
                <li className="c5 c8 li-bullet-0"><span className="c3 c4">Benutzername; und</span></li>
                <li className="c5 c8 li-bullet-0"><span className="c3 c4">Zahlungsdetails.</span></li>
            </ul>
            <p className="c5"><span className="c3 c4">Sie k&ouml;nnen im Rahmen Ihrer Essensbestellung auch Angaben machen, die auf Gesundheitsinformationen von Ihnen oder auf Ihre religi&ouml;se &Uuml;berzeugung hinweisen.</span>
            </p>
            <h3 className="c2" id="h.2wzja9lxpnjw"><span className="c17">Personenbezogene Daten, die wir automatisch erheben</span>
            </h3>
            <p className="c5"><span className="c3 c4">Einige personenbezogene Daten werden automatisch erhoben, wenn Sie die Website nutzen, wie zum Beispiel:</span>
            </p>
            <ul className="c11 lst-kix_obyyghjgpuql-0 start">
                <li className="c5 c8 li-bullet-0"><span className="c3 c4">IP-Adresse;</span></li>
                <li className="c5 c8 li-bullet-0"><span className="c3 c4">Ger&auml;tekennungen;</span></li>
                <li className="c5 c8 li-bullet-0"><span className="c3 c4">Browser-Informationen; und</span></li>
                <li className="c5 c8 li-bullet-0"><span className="c3">Cookie-Daten (wie in der </span><span
                    className="c3">Cookie-Richtlinie erl&auml;utert</span><span
                    className="c3 c4">).</span></li>
            </ul>
            <h2 className="c2" id="h.hr8eqpjgdoty"><span
                className="c12 c4">Wie wir personenbezogene Daten verwenden</span></h2>
            <p className="c5"><span className="c3 c4">Wir verarbeiten personenbezogene Daten, um unsere Leistungen zu erbringen, zu verbessern, zu erfassen und zu personalisieren, zum Beispiel verwenden wir personenbezogene Daten um:</span>
            </p>
            <ul className="c11 lst-kix_stipjsb61sk8-0 start">
                <li className="c5 c8 li-bullet-0"><span
                    className="c3 c4">Bestellungen abzuschlie&szlig;en, einschlie&szlig;lich der Lieferung oder Abholung von Essen;</span>
                </li>
                <li className="c5 c8 li-bullet-0"><span
                    className="c3 c4">Inhalte auf der Grundlage Ihrer Einstellungen zu personalisieren;</span>
                </li>
                <li className="c5 c8 li-bullet-0"><span
                    className="c3 c4">Sie entsprechend Ihrer Marketing-Einstellungen &uuml;ber unsere Angebote zu informieren;</span>
                </li>
                <li className="c5 c8 li-bullet-0"><span className="c3 c4">mit Ihnen zu kommunizieren;</span>
                </li>
                <li className="c5 c8 li-bullet-0"><span
                    className="c3 c4">vor betr&uuml;gerischen, illegalen oder sch&auml;dlichen Handlungen zu sch&uuml;tzen oder davon abzuhalten;</span>
                </li>
                <li className="c5 c8 li-bullet-0"><span
                    className="c3 c4">auf Benutzeranfragen zu antworten;</span></li>
                <li className="c5 c8 li-bullet-0"><span
                    className="c3 c4">Unterst&uuml;tzung und Hilfe zu leisten;</span>
                </li>
                <li className="c5 c8 li-bullet-0"><span className="c3 c4">unseren gesetzlichen oder vertraglichen Verpflichtungen nachzukommen;</span>
                </li>
                <li className="c5 c8 li-bullet-0"><span
                    className="c3 c4">unsere allgemeinen Gesch&auml;ftsbedingungen durchzusetzen; und</span>
                </li>
                <li className="c5 c8 li-bullet-0"><span className="c3 c4">Streitf&auml;lle zu l&ouml;sen.</span>
                </li>
            </ul>
            <h2 className="c2" id="h.5nunlxq5wata"><span
                className="c12 c4">Rechtsgrundlage f&uuml;r die Verarbeitung</span>
            </h2><a
            id="t.991700b8180921688e1cc9d9b8703f878b0e6c03"></a><a id="t.0"></a>
            <table className="c21">
                <tr className="c9">
                    <td className="c15" colSpan={1} rowSpan={1}><p className="c7"><span className="c14">Zweck der Verarbeitung</span>
                    </p></td>
                    <td className="c0" colSpan={1} rowSpan={1}><p className="c7"><span
                        className="c14">Rechtsgrundlage gem&auml;&szlig; DSGVO</span></p></td>
                </tr>
                <tr className="c22">
                    <td className="c15" colSpan={1} rowSpan={1}><p className="c7"><span
                        className="c14">Verwaltungszwecke</span></p></td>
                    <td className="c0" colSpan={1} rowSpan={1}><p className="c7"><span className="c10">Die derartige Verarbeitung ist f&uuml;r die Erf&uuml;llung des Vertrags zwischen uns und Ihnen erforderlich; wenn dies erforderlich ist zur Erf&uuml;llung unserer gesetzlichen Verpflichtungen; und wenn Sie zum Zeitpunkt des Kaufs eingewilligt haben, bestimmte Informationen in Bezug auf Ihre Essensbestellungspr&auml;ferenzen zur Verf&uuml;gung zu stellen.</span>
                    </p></td>
                </tr>
                <tr className="c22">
                    <td className="c15" colSpan={1} rowSpan={1}><p className="c7"><span
                        className="c14">Schulung, Qualit&auml;ts&uuml;berwachung oder Evaluierung der von uns angebotenen Leistungen</span>
                    </p></td>
                    <td className="c0" colSpan={1} rowSpan={1}><p className="c7"><span className="c10">Die derartige Verarbeitung ist erforderlich zur Wahrung unserer berechtigten Interessen, insbesondere die Dienste unserer Website und ihre Nutzung zu &uuml;berwachen und zu verbessern, und sicherzustellen, dass unsere Nutzer die Website in &Uuml;bereinstimmung mit unseren Nutzungsbestimmungen und Richtlinien nutzen.</span>
                    </p></td>
                </tr>
                <tr className="c19">
                    <td className="c15" colSpan={1} rowSpan={1}><p className="c7"><span className="c14">Website-Dienste, u.a. zur Fehlerbehebung, Datenanalyse und f&uuml;r Umfragen</span>
                    </p></td>
                    <td className="c0" colSpan={1} rowSpan={1}><p className="c7"><span className="c10">Wir haben ein berechtigtes Interesse am Betrieb und an der Pflege der Website und f&uuml;r damit verbundene Zwecke, einschlie&szlig;lich der Verbesserung unserer Leistungen.</span>
                    </p></td>
                </tr>
                <tr className="c13">
                    <td className="c15" colSpan={1} rowSpan={1}><p className="c7"><span className="c14">Statistische Informationen, die nicht auf Einzelpersonen zur&uuml;ckgef&uuml;hrt werden k&ouml;nnen, um uns zu helfen, die von uns angebotenen Leistungen zu verbessern</span>
                    </p></td>
                    <td className="c0" colSpan={1} rowSpan={1}><p className="c7"><span className="c10">Wir haben ein berechtigtes Interesse daran, Zugriff auf bestimmte Analysen zu haben, um sicherzustellen, dass die von uns angebotenen Produkte und Leistungen angemessen sind.</span>
                    </p></td>
                </tr>
                <tr className="c23">
                    <td className="c15" colSpan={1} rowSpan={1}><p className="c7"><span className="c14">Unsere Rechte durchsetzen und verteidigen</span>
                    </p></td>
                    <td className="c0" colSpan={1} rowSpan={1}><p className="c7"><span className="c10 c4">Wir haben ein berechtigtes Interesse daran sicherzustellen, dass unsere Leistungen und die Website in &Uuml;bereinstimmung mit unseren Nutzungsbedingungen und Richtlinien genutzt werden.</span>
                    </p>
                        <p className="c7"><span
                            className="c10 c4">Falls erforderlich zum Zweck der Erf&uuml;llung unserer rechtlichen Verpflichtungen.</span>
                        </p>
                        <p className="c7"><span
                            className="c10">Falls erforderlich zum Zweck der Begr&uuml;ndung, Aus&uuml;bung oder Verteidigung eines Rechtsanspruchs, eines voraussichtlichen Rechtsanspruchs, eines Gerichtsverfahrens oder eines voraussichtlichen Gerichtsverfahrens.</span>
                        </p></td>
                </tr>
            </table>
            <p className="c5"><span className="c3 c4">Wir werden Ihre personenbezogenen Daten ohne Ihre Einwilligung nicht f&uuml;r Marketing-Zwecke verwenden. Wenn Sie keine Marketing-Mitteilungen mehr von uns erhalten m&ouml;chten, k&ouml;nnen Sie sich jederzeit abmelden, indem Sie auf den Link &quot;Abmelden&quot; am Ende jeder Marketing-Mitteilung von uns klicken oder indem Sie uns &uuml;ber die auf unserer Website bereitgestellte E-Mail kontaktieren.</span>
            </p>
            <h2 className="c2" id="h.eqkc7e52yyck"><span
                className="c12 c4">Individuelle Rechte der betroffenen Personen</span>
            </h2>
            <p className="c5"><span className="c3">Die Datenschutzgesetze sehen bestimmte Rechte zugunsten der betroffenen Personen vor (die &quot;</span><span
                className="c6">Rechte der betroffenen Personen</span><span className="c3 c4">&quot;).</span></p>
            <p className="c5"><span
                className="c3 c4">Die Rechte der betroffenen Person umfassen das Recht der betroffenen Person auf:</span>
            </p>
            <ul className="c11 lst-kix_izljxf9wdcgj-0 start">
                <li className="c5 c8 li-bullet-0"><span className="c3 c4">detaillierte Informationen &uuml;ber die Verarbeitung (gem&auml;&szlig; den Transparenzpflichten des f&uuml;r die Verarbeitung Verantwortlichen), die wir mittels dieser Datenschutzerkl&auml;rung bereitgestellt haben;</span>
                </li>
                <li className="c5 c8 li-bullet-0"><span
                    className="c3 c4">Zugang zu personenbezogenen Daten;</span></li>
                <li className="c5 c8 li-bullet-0"><span className="c3 c4">die Berichtigung oder L&ouml;schung personenbezogener Daten (&bdquo;Recht auf Vergessenwerden&ldquo;);</span>
                </li>
                <li className="c5 c8 li-bullet-0"><span
                    className="c3 c4">Einschr&auml;nkung der Verarbeitung;</span></li>
                <li className="c5 c8 li-bullet-0"><span className="c3 c4">Daten&uuml;bertragbarkeit;</span></li>
                <li className="c5 c8 li-bullet-0"><span className="c3 c4">Widerspruch gegen die Verarbeitung; und</span>
                </li>
                <li className="c5 c8 li-bullet-0"><span className="c3 c4">Widerspruch gegen die automatisierte Entscheidungsfindung (einschlie&szlig;lich der Erstellung von Profilen).</span>
                </li>
            </ul>
            <p className="c5"><span className="c3 c4">Die Rechte der betroffenen Person k&ouml;nnen von Ihnen vorbehaltlich der in den Datenschutzgesetzen vorgesehenen Einschr&auml;nkungen ausge&uuml;bt werden.</span>
            </p>
            <p className="c5"><span className="c3 c4">Bitte wenden Sie sich bez&uuml;glich der Aus&uuml;bung der Rechte von Betroffenen an die auf unserer Website angegebene E-Mail-Adresse. Ihr Anliegen wird in &Uuml;bereinstimmung mit den Datenschutzgesetzen bearbeitet.</span>
            </p>
            <h2 className="c2" id="h.7liwvp6z1xr6"><span
                className="c12 c4">Wie wir Daten mit unseren Partnern teilen</span>
            </h2>
            <p className="c5"><span className="c3 c4">Wir geben keine personenbezogenen Daten an Dritte weiter und werden Ihre personenbezogenen Daten nicht an Dritte weitergeben, soweit in dieser Datenschutzerkl&auml;rung nicht anders beschrieben.</span>
            </p>
            <p className="c5"><span className="c3 c4">Das Gesch&auml;ft und FoodAmigos, unser Online-Bestellpartner, sind gemeinsam Verantwortliche hinsichtlich Ihrer personenbezogenen Daten, die im Zusammenhang mit Essensbestellungen &uuml;ber die FoodAmigos-Plattform auf der Website verarbeitet werden. Wenn Sie Fragen dazu haben, wie Ihre personenbezogenen Daten von FoodAmigos verarbeitet werden, oder wenn Sie sich &uuml;ber Ihre Rechte informieren m&ouml;chten, wenden Sie sich bitte an FoodAmigos.</span>
            </p>
            <p className="c5"><span className="c3 c4">FoodAmigos handelt als Auftragsverarbeiter, der uns bei der Verwaltung der Einwilligungen von Benutzern in unsere Marketing-Kommunikation unterst&uuml;tzt. FoodAmigos handelt auch als Auftragsverarbeiter, wenn es uns bei der Verwaltung unserer Analysen unterst&uuml;tzt.</span>
            </p>
            <p className="c5"><span className="c3 c4">In einigen F&auml;llen, z.B. wenn Sie gegen unsere Nutzungsbedingungen oder andere Richtlinien versto&szlig;en, kann es erforderlich sein, dass wir personenbezogene Daten offenlegen m&uuml;ssen. Wir k&ouml;nnen derartige personenbezogene Daten nach eigenem Ermessen offenlegen, wenn wir dies im Zusammenhang mit der Untersuchung von Betrug, Verletzung des geistigen Eigentums, Raubkopien oder anderen ungesetzlichen Aktivit&auml;ten f&uuml;r notwendig oder angemessen halten. Dies kann die Offenlegung Ihres Namens, Ihrer Adresse, Telefonnummer, E-Mail-Adresse oder Ihres Firmennamens erfordern.</span>
            </p>
            <p className="c5"><span className="c3 c4">Wir k&ouml;nnen andere Unternehmen und Personen damit beauftragen, Aufgaben in unserem Auftrag auszuf&uuml;hren (d. h. Auftragsverarbeiter), und m&uuml;ssen m&ouml;glicherweise Ihre Daten an diese Unternehmen und Personen weitergeben, um Ihnen unsere Leistungen anbieten zu k&ouml;nnen.</span>
            </p>
            <p className="c5"><span
                className="c3 c4">In einigen F&auml;llen kaufen oder verkaufen wir m&ouml;glicherweise Verm&ouml;genswerte, was die &Uuml;bertragung von Kundeninformationen beinhalten kann. Wir werden solche Informationen &uuml;bertragen, wenn wir von einem anderen Unternehmen &uuml;bernommen oder mit einem anderen Unternehmen fusioniert werden. In diesem Fall werden wir Sie per E-Mail benachrichtigen.</span>
            </p>
            <p className="c5"><span
                className="c3 c4">Ohne Einschr&auml;nkung des oben Gesagten und in dem Bem&uuml;hen, Ihre Privatsph&auml;re zu respektieren und in dem Bem&uuml;hen, mit unseren M&ouml;glichkeiten, die Gesellschaft von kriminellen Personen frei zu halten, werden wir Ihre personenbezogenen Daten nicht an Strafverfolgungsbeh&ouml;rden, andere Regierungsbeamte oder andere Dritte weitergeben, ohne dass ein Gerichtsbeschluss, ein Strafverfolgungsantrag, ein Gerichtsverfahren oder ein im Wesentlichen &auml;hnliches rechtliches Verfahren vorliegt, es sei denn, wir sind in gutem Glauben davon &uuml;berzeugt, dass die Weitergabe von Informationen notwendig ist, um unsere Rechte oder die Rechte Dritter zu sch&uuml;tzen, k&ouml;rperlichen Schaden oder finanzielle Verluste zu verhindern oder um vermutete illegale Aktivit&auml;ten zu melden.</span>
            </p>
            <h2 className="c2" id="h.o59xumd9cfa3"><span className="c12 c4">Aufbewahrung von Daten</span></h2>
            <p className="c5"><span className="c3 c4">Wir werden personenbezogene Daten nur so lange aufbewahren, wie es f&uuml;r die Zwecke, f&uuml;r die diese personenbezogenen Daten verarbeitet werden (wie in dieser Datenschutzerkl&auml;rung beschrieben), f&uuml;r erforderlich erachtet wird, es sei denn, wir sind verpflichtet, Ihre personenbezogenen Daten &uuml;ber einen l&auml;ngeren Zeitraum aufzubewahren (z. B. im Falle von Gerichtsverfahren oder Ermittlungen).</span>
            </p>
            <h2 className="c2" id="h.ciff6nt1eu2w"><span className="c12 c4">Links zu Dritten</span></h2>
            <p className="c5"><span className="c3 c4">Wir f&uuml;gen gelegentlich Links zu Dritten auf der Website ein. Wenn wir einen Link bereitstellen bedeutet dies nicht, dass wir irgendeine Kontrolle &uuml;ber die verlinkte Website oder deren Datenschutzerkl&auml;rung haben, oder dass wir die Erkl&auml;rung dieser Website in Bezug auf den Datenschutz der Besucher bef&uuml;rworten oder billigen. Sie sollten die Datenschutzerkl&auml;rung der verlinkten Website pr&uuml;fen, bevor Sie personenbezogene Daten &uuml;bermitteln.</span>
            </p>
            <h2 className="c2" id="h.15ogjv1y3vpr"><span
                className="c12 c4">Leistungsbezogene Ank&uuml;ndigungen und &Auml;nderungen</span>
            </h2>
            <p className="c5"><span
                className="c3 c4">Zuk&uuml;nftige &Auml;nderungen an dieser Datenschutzerkl&auml;rung werden auf der Website und gegebenenfalls per E-Mail-Benachrichtigung bekannt gegeben.</span>
            </p>
            <h2 className="c2" id="h.3e0f8qju5yps"><span className="c4 c12">Kontaktieren Sie uns</span></h2>
            <p className="c5"><span
                className="c3 c4">F&uuml;r weitere Informationen zu dieser Datenschutzerkl&auml;rung oder zur Verarbeitung Ihrer personenbezogenen Daten durch uns oder in unserem Namen kontaktieren Sie uns bitte &uuml;ber die auf unserer Website angegebenen Kontaktdaten.</span>
            </p>
            <p className="c5"><span
                className="c3 c4">Sie k&ouml;nnen bei der Datenschutzkommission eine Beschwerde &uuml;ber die Einhaltung der Datenschutzgesetze durch uns einreichen, wir bitten Sie jedoch, sich zun&auml;chst mit uns in Verbindung zu setzen, um uns Gelegenheit zu geben, auf Ihr Anliegen einzugehen.</span>
            </p>
            <p className="c5"><span className="c3 c4">Diese Datenschutzerkl&auml;rung wurde zuletzt im Juni 2022 aktualisiert.</span>
            </p>
            <p className="c1"><span className="c4 c18"></span></p>
            <p className="c1"><span className="c18 c4"></span></p>
        </div>
    );
}