export default {
  steps: {
    phone_number: {
      title: 'Deine Mobilnummer (Kein Festnetz)',
    },
    personal_info: {
      title: 'Persönliche Informationen',
    },
    receipt_info: {
      title_enabled: 'Quittung wird gesendet',
      title_disabled: 'Möchtest du eine Quittung?',
    },
    order_info: {
      title: 'Bestell Info',
    },
    payment_method: {
      title: 'Sicher bezahlen',
      securely: 'bezahlen',
      titles: {
        cash: 'Barzahlung',
        cash_or_card_in_store: 'Barzahlung oder Kartenzahlung im Restaurant',
      },
      descriptions: {
        cash_payment: 'Bitte prüfe, dass du möglichst den genauen Bargeldbetrag besitzt',
        online_payment: 'alle Transaktionen sind privat und verschlüsselt'
      },
    },
  },
  buttons: {
    confirm: 'Bestätigen',
  },
};
