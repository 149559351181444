export default {
  header: {
    review_us: "Bewerte uns auf Google",
    upload: "Review Screenshot hochladen",
    not_now: "Nicht jetzt",
  },
  steps: {
    how_it_works: "So funktioniert’s",
    step_1: {
      title: "Schritt",
      description: "Schreibe eine Bewertung über {{name}} bei Google",
    },
    step_2: {
      title: "Schritt",
      description:
        "Uploade einen Screenshot deiner veröffentlichten Google-Bewertung (siehe Beispiel unten)",
    },
  },
  buttons: {
    upload_screenshot: "Screenshot Hochladen",
    leave_google_review: "Google Bewertung Abgeben",
    submit_screenshot: "Screenshot Senden",
  },
  enter_name: {
    title: "Veröffentlichter Name mit Google Rezension",
    place_holder: "Name eingeben",
  },
  screen_shot_uploaded: {
    title: "Screenshot hochgeladen",
    description: "Scroll runter, um vollständigen Screenshot zu sehen",
  },
  loading: {
    processing: "Deine gepostete Bewertung wird gerade überprüft",
  },
  error_messages: {
    upload_failed:
      "Screenshot Hochladen ist fehlgeschlagen. Bitte versuche es erneut.",
    review_already_uploaded:
      "Es scheint, dass du bereits einen Google-Bewertung Screenshot hochgeladen hast. Du kannst maximal einmal für eine Google-Bewertung belohnt werden 😊",
    name_missing: {
      description:
        "Bitte gib den Namen ein, der mit deiner Google Rezension veröffentlicht ist, damit wir deine Rezension zuordnen können.",
      button: {
        title: "Okay",
      },
    },
  },
};
