export default {
  promotions: {
    invite_friends: "Invite friends to earn 5 points!",
    buy_one_get_one_free:
      "Buy one, get one for free! This is offer is limited till tomorrow",
    loyalty: "Punkte sammeln",
  },
  buttons: {
    view_menu: "Menü Ansehen & Bestellen",
    menu: "Menü",
    pay_now: "Jetzt Zahlen",
    close_table: "Bereit Zu Gehen",
  },
  feedback: {
    general_error: "Es ist ein Fehler aufgetreten, bitte lade die Seite neu",
  },
  toasts: {
    table_selected:
      "Super, sieht so aus, als wäre dein Tisch Nummer: {{number}}",
    failed_to_close_table: "Der Tisch konnte nicht geschlossen werden",
    table_closed: "Tisch erfolgreich geschlossen",
    left_table: "Du hast den Tisch erfolgreich verlassen",
  },
};
