export default {
  promotions: {
    invite_friends: "Invite friends to earn 5 points!",
    buy_one_get_one_free:
      "Buy one, get one for free! This is offer is limited till tomorrow",
    loyalty: "Loyalty points",
  },
  buttons: {
    view_menu: "View Menu & Order",
    menu: "Menu",
    pay_now: "Pay now",
    close_table: "Ready To Go",
  },
  feedback: {
    general_error: "Something went wrong, please reload the page",
  },
  toasts: {
    table_selected: "Great, looks like your table is number: {{number}}",
    failed_to_close_table: "Failed to close the table",
    table_closed: "Table closed successfully",
    left_table: "You successfully left the table",
  },
};
