import {
  forwardRef, ReactElement,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import collect from "collect.js";
import {
  CategoryInterface,
  ProductComboInterface,
  ProductInterface,
} from "../../services/exports/Interfaces";
import CategoryItem from "./category/CategoryItem";
import useScreenType from "../../hooks/utility/useScreenType";
import MenuBar from "./MenuNavigationBar/MenuBar";
import MenuList from "../menu/MenuList";
import useMenu from "../../hooks/menu/useMenu";
import { debounce } from "lodash";
import useAppState from "../../hooks/global/useAppState";
import classnames from "classnames";
import useScrollPosition from "../../hooks/utility/useScrollPosition";
import { ProductType } from "../../services/exports/Constants";
import useAppMethods from "../../hooks/utility/useAppMethods";

interface Props {
  className?: string;
  menuListClassName?: string;
  categoryListClassName?: string;
  ListHeaderComponent?: ReactElement;
  ListFooterComponent?: ReactElement;
  DiscountsComponent?: ReactElement;
  LastOrderComponent?: ReactElement;
  onProductClick?: (
    product: ProductInterface | ProductComboInterface | null
  ) => void;
}

const MenuCategoryList = forwardRef((props: Props, ref: any): ReactElement => {
  const {
    className,
    menuListClassName,
    categoryListClassName,
    ListHeaderComponent,
    ListFooterComponent,
    DiscountsComponent,
    LastOrderComponent,
    onProductClick,
  } = props;

  const { categories, selectedMenu, findProductCombo } = useMenu();

  const { menu } = useAppState();
  const {
    toggleComboUpsellModal,
    toggleMenuItemModal,
    toggleComboModal,
    saveMenu,
  } = useAppMethods();

  const { isDesktop } = useScreenType();
  const { scrollPosition } = useScrollPosition({ onScroll });

  const [focusedCategoryId, setFocusedCategoryId] = useState<number>(
    menu?.activeCategoryId ?? collect(categories)?.first()?.id
  );
  const [activeScrollIndex, setActiveScrollIndex] = useState<number>(0);

  const menuNavBarStickyPosition = document
    .getElementById("page-header")
    ?.getBoundingClientRect()?.height;

  const categoriesRef = useRef(categories);
  categoriesRef.current = categories;

  const activeScrollIndexRef = useRef(activeScrollIndex);
  activeScrollIndexRef.current = activeScrollIndex;

  const scrollPositionRef = useRef(scrollPosition);
  scrollPositionRef.current = scrollPosition;

  const menuWrapperRef = useRef<HTMLDivElement>(null);
  const menuBarRef = useRef<HTMLDivElement>();

  useImperativeHandle(ref, () => ({
    toggleProductModal,
  }));

  useLayoutEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        left: 0,
        top: menu?.screenPosition,
        behavior: "auto",
      });
    });

    return () => {
      saveMenu({
        screenPosition: scrollPositionRef?.current,
        activeCategoryId:
          categoriesRef.current[activeScrollIndexRef.current]?.id,
      });
    };
  }, []);

  function onScroll(): void {
    updateActiveCategory();
  }

  const updateActiveCategory = useRef(
    debounce(() => {
      const headerEndPosition =
        menuBarRef?.current?.getBoundingClientRect()?.top +
        menuBarRef?.current?.getBoundingClientRect()?.height +
        50;

      const categoryDivs = document.querySelectorAll("#category-list > div");
      let activeCategory: CategoryInterface;

      for (let i = 0; i < categoryDivs.length; i++) {
        const category = categoryDivs[i];

        const categoryStartPosition = category?.getBoundingClientRect()?.top;
        const categoryEndPosition = category?.getBoundingClientRect()?.height;

        if (
          categoryStartPosition < headerEndPosition &&
          headerEndPosition < categoryEndPosition
        ) {
          activeCategory = categories[i];
        }
      }
      setFocusedCategoryId(activeCategory?.id);
    }, 200)
  ).current;

  function toggleProductModal(
    product: ProductInterface | ProductComboInterface | null
  ): void {
    if (onProductClick) {
      return onProductClick(product);
    }

    if (product.type === ProductType.Combo) {
      return toggleComboModal({
        openModal: true,
        combo: product,
      });
    }

    const combo = findProductCombo(product);

    if (!combo || !product?.is_available || product.discount === 100) {
      return toggleMenuItemModal({
        openModal: true,
        product: product,
        isFreeItem: product.discount === 100,
      });
    }

    toggleComboUpsellModal({
      openModal: true,
      product: product,
      combos: [combo],
    });
  }

  function isActive(index: number): boolean {
    if (activeScrollIndexRef.current <= 3) {
      return index <= 6;
    }

    return (
      index >= activeScrollIndexRef.current - 3 &&
      index <= activeScrollIndexRef.current + 3
    );
  }

  const renderCategoryList = useMemo(() => {
    const renderCategory = (
      category: CategoryInterface,
      index: number
    ): JSX.Element => {
      return (
        <div
          key={`category-${category.id}`}
          className="lg:px-none px-small mb-large"
          id={`category-id-${category?.id}`}
        >
          <CategoryItem
            category={category}
            index={index}
            isActive={isActive(index)}
            isDesktop={isDesktop}
            toggleProduct={toggleProductModal}
            onChangeVisibleIndex={setActiveScrollIndex}
          />
        </div>
      );
    };

    return (
      <div
        id="category-list"
        className={classnames(categoryListClassName, "mt-medium")}
        key={selectedMenu?.id?.toString()}
      >
        {categories?.map(renderCategory)}
      </div>
    );
  }, [
    selectedMenu,
    categories,
    activeScrollIndexRef.current,
    isDesktop,
    categoryListClassName,
    onProductClick,
  ]);

  const renderMenu = useMemo<JSX.Element>(() => {
    return (
      <div
        className="w-full sticky top-0 z-20 mb-medium border-b-small"
        ref={menuBarRef}
        style={{ top: menuNavBarStickyPosition }}
      >
        <MenuBar
          focusedCategoryId={focusedCategoryId}
          menuNavBarStickyPosition={menuNavBarStickyPosition}
        />
      </div>
    );
  }, [focusedCategoryId, menuNavBarStickyPosition]);

  const renderMenuList = (): JSX.Element => {
    return (
      <div
        className={classnames(
          menuListClassName,
          "bg-background-inkWhite-white_1 px-small lg:px-medium"
        )}
      >
        <MenuList />
      </div>
    );
  };

  return (
    <div id={"menu-category-list"} className={className}>
      <div ref={menuWrapperRef}>
        {ListHeaderComponent && ListHeaderComponent}
        {renderMenuList()}
        {renderMenu}
        {DiscountsComponent}
        {LastOrderComponent}
        {renderCategoryList}
        {ListFooterComponent && ListFooterComponent}
      </div>
    </div>
  );
});

export default MenuCategoryList;
