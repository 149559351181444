import {createSlice} from "@reduxjs/toolkit";
import { REDUX_STORE_SLICES } from "../../services/exports/Constants";
import {BasketInterface, BasketLineItemInterface} from "../types";
import {ActionPayloadInterface} from "../../services/exports/Interfaces";
import collect from "collect.js";

interface AddLineItemPayloadInterface {
  company_id: number;
  item: BasketLineItemInterface;
}

interface RemoveLineItemPayloadInterface {
  company_id: number;
  index: number;
}

interface UpdateLineItemPayloadInterface {
  company_id: number;
  index: number;
  item: Partial<BasketLineItemInterface>;
}

interface ClearBasketPayloadInterface {
  company_id: number;
}

const initialState: BasketInterface = {
  lineItems: {},
};

export default createSlice({
  name: REDUX_STORE_SLICES.BASKET,
  initialState,
  reducers: {
    addItem: (state: BasketInterface, action: ActionPayloadInterface<AddLineItemPayloadInterface>): void => {
      const { company_id, item } = action.payload;

      state.lineItems = {
        ...state.lineItems,
        [company_id]: [
          ...collect(state.lineItems[company_id] ?? [])
            // @ts-ignore
            .when(
              item.is_free,
              (items) => items.where('is_free', false)
            )
            // @ts-ignore
            .toArray(),
          item,
        ]
      };
    },
    removeItem: (state: BasketInterface, action: ActionPayloadInterface<RemoveLineItemPayloadInterface>): void => {
      const { company_id, index } = action.payload;

      state.lineItems = {
        ...state.lineItems,
        [company_id]: collect(state.lineItems[company_id] ?? [])
          .forget(index)
          .toArray()
      };
    },
    updateItem: (state: BasketInterface, action: ActionPayloadInterface<UpdateLineItemPayloadInterface>): void => {
      const { company_id, index, item } = action.payload;

      state.lineItems = {
        ...state.lineItems,
        [company_id]: collect(state.lineItems[company_id] ?? [])
          .put(index, {
            ...state.lineItems[company_id][index],
            ...item,
          })
          .toArray()
      };
    },
    clear: (state: BasketInterface, action: ActionPayloadInterface<ClearBasketPayloadInterface>): void => {
      const { company_id } = action.payload;

      state.lineItems = {
        ...state.lineItems,
        [company_id]: [],
      };
    },
  },
});