export default {
  min: {
    title: 'Delivery to your location: Amount needed to reach the minimum order value (Subtotal excl. delivery costs & tip)',
    description: 'Sorry, you can\'t order yet. {{name}} has set a minimum order amount of {{threshold}} (excl. delivery costs)',
  },
  max: {
    title: 'Maximum order value for delivery reached (Total excl. delivery costs)',
    description: 'Your order has hit the maximum allowed value of {{threshold}} for online delivery. For orders exceeding this amount, please contact us directly so that we can organise the delivery for you. Thanks for understanding!',
  },
};
