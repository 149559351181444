export default {
  header: 'Pay / Split bill',
  payment_options: {
    pay_full: 'Pay full check amount',
    pay_rest: 'Pay the rest',
    pay_yours: 'Pay for my items',
    pay_equally: 'Divide the check equally',
    pay_custom: 'Pay custom amount',
  },
  titles: {
    overcharged: 'Thank you for being so generous!',
    total_bill: 'Total bill',
    left_to_pay: 'Left to pay',
    payment_options: 'How would you like to pay?',
  },
  text: {
    overcharged: 'You are about to pay more than what’s left',
    entered_amount: 'Amount you entered',
  },
  feedback: {
    min_payment_amount: {
      title: 'The minimum payment amount has to be {{amount}}',
      text: 'Sorry, the minimum payment is {{amount}} when paying online. Please adjust the amount to at least {{amount}}.',
    },
    min_amount_after_payment: {
      title: 'The remaining amount after your payment can not be less than {{amount}}',
      text: 'We cannot accept online payments below {{amount}}. Thus, you can not leave the remaining check below {{amount}}.',
    },
  },
  buttons: {
    add_as_tip: 'Add extra as tip',
    pay_only: 'Pay only {{amount}}',
    confirm: 'Confirm',
  },
};
