import React from "react";
import { CompanyDetailsInterface } from "../../services/exports/Interfaces";
import useMaps from "../../hooks/location/useMaps";
import useCompanyLocation from "../../hooks/location/useCompanyLocation";
import {Moment} from "moment";
import AddressHelpers from "../../services/helpers/AddressHelpers";

interface Props {
  company?: CompanyDetailsInterface | null;
  datetime?: null|string|Moment
  showPhoneNumber?: boolean;
  shouldOpenMap?: boolean;
}

export default function CompanyDescription(props: Props) {
  const { company, datetime, showPhoneNumber, shouldOpenMap } = props;
  const { openMap } = useMaps();
  const location = useCompanyLocation(datetime);

  return (
    <div>
      <h6>{company?.name}</h6>
      <div>
        <button onClick={() => (shouldOpenMap ? openMap() : null)}>
          <p className={addressStyle}>
            {AddressHelpers.format(
              location?.address,
              null,
              location?.city,
              null,
              location?.country
            )}
          </p>
        </button>
      </div>

      {showPhoneNumber && company?.phone_number && (
        <div className="mt-mini">
          <a href={`tel:${company?.phone_number}`} className={phoneNumberStyle}>
            <p>Tel: {company?.phone_number}</p>
          </a>
        </div>
      )}
    </div>
  );
}

const addressStyle = "text-brand-text-grey underline mt-mini";

const phoneNumberStyle = "inline text-[#2F80ED]";
