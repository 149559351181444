import {DEAL_TYPES} from "../../../../../components-library/carousel/DealsCarousel";

export default {
  title: 'Discounts',
  deals: {
    [DEAL_TYPES.GOOGLE_REVIEW_PROGRAM]: {
      title: '+{{reward}} Extra Points',
      description: 'Rate us on Google',
      buttons: {
        add: 'Add',
      },
    },
    [DEAL_TYPES.SMART_PRICING_RULE]: {
      description: 'Already automatically applied',
    },
  },
};
