import useTheme from "../../hooks/ui/useTheme";
import ProgressBar, { ProgressBarProps } from "@ramonak/react-progress-bar";

interface Props extends Partial<ProgressBarProps> {
  progress: number;
}

const LinearProgressBar = (props: Props): JSX.Element => {
  const { progress } = props;

  const theme = useTheme();

  return (
    <div>
      <ProgressBar
        completed={progress}
        isLabelVisible={false}
        height={"8px"}
        bgColor={theme.color.companyBrand.primary}
        baseBgColor={""}
        className={"bg-companyBrand-primary/20 w-full rounded-full"}
        {...props}
      />
    </div>
  );
};

export default LinearProgressBar;
