export default {
  my_points: 'Meine Punkte',
  loyalty_points: 'Loyalty Punkte',
  already_have_points: 'Hast du bereits Punkte?',
  log_in: 'Log in',
  earn_points: 'Erhalte Punkte für gratis Gerichte',
  status: {
    not_logged_in: {
      title: "Hier bestellen & Punkte sammeln",
      description: "{{count}} pts",
    },
    next_discount: {
      title: "Meine Reward-Punkte",
      description: "Noch {{count}} Punkt bis zum {{discount}}% Rabatt",
      description_other: "Noch {{count}} Punkte bis zum {{discount}}% Rabatt",
    },
    next_free_reward: {
      title: "Meine Reward-Punkte",
      description: "Noch {{count}} Punkt bis zum GRATIS Artikel",
      description_other: "Noch {{count}} Punkte bis zum GRATIS Artikel",
    },
    discount_available: {
      title: "Meine Reward-Punkte",
      description: "{{discount}}% Rabatt verfügbar & auto. angewandt",
    },
    free_reward_available: {
      title: "Meine Reward-Punkte",
      description: "Gratis Artikel verfügbar",
    },
  },
};