export default {
  vgn: {
    name: 'Vegan',
    emoji: '🌱',
  },
  vgt: {
    name: 'Vegetarisch',
    emoji: '🥬',
  },
  gf: {
    name: 'Glutenfrei',
  },
  df: {
    name: 'Laktosefrei',
  },
  hal: {
    name: 'Halal',
  },
  'h&s': {
    name: 'Scharf',
    emoji: '🌶',
  },
};