import React, { ReactElement } from "react";
import StarAnimationWhite from "../assets/animations/StarAnimationWhite.json";
import SuccessModal from "./SuccessModal";
import {Trans, useTranslation} from "react-i18next";
import useCashbackProgram from "../hooks/cashback/useCashbackProgram";

interface Props {
  open: boolean;
  value: number;
  buttonTitle?: string;
  toggleModal: () => void;
  setLoading?: (loading: boolean) => void;
}

export default function CashbackEarnedModal(props: Props): ReactElement {
  const { open, value, buttonTitle, toggleModal, setLoading } = props;

  const { t } = useTranslation(null, { keyPrefix: 'Modals:CashbackEarnedModal' });

  const { hasCashbackProgram, balance, formattedBalance } = useCashbackProgram();

  return hasCashbackProgram && (
    <SuccessModal
      open={open}
      animationDescription={(
        <h5 className="text-white">{t('title', { value })}</h5>
      )}
      lottieProps={{
        animationData: StarAnimationWhite,
      }}
      buttonTitle={buttonTitle ?? t('buttons.ok')}
      onConfirm={toggleModal}
    >
      <h6>
        <Trans
          t={t}
          values={{ value: balance, moneyValue: formattedBalance }}
          components={{
            highlight: <span className="text-companyBrand-primary" />
          }}
        >balance</Trans>
      </h6>
      <p className="text-mini mt-small">{t('description')}</p>
    </SuccessModal>
  );
}