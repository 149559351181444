import useCashbackProgram from "../../hooks/cashback/useCashbackProgram";
import useAuth from "../../hooks/global/useAuth";
import {Trans, useTranslation} from "react-i18next";
import useAppMethods from "../../hooks/utility/useAppMethods";
import classnames from "classnames";
import {useMemo} from "react";
import Switch from "../inputs/Switch";
import {useSelector} from "react-redux";
import {StoreInterface} from "../../store/types";
import {COUPON_TYPES} from "../../services/exports/Constants";
import ReduxActions from "../../store/ReduxActions";
import {actionCreators} from "../../store/actions";

interface Props {
  subtotal: number;
  total: number;
  className?: string;
}

export default function OrderBasketCashback(props: Props) {
  const { subtotal, total, className } = props;

  const { t } = useTranslation(null, { keyPrefix: 'Components:Cashback:OrderBasketCashback' });

  const { cached_order } = useSelector((state: StoreInterface) => state.order);

  const { isLoggedIn } = useAuth();
  const { formatCurrency, toggleLoginModal } = useAppMethods();
  const { hasCashbackProgram, cashbackProgram, balance, calculateSpendableCashback, calculateExpectedCashback } = useCashbackProgram();

  const spendableCashback = useMemo(
    () => calculateSpendableCashback(subtotal),
    [subtotal, calculateSpendableCashback],
  );

  const expectedCashback = useMemo(
    () => calculateExpectedCashback(total),
    [total, calculateExpectedCashback],
  );

  const onLoginClick = () => toggleLoginModal({
    openModal: true,
    onCloseRedirect: () => null,
  });

  const onToggleClick = (checked: boolean) => {
    if (! checked) {
      return ReduxActions.dispatch(
        actionCreators.order.updateOrder({
          coupon_type: null,
          coupon_details: null,
        })
      );
    }

    return ReduxActions.dispatch(
      actionCreators.order.updateOrder({
        coupon_type: COUPON_TYPES.CASHBACK,
        coupon_details: null,
      })
    );
  };

  const renderAvailableBalance = () => {
    if (! isLoggedIn) {
      return (
        <button
          onClick={onLoginClick}
          className="underline text-brand-inkGrey-grey_4 text-mini font-bold"
        >
          {t('buttons.log_in')}
        </button>
      );
    }

    if (balance > 0 && spendableCashback === 0) {
      return (
        <p>{t('sections.use_cashback.min_spending_limit_not_reached', { value: cashbackProgram.min_spending_limit })}</p>
      )
    }

    return (
      <div className="flex justify-between">
        <div>
          <p className="lg:text-base text-mini">{t('sections.use_cashback.balance', {
            value: spendableCashback,
            moneyValue: formatCurrency(spendableCashback / 100)
          })}</p>
          {!!cashbackProgram.max_spending_limit && (
            <p
              className="text-tiny text-brand-inkGrey-grey_4">{t('sections.use_cashback.max_spending_limit', {value: cashbackProgram.max_spending_limit})}</p>
          )}
        </div>
        <Switch
          onStateText={t('states.on')}
          offStateText={t('states.off')}
          checked={cached_order?.coupon_type === COUPON_TYPES.CASHBACK}
          onChange={(e) => onToggleClick(e.target.checked)}
        />
      </div>
    );
  }

  return hasCashbackProgram && (
    <div className={classnames('w-full', className)}>
      {renderAvailableBalance()}
      {expectedCashback > 0 && (
        <p className="text-mini mt-mini">
          <Trans
            t={t}
            values={{value: expectedCashback}}
            components={{
              highlight: <span className="text-companyBrand-primary" />
            }}
          >sections.expected_cashback</Trans>
        </p>
      )}
    </div>
  );
}