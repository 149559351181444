import {useCallback, useMemo, useRef, useState} from "react";
import * as stripeJs from "@stripe/stripe-js";
import {
  OrderInterface,
  OrderPaymentInterface,
  PaymentOptionPropertiesInterface,
} from "../../services/exports/Interfaces";
import PayPalManager from "../../services/api/PayPalManager";
import useStripeHelpers from "./useStripeHelpers";
import {useSelector} from "react-redux";
import {StoreInterface} from "../../store/types";
import {useStripe} from "@stripe/react-stripe-js";
import {DeliveryProvider, ORDER_METHODS, PAYMENT_METHODS, PaymentMethod} from "../../services/exports/Constants";
import collect from "collect.js";

export default function usePaymentMethods() {
  const { profile } = useSelector((state: StoreInterface) => state.profile);
  const { company } = useSelector((state: StoreInterface) => state.initialData);
  const { cached_order } = useSelector((state: StoreInterface) => state.order);
  const { payment_options } = useSelector((state: StoreInterface) => state.paymentOptions);

  const { paymentMethod, enabledWallets } = useStripeHelpers();
  const stripe = useStripe();

  const [loading, setLoading] = useState(false);

  const paymentMethodRef = useRef(paymentMethod);
  paymentMethodRef.current = paymentMethod;

  const isPaymentOptionSupported = useCallback(
    (paymentOption: PaymentOptionPropertiesInterface) => {
      const hiddenForOrderMethods = company?.hidden_payment_methods
        ? (company?.hidden_payment_methods[paymentOption.payment_method_id] ?? [])
        : [];

      return company?.supported_payment_methods?.includes(paymentOption.payment_method_id)
        && (paymentOption.payment_method_id !== PaymentMethod.ApplePay || enabledWallets.includes(PaymentMethod.ApplePay))
        && (paymentOption.payment_method_id !== PaymentMethod.GooglePay || enabledWallets.includes(PaymentMethod.GooglePay))
        && !hiddenForOrderMethods.includes('*')
        && !hiddenForOrderMethods.includes(cached_order?.method)
    },
    [
      enabledWallets,
      cached_order?.method,
      company?.hidden_payment_methods,
      company?.supported_payment_methods,
    ]
  );

  const isPaymentMethodDisabled = useCallback(
    (paymentMethod: PaymentMethod): boolean => paymentMethod === PaymentMethod.Cash
      && cached_order?.method === ORDER_METHODS.DELIVERY
      && company?.delivery_provider !== DeliveryProvider.Standalone,
    [cached_order?.method, company?.delivery_provider]
  );

  const paymentOptions = useMemo<PaymentOptionPropertiesInterface[]>(
    () => collect(PAYMENT_METHODS())
      .reject((item: PaymentOptionPropertiesInterface) => ! isPaymentOptionSupported(item))
      .map((item: PaymentOptionPropertiesInterface) => {
        const savedPaymentItem = collect(payment_options).firstWhere('payment_method_id', item.payment_method_id);

        return {
          ...item,
          ...(savedPaymentItem ?? {}),
          paymentMethodSaved: !!savedPaymentItem,
          is_default: savedPaymentItem?.is_default === true,
        };
      })
      .toArray(),
    [
      payment_options,
      isPaymentOptionSupported,
    ]
  );

  const isPaymentMethodSaved = useCallback(
    (paymentMethod: PaymentMethod): boolean => collect(paymentOptions)
      .firstWhere('payment_method_id', paymentMethod)
      ?.paymentMethodSaved ?? false,
    [paymentOptions]
  );

  const getDefaultPaymentOption = useCallback(
    (preferredPaymentMethod?: PaymentMethod, blacklist: PaymentMethod[] = []) => {
      const paymentOption = collect(paymentOptions).first(
        (item) => item.payment_method_id === preferredPaymentMethod,
        collect(paymentOptions).firstWhere('is_default', true)
      );

      return blacklist.includes(paymentOption?.payment_method_id)
        ? null
        : paymentOption;
    },
    [paymentOptions]
  );

  const _handleCardConfirmation = useCallback(
    async (
      data: stripeJs.PaymentIntent,
      successHandler: () => void,
      errorHandler: (error: stripeJs.StripeError) => void
    ) => {
      setLoading(true);

      const { error } = await stripe.confirmCardPayment(
        data?.client_secret
      );

      if (error) {
        return errorHandler(error);
      }

      setLoading(false);

      successHandler();
    },
    [stripe]
  );

  const _handleAppleGooglePayConfirmation = useCallback(
    async (
      data: stripeJs.PaymentIntent,
      successHandler: () => void,
      errorHandler: (error: stripeJs.StripeError) => void
    ) => {
      setLoading(true);
      const { error } = await stripe.confirmCardPayment(
        data?.client_secret,
        { payment_method: paymentMethodRef.current?.id },
        { handleActions: false }
      );

      if (error) {
        return errorHandler(error);
      }

      setLoading(false);

      successHandler();
    },
    [stripe, paymentMethodRef.current]
  );

  const _handleSofortConfirmation = useCallback(
    async (
      data: stripeJs.PaymentIntent,
      selectedPaymentMethod: PaymentOptionPropertiesInterface,
      successHandler: () => void,
      errorHandler: (error: stripeJs.StripeError) => void
    ) => {
      const response = await stripe.confirmSofortPayment(data?.client_secret, {
        return_url: `${window.location.href.replace(window.location.search, '')}?payment_method_id=${selectedPaymentMethod?.payment_method_id}&setup=false`,
        payment_method: {
          sofort: {
            country: "DE",
          },
          billing_details: {
            name: `${profile?.first_name} ${profile?.last_name}`,
            email: profile?.email,
          },
        },
      });

      if (response?.error) {
        return errorHandler(response?.error);
      }

      setLoading(false);
      successHandler();
    },
    [stripe]
  );

  const _handleGiropayConfirmation = useCallback(
    async (
      data: stripeJs.PaymentIntent,
      selectedPaymentMethod: PaymentOptionPropertiesInterface,
      successHandler: () => void,
      errorHandler: (error: stripeJs.StripeError) => void
    ) => {
      const response = await stripe.confirmGiropayPayment(data?.client_secret, {
        return_url: `${window.location.href.replace(window.location.search, '')}?payment_method_id=${selectedPaymentMethod?.payment_method_id}&setup=false`,
        payment_method: {
          billing_details: {
            name: `${profile?.first_name} ${profile?.last_name}`,
          },
        },
      });

      if (response?.error) {
        return errorHandler(response?.error);
      }

      setLoading(false);
      successHandler();
    },
    [stripe]
  );

  const _handlePaypalConfirmation = useCallback(
    async (
      data: stripeJs.PaymentIntent,
      selectedPaymentMethod: PaymentOptionPropertiesInterface,
      successHandler: () => void,
      errorHandler: (error: stripeJs.StripeError) => void
    ) => {
      const response = await stripe.confirmPayPalPayment(data?.client_secret, {
        return_url: `${window.location.href.replace(window.location.search, '')}?payment_method_id=${selectedPaymentMethod?.payment_method_id}&setup=false`,
      });

      if (response?.error) {
        return errorHandler(response?.error);
      }

      setLoading(false);
      successHandler();
    },
    [stripe]
  );

  const _handlePaypalStandaloneConfirmation = useCallback(
    async (
      data: OrderInterface,
      paymentData: OrderPaymentInterface,
      errorHandler: () => void
    ) => {
      const { success, response } = await PayPalManager.createOrder(
        paymentData?.id
      );

      if (!success) {
        return errorHandler();
      }

      return response?.data?.id;
    },
    []
  );

  return {
    loading,
    paymentOptions,
    isPaymentMethodSaved,
    isPaymentMethodDisabled,
    getDefaultPaymentOption,
    handleCardConfirmation: _handleCardConfirmation,
    handlePaypalConfirmation: _handlePaypalConfirmation,
    handleSofortConfirmation: _handleSofortConfirmation,
    handleGiropayConfirmation: _handleGiropayConfirmation,
    handleAppleGooglePayConfirmation: _handleAppleGooglePayConfirmation,
    handlePaypalStandaloneConfirmation: _handlePaypalStandaloneConfirmation,
  };
}
