import {useLocation} from "react-router-dom";
import useCompanySlug from "../global/useCompanySlug";
import {useCallback} from "react";

interface MatchFunction {
  (pattern: string): boolean;
}

export default function useRouteMatch(): MatchFunction {
  const location = useLocation();
  const {slug} = useCompanySlug();

  return useCallback(
    (pattern: string) => {
      if (pattern === '' || pattern === '/') {
        return location.pathname === pattern
          || location.pathname === `/${slug}`;
      }

      return location.pathname === pattern || `/${slug}/${pattern}` === location.pathname;
    },
    [location, slug]
  );
}