export default {
  toast_messages: {
    reward_available: {
      part_1: "Deine Bewertung wurde erfolgreich überprüft. ",
      part_2: "Du hast {{count}} Punkt und eine Belohnung verdient 🥳.",
      part_2_other: "Du hast {{count}} Punkte und eine Belohnung verdient 🥳.",
    },
    reward_not_available: {
      part_1: "Deine Bewertung wurde erfolgreich überprüft. ",
      part_2: "Du hast {{pointsEarned}} pts verdient.",
    },
  },
};
