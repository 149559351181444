import { useEffect } from "react";
import { toast, Toaster, ToastBar } from "react-hot-toast";
import CloseIcon from "../assets/logo/CloseIcon";
import Theme from "./Theme";
import { motion, useAnimation } from "framer-motion";

const Toast = ({ t }): JSX.Element => {
  const animation = useAnimation();

  const variants = {
    visible: {
      opacity: 1,
      transition: {
        delay: 0.3,
      },
    },
    hidden: { opacity: 0 },
  };

  useEffect(() => {
    setTimeout(() => onClose(), t.duration === Infinity ? 30000 : t.duration);
  }, []);

  useEffect(() => {
    if (t.visible) {
      animation.start("visible");
    } else {
      animation.start("hidden");
    }
  }, [t.visible, animation]);

  function onClose(): void {
    toast.dismiss(t.id);
  }

  return (
    <motion.div initial="hidden" animate={animation} variants={variants}>
      <ToastBar toast={t}>
        {({ icon, message }) => (
          <>
            {icon}
            {message}
            {t.type !== "loading" && (
              <button onClick={() => onClose()}>
                <CloseIcon color={Theme.color.text.grey} />
              </button>
            )}
          </>
        )}
      </ToastBar>
    </motion.div>
  );
};

const CustomToaster = (): JSX.Element => {
  return (
    <Toaster toastOptions={{ duration: 4500 }}>
      {(t) => <Toast t={t} />}
    </Toaster>
  );
};

export default CustomToaster;
