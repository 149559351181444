export default {
  titles: {
    main: 'Change',
    change_table: 'Change table nr. (see sticker)',
    select_table: 'Select table nr. (see sticker)',
    table: 'Table',
    group: 'Group',
  },
  buttons: {
    confirm: 'Confirm',
    reload: 'Reload',
  },
  feedback: {
    failed_to_load_tables: 'Something went wrong, please reload',
    failed_to_load_table_data: 'Failed to load table data, please try again',
  },
  toasts: {
    table_changed: 'You successfully changed the table',
  },
};
