import {
  CompanyDetailsInterface,
} from "../../services/exports/Interfaces";
import moment from "moment";
import useCompanyHours from "./useCompanyHours";
import {ORDER_METHODS, ORDERS_PAUSED_REASON} from "../../services/exports/Constants";
import { useTranslation } from "react-i18next";
import useInitialData from "../global/useInitialData";
import useAppMethods from "../utility/useAppMethods";
import {useSelector} from "react-redux";
import {StoreInterface} from "../../store/types";

interface Props {
  isPickup?: boolean;
  data?: CompanyDetailsInterface;
}

export interface OpeningStatusInterface {
  is_open: boolean;
  status_text: string;
  details_text: string;
}

export default function useOpeningStatus(
  props: Props = {}
): OpeningStatusInterface {
  const { t } = useTranslation(null, { keyPrefix: 'Hooks:Availability:UseOpeningStatus' });

  const { isPickup: isPickUpSelected, data } = props;

  const { data: initialData } = useInitialData();
  const { company: companyData } = initialData;
  const { cached_order } = useSelector((state: StoreInterface) => state.order);
  const company = data ?? companyData;

  const { formatTime, formatDateTime, formatScheduleUnit } = useAppMethods();
  const companyHours = useCompanyHours({ data: company });

  const isPickup: boolean =
    isPickUpSelected !== undefined
      ? isPickUpSelected
      : cached_order?.method === ORDER_METHODS.PICKUP;

  function open(detailsText: string): OpeningStatusInterface {
    return {
      is_open: true,
      status_text: t("status.open"),
      details_text: detailsText,
    };
  }

  function closed(detailsText: string): OpeningStatusInterface {
    return {
      is_open: false,
      status_text: t("status.closed"),
      details_text: detailsText,
    };
  }

  if (companyHours?.closedUntil) {
    //Check if company closing ends this week
    if (moment().diff(companyHours?.closedUntil, "week") === 0) {
      return closed(
        formatScheduleUnit(
          companyHours.closedUntil?.add(1, 'day'),
          {
            prefix: t('offline_until'),
            day: true,
            time: false
          }
        )
      );
    }

    return closed(
      `${t("offline_until")} ${formatDateTime(companyHours?.closedUntil, { format: 'DD-MM-YYYY' })}`
    );
  }

  //Company will open the same day
  if (!companyHours?.open && companyHours?.opensToday?.status) {
    return closed(
      formatScheduleUnit(
        companyHours.getCurrentWorkSchedule()?.open,
        { prefix: t('opens_today') }
      )
    );
  }

  //Company is closed and will open tomorrow
  if (!companyHours?.open) {
    return closed(
      formatScheduleUnit(
        companyHours.getNextWorkSchedule()?.open,
        { prefix: t('opens'), day: true, time: true }
      )
    );
  }

  if (
    (isPickup && companyHours?.pickupClosed) ||
    (isPickup === false && companyHours?.deliveryClosed)
  ) {
    return t('outside_ordering_times');
  }

  //Company orders are paused either due to inactivity or connection
  if (
    companyHours?.ordersPausedReason === ORDERS_PAUSED_REASON.INACTIVITY ||
    !company?.has_live_orders
  ) {
    return closed(t('online_orders_paused'));
  }

  //Company order are manually paused
  if (companyHours?.ordersPausedReason === ORDERS_PAUSED_REASON.MANUAL) {
    //Company hours are not paused till end of the day
    if (!companyHours?.orderArePausedTillEndOfDay) {
      return closed(`${t('online_orders_paused_until')} ${formatTime(companyHours?.ordersPausedUntil)}`);
    }

    //Company hours are paused till end of the day, thus will open next day
    return closed(
      formatScheduleUnit(
        companyHours.getNextWorkSchedule()?.open,
        { prefix: t('reopens'), day: true, time: true }
      )
    );
  }

  //Company orders are paused for any reason for a specific time but not till the rest of the day
  if (
    companyHours?.ordersPausedUntil &&
    !companyHours?.orderArePausedTillEndOfDay
  ) {
    return closed(
      formatScheduleUnit(
        companyHours.ordersPausedUntil,
        { prefix: t('reopens'), day: true, time: true }
      )
    );
  }

  if (companyHours.openWholeDay) {
    return open(t('open_whole_day'));
  }

  return open(
    formatScheduleUnit(
      companyHours.getCurrentWorkSchedule()?.open,
      { prefix: t('open') }
    ) + ' - ' + formatScheduleUnit(companyHours.getCurrentWorkSchedule()?.close)
  );
}
