import useCashbackProgram from "../../hooks/cashback/useCashbackProgram";
import {useTranslation} from "react-i18next";
import React, {useMemo} from "react";
import classnames from "classnames";
import useLoyaltyProgram from "../../hooks/loyalty/useLoyaltyProgram";
import RewardIcon from "../../assets/logo/RewardIcon";
import BackIcon from "../../assets/logo/BackIcon";
import useAppMethods from "../../hooks/utility/useAppMethods";
import useTheme from "../../hooks/ui/useTheme";

interface Props {
  price: number;
  quantity: number;
  className?: string;
}

export default function EarnPointsTag(props: Props) {
  const { price, quantity, className } = props;

  const { t } = useTranslation(null, { keyPrefix: 'Components:LoyaltyProgram:EarnPointsTag' });

  const theme = useTheme();
  const { toggleFreeProductsModal } = useAppMethods();
  const { hasLoyaltyPromotion, loyalty, isPerEligibleItem } = useLoyaltyProgram();

  const value = useMemo(
    () => price / quantity > loyalty?.reward_threshold
      ? loyalty?.reward * quantity
      : 0,
    [price, quantity, loyalty]
  );

  return hasLoyaltyPromotion && isPerEligibleItem && value > 0 && (
    <button
      className={classnames('bg-companyBrand-primary/20 w-full py-mini px-small rounded-[8px] flex justify-center items-center relative', className)}
      onClick={() => toggleFreeProductsModal({openModal: true})}
    >
      <RewardIcon fill className="w-[14px] h-[14px]"/>
      <p className="mini semibold text-center text-companyBrand-primary mx-mini uppercase">
        {t('text', { count: value })}
      </p>
      <div className="absolute right-0 rotate-180 mr-mini">
        <BackIcon
          className="w-[14px] h-[14px]"
          color={theme.color.companyBrand.primary}
        />
      </div>
    </button>
  );
}