import useDineInFlow from "../../hooks/global/useDineInFlow";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ORDER_FULFILLMENT_STATUSES, SCREENS } from "../../services/exports/Constants";
import useLoadingScreen from "../../hooks/ui/useLoadingScreen";
import { collect } from "collect.js";
import HelperMethods from "../../services/exports/HelperMethods";
import CustomButton from "../../components-library/buttons/CustomButton";
import ReduxActions from "../../store/ReduxActions";
import { actionCreators } from "../../store/actions";
import { useSelector } from "react-redux";
import CurrencyInput from "react-currency-input-field";
import { CurrencyInputOnChangeValues } from "react-currency-input-field/dist/components/CurrencyInputProps";
import RadioButton from "../../components-library/buttons/RadioButton";
import Divider from "../../components-library/Divider";
import PaymentsBreakdown from "../../components-library/dine-in/PaymentsBreakdown";
import useTheme from "../../hooks/ui/useTheme";
import DineInNavBar from "../../components-library/navigation/DineInNavBar";
import { useTranslation } from "react-i18next";
import HorizontalButtons from "../../components-library/buttons/HorizontalButtons";
import ButtonFooter from "../../components-library/footers/ButtonFooter";
import useNavigate from "../../hooks/navigation/useNavigate";
import useTips from "../../hooks/order/useTips";
import {StoreInterface} from "../../store/types";
import useAppMethods from "../../hooks/utility/useAppMethods";
import {ButtonTypes} from "../../services/exports/Interfaces";

const SplitBillScreen = (): JSX.Element => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "Pages:DineIn:SplitBillScreen",
  });

  const { company } = useSelector((state: StoreInterface) => state.initialData);
  const {
    isDineIn,
    party,
    masterOrder,
    userOrders,
    userPaidOriginal,
    paidAmount,
    unpaidAmount,
    paymentInfo,
  } = useDineInFlow();
  const { customOption: customTipOption } = useTips();

  const { formatCurrency } = useAppMethods();
  const { renderLoadingPage } = useLoadingScreen();
  const { navigate } = useNavigate();
  const theme = useTheme();

  const [amount, setAmount] = useState(
    HelperMethods.roundNumberWithPrecision(getInitialAmount())
  );
  const [formattedAmount, setFormattedAmount] = useState<number | string>(
    HelperMethods.roundNumberWithPrecision(getInitialAmount())?.toFixed(2)
  );
  const [selectedPaymentOption, setSelectedPaymentOption] = useState<number>(4);

  const [loading, setLoading] = useState<boolean>(false);

  const isOvercharged = +amount - unpaidAmount > 0.1;

  const [shouldConfirmTip, setShouldConfirmTip] = useState(false);

  const amountInputRef = useRef(null);

  const OPTIONS = {
    PAY_FULL: 1,
    PAY_YOURS: 2,
    PAY_EQUALLY: 3,
    PAY_CUSTOM: 4,
  };

  const getUserOrdersAmount = useCallback(() => {
    return +userOrders
      .where("status", "!=", ORDER_FULFILLMENT_STATUSES.REJECTED)
      .sum("total");
  }, [userOrders]);

  const getEqSplitAmount = useCallback(() => {
    const amount = masterOrder?.total / party?.users?.length - userPaidOriginal;
    const diff = masterOrder?.total - amount;

    return diff >= 1
      ? HelperMethods.ceilNumberWithPrecision(amount)
      : HelperMethods.ceilNumberWithPrecision(diff);
  }, [masterOrder?.total, party?.users?.length, userPaidOriginal]);

  const userLeftToPay = useMemo(
    () => getUserOrdersAmount() - userPaidOriginal,
    [userOrders, userPaidOriginal]
  );

  useEffect(() => {
    if (!isDineIn) {
      return navigate(SCREENS.HOME_SCREEN);
    }
  }, []);

  useEffect(() => {
    if (userLeftToPay > unpaidAmount) {
      return onSelectPayFull();
    }

    if (userLeftToPay > 1) {
      return onSelectPayForMyItems();
    }
  }, []);

  useEffect(() => {
    if (!isOvercharged) {
      setShouldConfirmTip(false);
    }
  }, [isOvercharged]);

  function getInitialAmount() {
    if (!paymentInfo) {
      return 0;
    }

    return (
      (paymentInfo?.amount ?? 0) +
      (!paymentInfo.tip_details?.percentage
        ? paymentInfo.tip_details?.price ?? 0
        : 0)
    );
  }

  function onSelectPayFull() {
    setSelectedPaymentOption(OPTIONS.PAY_FULL);
    setAmount(unpaidAmount);
    setFormattedAmount(unpaidAmount?.toFixed(2));
  }

  function onSelectPayForMyItems() {
    setSelectedPaymentOption(OPTIONS.PAY_YOURS);
    setAmount(userLeftToPay);
    setFormattedAmount(userLeftToPay?.toFixed(2));
  }

  function onSelectDivideEqually() {
    const sum = getEqSplitAmount();

    setSelectedPaymentOption(OPTIONS.PAY_EQUALLY);
    setAmount(sum);
    setFormattedAmount(sum?.toFixed(2));
  }

  const paymentOptions = collect([
    {
      id: OPTIONS.PAY_FULL,
      name:
        paidAmount > 0
          ? t("payment_options.pay_rest")
          : t("payment_options.pay_full"),
      onSelect: onSelectPayFull,
    },
  ])
    .when(
      userLeftToPay <= unpaidAmount && userLeftToPay > 1,
      // @ts-ignore
      (items) =>
        items.push({
          id: OPTIONS.PAY_YOURS,
          name: t("payment_options.pay_yours"),
          onSelect: onSelectPayForMyItems,
        }),
      (items) => items
      //@ts-ignore
    )
    //@ts-ignore
    .when(
      getEqSplitAmount() >= 1 &&
        (unpaidAmount === getEqSplitAmount() ||
          unpaidAmount - getEqSplitAmount() >= 1),
      // @ts-ignore
      (items) =>
        items.push({
          id: OPTIONS.PAY_EQUALLY,
          name: t("payment_options.pay_equally"),
          onSelect: onSelectDivideEqually,
        }),
      (items) => items
    )
    .merge([
      {
        id: OPTIONS.PAY_CUSTOM,
        name: t("payment_options.pay_custom"),
        onSelect: () => {
          setSelectedPaymentOption(OPTIONS.PAY_CUSTOM);
          amountInputRef.current.focus();
        },
      },
    ])
    .toArray();

  function goBack() {
    return navigate(SCREENS.DINE_IN);
  }

  function toggleLoading(timeout: number = 500, callback?: () => void) {
    setTimeout(() => {
      setLoading(false);
      callback && callback();
    }, timeout);
  }

  function onChangeAmount(
    value: string | undefined,
    name: string,
    values?: CurrencyInputOnChangeValues
  ) {
    setSelectedPaymentOption(OPTIONS.PAY_CUSTOM);
    setAmount(values.float);
    setFormattedAmount(value);
  }

  async function onConfirm(selectedAmount: number) {
    setLoading(true);
    toggleLoading(500, () => {
      if (isOvercharged && !shouldConfirmTip) {
        return setShouldConfirmTip(true);
      }

      const tip =
        selectedAmount - unpaidAmount > 0.1
          ? HelperMethods.roundNumberWithPrecision(+amount - unpaidAmount)
          : 0;

      ReduxActions.dispatch(
        actionCreators.dineInFlow.setPaymentInfo({
          amount: selectedAmount - tip,
        })
      );

      navigate(SCREENS.CHECKOUT, {
        state: tip
          ? {
              order: {
                tip,
              },
              defaultActiveTipIndex: customTipOption.index,
            }
          : null,
      });
    });
  }

  const renderBillInfo = useMemo(() => {
    if (paidAmount === 0) {
      return (
        <div className="p-small">
          {isOvercharged && shouldConfirmTip && (
            <div className="mb-mini">
              <h6 className="text-center">{t("titles.overcharged")}</h6>
              <p className="text-center">{t("text.overcharged")}</p>
            </div>
          )}
          <div className="flex justify-between">
            <h6>{t("titles.total_bill")}</h6>
            <h6 className="underline underline-offset-2">
              {formatCurrency(masterOrder?.total)}
            </h6>
          </div>
        </div>
      );
    }

    return (
      <div className="p-small">
        {isOvercharged && shouldConfirmTip && (
          <div className="mb-mini">
            <h6 className="text-center">{t("titles.overcharged")}</h6>
            <p className="text-center">{t("text.overcharged")}</p>
          </div>
        )}
        <div className="flex justify-between">
          <h6 className="text-mini font-normal">{t("titles.total_bill")}</h6>
          <h6 className="text-mini font-normal">
            {formatCurrency(masterOrder?.total)}
          </h6>
        </div>
        <div className="flex justify-between mt-small">
          <h6>{t("titles.left_to_pay")}</h6>
          <h6 className="underline underline-offset-2">
            {formatCurrency(unpaidAmount)}
          </h6>
        </div>
        <PaymentsBreakdown className="mt-small" />
      </div>
    );
  }, [masterOrder, paidAmount, unpaidAmount, isOvercharged, shouldConfirmTip]);

  const renderInvalidAmountFeedback = useMemo(() => {
    if (+amount === 0) {
      return null;
    }

    if (+amount < 1) {
      return (
        <div className="p-small">
          <div className="p-mini bg-brand-warning rounded-lg">
            <h6>{t("feedback.min_payment_amount.title", {
              amount: formatCurrency(1),
            })}</h6>
            <p className="mt-mini">{t("feedback.min_payment_amount.text", {
              amount: formatCurrency(1),
            })}</p>
          </div>
        </div>
      );
    }

    if (+amount < unpaidAmount && unpaidAmount - +amount < 1) {
      return (
        <div className="p-small">
          <div className="p-mini bg-brand-warning rounded-lg">
            <h6>{t("feedback.min_amount_after_payment.title", {
              amount: formatCurrency(1),
            })}</h6>
            <p className="mt-mini">
              {t("feedback.min_amount_after_payment.text", {
                amount: formatCurrency(1),
              })}
            </p>
          </div>
        </div>
      );
    }

    return null;
  }, [amount]);

  const renderPaymentOptions = useMemo(
    () =>
      paymentOptions &&
      !shouldConfirmTip && (
        <>
          <h6 className="p-small">{t("titles.payment_options")}</h6>
          {paymentOptions?.map((item, index) => (
            <div
              onClick={item?.onSelect}
              className="mt-small"
              key={`payment-option-${item?.id}`}
            >
              <button className="flex justify-between px-small w-full touchable-highlight">
                <p className="text-mini">{item?.name}</p>
                <RadioButton
                  active={selectedPaymentOption === item?.id}
                  color={theme.color.brand.inkGrey.grey_3}
                />
              </button>
              {index !== paymentOptions?.length - 1 && (
                <Divider className="my-small" />
              )}
            </div>
          ))}
        </>
      ),
    [paymentOptions, selectedPaymentOption, shouldConfirmTip]
  );

  const renderFooter = useMemo(() => {
    function isDisabled() {
      return (
        !amount ||
        +amount < 1 ||
        (+amount < unpaidAmount && unpaidAmount - +amount < 1)
      );
    }

    if (isOvercharged && shouldConfirmTip) {
      return (
        <ButtonFooter className="fixed bottom-0">
          <HorizontalButtons
            leftButtonProps={{
              title: t("buttons.add_as_tip"),
              buttonType: ButtonTypes.third,
              onClick: () => onConfirm(+amount),
              loading: loading,
            }}
            rightButtonProps={{
              title: t("buttons.pay_only", {
                amount: formatCurrency(unpaidAmount),
              }),
              onClick: () => onConfirm(unpaidAmount),
              loading: loading,
            }}
          />
        </ButtonFooter>
      );
    }

    return (
      <ButtonFooter className="fixed bottom-0">
        <CustomButton
          title={t("buttons.confirm")}
          disabled={isDisabled()}
          onClick={() => onConfirm(+amount)}
          loading={loading}
        />
      </ButtonFooter>
    );
  }, [amount, unpaidAmount, loading, isOvercharged, shouldConfirmTip]);

  return (
    <>
      <header className="w-full sticky top-0 z-10">
        <DineInNavBar title={t("header")} rightClick={goBack} hideBackIcon />
      </header>
      <main className="w-full h-screen relative overflow-x-hidden">
        {renderBillInfo}
        <div className="p-small bg-brand-inkGrey-grey_6">
          <CurrencyInput
            ref={amountInputRef}
            value={formattedAmount}
            defaultValue="0,00"
            onValueChange={onChangeAmount}
            decimalSeparator=","
            groupSeparator="."
            allowNegativeValue={false}
            disableGroupSeparators={true}
            intlConfig={{
                locale: HelperMethods.guessLocaleFromCurrency(company?.currency ?? 'EUR'),
                currency: company?.currency ?? 'EUR'
            }}
            prefix={company?.currency}
            className="text-h1 w-full font-semibold"
            placeholder={company?.currency}
          />
          {isOvercharged && shouldConfirmTip && (
            <p className="text-mini">{t("text.entered_amount")}</p>
          )}
        </div>
        {renderInvalidAmountFeedback}
        {renderPaymentOptions}
      </main>
      {renderFooter}
      {renderLoadingPage}
    </>
  );
};

export default SplitBillScreen;
