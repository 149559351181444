import { useState, useEffect, useMemo } from "react";
import { CategoryInterface } from "../../../services/exports/Interfaces";
import MobileMenuSideBar from "./MobileMenuSideBar";
import useScreenType from "../../../hooks/utility/useScreenType";
import DesktopMenuSideBar from "./DesktopMenuSideBar";
import useMenu from "../../../hooks/menu/useMenu";

interface Props {
  open: boolean;
  focusedCategoryId: number;
  menuSideBarStickyPosition?: number;
  toggle: (open: boolean) => void;
  onSelectCategory: (category: CategoryInterface) => void;
}

const MenuSideBar = (props: Props): JSX.Element | null => {
  const {
    open,
    focusedCategoryId,
    menuSideBarStickyPosition,
    toggle,
    onSelectCategory,
  } = props;

  const { categories } = useMenu();

  const { isDesktop } = useScreenType();

  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    if (open) {
      setShow(true);
    }
  }, [open]);

  function _onSelectCategory(category: CategoryInterface): void {
    onSelectCategory(category);
    toggle(false);
  }

  function _onSelectCategoryMobile(category: CategoryInterface): void {
    toggle(false);
    setTimeout(() => onSelectCategory(category), 250);
  }

  const renderDesktopMenuSideBar = useMemo(
    () => (
      <DesktopMenuSideBar
        open={open}
        setOpen={toggle}
        categories={categories}
        focusedCategoryId={focusedCategoryId}
        menuSideBarStickyPosition={menuSideBarStickyPosition}
        onSelectCategory={_onSelectCategory}
      />
    ),
    [open, categories, focusedCategoryId, menuSideBarStickyPosition]
  );

  const renderMobileMenuSideBar = useMemo(
    () => (
      <MobileMenuSideBar
        open={open}
        show={show}
        setOpen={toggle}
        setShow={setShow}
        categories={categories}
        focusedCategoryId={focusedCategoryId}
        onSelectCategory={_onSelectCategoryMobile}
      />
    ),
    [open, show, categories, focusedCategoryId]
  );

  return isDesktop ? renderDesktopMenuSideBar : renderMobileMenuSideBar;
};

export default MenuSideBar;
