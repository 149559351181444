import LoadingIndicator from "./LoadingIndicator";

interface Props {
  description: string;
  containerStyle?: string;
}

export default function LoadingWithText(props: Props): JSX.Element {
  const { description, containerStyle } = props;
  return (
    <div className={containerStyle ?? container}>
      <div className={wrapper}>
        <div className={loadingWrapper}>
          <div className="-mb-[10px]">
            <LoadingIndicator lg={60} md={50} sm={40} />
          </div>
        </div>
        <p className={descriptionStyle}>{description}</p>
      </div>
    </div>
  );
}

const container =
  "w-full h-full z-50 fixed top-0 bg-black/60";

const wrapper =
  "w-full h-full z-50 fixed top-0 flex flex-col justify-center items-center";

const loadingWrapper =
  "bg-background-inkWhite-white_1 lg:h-24 lg:w-24 md:h-20 md:w-20 h-16 w-16 rounded-lg flex justify-center items-center";

const descriptionStyle = "text-white text-center mt-medium px-medium";
