export default {
  point: "Punkt",
  point_other: "Punkte",
  status: {
    descriptions: {
      description_3:
        "Nachdem du deine Prämie für Rabatte einlöst, gehst du nicht auf 0 Punkte zurück, sondern sammelst weiter Punkte ab {{points}}.",
    },
    titles: {
      title_1: "Verdiene Punkte, erhalte Rabatte 💵",
      title_2: "{{discount}}% verdient 🥳",
    },
  },
};
