import {
  TableInterface,
} from "../exports/Interfaces";
import BackendApiClient from "./BackendApiClient";
import {
  DataWrapper,
  DataWrappedResponse,
} from "../exports/Types";
import { actionCreators } from "../../store/actions";
import ReduxActions from "../../store/ReduxActions";

class TablesManager {
  public get = async (
      companyId: number,
  ): Promise<DataWrappedResponse<DataWrapper<TableInterface[]>>> => BackendApiClient.request({
    method: 'GET',
    url: `/companies/${companyId}/tables`,
  });

  public show = async (id: number): Promise<DataWrappedResponse<DataWrapper<TableInterface>>> => {
    const request = await BackendApiClient.request({
      method: 'GET',
      url: `/tables/${id}`,
    });

    const { success, response } = request;

    if (success) {
      this.saveDataRedux(response?.data?.data);
    } else {
      this.errorHandler(response);
    }

    return request;
  }

  private errorHandler(error: any) {
    ReduxActions.dispatch(
        actionCreators.dineInFlow.reset()
    );
  }

  private saveDataRedux(data: TableInterface) {
    ReduxActions.batchActions([
      actionCreators.dineInFlow.setTable(data),
      actionCreators.dineInFlow.setParty(data.party),
    ]);
  }
}

export default new TablesManager();
