import { useMemo, useRef, createRef, useLayoutEffect } from "react";
import classnames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import CustomModal from "../../CustomModal";
import { CategoryInterface } from "../../../services/exports/Interfaces";
import { useTranslation } from "react-i18next";
import useMenu from "../../../hooks/menu/useMenu";

interface Props {
  open: boolean;
  show: boolean;
  categories: CategoryInterface[];
  focusedCategoryId: number;
  setOpen: (open: boolean) => void;
  setShow: (show: boolean) => void;
  onSelectCategory: (category: CategoryInterface) => void;
}

const MobileMenuSideBar = (props: Props): JSX.Element => {
  const {
    open,
    show,
    categories,
    focusedCategoryId,
    setOpen,
    setShow,
    onSelectCategory,
  } = props;

  const { t } = useTranslation(null, {
    keyPrefix: 'Components:Menu:MenuNavigationBar:MobileMenuSideBar',
  });

  const { selectedMenu } = useMenu();

  const animationDuration: number = 0.25;

  const sideBarWidth: number = window.innerWidth * 0.75;

  const openRef = useRef(open);
  openRef.current = open;

  const itemListRefs = categories?.reduce((acc: any, value: any) => {
    acc[value?.id] = createRef();
    return acc;
  }, {});

  useLayoutEffect(() => {
    if (openRef.current) {
      scrollToElement(focusedCategoryId);
    }
  }, [openRef.current, itemListRefs]);

  function scrollToElement(id: number): void {
    try {
      itemListRefs[id]?.current?.scrollIntoView({ block: "center" });
    } catch (error) {}
  }

  const renderBackgroundOverlay = (): JSX.Element => {
    const initial = {
      opacity: 0,
    };
    return (
      <AnimatePresence>
        {openRef.current && (
          <motion.div
            className={`absolute h-full w-full bg-black/50`}
            initial={initial}
            animate={{
              opacity: 1,
            }}
            exit={initial}
            transition={{
              duration: animationDuration,
              ease: "easeOut",
            }}
            onClick={() => setOpen(false)}
            onAnimationComplete={() =>
              !openRef.current ? setShow(false) : undefined
            }
          />
        )}
      </AnimatePresence>
    );
  };

  const renderCategories = useMemo(
    () =>
      categories &&
      categories?.map((item, index) => (
        <div
          className={classnames(
            "p-small cursor-pointer text-default",
            {
              "text-companyBrand-primary": focusedCategoryId === item?.id,
            },
            {
              "border-b": index !== categories?.length - 1,
            }
          )}
          onClick={() => onSelectCategory(item)}
          key={`sidebar-category-${item?.id}`}
          ref={itemListRefs[item?.id]}
        >
          <h6 className="text-mini">{item?.id > 0 ? item?.name : t("free")}</h6>
        </div>
      )),
    [categories, focusedCategoryId, itemListRefs]
  );

  const renderSideBar = (): JSX.Element => {
    return (
      <AnimatePresence>
        {openRef.current && (
          <motion.div
            className={`fixed top-0 h-full w-3/4 bg-background-inkWhite-white_1 shadow overflow-y-auto`}
            initial={{ x: -sideBarWidth }}
            animate={{
              x: 0,
            }}
            exit={{ x: -sideBarWidth }}
            transition={{
              duration: animationDuration,
              ease: "easeOut",
            }}
          >
            <div className="p-small cursor-pointer text-default sticky top-0 bg-background-inkWhite-white_1 border-b">
              <h6>{selectedMenu?.name ?? t('full_menu')}</h6>
            </div>

            {renderCategories}
          </motion.div>
        )}
      </AnimatePresence>
    );
  };

  return show ? (
    <CustomModal
      open={true}
      fullScreen={true}
      transparent
      hideBackgroundOverlay
    >
      {renderBackgroundOverlay()}
      {renderSideBar()}
    </CustomModal>
  ) : null;
};

export default MobileMenuSideBar;
