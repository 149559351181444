import {OTP_DELIVERY_CHANNELS} from '../../../../../../services/exports/Constants';

export default {
  otp: {
    resend: {
      options: {
        [OTP_DELIVERY_CHANNELS.EMAIL]: {
          title: 'Didn\'t receive the code via email?',
          button: 'Send Code via SMS →',
        },
        [OTP_DELIVERY_CHANNELS.SMS]: {
          title: 'Didn\'t receive the code?',
          button: 'Resend Code via SMS →',
        },
      }
    },
    text: {
      [OTP_DELIVERY_CHANNELS.SMS]: 'A message with a verification code has been sent to your phone. Enter the code to continue.',
      [OTP_DELIVERY_CHANNELS.EMAIL]: 'Please enter the code sent to this address: <br/> <strong>{{address}}</strong>',
    },
    tip: {
      [OTP_DELIVERY_CHANNELS.SMS]: '',
      [OTP_DELIVERY_CHANNELS.EMAIL]: 'Tip: Check your inbox and your spam folder.',
    },
  },
  terms_and_conditions: {
    terms: 'terms and conditions',
    conditions: 'privacy policy',
    accept_terms:
      'By signing up you agree to our <TermsLink>{{terms}}</TermsLink> and consent to our <ConditionsLink>{{conditions}}</ConditionsLink>.',
  },
  phone_number_input: {
    placeholder: 'Enter phone number',
  },
  custom_button: {
    continue: 'Continue',
  },
  error_messages: {
    frequent_requests:
      'Please check your SMS. We have already sent the code to the given number via SMS. If not, please check the phone number or try again in 10 minutes.',
    invalid_number: 'Invalid number. Please try again.',
    change_phone_number_unsuccessful:
      'Failed to change phone number. Please try again.',
    invalid_code: 'Invalid code. Please try again.',
  },
  toasts: {
    code_sent: 'Code sent',
  },
};
