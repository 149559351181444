export class Stringable {
  private value: string;

  public constructor(value: string) {
    this.value = value;
  }

  public start(prefix: string): this {
    if (! this.value) {
      return this;
    }

    if (this.value.startsWith(prefix)) {
      return this;
    }

    this.value = `${prefix}${this.value}`;

    return this;
  }

  public squish(): this {
    this.value = this.value.replace(/\s+/g, ' ')
      .trim();

    return this;
  }

  public toString(): string {
    return this.value
  }
}
