import CustomButton from "../../buttons/CustomButton";
import {useTranslation} from "react-i18next";
import CheckCircleIcon from "../../../assets/logo/CheckCircleIcon";
import classnames from "classnames";
import useTheme from "../../../hooks/ui/useTheme";
import useAppMethods from "../../../hooks/utility/useAppMethods";

interface Props {
  title: string;
  items: string[];
  buttonTitle: string;
  price: number;
  onSelect: () => void;
  discount?: number;
  className?: string;
}

export default function ComboUpsellItem(props: Props) {
  const { t } = useTranslation(null, { keyPrefix: 'Components:Menu:Combo:ComboUpsellItem' });

  const {
    title,
    items,
    buttonTitle,
    onSelect,
    price,
    discount,
    className,
  } = props;

  const { formatCurrency } = useAppMethods();

  const theme = useTheme();

  return (
    <div className={classnames('w-full flex flex-col justify-between p-small rounded-lg border shadow', className)}>
      <div>
        <h6>{title}</h6>
        {discount > 0 && (
          <div className="w-fit rounded-lg bg-brand-primary/[.125] px-mini py-tiny mt-small">
            <h6 className="text-mini text-brand-primary">{t('discount', { discount: formatCurrency(discount) })}</h6>
          </div>
        )}
        {items.map((item, index) => (
          <div
            className="flex mt-small"
            key={`combo-item-${item}-${index}`}
          >
            <CheckCircleIcon
              className="h-4 w-4 mr-mini my-auto"
              color={theme.color.companyBrand.primary}
            />
            <p className="text-mini my-auto">{item}</p>
          </div>
        ))}
      </div>
      <CustomButton
        title={(
          <div className="w-full flex justify-between">
            <h6 className="text-mini text-white">{buttonTitle}</h6>
            <h6 className="text-mini text-white">{formatCurrency(price)}</h6>
          </div>
        )}
        onClick={onSelect}
        className="mt-small"
      />
    </div>
  );
}