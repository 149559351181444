export default {
  note: {
    title: 'Notiz für Küche',
    placeholder: 'Allergien oder besondere Wünsche?',
  },
  error_messages: {
    missing_fields: "Bitte wähle alle erforderlichen Felder aus.",
    product_unavailable: "Ausverkauft!",
  },
};
