import detectBrowserLanguage from "detect-browser-language";
import ProfileManager from "../api/ProfileManager";
import AuthorizationTokenStorage from "../auth/AuthorizationTokenStorage";
import { AVAILABLE_LANGUAGES } from "../exports/Constants";

export const LANGUAGE_KEY = "LANGUAGE";
export const LANGUAGE_PARAM = "lng";

class LocalizationManager {
  /**
   * Get the current saved language
   * @method getLanguage
   */
  getLanguage() {
    return localStorage.getItem(LANGUAGE_KEY);
  }

  /**
   * Save new language
   * @method saveLanguage
   * @param language - language string eg. en, de etc.
   */
  saveLanguage(language) {
    localStorage.setItem(LANGUAGE_KEY, language);
    if (AuthorizationTokenStorage.getToken()) {
      ProfileManager.putProfile({
        lang: language,
      });
    }
  }

  /**
   * Get the default language from url
   * @method getLanguageFromUrl
   */
  getLanguageFromUrl() {
    try {
      const url = window.location.href;
      const query = "lng?=";
      if (url?.includes(query)) {
        return url?.split(query)[1];
      }
      return null;
    } catch (error) {
      return null;
    }
  }

  getPreferredLanguage() {
    try {
      const preferredBrowserLanguage = detectBrowserLanguage();
      const language = preferredBrowserLanguage?.split(/-|_/)[0];
      const defaultLanguage = AVAILABLE_LANGUAGES?.find(
        (item) => item?.language === language
      )?.language;
      
      if (!defaultLanguage) {
        return null;
      }
      
      return defaultLanguage;
    } catch (error) {
      return null;
    }
  }
}

export default new LocalizationManager();
