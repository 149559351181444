import {useEffect, useMemo, useState, useRef} from "react";
import classnames from "classnames";
import HeaderBar from "../components-library/headers/HeaderBar";
import UpSellModal from "../modals/UpSellModal";
import OrderBasketItem from "../components-library/basket/OrderBasketItem";
import {DeliveryProvider, ERROR_CODES, ORDER_METHODS, PROMO_CODE_DISCOUNT_TYPES, PROMO_CODE_TYPES, SCREENS,} from "../services/exports/Constants";
import CheckoutNavBar from "../components-library/navigation/CheckoutNavBar";
import LoadingPage from "../components-library/loading/LoadingPage";
import {useSelector} from "react-redux";
import {ProductInterface} from "../services/exports/Interfaces";
import CompanyManager from "../services/api/CompanyManager";
import useScreenType from "../hooks/utility/useScreenType";
import useTheme from "../hooks/ui/useTheme";
import DeliveryThreshold from "../components-library/delivery/DeliveryThreshold";
import useDeliveryThreshold from "../hooks/order/useDeliveryThreshold";
import OrderValidationManager from "../services/api/OrderValidationManager";
import useDineInFlow from "../hooks/global/useDineInFlow";
import CheckoutThreshold from "../components-library/checkout/CheckoutThreshold";
import useCheckoutThreshold from "../hooks/order/useCheckoutThreshold";
import CartFooter from "../components-library/footers/CartFooter";
import CheckoutButton from "../components-library/buttons/CheckoutButton";
import {Trans, useTranslation} from "react-i18next";
import useInitialData from "../hooks/global/useInitialData";
import BackIcon from "../assets/logo/BackIcon";
import useNavigate from "../hooks/navigation/useNavigate";
import useRouteMatch from "../hooks/navigation/useRouteMatch";
import useBasket from "../hooks/basket/useBasket";
import {CompleteBasketItemInterface} from "../context/types";
import {StoreInterface} from "../store/types";
import EmptyBasketIllustration from "../assets/illustrations/EmptyBasketIllustration";
import OrderType from "../components-library/order-type/OrderType";
import useAppMethods from "../hooks/utility/useAppMethods";
import useOrderMethodsStatus from "../hooks/availability/useOrderMethodsStatus";
import moment from "moment";
import useSessionStorage, {SESSION_STORAGE_KEYS} from "../hooks/global/useSessionStorage";
import {COMPLETION_STATUS} from "../modals/OrderMethodModal";
import LabelIcon from "../assets/logo/LabelIcon";
import PromoCodeInput from "../components-library/checkout/PromoCodeInput";

interface Props {
  customParentContainer?: string;
  customParentStyle?: Object;
}

const OrderBasketScreen = (props: Props): JSX.Element => {
  const { t } = useTranslation(null, { keyPrefix: "Pages:OrderBasketScreen" });

  const { customParentContainer, customParentStyle } = props;

  const { data, refresh } = useInitialData();
  const { company } = data;
  const { cached_order } = useSelector((state: StoreInterface) => state.order);

  const theme = useTheme();
  const { isDesktop } = useScreenType();
  const routeMatch = useRouteMatch();
  const { navigate } = useNavigate();
  const { getItem } = useSessionStorage();

  const { hasDeliveryThreshold, isWithinThreshold } = useDeliveryThreshold();
  const { hasReachedCheckoutThreshold } = useCheckoutThreshold();
  const { 
    saveMenu, 
    toggleChangeTablePartyModal, 
    toggleOrderMethodModal,
    formatCurrency
  } = useAppMethods();
  const { items, refreshBasket, formatForBackend, getProductIds } = useBasket();
  const { isOrderMethodCurrentlyOpen, isOrderMethodOpenForFutureOrders } =
    useOrderMethodsStatus();
  const { isDineIn, party, userOrdered, table } = useDineInFlow();

  const [showUpSellModal, setShowUpSellModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [upSellProducts, setUpSellProducts] = useState<ProductInterface[]>([]);
  const [validating, setValidating] = useState(false);
  const [loadingUpSellProducts, setLoadingUpSellProducts] = useState(false);

  type PromoCodeInputRefType = {
    setPromoCode: (promoCode: any) => void;
  };

  const promoCodeInputRef = useRef<PromoCodeInputRefType>();

  const orderMethodInfoComplete = getItem(SESSION_STORAGE_KEYS.order_method_info_status) === COMPLETION_STATUS.complete
    && (
      cached_order?.method === ORDER_METHODS.PICKUP
      || (cached_order?.method === ORDER_METHODS.ROOM_SERVICE && !!cached_order?.room_service_location_id)
      || (cached_order?.method === ORDER_METHODS.DELIVERY && company?.delivery_provider === DeliveryProvider.Standalone && !!cached_order?.delivery_zone?.id)
      || (cached_order?.method === ORDER_METHODS.DELIVERY && company?.delivery_provider === DeliveryProvider.FirstDelivery && !!cached_order?.delivery_zone?.id && !!cached_order?.delivery_quote?.id)
    );

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshBasket();
  }, []);

  useEffect(() => {
    if (isDineIn) {
      saveMenu({ menuLoaded: true });
    }
  }, [isDineIn]);

  useEffect(() => {
    if (!isDesktop) {
      setLoading(true);
      setTimeout(() => setLoading(false), 500);
    } else {
      setLoading(false);
    }
  }, [isDesktop]);

  function toggleUpSellModal(products?: ProductInterface[]) {
    setShowUpSellModal(!showUpSellModal);
    if (products) {
      setUpSellProducts(products);
    }
  }

  async function validateBeforeProceeding(): Promise<void> {
    setValidating(true);
    if (!(await isValid())) {
      return setValidating(false);
    }
    setValidating(false);

    if (!table && isDineIn) {
      return toggleChangeTablePartyModal({
        openModal: true,
        onTableSelected: onProceed,
      });
    }

    onProceed();
  }

  function onProceed(): Promise<void> {
    if (
      (!isDesktop || routeMatch(SCREENS.HOME_SCREEN)) &&
      (!isDineIn || !userOrdered)
    ) {
      return requestSuggestions();
    }

    proceedToNextScreen();
  }

  function proceedToNextScreen(): void {
    if (isDineIn) {
      return navigate(userOrdered ? SCREENS.CONFIRM_ORDER : SCREENS.TABLE, {
        state: {
          prevPath: window.location.pathname,
        },
      });
    }

    navigate(SCREENS.CHECKOUT);
  }

  async function isValid(): Promise<boolean> {
    if (!isDineIn && !orderMethodInfoComplete) {
      toggleOrderMethodModal({ openModal: true });

      return false;
    }

    if (
      cached_order.method === ORDER_METHODS.ROOM_SERVICE &&
      ((!company?.table_number_hidden
        ? !cached_order.room_service_details
        : false) ||
        !cached_order.room_service_location_id)
    ) {
      toggleOrderMethodModal({ openModal: true });

      return false;
    }

    if (
      cached_order?.scheduled_for &&
      moment(cached_order?.scheduled_for).isBefore(moment())
    ) {
      handleOrderTimeNotAvailable();

      return false;
    }

    return new OrderValidationManager().validate(
      {
        company_id: company?.id,
        party_id: party?.id,
        room_service_location_id: cached_order?.room_service_location_id,
        delivery_quote_id: cached_order.delivery_quote?.id,
        method: isDineIn ? ORDER_METHODS.DINE_IN : cached_order.method,
        line_items: formatForBackend(),
        scheduled_for: cached_order.scheduled_for,
        room_service_details: cached_order?.room_service_details,
        delivery_street_name: cached_order.delivery_street_name,
        delivery_street_number: cached_order.delivery_street_number,
        delivery_city: cached_order.delivery_city,
        delivery_state: cached_order.delivery_state,
        delivery_country: cached_order.delivery_country,
        delivery_zip_code: cached_order.delivery_zip_code,
        delivery_lat: cached_order.delivery_lat,
        delivery_lng: cached_order.delivery_lng,
      },
      {
        [ERROR_CODES.ORDER_TIME_NOT_AVAILABLE]: handleOrderTimeNotAvailable,
        [ERROR_CODES.PICKUP_CLOSED]: handleOrderTimeNotAvailable,
        [ERROR_CODES.DELIVERY_CLOSED]: handleOrderTimeNotAvailable,
        [ERROR_CODES.ITEMS_OUT_OF_STOCK]: handleItemsOutOfStock,
        [ERROR_CODES.DINE_IN_CLOSED]: handleDineInClosed,
        [ERROR_CODES.ADDRESS_OUTSIDE_DELIVERY_ZONE]:
          handleAddressOutsideDeliveryArea,
      }
    );
  }

  function handleOrderTimeNotAvailable(): void {
    navigate(SCREENS.HOME_SCREEN);
    toggleOrderMethodModal({ openModal: true });
  }

  function handleItemsOutOfStock(): Promise<void> | void {
    if (isDineIn) {
      return refresh().then(() => window.location.reload());
    }

    return refresh().then(() => navigate(SCREENS.HOME_SCREEN));
  }

  function handleDineInClosed(): void {
    return navigate(SCREENS.DINE_IN);
  }

  function handleAddressOutsideDeliveryArea(): void {
    navigate(SCREENS.HOME_SCREEN);
    toggleOrderMethodModal({ openModal: true });
  }

  async function requestSuggestions(): Promise<void> {
    setLoadingUpSellProducts(true);

    const { response, success } = await CompanyManager.getSuggestedProducts(
      company?.id,
      getProductIds()
    );

    setLoadingUpSellProducts(false);
    if (success && response?.data?.data?.length > 0) {
      return toggleUpSellModal(response?.data?.data);
    }

    proceedToNextScreen();
  }

  function goBack(): void {
    isDineIn ? navigate(SCREENS.MENU) : navigate(SCREENS.HOME_SCREEN);
  }

  const renderCheckoutButton = (): JSX.Element => {
    const title = isDineIn ? t('buttons.continue') : t('buttons.checkout');
    const disabled = ! hasReachedCheckoutThreshold
      || (hasDeliveryThreshold && !isWithinThreshold)
      || (! isOrderMethodCurrentlyOpen(cached_order?.method) && ! isOrderMethodOpenForFutureOrders(cached_order?.method));

    return (
      <div className={buttonContainer}>
        {isDesktop ? (
          <CheckoutButton
            title={title}
            onClick={validateBeforeProceeding}
            loading={validating}
            disabled={disabled}
          />
        ) : (
          <CartFooter
            title={title}
            onClick={validateBeforeProceeding}
            loading={validating || loadingUpSellProducts}
            disabled={disabled}
          />
        )}
      </div>
    );
  };

  const renderHeader = (): JSX.Element => {
    return (
      <div className={headerContainer} id="order-basket-header">
        {isDesktop && <HeaderBar title={t("header")} />}
        {!isDesktop && <CheckoutNavBar title={t("header")} goBack={goBack} />}
      </div>
    );
  };

  const renderSelectTable = (): JSX.Element | undefined =>
    !table &&
    isDineIn && (
      <button
        className="flex justify-between items-center p-small w-full bg-brand-inkDanger-danger_1"
        onClick={() =>
          toggleChangeTablePartyModal({
            openModal: true,
          })
        }
      >
        <p className="mini semibold text-brand-danger">
          {t("table.add_table_number")}
        </p>
        <div className="rotate-180">
          <BackIcon className="w-3 h-3" color={theme.color.brand.danger} />
        </div>
      </button>
    );

  const renderOrderType = () =>
    isDesktop && (
      <div className="px-small mt-mini">
        <OrderType />
      </div>
    );

  const renderPromoCodeBanner = (): JSX.Element | null => {
    const promoCode = company?.promo_codes?.length ? company.promo_codes[0] : null;

    if (!promoCode || !company?.show_master_promo_code) {
      return null;
    }

    const isApplied = cached_order?.coupon_details?.code === promoCode.code;

    const applyPromoCode = () => {
        promoCodeInputRef.current.setPromoCode(promoCode);
    }

    const formattedDiscount = promoCode.discount_type === PROMO_CODE_DISCOUNT_TYPES.PERCENTAGE ?
            promoCode.components.find(component => component.is_used === false).discount + '%':
            formatCurrency(promoCode.discount, { trimInteger: true });

    const renderRedeemButton = () => {
      return (
        <div
          className="rounded-full bg-white border border-brand-primary mt-mini p-mini flex justify-center cursor-pointer"
          onClick={applyPromoCode}
        >
          <span className="font-semibold text-brand-primary text-xtiny">
            {t('promo_code_banner.redeem')}
          </span>
        </div>
      );
    }

    const renderDisabledButton = () => {
      return (
        <div
          className="rounded-full bg-background-inkDisabled-disabled_3 border border-background-inkDisabled-disabled_3 mt-mini p-mini flex justify-center"
        >
          <span className="font-semibold text-white text-xtiny">
            {t('promo_code_banner.redeemed')}
          </span>
        </div>
      );
    }

    return (
      <div className="mx-small mt-small p-xsmall bg-background-inkGreen-green_3 rounded-xl">
        <div className="py-tiny px-xmini bg-brand-primary w-fit rounded flex items-center">
          <LabelIcon className="w-3 h-3 mr-tiny" color={theme.color.background.inkWhite.white_0} />
          <span className="text-xtiny font-semibold text-white">Code</span>
        </div>
        <p className="mt-mini text-tiny font-semibold text-brand-primary">
          {
              (
                promoCode.type === PROMO_CODE_TYPES.ONE_TIME_PROMO_CODE ||  
                promoCode.discount_type === PROMO_CODE_DISCOUNT_TYPES.PERCENTAGE
              ) &&
              t('promo_code_banner.single_use', {
                  discount: formattedDiscount,
                  code: promoCode.code,
              }
          )}

          {
              promoCode.type === PROMO_CODE_TYPES.MULTI_PROMO_CODE &&
              promoCode.discount_type === PROMO_CODE_DISCOUNT_TYPES.FIXED_AMOUNT &&
              t('promo_code_banner.multi_use', {
                  discount: formattedDiscount,
                  count: promoCode.components.length,
                  code: promoCode.code,
              }
          )}
        </p>
        {isApplied ? renderDisabledButton() : renderRedeemButton()}
      </div>
    );
  };

  const renderPromoCodeInput = (): JSX.Element => {
    return (
      <div className="mx-small mt-small">
        <PromoCodeInput ref={promoCodeInputRef} />
      </div>
    );
  }

  const renderEmptyBasket = (): JSX.Element => {
    return (
      <div className="grow w-full flex flex-col justify-center items-center z-1">
        <EmptyBasketIllustration/>
        <p className="text-center text-mini text-brand-inkGrey-grey_4">
          <Trans t={t}>empty_order_basket.description</Trans>
        </p>
      </div>
    );
  };

  const renderLineItems = useMemo(
    () => (
      <div className="pt-small px-small">
        {items.map((item: CompleteBasketItemInterface, index) => (
          <div className="mb-small" key={`basket-item-${index}`}>
            <OrderBasketItem data={item} index={index} />
          </div>
        ))}
      </div>
    ),
    [items, isDesktop]
  );

  const renderOrderBasket = (): JSX.Element => {
    return (
      <>
        <div className={wrapper}>
          {renderHeader()}
          {renderOrderType()}
          {renderPromoCodeBanner()}
          {renderPromoCodeInput()}
          {items.length > 0 ? (
            <>
              {renderSelectTable()}
              {renderLineItems}
              <DeliveryThreshold className="px-small mb-mini"/>
              <CheckoutThreshold className="px-small mb-mini"/>
            </>
          ) : renderEmptyBasket()}
        </div>
        {renderCheckoutButton()}
      </>
    );
  };

  const renderUpSellModal = useMemo<JSX.Element>(() => {
    return (
      <UpSellModal
        openModal={showUpSellModal}
        products={upSellProducts}
        toggleModal={toggleUpSellModal}
      />
    );
  }, [showUpSellModal, upSellProducts]);

  const renderLoadingPage = useMemo<JSX.Element | null>(() => {
    return loading ? (
      <div>
        <LoadingPage />
      </div>
    ) : null;
  }, [loading]);

  return (
    <div
      className={customParentContainer ?? parentContainer}
      style={customParentStyle}
    >
      <div className={container}>
        {renderOrderBasket()}
      </div>
      {renderUpSellModal}
      {renderLoadingPage}
    </div>
  );
};

export default OrderBasketScreen;

const parentContainer = "h-screen";

const container = "w-full h-full flex flex-col";

const wrapper = "w-full flex flex-col flex-auto";

const buttonContainer = "sticky bottom-0 w-full";

const headerContainer = "sticky top-0 z-20";
