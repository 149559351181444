export default {
  buttons: {
    no_thanks: 'Nein, Danke',
    send_receipt: 'Quittung an E-Mail senden',
  },
  text_inputs: {
    first_name: {
      placeholder: 'Vorname',
    },
    last_name: {
      placeholder: 'Nachname',
    },
    email: {
      placeholder: 'Email',
    },
  },
  error_messages: {
    failed_update_profile: 'Deine persönlichen Daten konnten nicht aktualisiert werden',
  },
};
