import React from "react";
import {useTranslation} from "react-i18next";
import {OrderItemInterface} from "../../services/exports/Interfaces";
import classnames from "classnames";
import LocationCircleIcon from "../../assets/logo/LocationCircleIcon";
import UberEatsIcon from "../../assets/logo/UberEatsIcon";
import {DeliveryProvider, ORDER_FULFILLMENT_STATUSES, ORDER_METHODS} from "../../services/exports/Constants";
import useAppMethods from "../../hooks/utility/useAppMethods";
import LoadingIndicator from "../loading/LoadingIndicator";

interface Props {
  order: OrderItemInterface;
  className?: string;
}

export default function DeliveryTracker(props: Props) {
  const { t } = useTranslation(null, { keyPrefix: 'Components:Delivery:DeliveryTracker' });

  const { order, className } = props;

  const { formatCurrency } = useAppMethods();

  return order?.method === ORDER_METHODS.DELIVERY
    && order.delivery_provider !== DeliveryProvider.Standalone
    && order.status !== ORDER_FULFILLMENT_STATUSES.REJECTED
    && order?.delivery?.id !== null
    && (
      <div className={classnames('p-small relative border-medium border-brand-secondary rounded-md', className)}>
        <LocationCircleIcon className="absolute h-8 w-8 right-small top-small"/>
        <h4>{t('title')}</h4>
        <div className="flex mt-mini">
          <p className="my-auto mr-mini">{t('labels.delivered_by')}</p>
          <UberEatsIcon className="h-[16px] w-initial my-auto"/>
        </div>
        <div className="mt-small p-small rounded-lg bg-[#FDF4EA]">
          <p className="text-mini">{t('text', { savings: `5-${formatCurrency(10)}` })}</p>
        </div>
        <button
          onClick={() => window.open(order.delivery?.tracking_url, '_blank')}
          disabled={!order.delivery?.tracking_url}
          className="w-10/12 mt-medium mx-auto flex justify-center items-center p-xsmall text-center rounded-md bg-white border border-brand-secondary font-semibold disabled:w-full disabled:bg-background-inkDisabled-disabled_3 disabled:text-brand-inkGrey-grey_4"
        >
          {!order.delivery?.tracking_url ? (
            <LoadingIndicator lg={24} md={24} sm={24} className="mr-mini my-auto" />
          ) : null}
          {t(order.delivery?.tracking_url ? 'buttons.track_order' : 'buttons.track_order_once_confirmed')}
        </button>
      </div>
    );
}
