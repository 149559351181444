import { useEffect } from "react";
import CookieBanner, { getCookieConsentValue } from "react-cookie-consent";
import { SCREENS } from "../services/exports/Constants";
import useScreenType from "../hooks/utility/useScreenType";
import Link from "./navigation/Link";
import ReduxActions from "../store/ReduxActions";
import { actionCreators } from "../store/actions";
import { useTranslation } from "react-i18next";

export default function CookieConsent() {
  const { t } = useTranslation(null, { keyPrefix: "Components:CookieConsent" });

  const { isDesktop } = useScreenType();

  useEffect(() => {
    onChangeStatus();
  }, []);

  function onChangeStatus(): void {
    ReduxActions.dispatch(
      actionCreators.appStatus.setCookieConsent(getCookieConsentValue())
    );
  }

  return (
    <div data-test="cookie-banner">
      <CookieBanner
        overlay
        flipButtons
        enableDeclineButton
        location={isDesktop ? "bottom" : "top"}
        disableStyles
        buttonText={t("buttons.accept")}
        declineButtonText={t("buttons.reject")}
        overlayClasses="bg-black/25 h-screen w-screen top-0 left-0 fixed z-[9999]"
        containerClasses="bg-white w-11/12 lg:w-9/12 lg:flex lg:justify-between rounded-lg mx-auto my-small px-small py-mini fixed lg:bottom-0 left-1/2 -translate-x-1/2"
        contentClasses="my-auto text-center lg:text-left"
        buttonWrapperClasses="mt-mini flex justify-center lg:my-auto"
        buttonClasses="p-mini lg:px-large rounded-md shadow-md flex flex-row items-center justify-center relative shadow text-white bg-gradient-to-r from-companyBrand-gradients-primary-color_1 via-companyBrand-gradients-primary-color_1 to-companyBrand-gradients-primary-color_2"
        declineButtonClasses="ml-small p-mini lg:px-large rounded-md shadow-md flex flex-row items-center justify-center relative bg-background-inkWhite-white_1 border border-brand-inkGrey-grey_2"
        onAccept={onChangeStatus}
        onDecline={onChangeStatus}
      >
        <h6 className="font-normal">
          {t("text")}{" "}
          <Link to={SCREENS.COOKIE_POLICY} text={t("cookie_policy")} />
        </h6>
      </CookieBanner>
    </div>
  );
}
