export default {
  point: "point",
  point_other: "points",
  status: {
    descriptions: {
      description_3:
        "After you use your points to get discounts for your next order, your points will not reset to 0. But you will continue collecting points from {{points}}.",
    },
    titles: {
      title_1: "Earn points, get discounts 💵",
      title_2: "{{discount}}% unlocked 🥳",
    },
  },
};
