import collect from "collect.js";
import { RewardInterface } from "../exports/Interfaces";

export default class RewardStatusResource {
  private rewards: RewardInterface[] = [];
  private rewardBalance: number = 0;

  constructor(data: { rewards?: RewardInterface[]; rewardBalance?: number }) {
    if (data?.rewards) {
      this.rewards = data.rewards;
    }
    if (data?.rewardBalance !== undefined) {
      this.rewardBalance = data.rewardBalance;
    }
  }

  public getLastActiveReward(): RewardInterface {
    try {
      if (!this.rewards) {
        return;
      }
      return collect(this.rewards)?.last(
        (reward: RewardInterface) => reward.is_available
      );
    } catch (error) {
      return null;
    }
  }

  public getLastActiveRewardThatIsNotForFree(): RewardInterface {
    try {
      let lastReward = collect(this.rewards)?.last(
        (reward: RewardInterface) => reward.is_available
      );
      if (lastReward?.discount === 100) {
        return collect(this.rewards).get(this.rewards?.length - 2);
      }
      return lastReward;
    } catch (error) {
      return null;
    }
  }

  public getRewardProgress(): number {
    try {
      const rewards: RewardInterface[] = this.rewards;

      const rewardBalance: number = this.rewardBalance;

      if (!rewards) {
        return 0;
      }

      if (!rewardBalance) {
        return 0;
      }

      let progress: number = 0;

      interface StructureInterface {
        cost: number;
        percentage: number;
      }

      let structures: StructureInterface[] = collect(
        this.rewards?.map((reward: RewardInterface, index: number) => {
          return {
            cost: reward?.cost,
            percentage: (index + 1) / this.rewards?.length,
          };
        })
      ).toArray();

      function computePreviousProgress(): number {
        let percentage: number = 0;
        collect(structures).each((structure: StructureInterface) => {
          if (rewardBalance > structure?.cost) {
            percentage += collect(structures)?.first()?.percentage;
          }
        });
        return percentage;
      }

      function computeCurrentInnerProgress(): number {
        let currentStructure = collect(structures)?.last(
          (structure: StructureInterface) => structure?.cost === rewardBalance
        );

        let nextStructure: StructureInterface = collect(structures)?.first(
          (structure: StructureInterface) => structure?.cost > rewardBalance
        );

        let previousStructures: StructureInterface[] = collect(structures)
          ?.filter((structure: StructureInterface) => {
            if (currentStructure) {
              return structure?.cost <= currentStructure?.cost;
            }
            if (nextStructure) {
              return structure?.cost <= nextStructure?.cost;
            }
          })
          ?.toArray();

        if (!previousStructures?.length) {
          return 0;
        }

        let secondLastStructure = collect(previousStructures)?.get(
          previousStructures?.length - 2
        );

        let lastStructure = collect(previousStructures).last();

        let innerProgressStructure = {
          cost: secondLastStructure
            ? (lastStructure?.cost ?? 0) - (secondLastStructure?.cost ?? 0)
            : lastStructure?.cost,
          percentage: 1,
        };

        let balanceDiff: number = lastStructure?.cost - rewardBalance;

        let innerRatio: number =
          (innerProgressStructure.cost - balanceDiff) /
          innerProgressStructure?.cost;

        let outerRatio: number = collect(structures)?.first()?.percentage;

        let percentage: number = innerRatio * outerRatio;
        return percentage;
      }

      progress += computePreviousProgress();

      progress += computeCurrentInnerProgress();

      return progress * 100;
    } catch (error) {
      return 0;
    }
  }

  public getNextReward(): RewardInterface {
    try {
      if (
        !this.rewards ||
        this.rewardBalance === null ||
        this.rewardBalance === undefined
      ) {
        return;
      }
      return collect(this.rewards)?.first(
        (reward: RewardInterface) => reward?.cost > this.rewardBalance
      );
    } catch (error) {
      return null;
    }
  }
}
