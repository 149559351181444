import {useEffect, useState} from "react";
import {
  PaymentElement,
  AddressElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import LoadingIndicator from "../loading/LoadingIndicator";
import { PaymentOptionPropertiesInterface } from "../../services/exports/Interfaces";
import useStripeHelpers from "../../hooks/payment/useStripeHelpers";
import { PaymentMethod } from "../../services/exports/Constants";
import {useTranslation} from "react-i18next";
import InfoIcon from "../../assets/logo/InfoIcon";
import CheckmarkIcon from "../../assets/logo/CheckmarkIcon";
import useTheme from "../../hooks/ui/useTheme";
import {useSelector} from "react-redux";
import {StoreInterface} from "../../store/types";
import ProfileManager from "../../services/api/ProfileManager";
import {StripeAddressElementChangeEvent} from "@stripe/stripe-js";

interface Props {
  selectedPaymentMethod: PaymentOptionPropertiesInterface;
}

export default function PaymentMethodElement(props: Props) {
  const { t } = useTranslation(null, { keyPrefix: 'Components:Payment:PaymentMethodElement' });

  const { selectedPaymentMethod } = props;

  const { profile } = useSelector((state: StoreInterface) => state.profile);
  const { company } = useSelector((state: StoreInterface) => state.initialData);

  const theme = useTheme();
  const stripe = useStripe();
  const elements = useElements();
  const addressRequired = company?.country !== 'DE';
  const { setStripe, setElements } = useStripeHelpers();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setStripe(stripe);

    return () => setStripe(null);
  }, [stripe]);

  useEffect(() => {
    setElements(elements);

    return () => setElements(null);
  }, [elements]);

  async function onAddressChange(e: StripeAddressElementChangeEvent) {
    e.complete && await ProfileManager.putProfile({
      country: e.value.address.country,
      state: e.value.address.state,
      city: e.value.address.city,
      zip_code: e.value.address.postal_code,
      address: e.value.address.line1,
    });
  }

  function renderLoading() {
    return loading ? (
      <div className={loadingContainer}>
        <LoadingIndicator lg={40} md={30} sm={20} />
      </div>
    ) : undefined;
  }

  const renderExtraInfo = () => {
    if (loading) {
      return null;
    }

    return {
      [PaymentMethod.Sofort]: (
        <div className="bg-brand-inkGrey-grey_6 border p-xsmall mb-small rounded">
          <h6 className="flex items-center text-mini">
            <InfoIcon className="h-5 w-5 mr-tiny" color={theme.color.text.default} />
            {t('extra_info.sofort.title')}
          </h6>
          <p className="flex items-center text-mini mt-xsmall">
            <CheckmarkIcon className="h-4 w-4 mr-tiny" color={theme.color.text.default} />
            {t('extra_info.sofort.item_1')}
          </p>
          <p className="flex items-center text-mini mt-xsmall">
            <CheckmarkIcon className="h-4 w-4 mr-tiny" color={theme.color.text.default} />
            {t('extra_info.sofort.item_2')}
          </p>
        </div>
      ),
    }[selectedPaymentMethod?.payment_method_id] ?? null;
  }

  return (
    <div className={container}>
      <div className={paymentElementContainer}>
        {renderExtraInfo()}
        <PaymentElement
          onReady={() => setLoading(false)}
          options={{
            wallets: {
              applePay: "never",
              googlePay: "never",
            },
            fields: {
              billingDetails: {
                email: "auto",
                name: "auto",
                phone: 'auto',
                address: "auto",
              },
            },
            defaultValues: {
              billingDetails: {
                email: profile?.email,
                name: `${profile?.first_name} ${profile?.last_name}`,
                phone: profile?.phone_number,
                address: {
                  country: profile?.country,
                  postal_code: profile?.zip_code,
                  state: profile?.state,
                  city: profile?.city,
                  line1: profile?.address,
                }
              },
            }
          }}
        />
        {addressRequired && (
          <AddressElement
            className="mt-mini"
            onChange={(e) => onAddressChange(e)}
            options={{
              mode: 'billing',
              fields: {
                phone: 'never',
              },
              defaultValues: {
                name: `${profile?.first_name} ${profile?.last_name}`,
                address: {
                  country: profile?.country,
                  postal_code: profile?.zip_code,
                  state: profile?.state,
                  city: profile?.city,
                  line1: profile?.address,
                }
              }
            }}
          />
        )}
      </div>
      {renderLoading()}
    </div>
  );
}

const container = "";

const paymentElementContainer = "w-full relative";

const loadingContainer =
  "h-full w-full absolute inset-0 flex flex-col justify-center items-center";
