import moment from "moment";
import {useCallback, useMemo} from "react";
import {useSelector} from "react-redux";
import {
  CompanyDetailsInterface,
  CompanyHoursHookInterface,
} from "../../services/exports/Interfaces";
import {StoreInterface} from "../../store/types";
import PickUpTimesResource from "../../services/resources/PickUpTimesResource";
import {ORDER_METHODS, ORDERS_PAUSED_REASON} from "../../services/exports/Constants";
import {WorkScheduleUnitResource} from "../../services/resources/WorkScheduleUnitResource";
import WorkScheduleHelpers from "../../services/helpers/WorkScheduleHelpers";

interface Props {
  data?: CompanyDetailsInterface;
}

export default function useCompanyHours(
  props: Props = {}
): CompanyHoursHookInterface {
  const { data } = props;

  const { cached_order } = useSelector((state: StoreInterface) => state.order);
  const { company: companyData } = useSelector((state: StoreInterface) => state.initialData);
  const company = data ?? companyData;

  const orderTimeAvailable = useMemo(
    () => cached_order
      && WorkScheduleHelpers.isOpen(
        cached_order?.method === ORDER_METHODS.DELIVERY ? company?.delivery_schedule : company?.work_schedule,
        cached_order?.scheduled_for
      ),
    [cached_order?.method, cached_order?.scheduled_for, company?.current_work_schedule, company?.current_delivery_schedule]
  );

  function getCurrentDineInSchedule() {
    return moment().isAfter(
      PickUpTimesResource.createDate(company?.current_work_schedule?.close)
    )
      ? company?.next_work_schedule
      : company?.current_work_schedule;
  }

  const getCurrentWorkSchedule = useCallback(() => {
    const pickupSchedule = new WorkScheduleUnitResource(
      company?.current_work_schedule
    );
    const deliverySchedule = new WorkScheduleUnitResource(
      company?.current_delivery_schedule
    );

    if (pickupSchedule.openNow() && deliverySchedule.openNow()) {
      return pickupSchedule.closesBefore(company?.current_delivery_schedule)
        ? company?.current_delivery_schedule
        : company?.current_work_schedule;
    }

    if (!pickupSchedule.openNow() && !deliverySchedule.openNow()) {
      return pickupSchedule.opensBefore(company?.current_delivery_schedule)
        ? company?.current_work_schedule
        : company?.current_delivery_schedule;
    }

    if (pickupSchedule.openNow()) {
      return company?.current_work_schedule;
    }

    return company?.current_delivery_schedule;
  }, [company]);

  const getNextWorkSchedule = useCallback(() => {
    if (
      new WorkScheduleUnitResource(company?.next_work_schedule).opensBefore(
        company?.next_delivery_schedule
      )
    ) {
      return company?.next_work_schedule;
    }

    return company?.next_delivery_schedule;
  }, [company]);

  const isCurrentlyOpen = useCallback(() => {
    const schedule = getCurrentWorkSchedule();
    const range = PickUpTimesResource.createDateRange(schedule);

    return range
      ? moment().isBetween(range[0], range[1]) && company?.open_now
      : false;
  }, [company]);

  const openWholeDay = useMemo(
    () => {
      const schedule = getCurrentDineInSchedule();

      return schedule?.open?.day !== schedule?.close?.day
        && schedule?.open?.hour === 0
        && schedule?.open?.minute === 0
        && schedule?.close?.hour === 0
        && schedule?.close?.minute === 0;
    },
    [company],
  );

  const _willOpenToday = useCallback(() => {
    const schedule = getCurrentWorkSchedule();
    const openRange = PickUpTimesResource.createDateRange(schedule);

    return schedule && (
      schedule.open.day === moment().day() &&
      moment().isBefore(openRange[0])
    );
  }, [company]);

  const _orderArePausedTillEndOfDay = useCallback(() => {
    try {
      return moment().endOf('day').isSame(moment(company?.orders_paused_until));
    } catch (error) {}
  }, [company]);

  const _opensStartingTomorrow = useCallback(() => {
    try {
      return {
        status:
          !isCurrentlyOpen() &&
          (!_willOpenToday() || _orderArePausedTillEndOfDay()),
        time: PickUpTimesResource.createDate(getNextWorkSchedule()?.open),
      };
    } catch (error) {}
  }, [company]);

  const _opensToday = useCallback(() => {
    try {
      return {
        status: _willOpenToday(),
        time: PickUpTimesResource.createDate(getCurrentWorkSchedule()?.open),
      };
    } catch (error) {}
  }, [company]);

  const _ordersArePaused = useCallback(() => {
    return (
      company?.orders_paused_until &&
      moment(company?.orders_paused_until).isAfter(moment())
    );
  }, [company]);

  const _ordersArePausedManually = useCallback(() => {
    return (
      _ordersArePaused() &&
      company?.orders_pause_reason === ORDERS_PAUSED_REASON.MANUAL
    );
  }, [company]);

  const pickupOpenRange = PickUpTimesResource.createDateRange(company?.current_work_schedule);
  const deliveryOpenRange = PickUpTimesResource.createDateRange(company?.current_delivery_schedule);

  const closedFrom = company?.closed_from ? moment(company?.closed_from) : null;

  const closedUntil = company?.closed_until
    ? moment(company?.closed_until)
    : null;

  const dineInOpenFrom = PickUpTimesResource.createDate(
    getCurrentDineInSchedule()?.open
  );

  const dineInOpenUntil = PickUpTimesResource.createDate(
    getCurrentDineInSchedule()?.close
  ).subtract(company?.last_order_gap_dine_in, "minutes");

  const _pickupClosed = useCallback(() => {
    try {
      return pickupOpenRange
        ? !moment().isBetween(
          pickupOpenRange[0],
          pickupOpenRange[1].subtract(company?.last_order_gap_pickup, 'minutes')
        )
        : true;
    } catch (error) {}
  }, [pickupOpenRange, company?.last_order_gap_pickup]);

  const _deliveryClosed = useCallback(() => {
    try {
      return deliveryOpenRange
        ? !moment().isBetween(
          deliveryOpenRange[0],
          deliveryOpenRange[1].subtract(company?.last_order_gap_delivery, 'minutes')
        )
        : true;
    } catch (error) {}
  }, [deliveryOpenRange, company?.last_order_gap_delivery]);

  const _dineInClosed = useCallback(() => {
    return !moment().isBetween(dineInOpenFrom, dineInOpenUntil);
  }, [dineInOpenFrom, dineInOpenUntil]);

  const _dineInOpensToday = useCallback(() => {
    const schedule = getCurrentDineInSchedule();

    return moment().isBefore(PickUpTimesResource.createDate(schedule?.open));
  }, [company]);

  return {
    open: isCurrentlyOpen(),
    openWholeDay,
    getCurrentDineInSchedule,
    getCurrentWorkSchedule,
    getNextWorkSchedule,
    dineInOpenUntil,
    closedFrom,
    closedUntil,
    orderTimeAvailable,
    pickupClosed: _pickupClosed(),
    deliveryClosed: _deliveryClosed(),
    dineInClosed: _dineInClosed(),
    dineInOpensToday: _dineInOpensToday(),
    opensToday: _opensToday(),
    opensStartingTomorrow: _opensStartingTomorrow(),
    ordersPausedUntil: company?.orders_paused_until,
    orderArePausedTillEndOfDay: _orderArePausedTillEndOfDay(),
    ordersPausedReason: company?.orders_pause_reason,
    ordersArePaused: _ordersArePaused(),
    ordersArePausedManually: _ordersArePausedManually(),
  };
}
