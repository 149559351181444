export default {
  title: 'Track deine Bestellung',
  text: 'Danke, dass du hier bestellst! Unsere Partnerschaft mit Uber ermöglicht es uns, Lieferungen direkt über unsere Seite zu abwickeln und dabei durchschnittlich {{savings}} pro Bestellung an Lieferprovisionen zu sparen. Deine Bestellung wird von Uber geliefert, was super Service garantiert 😊',
  labels: {
    delivered_by: 'Geliefert mit',
  },
  buttons: {
    track_order: 'Erhalte Updates zur Bestellung →',
    track_order_once_confirmed: 'Erhalte Live-Bestellupdates, Sobald Bestätigt',
  },
};
