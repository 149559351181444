export default {
  title: 'Finde den nahsten Standort',
  form: {
    address: {
      placeholder: 'Deine Adresse eingeben',
    },
  },
  toasts: {
    failed_to_load_stores: 'Fehler beim Laden von Store, bitte aktualisiere die Seite',
  },
};