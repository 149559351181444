import { useSelector } from "react-redux";
import InstagramIcon from "../../assets/logo/InstagramIcon";
import useMaps from "../../hooks/location/useMaps";
import { Link } from "react-router-dom";
import { SCREENS } from "../../services/exports/Constants";
import { useTranslation } from "react-i18next";
import { StoreInterface } from "../../store/types";

export default function Footer() {
  const { t } = useTranslation(null, { keyPrefix: 'Components:Footers:Footer' });

  const { company } = useSelector((state: StoreInterface) => state.initialData);

  const { openMap } = useMaps();

  function getImpressum() {
    const isDemo = company?.id === 21 || company?.id === 8;

    return {
      show: isDemo || company?.impressum_link,
      href: isDemo ? SCREENS.IMPRESSUM : company?.impressum_link,
    };
  }

  function renderInfo() {
    return (
      <div className={infoContainer}>
        <div className={infoItemContainer}>
          <h6 className={infoTitleStyle}>{company?.name}</h6>
          {company?.instagram_link && (
            <a
              href={company?.instagram_link}
              target={"_blank"}
              rel="noreferrer"
            >
              <InstagramIcon className={socialIconStyle} />
            </a>
          )}
        </div>
        <div className={infoItemContainer}>
          <h6 className={infoTitleStyle}>{t("address")}</h6>
          <div className="cursor-pointer" onClick={openMap}>
            <p className={linkStyle}>{company?.name}</p>
            <p className={linkStyle}>{company?.address}</p>
            {company?.city && (
              <p className={linkStyle}>
                {company?.zip_code && `${company.zip_code} `}
                {company?.city}
              </p>
            )}
          </div>
        </div>
        <div className={infoItemContainer}>
          <h6 className={infoTitleStyle}>{t("contact")}</h6>
          {company?.phone_number && (
            <a href={`tel:${company?.phone_number}`} className={linkStyle}>
              <p className="text-tiny">{company?.phone_number}</p>
            </a>
          )}
          {company?.email && (
            <a href={`mailto:${company?.email}`} className={linkStyle}>
              <p className="text-tiny">{company?.email}</p>
            </a>
          )}
          {company?.website_link && (
            <a
              href={company?.website_link}
              target="_blank"
              rel="noopener noreferrer"
              className={linkStyle}
            >
              <p className="text-tiny">{company?.website_link}</p>
            </a>
          )}
        </div>
      </div>
    );
  }

  function renderBase() {
    return (
      <div className={baseContainer}>
        <div className={baseWrapper}>
          <Link
            to={SCREENS.TERMS_AND_CONDITIONS}
            className={baseItemContainer}
            target="_blank"
            rel="noopener noreferrer"
          >
            <p className="text-tiny">{t("terms")}</p>
          </Link>
          <Link
            to={SCREENS.PRIVACY_POLICY}
            className={baseItemContainer}
            target="_blank"
            rel="noopener noreferrer"
          >
            <p className="text-tiny">{t("privacy_policy")}</p>
          </Link>
          {getImpressum().show && (
            <a
              className={baseItemContainer}
              href={getImpressum().href}
              target={"_blank"}
              rel="noreferrer"
            >
              <p className="text-tiny">{t("impressum")}</p>
            </a>
          )}
          <Link
            to={SCREENS.COOKIE_POLICY}
            className={baseItemContainer}
            target="_blank"
            rel="noopener noreferrer"
          >
            <p className="text-tiny">{t("cookie_policy")}</p>
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className={container}>
      {renderInfo()}
      {renderBase()}
    </div>
  );
}

const container = "bottom-0 w-full bg-background-inkWhite-white_1 lg:pb-none";

const infoContainer =
  "grid lg:grid-cols-3 grid-cols-1 gap-4 lg:py-small py-small lg:px-medium px-small";

const infoItemContainer = "";

const infoTitleStyle = "mb-small text-black";

const baseContainer =
  "flex flex-row justify-between lg:items-center items-start py-small lg:px-medium px-small";

const baseWrapper =
  "flex-1 flex lg:flex-row flex-col lg:items-center items-start justify-between mr-large";

const baseItemContainer =
  "flex mr-medium items-center lg:py-none pb-mini hover:underline decoration-brand-text-default decoration-solid underline-offset-4 line-clamp-1";

const socialIconStyle = "h-10 w-10";

const linkStyle = "text-tiny text-left mb-mini hover:underline text-black";
