export default {
  toasts: {
    member_joined: '{{user}} ist deinem Tisch beigetreten',
    member_left: '{{user}} hat dein Tisch verlassen',
    member_ordered: '{{user}} hat bestellt {{lineItems}}',
    table_closed: '{{user}} hat den Tisch gedeckt',
    all_paid: '{{user}} bezahlt {{amount}}. Volle Rechnung bezahlt!',
    left_to_pay: '{{user}} zahlte {{amount}}. Noch zu zahlen {{leftToPay}}',
    refund_created: 'Wir haben dir zurückerstattet {{amount}}. Wenn du das Geld noch nicht erhalten haben solltest, wirst du  es spätestens innerhalb 2 Tagen erhalten',
    order_canceled: 'Tut uns leid, wir mussten deine Bestellung stornieren',
    table_changed: '{{user}} hat die Tischnummer gewechselt {{table}}'
  },
};