import PusherContext from "../context/PusherContext";
import {useMemo, useRef, useState} from "react";
import {
  PusherContextInterface,
} from "../services/exports/Interfaces";
import PusherClient from "../services/pusher/PusherClient";
import Pusher from "pusher-js";

interface Props {
  children: any;
}

export default function PusherProvider(props: Props): JSX.Element {
  const { children } = props;

  const pusherHelper = PusherClient;
  const [pusherClient, setPusherClient] = useState<Pusher | null>(null);

  const pusherClientRef = useRef(pusherClient);
  pusherClientRef.current = pusherClient;

  const context: PusherContextInterface = useMemo(() => {
    const isConnected = () => pusherHelper.isConnected(pusherClientRef.current);
    const unbind = (eventsToUnbind: string | string[] = []): void => {
      if (typeof eventsToUnbind === "string") {
        pusherClientRef.current?.unbind(eventsToUnbind);

        return;
      }

      eventsToUnbind.forEach((event) => pusherClientRef.current?.unbind(event));
    };

    return {
      client: pusherClientRef.current,
      isConnected: isConnected,
      connect: async () => {
        if (!isConnected()) {
          const pusher = await pusherHelper.forceConnect();
          setPusherClient(pusher);

          return pusher;
        }

        return pusherClientRef.current;
      },
      disconnect: () => {
        if (!isConnected()) {
          return;
        }

        pusherClientRef.current?.unbind_all();
        pusherClientRef.current?.disconnect();
        setPusherClient(null);
      },
      unbind,
      unsubscribe: (
        channel: string,
        eventsToUnbind: string | string[] = []
      ) => {
        pusherClientRef.current?.unsubscribe(channel);
        unbind(eventsToUnbind);
      },
    };
  }, [pusherClientRef.current]);


  return (
    <PusherContext.Provider value={context}>
      {children}
    </PusherContext.Provider>
  )
}