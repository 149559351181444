import {OrderItemInterface} from "../../services/exports/Interfaces";
import {DeliveryProvider, ORDER_FULFILLMENT_STATUSES, ORDER_METHODS} from "../../services/exports/Constants";
import moment from "moment-timezone";
import OrderTimeline from "./OrderTimeline";
import Section from "../Section";
import {useSelector} from "react-redux";
import {StoreInterface} from "../../store/types";
import {useTranslation} from "react-i18next";
import React, {useMemo} from "react";
import useAppMethods from "../../hooks/utility/useAppMethods";
import LoadingIndicator from "../loading/LoadingIndicator";
import DeliveryTracker from "../delivery/DeliveryTracker";

interface Props {
  order: OrderItemInterface;
}

export default function OrderTracker(props: Props): React.ReactElement {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Order:OrderTracker' });

  const { order } = props;

  const { company } = useSelector((state: StoreInterface) => state.initialData);

  const { formatTime } = useAppMethods();

  const time = useMemo(
    () => (moment(order?.estimated_pickup_time).isSame(moment(), 'day') ? t('labels.today') : moment(order?.estimated_pickup_time).format('dddd, DD MMM'))
      + ' '
      + formatTime(order?.estimated_pickup_time)
      + ' - '
      + formatTime(moment(order?.estimated_pickup_time).add(10, 'minutes')),
    [order?.estimated_pickup_time]
  )

  const renderLoadingIndicator = useMemo(
    () => [
      ORDER_FULFILLMENT_STATUSES.CREATED,
      ORDER_FULFILLMENT_STATUSES.REVIEWED,
    ].includes(order?.status) && (
      <div className="my-auto mr-mini" >
        <LoadingIndicator sm={24} md={28} lg={28} />
      </div>
    ),
    [order?.status]
  );

  const renderPickupTimeChangedLabel = useMemo(() => {
    if (
      !order?.initial_confirmation_time ||
      !order?.estimated_pickup_time ||
      (order?.method === ORDER_METHODS.DELIVERY && order?.delivery_provider !== DeliveryProvider.Standalone) ||
      order?.initial_confirmation_time === order?.estimated_pickup_time
    ) {
      return null;
    }

    return (
      <div className="mt-small bg-brand-notice px-mini py-small rounded">
        <p className="text-mini">
          {t('warning_label.pickup_time_changed', {
            company: company?.name,
            initialTime: formatTime(order?.initial_confirmation_time),
            currentTime: formatTime(order?.estimated_pickup_time),
          })}
        </p>
      </div>
    );
  }, [order?.initial_confirmation_time, order?.estimated_pickup_time]);

  return (
    <Section hideTopDivider className="py-medium lg:px-none px-small">
      <div>
        <div className="flex flex-row">
          <h4 className="flex">
            {renderLoadingIndicator}
            <span className="my-auto">
              {
                order?.method === ORDER_METHODS.DELIVERY
                  ? t(`order_status.${order?.method}.${order?.delivery_provider}.${order?.status}`)
                  : t(`order_status.${order?.method}.${order?.status}`)
              }
            </span>
          </h4>
          {order?.status !== ORDER_FULFILLMENT_STATUSES.REJECTED && order?.delivery_provider !== DeliveryProvider.FirstDelivery && (
            <h4 className="text-brand-primary ml-mini my-auto">{time}</h4>
          )}
        </div>
        {t(`time_label.${order?.delivery_provider}.${order?.status}`, { defaultValue: null }) && (
          <p className="mt-mini">
            {t(`time_label.${order?.delivery_provider}.${order?.status}`)}
            {' '}
            {time}
          </p>
        )}
      </div>
      <DeliveryTracker order={order} className="mt-small" />
      {order?.status === ORDER_FULFILLMENT_STATUSES.REJECTED ? (
        <div className="mt-small">
          <p>
            {t(
              `warning_label.order_rejected_reason.${order?.rejection_reason}`,
              {
                name: company?.name,
                provider: company?.service_provider?.name,
                supportEmail: company?.service_provider?.support_email,
                defaultValue: '',
              }
            )}
          </p>
        </div>
      ) : (renderPickupTimeChangedLabel)}
      <OrderTimeline
        className="mt-small"
        order={order}
      />
    </Section>
  )
}