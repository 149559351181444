import AuthorizationTokenStorage from "../../services/auth/AuthorizationTokenStorage";

interface AuthHookInterface {
    isLoggedIn: boolean;
}

export default function useAuth(): AuthHookInterface {
  return {
    isLoggedIn: !!AuthorizationTokenStorage.getToken(),
  };
}