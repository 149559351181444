import { useMemo } from "react";
import { useSelector } from "react-redux";
import {StoreInterface} from "../../store/types";

export default function useConfig() {
  const { franchise } = useSelector((state: StoreInterface) => state.initialData);
  const { company } = useSelector((state: StoreInterface) => state.initialData);

  return useMemo(
    () => ({
      logo_url: company?.logo_url ?? franchise?.logo_url,
      primary_color: company?.primary_color ?? franchise?.primary_color,
    }),
    [
      franchise?.logo_url,
      franchise?.primary_color,
      company?.logo_url,
      company?.primary_color,
    ]
  );
}
