import React, {useEffect, useMemo, useState} from "react";
import CustomModal from "../components-library/CustomModal";
import CustomNavBar from "../components-library/navigation/CustomNavBar";
import useScreenType from "../hooks/utility/useScreenType";
import {DELIVERY_ZONE_GEOMETRY_TYPES, DeliveryProvider, MODAL_SIZES,} from "../services/exports/Constants";
import {CompanyDetailsInterface} from "../services/exports/Interfaces";
import WorkSchedule from "../components-library/company/WorkSchedule";
import LoadingIndicator from "../components-library/loading/LoadingIndicator";
import CompanyLocation from "../components-library/company/CompanyLocation";
import {useTranslation} from "react-i18next";
import {collect} from "collect.js";
import classnames from "classnames";
import useAppMethods from "../hooks/utility/useAppMethods";

interface Props {
  data: CompanyDetailsInterface;
  openModal: boolean;
  toggleModal: () => void;
}

export default function CompanyDetailsModal(props: Props) {
  const { t } = useTranslation(null, {
    keyPrefix: "Modals:CompanyDetailsModal",
  });

  const { data, openModal, toggleModal } = props;

  const [loading, setLoading] = useState(false);

  const { formatCurrency } = useAppMethods();
  const { isDesktop } = useScreenType();

  useEffect(() => {
    if (openModal) {
      onDidFocus();
    }
  }, [openModal]);

  function onDidFocus() {
    setLoading(true);
    setTimeout(() => setLoading(false), 1000);
  }

  function isFullScreen() {
    return !isDesktop;
  }

  function renderHeaderBar() {
    return (
      <div className={headerContainer}>
        <CustomNavBar
          title={t("title")}
          rightClick={toggleModal}
          hideBackIcon
        />
      </div>
    );
  }

  function renderMap() {
    return <CompanyLocation data={data} className="h-80" />;
  }

  function renderWorkSchedule() {
    return (
      data?.has_work_schedule && (
        <WorkSchedule
          title={t("sections.work_schedule")}
          schedule={data.work_schedule}
          className="mt-large"
        />
      )
    );
  }

  function renderDeliveryInfo() {
    function renderDeliverySchedule() {
      return (
        data?.has_delivery_schedule &&
        data?.delivery_schedule && (
          <WorkSchedule
            title={t("sections.delivery_schedule")}
            schedule={data.delivery_schedule}
            className="mt-large"
          />
        )
      );
    }

    function renderDeliveryZoneIdentifier(zone) {
      if (zone.geometry_type === DELIVERY_ZONE_GEOMETRY_TYPES.ZIP) {
        return (
          <div>
            <p className="text-mini">
              {collect(zone.geometry_data).implode(", ")}
            </p>
          </div>
        );
      }

      return (
        <div
          className="rounded-full w-5 h-5"
          style={{ backgroundColor: zone.color }}
        />
      );
    }

    function renderDeliveryCosts() {
      if (data.delivery_provider !== DeliveryProvider.Standalone) {
        return null;
      }

      return (
        <div className="mt-large">
          <h4 className="mb-small">{t("sections.delivery_costs")}</h4>
          {data?.delivery_zones?.map((item, index) => (
            <div
              className={classnames("py-mini", { "border-t": index > 0 })}
              key={`delivery-zone-${item.id}-delivery-costs`}
            >
              <div className="flex">
                <h6 className="text-mini mr-mini">{item.name}</h6>
                {renderDeliveryZoneIdentifier(item)}
              </div>
              <p className="text-mini mt-mini">
                {t("labels.delivery_fees", {
                  threshold: formatCurrency(item.min_threshold),
                  fee: formatCurrency(item.fee),
                })}
              </p>
            </div>
          ))}
        </div>
      );
    }

    return (
      data?.has_delivery &&
      data?.delivery_enabled && (
        <>
          {renderDeliveryCosts()}
          {renderDeliverySchedule()}
        </>
      )
    );
  }

  function renderWebsiteInfo() {
    return (
      data?.website_link && (
        <div className="mt-large">
          <h4 className="mb-small">{t("sections.website")}</h4>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={data.website_link}
            className="hover:underline text-mini text-brand-text-blue"
          >
            {data.website_link}
          </a>
        </div>
      )
    );
  }

  const renderLoadingPage = useMemo(() => {
    return loading ? (
      <div className={loadingContainer}>
        <div className={loadingWrapper}>
          <div className={loadingIndicator}>
            <LoadingIndicator lg={60} md={50} sm={40} />
          </div>
        </div>
      </div>
    ) : null;
  }, [loading]);

  return (
    <CustomModal
      open={openModal}
      size={MODAL_SIZES.XL}
      fullScreen={isFullScreen()}
      onClose={() => toggleModal()}
    >
      <div className={container}>
        {renderHeaderBar()}
        <div className={wrapper}>
          {renderMap()}
          {renderWorkSchedule()}
          {renderDeliveryInfo()}
          {renderWebsiteInfo()}
          {renderLoadingPage}
        </div>
      </div>
    </CustomModal>
  );
}

const container = "h-fit bg-background-inkWhite-white_1";

const wrapper = "p-small h-fit bg-background-inkWhite-white_1 relative";

const headerContainer = "sticky top-0 z-10";

const loadingContainer =
  "absolute inset-0 flex flex-col bg-background-inkWhite-white_1 items-center";

const loadingWrapper = "lg:h-1/2 h-4/6  justify-center flex flex-col";

const loadingIndicator = "lg:pb-[50px] pb-[100px]";
