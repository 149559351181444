import BackIcon from "../../assets/logo/BackIcon";
import Theme from "../Theme";
import useAuth from "../../hooks/global/useAuth";
import { SCREENS } from "../../services/exports/Constants";
import RewardStatusResource from "../../services/resources/RewardStatusResource";
import classnames from "classnames";
import useLoyaltyProgram from "../../hooks/loyalty/useLoyaltyProgram";
import { useTranslation } from "react-i18next";
import useGoogleReviewSuggestedProducts from "../../hooks/google-review/useGoogleReviewSuggestedProducts";
import useRewards from "../../hooks/loyalty/useRewards";
import useNavigate from "../../hooks/navigation/useNavigate";
import {useSelector} from "react-redux";
import {StoreInterface} from "../../store/types";

interface Props {
  className?: string;
}

const LoyaltyCard = (props: Props): JSX.Element | null => {
  const { t } = useTranslation(null, { keyPrefix: 'Components:Promotion:LoyaltyCard' });

  const { className } = props;

  const { company } = useSelector((state: StoreInterface) => state.initialData);

  const { navigate } = useNavigate();
  const { isLoggedIn } = useAuth();
  const { message } = useGoogleReviewSuggestedProducts({ autoLoad: true });
  const { hasLoyaltyPromotion } = useLoyaltyProgram();
  const { maxReward } = useRewards();

  const RewardStatus = new RewardStatusResource({
    rewards: company?.rewards,
    rewardBalance: company?.reward_balance,
  });

  const nextReward = RewardStatus.getNextReward();
  const activeReward = RewardStatus.getLastActiveReward();

  function onClick(): void {
    navigate(SCREENS.REWARD_DETAILS_SCREEN);
  }

  const renderRewardPoints = (): JSX.Element => {
    function getDescription(): JSX.Element | null {
      // Case: Not logged in
      if (!isLoggedIn) {
        return (
          <p className={descriptionStyle}>
            {t("status.not_logged_in.description", { count: maxReward?.cost })}
            {" = "}
            {message({ type: "reward" })}
          </p>
        );
      }

      // Case: Logged in and
      // Case: Reward available and is matching reward balance or is last available reward
      if (
        activeReward &&
        (activeReward.cost === company.reward_balance || !nextReward)
      ) {
        // Case: Available Reward is free
        if (activeReward.discount === 100) {
          return (
            <p
              className={classnames(
                descriptionStyle,
                "text-companyBrand-primary"
              )}
            >
              {t("status.free_reward_available.description")}
            </p>
          );
        }

        // Case Available Reward is a discount
        return (
          <p
            className={classnames(
              descriptionStyle,
              "text-companyBrand-primary"
            )}
          >
            {t("status.discount_available.description", {
              discount: activeReward?.discount,
            })}
          </p>
        );
      }

      // Case: No available reward
      if (nextReward) {
        // Case: Next reward is a free item
        if (nextReward?.discount === 100) {
          return (
            <p className={descriptionStyle}>
              {t("status.next_free_reward.description", {
                count: nextReward?.cost - company?.reward_balance,
              })}
            </p>
          );
        }

        // Case: Next reward is a discount
        return (
          <p className={descriptionStyle}>
            {t("status.next_discount.description", {
              count: nextReward?.cost - company?.reward_balance,
              discount: nextReward?.discount,
            })}
          </p>
        );
      }

      return null;
    }

    return company?.rewards?.length > 0 ? (
      <div
        className={
          className ??
          "flex items-center justify-between px-xsmall lg:py-xsmall py-small cursor-pointer rounded-lg border"
        }
        onClick={onClick}
      >
        <div className="pr-mini">
          <div className="flex items-center">
            {getDescription()}
          </div>
        </div>
        <div className={"rotate-180"}>
          <BackIcon className={"w-3 h-3"} color={Theme.color.text.grey} />
        </div>
      </div>
    ) : null;
  };

  if (!hasLoyaltyPromotion) {
    return null;
  }

  return renderRewardPoints();
};

export default LoyaltyCard;

const descriptionStyle = "mini line-clamp-1 break-words";
