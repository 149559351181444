import {useState, useEffect, useMemo, ReactElement} from "react";
import LoadingApp from "../components-library/loading/LoadingApp";
import { AnimatePresence } from "framer-motion";
import ConfigManager from "../services/api/ConfigManager";
import DomainResource from "../services/resources/DomainResource";
import { ConfigInterface } from "../services/exports/Interfaces";
import LoadingContext from "../context/LoadingContext";

interface Props {
    children: ReactElement;
}

const LoadingProvider = (props: Props): ReactElement => {
    const { children } = props;

    const [config, setConfig] = useState<ConfigInterface | null>(null);
    const [loading, setLoading] = useState(true);

    async function requestConfig(): Promise<void> {
        const { response, success } = await ConfigManager.get(DomainResource.extractSubdomain());

        if (success && response?.data?.data) {
            setConfig(response.data.data);
        }
    }

    useEffect(() => {
        requestConfig();
    }, []);

    const renderLoadingApp = () => (
      <AnimatePresence>
          {loading && <LoadingApp color={config?.primary_color} logo={config?.logo_url} />}
      </AnimatePresence>
    );

    const context = useMemo(
      () => ({
          loading,
          setLoading,
          renderLoadingApp,
      }),
      [loading, renderLoadingApp]
    )

    return (
        <LoadingContext.Provider value={context}>
            {children}
            {renderLoadingApp()}
        </LoadingContext.Provider>
    );
};

export default LoadingProvider;
