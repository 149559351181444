export default {
  description:
    "Verwende code: {{code}}, um einen Rabatt auf deine {{count}} nächsten zu erhalten, wenn du hier bestellst.",
  discounts: {
    percentage: "{{discount}}% Rabatt",
    fixed: "{{discount}} Rabatt",
  },
  list: {
    item: {
      order: "{{orderNumber}}. Bestellung",
    },
  },
  savings: {
    percentage: "Du sparst insgesamt {{totalSaved}}%",
    fixed: "Du sparst insgesamt {{totalSaved}}",
  },
  custom_button: {
    done: "Fertig",
  },
};
