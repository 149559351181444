import React from "react";

interface Props {
  className?: string;
  style?: Object;
  color?: string;
}

export default function GiroPayIcon(props: Props) {
  const { className, color, style } = props;
  return (
    <svg
      className={className ?? "h-4 w-4"}
      viewBox="0 0 2500 1075"
      fill="none"
      style={style}
    >
      <path
        d="M0.0195312 192.761C0.0195312 86.3613 86.9452 0 193.909 0H2306.37C2413.34 0 2500.26 86.3613 2500.26 192.761V881.957C2500.26 988.357 2413.62 1074.72 2306.37 1074.72H193.909C86.9452 1075 0.0195312 988.639 0.0195312 882.239V192.761Z"
        fill="#000268"
      />
      <path
        d="M79.6055 199.534V875.748C79.6055 942.354 133.793 996.259 200.681 996.259H1307.29V79.0234H200.681C133.793 79.0234 79.6055 132.929 79.6055 199.534V199.534ZM1593.47 534.254C1593.47 577.435 1572.3 606.787 1537.02 606.787C1505.98 606.787 1480.01 577.153 1480.01 537.641C1480.01 497.283 1502.87 467.085 1537.02 467.085C1573.15 467.367 1593.47 498.412 1593.47 534.254ZM1386.31 781.202H1479.73V634.445H1480.86C1498.64 666.619 1533.92 678.472 1566.37 678.472C1646.81 678.472 1689.71 612.149 1689.71 532.279C1689.71 466.802 1648.78 395.964 1573.99 395.964C1531.38 395.964 1492.15 413.179 1473.24 450.998H1472.11V402.173H1386.03V781.202H1386.31ZM1817.27 586.466C1817.27 560.502 1842.11 550.624 1873.72 550.624C1887.83 550.624 1901.09 551.47 1913.23 552.035C1913.23 583.644 1890.93 615.818 1855.66 615.818C1833.92 616.1 1817.27 605.375 1817.27 586.466ZM2005.8 672.263C2001.57 651.096 2000.72 629.647 2000.72 608.48V507.725C2000.72 425.315 1941.17 395.681 1872.02 395.681C1832.23 395.681 1797.23 401.326 1764.21 414.873L1765.91 478.091C1791.87 463.416 1821.79 457.771 1851.99 457.771C1885.57 457.771 1913.23 467.649 1913.79 504.621C1901.94 502.645 1885.29 500.952 1870.33 500.952C1820.66 500.952 1730.91 510.83 1730.91 593.24C1730.91 651.943 1778.61 678.19 1831.95 678.19C1870.33 678.19 1896.3 663.232 1917.46 629.365H1918.59C1918.59 643.476 1920 657.305 1920.57 671.981H2005.8V672.263ZM2047.57 781.202C2066.76 785.436 2085.95 787.411 2105.71 787.411C2191.22 787.411 2211.54 721.371 2238.07 653.636L2337.13 402.173H2243.72L2188.12 578.564H2187.27L2129.13 402.173H2028.66L2138.16 678.754C2131.39 702.744 2113.89 716.008 2091.03 716.008C2078.05 716.008 2066.76 714.315 2054.06 710.364L2047.57 781.202V781.202Z"
        fill="white"
      />
      <path
        d="M307.645 534.818C307.645 497.846 325.708 467.366 360.704 467.366C402.756 467.366 420.254 500.951 420.254 531.149C420.254 572.636 393.724 600.577 360.704 600.577C332.481 600.577 307.645 576.87 307.645 534.818V534.818ZM510.566 401.89H425.898V450.715H425.052C405.296 417.412 373.122 395.681 333.328 395.681C249.225 395.681 211.406 455.795 211.406 536.229C211.406 616.099 257.691 672.262 331.635 672.262C369.171 672.262 400.216 657.587 422.511 625.977H423.64V640.653C423.64 693.429 394.571 718.83 340.666 718.83C301.719 718.83 277.729 710.645 249.225 696.534L244.427 770.195C266.158 778.097 303.13 787.411 347.722 787.411C456.661 787.411 510.566 751.568 510.566 640.653V401.89V401.89ZM668.331 289.281H574.914V357.862H668.331V289.281ZM574.914 672.262H668.331V401.89H574.914V672.262ZM928.261 398.785C918.948 397.374 907.376 395.681 896.652 395.681C856.293 395.681 832.869 417.412 816.782 451.844H815.653V402.172H730.703V672.545H824.12V558.525C824.12 505.467 848.673 473.857 892.136 473.857C903.143 473.857 913.303 473.857 923.745 476.962L928.261 398.785V398.785ZM1089.13 612.995C1045.95 612.995 1028.45 577.152 1028.45 537.358C1028.45 497 1045.95 461.157 1089.13 461.157C1132.31 461.157 1149.81 497 1149.81 537.358C1149.81 577.152 1132.03 612.995 1089.13 612.995ZM1089.13 678.471C1178.31 678.471 1246.33 626.542 1246.33 537.358C1246.33 447.61 1178.31 395.681 1089.13 395.681C999.946 395.681 931.93 447.61 931.93 537.358C931.93 626.542 999.664 678.471 1089.13 678.471Z"
        fill="#FF0007"
      />
    </svg>
  );
}
