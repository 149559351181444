import { useContext } from "react";
import ConfirmCancelModal from "../ConfirmCancelModal";
import useDineInFlow from "../../hooks/global/useDineInFlow";
import { SCREENS } from "../../services/exports/Constants";
import AppMethodsContext from "../../context/AppMethodsContext";
import { useTranslation } from "react-i18next";
import useAppState from "../../hooks/global/useAppState";
import useNavigate from "../../hooks/navigation/useNavigate";
import useAppMethods from "../../hooks/utility/useAppMethods";

export default function OrdersDisabledModal() {
  const { t } = useTranslation(null, { keyPrefix: "Modals:DineIn:OrdersDisabledModal" });

  const { openModal, onConfirm } = useAppState().ordersDisabledModalProps;

  const { toggleOrdersDisabledModal, toggleMenuItemModal } = useAppMethods();
  const { navigate } = useNavigate();
  const { unpaidAmount } = useDineInFlow();

  const _onConfirm = () => {
    if (onConfirm) {
      onConfirm();
    }
    toggleMenuItemModal({ openModal: false, product: null });
    toggleOrdersDisabledModal({ openModal: false });
    navigate(SCREENS.DINE_IN);
  };

  return (
    <ConfirmCancelModal
      title={
        unpaidAmount > 0 ? t("titles.unpaid_amount_left") : t("titles.main")
      }
      description={t("description")}
      primaryButtonTitle={t("buttons.okay")}
      customGradient={
        "bg-gradient-to-r from-companyBrand-primary via-companyBrand-primary to-companyBrand-primary"
      }
      openModal={openModal}
      onConfirm={_onConfirm}
    />
  );
}
